
/**
 * Returns a objet from a immutable map
 */
export const toSafeObject = (mapObject) => {
    if (mapObject === null || mapObject === undefined)
        return null;
    return mapObject.toJS();
}

/**
 * Returns an object's array from a immutable list
 */
export const toSafeArray = (list) => {
    if (list === null || list === undefined)
        return null
    return list.toJS();
}