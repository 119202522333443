/**Import react section */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
/**Import Material UI Section */
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

/**Import resources section */
import {cropDialogStyles} from '../../../resources/styles/CropDialogStyles'
/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const CropImageSelector = props => {
    const classes = cropDialogStyles();
    // Define local state
    const [crop, setCrop] = useState(null);

    /**
 * On change crop
 *
 * @param {*} crop
 * @param {*} pixelCrop
 * @memberof CropForm
 */
    const onChangeCrop=(crop)=> {
        setCrop(crop)
    }
    
    const onImageLoaded = (image) => {
        let initialCrop = makeAspectCrop({
            x: 0,
            y: 0,
            aspect: 1,
            width: image.width,
            height: image.height
        }, image.naturalWidth / image.naturalHeight);
        setCrop(initialCrop);
    }


    return (
        <div className={classes.root}>
            <ReactCrop
                src={props.fileBase64}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onChange = {onChangeCrop}
            >

            </ReactCrop>

        </div>
    );
}

export default withRouter(CropImageSelector);