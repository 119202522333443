/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'
/**Import components section */
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import { Alert, AlertTitle } from '@material-ui/lab'
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined'
/**Import Material Ui section */
import { Grid } from '@material-ui/core'

const SparePartSalesForm = (props) => {
    const { onSaveSparePartRequest, isSaving, isAuthorizadoForEditedData, isModeCreate, isMobile } = props;
    const [sparePartRequestsForm, setSparePartRequestsForm] = useState(null);

    // const [mapPositionModel, setPositionModel] = useState({
    //     latitude: "",
    //     longitude: ""
    // })

    useEffect(() => {
        setSparePartRequestsForm(props.sparePartSales)
    }, [props.sparePartSales])

    // useEffect(() => {
    //     setPositionModel(props.mapPosotionModel)
    // }, [props.mapPosotionModel])

    /**Custom hooks an styles */
    const classes = createServicesStyle();

    const onUpdateData = () => {
        if (onSaveSparePartRequest) {
            onSaveSparePartRequest(sparePartRequestsForm)
        }
    }

    /**Verify if cam be sabed */
    const canBeSaved = () => {
        if (!sparePartRequestsForm) return false;
        if (sparePartRequestsForm.Address ? (sparePartRequestsForm.address) : true) return false;
        return true;
    }

    const onPropertyChange = event => {
        let { name, value } = event.target;
        setSparePartRequestsForm({
            ...sparePartRequestsForm,
            [name]: value
        })
    }
    const renderTextBox = (key, item, label, isActive, isDisabled, size = 6, multiline = false, rows = 0, required) => {
        let value = item ? item[key] ? item[key] : '' : '';
        return (
            <Grid item xs={size}>
                <TextBox
                    key={key}
                    active={isActive}
                    disabled={isDisabled || !isModeCreate}
                    fullWidth
                    multiline={multiline}
                    name={key}
                    rows={rows}
                    variant="outlined"
                    margin="dense"
                    label={label}
                    onChange={onPropertyChange}
                    value={value}
                    inputProps={{
                        style: { fontSize: 12 }
                    }}
                    />
            </Grid>
        )
    }
    let sparePartRequestsAddress = sparePartRequestsForm && sparePartRequestsForm.Address && sparePartRequestsForm.Address.locality;
    return (
        <>
         <Grid item xs={6}>
                <Grid item xs={12}>
                        <TextBox
                        key={"TotalAmountInvoice"}
                        active={true}
                        disabled={!isAuthorizadoForEditedData || !isModeCreate}
                        fullWidth
                        multiline={false}
                        name={"TotalAmountInvoice"}
                        rows={0}
                        type={"number"}
                        variant="outlined"
                        margin="dense"
                        label={"TOTAL"}
                        onChange={onPropertyChange}
                        value={sparePartRequestsForm?.TotalAmountInvoice}
                        inputProps={{
                            style: { fontSize: 12 }
                        }}
                        />
                </Grid>
                        {renderTextBox("ShipTo", sparePartRequestsForm, "SHIP TO", true, !isAuthorizadoForEditedData, 12, true, (isMobile ? 3 : 4), false)}  
                        {renderTextBox("Comments", sparePartRequestsForm, "COMMENTS", true, !isAuthorizadoForEditedData, 12, true, (isMobile ? 3 : 4), false)}
            </Grid>

            <Grid item xs={6} spacing={2}>
                {renderTextBox("EmailsContact", sparePartRequestsForm, "EMAILS TO SEND NOTIFICATIONS e.g. (correo@correo.com,)", true, !isAuthorizadoForEditedData, 12, true, 1, false)}
                {renderTextBox("ContactPhone", sparePartRequestsForm, "PHONES FOR CLOSING SHIPMENT e.g. (7712333456,7798762345)", true, !isAuthorizadoForEditedData, 12, true, 1, false)}
                <Grid item xs={12} style={{marginTop:"12px"}}>
                    {<Alert
                        variant='outlined'
                        severity='info'
                        icon={<LocationOnIcon fontSize='inherit' />}
                    >
                        <AlertTitle>Address</AlertTitle>
                        {`${sparePartRequestsAddress ? sparePartRequestsForm.Address.street_number : ""} ${sparePartRequestsAddress ? sparePartRequestsForm.Address.route : ""}`} <br />
                        {`${sparePartRequestsAddress ? sparePartRequestsForm.Address.locality : ""}, ${sparePartRequestsAddress ? sparePartRequestsForm.Address.administrative_area_level_1 : ""}, ${sparePartRequestsAddress ? sparePartRequestsForm.Address.country : ""}`}
                        <br />
                        {`${sparePartRequestsAddress ? sparePartRequestsForm.Address.postal_code : ""}`}
                    </Alert>}
                    <br/>
                </Grid>

                {isAuthorizadoForEditedData && isModeCreate &&
                    <Grid item xs={12} className={classes.updateButton}>
                        <ButtonSaved
                            onSavedClick={onUpdateData}
                            title="Update"
                            isSaving={isSaving || !canBeSaved()}
                        />
                    </Grid>
                }
            </Grid>
        </>
    )
}

SparePartSalesForm.propTypes = {
    /**Determinate if update spare Part requests address  data */
    isSaving: propTypes.bool.isRequired,
    /**Function invoke callback for update contact info */
    onSaveSparePartRequest: propTypes.func.isRequired,
    /**Determinate if edit spare Part requests address  data */
    isAuthorizadoForEditedData: propTypes.bool.isRequired,
}
SparePartSalesForm.defaultProps = {
    isSaving: false,
    isAuthorizadoForEditedData: false,
    onSaveSparePartRequest: () =>
        console.warn('Callback [onSaveSparePartRequest] no defined'),
}


export default SparePartSalesForm
