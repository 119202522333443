/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js';

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import {
    Layout,
    Content,
    Header,
    HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'
import Toolbar from '../../common/Toolbar'
import HeaderFormCreate from './HeaderFormCreate'
/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useCreateDesktopStyle'
import iconSparePart from '../../../resources/images/sparePart.svg';
import { PositionMapDefault } from '../../../store/helpers/AppConstants'

/** Import material UI */
import {
    Typography,
} from '@material-ui/core'
import { getOptionsReactSelect } from '../../../store/helpers/FilterHelper'


const CreateDesktopView = (props) => {

    const classes = useCreateDesktopStyle();

    /**Functions section */
    const { onRedirectToHome, onCreateSpartPartSalesRequest, redirectToSpartPart } = props;
    /**Array props section */
    const { customers } = props
    /**Objects props section */
    const { userProfile } = props
    /**bool props sections */
    const { isSaving, isMobile } = props;

    let customersOptions = getOptionsReactSelect(customers)

    /**store local this components */
    const [spartPartModel, setSpartPartModel] = useState({
        Address: {},
    })

    const [mapPositionModel, setPositionModel] = useState({
        latitude: PositionMapDefault.latitude,
        longitude: PositionMapDefault.longitude
    })

    /**On change properties for the service */
    const onPropertyChange = (event) => {
        let { name, value } = event.target
        setSpartPartModel({
            ...spartPartModel,
            [name]: value
        })
    }

    /** Invoke when select property change*/
    const onSelectChange = (name, valueProperty) => {
        setSpartPartModel({
            ...spartPartModel,
            [name]: valueProperty
        })
    }

    /**
     * Function add map position
     * @param {*} position
     */
    const onChangePosition = (position) => {
        setPositionModel({ latitude: position.lat, longitude: position.lng })
    }

    /**
     * Action ok Change address
     * @param {*} address
     */
    const onChangeAddress = (address) => {
        setSpartPartModel({
            ...spartPartModel,
            Address: address
        })
    }

    /**Create spart part in the back end */
    const createButtonOnClick = () => {
        if (onCreateSpartPartSalesRequest) {
            onCreateSpartPartSalesRequest(spartPartModel, mapPositionModel, userProfile ? userProfile.Account.Code : "")
        }
    }

    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>
                            {isMobile ? "Create request" : "Spare Part Sales / Create"}
                        </Typography>
                    </HeaderBreadCrum>
                }
            />
            <SideBar
                key={'AuthenticatedSideBar'}
                onChangeLocation={props.onChangeLocation}
                selectedButtonIndex={5}
                userProfile={userProfile}
            />
            <Content key={'AuthenticatedContent'}>
                <div className={classes.root}>
                    <Toolbar
                        title='CREATE SPARE PART SALES'
                        icon={iconSparePart}
                    />
                    <div className={classes.container}>
                        <HeaderFormCreate
                            customersOptions={customersOptions}
                            onPropertyChange={onPropertyChange}
                            isSaving={isSaving}
                            spartPartModel={spartPartModel}
                            onSelectChange={onSelectChange}
                            mapPostionModel={mapPositionModel}
                            onChangePosition={onChangePosition}
                            onChangeAddress={onChangeAddress}
                            redirectToHome={onRedirectToHome}
                            addSparePartOnClick={createButtonOnClick}
                            redirectToSpartPart={redirectToSpartPart}
                            userProfile={userProfile}
                        />
                    </div>
                </div>
            </Content>
        </Layout>
    )
}

CreateDesktopView.propTypes = {
    /**List of customers */
    customers: propTypes.array.isRequired,
    isSaving: propTypes.bool.isRequired,
    /**Function invoke callback for create a spart part manual */
    onCreateSpartPartSalesRequest: propTypes.func.isRequired,
    /**Redirects to the spare part view when you don't want to create a new spare part*/
    redirectToSpartPart: propTypes.func.isRequired,
    /**
     * Is the information of the logged in user
     */
    userProfile: propTypes.shape({
        userName: propTypes.string.isRequired,
        name: propTypes.string.isRequired,
        email: propTypes.string.isRequired,
        avatarUrl: propTypes.string,
        enabled: propTypes.bool.isRequired,
        roles: propTypes.array.isRequired,
    }),
}

CreateDesktopView.defaultProps = {
    isSaving: false,
    customers: [],
    userProfile: null,
    onCreateSpartPartSalesRequest: () => console.warn('Callback [createService] no defined'),
    redirectToSpartPart: () =>
        console.warn('Callback [redirectToSpartPart] no defined')
}


export default withImmutablePropsToJS(CreateDesktopView)