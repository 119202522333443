/**Import react section */
import React, {useEffect} from 'react'
import propTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import {
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanel,
    Divider
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    expansionPanelDetails:{
        padding:0

    },
    headerPanelSerie:{
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        minHeight: "0px !important",
        height: "50px !important"
    },
}));

const ExpandedSummary = (props) => {
    const classes = useStyles();
    const { id, title, onRenderContent, isPaddingContent, isVisibleColor, isWrapTitle } = props

    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
        setExpanded(props.isExpanded?props.isExpanded:false)
    }, [props.isExpanded])

    const handleChange = (event) => {
        event.stopPropagation()
        setExpanded(!expanded);
      };
      

    return (
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded} >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon onClick={handleChange} />}
                    aria-controls="panel1a-content"
                    id={id}
                    className={(expanded && isVisibleColor) ? classes.headerPanelSerie : ""}
                >
                    <Typography 
                    variant='subtitle2' 
                    style={isWrapTitle? {
                        width:"240px",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        whiteSpace: "nowrap"
                    }:null}
                    className={expanded ? "" : classes.titleSerie}>{title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={isPaddingContent ? null: classes.expansionPanelDetails}>
                    {onRenderContent()}
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Divider/>
        </div>
    )
}

ExpandedSummary.propTypes = {
    id: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    onRenderContent: propTypes.func.isRequired,
    isVisibleColor: propTypes.bool

}
ExpandedSummary.defaultProps = {
    id: "",
    title: "",
    isVisibleColor:false,
	onRenderContent: () => console.warn('Callback [onRenderContent] no defined'),

}

export default ExpandedSummary