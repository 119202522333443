/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import {
	Layout,
	Content,
	Header,
	HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'
import Toolbar from '../../common/Toolbar'
import EquipmentsList from '../../services/components/EquipmentsList'
import EquipmentSparePartList from './EquipmentSparePartList'
import AddressComponent from './Address'
import Authorization from '../../common/AuthorizationEvaluation'
import OpenDesktopViewSkeleton from '../../skeletons/spareParts/OpenDesktopViewSkeleton'
import EvidenceComponent from './EvidencesComponents'

/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useStyles'
import {
	SPARE_PARTS_IMBERA_USA,
	IMBERA_TECHNICIAN
} from '../../../resources/RolesEnum'
import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	SpareParRequestStatus,
	WarrantyType
} from '../../../store/helpers/AppConstants'
import iconSparePart from '../../../resources/images/sparePart.svg'
import { GetBackgroundColorSparePartRequestByStatus } from '../../../store/helpers/StatusHelper'
import { toolbarStyles } from '../../../resources/styles/ToolbarStyles'

/**Import Material Ui section section */
import {
	Typography,
	Card,
	CardContent,
	Paper,
	Chip,
	Tabs,
	Tab,
	AppBar
} from '@material-ui/core'
import { GetWarrantyType } from '../../../store/helpers/SelectOptions'

/**
 * View create desktop
 */
const OpenDesktopView = (props) => {
	const {
		userProfile,
		onEditLateralPanelConfig,
		onUpdateEquipment,
		onSaveSparePartRequest,
		onRemoveFilesInSparePartRequests,
		onAddFilesInSparePartRequest
	} = props
	const classes = useCreateDesktopStyle()
	const toolbarStylesClasses = toolbarStyles()
	const [mode] = useState('MODE_SHOW')
	const {
		isLoading,
		sparePartRequest,
		onSendEmailToTechnical,
		openLateralPanelChangeStatus,
		onShowWarranty
	} = props
	const [status, setStatus] = useState(sparePartRequest)
	const [visiblePanelIndex, setVisiblePanelIndex] = useState(0)
	const [identifierExpanded, setIdentifierExpanded] = useState([])

	useEffect(() => {
		if (
			!!props.sparePartRequest.open &&
			props.sparePartRequest.open.Status
		) {
			setStatus(props.sparePartRequest.open.Status)
		}
	}, [props.sparePartRequest.open])

	let isAuthorizadoForEditedData = hasAuthorization(userProfile, [
		SPARE_PARTS_IMBERA_USA,
		IMBERA_TECHNICIAN
	])
	const colorByStatus =
		status &&
		GetBackgroundColorSparePartRequestByStatus(status, toolbarStylesClasses)

	const onAddEvidencesFiles = (files) => {
		if (files.length > 0 && onAddFilesInSparePartRequest) {
			onAddFilesInSparePartRequest(files, sparePartRequest.open)
		}
	}

	const getWarrantyType = (value) => {
		let existWarranty = GetWarrantyType().find((x) => x.value === value)
		if (existWarranty) return existWarranty.label
		return null
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileName) => {
		if (fileName && onRemoveFilesInSparePartRequests) {
			onRemoveFilesInSparePartRequests(fileName, sparePartRequest.open)
		}
	}

	const onRenderViewOptions = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{(status === SpareParRequestStatus.IN_PROCESS ||
					(sparePartRequest.open &&
						!sparePartRequest.open.IsClosed)) && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							SPARE_PARTS_IMBERA_USA,
							IMBERA_TECHNICIAN
						]}
					>
						<Chip
							label='CHANGE STATUS'
							clickable
							color='primary'
							onClick={() =>
								openLateralPanelChangeStatus(
									props.sparePartRequest.open
								)
							}
						/>
					</Authorization>
				)}
			</div>
		)
	}

	const renderContent = () => {
		if (isLoading) {
			return <OpenDesktopViewSkeleton />
		} else {
			return (
				<>
					<Toolbar
						title={
							sparePartRequest && sparePartRequest.open
								? `SPARE PART REQUEST | FOLIO: ${
										sparePartRequest.open.Folio
								  } ${
										getWarrantyType(
											sparePartRequest.open.WarrantyType
										)
											? `| Warranty type: ${getWarrantyType(
													sparePartRequest.open
														.WarrantyType
											  )}`
											: ''
								  }`
								: ''
						}
						renderContentRight={onRenderViewOptions()}
						icon={iconSparePart}
						renderBackgroundColor={colorByStatus}
					/>

					<div className={classes.containerData}>
						<Paper>
							{sparePartRequest &&
								sparePartRequest.open &&
								sparePartRequest.open.Service && (
									<Typography className={classes.titleTicket}>
										{sparePartRequest &&
										sparePartRequest.open
											? `TICKET: ${sparePartRequest.open.Service.Ticket} | SALES REPRESENTATIVE: ${sparePartRequest.open.Service.SalesRepresentative.Name} | RETAILER: ${sparePartRequest.open.Service.Retailer.Name}`
											: ''}
									</Typography>
								)}
							<EquipmentsList
								equipments={sparePartRequest.equipments}
								isAuthorizadoForEditedData={true}
								modeView='SPARE_PART_REQUETS_SHOW'
								onShowWarranty={onShowWarranty}
								onUpdateSerieInService={
									sparePartRequest &&
									sparePartRequest.open &&
									sparePartRequest.open.IsManual
										? onUpdateEquipment
										: null
								}
								isWarrantySales={
									props.sparePartRequest &&
									props.sparePartRequest.open &&
									props.sparePartRequest.open.WarrantyType ===
										WarrantyType.SALE
								}
								expandablePanelContent={
									<EquipmentSparePartList
										isLoading={isLoading}
										mode={mode}
										onUpdateSpartPart={
											props.onUpdateSpartPart
										}
										userProfile={userProfile}
										status={
											props.sparePartRequest.open &&
											props.sparePartRequest.open.Status
										}
										onEditLateralPanelConfig={
											onEditLateralPanelConfig
										}
										onSendEmailToTechnical={
											onSendEmailToTechnical
										}
										identifierSparePartDelete={
											props.identifierSparePart
										}
										identifierExpanded={identifierExpanded}
										setIdentifierExpanded={
											setIdentifierExpanded
										}
									/>
								}
								handleClick={handleClick}
								open={open}
							/>
						</Paper>
						<br />
						<Card>
							<AppBar position='static'>
								<Tabs
									value={visiblePanelIndex}
									onChange={(event, index) =>
										setVisiblePanelIndex(index)
									}
								>
									<Tab label='Address' />
									<Tab label='Evidences' />
								</Tabs>
							</AppBar>

							<TabPanel value={visiblePanelIndex} index={0}>
								<CardContent>
									<AddressComponent
										requestDraft={sparePartRequest.open}
										mode={mode}
										isVisibleChipsAssignement={true}
										onSaveSparePartRequest={
											onSaveSparePartRequest
										}
										isAuthorizadoForEditedData={
											isAuthorizadoForEditedData
										}
										isSaving={props.isSaving}
									/>
								</CardContent>
							</TabPanel>

							<TabPanel value={visiblePanelIndex} index={1}>
								<CardContent>
									<EvidenceComponent
										onAddFiles={onAddEvidencesFiles}
										onRemoveFile={onRemoveFile}
										canBeAddFiles={
											isAuthorizadoForEditedData &&
											status !==
												SpareParRequestStatus.CLOSED
										}
										files={
											props.sparePartRequest.open &&
											props.sparePartRequest.open.Files
												? props.sparePartRequest.open
														.Files
												: []
										}
									/>
								</CardContent>
							</TabPanel>
						</Card>
					</div>
				</>
			)
		}
	}
	const [open, setOpen] = useState(false)

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>
							Spare Part Request / View Request
						</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={3}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>{renderContent()}</div>
			</Content>
		</Layout>
	)
}

/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}
TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired
}

OpenDesktopView.propTypes = {
	onChangeLocation: propTypes.func.isRequired,
	onEditLateralPanelConfig: propTypes.func.isRequired,
	/**Send email to technical when spart part is completed and user executed callback sen email */
	onSendEmailToTechnical: propTypes.func.isRequired,
	/**Callback executed when user reuired change status the spare part requests and open lateral panel */
	openLateralPanelChangeStatus: propTypes.func.isRequired,
	onSaveSparePartRequest: propTypes.func.isRequired
}
OpenDesktopView.defaultProps = {
	onChangeLocation: () =>
		console.warn('Callback [onChangeLocation] no defined'),
	onEditLateralPanelConfig: () =>
		console.warn('Callback [onEditLateralPanelConfig] no defined'),
	onSendEmailToTechnical: () =>
		console.warn('Callback [onSendEmailToTechnical] no defined'),
	openLateralPanelChangeStatus: () =>
		console.warn('Callback [openLateralPanelChangeStatus] no defined'),
	onSaveSparePartRequest: () =>
		console.warn('Callback [onSaveSparePartRequest] no defined')
}

export default OpenDesktopView
