import React from 'react'
import PropTypes from 'prop-types'
import { useIsMobile } from '../../hooks/useIsMobile'

/** Import layouts */
import Layout from '../layouts/AnonymousLayout'

/** Import components */
import DesktopView from './components/AnonymousDesktopView'
import MobileView from './components/AnonymousMobileView'

/** Import components and helpers */
import { getUserManager } from '../../store/helpers/UserManager'

/**
 * This view is shown to an anonymous user when he access to the portal initial page.
 * The anomymous user can login from this page
 */
const AnonymousView = (props) => {
	let userManager = getUserManager()
	const isMobile = useIsMobile(props.isMobile)

	/**
	 * Execute login
	 */
	const onLogin = () => userManager.signinRedirect()
	

	return (
		<Layout onLoginClick={onLogin}>
			{isMobile ? <MobileView /> : <DesktopView />}
		</Layout>
	)
}
AnonymousView.propTypes = {
	isMobile: PropTypes.bool.isRequired
}
AnonymousView.defaultProps = {
	isMobile: false
}
export default AnonymousView
