/**Import react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'
/**Import actions section */
import {
	getService,
	addSeriesInService,
	saveService,
	updateSerieInSerivce,
	deleteSerieInSerivce,
	addDateAttention,
	addCommentHistory,
	updateCommentHistoryStatus,
	updateComment,
	addAttachments,
	removeAttachments
} from '../../store/services/serviceActions'
import { loadInternalUsers } from '../../store/users/UsersActions'
import { loadAllServiceProviderWithFilters, loadUserInServiceProvider } from '../../store/serviceproviders/serviceProvidersActions'
import { addSpareParrRequestFromTechnicial } from '../../store/spareParts/sparePartsActions'
import { loadAllSalesRepresentatives } from '../../store/salesrepresentatives/salesRepresentativesActions'

/**Import components section */
import ShowDesktopView from './components/ShowDesktopView'
import ShowMobilView from './componentsMobile/ShowMobilView'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import Toaster from '../common/Toaster'
import WarrantyChecksPanel from './components/WarrantyChecksPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel'
import Edit from './Edit'
import EditServiceProvider from './EditServiceProvider'

const Show = (props) => {
	/**Hooks sections */
	const isMobile = useIsMobile(props.isMobile)
	let history = useHistory()
	const dispatch = useDispatch()
	let match = useRouteMatch()
	const token = match.params.token
	const isLoading = useSelector((state) => state.services.get('isLoading'))
	const isSavingService = useSelector((state) => state.services.get('isSavingService'))
	const isSavingSeries = useSelector((state) => state.services.get('isSavingSeries'))
	const isAddingSeries = useSelector((state) => state.services.get('isAddingSeries'))
	const service = useSelector((state) => state.services.get('service'))
	const isSavingRequestDraft = useSelector((state) => state.spareParts.getIn(['create', 'isSavingRequestDraft']))
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [lateralPanelEquipment, setLateralPanelEquipment] = useState({
		opened: false,
		equipment: null
	})
	const [lateralPanelStatusConfig, setLateralPanelStatusConfig] = useState({
		opened: false,
		service: null
	})
	const [lateralPanelProviderConfig, setLateralPanelProvicerConfig] = useState({ opened: false, service: null })
	const userProfile = useSelector((state) => state.session.get('profile'))
	const internalUsers = useSelector((state) => state.users.get('internalUsers'))
	const serviceProviders = useSelector((state) => state.serviceProviders.get('serviceProviders'))

	useEffect(() => {
		dispatch(getService(token))
	}, [dispatch, token])

	useEffect(() => {
		dispatch(loadInternalUsers())
		dispatch(loadAllServiceProviderWithFilters(null, 1000))
		dispatch(loadAllSalesRepresentatives(null, 1000))
	}, [dispatch])

	useEffect(() => {
		if (service) {
			let provider = service.get('ServiceProvider')
			if (provider) {
				dispatch(loadUserInServiceProvider(null, provider.get('Code')))
			}
		}
	}, [dispatch, service])

	/**
	 * In update service
	 * @param {*} service
	 */
	const onSaveService = (service) => {
		dispatch(saveService(service))
			.then(() => {
				setToasterOptions({
					open: true,
					message: 'Service updated successfully',
					variant: 'success'
				})
			})
			.catch(() => {
				setToasterOptions({
					open: true,
					message: 'An error to update series in service',
					variant: 'error'
				})
			})
	}

	/**
	 * Function to add equipment
	 * @param {*} series
	 */
	const onAddSeries = (series) => {
		return dispatch(addSeriesInService(token, series))
			.then(() => {
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to add series in service',
					variant: 'warning'
				})
				return Promise.reject()
			})
	}

	/**
	 * Function to add date attention by tech provider
	 * @param {*} service
	 * @param {*} dateAttentionModel
	 * @param {*} dispatcherService
	 */
	const onAddDateAttention = (service, dateAttentionModel, dispatcherService) => {
		dispatch(addDateAttention(service, dateAttentionModel, dispatcherService))
			.then(() => {
				setToasterOptions({
					open: true,
					message: 'Date attention add successfully',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to add date attention in service',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to add comment history
	 * @param {*} service
	 * @param {*} comments
	 */
	const onAddCommentHistory = (service, comments) => {
		dispatch(addCommentHistory(service, comments))
			.then(() => {
				setToasterOptions({
					open: true,
					message: 'Comment added successfully to history',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to add comment to history in service',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to add comment history
	 * @param {*} service
	 * @param {*} comments
	 */
	const onUpdateCommentHistory = (serviceToken, commentsToken) => {
		dispatch(updateCommentHistoryStatus(serviceToken, commentsToken))
			.then(() => {
				setToasterOptions({
					open: true,
					message: 'Comment updated successfully in history',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to update comment in history in service',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to update a comment in history
	 * @param {*} service
	 * @param {*} comments
	 */
	const onUpdateComment = (serviceToken, commentToken, newComment) => {
		dispatch(updateComment(serviceToken, commentToken, newComment))
			.then(() => {
				setToasterOptions({
					open: true,
					message: 'Comment updated successfully.',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to update comment.',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to update equipment
	 * @param {*} equipmentToken
	 * @param {*} property
	 * @param {*} propertyData
	 */
	const onUpdateSerieInService = (
		equipmentToken,
		refrigerationNotCoolFailure,
		iluminationFailure,
		doorFailure,
		comments,
		faultWarranty,
		CompletionDate
	) => {
		dispatch(
			updateSerieInSerivce(
				token,
				equipmentToken,
				refrigerationNotCoolFailure,
				iluminationFailure,
				doorFailure,
				comments,
				faultWarranty,
				CompletionDate
			)
		).catch(() => {
			console.error('Error to update service')
		})
	}

	const onDeleteSerieInService = (equipmentToken) => {
		dispatch(deleteSerieInSerivce(token, equipmentToken)).catch(() => {
			console.error('Error to delete service')
		})
	}

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelOnClick = (item) => {
		setLateralPanelEquipment({
			opened: !lateralPanelEquipment.opened,
			equipment: item
		})
	}

	/**Close lateral panel equipment*/
	const onCloseButtonClick = () => {
		setLateralPanelEquipment({ opened: false, equipment: null })
	}
	const onChangeLocation = (location) => history.push(location)

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelChangeStatus = (service) => {
		setLateralPanelStatusConfig({
			opened: !lateralPanelStatusConfig.opened,
			service: service
		})
	}

	/**Open lateral panel for show assigned tech provider */
	const openLateralPanelAssignedProvider = (service) => {
		setLateralPanelProvicerConfig({
			opened: !lateralPanelProviderConfig.opened,
			service: service
		})
	}

	/**
	 * Function to a add spare part Request by techncial provider in draft
	 */
	const onAddSpareRequestInDraft = () => {
		dispatch(addSpareParrRequestFromTechnicial(service.get('Ticket')))
			.then(() => {
				history.push(`/spareparts/create/${service.get('Ticket')}`)
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to add spare part request in draft',
					variant: 'error'
				})
			})
	}

	/**
	 * Function to add files or image in service
	 * @param {*} files
	 */
	const onAddAttachments = (files) => {
		return dispatch(addAttachments(token, files)).catch((error) => {
			setToasterOptions({
				open: true,
				message: !isNullOrEmpty(error) ? error : 'An error occurred while adding file',
				variant: 'warning'
			})
		})
	}

	/**
	 * Function to remove files in service
	 * @param {*} fileName
	 */
	const onRemoveAttachments = (fileName) => {
		return dispatch(removeAttachments(token, fileName)).catch((error) => {
			setToasterOptions({
				open: true,
				message: !isNullOrEmpty(error) ? error : 'An error occurred remove file',
				variant: 'warning'
			})
		})
	}

	return (
		<>
			{isMobile ? (
				<ShowMobilView
					service={service}
					isLoading={isLoading}
					onAddSeries={onAddSeries}
					isSavingSeries={isSavingSeries}
					onSaveService={onSaveService}
					isSavingService={isSavingService}
					onUpdateSerieInService={onUpdateSerieInService}
					onShowWarranty={openLateralPanelOnClick}
					userProfile={userProfile}
					internalUsers={internalUsers}
					onChangeLocation={onChangeLocation}
					serviceProviders={serviceProviders}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					openLateralPanelAssignedProvider={openLateralPanelAssignedProvider}
					onAddDateAttention={onAddDateAttention}
					onAddCommentHistory={onAddCommentHistory}
					onUpdateCommentHistory={onUpdateCommentHistory}
					onUpdateComment={onUpdateComment}
					isMobile={isMobile}
					onAddSpareRequestInDraft={onAddSpareRequestInDraft}
					isSavingRequestDraft={isSavingRequestDraft}
					onAddAttachments={onAddAttachments}
					onRemoveAttachments={onRemoveAttachments}
				/>
			) : (
				<ShowDesktopView
					service={service}
					isLoading={isLoading}
					isAddingSeries={isAddingSeries}
					onAddSeries={onAddSeries}
					isSavingSeries={isSavingSeries}
					onSaveService={onSaveService}
					isSavingService={isSavingService}
					onUpdateSerieInService={onUpdateSerieInService}
					onDeleteSerieInService={onDeleteSerieInService}
					onShowWarranty={openLateralPanelOnClick}
					userProfile={userProfile}
					internalUsers={internalUsers}
					onChangeLocation={onChangeLocation}
					serviceProviders={serviceProviders}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					openLateralPanelAssignedProvider={openLateralPanelAssignedProvider}
					onAddDateAttention={onAddDateAttention}
					onAddCommentHistory={onAddCommentHistory}
					onUpdateCommentHistory={onUpdateCommentHistory}
					onUpdateComment={onUpdateComment}
					onAddAttachments={onAddAttachments}
					onRemoveAttachments={onRemoveAttachments}
				/>
			)}

			{/** Toaster */
			toasterOptions.open && <Toaster toasterOptions={toasterOptions} onClose={() => setToasterOptions({ open: false })} />}

			{/** Equipment Panel */
			lateralPanelEquipment.opened && (
				<LateralPanel onClose={onCloseButtonClick}>
					<WarrantyChecksPanel
						onCloseButtonClick={onCloseButtonClick}
						equipment={lateralPanelEquipment.equipment}
						serviceType={service ? service.get('ServiceType') : ''}
					/>
				</LateralPanel>
			)}

			<Edit
				lateralPanelStatusConfig={lateralPanelStatusConfig}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
				isLoading={isLoading}
				isSavingService={isSavingService}
				userProfile={userProfile}
			/>

			<EditServiceProvider
				lateralPanelProviderConfig={lateralPanelProviderConfig}
				openLateralPanelAssignedProvider={openLateralPanelAssignedProvider}
				isLoading={isLoading}
				isSavingService={isSavingService}
				userProfile={userProfile}
			/>
		</>
	)
}

export default Show
