import React from 'react'
import propTypes from 'prop-types'

import AttachIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Cancel'

/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useStyles'
import DropDownZone from '../../common/DropDownZone'

/**Import styles section */
import { Alert } from '@material-ui/lab'
/**Import Material Ui section */
import { Typography, Chip, Divider, Grid } from '@material-ui/core'

const EvidenceComponent = (props) => {
	const classes = useCreateDesktopStyle()
	const {
		FilesProvider,
		onAddFilesReceived,
		onRemoveFile,
		canBeEditProvider,
		Files,
		canBeEditInPlant,
		onAddFilesInPlant,
		onRemoveFileInPlant
	} = props

	return (
		<Grid>
			<Grid item xs={12}>
				<Typography className={classes.title}>
					<strong>{'PROVIDER FILES'}</strong>
				</Typography>
				<Divider />
				{canBeEditProvider && (
					<DropDownZone
						classes={classes}
						multiple={false}
						message={'Click here to select files'}
						onFilesReceived={(files) => {
							onAddFilesReceived(files)
						}}
					/>
				)}

				{FilesProvider.length !== 0 ? (
					FilesProvider.map((file) => {
						return (
							<UploadFileChip
								key={file.Token}
								file={file}
								canBeEdit={canBeEditProvider}
								onRemoveFile={onRemoveFile}
							/>
						)
					})
				) : (
					<Alert severity='info'>
						{'There are no files added by the provider'}
					</Alert>
				)}
			</Grid>
			<br />
			<br />
			<Grid item xs={12}>
				<Typography className={classes.title}>
					<strong>{'VALIDATOR FILES'}</strong>
				</Typography>
				<Divider />
				{canBeEditInPlant && (
					<DropDownZone
						classes={classes}
						multiple={false}
						message={'Click here to select files'}
						onFilesReceived={(files) => {
							onAddFilesInPlant(files)
						}}
					/>
				)}

				{Files.length !== 0 ? (
					Files.map((file) => {
						return (
							<UploadFileChip
								key={file.Token}
								file={file}
								canBeEdit={canBeEditInPlant}
								onRemoveFile={onRemoveFileInPlant}
							/>
						)
					})
				) : (
					<Alert severity='info'>
						{'There are no files added by the validator'}
					</Alert>
				)}
			</Grid>
			<br />
			<br />
		</Grid>
	)
}
EvidenceComponent.propTypes = {
	onAddFilesReceived: propTypes.func,
	onRemoveFile: propTypes.func,
	onAddFilesInPlant: propTypes.func,
	onRemoveFileInPlant: propTypes.func
}
EvidenceComponent.defaultProps = {
	onAddFilesReceived: () =>
		console.warn('Callback [onAddFilesReceived] no defined'),
	onRemoveFile: () => console.warn('Callback [onRemoveFile] no defined'),
	canBeEditProvider: false,
	FilesProvider: [],
	Files: [],
	canBeEditInPlant: false,
	onAddFilesInPlant: () =>
		console.warn('Callback [onAddFilesInPlant] no defined'),
	onRemoveFileInPlant: () =>
		console.warn('Callback [onRemoveFileInPlant] no defined')
}

/**
 * Component to upload file
 * @param {*} props
 */
const UploadFileChip = (props) => {
	const { canBeEdit, file, onRemoveFile } = props

	return (
		<>
			{canBeEdit ? (
				<Chip
					color='primary'
					icon={<AttachIcon />}
					label={
						<div
							style={{
								maxWidth: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}
						>
							<Typography
								variant='caption'
								noWrap
								title={file.name}
							>
								{file.name}
							</Typography>
						</div>
					}
					id={file.name}
					key={file.name}
					onDelete={() => {
						onRemoveFile(file.name)
					}}
					deleteIcon={<CloseIcon id={file.name} />}
					clickable
					target='_blank'
					onClick={() =>
						window.open(
							`${window.config.serviceManagementBackendUrl}/${file.relativePath}`
						)
					}
					style={{
						margin: '1px',
						maxWidth: '450px',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				/>
			) : (
				<Chip
					color='primary'
					icon={<AttachIcon />}
					label={
						<div
							style={{
								maxWidth: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}
						>
							<Typography
								variant='caption'
								noWrap
								title={file.name}
							>
								{file.name}
							</Typography>
						</div>
					}
					clickable
					component='a'
					target='_blank'
					onClick={() =>
						window.open(
							`${window.config.serviceManagementBackendUrl}/${file.relativePath}`
						)
					}
					id={file.name}
					key={file.name}
					style={{
						margin: '1px',
						maxWidth: '450px',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				/>
			)}
		</>
	)
}

export default EvidenceComponent
