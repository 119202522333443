/**IMport react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**Import components section */
import CreateManualDesktopView from './components/CreateManualDesktopView'
import Toaster from '../common/Toaster'
import { useIsMobile } from '../../hooks/useIsMobile'

/**Import actions section */
import { loadAllServiceProviderWithFilters } from '../../store/serviceproviders/serviceProvidersActions'
import { addSpartPartRequestManual } from '../../store/spareParts/sparePartsActions'

const IndexManual = (props) => {
    const isMobile = useIsMobile(props.isMobile)
    const dispatch = useDispatch()
    let history = useHistory();
    /**store local redux */

    const serviceProviders = useSelector((state) =>
        state.serviceProviders.get('serviceProviders')
    )

    const userProfile = useSelector((state) => state.session.get('profile'))
    const isSavingDraft = useSelector((state) => state.spareParts.getIn(['create', 'isSavingDraft']))
    /**store local this components */
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });


    useEffect(() => {
        dispatch(loadAllServiceProviderWithFilters(null, 1000))
    }, [dispatch])
    
    const redirectToSpartPart = () =>{
        history.push(`/spareparts`)
    }

    const redirectToSpartPartManual = (token) =>{
        history.push(`/spareparts/manual/open/${token}`)
    }
    
    
    /**
     * Function to create spart part
     * @param {*} service 
     * @param {*} position 
     */
    const onCreateSpartPartRequestsManual = (spartPartModel, position, accountCode) => {
        return dispatch(addSpartPartRequestManual(spartPartModel, position, accountCode)).then((token) => {
            redirectToSpartPartManual(token)
        })
            .catch(() => {
                setToasterOptions({ open: true, message: "An error was generated while generating the request", variant: 'error' })
            });
    }

	const onChangeLocation = (location) => history.push(location)

    return (
        <>
            <CreateManualDesktopView
                onChangeLocation={onChangeLocation}
                serviceProviders={serviceProviders}
                onCreateSpartPartRequestsManual={onCreateSpartPartRequestsManual}
                userProfile={userProfile}
                redirectToSpartPart={redirectToSpartPart}
                isMobile={isMobile}
                isSaving={isSavingDraft}
            />

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }

        </>
    )
}

export default IndexManual
