/**IMport actions sections */
import React from 'react'
import Authorization from '../common/AuthorizationEvaluation'
import {
	PORTAL_ADMINISTRATOR,
	IMBERA_TECHNICIAN,
	DISPATCHER,
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER,
	CUSTOMER_MANAGER,
	PROVIDER_REGISTRATION_VALIDATOR,
	CUSTOMER_TECHNICIAN,
	PAYMENT_MANAGER,
	VISUALIZER
} from '../../resources/RolesEnum'
import { useIsMobile } from '../../hooks/useIsMobile'
import classNames from 'classnames'
import propTypes from 'prop-types'
import { useSideBarStyles } from '../../resources/styles/SideBarStyles'
import { Paper, List, ListItem, Tooltip, Avatar, ListItemAvatar } from '@material-ui/core'
import ActiveServiceListIcon from '@material-ui/icons/ChromeReaderModeTwoTone'
import ClosedServiceListIcon from '@material-ui/icons/GridOffOutlined'
import NetworkDaysIcon from '@material-ui/icons/NetworkCheckOutlined'
import SparePartsIcon from '@material-ui/icons/Build'
import SparePartsClosedIcon from '@material-ui/icons/WorkOff'
import SparePartsSalesIcon from '@material-ui/icons/LocalShippingOutlined'

import RegionsIcon from '@material-ui/icons/Public'
import HistoryIcon from '@material-ui/icons/History'
import HistorySapIcon from '@material-ui/icons/FindInPage'
import GraphicEqIcon from '@material-ui/icons/GraphicEq'
import PaymentsIcon from '@material-ui/icons/AttachMoney'
import PaymentSummaryIcon from '@material-ui/icons/CreditCard'
import StorageIcon from '@material-ui/icons/Storage';

/**
 * Authenticated sidebar
 */
const SideBar = (props) => {
	const { userProfile } = props
	const classes = useSideBarStyles()
	const isMobile = useIsMobile(props.isMobile)
	const isSelected = (index) => props.selectedButtonIndex === index

	return (
		<Paper className={classes.root}>
			<List>
				<Authorization
					profile={userProfile}
					allowedRoles={[
						PORTAL_ADMINISTRATOR,
						IMBERA_TECHNICIAN,
						DISPATCHER,
						SPARE_PARTS_IMBERA_USA,
						SERVICE_PROVIDER_MANAGER,
						PAYMENT_MANAGER,
						SERVICE_PROVIDER_TECHNICIAN,
						SALES_MANAGER,
						PROVIDER_REGISTRATION_VALIDATOR,
						SALES_REPRESENTATIVE_MANAGER,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN,
						VISUALIZER
					]}
				>
					<ListItem
						button
						disableGutters
						dense
						onClick={() => props.onChangeLocation('/')}
					>
						<ListItemAvatar>
							<Avatar
								className={classNames(
									classes.iconButton,
									isSelected(1) ? classes.activeIcon : null
								)}
							>
								<Tooltip title='Active services'>
									<ActiveServiceListIcon />
								</Tooltip>
							</Avatar>
						</ListItemAvatar>
					</ListItem>
				</Authorization>
				<Authorization
					profile={userProfile}
					allowedRoles={[
						PORTAL_ADMINISTRATOR,
						IMBERA_TECHNICIAN,
						DISPATCHER,
						SPARE_PARTS_IMBERA_USA,
						SERVICE_PROVIDER_MANAGER,
						SERVICE_PROVIDER_TECHNICIAN,
						SALES_MANAGER,
						SALES_REPRESENTATIVE_MANAGER,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN,
						VISUALIZER
					]}
				>
					<ListItem
						button
						disableGutters
						dense
						onClick={() => props.onChangeLocation('/closed')}
					>
						<ListItemAvatar>
							<Avatar
								className={classNames(
									classes.iconButton,
									isSelected(2) ? classes.activeIcon : null
								)}
							>
								<Tooltip title='Closed services'>
									<ClosedServiceListIcon />
								</Tooltip>
							</Avatar>
						</ListItemAvatar>
					</ListItem>
				</Authorization>
				<Authorization
					profile={userProfile}
					allowedRoles={[
						PORTAL_ADMINISTRATOR,
						IMBERA_TECHNICIAN,
						DISPATCHER,
						SPARE_PARTS_IMBERA_USA,
						SPARE_PARTS_IMBERA_MX,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN,
						SERVICE_PROVIDER_MANAGER,
						SERVICE_PROVIDER_TECHNICIAN,
						VISUALIZER
					]}
				>
					<ListItem
						button
						disableGutters
						dense
						onClick={() => props.onChangeLocation('/spareparts')}
					>
						<ListItemAvatar>
							<Avatar
								className={classNames(
									classes.iconButton,
									isSelected(3) ? classes.activeIcon : null
								)}
							>
								<Tooltip title='Spare parts request'>
									<SparePartsIcon />
								</Tooltip>
							</Avatar>
						</ListItemAvatar>
					</ListItem>
				</Authorization>
				<Authorization
					profile={userProfile}
					allowedRoles={[
						PORTAL_ADMINISTRATOR,
						IMBERA_TECHNICIAN,
						DISPATCHER,
						SPARE_PARTS_IMBERA_USA,
						SPARE_PARTS_IMBERA_MX,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN,
						SERVICE_PROVIDER_MANAGER,
						SERVICE_PROVIDER_TECHNICIAN,
						VISUALIZER
					]}
				>
					<ListItem
						button
						disableGutters
						dense
						onClick={() => props.onChangeLocation('/spareparts/closed')}
					>
						<ListItemAvatar>
							<Avatar
								className={classNames(
									classes.iconButton,
									isSelected(4) ? classes.activeIcon : null
								)}
							>
								<Tooltip title='Spare parts request closed'>
									<SparePartsClosedIcon />
								</Tooltip>
							</Avatar>
						</ListItemAvatar>
					</ListItem>
				</Authorization>
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							IMBERA_TECHNICIAN,
							DISPATCHER,
							SPARE_PARTS_IMBERA_USA,
							SPARE_PARTS_IMBERA_MX,
							CUSTOMER_MANAGER,
							CUSTOMER_TECHNICIAN,
							SERVICE_PROVIDER_MANAGER,
							SERVICE_PROVIDER_TECHNICIAN,
							SALES_MANAGER,
							SALES_REPRESENTATIVE_MANAGER,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/sparepartsales')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(5) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Spare parts Sales'>
										<SparePartsSalesIcon />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}

				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							SERVICE_PROVIDER_MANAGER,
							PAYMENT_MANAGER,
							PROVIDER_REGISTRATION_VALIDATOR,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/paymentrequests')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(6) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Payment requests'>
										<PaymentsIcon style={{ fontSize: 30 }} />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							SERVICE_PROVIDER_MANAGER,
							PAYMENT_MANAGER,
							PROVIDER_REGISTRATION_VALIDATOR,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/paymentsummary')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(7) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Payment summary'>
										<PaymentSummaryIcon style={{ fontSize: 30 }} />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[PORTAL_ADMINISTRATOR, DISPATCHER, VISUALIZER]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/graph')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(8) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Graph services'>
										<GraphicEqIcon />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							IMBERA_TECHNICIAN,
							DISPATCHER,
							SPARE_PARTS_IMBERA_USA,
							SERVICE_PROVIDER_MANAGER,
							SERVICE_PROVIDER_TECHNICIAN,
							SALES_REPRESENTATIVE_MANAGER,
							SALES_MANAGER,
							CUSTOMER_MANAGER,
							CUSTOMER_TECHNICIAN,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/networkdays')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(9) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Network days report'>
										<NetworkDaysIcon />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							IMBERA_TECHNICIAN,
							DISPATCHER,
							SPARE_PARTS_IMBERA_USA,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/regions')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(10) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Regions'>
										<RegionsIcon />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							IMBERA_TECHNICIAN,
							DISPATCHER,
							SPARE_PARTS_IMBERA_USA,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/serialHistory')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(11) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Serial history portal'>
										<HistoryIcon />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
				{
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							IMBERA_TECHNICIAN,
							DISPATCHER,
							SPARE_PARTS_IMBERA_USA,
							SPARE_PARTS_IMBERA_MX,
							SERVICE_PROVIDER_MANAGER,
							SERVICE_PROVIDER_TECHNICIAN,
							PROVIDER_REGISTRATION_VALIDATOR,
							SALES_REPRESENTATIVE_MANAGER,
							SALES_MANAGER,
							CUSTOMER_MANAGER,
							CUSTOMER_TECHNICIAN,
							VISUALIZER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/serialSap')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(12) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Serial SAP'>
										<HistorySapIcon />
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				}
				{!isMobile && (
					<Authorization
						profile={userProfile}
						allowedRoles={[
							PORTAL_ADMINISTRATOR,
							PAYMENT_MANAGER
						]}
					>
						<ListItem
							button
							disableGutters
							dense
							onClick={() => props.onChangeLocation('/statistics')}
						>
							<ListItemAvatar>
								<Avatar
									className={classNames(
										classes.iconButton,
										isSelected(13) ? classes.activeIcon : null
									)}
								>
									<Tooltip title='Statistics'>
										 <StorageIcon/> 
									</Tooltip>
								</Avatar>
							</ListItemAvatar>
						</ListItem>
					</Authorization>
				)}
			</List>
		</Paper>
	)
}
SideBar.propTypes = {
	/**
	 * Callback executed when the user clicks in a button
	 */
	onChangeLocation: propTypes.func.isRequired,
	/**
	 * The selected button index
	 */
	selectedButtonIndex: propTypes.number,
	/**
	 * Is the information of the logged in user
	 */
	userProfile: propTypes.shape({
		userName: propTypes.string.isRequired,
		name: propTypes.string.isRequired,
		email: propTypes.string.isRequired,
		avatarUrl: propTypes.string,
		enabled: propTypes.bool.isRequired,
		roles: propTypes.array.isRequired
	})
}
SideBar.defaultProps = {
	onChangeLocation: () => console.warn('No [onChangeLocation] callback defined'),
	selectedButtonIndex: 0,
	userProfile: null
}
export default SideBar
