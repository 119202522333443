import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	inputHolder: {
		width: '100%',
		backgroundColor: 'white',
		borderRadius: '4px 25px 25px 4px',
		display: 'flex',
		border: `0.5px solid ${theme.palette.colors.purple}`,

	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
		backgroundColor: theme.palette.colors.purple,
		color: theme.palette.common.white,
		"&:hover": {
			backgroundColor: theme.palette.colors.purple,
			opacity:.75,
			color: theme.palette.common.white,
		},
	},
	divider: {
		height: 28,
		margin: 4
	}
}))

/**
 * Search toolbox
 */
const SearchToolbox = (props) => {
	const classes = useStyles()
	const [value, setValue] = useState(props.value || '')

	/**
	 * Update the value when the props value change
	 */
	useEffect(() => {
		if (props.value) {
			setValue(props.value)
		}
	}, [props.value])

	/**
	 * On submit callback
	 */
	const onSubmit = (event) => {
		event.preventDefault()
		props.onChange(value)
	}

	return (
		<div className={classes.root}>
			<Box
				component='form'
				className={classes.inputHolder}
				onSubmit={onSubmit}
			>
				<InputBase
					className={classes.input}
					placeholder={props.placeholder}
					inputProps={{ 'aria-label': 'search' }}
					value={value}
					onChange={(event) => setValue(event.target.value)}
				/>
				<IconButton
					type='submit'
					className={classes.iconButton}
					aria-label='search'
				>
					<SearchIcon />
				</IconButton>
			</Box>
		</div>
	)
}
SearchToolbox.propTypes = {
	/**
	 * The placeholder shown in the search box
	 */
	placeholder: propTypes.string,
	/**
	 * Callback executed when the user press search
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial searchbox value
	 */
	value: propTypes.string
}
SearchToolbox.defaultProps = {
	onChange: () => console.warn('No [onChangeValue] callback defined')
}
export default SearchToolbox
