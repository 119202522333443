
import { fromJS } from 'immutable';
import * as parserSeriesAction from './ParserSeriesSapActions';

let initialState = fromJS({
    
    isLoading: false,
    isSaving: false,
    items: [],
    pagination: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        orderBy: "code",
        orderType: "asc",
    },
    isChecked: undefined,
})

/**
 * parserSeriesReducer reducer
 */
const parserSeriesReducer = (state = initialState, action) => {
    switch (action.type) {

        case parserSeriesAction.TOGGLE_SWITCH: 
            return state.merge({
                isChecked: action.isChecked
            })
        

        case parserSeriesAction.IS_LOADING_WARRANTY_SERIES_SAP:
            return state.merge({
                isLoading: action.status
            })
        
        
        case parserSeriesAction.SET_ITEMS: {
            return state.merge({
                items: fromJS(action.data.filteredSeriesSAP).set('isLoading', false),
                pagination: fromJS({
                    pageNumber: action.data.pageNumber,
                    pageSize: action.data.pageSize,
                    total: action.data.total,
                    orderBy: action.data.orderBy,
                    orderType: action.data.orderType,
                })
            })
        }
        
        case parserSeriesAction.IS_SAVING:
            return state.merge({
                isSaving: action.status
            })
        
        default:
            return state
    }
}

export default parserSeriesReducer

