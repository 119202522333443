import React from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'

/** Import components */
import { Button, Typography } from '@material-ui/core'
import { oneColumnStyles as useStyles } from '../../resources/styles/LayoutStyles'
import { unauthorizedUseStyles } from '../../resources/styles/ErrorViewStyles'
import { getUserManager } from '../../store/helpers/UserManager'

/** Import resources */
import logo from '../../resources/images/isplogo.png'

/** Import components */
import Header from '../common/ApplicationHeader'
import DesktopView from './components/DesktopView'
import MobileView from './components/MobileView'

/**
 * Unauthorized view
 */
const UnauthorizedView = (props) => {
	const isMobile = useIsMobile(props.isMobile)
	const userManager = getUserManager()
	const classes = useStyles()
	const unAuthClasses = unauthorizedUseStyles()

	const onLogout = () => userManager.signoutRedirect()

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Header
					leftToolbar={
						<div className={unAuthClasses.toolBar}>
							<img
								src={logo}
								className={classes.appLogo}
								alt='isp-logo'
							/>
							<Typography
								color='secondary'
								variant='overline'
								className={unAuthClasses.unauthorizedMessage}
							>
								Unauthorized access
							</Typography>
						</div>
					}
					rightToolbar={
						<Button
							color='secondary'
							variant='outlined'
							onClick={onLogout}
						>
							Logout
						</Button>
					}
				/>
			</div>
			{isMobile ? <MobileView  onLogout={onLogout} /> : <DesktopView onLogout={onLogout} />}
		</div>
	)
}
export default UnauthorizedView
