
const userInProviderConfig = [{
    title: '',
    dataSource: 'avatar',
    width: '*'
},{
    title: 'USER NAME',
    dataSource: 'UserName',
    sortable: true,
},{
    title: 'NAME',
    dataSource: 'Name',
    sortable: true,
},{
    title: 'EMAIL',
    dataSource: 'Email',
    sortable: true,
},{
    title: 'LAST AUTHENTICATION',
    dataSource: 'LastAuthenticationDate',
    sortable: true,
},{
    title: 'OPTIONS',
    dataSource: 'Active',
    width: '*'
}]

export default userInProviderConfig