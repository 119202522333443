import { makeStyles } from '@material-ui/styles'

/**
 * custom lateral panel
 */
export const panelLateralStyles = makeStyles((theme) => ({
    drawerContent: {
        [theme.breakpoints.down('xl')]: {
            width: '420px',
        },
        [theme.breakpoints.down('lg')]: {
            width: '420px',
        },
        [theme.breakpoints.down('md')]: {
            width: '420px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '420px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '300px',
        },
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    panelHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        backgroundColor: "#444054",
    },
    title: {
        flexGrow: 1,
        color: theme.palette.common.white
    },
    grow: {
        flexGrow: 1,
    },
    buttonClose: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.red,
        "&:hover": {
            backgroundColor: theme.palette.colors.red
        },
        width: "37px",
        height: "36px",
        margin: 0,
        marginLeft: theme.spacing(1),

    },
    buttonEdit: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.blue,
        "&:hover": {
            backgroundColor: theme.palette.colors.blue
        },
        width: "37px",
        height: "36px",
        margin: 0,
    },
    buttonSaved: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.green,
        "&:hover": {
            backgroundColor: theme.palette.colors.green
        },
        width: "37px",
        height: "36px",
        margin: 0,
        "&.Mui-disabled": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colors.green,
            opacity: .5,
        },
    },
    iconPanel: {
        width: "4rem",
        height: "4rem",
        margin: '0 auto',
        padding: "5px"
    },
    iconColorGreen: {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.colors.green} !important`,
    },
    iconColorRed: {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.colors.red} !important`,
    },
    contentPanelLsit: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "80vh"
    },
}))

/**
 * custom lateral panel
 */
export const panelLateralNetworDaysStyles = makeStyles((theme) => ({
    drawerContent: {
        width: "380px",
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    panelHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        backgroundColor: "#444054",
        padding: "10px"
    },
    title: {
        flexGrow: 1,
        color: theme.palette.common.white
    },
    containerIcon: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        gridColumn: '1 / 1',
    },
    containerHeader: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
    },
    containerText: {
        gridColumn: '2 / 2',
        padding: "5px"
    },
    subTitle: {
        color: theme.palette.common.white,
        marginTop: '-8px'

    },
    faultWarrantyIconSelected: {
        marginRight: theme.spacing(0.5),
        boxShadow: "0px 0px 0px 0px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
    },
    box: {
        backgroundColor: theme.palette.colors.blueLight
    },
    contentList: {
        maxHeight: `calc(100vh - ${85}px)`,
        overflowY: "auto"
    },
    listSubheaderStyle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }

}))