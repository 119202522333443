/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ConfirmPasswordDialog from '../../common/ConfirmPasswordDialog'
import Toaster from '../../common/Toaster'
import EditLateralPanel from './components/EditLateralPanel'
import UploadImgCropDialog from '../../common/cropDialog/UploadImgCropDialog'

/**Import actions sections */
import {
    saveUser,
    uploadLogoUser
} from '../../../store/users/UsersActions'
const Edit = (props) => {
    /**Hooks sections */
    const dispatch = useDispatch()
    const { passwordDialogConfiguration, isValidatingPassword, isSaving, onSetPasswordDialogConfiguration, onRemoveUserFromAccount, isEnable } = props;
    /**Function section*/
    const { lateralPanelEditConfig, openLateralPanelEditOnClick, onToggleUserRole, onActiveNotifications } = props;
    /**Local store from this component */
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [logoFileBase64, setLogoFileBase64] = useState("");
    const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false);

    /**
 * Handler to edit an user.
 * Execute async action to edit an user.
 * @param {object} user
 */
    const editUserHandler = user => {
        dispatch(saveUser(user))
            .then(() => {
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error updating user information", variant: 'error' })
            });
    };

    /**Upload image crop */
    const onUploadLogoUser = (imageBase64) => {
        let userId = lateralPanelEditConfig && lateralPanelEditConfig.user.Id;
        return dispatch(uploadLogoUser(userId, imageBase64))
            .then(() => {
                onSetHandleOpenCloseModalUploadImg();
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "An error was generated while updating the logo", variant: 'error' })
                console.error("Error to upload image crop");
            });
    };

    /**Set img base 64 in the local store */
    const onSetLogoFileBase64 = (fileBase64) => {
        setLogoFileBase64(fileBase64);
    };

    /**Open/close dialog modal for upload logo user */
    const onSetHandleOpenCloseModalUploadImg = () => {
        setHandleOpenModalUploadImg(!handleOpenModalUploadImg);
    };


    return (
        <>
            {passwordDialogConfiguration.opened && <ConfirmPasswordDialog
                title={'Confirm operation'}
                open={passwordDialogConfiguration.opened}
                onClickButtonConfirm={onRemoveUserFromAccount}
                isSaving={isValidatingPassword || isSaving}
                onClose={onSetPasswordDialogConfiguration}
                message1={`This action will remove the access of ${passwordDialogConfiguration.user ? passwordDialogConfiguration.user.Name : ''} to this portal. Are you sure to proceed?`}
                message2="Enter the password to confirm the operation"
            />}
            {
                lateralPanelEditConfig.opened &&
                <EditLateralPanel
                    onSaveButtonClick={editUserHandler}
                    onCloseButtonClick={openLateralPanelEditOnClick}
                    isSaving={isSaving}
                    user={lateralPanelEditConfig.user}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onToggleUserRole={onToggleUserRole}
                    onActiveNotifications={onActiveNotifications}
                    isEnable={isEnable}
                />
            }
            {
                <UploadImgCropDialog
                    open={handleOpenModalUploadImg}
                    onClose={onSetHandleOpenCloseModalUploadImg}
                    fileBase64={logoFileBase64}
                    isSaving={isSaving}
                    onUploadImagen={onUploadLogoUser}

                />
            }
            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

Edit.propTypes = {
    /**
     * Determine if the isSaving data in database
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Determine if is validating password user loggued when removing user from account
     */
    isValidatingPassword: propTypes.bool.isRequired,
    /**
     * Open modal for emove an user fron this list user for company IMBERA
     */
    onSetPasswordDialogConfiguration: propTypes.func.isRequired,

    /**
     * This function remove user from account IMB 
     */
    onRemoveUserFromAccount: propTypes.func.isRequired,

    /**Configuration for open dialog for remove user for account */
    passwordDialogConfiguration: propTypes.shape({
        opened: propTypes.bool,
        user: propTypes.object
    }),

    /**
     * Determines the side panel settings, to determine the user information being edited, and whether the panel is open. 
     */
    lateralPanelEditConfig: propTypes.shape({
        opened: propTypes.bool,
        user: propTypes.object
    }),
    /**
 * Opens side panel to edit a user's information
 */
    openLateralPanelEditOnClick: propTypes.func.isRequired,
    /**Add and remove the roles for users imbera */
    onToggleUserRole: propTypes.func.isRequired,


}
Edit.defaultProps = {
    isSaving: false,
    onSetPasswordDialogConfiguration: () =>
        console.warn('Callback [onSetPasswordDialogConfiguration] no defined'),
    onRemoveUserFromAccount: () =>
        console.warn('Callback [onRemoveUserFromAccount] no defined'),
    passwordDialogConfiguration: { opened: false, user: null },
    lateralPanelEditConfig: { opened: false, user: null },
    openLateralPanelEditOnClick: () =>
        console.warn('Callback [openLateralPanelEditOnClick] no defined'),
    onToggleUserRole: () =>
        console.warn('Callback [onToggleUserRole] no defined'),
}

export default Edit
