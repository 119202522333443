import React from 'react'
import { withRouter } from 'react-router-dom'
import { CallbackComponent } from 'redux-oidc'
import { getUserManager } from '../../store/helpers/UserManager'

/**
 * Callback page for identity authentication
 */
const CallbackPage = (props) => {
	const userManager = getUserManager()
	
	const redirectToHome = () => {
		props.history.push('/')
	}

	return (
		<CallbackComponent
			userManager={userManager}
			successCallback={redirectToHome}
			errorCallback={(error) => {
				console.error('CallbackPage: ERROR: ', error)
			}}
		>
			<div>Getting user's profile...</div>
		</CallbackComponent>
	)
}

export default withRouter(CallbackPage)
