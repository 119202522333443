/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import moment from 'moment'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import ShowViewSekeleton from '../../skeletons/paymentRequests/ShowViewSekeleton'
import PaymentHeaderComponent from './PaymentHeaderComponent'
import EvidenceComponent from './EvidenceComponent'
import HistorialComponent from './HistorialComponent'
import TextBox from '../../common/TextBox'

/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useStyles'
import { GetColorPaymentRequestByStatus, GetIconAmount } from '../../../store/helpers/StatusHelper'
import { toolbarStyles } from '../../../resources/styles/ToolbarStyles'
import { currencyFormat } from '../../../store/helpers/AmountHelper'
import { canBeUploadFiles } from '../../../store/helpers/PaymentRequest'
/**Import Material Ui section section */
import { Typography, DialogContent, CardContent, CardHeader } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import PaymentsIcon from '@material-ui/icons/AttachMoney'
import PaymentSummaryIcon from '@material-ui/icons/CreditCard'
import NotesIcon from '@material-ui/icons/SpeakerNotes'
import PersonIcon from '@material-ui/icons/Person'
import CalendarIcon from '@material-ui/icons/CalendarToday'

/** Import material UI */
import {
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Grid,
	Tab,
	AppBar,
	Paper,
	Tabs,
	Fab
} from '@material-ui/core'

import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	SERVICE_PROVIDER_MANAGER,
	PORTAL_ADMINISTRATOR,
	PAYMENT_MANAGER
} from '../../../resources/RolesEnum'

/**
 * View create desktop
 */
const DesktopView = (props) => {
	const {
		isLoading,
		userProfile,
		paymentRequest,
		isSavingService,
		onUpdateAmountInService,
		onShowWarranty,
		onCommentsServiceChange,
		openCloseDialogPassword,
		onUpdateSerieInService
	} = props
	const classes = useCreateDesktopStyle()
	const toolbarStylesClasses = toolbarStyles()
	const [status, setStatus] = useState(paymentRequest)

	const colorByStatus = status && GetColorPaymentRequestByStatus(status, toolbarStylesClasses)
	let isAuthorizerCanEdit = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER])
	let isValidatorCanEdit = hasAuthorization(userProfile, [PORTAL_ADMINISTRATOR])
	let isPaymentRequestAuthorizerCanEdit = hasAuthorization(userProfile, [PAYMENT_MANAGER])

	/// Get status from paymen request
	useEffect(() => {
		if (!!props.paymentRequest && props.paymentRequest.Status) {
			setStatus(props.paymentRequest.Status)
		}
	}, [props.paymentRequest])

	/** add service in payment */
	const onServiceSelected = (service) => {
		if (props.addServiceInPayment) props.addServiceInPayment(service)
	}

	/*Function to update amount in item list  */
	const updateAmount = (serviceToken, amountToken, value) => {
		if (onUpdateAmountInService) {
			onUpdateAmountInService(serviceToken, amountToken, value)
		}
	}

	/**
	 * Remove file added of state
	 * @param {*} fileId
	 */
	const onRemoveFile = (fileName) => {
		if (fileName && props.onRemoveFilesSupplier) {
			props.onRemoveFilesSupplier(fileName)
		}
	}

	const onAddFilesReceived = (files) => {
		if (files.length > 0 && props.onAddFilesBySupplier) {
			props.onAddFilesBySupplier(files)
		}
	}

	/**
	 * Remove file in plant
	 * @param {*} fileId
	 */
	const onRemoveFileInPlant = (fileName) => {
		if (fileName && props.onRemoveFilesInPlant) {
			props.onRemoveFilesInPlant(fileName)
		}
	}

	const onAddFileInPlant = (files) => {
		if (files.length > 0 && props.onAddFilesByPlant) {
			props.onAddFilesByPlant(files)
		}
	}

	const updateCommentsServiceChange = (serviceToken, comments) => {
		if (onCommentsServiceChange) {
			onCommentsServiceChange(serviceToken, comments)
		}
	}

	const renderContent = () => {
		if (isLoading) {
			return <ShowViewSekeleton />
		} else {
			return (
				<div className={classes.container}>
					<PaymentHeaderComponent
						status={status}
						userProfile={userProfile}
						colorByStatus={colorByStatus}
						paymentRequest={paymentRequest ? paymentRequest : undefined}
						openLateralPanelChangeStatus={props.openLateralPanelChangeStatus}
						isAuthorizerCanEdit={isAuthorizerCanEdit}
						isPaymentRequestAuthorizerCanEdit={isPaymentRequestAuthorizerCanEdit}
						isSavingService={isSavingService}
						closedServices={props.closedServices}
						updateAmount={updateAmount}
						onServiceSelected={onServiceSelected}
						onShowWarranty={onShowWarranty}
						onCommentsServiceChange={updateCommentsServiceChange}
						openCloseDialogPassword={openCloseDialogPassword}
						onUpdateSerieInService={onUpdateSerieInService}
					/>
					<br />
					<TabsView
						paymentRequest={paymentRequest ? paymentRequest : undefined}
						isAuthorizerCanEdit={isAuthorizerCanEdit}
						onAddFilesReceived={onAddFilesReceived}
						onRemoveFile={onRemoveFile}
						isValidatorCanEdit={isValidatorCanEdit}
						onAddFilesInPlant={onAddFileInPlant}
						onRemoveFileInPlant={onRemoveFileInPlant}
						userProfile={userProfile}
					/>
				</div>
			)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Payment Request / View Request</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={6}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>{renderContent()}</div>
			</Content>
		</Layout>
	)
}
DesktopView.propTypes = {
	onChangeLocation: propTypes.func.isRequired,
	openLateralPanelChangeStatus: propTypes.func.isRequired,
	/**Function invoke callback for show warranty for equipment */
	onShowWarranty: propTypes.func.isRequired
}
DesktopView.defaultProps = {
	onChangeLocation: () => console.warn('Callback [onChangeLocation] no defined'),
	openLateralPanelChangeStatus: () =>
		console.warn('Callback [openLateralPanelChangeStatus] no defined'),
	onShowWarranty: () => console.warn('Callback [onShowWarranty] no defined')
}

/**
 * Tabs view container
 */
const TabsView = (props) => {
	const [visiblePanelIndex, setVisiblePanelIndex] = useState(0)
	const {
		paymentRequest,
		isAuthorizerCanEdit,
		onAddFilesReceived,
		onRemoveFile,
		isValidatorCanEdit,
		onAddFilesInPlant,
		onRemoveFileInPlant
	} = props

	return (
		<Paper>
			<AppBar position='static'>
				<Tabs
					value={visiblePanelIndex}
					onChange={(event, index) => setVisiblePanelIndex(index)}
				>
					<Tab label='SERVICE AMOUNTS' />
					<Tab label='Evidences' />
					{paymentRequest.Workflows.length !== 0 && <Tab label='History' />}
					{paymentRequest.PaymentSummary && <Tab label='Payment Summary' />}
				</Tabs>
			</AppBar>
			<TabPanel value={visiblePanelIndex} index={0}>
				<DialogContent>
					<TotalAmountComponent
						TotalAmount={paymentRequest.TotalAmount}
						Comments={paymentRequest.Comments}
						InvoiceFolio={paymentRequest.InvoiceFolio}
					/>
				</DialogContent>
			</TabPanel>
			<TabPanel value={visiblePanelIndex} index={1}>
				<DialogContent>
					<EvidenceComponent
						FilesProvider={paymentRequest.FilesProvider}
						onAddFilesReceived={onAddFilesReceived}
						onRemoveFile={onRemoveFile}
						canBeEditProvider={canBeUploadFiles(paymentRequest) && isAuthorizerCanEdit}
						Files={paymentRequest.Files}
						canBeEditInPlant={canBeUploadFiles(paymentRequest) && isValidatorCanEdit}
						canBeUploadFiles
						onAddFilesInPlant={onAddFilesInPlant}
						onRemoveFileInPlant={onRemoveFileInPlant}
					/>
				</DialogContent>
			</TabPanel>
			{paymentRequest.Workflows && paymentRequest.Workflows.length > 0 && (
				<TabPanel value={visiblePanelIndex} index={2}>
					<DialogContent>
						<HistorialComponent Workflows={paymentRequest.Workflows} />
					</DialogContent>
				</TabPanel>
			)}
			{paymentRequest.PaymentSummary && (
				<TabPanel
					value={visiblePanelIndex}
					index={paymentRequest.Workflows && paymentRequest.Workflows.length > 0 ? 3 : 2}
				>
					<DialogContent>
						<PaymentSummary PaymentSummary={paymentRequest.PaymentSummary} />
					</DialogContent>
				</TabPanel>
			)}
		</Paper>
	)
}
TabsView.propTypes = {
	onAddFilesReceived: propTypes.func,
	onRemoveFile: propTypes.func
}
TabsView.defaultProps = {
	paymentRequest: {
		Folio: '',
		TotalAmount: {
			LaboralTotal: 0.0,
			MaterialTotal: 0.0,
			MileageTotal: 0.0,
			MiscellaneousTotal: 0.0,
			SalesTotal: 0.0,
			Total: 0.0
		},
		Comments: '',
		CanBeEditedProvider: false,
		FilesProvider: [],
		Workflows: []
	}
}

/**
 * Component to add header form amount
 * @param {*} props
 */
const TotalAmountComponent = (props) => {
	const classes = useCreateDesktopStyle()
	const { TotalAmount, Comments, InvoiceFolio } = props

	/**
	 * Render list amount
	 * @param {*} amount
	 * @param {*} title
	 * @param {*} description
	 */
	const renderItem = (amount, title, description) => {
		return (
			<ListItem divider>
				<ListItemAvatar>
					<Fab size='small' className={classes.amountIconSelected} disabled={true}>
						<img
							src={GetIconAmount(title)}
							alt='warranty-icon'
							className={classes.amountIcon}
						/>
					</Fab>
				</ListItemAvatar>
				<ListItemText primary={title} secondary={description} />
				<ListItemSecondaryAction>{`${currencyFormat(amount)}`}</ListItemSecondaryAction>
			</ListItem>
		)
	}

	return (
		<Grid item xs={12}>
			<List>
				{InvoiceFolio && (
					<ListItem divider>
						<ListItemText
							primary={
								<Typography className={classes.title}>
									<strong>{'INVOICE:'}</strong>
									{InvoiceFolio}
								</Typography>
							}
						/>
					</ListItem>
				)}
				<ListItem divider>
					<ListItemText
						primary={
							<Typography className={classes.title}>
								<strong>{'SERVICE AMOUNTS'}</strong>
							</Typography>
						}
					/>
				</ListItem>
				{renderItem(TotalAmount.LaboralTotal, 'LABOR', 'Labor costs total (USD)')}
				{renderItem(
					TotalAmount.MaterialTotal,
					'MATERIALS',
					'Cost total of materials used (USD)'
				)}
				{renderItem(
					TotalAmount.MileageTotal,
					'MILEAGE',
					'Mileage costs incurred total (USD)'
				)}
				{renderItem(
					TotalAmount.MiscellaneousTotal,
					'MISCELLANEOUS',
					' Additional costs incurred not related to Labor, Materials and Mileage (USD)'
				)}
				{renderItem(
					TotalAmount.SalesTotal,
					'SALES TAX',
					'HST only for Canada service partners (USD)'
				)}
				{renderItem(TotalAmount.Total, 'TOTAL', 'The total amount of costs (USD)')}
			</List>
			<br />
			<TextBox
				key={'Comments'}
				active={true}
				disabled={true}
				fullWidth
				multiline={true}
				name={'Comments'}
				rows={4}
				variant='outlined'
				margin='dense'
				label={'COMMENTS'}
				onChange={props.onPropertyChange}
				value={Comments}
			/>
		</Grid>
	)
}

TotalAmountComponent.propTypes = {
	TotalAmount: propTypes.shape({
		LaboralTotal: propTypes.number.isRequired,
		MaterialTotal: propTypes.number.isRequired,
		MileageTotal: propTypes.number.isRequired,
		MiscellaneousTotal: propTypes.number.isRequired,
		SalesTotal: propTypes.number.isRequired,
		Total: propTypes.number.isRequired
	})
}
TotalAmountComponent.defaultProps = {
	TotalAmount: {
		LaboralTotal: 0.0,
		MaterialTotal: 0.0,
		MileageTotal: 0.0,
		MiscellaneousTotal: 0.0,
		SalesTotal: 0.0,
		Total: 0.0
	}
}

/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}
TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired
}

/**
 * Component Payment Summary
 */
const PaymentSummary = (props) => {
	const classes = useCreateDesktopStyle()
	const { PaymentSummary } = props
	return (
		<CardContent>
			<CardHeader
				style={{ paddingBottom: '0px', paddingTop: '5px', backgroundColor: '#e8eff2f6' }}
				title={
					<Typography
						gutterBottom
						variant='subtitle1'
						component='span'
						color='textPrimary'
					>
						<PaymentSummaryIcon color='primary' className={classes.inconMargin} />
						{'PAYMENT SUMMARY'}
					</Typography>
				}
			/>
			<CardHeader
				title={
					<Typography gutterBottom variant='body2' component='p'>
						<CalendarIcon color='secondary' className={classes.inconMargin} />
						{` Date: ${moment(PaymentSummary.CreationDate).format(
							'MMMM Do YYYY, h:mm:ss a'
						)}`}
					</Typography>
				}
				subheader={
					<>
						<Typography gutterBottom variant='body2' component='p'>
							<PersonIcon color='secondary' className={classes.inconMargin} />{' '}
							{PaymentSummary.CreatorName}
						</Typography>
						<Typography gutterBottom variant='body2' component='p'>
							<PaymentsIcon color='secondary' className={classes.inconMargin} />{' '}
							{` Check Folio: ${PaymentSummary.CheckFolio}`}
						</Typography>
						<Typography gutterBottom variant='body2' component='p'>
							<PaymentSummaryIcon color='secondary' className={classes.inconMargin} />{' '}
							{`Total : ${currencyFormat(PaymentSummary.TotalAmount)}`}
						</Typography>
						<Alert
							variant='outlined'
							severity='info'
							icon={<NotesIcon fontSize='inherit' />}
						>
							<AlertTitle>Notes</AlertTitle>
							{`${PaymentSummary.Notes}`}
						</Alert>
					</>
				}
			/>
		</CardContent>
	)
}

PaymentSummary.propTypes = {
	PaymentSummary: propTypes.shape({})
}
PaymentSummary.defaultProps = {
	PaymentSummary: {}
}

export default DesktopView
