/**Import react section */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4'

/** Material UI Section*/
import {
    makeStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Grid,
    LinearProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

/** Custom component import section */
import DropDownZone from '../../common/DropDownZone';
import FileContent from '../../common/FileContent';
import TextBox from '../../common/TextBox'
import AlertComponent from '../../common/AlertComponent'

const DialogEvidence = (props) => {
    const classes = useStyles();
    const [pdfFile, setPdfFile] = useState([]);
    const [evidenceModel, setEvidenceModel] = useState(null)

    useEffect(() => {
        setEvidenceModel({
            "Comments": props.Comments ? props.Comments:""
        })
    }, [props.Comments])

    const canBeSaved = () => {
        if (pdfFile.length > 0) return false;
        return true;
    }

    /**
     * Remove file added of state 
     * @param {*} fileId 
     */
    const onRemoveFile = (fileId) => {
        let index = pdfFile.findIndex(attachment => attachment.id === fileId);
        if (index !== -1) {
            let newListFiles = pdfFile.filter(file => {
                return file.id !== fileId
            })
            setPdfFile(newListFiles)
        }
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setEvidenceModel({
            ...evidenceModel,
            [name]: value
        });
    }

    /**
     * Save information in back end
     */
    const onSaveButtonClick = () => {
        if (props.onSaveEvidence) {
            props.onSaveEvidence(pdfFile, evidenceModel);
        }
    }

    /**Exist document add with name */
    const onExisteDocumentWithSameName = (fileName) => {
        let existDocument = pdfFile.find(u => u.fileName === fileName);
        return existDocument ? true : false;
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="button" className={classes.title}><strong>{props.title}</strong></Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {props.existTerms && <Grid item xs={12}>
                        <AlertComponent
                            title="Update Terms and Conditions"
                            severityType="warning"
                            message={"Sure you want to update the document of terms and conditions, once updated the provider will see the updated document in the portal."}
                        />
                    </Grid>}
                    {!props.existTerms && <Grid item xs={12}>
                        <AlertComponent
                            title="Add terms and conditions"
                            severityType="info"
                            message={"Add terms and conditions of the portal, when adding them the provider must accept the terms when sending the invitation to navigate to the portal."}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <TextBox
                            active={true}
                            fullWidth
                            autoFocus
                            multiline
                            rows={2}
                            name="Comments"
                            variant="outlined"
                            margin="dense"
                            label="NOTES"
                            onChange={onPropertyChange}
                            value={evidenceModel ? evidenceModel.Comments : ""}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <DropDownZone
                            datacy='drop-zone-documents-operating'
                            classes={classes}
                            accept= {props.accept}
                            multiple={props.isMultiple? props.isMultiple:true}
                            message={"Click here to select files"}
                            onFilesReceived={(files) => {
                                if (files.length > 0) {
                                    const filesAdded = files.map((file) => {
                                        let existFile = onExisteDocumentWithSameName(file.name);
                                        return ({
                                            id: uuidv4(),
                                            attachment: file,
                                            existFile: existFile
                                        })
                                    })
                                    setPdfFile(pdfFile.concat(filesAdded))
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        {
                            pdfFile.length > 0 &&
                            <FileContent
                                files={pdfFile}
                                onRemoveFile={onRemoveFile}
                            />
                        }
                    </Grid>

                </Grid>
            </DialogContent>
            {props.isSaving && <LinearProgress color="secondary" />}
            <DialogActions>
                <div className={classes.wrapper}>
                    <Button
                        data-cy="btn-accept"
                        className={classes.buttonAccept}
                        variant='contained'
                        onClick={onSaveButtonClick}
                        disabled={canBeSaved() || props.isSaving}
                    >
                        {props.existTerms && "Confirm update terms"}
                        {!props.existTerms && "Confirm add terms"}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

DialogEvidence.propTypes = {
    onSaveEvidence: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,

}
DialogEvidence.defaultProps = {
    open: false,
    isSaving: false
}

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.colors.purple
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonAccept: {
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        right: "10px",
        "&:hover": {
            backgroundColor: theme.palette.colors.green
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
            opacity: .75,
        }
    },
    buttonProgress: {
        color: theme.palette.prim,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    closeButton: {
        position: 'absolute',
        right: "22px",
        top: theme.spacing(1),
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.red
        },
        width: "37px",
        height: "36px",
        paddingTop: 8
    },
}));


export default DialogEvidence
