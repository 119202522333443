import { fromJS } from 'immutable'
import {
	EXECUTING_LOGOUT,
	IS_GETTING_PROFILE,
	SET_USER_PROFILE
} from './sessionActions'

/** Define the initial session state */
let initialSessionState = fromJS({
	isExecutingLogout: false,
	isGettingProfile: false,
	profile: null
})

/**
 * Session reducer
 */
const sessionReducer = (state = initialSessionState, action) => {
	switch (action.type) {
		case EXECUTING_LOGOUT:
			return state.merge({
				isExecutingLogout: true
			})
		case IS_GETTING_PROFILE:
			return state.merge({
				isGettingProfile: action.value
			})
		case SET_USER_PROFILE:
			return state.merge({
				isGettingProfile: false,
				profile: fromJS(action.profile)
			})
		default: {
			return state
		}
	}
}
export default sessionReducer
