import { isNullOrEmpty } from './StringHelper'

/**
 * Get the absolute url address
 *
 * @param {string} url
 */
export const getAbsoluteUrlAddress = (url) => {
	let configuration = window.config

	let baseUrl = `${window.location.protocol}//${window.location.hostname}${
		window.location.port ? `:${window.location.port}` : ''
	}`
	if (isNullOrEmpty(url)) {
		return configuration.applicationRoot  === '/'
			? `${baseUrl}`
			: `${baseUrl}${configuration.applicationRoot}/${url}`
	} else {
		return configuration.applicationRoot === '/'
			? `${baseUrl}/${url}`
			: `${baseUrl}${configuration.applicationRoot}/${url}`
	}
}
