/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/**Import components section */
import AlertComponent from '../../../common/AlertComponent'
import { desktopViewRegisterStyles } from '../../resources/DesktopViewRegisterStyles'
import ButtonSaved from '../../../common/Buttons/ButtonSaved'
import ButtonCancel from '../../../common/Buttons/ButtonCancel'
import Authorization from '../../../common/AuthorizationEvaluation'

import { PROVIDER_REGISTRATION_VALIDATOR } from '../../../../resources/RolesEnum'
import { ServiceProviderStatus } from '../../../../store/helpers/AppConstants'

const ValidateInformation = (props) => {
    const classes = desktopViewRegisterStyles();
    const { isSaving, onOpenDialogForGrantedAccess, status, onRedirectToServiceProvider, userProfile, serviceProvider } = props;
    let dateValidation = `The documents have been validated in the plant ${serviceProvider.TermsValidationDate} correctly.`

    return (
        <>
            {userProfile.Account.RequieredTermsAccepted && <AlertComponent
                title={"Waiting to validate information"}
                message={"Congratulations, you have finished the registration process, wait for the administrator to validate your data and grant you permissions to enter the portal."}
                severityType={"success"}

            />}
            {userProfile.Account.IsAccountImbera && status === ServiceProviderStatus.SUPPLIER_ACCEPTED && <AlertComponent
                title={"Validate information"}
                message={"In order for the provider to enter the portal, they must validate the requested documents. The provider has accepted the terms and conditions."}
                severityType={"info"}

            />}
             {status === ServiceProviderStatus.ACCEPTED_IN_COMPANY && <AlertComponent
                title={"Documents Validate"}
                message={dateValidation}
                severityType={"success"}

            />}
            <br />
            <div className={classes.actionButtonGrandAccess}>
                <Authorization
                    profile={props.userProfile}
                    allowedRoles={[PROVIDER_REGISTRATION_VALIDATOR]}
                >
                    {
                        status === ServiceProviderStatus.SUPPLIER_ACCEPTED && <>
                            <ButtonCancel
                                title='REJECTED'
                                isSaving={isSaving}
                                onCancelClick={() => onOpenDialogForGrantedAccess(false)}
                            />
                            <ButtonSaved
                                title='ACCEPTED'
                                isSaving={isSaving}
                                onSavedClick={() => onOpenDialogForGrantedAccess(true)}
                            />
                        </>
                    }

                    {  
                        props.CanBeValidateOtherFiles && status === ServiceProviderStatus.ACCEPTED_IN_COMPANY && <>
                            <ButtonSaved
                                title='ACCEPTED'
                                isSaving={isSaving}
                                onSavedClick={() => onOpenDialogForGrantedAccess(true)}
                            />
                        </>
                    }

                    {
                         (status === ServiceProviderStatus.REJECTED_IN_COMPANY || status === ServiceProviderStatus.ACCEPTED_IN_COMPANY ) &&  !props.CanBeValidateOtherFiles &&
                         <ButtonSaved
                            title='BACK'
                            isSaving={isSaving}
                            onSavedClick={onRedirectToServiceProvider}
                        />
                    }

                </Authorization>
            </div>
        </>
    )
}

/**Proptypes this component */
ValidateInformation.propTypes = {
    /**
     * Invoke confirm passwork operation
     */
    onOpenDialogForGrantedAccess: propTypes.func.isRequired,
    /**
    * Determinate is saving information in database
    */
    isSaving: propTypes.bool.isRequired,
}


ValidateInformation.defaultProps = {
    onOpenDialogForGrantedAccess: () =>
        console.warn('Callback [onOpenDialogForGrantedAccess] no defined'),
    isSaving: false,
}

export default ValidateInformation
