import React from 'react';
import propTypes from 'prop-types';

/** Material-UI  section */
import { makeStyles, withStyles } from '@material-ui/styles';
import {
    Stepper,
    Step,
    StepLabel,
    StepIcon,
    StepConnector,
    Typography
} from '@material-ui/core';


/**
 * Stepper component
 * @param {*} props
 */
const AssistanceStepper = (props) => {
    const classes = useStyle();

    return (
        <Stepper className={classes.stepper} activeStep={props.activeStep}
            alternativeLabel
            connector={<ColorLibConnector />}>

            <Step className={classes.stepper} key={1}>
                <StepLabel StepIconComponent={IconComponent}>
                    <Typography noWrap className={classes.stepLabel}>
                        Terms and Conditions
					</Typography>

                </StepLabel>
            </Step>

            <Step key={2} style={{ width: '10px' }}>
                <StepLabel StepIconComponent={IconComponent}>
                    <Typography className={classes.stepLabel}>
                        Attachments
					</Typography>
                </StepLabel>
            </Step>

            <Step key={3} style={{ width: '10px' }}>
                <StepLabel StepIconComponent={IconComponent}>
                    <Typography className={classes.stepLabel}>
                        Waiting to validate information
					</Typography>
                </StepLabel>
            </Step>
        </Stepper>
    );
};


AssistanceStepper.propTypes = {
    activeStep: propTypes.number.isRequired
};


AssistanceStepper.defaultProps = {
    activeStep: 0
};

const IconComponent = withStyles((theme) => ({
    root: {
        color: "#EBEEF7",
        width: 40,
        height: 40,
        '&$active': {
            color: "#6369D7",

        },
        '&$completed': {
            color: "#6369D7"
        }
    },
    active: {},
    completed: {}
}))(StepIcon);

const ColorLibConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundColor: "#6369D7"
        }
    },
    completed: {
        '& $line': {
            backgroundColor: "#464A97"
        }
    },
    line: {
        height: 10,
        border: 1,
        backgroundColor: "#EBEEF7",
        borderRadius: 0
    }
}))(StepConnector);

const useStyle = makeStyles(() => ({
    stepper: {
        alignSelf: 'stretch',
        "&.MuiStepper-root": {
            padding: "10px !important"
        },
    },
    stepLabel: {
        fontSize: '10px',
        color: "rgb(118, 118, 118)"
    }
}));

export default AssistanceStepper;
