import { fromJS } from 'immutable'
import  * as paymentRequests from '../paymentrequests/paymentRequestActions'

const initialPaymentRequeststate = fromJS({
	isLoadingPayments: false,
    index: {
		paymentRequests: [],
		pagination: {
			pageNumber: 1,
			pageSize: 10,
			total: 0,
			orderBy: "Folio",
			orderType: "desc",
		},
	},
	isLoadingDashboard: false,
	dashboard: null,
	isSavingPaymentRequest:false,
	isLoadingRequest: false,
	paymentRequest: null,
	isSavingService: false,
	isLoadingSummary: false,
	summaryRequests: [],
	isSavingSummary: false,
	paymentSummary: null,
	isDownloadReport: false,
})

/**
 * Payment request reducer
 */
const paymentRequestReducer = (state = initialPaymentRequeststate, action) => {
	switch (action.type) {
		case paymentRequests.IS_LOADING_PAYMENT_REQUESTS: 
			return state.merge({
				isLoadingPayments: action.status
			})
		case paymentRequests.SET_PAYMENT_REQUESTS:
            let actualIndex = state.get('index').toJS()
			let updatedIndex = {
				...actualIndex,
				paymentRequests: action.paymentRequests.paymentRequests,
				pagination: action.paymentRequests.pagination,
			}
            return state.setIn(['index'], fromJS(updatedIndex))
		case paymentRequests.IS_LOADING_DASHBOAR_PAYMENT: 
			return state.merge({
				isLoadingDashboard: action.status
			})
		case paymentRequests.SET_DASHBOAR_PAYMENTS:
			return state.merge({
				isLoadingDashboard: false,
				dashboard: fromJS(action.dashboard)
			})
		case paymentRequests.IS_SAVING_PAYMENT_REQUEST:{
			return state.merge({
				isSavingPaymentRequest: action.status
			})
		}
		case paymentRequests.IS_LOADING_PAYMENT_REQUEST:
			return state.merge({
				isLoadingRequest: action.status
			})
		case paymentRequests.SET_PAYMENT_REQUEST_OPEN:{
			return state.merge({
				isLoadingRequest: false,
				paymentRequest: fromJS(action.paymentRequest)
			})
		}
		case paymentRequests.IS_SAVING_PAYMENT:{
			return state.merge({
				isSavingService: action.status
			})
		}
		case paymentRequests.ADD_SERVICE_IN_PAYMENT:{
			let service = fromJS(action.service)
			return state.updateIn(['paymentRequest', "Services"], (actions) => actions.push(service))
				.set('isSavingService', false);
		}
		case paymentRequests.REMOVE_SERVICE_IN_PAYMENT:{
            return state.updateIn(['paymentRequest', "Services"], allServices => allServices.filter(service => service.get('Token') !== action.serviceToken))
			.set('isSavingService', false);
		}
		case paymentRequests.UPDATE_SERVICE_IN_PAYMENT:{
            return state.updateIn(['paymentRequest', 'Services'], (allServices) => {
                let index = allServices.findIndex((service) => {
                    return service.get('Token') === action.serviceToken
				});

                let serviceToChange = allServices.get(index);
                let updatedQuestionList = serviceToChange.get('ServiceAmounts').map((item) => {
                    if (item.get('Token') === action.amount.Token) {
                        return fromJS(action.amount);
                    } else {
                        return item;
                    }
				})

				let updatedService = serviceToChange.set('ServiceAmounts', updatedQuestionList);
				let totalPercentage = updatedService.get('ServiceAmounts').reduce((sum, section) => sum+section.get("Amount"), 0)
				let dupdatedService = updatedService.set("TotalAmounts", totalPercentage)
                return allServices.setIn([index], dupdatedService);
            });
		}
		case paymentRequests.UPDATE_TOTAL_AMOUNT:{
			let totalMount = action.amount.TotalAmount;
            return state.setIn(['paymentRequest', "TotalAmount"], fromJS(totalMount));
		}
		case paymentRequests.ADD_FILES_PROVIDER:{
			let files = fromJS(action.evidences);
			return state.setIn(['paymentRequest', "FilesProvider"], files);
		}
		case paymentRequests.REMOVE_FILES_PROVIDER:{
            return state.updateIn(['paymentRequest', "FilesProvider"], files => files.filter(file => file.get('name') !== action.fileName));
		}
		case paymentRequests.ADD_FILES_PLANT:{
			let files = fromJS(action.evidences);
			return state.setIn(['paymentRequest', "Files"], files);
		}
		case paymentRequests.REMOVE_FILES_PLANT:{
            return state.updateIn(['paymentRequest', "Files"], files => files.filter(file => file.get('name') !== action.fileName));
		}
		case paymentRequests.IS_LOADING_SUMMARY_REQUESTS:{
			return state.merge({
				isLoadingSummary: action.status
			})
		}
		case paymentRequests.SET_SUMMARY_REQUESTS:{
			return state.merge({
				isLoadingSummary: false,
				summaryRequests: fromJS(action.summaryRequests)
			})
		}
		case paymentRequests.IS_SAVING_SUMMARY_PAYMENT:{
			return state.merge({
				isSavingSummary: action.status
			})
		}
		case paymentRequests.SET_SUMMARY_PAYMENT:{
			return state.merge({
				isSavingSummary: false,
				isLoadingSummary: false,
				paymentSummary: fromJS(action.summaryPayment)
			})
		}
		case paymentRequests.IS_DONWLOAD_REPORT:{
			return state.merge({
				isDownloadReport: action.status
			})
		}
		default:
			return state
	}
}

export default paymentRequestReducer
