/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types'

/**Import MaterialUi section */
import { Grid } from '@material-ui/core'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import TextBox from '../../common/TextBox'

const AddLateralPanel = (props) => {

    /**Local store this component */
    const { onSaveButtonClick, onCloseButtonClick, isSaving } = props;

    const [customerModel, setCustomerModel] = useState(defaultCustomer);

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setCustomerModel({
            ...customerModel,
            [name]: value
        });

    }

    /**Verify if cam be sabed */
    const canBeSaved = () => {
        if (!customerModel) return false;
        if (customerModel.Name ? (customerModel.Name.length <= 0) : true) return false;
        if (customerModel.Code ? (customerModel.Code.length <= 0) : true) return false;
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(customerModel)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Add Customer"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={canBeSaved()}
                onSaveButtonClick={onSave}
                isSaving={isSaving}
            />
            <div style={{ padding: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <TextBox
                            active={true}
                            disabled={isSaving}
                            fullWidth
                            autoFocus
                            name="Code"
                            variant="outlined"
                            margin="dense"
                            label="Code"
                            onChange={onPropertyChange}
                            value={customerModel ? customerModel.Code : ""}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            active={true}
                            disabled={isSaving}
                            fullWidth
                            name="Name"
                            variant="outlined"
                            margin="dense"
                            label="Name"
                            onChange={onPropertyChange}
                            value={customerModel ? customerModel.Name : ""}
                        />
                    </Grid>
                </Grid>
            </div>

        </LateralPanel>
    )
}

const defaultCustomer = {
    Name: '',
    code: ''
}

/**Proptypes this component */
AddLateralPanel.propTypes = {
    /**
	 * On close lateral menu
	 */
    onCloseButtonClick: propTypes.func.isRequired,
    /**
    * Invoke function for add new service provider
    */
    onSaveButtonClick: propTypes.func.isRequired,
    /**
    * Determinate is saving when add new service provider
    */
    isSaving: propTypes.bool.isRequired,
}


AddLateralPanel.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    isSaving: false
}

export default AddLateralPanel
