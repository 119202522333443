import { makeStyles } from '@material-ui/styles'

/**
 * One column view styles
 */
export const useBaseHeaderStyles = makeStyles((theme) => ({
	root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    leftContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    appLogo: {
        height: 40,
        marginRight: '10px'
	}
}))

/**
 * Authorized toolbar styles
 */
export const useAuthorizedToolbarStyles = makeStyles((theme) => ({
	toolbar: {
	},
	leftToobarContainer: {
		display: 'flex'
	},
	rightToobarContainer: {
        display: 'flex',
        alignItems: 'center'
	},
	settingIconButton: {
		backgroundColor: theme.palette.primary.main,		
		width: theme.spacing(5),
		height: theme.spacing(5),
    },
    profileIconButton: {
		backgroundColor: `${theme.palette.secondary.main} !important`,
		width: theme.spacing(5),
		height: theme.spacing(5)
	},
    profilerForm: {
		padding: '10px',
		display: 'flex',
		alignItems: 'center'
    },
	profilerUserInformation: {
		padding: '10px'
    },
    profileUserAvatar: {
		width: theme.spacing(7),
		height: theme.spacing(7),
		backgroundColor: theme.palette.primary.main,
    },
    profileFormButtons: {
        padding: '5px',
		display: 'flex',
		justifyContent: 'space-around'
	},
	avatarListIcon:{
		width: theme.spacing(4),
		height: theme.spacing(4),
		backgroundColor:theme.palette.primary.main,
		color: theme.palette.common.white
	},
	iconAvatar:{
		width:"20px",
		height:"auto"
	},
	logoHeader:{
		maxHeight: "45px",
		cursor: "pointer",
		marginLeft:"-20px"
	},
	logoHeaderWithCursor:{
		maxHeight: "45px",
		marginLeft:"-20px"
	},
	iconHeader:{
		marginTop:"13px",
		marginRight: "5px"
	}
}))