/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types'

/**Import MaterialUi section */
import { Grid } from '@material-ui/core'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import TextBox from '../../common/TextBox'

const AddLateralPanel = (props) => {

    /**Local store this component */
    const { onSaveButtonClick, onCloseButtonClick, isSaving } = props;

    const [serviceProviderModel, setServiceProviderModel] = useState(defaultServiceProvider);

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setServiceProviderModel({
            ...serviceProviderModel,
            [name]: value
        });

    }

    /**Verify if cam be sabed */
    const canBeSaved = () => {
        if (!serviceProviderModel) return false;
        if (serviceProviderModel.Name ? (serviceProviderModel.Name.length <= 0) : true) return false;
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(serviceProviderModel)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Add service provider"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={canBeSaved()}
                onSaveButtonClick={onSave}
                isSaving={isSaving}
            />
            <div style={{ padding: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <TextBox
                            active={true}
                            disabled={isSaving}
                            fullWidth
                            name="Name"
                            variant="outlined"
                            margin="dense"
                            label="Name (as shown on your income tax return)"
                            onChange={onPropertyChange}
                            value={serviceProviderModel ? serviceProviderModel.Name : ''}
                        />

                    </Grid>
                </Grid>
            </div>

        </LateralPanel>
    )
}

const defaultServiceProvider = {
    Name: '',
}

/**Proptypes this component */
AddLateralPanel.propTypes = {
    /**
     * On close lateral menu
     */
    onCloseButtonClick: propTypes.func.isRequired,
    /**
    * Invoke function for add new service provider
    */
    onSaveButtonClick: propTypes.func.isRequired,
    /**
    * Determinate is saving when add new service provider
    */
    isSaving: propTypes.bool.isRequired,
}


AddLateralPanel.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    isSaving: false
}

export default AddLateralPanel
