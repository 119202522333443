/**Import react section */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
/**Import Material UI section */
import { Stepper, Step, StepLabel, Typography, Card } from '@material-ui/core'

/**Import resources section */
import { useSerialHistoryDesktopStyle } from '../resources/useStyles'
import { currencyFormat } from '../../../store/helpers/AmountHelper'

const PaymentsTimeLine = (props) => {
    const { paymentRequests } = props;
    let history = useHistory()
    const classes = useSerialHistoryDesktopStyle();

    const [activeStep] = useState(0)

    const onRedirectToPaymentRequests = (token) => {
        history.push(`/paymentrequests/${token}`)
    }

    return (
        <div >
            <Card className={classes.serviceHeader}>
                <Typography className={classes.serviceHeaderTicket}>{`Folio`}</Typography>
                <Typography className={classes.serviceHeaderDate}>{`Creation date`}</Typography>
                <Typography className={classes.serviceHeaderStatus}>{`Ticket`}</Typography>
                <Typography className={classes.serviceHeaderDescription}>{`Subcategory`}</Typography>
                <Typography className={classes.serviceHeaderUltim}>{`Service Provider`}</Typography>
                <Typography align='center' className={classes.serviceHeaderItem7}>{`Total Amounts`}</Typography>
            </Card>
            <br />

            <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepperContainer}>
                {paymentRequests && paymentRequests.map((payment, index) => {
                    return (
                        <Step key={index} onClick={() => onRedirectToPaymentRequests(payment.PaymentToken)}>
                            <StepLabel>
                                <div className={classes.serviceContent} >
                                    <Typography className={classes.serviceHeaderTicket}>{payment.PaymentFolio}</Typography>
                                    <Typography className={classes.serviceHeaderDate}>{payment.CreationDate}</Typography>
                                    <Typography className={classes.serviceHeaderStatus}>{payment.Ticket}</Typography>
                                    <Typography className={classes.serviceHeaderDescription}>{payment.Subcategory}</Typography>
                                    <Typography className={classes.serviceHeaderUltim}>{payment.ServiceProvider}</Typography>
                                    <Typography align='center' className={classes.serviceHeaderItem7}>{`${currencyFormat(payment.TotalAmounts)}`}</Typography>
                                </div>
                            </StepLabel>
                        </Step>
                    )

                })}
            </Stepper>
        </div>

    )
}

export default PaymentsTimeLine