import { makeStyles } from '@material-ui/styles'

/**
 * Service table styles
 */

export const toolbarStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.colors.purple,
		height: '45px',
		color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	faultWarrantyIconSelected: {
		marginRight: theme.spacing(0.5),
		'&:hover': {
			backgroundColor: theme.palette.serviceStatus.newServiceDark
		},
		'&.Mui-disabled': {
			backgroundColor: theme.palette.serviceStatus.newServiceDark,
			boxShadow: '1px 1px 1px 1px'
		}
	},
	faultWarrantyIcon: {
		width: '25px',
		height: 'auto'
	},
	title: {
		marginTop: '10px',
		marginLeft: theme.spacing(1)
	},
	grow: {
		flexGrow: 1
	},
	active: {
		height: '45px',
		backgroundColor: theme.palette.colors.purple,
		color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	assigned: {
		backgroundColor: theme.palette.colors.blue,
		height: '45px',
		// color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	suspended: {
		backgroundColor: theme.palette.colors.orange,
		height: '45px',
		// color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	waiting: {
		backgroundColor: theme.palette.colors.waiting,
		height: '45px',
		// color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	completed: {
		backgroundColor: theme.palette.colors.green,
		height: '45px',
		// color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	replaced: {
		backgroundColor: theme.palette.secondary.main,
		height: '45px',
		// color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	},
	cancel: {
		backgroundColor: theme.palette.colors.red,
		height: '45px',
		color: theme.palette.common.white,
		paddingTop: '5px',
		paddingRight: '10px',
		paddingLeft: '10px',
		paddingBottom: '5px',
		display: 'flex',
		flexDirection: 'row'
	}
}))
