import axios from 'axios'
import { getAuthorizedConfiguration, getAuthorizedConfigurationParameters } from '../helpers/RequestHelper'
import moment from 'moment'
import { isNullOrEmpty } from '../helpers/StringHelper'

export const SET_SPAREPARSALES_DRAFT = 'SET_SPAREPARSALES_DRAFT'
export const IS_GETTING_SPAREPARTSALES_DRAFT = 'IS_GETTING_SPAREPARTSALES_DRAFT'
export const IS_SAVING_DRAFT_SPAREPARTSALES = 'IS_SAVING_DRAFT_SPAREPARTSALES'
export const IS_LOADING_SALES_LIST = 'IS_LOADING_SALES_LIST'
export const SET_SPAREPARTRSALES_LIST = 'SET_SPAREPARTRSALES_LIST'
export const IS_DONWLOAD_REPORT_PRICES = 'IS_DOWNLOAD_REPORT_PRICES'
export const SET_DASHBOAR_SALES = 'SET_DASHBOAR_SALES'
export const SET_SPAREPART_INFO_SELECTOR = 'SET_SPAREPART_INFO_SELECTOR'
export const IS_LOADING_DASHBOAR_SALES = 'IS_LOADING_DASHBOAR_SALES'

export const IS_LOADING_SPARE_PART_SALES = 'IS_LOADING_SPARE_PART_SALES'
export const SET_SPAREPARTSALES_OPEN = 'SET_SPAREPARTSALES_OPEN'
export const IS_SAVING_SPARE_PART_DETAIL = 'IS_SAVING_SPARE_PART_DETAIL'
export const SET_SPAREPARTDETAIL_OPEN = 'SET_SPAREPARTDETAIL_OPEN'
export const IS_GET_STOCK = 'IS_GET_STOCK'
export const SET_EXISTENCE_STOCK = 'SET_EXISTENCE_STOCK'

export const ADD_FILES_SALES = 'ADD_FILES_SALES'
export const REMOVE_FILES_SALES = 'REMOVE_FILES_SALES'
export const REMOVE_SPARE_PART_DETAIL = 'REMOVE_SPARE_PART_DETAIL'
export const REMOVE_SPARE_PART_SALE = 'REMOVE_SPARE_PART_SALE'
export const UPDATE_SPARE_PART_SALE = 'UPDATE_SPARE_PART_SALE'
export const UPDATE_SPARE_PART_DETAIL = 'UPDATE_SPARE_PART_DETAIL'

export const GETTING_INFORMATION_TRACKING_SALE = 'GETTING_INFORMATION_TRACKING_SALE'
export const SET_CARRIER_DATA_SALES = 'SET_CARRIER_DATA_SALES'
export const UPDATE_STATUS_SALES = 'UPDATE_STATUS_SALES'

/**
 * Is saving draft
 */
const isSavingDraft = (status = true) => {
	return {
		type: IS_SAVING_DRAFT_SPAREPARTSALES,
		status: status
	}
}

/**
 * Is loading action constructor
 */
export const isLoadingDashboar = (status) => {
	return {
		type: IS_LOADING_DASHBOAR_SALES,
		status
	}
}

/**
 * Is downloading
 */
const setIsDownloadReport = (status) => {
	return {
		type: IS_DONWLOAD_REPORT_PRICES,
		status
	}
}

/**
 * Set dashboard action dashboard
 */
export const setDashboardList = (dashboard) => {
	return {
		type: SET_DASHBOAR_SALES,
		dashboard
	}
}

export const setSparePartInfoSelector = (sparePartsInfo) => {
	return {
		type: SET_SPAREPART_INFO_SELECTOR,
		sparePartsInfo
	}
}

const isLoading = (status = true) => {
	return {
		type: IS_LOADING_SPARE_PART_SALES,
		status: status
	}
}

/**
 * Is saving request
 * @param {*} status
 */
const isSavingRequest = (status = true) => {
	return {
		type: IS_SAVING_SPARE_PART_DETAIL,
		status: status
	}
}

const isLoadStock = (status = true) => {
	return {
		type: IS_GET_STOCK,
		status: status
	}
}

/**
 * set existence material getting from sap
 */
const setExistenceMaterialAction = (code, data, updateType) => {
	return {
		type: SET_EXISTENCE_STOCK,
		data: data,
		code: code,
		updateType: updateType
	}
}

/**
 * set tracking data from backend
 */
export const gettingTrackingData = (status) => {
	return {
		type: GETTING_INFORMATION_TRACKING_SALE,
		status
	}
}

/**
 * set carrier information from backend
 */
export const setCarrierInformation = (data) => {
	return {
		type: SET_CARRIER_DATA_SALES,
		data
	}
}

/**
 * updates status relationed with trackingmore
 * @param {*} status
 * @param {*} trackingNumber
 * @returns
 */
export const updateStatusTraking = (status, trackingNumber) => {
	return {
		type: UPDATE_STATUS_SALES,
		status,
		trackingNumber
	}
}

/**
 * Load the spare parts sales requests
 */
export const loadSparePartSalesRequests = (status, queryParameters) => {
	return async (dispatch, getState) => {
		dispatch({
			type: IS_LOADING_SALES_LIST,
			status: true
		})

		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			PageNumber: queryParameters ? queryParameters.pageNumber : 1,
			PageSize: 10,
			OrderBy: queryParameters ? queryParameters.sortBy : null,
			OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
			Query: !!queryParameters.query ? queryParameters.query : '',
			Status: status
		}

		if (!!queryParameters) {
			if (!!queryParameters.statusFilter) {
				if (typeof queryParameters.statusFilter !== 'string')
					command.StatusFilter = queryParameters ? queryParameters.statusFilter.map((d) => parseInt(d, 10)) : []
				else command.StatusFilter = queryParameters ? [queryParameters.statusFilter] : []
			}
		}
		if (!!queryParameters.technicianFilter) {
			if (typeof queryParameters.technicianFilter !== 'string')
				command.TechnicianFilter = queryParameters ? queryParameters.technicianFilter.map((d) => d) : []
			else command.TechnicianFilter = queryParameters ? [queryParameters.technicianFilter] : []
		}
		if (!!queryParameters.warehouseFilter) {
			if (typeof queryParameters.warehouseFilter !== 'string')
				command.WarehousesFilter = queryParameters ? queryParameters.warehouseFilter.map((d) => d) : []
			else command.WarehousesFilter = queryParameters ? [queryParameters.warehouseFilter] : []
		}
		if (!!queryParameters.trackingFilter) {
			if (typeof queryParameters.trackingFilter !== 'string')
				command.TrackingStatusFilter = queryParameters ? queryParameters.trackingFilter.map((d) => d) : []
			else command.TrackingStatusFilter = queryParameters ? [queryParameters.trackingFilter] : []
		}
		if (!!queryParameters.customers) {
			if (typeof queryParameters.customers !== 'string') command.CustomerCodes = queryParameters ? queryParameters.customers.map((d) => d) : []
			else command.CustomerCodes = queryParameters ? [queryParameters.customers] : []
		}

		let url = `${window.config.serviceManagementBackendUrl}/sparepartsales`
		let serviceResponse = await axios.post(url, command, config)
		let resultRequests = await getFullRequestWithUsers(serviceResponse.data.sparePartSales)
		dispatch({
			type: SET_SPAREPARTRSALES_LIST,
			requests: resultRequests,
			pagination: serviceResponse.data
		})
	}
}

/**
 * Action to get full request with diferentes users
 * @param {*} sparePartsList
 * @param {*} configuration
 */
export const getFullRequestWithUsers = async (sparePartsList) => {
	try {
		let url = `${window.config.identityServerUrl}/api/users`
		const content = sparePartsList.map((request) => request.CreatorUserName)
		let useIdentityData = await axios.post(url, content, null)
		return sparePartsList.map((request) => {
			let usesData = useIdentityData.data.find((x) => x.user_name === request.CreatorUserName)
			request.CreationDate = moment(request.CreationDate, 'MM/DD/YYYY')
			request.CreatorUser = {
				Name: usesData ? usesData.full_name : '',
				Email: usesData ? usesData.email : ''
			}
			return request
		})
	} catch (ex) {
		console.error('Errog getting the internal users list', ex)
	}
}

/**
 * Load services to load dashboard
 */
export const loadDashboardSparePartSales = (status) => {
	return async (dispatch, getState) => {
		try {
			const loadDashboardSparePartSales = async () => {
				dispatch(isLoadingDashboar(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				const command = {
					Status: status
				}
				// Get the spare parts requests from load dashboard
				let url = `${window.config.serviceManagementBackendUrl}/sparepartsales/dashboard`
				let serverResponse = await axios.post(url, command, config)
				dispatch(setDashboardList(serverResponse.data))
			}
			await loadDashboardSparePartSales()
		} catch (ex) {
			dispatch(isLoadingDashboar(false))
			console.error('Error getting dashboard spare part sales', ex)
		}
	}
}

export const loadSparePartByCustomer = (clientCode) => {
	return async (dispatch, getState) => {
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = { ClientCode: clientCode }
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/sparePartsByCustomer`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSparePartInfoSelector(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				if (error.response) {
					return Promise.reject(error.response.data)
				} else {
					return Promise.reject({
						Message: 'Unexpected error thrown'
					})
				}
			})
	}
}

export const updateSparePartsPrices = (token) => {
	return async (dispatch, getState) => {
		dispatch(isSavingDraft(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/sparePartsInfo/${token}`
		return axios
			.post(url, {}, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDraft(false))
					dispatch({
						type: SET_SPAREPARTSALES_OPEN,
						sparePart: serverResponse.data
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingDraft(false))
				if (error.response) {
					return Promise.reject(error.response.data)
				} else {
					return Promise.reject({
						Message: 'Unexpected error thrown'
					})
				}
			})
	}
}

export const downloadReportSparePartSalesRequestGral = (status, queryParameters, startDate, endDate) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartsale/downloadgeneralreport`
		let command = {
			Status: status,
			UserName: identityUser.profile.preferred_username,
			StartDate: startDate,
			EndDate: endDate
		}

		if (!!queryParameters) {
			if (!!queryParameters.statusFilter) {
				if (typeof queryParameters.statusFilter !== 'string')
					command.StatusFilter = queryParameters ? queryParameters.statusFilter.map((d) => parseInt(d, 10)) : []
				else command.StatusFilter = queryParameters ? [queryParameters.statusFilter] : []
			}
		}
		if (!!queryParameters.technicianFilter) {
			if (typeof queryParameters.technicianFilter !== 'string')
				command.TechnicianFilter = queryParameters ? queryParameters.technicianFilter.map((d) => d) : []
			else command.TechnicianFilter = queryParameters ? [queryParameters.technicianFilter] : []
		}
		if (!!queryParameters.warehouseFilter) {
			if (typeof queryParameters.warehouseFilter !== 'string')
				command.WarehousesFilter = queryParameters ? queryParameters.warehouseFilter.map((d) => d) : []
			else command.WarehousesFilter = queryParameters ? [queryParameters.warehouseFilter] : []
		}
		if (!!queryParameters.trackingFilter) {
			if (typeof queryParameters.trackingFilter !== 'string')
				command.TrackingStatusFilter = queryParameters ? queryParameters.trackingFilter.map((d) => d) : []
			else command.TrackingStatusFilter = queryParameters ? [queryParameters.trackingFilter] : []
		}
		if (!!queryParameters.customers) {
			if (typeof queryParameters.customers !== 'string') command.CustomerCodes = queryParameters ? queryParameters.customers.map((d) => d) : []
			else command.CustomerCodes = queryParameters ? [queryParameters.customers] : []
		}
		if (!!queryParameters.providers) {
			if (typeof queryParameters.providers !== 'string') command.ProvidersCodes = queryParameters ? queryParameters.providers.map((d) => d) : []
			else command.ProvidersCodes = queryParameters ? [queryParameters.providers] : []
		}

		return axios
			.post(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `ReportSparePartRequest.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report spare part requests', error)
				return Promise.reject()
			})
	}
}

/**
 * Action creator to add a spare part sales
 */
export function addSparePartSales(spartPartSaleModel, position, accountCode) {
	return function(dispatch, getState) {
		dispatch(isSavingDraft(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			CustomerCode: !isNullOrEmpty(spartPartSaleModel.Customer ? spartPartSaleModel.Customer.value : '')
				? spartPartSaleModel.Customer.value
				: accountCode,
			Comments: spartPartSaleModel ? spartPartSaleModel.Comments : '',
			Address: {
				Country: spartPartSaleModel.Address ? spartPartSaleModel.Address.country : '',
				State: spartPartSaleModel.Address ? spartPartSaleModel.Address.administrative_area_level_1 : '',
				ZipCode: spartPartSaleModel.Address ? spartPartSaleModel.Address.postal_code : '',
				Location: spartPartSaleModel.Address ? spartPartSaleModel.Address.locality : '',
				Street: spartPartSaleModel.Address ? spartPartSaleModel.Address.route : '',
				StreetNumber: spartPartSaleModel.Address ? spartPartSaleModel.Address.street_number : '',
				Latitude: position ? position.latitude : null,
				Longitude: position ? position.longitude : null
			}
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDraft(false))
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingDraft(false))
				if (error.response) {
					return Promise.reject(error.response.data)
				} else {
					return Promise.reject({
						Message: 'Unexpected error thrown'
					})
				}
			})
	}
}

/**
 * Action to delete spare part in draft
 * @param {*} token
 */
export const deleteSparepartSales = (token) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/${token}/remove`
		return await axios
			.delete(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: REMOVE_SPARE_PART_SALE,
						token: token
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to delete spare part sale', error)
				return Promise.reject(`Error to delete spare part sale`)
			})
	}
}

/**get spart part */
export const getSparePartSales = (token) => {
	return function(dispatch, getState) {
		dispatch(isLoading())
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/${token}`
		return axios
			.get(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: SET_SPAREPARTSALES_OPEN,
						sparePart: serverResponse.data
					})
					dispatch(isLoading(false))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error to get spare part sales', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to create new service
 * @param {*} spartPartModel
 */
export function addSpartPartInRequest(sparePartSales, sparePart, parentToken) {
	return function(dispatch, getState) {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Add service
		let command = {
			Token: sparePartSales ? sparePartSales.Token : '',
			Quantity: sparePart ? sparePart.Quantity : 0,
			SparePartCode: sparePart ? sparePart.Code : '',
			SparePartDescription: sparePart ? sparePart.Description : '',
			ClientCode: sparePartSales ? sparePartSales.Customer.Code : '',
			ParentToken: parentToken ? parentToken : null,
			PartialQuantity: sparePart ? sparePart.Quantity : 0,
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/addSparePart`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: SET_SPAREPARTDETAIL_OPEN,
						sparePart: serverResponse.data
					})
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error in create spart part in sale', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to Add a Partiality
 * @param {*} sparePartSaleToken
 * @param {*} SparePartCode
 * 
 */
export function addPartialityInRequest(sparePartRequestToken, sparePartCode) {
	return function(dispatch, getState) {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/${sparePartRequestToken}/addpartiality/${sparePartCode}`
		return axios
			.post(url, {}, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: SET_SPAREPARTDETAIL_OPEN,
						sparePart: serverResponse.data
					})
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error Adding a partiality in spart part sale', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**Remove spart part detail */
export const removeSparePartDetail = (sparePart, sparePartSaleToken) => {
	return function(dispatch, getState) {
		dispatch(isSavingRequest(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/${sparePartSaleToken}/details/${sparePart.Token}`
		return axios
			.delete(url, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_SPARE_PART_DETAIL,
						sparePart
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to remove spare part detail', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**get spart part */
export const getExistenceMaterialSales = (code, updateType) => {
	return function(dispatch, getState) {
		dispatch(isLoadStock(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/warehouses/${code}` // TODO UPDATE FUNCION
		return axios
			.get(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setExistenceMaterialAction(code, serverResponse.data, updateType))
					dispatch(isLoadStock(false))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isLoadStock(false))
				console.error('Error to get spare part  existences', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to import references
 */
export const importSparePartFromBackend = (sparePartToken, files, sparePartOpen) => {
	return function(dispatch, getState) {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let customer = sparePartOpen.Customer
		let currentClientCode
		if (customer != null) {
			currentClientCode = customer.Code
		}
		let command = {
			Token: sparePartToken,
			ClientCode: currentClientCode
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/sparepartsale/import`
		let data = new FormData()
		data.append('files', files)
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTDETAIL_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve(serverResponse.data.Token)
			})
			.catch((error) => {
				dispatch(isSavingRequest(true))
				console.error('Error import spare parts list', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add file in spare part sales
 * @param {*} token
 * @param {*} files
 */
export const addFilesSparePartSales = (token, files) => {
	return function(dispatch, getState) {
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let command = {
			token: token
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartsale/files`
		let data = new FormData()
		for (var index = 0; index <= files.length; index++) {
			data.append('file', files[index])
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch({
					type: ADD_FILES_SALES,
					evidences: response.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error uploading evidence in sales', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove file in the spare part sales
 * @param {*} token
 * @param {*} fileName
 */
export function removeFilesInSparePartSales(token, fileName) {
	return function(dispatch, getState) {
		let params = {
			Name: fileName
		}
		let configuration = getAuthorizedConfigurationParameters(getState().oidc.user, params)
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartsale/${token}/files`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_FILES_SALES,
						fileName: fileName
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from spare part sales', error.response)
				return Promise.reject()
			})
	}
}

/**update spart part */
export const updateSparePartSales = (sparePartSaleToken, sparePartModel) => {
	return function(dispatch, getState) {
		dispatch(isSavingRequest())
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale`
		let command = {
			SparePartSaleToken: sparePartSaleToken,
			Comments: sparePartModel ? sparePartModel.Comments : '',
			ShipTo: sparePartModel ? sparePartModel.ShipTo : '',
			EmailsContact: sparePartModel ? sparePartModel.EmailsContact : '',
			ContactPhone: sparePartModel ? sparePartModel.ContactPhone : '',
			TotalAmountInvoice: sparePartModel ? sparePartModel.TotalAmountInvoice : ''
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: UPDATE_SPARE_PART_SALE,
						sparePart: serverResponse.data
					})
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to update spare part sale', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Change the confirm request from draft to submmited
 */
export const confirmSendToPlant = (sparePartSale, emailsRolesTechsUsa) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartSale.Token,
			Emails: emailsRolesTechsUsa
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/sendconfirm`
		await axios
			.put(url, command, config)
			.then(() => {
				dispatch(isSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to send spare part sales to validate in plant', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update spare part detail
 * @param {*} sparePartSaleToken
 * @param {*} sparePartToken
 * @param {*} numberTraking
 */
export const updateSpareaPartDetail = (sparePartSaleToken, sparePart, emailsToSend, propertyToUpdate = '') => {
	return async (dispatch, getState) => {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			SparePartSaleToken: sparePartSaleToken,
			SparePartParser: sparePart,
			Emails: emailsToSend,
			PropertyToUpdate: propertyToUpdate
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/sparepart`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				let response = serverResponse.data
				if (response.IsClose) {
					dispatch({
						type: UPDATE_SPARE_PART_SALE,
						sparePart: serverResponse.data
					})
				} else {
					dispatch({
						type: UPDATE_SPARE_PART_DETAIL,
						sparePart: serverResponse.data
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to update spare part', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to send request from Imbera MX
 * @param {*} sparePartRequest
 */
export const sendRequestSalesImberaMx = (sparePartSaleToken, emailsroleImberaMx) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest())
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartSaleToken,
			Emails: emailsroleImberaMx
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/send`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTDETAIL_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to send spare part sales', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to close request
 * @param {*} sparePartSaleToken
 */
export const closeRequestSales = (sparePartSaleToken) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartSaleToken
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/close`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTDETAIL_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to close spare part sales', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to cancel request
 * @param {*} sparePartSaleToken
 */
export const cancelRequestSales = (sparePartSaleToken, emailToSend) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartSaleToken,
			Emails: emailToSend
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/cancel`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTDETAIL_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to cancel spare part sales', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to download data by customer
 */
export const downloadReportByCustomer = (customModel, startDate, enDate) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let data = {
			CustomCode: customModel ? customModel.value : null,
			StartDate: startDate ? moment(startDate) : null,
			EnDate: enDate ? moment(enDate) : null
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartsale/download/customer`
		return axios
			.post(endPoint, data, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Download_Price_List.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report by customer', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to check traking number in spare part request
 * @param {*} carrier
 * @param {*} trackingNumber
 */
export const checkShipping = (carrier, trackingNumber, token) => {
	return async (dispatch, getState) => {
		dispatch(gettingTrackingData(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartsale/trackings/${carrier}/${trackingNumber}/${token}`
		return await axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(gettingTrackingData(false))
				if (serverResponse.data.meta.code === 200) {
					dispatch(setCarrierInformation(serverResponse.data.data))
					dispatch(updateStatusTraking(serverResponse.data.data.status, trackingNumber))
					return Promise.resolve(serverResponse.data.data)
				} else {
					return Promise.reject(`The request was there but there is no information for this tracking number ${trackingNumber} to update`)
				}
			})
			.catch((error) => {
				dispatch(gettingTrackingData(false))
				console.error('Error to close spare part requests', error)
				return Promise.reject(`The request was there but there is no information for this tracking number ${trackingNumber} to update`)
			})
	}
}
