
/** Import react section **/
import React from 'react';
import propTypes from 'prop-types'
/** Import Material UI section **/
import {
    Paper,
    Popper,
    Typography,
    IconButton
} from '@material-ui/core';

/**Import componets section */
import { DateRangeSelectStyles } from '../../../resources/styles/ServicesListStyles'
import ButtonConfirm from '../../common/mobile/Buttons/ButtonSavedMobile'
import DateSelector from '../../common/DateSelector'
import CloseIcon from '@material-ui/icons/HighlightOff'

export const PopperComponent = (props) => {
    const classes = DateRangeSelectStyles();
    const { settingsEl, onButtonSavedClick, isSaving, title } = props;

    let open = !!settingsEl;

    const onButtonConfirmClick = () => {
        if (onButtonSavedClick) {
            return onButtonSavedClick()
        }
    }

    return (
        <Popper
            anchorEl={settingsEl}
            open={open}
            placement={'bottom'}
        >
            <Paper
                elevation={2}
                className={classes.papperPopperMobile}
            >
                <div className={classes.containerData}>
                    {title && <div className={classes.flex}>
                        <Typography variant='subtitle1' className={classes.titlePopper} aling="center">{title}</Typography>
                        <div className={classes.flexGrow} />
                        <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            className={classes.closedButton}
                            size="small"
                            onClick={props.setSettingsEl}
                        >
                            <CloseIcon size="small" />
                        </IconButton>
                    </div>
                    }
                    <DateSelector
                        dataCy='data-picker-date-attention'
                        label="Attention date"
                        name='dateAttention'
                        showIncon={false}
                        active={true}
                        value={props.value ? props.value : null}
                        onChange={props.onPropertyChange}
                        disabled={false}
                    />
                    <div className={classes.actionButtons}>
                        <ButtonConfirm
                            title='CONFIRM'
                            onSavedClick={onButtonConfirmClick}
                            isSaving={isSaving}
                        />
                    </div>

                </div>
            </Paper>
        </Popper>
    )
}

PopperComponent.propTypes = {
	/**
	 * Callback execute for fownload report by range date 
	 */
    onButtonSavedClick: propTypes.func.isRequired,
    /**
     * Determinate if is saving data
     */
    isSaving: propTypes.bool.isRequired,
    /**
     * Determinate title popper
     */
    title: propTypes.string.isRequired,
}

PopperComponent.defaultProps = {
    title: "",
    isSaving: false,
    onButtonSavedClick: () =>
        console.warn('Callback [onButtonSavedClick] no defined'),
}

export default PopperComponent
