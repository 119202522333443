/**Import react section */
import React from 'react'
/**Import Material Ui section section */
import { Grid, Chip, Typography } from '@material-ui/core'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import ButtonConfirm from '../../common/Buttons/ButtonConfirm'
import ButtonSync from '../../common/Buttons/ButtonSync'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
/**Import resources section */
import { currencyFormat } from '../../../store/helpers/AmountHelper'
import { useCreateDesktopStyle } from '../resources/useStyles'
import { GetIconAmount } from '../../../store/helpers/StatusHelper'
import AuthorizationEvaluation from '../../common/AuthorizationEvaluation'
import {
	PORTAL_ADMINISTRATOR,
	IMBERA_TECHNICIAN,
	DISPATCHER,
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER,
	CUSTOMER_MANAGER,
	PROVIDER_REGISTRATION_VALIDATOR,
	CUSTOMER_TECHNICIAN,
	PAYMENT_MANAGER
} from '../../../resources/RolesEnum'
const HeaderOpen = (props) => {
	const { sparePartSale, onAddSparePart, onImportSpartParts, onUpdateSparePartsPrices, isSaving, userProfile } = props
	const classes = useCreateDesktopStyle()
	return (
		<Grid container spacing={1} direction='row' justify='space-between' alignItems='center' style={{ padding: 10 }}>
			{sparePartSale && sparePartSale.Customer && (
				<Grid item xs={3}>
					<Typography color='primary' style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
						{'CUSTOMER ASSIGNEMENT'}
					</Typography>
					<Chip
						id={'value'}
						size='small'
						avatar={<AvatarCustomSize avatarUrl={null} name={'CUSTOMER'} isAvatarFromIdentity={false} />}
						label={sparePartSale && sparePartSale.Customer && sparePartSale.Customer.Name}
						style={{ color: 'white', background: '#5357DC' }}
					/>
					<br />
				</Grid>
			)}
			{sparePartSale && sparePartSale.TotalAmount && (
				<Grid item xs={3} container justify='space-between' alignItems='center' style={{ display: 'flex', justifyContent: 'center' }}>
					<img src={GetIconAmount('TOTAL')} width='' alt='warranty-icon' className={classes.amountIcon} />
					<Typography color='primary' variant='h6' style={{ fontWeight: 'bold' }}>
						TOTAL {currencyFormat(sparePartSale.TotalAmount)}
					</Typography>
				</Grid>
			)}
			{sparePartSale && sparePartSale.Status === 1 && (
				<AuthorizationEvaluation
					profile={userProfile}
					allowedRoles={[
						PORTAL_ADMINISTRATOR,
						IMBERA_TECHNICIAN,
						DISPATCHER,
						SPARE_PARTS_IMBERA_USA,
						SPARE_PARTS_IMBERA_MX,
						SERVICE_PROVIDER_MANAGER,
						SERVICE_PROVIDER_TECHNICIAN,
						SALES_REPRESENTATIVE_MANAGER,
						SALES_MANAGER,
						CUSTOMER_MANAGER,
						PROVIDER_REGISTRATION_VALIDATOR,
						CUSTOMER_TECHNICIAN,
						PAYMENT_MANAGER
					]}
				>
					<Grid item xs={sparePartSale && sparePartSale.Customer ? 6 : 10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						{sparePartSale && sparePartSale.PriceZeroExist === true && isSaving === false && (
							<ButtonSync title='Update SAP prices' onSavedClick={() => onUpdateSparePartsPrices(sparePartSale.Token)} />
						)}
						<Grid style={{ marginLeft: '8px' }}>
							<ButtonConfirm title='Import spare part' onSavedClick={() => onImportSpartParts()} />
						</Grid>

						<Grid style={{ marginLeft: '8px' }}>
							<ButtonSaved title='Add spare part' onSavedClick={() => onAddSparePart()} />
						</Grid>
					</Grid>
				</AuthorizationEvaluation>
			)}
		</Grid>
	)
}

export default HeaderOpen
