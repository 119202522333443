import React from 'react'
import propTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	selectorContainer: {
		paddingTop: '5px',
		paddingBottom: '5px'
	},
	textHelper: {
		paddingBottom: '2px'
	},
	container: {
		cursor: 'pointer',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out'
	}
}))

/**
 * The dropdow zone component
 *
 * @class DropDownZone
 * @extends {Component}
 */
const DropDownZone = (props) => {
	const classes = useStyles()

	return (
		<div>
			{props.isLoadingFile && <LinearProgress color='primary' />}
			<Dropzone
				
				multiple={props.multiple}
				accept={props.accept}
				onDrop={(files) => {
					if (props.onFilesReceived) {
						props.onFilesReceived(files)
					}
				}}
			>
				{({ getRootProps, getInputProps }) => (
					<div className={classes.container}>
						<div
							{...getRootProps({
                                className: 'dropzone',
                                datacy: props.datacy ? props.datacy: null
							})}
						>
							<input {...getInputProps()} />
							{!props.isLoadingFile ? (
								props.message
							) : (
								<p>{'Cargando archivo por favor espere'}</p>
							)}
						</div>
					</div>
				)}
			</Dropzone>
		</div>
	)
}
DropDownZone.propTypes = {
	onFilesReceived: propTypes.func.isRequired,
	isLoadingFile: propTypes.bool,
	message: propTypes.node
}

export default DropDownZone
