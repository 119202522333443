/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
/**Import components section */
import Toaster from '../common/Toaster'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'

import EditLateralPanel from './components/EditLateralPanel.js'
import UploadImgCropDialog from '../common/cropDialog/UploadImgCropDialog'
import { uploadLogoAccount, SET_ROLES_IN_PROFILE, saveExternalUser, uploadLogoExternalUser } from '../../store/serviceproviders/serviceProvidersActions'
import EditUserPanel from './components/EditUserPanel'

/**Import actions sections */
import {
    addUserInRole,
    removeUserFromRole,
} from '../../store/users/UsersActions'
/**Import helpers section */
import { isUserInRole } from '../../store/helpers/UserHelper'

const Edit = (props) => {
    const { serviceProviderEdit, onClosedLateralPanelEdit, isSaving,
        updateServiceProviderData, handleOpenModalUploadImg, onSetHandleOpenCloseModalUploadImg,
        userInServiceProviderEdit, onEditPanelUserConfig, passwordDialogConfiguration,
        onRemoveUserFromAccount, isValidatingPassword, onSetPasswordDialogConfiguration,
        isModeServiceProvider, onSetUserActiveExpiredDialog, userActiveExpiredConfing, onActiveUserExpired,
        onSetResendPasswordDialog, resendPasswordDialog, onResendPasswordUser, onActiveNotifications, isEnable
    } = props;

    /**Hooks sections */
    const dispatch = useDispatch()
    /**Local store from this component */
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [logoFileBase64, setLogoFileBase64] = useState("");
    const [userInServiceProviderModel, setUserInServiceProviderModel] = useState(null);

    useEffect(() => {
        setUserInServiceProviderModel(userInServiceProviderEdit ? userInServiceProviderEdit.user : null)
    }, [userInServiceProviderEdit])

    /**Upload image crop */
    const onUploadLogoUser = (imageBase64) => {
        let token = isModeServiceProvider ? (userInServiceProviderModel && userInServiceProviderModel.Id) : (serviceProviderEdit && serviceProviderEdit.serviceProvider.Token)
        let tokenManager = isModeServiceProvider ? (userInServiceProviderModel && userInServiceProviderModel.Token) : ''
        return dispatch(isModeServiceProvider ? uploadLogoExternalUser(token, imageBase64, tokenManager) : uploadLogoAccount(token, imageBase64))
            .then((representative) => {
                onSetHandleOpenCloseModalUploadImg(representative);
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "An error was generated while updating the logo", variant: 'error' })
                console.error("Error to upload image crop");
            });
    };

    /**Set img base 64 in the local store */
    const onSetLogoFileBase64 = (fileBase64) => {
        setLogoFileBase64(fileBase64);
    };

    const onUserInServiceProvider = (roles, userName) => {
        dispatch({
            type: SET_ROLES_IN_PROFILE,
            roles: roles,
            userName: userName,

        })
    }

    /**Add role to user and remove roles */
    const onToggleUserRole = (roleName, roleType) => {
        let token = userInServiceProviderModel && userInServiceProviderModel.Token;
        let roles = userInServiceProviderModel && userInServiceProviderModel.Roles;
        let userName = userInServiceProviderModel && userInServiceProviderModel.UserName;
        if (isUserInRole(roleName, roles)) {
            return dispatch(removeUserFromRole(token, roleName))
                .then(roles => {
                    onUserInServiceProvider(roles, userName)
                    setUserInServiceProviderModel({
                        ...userInServiceProviderModel,
                        "Roles": roles
                    })
                })
                .catch((error) => {
                    setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to remove role from user', variant: 'warning' })
                    console.error("Failed to remove role");
                });
        }
        else {
            return dispatch(addUserInRole(token, roleName, roleType))
                .then(roles => {
                    onUserInServiceProvider(roles, userName)
                    setUserInServiceProviderModel({
                        ...userInServiceProviderModel,
                        "Roles": roles
                    })
                })
                .catch((error) => {
                    setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add role', variant: 'error' })
                    console.error("Failed to assign role", error);
                });
        }
    }

    /**
    * Handler to edit an user.
    * Execute async action to edit an user.
    * @param {object} user
    */
    const editUserHandler = user => {
        dispatch(saveExternalUser(user))
            .then(() => {
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error updating user information", variant: 'error' })
            });
    };

    return (
        <div>
            {
                (serviceProviderEdit && serviceProviderEdit.opened) &&
                <EditLateralPanel
                    serviceProvider={serviceProviderEdit.serviceProvider}
                    onCloseButtonClick={onClosedLateralPanelEdit}
                    onSaveButtonClick={updateServiceProviderData}
                    isSaving={isSaving}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                />
            }
            {
                (userInServiceProviderEdit ? userInServiceProviderEdit.opened : false) &&
                <EditUserPanel
                    user={userInServiceProviderModel}
                    onToggleUserRole={onToggleUserRole}
                    isSaving={isSaving}
                    onCloseButtonClick={onEditPanelUserConfig}
                    onSaveButtonClick={editUserHandler}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                    onActiveNotifications={onActiveNotifications}
                    isEnable={isEnable}
                />
            }
            {
                <UploadImgCropDialog
                    open={handleOpenModalUploadImg}
                    onClose={onSetHandleOpenCloseModalUploadImg}
                    fileBase64={logoFileBase64}
                    isSaving={isSaving}
                    onUploadImagen={onUploadLogoUser}

                />
            }

            {
                (passwordDialogConfiguration && passwordDialogConfiguration.opened) && <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={onRemoveUserFromAccount}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetPasswordDialogConfiguration}
                    message1={`This action will remove the access of ${passwordDialogConfiguration.user ? passwordDialogConfiguration.user.Name : ''} to this portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                (resendPasswordDialog && resendPasswordDialog.opened) && <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={resendPasswordDialog.opened}
                    onClickButtonConfirm={onResendPasswordUser}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetResendPasswordDialog}
                    message1={`The password will be send to  ${resendPasswordDialog.user ? resendPasswordDialog.user.Name : ''}. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                (userActiveExpiredConfing && userActiveExpiredConfing.opened) && <ConfirmPasswordDialog
                    title={'Confirm activate expired user'}
                    open={userActiveExpiredConfing.opened}
                    onClickButtonConfirm={onActiveUserExpired}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetUserActiveExpiredDialog}
                    message1={`This action will activate the expired ${userActiveExpiredConfing.user ? userActiveExpiredConfing.user.Name : ''} user in the portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </div>
    )
}

Edit.propTypes = {
    /**
     * Determine if the isSaving data in database
     */
    isSaving: propTypes.bool.isRequired,

    /**
     * invoke update service provide data
     */
    updateServiceProviderData: propTypes.func,
    /**
     * Close lateral panel for edit information from service provider
     */
    onClosedLateralPanelEdit: propTypes.func.isRequired,
    /**
     * Close Modal for upload logo user
     */
    onSetHandleOpenCloseModalUploadImg: propTypes.func.isRequired,
    /**
     * Determines the side panel settings, to determine the service provider information being edited, and whether the panel is open. 
     */
    serviceProviderEdit: propTypes.shape({
        serviceProvider: propTypes.object,
        opened: propTypes.bool
    }),
    /**
    * Determines the side panel settings, to determine the user information being edited, and whether the panel is open. 
    */
    userInServiceProviderEdit: propTypes.shape({
        user: propTypes.object,
        opened: propTypes.bool
    }),
    /**
     * Close lateral panel for edit information from user in service provider
     */
    onEditPanelUserConfig: propTypes.func.isRequired,
    /**
     * Resend password to user
     */
    onResendPasswordUser: propTypes.func.isRequired,
}


Edit.defaultProps = {
    isSaving: false,
    serviceProviderEdit: {
        serviceProvider: null,
        opened: false,
    },
    onClosedLateralPanelEdit: () =>
        console.warn('Callback [onClosedLateralPanelEdit] no defined'),
    updateServiceProviderData: () =>
        console.warn('Callback [updateServiceProviderData] no defined'),
    onSetHandleOpenCloseModalUploadImg: () =>
        console.warn('Callback [onSetHandleOpenCloseModalUploadImg] no defined'),
    onEditPanelUserConfig: () =>
        console.warn('Callback [onEditPanelUserConfig] no defined'),
    onResendPasswordUser: () =>
        console.warn('Callback [onResendPasswordUser] no defined')
}


export default Edit
