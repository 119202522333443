import React, { useEffect, useState } from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Content, Header, HeaderBreadCrum, Layout } from '../../layouts/AuthenticatedLayout'
import SideBar from '../../common/DashboadSideBar'
import { Button, Card, CardActions, CardContent, Checkbox, Chip, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core'
import { useSerialSapDesktopStyle } from '../resources/useStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'
import Toolbar from '../../common/Toolbar'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import ServiceProviderStepper from './StatisticsStepper'
import TextBox from '../../common/TextBox'
import SearchBox from '../../common/SearchToolbox'
import ImagesNoInformation from '../../common/ImagesNoInformation'
import headerConfig from './resources/StatisticsTableConfig'
import headerSearchConfig from './resources/StatisticsTableSearchConfig'
import headerSecondStepConfig from './resources/StatisticsTableSecondStepConfig'
import headerThirdStepConfig from './resources/StatisticsTableThirdStepConfig'
import { useForm } from 'react-hook-form'
import ReactSelectAvatar from '../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import SelectDateRange from './SelectDateRange'
import { getOptionsReactSelect } from '../../../store/helpers/FilterHelper'
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import moment from 'moment'
import Pagination from '../../common/Pagination/Pagination'
import { sortBy } from '../../../store/helpers/SortingHelper'
import FirstStepView from './FirstStepView'

const ServiceProviderRegisterDesktopView = (props) => {
	const { errors, control } = useForm()
	const classes = useSerialSapDesktopStyle()
	const {
		userProfile,
		activeStep,
		onMoveBackWardHandler,
		onMoveForwardHandler,
		getMaterialsByGae,
		firstData,
		isLoadingFirstData,
		isAnyCustomerSelected,
		onSelectAllChange,
		onDateRangeChange,
		startDate,
		endDate,
		getCustomerInvoice,
		customersInvoiceSearchResult,
		isLoadingSecondData
	} = props
	const { customers, statisticsModel, isDownloadReport, handleDownloadStatisticsReport } = props
	const { onSelectChange, setActiveStep } = props
	let customersOptions = getOptionsReactSelect(customers)
	const [gaeValue, setGaeValue] = useState('')
	const [selectedItems, setSelectedItems] = useState([])
	const [allFistDataCheck, setAllFistDataCheck] = useState(false)

	const handleCheckboxChange = (event) => {
		if (event.target.checked) {
			setSelectedItems((prev) => [...prev, event.target.value])
		} else {
			setSelectedItems((prev) => prev.filter((item) => item !== event.target.value))
		}
	}
	// funcion "resetSteps" para resetear valores de busqueda, gae y materiales seleccionados
	const handleReset = () => {
		setGaeValue('')
		setSelectedItems([])
		setActiveStep(0)
		onSelectAllChange('Customer', [])
	}
	const [searchValue, setSearchValue] = useState('')
	const [filteredData, setFilteredData] = useState([])
	// filtro para hacer la busqueda en la informacion obtenida del backend ignorando acentos
	useEffect(() => {
		setFilteredData(
			firstData.filter(
				(item) =>
					item.MATNR.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLowerCase()
						.includes(
							searchValue
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
						) ||
					item.MAKTX.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLowerCase()
						.includes(
							searchValue
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
						) ||
					item.EXTWG.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLowerCase()
						.includes(
							searchValue
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
						) ||
					item.EWBEZ.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLowerCase()
						.includes(
							searchValue
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
						)
			)
		)
	}, [searchValue, firstData])

	const onChange = (value) => {
		setSearchValue(value.trim())
	}

	const [pagination, setPagination] = useState({ pageSize: 5, total: 0 })
	const [currentPage, setCurrentPage] = useState(1)
	const [allCustomersInvoiceResultPaginated, setAllCustomersInvoiceResultPaginated] = useState([])
	const [allCustomersInvoiceSearchResult, setAllCustomersInvoiceSearchResult] = useState([])
	const [sortingCriteria, setSortCriteria] = useState({
		by: 'Gae',
		sortDirection: 'asc'
	})
	const onChangePage = (event, page) => {
		setCurrentPage(page)
	}
	useEffect(() => {
		let customerInvoicePerPage = 10
		let total = allCustomersInvoiceSearchResult.length / customerInvoicePerPage
		let allTotal = Math.round(Math.ceil(total))
		const indexOfLastCustomersInvoice = currentPage * customerInvoicePerPage
		let indexOfFirtsCustomersInvoice = indexOfLastCustomersInvoice - customerInvoicePerPage
		let currentCustomerInvoice = allCustomersInvoiceSearchResult.slice(indexOfFirtsCustomersInvoice, indexOfLastCustomersInvoice)
		setAllCustomersInvoiceResultPaginated(currentCustomerInvoice)
		setPagination({ pageSize: customerInvoicePerPage, total: allTotal })
	}, [allCustomersInvoiceSearchResult, pagination.total, currentPage])

	/**
	 * Update sort criteria
	 */
	const onChangeSortingCriteria = (criteria) => {
		let resultSort = sortBy(customersInvoiceSearchResult, criteria.by, criteria.direction)
		setAllCustomersInvoiceSearchResult(resultSort)
		setSortCriteria({
			by: criteria.by,
			sortDirection: criteria.direction
		})
	}
	useEffect(() => {
		setAllCustomersInvoiceSearchResult(customersInvoiceSearchResult)
	}, [customersInvoiceSearchResult])

	const getStepComponent = (activeStep) => {
		switch (activeStep) {
			case 0:
				return (
					<div>
						<Paper className={classes.paperSearch}>
							<Typography variant='subtitle2'>Complete the information</Typography>
							<Grid container spacing={1}>
								<Grid item xs={11}>
									<div className={classes.texBoxes}>
										<TextBox
											className={classes.textBox}
											active={true}
											fullWidth
											name='GAE'
											variant='outlined'
											margin='dense'
											label={'GAE'}
											onChange={(event) => setGaeValue(event.target.value)}
											value={gaeValue}
										/>
									</div>
								</Grid>
								<Grid item xs={1} style={{ marginTop: '8px' }}>
									<ButtonSaved
										title='Search'
										onSavedClick={() => getMaterialsByGae(gaeValue.trim())}
										isSaving={gaeValue.trim() === '' || gaeValue === null}
									/>
								</Grid>
							</Grid>
						</Paper>

						<Paper className={classes.paperResults}>
							{isLoadingFirstData && <DataGrid headers={headerConfig} isLoading={isLoadingFirstData} data={[]} />}

							{firstData.length === 0 && !isLoadingFirstData && (
								<ImagesNoInformation query={'e'} titleData={'There are no search results, please perform a search'} />
							)}

							{firstData.length > 0 && !isLoadingFirstData && (
								<>
									<Typography className={classes.resultText} variant='subtitle1'>
										Select materials to continue
									</Typography>
									<div>
										<Grid container spacing={3}>
											<Grid item xs={8}>
												<FormControlLabel
													className={classes.paddingLeft}
													control={<Checkbox onChange={onSelectAllFistData} checked={allFistDataCheck} />}
													label='Select all'
												/>
											</Grid>
											<Grid item xs={4}>
												<SearchBox placeholder='Search materials' onChange={onChange} value={searchValue} />
											</Grid>
										</Grid>
										{filteredData.length > 0 && !isLoadingFirstData ? (
											<FirstStepView
												getSearchHeaderConfiguration={getSearchHeaderConfiguration}
												filteredData={filteredData}
												selectedItems={selectedItems}
												classes={classes}
											/>
										) : (
											<ImagesNoInformation query={'e'} titleData={'There are no search results, please try again.'} />
										)}
									</div>
								</>
							)}
						</Paper>
					</div>
				)

			case 1:
				return (
					<div>
						<Paper className={classes.paperSearchMat}>
							<Typography variant='subtitle2'>Complete the following information to perform a search.</Typography>
							<Grid container spacing={3} className={classes.gridSelectors}>
								<Grid item xs={3}>
									<SelectDateRange onDateRangeChange={onDateRangeChange} startDate={startDate} endDate={endDate} />
								</Grid>
								<Grid item xs={6}>
									{statisticsModel.Customer && statisticsModel.Customer.length === customersOptions.length ? (
										<ReactSelectAvatar
											isDisabled={true}
											id='Customer'
											name='Customer'
											placeholder={'All customers selected'}
											formatOptionLabel={formatOptionLabel}
											errors={errors}
											control={control}
											value={[]}
										/>
									) : (
										<ReactSelectAvatar
											id='Customer'
											name='Customer'
											placeholder={'Customer'}
											options={customersOptions}
											formatOptionLabel={formatOptionLabel}
											onChange={onSelectChange}
											errors={errors}
											control={control}
											isMulti={true}
											value={statisticsModel.Customer}
										/>
									)}
								</Grid>
								<Grid item xs={3} className={classes.gridItem}>
									<FormControlLabel
										control={
											<Checkbox
												checked={!!(statisticsModel.Customer && statisticsModel.Customer.length === customersOptions.length)}
												onChange={(e) => {
													if (e.target.checked) {
														onSelectAllChange('Customer', customersOptions)
													} else {
														onSelectAllChange('Customer', [])
													}
												}}
											/>
										}
										label='Select all customers'
									/>
								</Grid>
							</Grid>
							{renderContent()}
						</Paper>
					</div>
				)

			case 2:
				return (
					<div>
						<Paper className={classes.paperSearchMat}>
							<Typography variant='subtitle2'>Results</Typography>

							{isLoadingSecondData && (
								<DataGrid headers={getThirdStepHeaderConfiguration()} isLoading={isLoadingSecondData} data={[]} />
							)}

							{customersInvoiceSearchResult.length === 0 && !isLoadingSecondData && (
								<ImagesNoInformation query={'e'} titleData={'There are no search results, please perform a search'} />
							)}

							{customersInvoiceSearchResult.length > 0 && !isLoadingSecondData && renderContentStep3()}
						</Paper>
					</div>
				)
			default: {
				return null
			}
		}
	}

	/**
	 * Get the header configuration
	 */
	const getSearchHeaderConfiguration = (selectedItems) => {
		let checkboxProperty = headerSearchConfig.find((x) => x.dataSource === 'CheckBox')
		checkboxProperty.onRenderProperty = (item) => {
			return <Checkbox value={item.MATNR} onChange={handleCheckboxChange} checked={selectedItems.includes(item.MATNR)} />
		}

		let materialProperty = headerSearchConfig.find((x) => x.dataSource === 'MATNR')
		materialProperty.onRenderProperty = (item) => {
			return <Typography>{item.MATNR}</Typography>
		}

		let descriptionProperty = headerSearchConfig.find((x) => x.dataSource === 'MAKTX')
		descriptionProperty.onRenderProperty = (item) => {
			return <Typography>{item.MAKTX}</Typography>
		}

		let gaeDenominationProperty = headerSearchConfig.find((x) => x.dataSource === 'EWBEZ')

		gaeDenominationProperty.onRenderProperty = (item) => {
			return <Typography>{item.EWBEZ}</Typography>
		}

		let gaeProperty = headerSearchConfig.find((x) => x.dataSource === 'EXTWG')

		gaeProperty.onRenderProperty = (item) => {
			return <Typography>{item.EXTWG}</Typography>
		}
		return headerSearchConfig
	}

	/**
	 * Get the header configuration
	 */
	const getSecondStepHeaderConfiguration = () => {
		let materialProperty = headerSecondStepConfig.find((x) => x.dataSource === 'MATNR')
		materialProperty.onRenderProperty = (item) => {
			return <Typography>{item.MATNR}</Typography>
		}

		let descriptionProperty = headerSecondStepConfig.find((x) => x.dataSource === 'MAKTX')
		descriptionProperty.onRenderProperty = (item) => {
			return <Typography>{item.MAKTX}</Typography>
		}

		let gaeDenominationProperty = headerSecondStepConfig.find((x) => x.dataSource === 'EXTWG')

		gaeDenominationProperty.onRenderProperty = (item) => {
			return <Typography>{item.EXTWG}</Typography>
		}

		let materialGroupProperty = headerSecondStepConfig.find((x) => x.dataSource === 'EWBEZ')
		materialGroupProperty.onRenderProperty = (item) => {
			return <Typography>{item.EWBEZ}</Typography>
		}
		return headerSecondStepConfig
	}

	/**
	 * Get the third header configuration
	 */
	const getThirdStepHeaderConfiguration = () => {
		let materialProperty = headerThirdStepConfig.find((x) => x.dataSource === 'MATNR')
		materialProperty.onRenderProperty = (item) => {
			return <Typography>{item.MATNR}</Typography>
		}

		let descriptionProperty = headerThirdStepConfig.find((x) => x.dataSource === 'ARKTX')
		descriptionProperty.onRenderProperty = (item) => {
			return <Typography>{item.ARKTX}</Typography>
		}

		let quantityFactProperty = headerThirdStepConfig.find((x) => x.dataSource === 'FKIMG')
		quantityFactProperty.onRenderProperty = (item) => {
			return <Typography>{parseFloat(item.FKIMG).toFixed(3)}</Typography>
		}

		let paymentRespProperty = headerThirdStepConfig.find((x) => x.dataSource === 'KUNRG')
		paymentRespProperty.onRenderProperty = (item) => {
			return <Typography>{item.KUNRG}</Typography>
		}

		let invoiceDateProperty = headerThirdStepConfig.find((x) => x.dataSource === 'FKDAT')
		invoiceDateProperty.onRenderProperty = (item) => {
			return <Typography>{item && item.FKDAT ? moment(item.FKDAT).format('YYYY-MM-DD') : ''}</Typography>
		}
		return headerThirdStepConfig
	}

	const onSelectAllFistData = () => {
		if (allFistDataCheck) {
			if (selectedItems.length === firstData.length) {
				setSelectedItems([])
				setAllFistDataCheck(false)
			} else {
				setSelectedItems(firstData?.map((x) => x.MATNR))
			}
		} else {
			setSelectedItems(firstData?.map((x) => x.MATNR))
			setAllFistDataCheck(true)
		}
	}

	/**Render content in cads o en mode list */
	const renderContent = () => {
		return <DataGrid headers={getSecondStepHeaderConfiguration()} data={firstData.filter((item) => selectedItems.includes(item.MATNR))} />
	}

	/**Render content in cads o en mode list */
	const renderContentStep3 = () => {
		return (
			<div>
				<DataGrid
					headers={getThirdStepHeaderConfiguration()}
					data={allCustomersInvoiceResultPaginated}
					onChangeSortingCriteria={onChangeSortingCriteria}
					sortBy={sortingCriteria.by}
					sortDirection={sortingCriteria.sortDirection}
				/>
				<div className={classes.pagination}>
					<div className={classes.flexGrow} />
					<Pagination count={pagination.total ? pagination.total : 0} page={currentPage ? currentPage : 0} onChangePage={onChangePage} />
				</div>
			</div>
		)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Statistics</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={13} userProfile={userProfile} />

			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar title='STATISTICS' icon={iconSparePart} />
					<ServiceProviderStepper activeStep={activeStep} />
					<Card className={classes.cardContainer}>
						<CardContent className={classes.cardContent}>{getStepComponent(activeStep)}</CardContent>
						<CardActions className={classes.actionButton}>
							{activeStep !== 0 && (
								<Button variant='contained' color={'primary'} disabled={false} onClick={onMoveBackWardHandler}>
									BACK
								</Button>
							)}
							{activeStep === 1 && (
								<Button
									variant='contained'
									color={'primary'}
									disabled={!isAnyCustomerSelected || isLoadingSecondData}
									onClick={() => {
										getCustomerInvoice(selectedItems, startDate, endDate, statisticsModel)
										onMoveForwardHandler()
									}}
								>
									SEARCH
								</Button>
							)}

							{activeStep === 0 && (
								<div className={classes.actionButtonNext}>
									<Button variant='contained' color={'primary'} disabled={!selectedItems.length > 0} onClick={onMoveForwardHandler}>
										NEXT
									</Button>
								</div>
							)}

							{activeStep === 2 && (
								<div>
									<Button
										variant='contained'
										color={'primary'}
										disabled={isDownloadReport}
										onClick={handleReset}
										className={classes.resetButton}
									>
										RESET
									</Button>
									<Button
										variant='contained'
										color={'primary'}
										disabled={isDownloadReport || customersInvoiceSearchResult.length < 1}
										onClick={() => handleDownloadStatisticsReport(customersInvoiceSearchResult)}
									>
										DOWNLOAD
									</Button>
								</div>
							)}
						</CardActions>
					</Card>
				</div>
			</Content>
		</Layout>
	)
}

const formatOptionLabel = ({ value, label, avatarName, avatarUrl, isAvatarFromIdentity }) => (
	<div>
		<Chip
			id={value}
			size='small'
			avatar={<AvatarCustomSize avatarUrl={avatarUrl} name={avatarName} isAvatarFromIdentity={isAvatarFromIdentity} />}
			label={label}
			clickable
			style={{ color: 'white', background: '#5357DC' }}
		/>
	</div>
)
export default withImmutablePropsToJS(ServiceProviderRegisterDesktopView)
