/**Import actions section */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
/**Import components */

import DialogEvidence from './DialogEvidence'
import ConfirmPasswordDialog from '../../common/ConfirmPasswordDialog'
import Toaster from '../../common/Toaster'

/**import actions section */
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';
import {
    uploadDocuments,
    removeAttachment,
    accessIsGrantedToPortal
} from '../../../store/serviceproviders/serviceProvidersActions'
import { toSafeObject } from '../../../store/helpers/StateHelper'
import { PROVIDER_REGISTRATION_VALIDATOR } from '../../../resources/RolesEnum'
import { getEmailUsersByRole } from '../../../store/helpers/UserHelper'

const AddRegister = (props) => {
    /**Objecs props*/
    const { openEvidenceDialog, passwordDialogConfiguration, passwordDialogAccess } = props;
    /**Function props */
    const { onOpenEvidenceDialog, serviceProvider, administrator,
        onOpenDialogPassword, onOpenDialogForGrantedAccess } = props;
    /**Hooks sections */
    const dispatch = useDispatch()

    const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
    const isSaving = useSelector((state) => state.serviceProviders.get('isSaving'))
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    let emailsRoleValidator = getEmailUsersByRole(PROVIDER_REGISTRATION_VALIDATOR, internalUsers)

    
    const onUploadDocument = (files, evidenceModel) => {
        return dispatch(uploadDocuments(files, evidenceModel, serviceProvider ? serviceProvider.Code : '', emailsRoleValidator))
            .then(_ => {
                onOpenEvidenceDialog()
                setToasterOptions({ open: true, message: "Document successfully uploaded", variant: 'success' })
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error adding document', variant: 'warning' })
            });
    }

    const onRemoveAttachment = () => {
        return dispatch(removeAttachment(serviceProvider ? serviceProvider.Token : '', passwordDialogConfiguration ? passwordDialogConfiguration.attachment.Token : ''))
            .then(_ => {
                onOpenDialogPassword()
                setToasterOptions({ open: true, message: "Document successfully deleted", variant: 'success' })
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to deleted document', variant: 'warning' })
            });
    }

    const onAccessIsGrantedToPortal = (passwordModel) => {
        return dispatch(accessIsGrantedToPortal(serviceProvider ? serviceProvider.Token : '', passwordDialogAccess ? passwordDialogAccess.isGrantedAccess: false, passwordModel, administrator))
            .then(_ => {
                onOpenDialogForGrantedAccess()
                setToasterOptions({ open: true, message: (passwordDialogAccess ? (passwordDialogAccess.isGrantedAccess ? "Access granted": "Acces denied"): ""), variant: 'success' })
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to confirm operaation', variant: 'warning' })
            });
    }

    return (
        <div>

            {
                openEvidenceDialog.opened &&
                <DialogEvidence
                    open={openEvidenceDialog.opened}
                    onClose={onOpenEvidenceDialog}
                    onSaveEvidence={onUploadDocument}
                    isSaving={isSaving}

                />
            }

            {
                passwordDialogConfiguration.opened &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={onRemoveAttachment}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onOpenDialogPassword}
                    message1={`This action will permanently delete the document to this portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                passwordDialogAccess.opened &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogAccess.opened}
                    onClickButtonConfirm={onAccessIsGrantedToPortal}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onOpenDialogForGrantedAccess}
                    isVisibleComments={true}
                    commentRequired={passwordDialogAccess ? !passwordDialogAccess.isGrantedAccess : false}
                    message1={passwordDialogAccess ? (!passwordDialogAccess.isGrantedAccess ? `This action does not grant access to the platform. Are you sure to proceed?` : "This action grants access to the platform. Are you sure to proceed?") : ""}
                    message2="Enter the password to confirm the operation"
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }


        </div>
    )
}

export default withImmutablePropsToJS(AddRegister)
