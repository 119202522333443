const ServiceProviderRegisterTableSearchConfig = [
	{
		title: 'Select',
		dataSource: 'CheckBox',
		sortable: false,
		width: 50
	},
	{
		title: 'Gae',
		dataSource: 'EXTWG',
		sortable: true,
		width: 250
	},
	{
		title: 'Material number',
		dataSource: 'MATNR',
		sortable: true,
		width: 250
	},
	{
		title: 'Material Description',
		dataSource: 'MAKTX',
		sortable: true,
		width: 250
	},
	{
		title: 'Grupo Articulo',
		dataSource: 'EWBEZ',
		width: 500
	}
]
export default ServiceProviderRegisterTableSearchConfig
