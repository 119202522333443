
import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'
import { mapUserServerResponse, getFullUsersInformation, mapCombineUserFromIdentity } from '../users/UsersActions'

export const IS_LOADING_SALES_REPRESENTATIVES = 'IS_LOADING_SALES_REPRESENTATIVES'
export const SET_SALES_REPRESENTATIVES = 'SET_SALES_REPRESENTATIVES'
export const UPDATE_SALES_REPRESENTATIVE = 'UPDATE_SALES_REPRESENTATIVE'
export const IS_SAVING = 'IS_SAVING'
export const ADD_SALES_REPRESENTATIVE = 'ADD_SALES_REPRESENTATIVE'
export const REMOVE_USER_FROM_ACCOUNT = 'REMOVE_USER_FROM_ACCOUNT'
export const SET_REPRESENTATIVE_USERS = 'SET_REPRESENTATIVE_USERS'
export const SET_REPRESENTATIVES_QUERYPARAMETERS = 'SET_REPRESENTATIVES_QUERYPARAMETERS'
export const ADD_USER_IN_SALES_REPRESENTATIVE = 'ADD_USER_IN_SALES_REPRESENTATIVE'
export const UPDATE_SALES_REPRESENTATIVE_USERS = 'UPDATE_SALES_REPRESENTATIVE_USERS'
export const SET_ROLES_IN_PROFILE = 'SET_ROLES_IN_PROFILE'
export const UPDATE_LAST_DATE_USER_REP = "UPDATE_LAST_DATE_USER_REP"
export const UPDATE_LAST_ACTIVE_NOTIFICATIONS_SALES_RERESENTATIVE_USER = "UPDATE_LAST_ACTIVE_NOTIFICATIONS_SALES_RERESENTATIVE_USER"

/**
 * Is loading action constructor
 */
export const isLoadingRepresentations = (status) => {
    return {
        type: IS_LOADING_SALES_REPRESENTATIVES,
        status
    }
}

/**
 * Set Sales Representatives action creator
 */
export const setSalesRepresentatives = (data) => {
    return {
        type: SET_SALES_REPRESENTATIVES,
        data
    }
}

/*
Update sales representative* */
export const setUpdateSalesRepresentative = (representative) => {
    return {
        type: UPDATE_SALES_REPRESENTATIVE,
        representative
    }
}

/**
 * Is saving
 */
export const isSaving = (status) => {
    return {
        type: IS_SAVING,
        status
    }
}

export const addRepresentative = (representative) => {
    return {
        type: ADD_SALES_REPRESENTATIVE,
        representative
    }
}

/**
 * Set all representative users
 */
export const setRepresentativeUsers = (users) => {
    return {
        type: SET_REPRESENTATIVE_USERS,
        users
    }
}

/**
 * Set internal user sort criteria
 */
export const setInternalQueryParameters = (queryParametes) => {
    return {
        type: SET_REPRESENTATIVES_QUERYPARAMETERS,
        queryParametes
    }
}

export const addUserInRepresentative = (user) => {
    return {
        type: ADD_USER_IN_SALES_REPRESENTATIVE,
        user
    }
}

export const updateSalesRepresentativeUser = (user) => {
    return {
        type: UPDATE_SALES_REPRESENTATIVE_USERS,
        user
    }
}

/** 
 * Set the notifications state
*/
export const updateActiveNotifications = (user) => {
    return {
        type: UPDATE_LAST_ACTIVE_NOTIFICATIONS_SALES_RERESENTATIVE_USER,
        user
    }
}


/**
 * Set last date User
 */
export const updateLastDateUser = (user) => {
    return {
        type: UPDATE_LAST_DATE_USER_REP,
        user
    }
}


export const loadAllSalesRepresentatives = (queryParameters, pageSize = 10) => {
    return function (dispatch, getState) {
        let loadedSalesRepresentives = getState().salesRepresentatives.get('salesRepresentatives')
        if (loadedSalesRepresentives && loadedSalesRepresentives.size > 0 && queryParameters === {}) {
            return Promise.resolve()
        }

        dispatch(isLoadingRepresentations(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/salesrepresentatives`
        let command = {
            PageNumber: queryParameters ? queryParameters.pageNumber : 1,
            PageSize: pageSize,
            OrderBy: queryParameters ? queryParameters.sortBy : null,
            OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
            Query: queryParameters && (!!queryParameters.query ? queryParameters.query : "")
        }
        return axios.post(url, command, config)
            .then(serverResponse => {
                dispatch(isLoadingRepresentations(false));
                dispatch(setSalesRepresentatives(serverResponse.data));
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isLoadingRepresentations(false));
                console.error("Error loading all sales representatives", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const updateSalesRepresentative = (representativeModel) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/salesrepresentatives`
        let command = {
            Token: representativeModel ? representativeModel.Token : "",
            Code: representativeModel ? representativeModel.CodeSap : "",
            Name: representativeModel ? representativeModel.Name : "",

        }
        return axios.put(url, command, config)
            .then(serverResponse => {
                dispatch(isSaving(false))
                dispatch(setUpdateSalesRepresentative(serverResponse.data));
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to update sales representative", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const addSalesRepresentative = (representativeModel) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/salesrepresentatives/add`
        let command = {
            Name: representativeModel ? representativeModel.Name : "",
        }
        return axios.post(url, command, config)
            .then(serverResponse => {
                dispatch(isSaving(false))
                dispatch(addRepresentative(serverResponse.data));
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to update sales representative", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

/**Upload imagen account */
export const uploadLogoAccount = (token, fileBase64) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/salesrepresentatives/uploadAvatar`
        let command = {
            FileParameter: fileBase64,
            Token: token
        };
        return axios.put(url, command, config)
            .then((serverResponse) => {
                dispatch(isSaving(false));
                dispatch(setUpdateSalesRepresentative(serverResponse.data));
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error uploading account avatar", error);
                return Promise.reject();
            });
    };
};

export const lockedUser = (token, locked) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/salesrepresentatives/locked`
        let command = {
            Token: token,
            Locked: locked
        }
        return axios.put(url, command, config)
            .then(serverResponse => {
                dispatch(isSaving(false))
                dispatch(setUpdateSalesRepresentative(serverResponse.data));
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to lock user", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const loadUserInRepresentative = (queryParameters, code) => {
    return async (dispatch, getState) => {
        try {
            const loadUsersFromBackend = async (queryParameters) => {
                dispatch(isLoadingRepresentations(true))
                // Get the authorized configuration
                let user = getState().oidc.user
                let config = getAuthorizedConfiguration(user)
                // Create command
                let command = {
                    AccountCode: code,
                }
                // Get the users
                let url = `${window.config.serviceManagementBackendUrl}/users/account`
                let serverResponse = await axios.post(url, command, config)
                let usersResult = mapUserServerResponse(serverResponse.data)
                await getFullUsersInformation(usersResult, null)
                dispatch(setRepresentativeUsers(usersResult))
                if (queryParameters) {
                    dispatch(setInternalQueryParameters(queryParameters))
                }
            }
            await loadUsersFromBackend(queryParameters)

        } catch (ex) {
            console.error('Errog getting the internal users in representatives list', ex)
        }
    }
}

export const addUserInSalesRepresentative = (representativeModel, code) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let command = {
            Name: representativeModel ? representativeModel.Name : "",
            AccountCode: code,
            UserName: representativeModel ? representativeModel.Email : "",
            Email: representativeModel ? representativeModel.Email : "",
            Password: representativeModel ? representativeModel.Password : "",
            PhoneNumber: representativeModel ? representativeModel.Password : "",
        }
        const { identityServerUrl, serviceManagementBackendUrl } = window.config
        let url = `${serviceManagementBackendUrl}/user`
        let urlIdentityServer = `${identityServerUrl}/api/externaluser`
        let identityUser;
        return axios.post(urlIdentityServer, command)
            .then(identityUserResponse => {
                identityUser = identityUserResponse.data;
                command.UserName = identityUser.user_name
                return axios.post(url, command, config)
            })
            .then(serverResponse => {
                dispatch(isSaving(false))
                let mappedUser = mapCombineUserFromIdentity(serverResponse.data, identityUser)
                dispatch(addUserInRepresentative(mappedUser));
                return Promise.resolve(mappedUser);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to add user in sales representative", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

/**save information internal user */
export const saveExternalUser = (user) => {
    return function (dispatch, getState) {
        // Get the authorized configuration
        let userConfig = getState().oidc.user
        let config = getAuthorizedConfiguration(userConfig)
        const { identityServerUrl, serviceManagementBackendUrl } = window.config;
        dispatch(isSaving(true))
        let identityCommand = {
            Email: user.Email,
            Name: user.Name,
            PhoneNumber: user.PhoneNumber
        };
        let endPoint = `${identityServerUrl}/api/user/${user.Id}`;
        let url = `${serviceManagementBackendUrl}/user/profile/${user.Token}`;
        let identityUser;
        return axios.put(endPoint, identityCommand)
            .then((identityUserResponse) => {
                identityUser = identityUserResponse.data;
                return axios.get(url, config)
            })
            .then((managerResponse) => {
                dispatch(isSaving(false));
                let mappedUser = mapCombineUserFromIdentity(managerResponse.data, identityUser)
                dispatch(updateSalesRepresentativeUser(mappedUser));
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error to save user", error);
                return Promise.reject(error);
            });
    };
};

/**Upload imagen dialog user */
export const uploadLogoExternalUser = (userId, fileBase64, token) => {
    return function (dispatch, getState) {
        const { identityServerUrl, serviceManagementBackendUrl } = window.config
        dispatch(isSaving(true))
        let endPoint = `${identityServerUrl}/api/user/${userId}/avatar`;
        let data = { fileBase64 };
        let identityUser;
        let userConfig = getState().oidc.user
        let config = getAuthorizedConfiguration(userConfig)
        let url = `${serviceManagementBackendUrl}/user/profile/${token}`;
        return axios.put(endPoint, data)
            .then((identityUserResponse) => {
                dispatch(isSaving(false));
                identityUser = identityUserResponse.data;
                return axios.get(url, config)
            })
            .then((managerResponse) => {
                dispatch(isSaving(false));
                let mappedUser = mapCombineUserFromIdentity(managerResponse.data, identityUser)
                dispatch(updateSalesRepresentativeUser(mappedUser));
                return Promise.resolve(mappedUser);
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error uploading user avatar", error);
                return Promise.reject();
            });
    };
};



/** This function search for imbera users in sales representative to find out if it has already been added */
export const lookUserInSalesRepresentative = userName => {
    return function (dispatch, getState) {
        let allUsers = getState().salesRepresentatives.get('representativeUsers');
        let foundUser = allUsers.find(u => u.get('UserName').toLowerCase() === userName)
        return Promise.resolve(foundUser ? true : false);
    }
}

export const editActiveNotifications = (token, activeNotification) => {
    return function (dispatch, getState) {
        const { serviceManagementBackendUrl } = window.config
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let command = {
            Token: token,
            ActiveNotifications: activeNotification
        };
        let url = `${serviceManagementBackendUrl}/user/notifications/activation`
        return axios.put(url, command, config)
            .then(serverResponse => {
                dispatch(updateActiveNotifications(serverResponse.data))
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to change Notification status", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const downloadSalesRepresentativesList = () => {
    return function (dispatch, getState) {
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let endPoint = `${window.config.serviceManagementBackendUrl}/salesrepresentatives/salesRepresentativeReport`
        return axios
            .post(
                endPoint,
                {},
                {
                    responseType: 'arraybuffer',
                    headers: config
                }
            )
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    var blob = new Blob([serverResponse.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    })
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = `Sales_representatives_list.xlsx`
                    a.click()
                    return Promise.resolve('Ok')
                }
            })
            .catch((error) => {
                console.error('Error donwload list of sales representatives', error)
                return Promise.reject()
            })
    }
}
