/**Import react section */
import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import {
	ServicesStatusTypes,
	DELETED,
	CHANGE_INITIATED_DATE,
	ASSIGNED_SERVICE_PROVIDER_TECH
} from '../../store/helpers/AppConstants'
/**Import components section */
import ChangeStatusPanel from './components/ChangeStatusPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'
import Toaster from '../common/Toaster'
import {
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	PORTAL_ADMINISTRATOR,
	DISPATCHER,
	IMBERA_TECHNICIAN
} from '../../resources/RolesEnum'

/**Import actions sections */
import {
	cancelService,
	suspendedService,
	completedService,
	replacedService,
	deleteService,
	changeInitialDateService,
	assignementService,
	assignerdServiceProviderTech,
	changeRepresentativeSales,
	reasisngedServiceProvider
} from '../../store/services/serviceActions'
import { removeNotificationUser } from '../../store/users/UsersActions'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { getEmailInternalUsersByRole } from '../../store/helpers/UserHelper'

const Edit = (props) => {
	/**Custom hooks sections */
	const dispatch = useDispatch()
	let history = useHistory()
	const {
		lateralPanelStatusConfig,
		openLateralPanelChangeStatus,
		isSavingService,
		userProfile
	} = props
	/**State local for this component*/
	const [
		passwordDialogConfiguration,
		setPasswordDialogConfiguration
	] = useState({ opened: false, statusModel: null })
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const internalUsers = useSelector((state) =>
		toSafeObject(state.users.get('internalUsers'))
	)
	let match = useRouteMatch()
	const serie = match.params.serie

	const isValidatingPassword = useSelector((state) =>
		state.users.get('isValidatingPassword')
	)
	const [service, setService] = useState(null)
	let emailsInternalUsers = getEmailInternalUsersByRole(
		[
			SPARE_PARTS_IMBERA_MX,
			SPARE_PARTS_IMBERA_USA,
			PORTAL_ADMINISTRATOR,
			DISPATCHER,
			IMBERA_TECHNICIAN
		],
		internalUsers
	)
	useEffect(() => {
		setService(lateralPanelStatusConfig.service)
	}, [lateralPanelStatusConfig.service])

	/**Open/close dialog confirm dialog user*/
	const onSetPasswordDialogConfiguration = (statusModel) => {
		setPasswordDialogConfiguration({
			opened: !passwordDialogConfiguration.opened,
			statusModel: statusModel
		})
	}

	/**
	 * cancel service
	 */
	const onCancelService = (paswordModel) => {
		return dispatch(
			cancelService(
				service.Token,
				passwordDialogConfiguration.statusModel,
				paswordModel.identifier,
				emailsInternalUsers
			)
		)
			.then((service) => {
				onSetPasswordDialogConfiguration()
				setService(service)
				setToasterOptions({
					open: true,
					message: 'Service canceled success',
					variant: 'success'
				})
				openLateralPanelChangeStatus()
				dispatch(removeNotificationUser(service.Token))
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to cancel servie',
					variant: 'warning'
				})
				console.error('Failed to cancel service', error)
			})
	}

	const onSuspendedService = (paswordModel) => {
		return dispatch(
			suspendedService(
				service.Token,
				passwordDialogConfiguration.statusModel,
				paswordModel.identifier
			)
		)
			.then((service) => {
				onSetPasswordDialogConfiguration()
				setService(service)
				setToasterOptions({
					open: true,
					message: 'Service suspended success',
					variant: 'success'
				})
				openLateralPanelChangeStatus()
				dispatch(removeNotificationUser(service.Token))
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to suspended servie',
					variant: 'warning'
				})
				console.error('Failed to suspended service', error)
			})
	}

	const onCompletedService = (paswordModel) => {
		return dispatch(
			completedService(
				service.Token,
				paswordModel.identifier,
				passwordDialogConfiguration.statusModel,
				serie
			)
		)
			.then((service) => {
				onSetPasswordDialogConfiguration()
				setService(service)
				setToasterOptions({
					open: true,
					message: 'Service completed success',
					variant: 'success'
				})
				openLateralPanelChangeStatus()
				dispatch(removeNotificationUser(service.Token))
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to completed servie',
					variant: 'warning'
				})
				console.error('Failed to completed service', error)
			})
	}

	const onReplacedService = (paswordModel) => {
		return dispatch(
			replacedService(
				service.Token,
				passwordDialogConfiguration.statusModel,
				paswordModel.identifier
			)
		)
			.then((service) => {
				onSetPasswordDialogConfiguration()
				setService(service)
				setToasterOptions({
					open: true,
					message: 'Service replaced success',
					variant: 'success'
				})
				openLateralPanelChangeStatus()
				dispatch(removeNotificationUser(service.Token))
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to replaced servie',
					variant: 'warning'
				})
				console.error('Failed to replaced service', error)
			})
	}

	const onDeleteService = (paswordModel) => {
		return dispatch(deleteService(service.Token, paswordModel.identifier))
			.then(() => {
				dispatch(removeNotificationUser(service.Token))
				history.push(`/`)
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to delete servie',
					variant: 'warning'
				})
				console.error('Failed to delete service', error)
			})
	}

	const onChangeInitialDateService = (paswordModel) => {
		return dispatch(
			changeInitialDateService(
				service.Token,
				paswordModel.identifier,
				passwordDialogConfiguration.statusModel
			)
		)
			.then((service) => {
				onSetPasswordDialogConfiguration()
				setService(service)
				setToasterOptions({
					open: true,
					message: 'Service change initial date success',
					variant: 'success'
				})
				openLateralPanelChangeStatus()
				return Promise.resolve()
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to change initial date servie',
					variant: 'warning'
				})
				console.error('Failed to change initial date service', error)
			})
	}

	/**
	 * Function to assigned Service to Tech Assigned
	 * @param {*} series
	 */
	const onAssignementService = (assignementModel) => {
		return dispatch(assignementService(service.Token, assignementModel))
			.then(() => {
				dispatch(removeNotificationUser(service.Token))
				openLateralPanelChangeStatus()
				setToasterOptions({
					open: true,
					message: 'Service assigned successfully',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to assigned service to tech assigned',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to assigned Service to Tech Assigned
	 * @param {*} series
	 */
	const onAssignerdServiceProviderTech = (assignementModel) => {
		return dispatch(
			assignerdServiceProviderTech(service.Token, assignementModel)
		)
			.then(() => {
				openLateralPanelChangeStatus()
				setToasterOptions({
					open: true,
					message: 'Service assigned successfully',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to assigned service to tech assigned',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to change rep to
	 * @param {*} series
	 */
	const onChangeRepresentanteSales = (assignementModel) => {
		return dispatch(
			changeRepresentativeSales(service.Token, assignementModel)
		)
			.then(() => {
				openLateralPanelChangeStatus()
				setToasterOptions({
					open: true,
					message: 'Service representative change successfully',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to change representative in service',
					variant: 'warning'
				})
			})
	}

	/**
	 * Function to reasigned provider
	 * @param {*} series
	 */
	const onReasisngedServiceProviderr = (assignementModel) => {
		return dispatch(
			reasisngedServiceProvider(service.Token, assignementModel)
		)
			.then(() => {
				openLateralPanelChangeStatus()
				setToasterOptions({
					open: true,
					message: 'Service provider reasigned successfully',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to reasigned service provider in service',
					variant: 'warning'
				})
			})
	}

	const GetFunctionToInvoke = (status) => {
		switch (Number(status)) {
			case ServicesStatusTypes.CANCELLED:
				return onCancelService
			case ServicesStatusTypes.SUSPENDED:
				return onSuspendedService
			case ServicesStatusTypes.COMPLETED:
				return onCompletedService
			case ServicesStatusTypes.REPLACED:
				return onReplacedService
			case DELETED:
				return onDeleteService
			case CHANGE_INITIATED_DATE:
				return onChangeInitialDateService
			default:
				return null
		}
	}

	const GetStatusDescription = (status) => {
		switch (Number(status)) {
			case ServicesStatusTypes.CANCELLED:
				return 'CANCEL'
			case ServicesStatusTypes.SUSPENDED:
				return 'SUSPENDED'
			case ServicesStatusTypes.COMPLETED:
				return 'COMPLETED'
			case ServicesStatusTypes.REPLACED:
				return 'REPLACED'
			case DELETED:
				return 'DELETED'
			case CHANGE_INITIATED_DATE:
				return 'CHANGE INITIATED DATE'
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return 'ASSIGN TECHNICAL PROVIDER'
			default:
				return null
		}
	}

	return (
		<>
			{lateralPanelStatusConfig.opened && (
				<LateralPanel onClose={openLateralPanelChangeStatus}>
					<ChangeStatusPanel
						onCloseButtonClick={openLateralPanelChangeStatus}
						service={service}
						onAssignementService={onAssignementService}
						isSavingService={isSavingService}
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
						onAssignementTechServiceProvider={
							onAssignerdServiceProviderTech
						}
						onChangeRepresentanteSales={onChangeRepresentanteSales}
						onReasisngedServiceProviderr={
							onReasisngedServiceProviderr
						}
					/>
				</LateralPanel>
			)}

			{passwordDialogConfiguration.opened && (
				<ConfirmPasswordDialog
					title={'Confirm operation'}
					open={passwordDialogConfiguration.opened}
					onClickButtonConfirm={GetFunctionToInvoke(
						passwordDialogConfiguration
							? passwordDialogConfiguration.statusModel &&
							passwordDialogConfiguration.statusModel
								.status
							: null
					)}
					isSaving={isValidatingPassword || isSavingService}
					onClose={onSetPasswordDialogConfiguration}
					message1={`This action will change the status of the service to ${GetStatusDescription(
						passwordDialogConfiguration.statusModel &&
						passwordDialogConfiguration.statusModel.status
					)} . Are you sure to proceed  ${userProfile ? userProfile.name : ''
						}?`}
					message2='Enter the password to confirm the operation'
					isVisibleTicket={true}
					labelForIdentifier={'Enter the ticket'}
				/>
			)}
			{/** Toaster */}
			{toasterOptions.open && (
				<Toaster
					toasterOptions={toasterOptions}
					onClose={() => setToasterOptions({ open: false })}
				/>
			)}
		</>
	)
}

Edit.props = {
	/**Service to change status*/
	lateralPanelStatusConfig: propTypes.shape({
		opened: propTypes.bool.isRequired,
		service: propTypes.object.isRequired
	}),
	/**open and close lateral panel config*/
	openLateralPanelChangeStatus: propTypes.func,
	/**Determinate if is saving data in the back end */
	isSavingService: propTypes.bool.isRequired
}
Edit.defaultProps = {
	lateralPanelStatusConfig: {
		opened: false,
		service: {}
	},
	isSavingService: false,
	openLateralPanelChangeStatus: () =>
		console.warn('Callback [openLateralPanelChangeStatus] no defined')
}

export default withImmutablePropsToJS(Edit)
