import axios from 'axios'
import { toSafeObject } from '../helpers/StateHelper'
import { getAuthorizedConfiguration, getAuthorizedConfigurationParameters } from '../helpers/RequestHelper'
import moment from 'moment'
import { isNullOrEmpty } from '../helpers/StringHelper'

export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE'
export const SET_SPAREPARTREQUEST_DRAFT = 'SET_SPAREPARTREQUEST_DRAFT'
export const IS_GETTING_SPAREPARTREQUEST_DRAFT = 'IS_GETTING_SPAREPARTREQUEST_DRAFT'
export const IS_SAVING_DRAFT = 'IS_SAVING_DRAFT'
export const REMOVE_SPARE_PART = 'REMOVE_SPARE_PART'
export const IS_LOADING_REQUEST_LIST = 'IS_LOADING_REQUEST_LIST'
export const SET_SPAREPARTREQUEST_LIST = 'SET_SPAREPARTREQUEST_LIST'
export const UPDATE_SPARE_PART_REQUEST = 'UPDATE_SPARE_PART_REQUEST'
export const IS_LOADING_SPARE_PART_REQUEST = 'IS_LOADING_SPARE_PART_REQUEST'
export const SET_SPAREPARTREQUEST_OPEN = 'SET_SPAREPARTREQUEST_OPEN'
export const UPDATE_SPARE_PART = 'UPDATE_SPARE_PART'
export const IS_SAVING_SPARE_PART_REQUEST = 'IS_SAVING_SPARE_PART_REQUEST'
export const IS_DONWLOAD_REPORT = 'IS_DONWLOAD_REPORT'
export const IS_DOWNLOAD_PLANE = 'IS_DOWNLOAD_PLANE'
export const SET_CARRIER_DATA = 'SET_CARRIER_DATA'
export const GETTING_INFORMATION_TRACKING = 'GET_INFORMATION_TRACKING'
export const SET_HISTORY_SERIAL_NUMBER = 'SET_HISTORY_SERIAL_NUMBER'
export const SET_SERVICE_ADDRESSES = 'SET_SERVICE_ADDRESSES'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const IS_LOADING_DASHBOAR = 'IS_LOADING_DASHBOAR'
export const SET_DASHBOAR = 'SET_DASHBOAR'
export const REMOVE_SPARE_PART_REQUESTS = 'REMOVE_SPARE_PART_REQUESTS'
export const UPDATE_EQUIPMENT_MANUAL = 'UPDATE_EQUIPMENT_MANUAL'
export const IS_LOADNG_FROM_SAP = 'IS_LOADNG_FROM_SAP'
export const SET_SPARE_PARTS_FROM_SAP = 'SET_SPARE_PARTS_FROM_SAP'
export const SET_QUERY_SAP = 'SET_QUERY_SAP'
export const IS_SAVING_DRAFT_REQUEST = 'IS_SAVING_DRAFT_REQUEST'
export const ADD_FILES = 'ADD_FILES'
export const REMOVE_FILES = 'REMOVE_FILES'
export const SET_EXISTENCE_MATERIAL = 'SET_EXISTENCE_MATERIAL'
export const IS_LOADDING_ADDRESS = 'IS_LOADDING_ADDRESS'
export const IS_LOADDING_HISTORYA_SERIES = 'IS_LOADDING_HISTORYA_SERIES'

/**
 * set existence material getting from sap
 */
const setExistenceMaterialAction = (code, data, updateType) => {
	return {
		type: SET_EXISTENCE_MATERIAL,
		data: data,
		code: code,
		updateType: updateType
	}
}

/**
 * Is getting spare part request draft
 */
const isGettingRequestDraft = (status = true) => {
	return {
		type: IS_GETTING_SPAREPARTREQUEST_DRAFT,
		status: status
	}
}

/**
 * Is saving draft
 */
const isSavingDraft = (status = true) => {
	return {
		type: IS_SAVING_DRAFT,
		status: status
	}
}

const isLoading = (status = true) => {
	return {
		type: IS_LOADING_SPARE_PART_REQUEST,
		status: status
	}
}

/**
 * Is saving request
 * @param {*} status
 */
const isSavingRequest = (status = true) => {
	return {
		type: IS_SAVING_SPARE_PART_REQUEST,
		status: status
	}
}

const setIsDownloadReport = (status) => {
	return {
		type: IS_DONWLOAD_REPORT,
		status
	}
}

const setIsDownloadPlane = (status) => {
	return {
		type: IS_DOWNLOAD_PLANE,
		status
	}
}

export const gettingTrackingData = (status) => {
	return {
		type: GETTING_INFORMATION_TRACKING,
		status
	}
}

export const isLoadingHistorialAddress = (status) => {
	return {
		type: IS_LOADDING_HISTORYA_SERIES,
		status: status
	}
}

export const setHistorialSerial = (data) => {
	return {
		type: SET_HISTORY_SERIAL_NUMBER,
		data
	}
}

export const isLoadingAddress = (status) => {
	return {
		type: IS_LOADDING_ADDRESS,
		status: status
	}
}

export const setServiceAddresses = (data) => {
	return {
		type: SET_SERVICE_ADDRESSES,
		data
	}
}

export const setCarrierInformation = (data) => {
	return {
		type: SET_CARRIER_DATA,
		data
	}
}

export const updateStatusTraking = (status, trackingNumber) => {
	return {
		type: UPDATE_STATUS,
		status,
		trackingNumber
	}
}

/**
 * Is loading action constructor
 */
export const isLoadingDashboar = (status) => {
	return {
		type: IS_LOADING_DASHBOAR,
		status
	}
}

/**
 * Set dashboard action dashboard
 */
export const setDashboardList = (dashboard) => {
	return {
		type: SET_DASHBOAR,
		dashboard
	}
}

/**
 * Is loading from sap
 * @param {*} status
 */
export const isLoadingFromSap = (status) => {
	return {
		type: IS_LOADNG_FROM_SAP,
		status
	}
}

/**
 * Set equipments consult by serie
 */
export const setEquipmentFromSap = (Equipment) => {
	return {
		type: SET_SPARE_PARTS_FROM_SAP,
		Equipment
	}
}

/**
 * function update equipment
 * @param {*} equipment
 */
export const updateEquipmentManual = (equipment) => {
	return {
		type: UPDATE_EQUIPMENT_MANUAL,
		equipment
	}
}

/**
 * Function to is saving request in draft from service by tech provider
 * @param {*} status
 */
export const isSavingRequestDraft = (status) => {
	return {
		type: IS_SAVING_DRAFT_REQUEST,
		status
	}
}

/**
 * Action creator for set the selected service
 */
export const setSelectedService = (service) => {
	return async (dispatch, getState) => {
		dispatch(isGettingRequestDraft())
		// Get the authorized configuration
		if (service) {
			let user = getState().oidc.user
			let config = getAuthorizedConfiguration(user)
			let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/service/${service.Ticket}`
			let serverResponse = await axios.get(url, config)
			dispatch({
				type: SET_SELECTED_SERVICE,
				service: service,
				draft: serverResponse.data === '' ? null : serverResponse.data
			})
		}
	}
}

/**
 * Action creator to add a spare part in a request
 */
export const addSparePartToRequest = (sparePart, equipment) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isSavingDraft())

			let selectedService = toSafeObject(
				getState()
					.spareParts.get('create')
					.get('service')
			)
			// Get the authorized configuration
			let user = getState().oidc.user
			let config = getAuthorizedConfiguration(user)
			// Create command
			let command = {
				ServiceTicket: selectedService.Ticket,
				Serie: equipment.Serie,
				CustomerCode: equipment ? equipment.CustomerCode : '',
				CustomerName: equipment ? equipment.CustomerName : '',
				Quantity: sparePart ? sparePart.Quantity : 0,
				SparePartCode: sparePart ? sparePart.Code : '',
				SparePartDescription: sparePart ? sparePart.Description : '',
				DestinatarionSerie: equipment.DestinatarionSerie,
				CustomerSerie: equipment.CustomerSerie,
				DescriptionSerie: equipment.DescriptionSerie
			}
			let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest`
			let serviceResponse = await axios.post(url, command, config)
			let sparePartRequestResponse = serviceResponse.data
			dispatch({
				type: SET_SPAREPARTREQUEST_DRAFT,
				draft: sparePartRequestResponse
			})
		} catch (err) {
			dispatch(isSavingDraft(false))
			if (err.response) {
				return Promise.reject(err.response.data)
			} else {
				return Promise.reject({ Message: 'Unexpected error thrown' })
			}
		}
	}
}

/**
 * Action creator to add a spare part in a request
 */
export const addSpareParrRequestFromTechnicial = (serviceTicket) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isSavingRequestDraft(true))
			// Get the authorized configuration
			let user = getState().oidc.user
			let config = getAuthorizedConfiguration(user)
			// Create command
			let command = {
				ServiceTicket: serviceTicket
			}
			let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/technicial`
			await axios.post(url, command, config)
			dispatch(isSavingRequestDraft(false))
		} catch (err) {
			dispatch(isSavingRequestDraft(false))
			if (err.response) {
				return Promise.reject(err.response.data)
			} else {
				return Promise.reject({ Message: 'Unexpected error thrown' })
			}
		}
	}
}

/**Remove spart part */
export const removeSparePart = (sparePart, sparePartRequestToken, isSparePartManual) => {
	return function(dispatch, getState) {
		dispatch(isSavingDraft())
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/${sparePartRequestToken}/${sparePart.Token}`
		return axios
			.delete(url, config)
			.then((serverResponse) => {
				dispatch(isSavingDraft(false))
				if (isSparePartManual) {
					dispatch({
						type: SET_SPAREPARTREQUEST_OPEN,
						sparePart: serverResponse.data
					})
				} else {
					dispatch({
						type: REMOVE_SPARE_PART,
						sparePart
					})
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingDraft(false))
				console.error('Error to remove spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**update spart part */
export const updateSparePartRequest = (sparePartRequestToken, positionModel, sparePartModel) => {
	return function(dispatch, getState) {
		dispatch(isSavingDraft())
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest`
		let command = {
			SparePartRequestToken: sparePartRequestToken,
			Comments: sparePartModel ? sparePartModel.Comments : '',
			ShipTo: sparePartModel ? sparePartModel.ShipTo : '',
			EmailsContact: sparePartModel ? sparePartModel.EmailsContact : '',
			ContactPhone: sparePartModel ? sparePartModel.ContactPhone : '',
			Address: {
				Country: sparePartModel.Address ? sparePartModel.Address.country : '',
				State: sparePartModel.Address ? sparePartModel.Address.administrative_area_level_1 : '',
				ZipCode: sparePartModel.Address ? sparePartModel.Address.postal_code : '',
				Location: sparePartModel.Address ? sparePartModel.Address.locality : '',
				Street: sparePartModel.Address ? sparePartModel.Address.route : '',
				StreetNumber: sparePartModel.Address ? sparePartModel.Address.street_number : '',
				Latitude: positionModel ? positionModel.latitude : null,
				Longitude: positionModel ? positionModel.longitude : null
			}
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDraft(false))
					dispatch({
						type: UPDATE_SPARE_PART_REQUEST,
						sparePart: serverResponse.data
					})
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingDraft(false))
				console.error('Error to update spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**update spart part */
export const saveSparePartComments = (sparePartRequestToken, sparePartModel) => {
	return function(dispatch, getState) {
		dispatch(isSavingRequest())
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest`
		let command = {
			SparePartRequestToken: sparePartRequestToken,
			ShipTo: sparePartModel ? sparePartModel.ShipTo : '',
			Comments: sparePartModel ? sparePartModel.Comments : '',
			EmailsContact: sparePartModel ? sparePartModel.EmailsContact : '',
			ContactPhone: sparePartModel ? sparePartModel.ContactPhone : ''
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: SET_SPAREPARTREQUEST_OPEN,
						sparePart: serverResponse.data
					})
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to update spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Load the spare parts requests
 */
export const loadSparePartRequests = (status, queryParameters, isMobile, trackingStatusFilter) => {
	return async (dispatch, getState) => {
		dispatch({
			type: IS_LOADING_REQUEST_LIST,
			status: true
		})

		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			PageNumber: queryParameters ? queryParameters.pageNumber : 1,
			PageSize: 10,
			OrderBy: queryParameters ? queryParameters.sortBy : null,
			OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
			Query: !!queryParameters.query ? queryParameters.query : '',
			Status: status,
			TrackingStatusFilter: trackingStatusFilter.length > 0 ? trackingStatusFilter : []
		}

		if (!!queryParameters) {
			if (!!queryParameters.statusFilter) {
				if (typeof queryParameters.statusFilter !== 'string')
					command.StatusFilter = queryParameters ? queryParameters.statusFilter.map((d) => parseInt(d, 10)) : []
				else command.StatusFilter = queryParameters ? [queryParameters.statusFilter] : []
			}
		}
		if (!!queryParameters.technicianFilter) {
			if (typeof queryParameters.technicianFilter !== 'string')
				command.TechnicianFilter = queryParameters ? queryParameters.technicianFilter.map((d) => d) : []
			else command.TechnicianFilter = queryParameters ? [queryParameters.technicianFilter] : []
		}
		if (!!queryParameters.warehouseFilter) {
			if (typeof queryParameters.warehouseFilter !== 'string')
				command.WarehousesFilter = queryParameters ? queryParameters.warehouseFilter.map((d) => d) : []
			else command.WarehousesFilter = queryParameters ? [queryParameters.warehouseFilter] : []
		}
		if (!!queryParameters.trackingFilter) {
			if (typeof queryParameters.trackingFilter !== 'string')
				command.TrackingStatusFilter = queryParameters ? queryParameters.trackingFilter.map((d) => d) : []
			else command.TrackingStatusFilter = queryParameters ? [queryParameters.trackingFilter] : []
		}
		if (!!queryParameters.customers) {
			if (typeof queryParameters.customers !== 'string') command.CustomerCodes = queryParameters ? queryParameters.customers.map((d) => d) : []
			else command.CustomerCodes = queryParameters ? [queryParameters.customers] : []
		}
		if (!!queryParameters.providers) {
			if (typeof queryParameters.providers !== 'string') command.ProvidersCodes = queryParameters ? queryParameters.providers.map((d) => d) : []
			else command.ProvidersCodes = queryParameters ? [queryParameters.providers] : []
		}

		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequests`
		let serviceResponse = await axios.post(url, command, config)
		if (isMobile) {
			dispatch({
				type: SET_SPAREPARTREQUEST_LIST,
				requests: serviceResponse.data.spareParts,
				pagination: serviceResponse.data
			})
		} else {
			let resultRequests = await getFullRequestWithUsers(serviceResponse.data.spareParts)
			dispatch({
				type: SET_SPAREPARTREQUEST_LIST,
				requests: resultRequests,
				pagination: serviceResponse.data
			})
		}
	}
}

/**
 * Action to get full request with diferentes users
 * @param {*} sparePartsList
 * @param {*} configuration
 */
export const getFullRequestWithUsers = async (sparePartsList) => {
	try {
		let url = `${window.config.identityServerUrl}/api/users`
		const content = sparePartsList.map((request) => request.CreatorUserName)
		let useIdentityData = await axios.post(url, content, null)
		return sparePartsList.map((request) => {
			let usesData = useIdentityData.data.find((x) => x.user_name === request.CreatorUserName)
			request.CreationDate = moment(request.CreationDate, 'MM/DD/YYYY')
			request.CreatorUser = {
				Name: usesData ? usesData.full_name : '',
				Email: usesData ? usesData.email : ''
			}
			return request
		})
	} catch (ex) {
		console.error('Errog getting the internal users list', ex)
	}
}

/**
 * Change the submit request from draft to submmited
 */
export const submitRequest = (sparePartRequest, emailsroleImberaUsa, emailsroleImberaMx) => {
	return async (dispatch, getState) => {
		if (!sparePartRequest) return Promise.reject()
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartRequest.Token,
			Emails: emailsroleImberaUsa,
			EmailsToMx: emailsroleImberaMx
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/inprocess`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to send spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**get spart part */
export const getSparePartRequest = (token) => {
	return function(dispatch, getState) {
		dispatch(isLoading())
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/${token}`
		return axios
			.get(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: SET_SPAREPARTREQUEST_OPEN,
						sparePart: serverResponse.data
					})
					dispatch(isLoading(false))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error to get spare part request', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update number traking spare part
 * @param {*} sparePartRequestToken
 * @param {*} sparePartToken
 * @param {*} numberTraking
 */
export const updateNumberTrackingSpareaPart = (sparePartRequestToken, sparePart, emailsToSend, emailsImberaMX, propertyToUpdate = '') => {
	return async (dispatch, getState) => {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			SparePartRequestToken: sparePartRequestToken,
			SparePartParser: sparePart,
			Emails: emailsToSend,
			EmailsMx: emailsImberaMX,
			PropertyToUpdate: propertyToUpdate
		}

		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sparePart`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				let response = serverResponse.data
				if (response.IsClose) {
					dispatch({
						type: SET_SPAREPARTREQUEST_OPEN,
						sparePart: serverResponse.data
					})
				} else {
					dispatch({
						type: UPDATE_SPARE_PART,
						sparePart: serverResponse.data
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to update spare part', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to send request from Imbera MX
 * @param {*} sparePartRequest
 */
export const sendRequestImberaMx = (sparePartRequestToken, emailsroleImberaMx) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest())
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartRequestToken,
			Emails: emailsroleImberaMx
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/send`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTREQUEST_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to send spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to close request
 * @param {*} sparePartRequest
 */
export const closeRequest = (sparePartRequestToken) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartRequestToken
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/close`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTREQUEST_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to close spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to cancel request
 * @param {*} sparePartRequestToken
 */
export const cancelRequest = (sparePartRequestToken, emailToSend) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartRequestToken,
			Emails: emailToSend
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/cancel`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSavingRequest(false))
				dispatch({
					type: SET_SPAREPARTREQUEST_OPEN,
					sparePart: serverResponse.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to cancel spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const downloadReportSparePartRequestGral = (status, queryParameters, startDate, endDate) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartrequest/downloadReport`
		let command = {
			Status: status,
			UserName: identityUser.profile.preferred_username,
			StartDate: startDate,
			EndDate: endDate
		}

		if (!!queryParameters) {
			if (!!queryParameters.statusFilter) {
				if (typeof queryParameters.statusFilter !== 'string')
					command.StatusFilter = queryParameters ? queryParameters.statusFilter.map((d) => parseInt(d, 10)) : []
				else command.StatusFilter = queryParameters ? [queryParameters.statusFilter] : []
			}
		}
		if (!!queryParameters.technicianFilter) {
			if (typeof queryParameters.technicianFilter !== 'string')
				command.TechnicianFilter = queryParameters ? queryParameters.technicianFilter.map((d) => d) : []
			else command.TechnicianFilter = queryParameters ? [queryParameters.technicianFilter] : []
		}
		if (!!queryParameters.warehouseFilter) {
			if (typeof queryParameters.warehouseFilter !== 'string')
				command.WarehousesFilter = queryParameters ? queryParameters.warehouseFilter.map((d) => d) : []
			else command.WarehousesFilter = queryParameters ? [queryParameters.warehouseFilter] : []
		}
		if (!!queryParameters.trackingFilter) {
			if (typeof queryParameters.trackingFilter !== 'string')
				command.TrackingStatusFilter = queryParameters ? queryParameters.trackingFilter.map((d) => d) : []
			else command.TrackingStatusFilter = queryParameters ? [queryParameters.trackingFilter] : []
		}
		if (!!queryParameters.customers) {
			if (typeof queryParameters.customers !== 'string') command.CustomerCodes = queryParameters ? queryParameters.customers.map((d) => d) : []
			else command.CustomerCodes = queryParameters ? [queryParameters.customers] : []
		}
		if (!!queryParameters.providers) {
			if (typeof queryParameters.providers !== 'string') command.ProvidersCodes = queryParameters ? queryParameters.providers.map((d) => d) : []
			else command.ProvidersCodes = queryParameters ? [queryParameters.providers] : []
		}

		return axios
			.post(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `ReportSparePartRequest.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report spare part requests', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to check traking number in spare part request
 * @param {*} carrier
 * @param {*} trackingNumber
 */
export const checkShipping = (carrier, trackingNumber, token) => {
	return async (dispatch, getState) => {
		dispatch(gettingTrackingData(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/trakings/${carrier}/${trackingNumber}/${token}`
		return await axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(gettingTrackingData(false))
				if (serverResponse.data.meta.code === 200) {
					dispatch(setCarrierInformation(serverResponse.data.data))
					dispatch(updateStatusTraking(serverResponse.data.data.status, trackingNumber))
					return Promise.resolve(serverResponse.data.data)
				} else {
					return Promise.reject(`The request was there but there is no information for this tracking number ${trackingNumber} to update`)
				}
			})
			.catch((error) => {
				dispatch(gettingTrackingData(false))
				console.error('Error to close spare part requests', error)
				return Promise.reject(`The request was there but there is no information for this tracking number ${trackingNumber} to update`)
			})
	}
}

export const searchHistoryBySerie = (serie) => {
	return async (dispatch, getState) => {
		dispatch(isLoadingHistorialAddress(true))
		dispatch(setHistorialSerial(null))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/services/${serie}/record`
		return await axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(isLoadingHistorialAddress(false))
				if (serverResponse.status === 200) {
					dispatch(setHistorialSerial(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingHistorialAddress(false))
				console.error('Error to get history by serie', error)
				return Promise.reject(`Error to get history by serie`)
			})
	}
}

export const searchHistoryByAddress = (address) => {
	return async (dispatch, getState) => {
		dispatch(isLoadingAddress(true))
		dispatch(setHistorialSerial(null))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/services/${address.label}/recordbyaddress`
		return await axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(isLoadingAddress(false))
				if (serverResponse.status === 200) {
					dispatch(setHistorialSerial(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingAddress(false))
				console.error('Error to get history by address', error)
				return Promise.reject(`Error to get history by address`)
			})
	}
}

export const getAllServiceAddresses = () => {
	return async (dispatch, getState) => {
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/services/addresses`
		return await axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(gettingTrackingData(false))
				if (serverResponse.status === 200) {
					dispatch(setServiceAddresses(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to get service addresses', error)
				return Promise.reject(`Error to get service addresses`)
			})
	}
}

/**
 * Load services to load dashboard
 */
export const loadDashboardSparePartRequest = (status) => {
	return async (dispatch, getState) => {
		try {
			const loadSparePartRequests = async () => {
				dispatch(isLoadingDashboar(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				const command = {
					Status: status
				}
				// Get the spare parts requests from load dashboard
				let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/dashboard`
				let serverResponse = await axios.post(url, command, config)
				dispatch(setDashboardList(serverResponse.data))
			}
			await loadSparePartRequests()
		} catch (ex) {
			dispatch(isLoadingDashboar(false))
			console.error('Error getting dashboard spare part request', ex)
		}
	}
}

/**
 * Action to delete spare part in draft
 * @param {*} token
 */
export const deleteSparepartRequests = (token) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/${token}/remove`
		return await axios
			.delete(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: REMOVE_SPARE_PART_REQUESTS,
						token: token
					})
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to delete spare part requests', error)
				return Promise.reject(`Error to delete spare part requests`)
			})
	}
}

/**
 * Change the submit request from draft to submmited
 */
export const sendRequestToValidationInplant = (sparePartRequest, emailsRolesTechsUsa) => {
	return async (dispatch, getState) => {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartRequest.Token,
			Emails: emailsRolesTechsUsa
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sendtovalidate`
		await axios
			.put(url, command, config)
			.then(() => {
				dispatch(isSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to send spare part requests to validate in plant', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Change the submit request from draft to submmited
 */
export const rejectedRequestInPlant = (sparePartRequest, statusModel) => {
	return async (dispatch, getState) => {
		if (!sparePartRequest) return Promise.reject()
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			Token: sparePartRequest.Token,
			Comments: statusModel ? statusModel.comments : null
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/rejectedinplant`
		await axios
			.put(url, command, config)
			.then(() => {
				dispatch(isSavingRequest(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to send spare part requests', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const senEmailBySpartPart = (spareParRequestToken, sparePartToken) => {
	return async (dispatch, getState) => {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/${spareParRequestToken}/email/${sparePartToken}`
		return await axios
			.post(url, null, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to send email to technical', error)
				return Promise.reject(`Error to send email to technical`)
			})
	}
}

/**
 * Action to create new service
 * @param {*} spartPartModel
 */
export function addSpartPartRequestManual(spartPartModel, position, accountCode) {
	return function(dispatch, getState) {
		dispatch(isSavingDraft(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Add spare part
		let command = {
			ServiceProviderCode: !isNullOrEmpty(spartPartModel.Provider ? spartPartModel.Provider.value : '')
				? spartPartModel.Provider.value
				: accountCode,
			CustomerCode: !isNullOrEmpty(spartPartModel.Customer ? spartPartModel.Customer.value : '') ? spartPartModel.Customer.value : accountCode,
			CustomerType: spartPartModel.CustomerType ? spartPartModel.CustomerType.value : '',
			WarrantyType: spartPartModel.WarrantyType ? spartPartModel.WarrantyType.value : '',
			Address: {
				Country: spartPartModel.Address ? spartPartModel.Address.country : '',
				State: spartPartModel.Address ? spartPartModel.Address.administrative_area_level_1 : '',
				ZipCode: spartPartModel.Address ? spartPartModel.Address.postal_code : '',
				Location: spartPartModel.Address ? spartPartModel.Address.locality : '',
				Street: spartPartModel.Address ? spartPartModel.Address.route : '',
				StreetNumber: spartPartModel.Address ? spartPartModel.Address.street_number : '',
				Latitude: position ? position.latitude : null,
				Longitude: position ? position.longitude : null
			},
			Comments: spartPartModel ? spartPartModel.ServiceDescription : ''
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/manual`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingDraft(false))
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingDraft(false))
				console.error('Error in create spart part', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to add file in spare part requests
 * @param {*} token
 * @param {*} files
 */
export const addFilesSparePartResquest = (token, files) => {
	return function(dispatch, getState) {
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let command = {
			token: token
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartrequest/uploadfiles`
		let data = new FormData()
		for (var index = 0; index <= files.length; index++) {
			data.append('file', files[index])
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch({
					type: ADD_FILES,
					evidences: response.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error uploading evidence by provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove file in the spare part request
 * @param {*} token
 * @param {*} fileName
 */
export function removeFilesInSparePartRequest(token, fileName) {
	return function(dispatch, getState) {
		let params = {
			Name: fileName
		}
		let configuration = getAuthorizedConfigurationParameters(getState().oidc.user, params)
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartrequest/${token}/removefiles`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_FILES,
						fileName: fileName
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) from spare part request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to search by serie from sap
 * @param {*} serie
 */
export const consultBySerie = (serie) => {
	return async (dispatch, getState) => {
		dispatch(isLoadingFromSap(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sap/${serie}`
		let urlSap = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sap/${serie}/warranty`
		let equipmentFromSap = null
		await axios
			.get(url, config)
			.then((serverResponse) => {
				console.log({ serverResponse })
				let commandWarranty = {
					Serie: serverResponse.data.Serie,
					WarrantyDate: serverResponse.data.WarrantyDate,
					CustomerGroupCode: serverResponse.data.CustomerGroupCode,
					CustomerGroupDescription: serverResponse.data.CustomerGroupDescription
				}
				equipmentFromSap = serverResponse.data
				return axios.post(urlSap, commandWarranty, config)
			})
			.then((serverResponseWarranty) => {
				equipmentFromSap.EquipmentWarranty = serverResponseWarranty.data
				dispatch(setEquipmentFromSap(equipmentFromSap))
				dispatch(isLoadingFromSap(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isLoadingFromSap(false))
				console.error('Error get material by serie', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove file json with serie sap to get the SAP information again
 * @param {*} serie
 */
export const removeJsonSerieSap = (serie) => {
	return async (dispatch, getState) => {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sap/${serie}`
		await axios
			.delete(url, config)
			.then((response) => {
				dispatch(setIsDownloadReport(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error remove file json by serie', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to create new service
 * @param {*} spartPartModel
 */
export function addSpartPartManual(sparePartRequest, equipment, sparePart) {
	return function(dispatch, getState) {
		dispatch(isSavingRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Add service
		let command = {
			Token: sparePartRequest ? sparePartRequest.Token : '',
			Serie: equipment ? equipment.Serie : '',
			Model: equipment ? equipment.Model : '',
			CustomerCode: equipment ? equipment.CustomerCode : '',
			CustomerName: equipment ? equipment.CustomerName : '',
			WarrantyDate: equipment ? equipment.WarrantyDate : '',
			Quantity: sparePart ? sparePart.Quantity : 0,
			SparePartCode: sparePart ? sparePart.Code : '',
			SparePartDescription: sparePart ? sparePart.Description : '',
			MaterialCode: equipment.MaterialCode,
			DescriptionMaterial: equipment.DescriptionMaterial,
			DestinatarionCode: equipment.DestinatarionCode,
			DestinatarionName: equipment.DestinatarionName
		}
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/manual/addSparePart`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingRequest(false))
					dispatch({
						type: SET_SPAREPARTREQUEST_OPEN,
						sparePart: serverResponse.data
					})
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error in create spart part', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update equipment
 * @param {*} token
 * @param {*} equipmentToken
 * @param {*} refrigerationNotCoolFailure
 * @param {*} iluminationFailure
 * @param {*} doorFailure
 * @param {*} comments
 * @param {*} faultWarranty
 */
export function updateEquipment(token, equipmentToken, refrigerationNotCoolFailure, iluminationFailure, doorFailure, comments, faultWarranty) {
	return function(dispatch, getState) {
		dispatch(isSavingRequest(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			SparePartRequestsToken: token,
			EquipmentToken: equipmentToken,
			RefrigerationNotCoolFailure: refrigerationNotCoolFailure,
			IluminationFailure: iluminationFailure,
			DoorFailure: doorFailure,
			Comments: comments,
			FaultWarranty: faultWarranty
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartrequest/updateEquipment`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateEquipmentManual(serverResponse.data))
					dispatch(isSavingRequest(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingRequest(false))
				console.error('Error to update property in equipment', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to donsload dta by serie
 */
export const downloadReportByserie = () => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let serieData = getState().spareParts.getIn(['show', 'equipmentsSap'])
			? getState()
					.spareParts.getIn(['show', 'equipmentsSap'])
					.toJS()
			: null
		let data = {
			SerieData: serieData
		}

		let endPoint = `${window.config.serviceManagementBackendUrl}/sparepartrequest/download/serie`
		return axios
			.post(endPoint, data, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `ReportBySerie.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report by serie', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to import references
 */
export const importReferencesFromBackend = (files, getType) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			ImportOption: getType
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/sparepartrequest/import`
		let data = new FormData()
		data.append('files', files)
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then(() => {
				dispatch(setIsDownloadReport(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error import references', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to import references
 */
export const importSalesReferencesFromBackend = (files, getType) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			ImportOption: getType
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/sparepartsale/importreferences`
		let data = new FormData()
		data.append('files', files)
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then(() => {
				dispatch(setIsDownloadReport(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error import references', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**get spart part */
export const getExistenceMaterial = (code, updateType) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/warehouses/${code}`
		return axios
			.get(url, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setExistenceMaterialAction(code, serverResponse.data, updateType))
					dispatch(setIsDownloadReport(false))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error to get spare part  existences', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/*Get the information of series material plane*/
export const getMaterialPlaneInfo = (materialNumber, productionDate) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadPlane(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sap/planes`
		let command = {
			MaterialNumber: !isNullOrEmpty(materialNumber) ? materialNumber : '',
			ProductionDate: !isNullOrEmpty(productionDate) ? productionDate : ''
		}
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					if (serverResponse.data.Result !== null) {
						let materialPlaneInfo = serverResponse.data
						let FileName = materialPlaneInfo.FILEP.split('\\')
						dispatch(downloadMaterialPlane(FileName[4]))
					}
				}
				dispatch(setIsDownloadPlane(false))
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				dispatch(setIsDownloadPlane(false))
				console.error('Error to get material planes information', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const downloadMaterialPlane = (planeName) => {
	return function(dispatch, getState) {
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sap/planes/files?planeName=${planeName}`
		return axios
			.get(url, {
				// responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('DATA PDF', {
						serverResponse
					})

					const linkSource = `data:application/pdf;base64,${serverResponse.data}`
					const downloadLink = document.createElement('a')
					const fileName = `${planeName}`
					downloadLink.href = linkSource
					downloadLink.download = fileName
					downloadLink.click()

					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error downloading material plane', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}
