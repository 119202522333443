/*Import react section*/
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js';

/**Import components section */
import SearchSeriePanel from './SearchSeriePanel'
import LateralPanel from '../../../common/lateralPanel/LateralPanel';
import { isNullOrEmpty } from '../../../../store/helpers/StringHelper';
import Toaster from '../../../common/Toaster'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'
import ChangeStatusPanel from '../ChangeStatusPanel'

/**Import actions section */
import {
    consultBySerie
} from '../../../../store/spareParts/sparePartsActions'
import { deleteSparepartRequests, submitRequest } from '../../../../store/spareParts/sparePartsActions'

/**Import helper section */
import { SpareParRequestStatus } from '../../../../store/helpers/AppConstants'
import { getEmailUsersByRole } from '../../../../store/helpers/UserHelper'
import { SPARE_PARTS_IMBERA_MX, SPARE_PARTS_IMBERA_USA } from '../../../../resources/RolesEnum'
import { toSafeObject } from '../../../../store/helpers/StateHelper'

const AddPanel = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()

    /**Functions props */
    const { openLateralPanelSeries, cleamSparePartSap } = props

    /**Objects props section*/
    const { addLateralPanelConfig, lateralPanelStatusConfig, openLateralPanelChangeStatus, userProfile } = props

    const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    const isLoadingFromSAP = useSelector((state) => state.spareParts.getIn(['show', 'isLoadingFromSAP']))
    const isSaving = useSelector((state) => state.spareParts.getIn(['show', 'isSaving']))

    let emailsroleImberaUsa = getEmailUsersByRole(SPARE_PARTS_IMBERA_USA, internalUsers)
    let emailsroleImberaMx = getEmailUsersByRole(SPARE_PARTS_IMBERA_MX, internalUsers)

    const [toaster, setToaster] = useState({
        open: false,
        variant: null,
        message: null
    })
    const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, statusModel: null })

    const onConsultBySerie = (serie) => {
        cleamSparePartSap()
        return dispatch(consultBySerie(serie))
            .then((data) => {
                openLateralPanelSeries()
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to consult materials by serie from SAP', variant: 'warning' })
            })
    }

    /**Open/close dialog confirm dialog user*/
    const onSetPasswordDialogConfiguration = (statusModel) => {
        setPasswordDialogConfiguration({ opened: !passwordDialogConfiguration.opened, statusModel: statusModel });
    };

    /**
     * Callback executed when the user submits the request
     */
    const onSubmitRequest = () => {
        dispatch(submitRequest(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart : null, emailsroleImberaUsa, emailsroleImberaMx))
            .then(() => {
                onSetPasswordDialogConfiguration()
                history.push('/spareparts')
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to submit spare part', variant: 'error' })
            })
    }

    const onCancelService = () => {
        return dispatch(deleteSparepartRequests(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart.Token : ''))
            .then((_) => {
                onSetPasswordDialogConfiguration()
                setToaster({ open: true, message: "Spare part requests deleted success", variant: 'success' })
                history.push('/spareparts')
                openLateralPanelChangeStatus();
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to deleted spart part requests', variant: 'warning' })
                console.error("Failed to removed spare part requests", error);
            });
    };


    const GetFunctionToInvoke = (status) => {
        switch (Number(status)) {
            case SpareParRequestStatus.IN_PROCESS:
                return onSubmitRequest
            case SpareParRequestStatus.DELETED:
                return onCancelService
            default:
                return null
        }
    }
    const GetStatusDescription = (status) => {
        switch (Number(status)) {
            case SpareParRequestStatus.DRAFT:
                return "This action will change the status of the spare part to DRAFT"
            case SpareParRequestStatus.IN_PROCESS:
                return "This operation will send the request to spare sparts providers in order to be supplied"
            case SpareParRequestStatus.DELETED: // Delete
                return "This action will delete the spare request, the information cannot be restored."
            default:
                return null
        }
    }


    return (
        <>
            {
                (addLateralPanelConfig && addLateralPanelConfig.opened) &&
                <LateralPanel
                    onClose={openLateralPanelSeries}
                >
                    <SearchSeriePanel
                        onSaveButtonClick={onConsultBySerie}
                        onCloseButtonClick={openLateralPanelSeries}
                        isLoadingFromSAP={isLoadingFromSAP}
                    />

                </LateralPanel>
            }

            {
                (lateralPanelStatusConfig && lateralPanelStatusConfig.opened) &&
                <LateralPanel
                    onClose={openLateralPanelChangeStatus}
                >
                    <ChangeStatusPanel
                        onCloseButtonClick={openLateralPanelChangeStatus}
                        sparePart={(lateralPanelStatusConfig && lateralPanelStatusConfig.spartPart)}
                        onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                    />

                </LateralPanel>
            }


            {
                passwordDialogConfiguration.opened &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={GetFunctionToInvoke(passwordDialogConfiguration ? (passwordDialogConfiguration.statusModel && passwordDialogConfiguration.statusModel.status) : null)}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetPasswordDialogConfiguration}
                    message1={`${GetStatusDescription(passwordDialogConfiguration.statusModel && passwordDialogConfiguration.statusModel.status)} . Are you sure to proceed  ${userProfile ? userProfile.name : ''}?`}
                    message2="Enter the password to confirm the operation"
                />
            }


            <Toaster
                toasterOptions={toaster}
                onClose={() => setToaster({ open: false })}
            />

        </>
    )
}

export default withImmutablePropsToJS(AddPanel)