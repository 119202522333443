/**Import react section */
import React, {  useState } from 'react'
import { useForm } from 'react-hook-form'

/**Import material UI Section */
import {
    DialogContent,
    Grid,
    Typography
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

/**Import components section */
import PanelHeaderIcon from '../../../common/lateralPanel/PanelHeaderIcon'
import TextBox from '../../../common/TextBox'
import AlertComponent from '../../../common/AlertComponent'

/**Import resouces section */
import iconFailureDoor from '../../../../resources/images/failureWarranty/doorWhite.svg';

const SearchSeriePanel = (props) => {
    const { onCloseButtonClick, onSaveButtonClick, isLoadingFromSAP } = props;
    const { register, handleSubmit, errors } = useForm()

    const [serieModel, setSerieModel] = useState("");

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setSerieModel({
            ...serieModel,
            [name]: value
        });

    }

    const onSearchSerie = () => {
        if (onSaveButtonClick)
            onSaveButtonClick(serieModel.Serie)
    }


    return (
        <>
            <PanelHeaderIcon
                title={'Search serie'}
                isIconSvgOrPng={true}
                icon={iconFailureDoor}
                onCloseButtonClick={onCloseButtonClick}
                onSaveButtonClick={handleSubmit(onSearchSerie)}
                canBeSaved={!isLoadingFromSAP}
            />
            <DialogContent >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{"Enter the serial number of your product, it must contain 11 or 12 digits"}</Typography>
                        <br/>
                        <TextBox
                            active={true}
                            fullWidth
                            key="serie"
                            name="Serie"
                            label="Serie"
                            variant="outlined"
                            margin="dense"
                            onChange={onPropertyChange}
                            value={serieModel ? serieModel.Serie : ""}
                            register={register({ 
                                required: { value: true, message: 'The serie is required' },
                                minLength: {
									value: 11,
									message: "The series must contain a minimum of 11 or 12 characters"
								},
                         })}
                            errors={errors}
                            required={(true && errors?.["Serie"]) ? true : false}
                        />
                        {isLoadingFromSAP && <>
                            <br/><br/><br/>
                            <AlertComponent
                                title={"Getting series from SAP"}
                                message={"This process may take a while, please wait..."}
                                severityType={"info"}
                            />
                            <LinearProgress />
                        </>}
                    </Grid>
                </Grid>

            </DialogContent>

        </>
    )
}

export default SearchSeriePanel