import moment from 'moment'

export const getDatagridConfiguration = () => {
	let config = [...dataGridConfiguration]
	return config
}

export const dataGridConfiguration = [
	{
		title: 'Folio',
		dataSource: 'Folio',
		sortable: true
	},
	{
		title: 'Invoice No',
		dataSource: 'Invoice',
		sortable: true
	},
	{
		title: 'Creation Date',
		dataSource: 'CreationDate',
		sortable: true,
		onRenderProperty: (item) =>
			item.CreationDate
				? moment(item.CreationDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
				: ''
	},
	{
		title: 'Ticket',
		dataSource: 'Ticket',
		sortable: true,
		onRenderProperty: (item) => {
			return item.Ticket ? item.Ticket : ''
		}
	},
	{
		title: 'Serie',
		dataSource: 'Serie',
		sortable: true,
		onRenderProperty: (item) => {
			return item.Serie ? item.Serie : ''
		}
	},
	{
		title: 'Status',
		dataSource: 'Status',
		sortable: true,
		onRenderProperty: (item) => {
			return getStatusDescription(item.Status)
		}
	},
	{
		title: 'Created By',
		dataSource: 'CreatorUser',
		onRenderProperty: (item) => {
			return item.CreatorUser ? item.CreatorUser.Name : ''
		}
	},
	{
		title: 'Service Provider',
		dataSource: 'ServiceProviderName',
		sortable: true,
		onRenderProperty: (item) => {
			return item.ServiceProviderName
				? item.ServiceProviderName
				: 'Unassigned'
		}
	},
	{
		title: 'Validation Date',
		dataSource: 'ValidationDate',
		sortable: true,
		onRenderProperty: (item) =>
			item.ValidationDate
				? moment(item.ValidationDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
				: ''
	},
	{
		title: 'Check Folio',
		dataSource: 'CheckFolio',
		sortable: true,
		onRenderProperty: (item) => {
			return item.CheckFolio ? item.CheckFolio : ''
		}
	}
]

export const getStatusDescription = (statusId) => {
	switch (statusId) {
		case 1:
			return 'Draft'
		case 2:
			return 'Submitted'
		case 4:
			return 'Rejected'
		case 5:
			return 'Accepted'
		case 6:
			return 'Paid'
		default:
			return 'Undefined'
	}
}
