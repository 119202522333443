/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'

/**Import components section */
import TextBox from '../../common/TextBox'
import AlertComponent from '../../common/AlertComponent'
import Datagrid from '../../common/apsys.datagrid.materialui/DataGrid'
import AvatarCustomSize from '../../common/AvatarCustomSizes'

/** Import material UI */
import {
	Typography,
	LinearProgress,
	Grid,
	Chip,
	ButtonGroup,
	Tooltip,
	Button
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/SearchSharp'
import CloudDownload from '@material-ui/icons/CloudDownload'
import PaymentIcon from '@material-ui/icons/CreditCard'

/**Import resources section */
import { getDatagridSummaryConfiguration } from '../resources/requestSummaryConfig'
import { useCreateDesktopStyle } from '../resources/useStyles'
import { currencyFormat } from '../../../store/helpers/AmountHelper'

const DesktopSummaryView = (props) => {
	/**props sections */
	const { paymentSummary } = props
	const {
		isLoadingSummary,
		isSavingSummary,
		isAuthorizerCanGenerated
	} = props
	const { onGetPaymentRequest, onDonwloadReportSummary } = props

	let configuration = getDatagridSummaryConfiguration()
	console.log(
		'🚀 ~ file: DesktopSummaryView.js:30 payment ~ DesktopSummaryView ~ configuration:',
		configuration
	)
	const classes = useCreateDesktopStyle()
	const [checkFolio, setCheckFolio] = useState('')

	/**Verify if can be search request list */
	const canBeSaved = () => {
		if (checkFolio ? checkFolio.length <= 3 : true) return false
		return true
	}

	/**
	 * onPropertyChange
	 * @param {*} event
	 */
	const onPropertyChange = (event) => {
		let { value } = event.target
		setCheckFolio(value)
	}

	/**
	 * Function to ger summary
	 */
	const onGetSummary = () => {
		if (onGetPaymentRequest) {
			onGetPaymentRequest(checkFolio)
		}
	}

	return (
		<div>
			<Typography variant='subtitle2'>
				Write the folio of the check to show the information with the
				payment summary
			</Typography>
			<Grid container spacing={1}>
				<Grid item xs={isAuthorizerCanGenerated ? 8 : 8}>
					<TextBox
						active={true}
						fullWidth
						name='checkFolio'
						variant='outlined'
						margin='dense'
						label={'Search Check Folio'}
						onChange={onPropertyChange}
						value={checkFolio}
					/>
				</Grid>
				<Grid
					item
					xs={isAuthorizerCanGenerated ? 4 : 4}
					style={{ marginTop: '8px' }}
				>
					<ButtonGroup
						component='span'
						size='small'
						className={classes.buttonsGroupEquipmentContainer}
					>
						<Button
							onClick={onGetSummary}
							disabled={
								isLoadingSummary ||
								isSavingSummary ||
								!canBeSaved()
							}
							className={classes.buttonsGroup}
						>
							<SearchIcon /> {' Search'}
						</Button>
						{
							<Button
								onClick={() =>
									props.changeView(props.optionView)
								}
								className={classes.buttonsGroup}
							>
								<PaymentIcon style={{ marginRight: '10px' }} />{' '}
								{'View Summary'}
							</Button>
						}
						<Tooltip component='span' title='Download report'>
							<Button
								onClick={() =>
									onDonwloadReportSummary(checkFolio)
								}
								className={classes.buttonsGroup}
								disabled={!paymentSummary || isSavingSummary}
							>
								<CloudDownload
									style={{
										marginRight: '5px',
										marginLeft: '5px'
									}}
								/>
							</Button>
						</Tooltip>
					</ButtonGroup>
				</Grid>
			</Grid>
			{paymentSummary && (
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<Typography className={classes.title}>
							{'SERVICES PROVIDER'}
						</Typography>
						<Chip
							id={'value'}
							size='small'
							avatar={
								<AvatarCustomSize
									avatarUrl={null}
									name={'SERVICES PROVIDER'}
									isAvatarFromIdentity={false}
								/>
							}
							label={
								paymentSummary &&
								paymentSummary.ServiceProvider &&
								paymentSummary.ServiceProvider.Name
							}
							className={classes.iconSummary}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography className={classes.title}>
							{'CREATED BY'}
						</Typography>
						<Chip
							id={'value'}
							size='small'
							avatar={
								<AvatarCustomSize
									avatarUrl={null}
									name={'CREATED BY'}
									isAvatarFromIdentity={false}
								/>
							}
							label={paymentSummary && paymentSummary.CreatorName}
							className={classes.iconSummary}
						/>
					</Grid>
					<Grid
						container
						spacing={1}
						className={classes.setMarginGrid}
					>
						<Grid item xs={3}>
							<TextBox
								active={true}
								disabled={true}
								fullWidth
								name='totalAmount'
								variant='outlined'
								margin='dense'
								label={'Total to pay'}
								value={currencyFormat(
									paymentSummary.TotalAmount
								)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextBox
								active={true}
								fullWidth
								name='checkFolio'
								variant='outlined'
								margin='dense'
								label={'Check folio'}
								disabled={true}
								value={paymentSummary.CheckFolio}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextBox
								active={true}
								fullWidth
								name='note'
								variant='outlined'
								margin='dense'
								label={'Note'}
								disabled={true}
								value={paymentSummary.Notes}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}

			{paymentSummary ? (
				<Grid item xs={12} className={classes.setMarginGrid}>
					<Typography className={classes.title}>
						<strong>{'PAYMENT REQUESTS'}</strong>
					</Typography>
					<div className={classes.containerDesktop}>
						<Datagrid
							headers={configuration}
							isLoading={isLoadingSummary}
							data={paymentSummary.PaymentRequests}
						/>
					</div>
				</Grid>
			) : (
				<Grid item xs={12} className={classes.setMarginGrid}>
					{!isLoadingSummary && (
						<AlertComponent
							title={'Payment summary'}
							message={
								'There is no summary of expenses to show information or the folio of the check is invalid.'
							}
							severityType={'info'}
						/>
					)}
				</Grid>
			)}

			{isLoadingSummary && (
				<Grid item xs={12} className={classes.setMarginGrid}>
					<AlertComponent
						title={'Getting payment summary'}
						message={'Getting payment summary information...'}
						severityType={'info'}
					/>
					<LinearProgress />
				</Grid>
			)}
		</div>
	)
}

DesktopSummaryView.propTypes = {
	isLoadingSummary: propTypes.bool.isRequired,
	paymentSummary: propTypes.object.isRequired,
	onGetPaymentRequest: propTypes.func
}
DesktopSummaryView.defaultProps = {
	paymentSummary: {
		CheckFolio: '',
		PaymentRequests: []
	},
	isLoadingSummary: false,
	onGetPaymentRequest: () =>
		console.warn('No [onGetPaymentRequest] callback defined')
}

export default DesktopSummaryView
