import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'

export const IS_SAVING = 'IS_SAVING'
export const SET_ITEMS = 'SET_ITEMS'
export const IS_LOADING_WARRANTY_SERIES_SAP = 'IS_LOADING_WARRANTY_SERIES_SAP'
export const TOGGLE_SWITCH = 'TOGGLE_SWITCH';


export const isSaving = (status) => {
	return {
		type: IS_SAVING,
		status
	}
}


/**
 * Is loading action warranty series sap
 */

export const isLoadingWarrantySeriesSap = (status) => {
    return {
        type: IS_LOADING_WARRANTY_SERIES_SAP,
        status
    }
}

/**
 * Set warranty series sap action 
 */
export const setAllWarrantySeriesSap = (data) => {
    return {
        type: SET_ITEMS,
        data
    }
}

// Warranty series Sap
export const AddWarrantySeriesSap = (files) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user
		let command = {
			CreatorName: identityUser.profile.username
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/FilteredSeriesSap/import`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileName', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.post(endPoint, data, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				if (serverResponse.status === 200) {
                    dispatch(loadAllWarrantySeriesSap(serverResponse.data));
					
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error uploading documents', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}


export const loadAllWarrantySeriesSap = (queryParameters, pageSize = 10) => {
    return function (dispatch, getState) {
        let loadedWarrantySeriesSap = getState().warrantySeriesSap.get('warrantySeriesSap')
        if (loadedWarrantySeriesSap && loadedWarrantySeriesSap.size > 0) {
            return Promise.resolve()
        }

        dispatch(isLoadingWarrantySeriesSap(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/FilteredSeriesSap`
        let command = {
            PageNumber: queryParameters ? queryParameters.pageNumber : 1,
            PageSize: pageSize,
            OrderBy: queryParameters ? queryParameters.sortBy : null,
            OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
            Query: queryParameters && (!!queryParameters.query ? queryParameters.query : "")
        }
        return axios.post(url, command, config)
            .then(serverResponse => {
                dispatch(isLoadingWarrantySeriesSap(false));
                if (serverResponse.status === 200) {
                    dispatch(setAllWarrantySeriesSap(serverResponse.data));
                }
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isLoadingWarrantySeriesSap(false));
                console.error("Error loading all warranty series sap", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const downloadWarrantySeriesList = () => {
    return function (dispatch, getState) {
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let endPoint = `${window.config.serviceManagementBackendUrl}/FilteredSeriesSap/DownloadDocument`
        return axios
            .post(
                endPoint,
                {},
                {
                    responseType: 'arraybuffer',
                    headers: config
                }
            )
            
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    var blob = new Blob([serverResponse.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    })
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = `Filtered_series_SAP.xlsx`
                    a.click()
                    window.URL.revokeObjectURL(url);
                    return Promise.resolve('Ok')
                }
            })
            .catch((error) => {
                console.error('Error donwload list of filtered series', error)
                return Promise.reject()
            })
    }
}

export const toggleSwitch = (isChecked) =>{
    return function(dispatch, getState){
        dispatch(isSaving(true))
        dispatch({ type: TOGGLE_SWITCH, isChecked });
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let command = {
            Status: isChecked
        }
        let endPoint = `${window.config.serviceManagementBackendUrl}/FilteredSeriesSap/ActivateFilter`
        return axios.put(endPoint, command, config)
        .then((serverResponse) => {
            if (serverResponse.status === 200) {
                dispatch(loadAllWarrantySeriesSap())
            }
            dispatch(isSaving(false))
            return Promise.resolve()
        })
        .catch((error) => {
            dispatch(isSaving(false))
            console.error('Error activating the warranty series sap filter', error)
            return Promise.reject()
        })
    }
}
