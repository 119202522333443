/**Import react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
/**Import Material Ui section */

/**Import actions section */
import { createPaymentRequest } from '../../store/paymentrequests/paymentRequestActions'
import { loadServicesClosed } from '../../store/services/serviceActions'
import { servicesClosedSelector } from '../../store/services/servicesSelectors'
import { toSafeObject } from '../../store/helpers/StateHelper'

/**Import component section */
import CreateDesktopView from './components/CreateDesktopView'
import Toaster from '../common/Toaster'


const Create = () => {
    /**Hooks sections */
    const dispatch = useDispatch();
	let history = useHistory();

    const userProfile = useSelector((state) => toSafeObject(state.session.get('profile')))
    const isSavingPaymentRequest = useSelector((state) => state.paymentRequests.get('isSavingPaymentRequest'))
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const services = useSelector((state) => servicesClosedSelector(state))

    useEffect(() => {
		dispatch(loadServicesClosed())
	}, [dispatch])
	
	const redirectToPayment = (token) =>{
        history.push(`/paymentrequests/${token}`)
    }

    const redirectToHomeCancelPayment = () =>{
		history.push(`/paymentrequests`)
    }

    /**
     * Function to create new payment request
     * @param {*} payment 
     * @param {*} userProfile 
     */
    const createPayment = (payment) =>{
        return dispatch(createPaymentRequest(payment, userProfile)).then((token) => {
            redirectToPayment(token)
        })
        .catch(() => {
            setToasterOptions({ open: true, message: "An error was generated while generating the request", variant: 'error' })
        });
    }

    const onChangeLocation = (location) => history.push(location)


    return (
        <>
            <CreateDesktopView
            	closedServices={services}
                createPayment={createPayment}
                isSavingPaymentRequest={isSavingPaymentRequest}
                redirectToHomeCancelPayment={redirectToHomeCancelPayment}
                onChangeLocation={onChangeLocation}
                userProfile={userProfile}
            />

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
        
    )
}

export default Create
