/**
 * Define the common styles used in all the index's views
 * 
 */
import { makeStyles } from '@material-ui/styles'

export const termsStyle = makeStyles((theme) => ({
    root: {
        height: theme.mixins.toolbar.minHeight,
        display: 'flex',
        flexDirection: 'row-reverse',
        paddingRight: '10px',
        alignItems: 'center',
        margin: '10px 10px 10px 0px',
    },
    headerbotton:{
        display: 'flex',
        alignItems: 'center',
    },
    titleHeader:{
        marginRight: "10px"
    },
    container:{
        maxHeight: `calc(100vh - ${50}px)`,
        minHeight: `calc(100vh - ${140}px)`,
    },
    addIcon: {
        backgroundColor: theme.palette.common.black,
        width: theme.spacing(5),
        height: theme.spacing(5),
        cursor: 'pointer'
    },
    rootIndex: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
    },
    btnSaved: {
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
            opacity: .5,
        },
    },
    buttonsGroupContainer: {
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroup: {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.primarySelected} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth:"14px !important",
            height:"2rem !important",
        },
    },
}))