
/**Import react section */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4'

/**Import MaterialUi section */
import { DialogContent, Grid, LinearProgress } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload';


/**Import resources section */
import PanelHeaderIcon from '../../common/lateralPanel/PanelHeaderIcon'
import { panelEditUseStyles } from '../resources/useStyles'
import iconFailureDoor from '../../../resources/images/importIcon.png';
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import AlertComponent from '../../common/AlertComponent'
import ButtonConfirm from '../../common/Buttons/ButtonConfirm'
import DropDownZone from '../../common/DropDownZone';
import FileContent from '../../common/FileContent';
import { GetParcelName } from '../../../store/helpers/SelectOptions';
import Selector from 'react-select';

//*** Excel files imported **/
import downloadTemplate from '../../../resources/files/SAP_TEMPLATE.xlsx';
import downloadTemplateUPS from '../../../resources/files/UPS_TEMPLATE.xlsx';
import downloadTemplateFEDEX from '../../../resources/files/FEDEX_TEMPLATE.xlsx';


/**
 * Panel to edit information traking
 * @param {} props 
 */
const PanelImportReference = (props) => {
    const classes = panelEditUseStyles();
    const { isSaving, onCloseButtonClick, onImportReferenseSAP } = props;
    const [pdfFile, setPdfFile] = useState([]);
    const [modelImmport, setModelImport] = useState(null);
    const parcelName = GetParcelName()
    
    const canBeSaved = () => {
        if (pdfFile.length > 0 && modelImmport != null) return false;
        return true;
    }
    /**
     * Remove file added of state 
     * @param {*} fileId 
     */
    const onRemoveFile = (fileId) => {
        let index = pdfFile.findIndex(attachment => attachment.id === fileId);
        if (index !== -1) {
            let newListFiles = pdfFile.filter(file => {
                return file.id !== fileId
            })
            setPdfFile(newListFiles)
        }
    }

    const getModelImport = (getType) => {
        setModelImport(getType)
    }

    /**Exist document add with name */
    const onExisteDocumentWithSameName = (fileName) => {
        let existDocument = pdfFile.find(u => u.fileName === fileName);
        return existDocument ? true : false;
    }


    /**
     * Save import information in back end
     */
    const onSaveButtonClick = () => {
        if (props.onImportReferenseSAP) {
            onImportReferenseSAP(pdfFile, modelImmport.value);
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeaderIcon
                title={'IMPORT REFERENCES'}
                isIconSvgOrPng={true}
                icon={iconFailureDoor}
                onCloseButtonClick={onCloseButtonClick}

            />
            <DialogContent >
                <Grid item xs={12}>
                    <AlertComponent
                        title={"Import references"}
                        message={"To import the file, download the information in this test template with this information, you must upload the file in extension .xlsx and that the file is not damaged."}
                        severityType={"info"}
                    /><br />
                    <div>
                        Enter the parcel you would like to import the references
                    </div>
                    <br />
                    <Grid item xs={12}>
                        <Selector
                            options={parcelName}
                            onChange={getModelImport}
                        />
                        <br />

                        {modelImmport && <Grid>
                            {modelImmport.value === 1 && <span className={classes.fileImport} >
                                <DownloadIcon fontSize="small" color="primary" style={{ marginRight: "5px" }} />
                                <a color="primary" href={downloadTemplateFEDEX}>{" Click here to download FEDEX template"}</a>
                            </span>}
                            {modelImmport.value === 2 && <span className={classes.fileImport} >
                                <DownloadIcon fontSize="small" color="primary" style={{ marginRight: "5px" }} />
                                <a color="primary" href={downloadTemplateUPS}>{" Click here to download UPS template"}</a>
                            </span>}

                            {modelImmport.value === 3 && <span className={classes.fileImport} >
                                <DownloadIcon fontSize="small" color="primary" style={{ marginRight: "5px" }} />
                                <a color="primary" href={downloadTemplate}>{" Click here to download SAP template"}</a>
                            </span>}
                            <br /><br />
                        </Grid>}

                    </Grid>
                </Grid>

                {modelImmport && <Grid item xs={12} >
                    <DropDownZone
                        datacy='drop-zone-documents-operating'
                        classes={classes}
                        multiple={false}
                        disabled={canBeSaved()}
                        accept={".xlsx"}
                        message={"Click here to add file import"}
                        onFilesReceived={(files) => {
                            if (files.length > 0) {
                                const filesAdded = files.map((file) => {
                                    let existFile = onExisteDocumentWithSameName(file.name);
                                    return ({
                                        id: uuidv4(),
                                        attachment: file,
                                        existFile: existFile
                                    })
                                })
                                setPdfFile(pdfFile.concat(filesAdded))
                            }
                        }}
                    />
                </Grid>}

                <Grid item xs={12} >
                    {
                        pdfFile.length > 0 &&
                        <FileContent
                            files={pdfFile}
                            onRemoveFile={onRemoveFile}
                        />
                    }
                </Grid>

                <Grid item xs={12}>
                    <br />
                    {isSaving && <LinearProgress color="primary" />}
                    <br />
                    <div className={classes.fileImport} >
                        <ButtonConfirm
                            title={!isSaving ? "IMPORT" : "IMPORTING..."}
                            onSavedClick={onSaveButtonClick}
                            disabled={canBeSaved() || isSaving}
                            isSaving={isSaving || canBeSaved()}
                        />
                    </div>
                </Grid>

            </DialogContent>
        </LateralPanel>
    )
}
PanelImportReference.propTypes = {
    onCloseButtonClick: PropTypes.func.isRequired,
    onImportReferenseSAP: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    // parcelNames: propTypes.object.isRequired

}
PanelImportReference.defaultProps = {
    open: false,
    // parcelNames:{ },
    isSaving: false,
    onImportReferenseSAP: () =>
        console.warn('Callback [onImportReferenseSAP] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
}


export default PanelImportReference