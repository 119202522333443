/**Import react section */
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
/**Import material UI Section */
import {
    DialogContent,
    Chip,
    Grid
} from '@material-ui/core';

/**Import components section */
import PanelHeaderIcon from '../../common/lateralPanel/PanelHeaderIcon'
import iconFailureDoor from '../../../resources/images/failureWarranty/doorWhite.svg';

/**Import resouces section */
import { SpareParRequestStatus } from '../../../store/helpers/AppConstants'
import defaultTheme from '../../../resources/themes/default.js'
import ButtonConfirm from '../../common/Buttons/ButtonConfirm'
import AlertComponent from '../../common/AlertComponent'

const ChangeStatusCustomerPanel = (props) => {
    const { onCloseButtonClick } = props;
    const [spartPartModel, setSpartPartModel] = useState(null);
    const [changeStatusModel, setStatusModel] = useState(null);

    useEffect(() => {
        setSpartPartModel(props.sparePartSale)
    }, [props.sparePartSale])

    const getPlaceholder = () => {
        switch (Number(spartPartModel ? spartPartModel.Status : null)) {
            case SpareParRequestStatus.DRAFT:
                return "SPARE PART DRAFT"
            case SpareParRequestStatus.DELETED:
                return "SPARE PART REQUEST DELETED"
            case SpareParRequestStatus.SEND_TECH_USA:
                return "SEND TO VALIDATION"
            case SpareParRequestStatus.REJECTED_RESEND:
                return "SPARE PART REJECTED"
            case SpareParRequestStatus.CANCELLED:
                return "SPARE PART REQUEST CANCELLED"
            default:
                return null
        }
    }

    const backgroundColorSelect = () => {
        let status = changeStatusModel ? changeStatusModel.status.value : null;
        switch (Number(status)) {
            case SpareParRequestStatus.DRAFT:
                return changeStatusModel.status.color
            case SpareParRequestStatus.SEND_TECH_USA:
                return changeStatusModel.status.color
            case SpareParRequestStatus.DELETED:
                return changeStatusModel.status.color
            case SpareParRequestStatus.REJECTED_RESEND:
                return changeStatusModel.status.color
            case SpareParRequestStatus.CANCELLED:
                return changeStatusModel.status.color
            case SpareParRequestStatus.IN_PROCESS:
                return changeStatusModel.status.color
            default:
                return null
        }
    };

    const backGroundColorDefaultByStatus = () => {
        switch (Number(spartPartModel ? spartPartModel.Status : null)) {
            case SpareParRequestStatus.DRAFT:
                return defaultTheme.palette.colors.purple
            case SpareParRequestStatus.SEND_TECH_USA:
                return defaultTheme.palette.colors.blue
            case SpareParRequestStatus.DELETED:
                return defaultTheme.palette.colors.red
            case SpareParRequestStatus.REJECTED_RESEND:
                return defaultTheme.palette.colors.red 
            case SpareParRequestStatus.CANCELLED:
                return defaultTheme.palette.colors.red
            case SpareParRequestStatus.IN_PROCESS:
                return defaultTheme.palette.colors.waiting
            default:
                return defaultTheme.palette.colors.purple
        }
    }


    const colorSelectorStyles = {
        control: styles => ({ ...styles, backgroundColor: changeStatusModel ? backgroundColorSelect() : backGroundColorDefaultByStatus(), color: "white" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? `${data.color}80` : isFocused ? `${data.color}80`
                    : null,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (`${data.color}80`),
                },
            };
        },
        input: styles => ({ ...styles, ...colorSelected() }),
        placeholder: styles => ({ ...styles, ...colorSelected() }),
        singleValue: (styles, { data }) => ({ ...styles, ...colorSelected(data.color) }),
        menu: styles => ({ ...styles, zIndex: 10, maxHeight: 200 }),
        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
    };

    /** Invoke when select property change*/
    const onSelectChange = (name, option) => {
        setStatusModel({
            ...changeStatusModel,
            [name]: option
        })
    }

    const GetComponentByStatus = (status) => {
        switch (Number(status)) {
            case SpareParRequestStatus.SEND_TECH_USA:
                return (<Grid item xs={12}>
                    <AlertComponent
                        title={"Submit request"}
                        message={"Send the request to the plant for validation"}
                        severityType={"info"}
                    /><br/>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <ButtonConfirm
                            title="CONFIRM"
                            isSaving={props.isSaving}
                            onSavedClick={props.onSavedClick(status)}
                        />
                    </div>
                </Grid>)
            case SpareParRequestStatus.DELETED:
                return (<Grid item xs={12}>
                    <AlertComponent
                        title={"Delete request"}
                        message={"Sure you want to delete the request, once deleted the changes cannot be reversed"}
                        severityType={"warning"}
                    /><br/>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <ButtonConfirm
                            title="CONFIRM"
                            isSaving={props.isSaving}
                            onSavedClick={props.onSavedClick(status)}
                        />
                    </div>
                </Grid>)
            case SpareParRequestStatus.CANCELLED:
                return (<Grid item xs={12}>
                    <AlertComponent
                        title={"Cancel request"}
                        message={"You sure want to cancel the request. once canceled the changes cannot be reversed"}
                        severityType={"warning"}
                    /><br/>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <ButtonConfirm
                            title="CONFIRM"
                            isSaving={props.isSaving}
                            onSavedClick={props.onSavedClick(status)}
                        />
                    </div>
                </Grid>)
            default:
                return null
        }
    }

    return (
        <>
            <PanelHeaderIcon
                title={'Change status'}
                isIconSvgOrPng={true}
                icon={iconFailureDoor}
                onCloseButtonClick={onCloseButtonClick}
            />
            <DialogContent >
                <Select
                    id={"id-status"}
                    placeholder={getPlaceholder()}
                    name="status"
                    autoFocus
                    options={spartPartModel ? spartPartModel.GetAvailableOperationsCustomer : null}
                    styles={colorSelectorStyles}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(value, action) => { onSelectChange(action.name, value) }}

                /><br /><br />
             {GetComponentByStatus(changeStatusModel ? changeStatusModel.status.value : null)}
            </DialogContent>

        </>
    )
}


const formatOptionLabel = ({ value, label, color }) => (
    <div>
        <Chip
            id={value}
            size="small"
            label={label}
            clickable
            style={{ color: defaultTheme.palette.common.white, background: color }}
        />
    </div>
);

const colorSelected = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    color: defaultTheme.palette.common.white
});

export default ChangeStatusCustomerPanel