import { makeStyles } from '@material-ui/styles'

import unauthorized from '../images/unauthorized.png'

import mobileUnauthorized from '../images/unauthorized_mobile.png'

/**
 * Mobile view styles
 */
export const mobileUseStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${mobileUnauthorized})`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center'
    },
    leftPanel: {
        marginTop: '20px',
        gridColumn: '1 / 3',
        textAlign: 'center',
        color: theme.palette.common.white,
        padding: '10px'
    },
    unauthorizedMessage: {
        marginLeft: '20px'
    }
}))


export const unauthorizedUseStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${unauthorized})`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
    },
    content:{
        gridRow: '2/3',
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center'
    },
    leftPanel: {
        marginTop: '20px',
        gridColumn: '1 / 2',
        textAlign: 'center',
        color: theme.palette.common.white,
        padding: '10px'
    },
    unauthorizedMessage: {
        marginLeft: '20px'
    }
}))