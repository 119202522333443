/**Import react section */
import React, { useState, useRef } from 'react';
import propTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
/**Import MaterialUi section */
import { Grid, FormHelperText } from '@material-ui/core'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import ValidatedTextField from '../../common/hooksForms/ValidatedTextField'
import AlertComponent from '../../common/AlertComponent'
import { lookUserInServiceProvider } from '../../../store/serviceproviders/serviceProvidersActions'
const AddUserInProviderPanel = (props) => {
    let { code } = useParams()
    const dispatch = useDispatch()
    const { handleSubmit, errors, control, watch } = useForm()
    const password = useRef({});
    password.current = watch("Password", "");

    const email = watch("Email", "")
    /**Local store this component */
    const { onSaveButtonClick, onCloseButtonClick, isSaving } = props;

    const [alreadyUsers, setAlreadyUser] = useState(false)


    const onExistUserInServiceProvider = (userName) => {
        return dispatch(lookUserInServiceProvider(userName))
            .then((result) => {
                setAlreadyUser(result)
            })
            .catch(() => {
                setAlreadyUser(false)
            });
    }

    if (email.length > 0) {
        onExistUserInServiceProvider(email)
    }

    const onSave = (provider) => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(provider, code)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Add new user"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={!alreadyUsers}
                onSaveButtonClick={handleSubmit(onSave)}
                isSaving={isSaving}
            />
            <div style={{ padding: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <ValidatedTextField
                            autoFocus
                            name="Email"
                            label="Email"
                            margin="dense"
                            rules={{
                                required: { value: true, message: 'The email is required' },
                                pattern: {
                                    value: /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Enter a valid e-mail address",
                                },
                            }}
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                        {
                            alreadyUsers &&
                            <AlertComponent
                                title={"Warning"}
                                message={"This user has already been added. Try a different user"}
                                severityType={"warning"}
                            />
                        }
                        <ValidatedTextField
                            name="Name"
                            label="Name"
                            margin='dense'
                            rules={{ required: 'The name is required' }}
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                        <ValidatedTextField
                            name="Phone"
                            variant="outlined"
                            label="Phone"
                            margin='dense'
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                        <ValidatedTextField
                            name="Password"
                            variant="outlined"
                            type="password"
                            margin="dense"
                            label="Password"
                            rules={{
                                required: { value: true, message: 'The password is required' },
                                minLength: {
                                    value: 6,
                                    message: "Password must have at least 8 characters"
                                },
                                pattern: {
                                    value: /^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                                    message: "The password must contain a minimum of 8 characters with at least 3 of the 4 character groups (Uppercase, Lowercase, Numbers and/or special characters).",
                                },
                            }}
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                        <ValidatedTextField
                            name="ConfirmPassword"
                            variant="outlined"
                            margin="dense"
                            type="password"
                            label="ConfirmPassword"
                            rules={{
                                validate: value => value === password.current || "The passwords do not match",
                                pattern: {
                                    value: /^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[a-z])(?=.*[0-9]))((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                                    message: "",
                                },
                            }}
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                        <FormHelperText>The password must contain a minimum of 8 characters with at least 3 of the 4 character groups (Uppercase, Lowercase, Numbers and/or special characters).</FormHelperText>

                    </Grid>
                </Grid>
            </div>

        </LateralPanel>
    )
}

/**Proptypes this component */
AddUserInProviderPanel.propTypes = {
    /**
	 * On close lateral menu
	 */
    onCloseButtonClick: propTypes.func.isRequired,
    /**
    * Invoke function for add new user in service provider
    */
    onSaveButtonClick: propTypes.func.isRequired,
    /**
    * Determinate is saving when add new service provider
    */
    isSaving: propTypes.bool.isRequired,
}


AddUserInProviderPanel.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    isSaving: false
}

export default AddUserInProviderPanel
