/**Import reacts section */
import React from 'react'
import propTypes from 'prop-types'
import {
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	PORTAL_ADMINISTRATOR,
	DISPATCHER,
	IMBERA_TECHNICIAN,
	SPARE_PARTS_IMBERA_USA,
	SALES_MANAGER,
	SALES_REPRESENTATIVE_MANAGER,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	VISUALIZER,
} from '../../../resources/RolesEnum'

/**Import components secction */
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import { useServiceTableStyles } from '../../../resources/styles/ServicesListStyles'
import FilterSelect from '../../common/Filters/FilterSelect'
import SearchBox from '../../common/SearchToolbox'
import Authorization from '../../common/AuthorizationEvaluation'
import ReportDateRange from '../../common/ReportDateRange/ReportDateRange'
import { getOptions, getOptionsInternalUsers } from '../../../store/helpers/FilterHelper'

/**Import material ui section */
import { Tooltip, Button, ButtonGroup } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import ImportFile from '@material-ui/icons/CloudUpload'

/**
 * Service list co
 */
const ServicesTable = (props) => {
	/**Functions */
	const { onChangeSearchValue, onDownloadReportOpenServices } = props

	/**Arrays */
	const {
		serviceProviders,
		salesRepresentatives,
		retails,
		internalUsers,
		isServicesOpen,
		redirectToCreateService,
		redirectToServiceDetail,
		userProfile,
		optionsStatus
	} = props
	/**String values */
	const { searchValue } = props
	/**String values */
	const { isDownloadReport } = props

	const classes = useServiceTableStyles()

	let representatives = getOptions(salesRepresentatives)
	let serviceProvider = getOptions(serviceProviders)
	let allRetailers = getOptions(retails)
	let allInternalUsers = getOptionsInternalUsers(internalUsers)

	const onRowClick = (sender, item) => {
		if (redirectToServiceDetail) {
			redirectToServiceDetail(item.Token, item.Serie)
		}
	}

	return (
		<>
			<div className={classes.root}>
				{/**Common component for filters */}
				<FilterSelect
					nameFilter={'STATUS'}
					data={optionsStatus}
					nameFilterUrl={'statusFilter'}
					isVisibleSearch={false}
					userProfile={userProfile}
					roles={[
						SERVICE_PROVIDER_TECHNICIAN,
						SERVICE_PROVIDER_MANAGER,
						PORTAL_ADMINISTRATOR,
						DISPATCHER,
						IMBERA_TECHNICIAN,
						SPARE_PARTS_IMBERA_USA,
						SALES_MANAGER,
						SALES_REPRESENTATIVE_MANAGER,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN,
						VISUALIZER,
					]}
				/>
				<FilterSelect
					nameFilter={'Sales rep'}
					data={representatives}
					nameFilterUrl={'representativesFilter'}
					isVisibleSearch={true}
					userProfile={userProfile}
					roles={[PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN, VISUALIZER]}
				/>
				<FilterSelect
					nameFilter={'Provider'}
					data={serviceProvider}
					nameFilterUrl={'serviceProviderFilter'}
					isVisibleSearch={true}
					userProfile={userProfile}
					roles={[PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN, VISUALIZER]}
				/>
				<FilterSelect
					nameFilter={'Customer'}
					data={allRetailers}
					nameFilterUrl={'retailersFilter'}
					isVisibleSearch={true}
					userProfile={userProfile}
					roles={[PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN, VISUALIZER]}
				/>
				<FilterSelect
					nameFilter={'Dispatcher'}
					data={allInternalUsers}
					nameFilterUrl={'dispatcherFilter'}
					isVisibleSearch={true}
					userProfile={userProfile}
					roles={[PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN, VISUALIZER]}
				/>
				<FilterSelect
					nameFilter={'Tech assigned'}
					data={allInternalUsers}
					nameFilterUrl={'teachAssignedFilter'}
					isActiveCleanButton={true}
					isVisibleSearch={true}
					userProfile={userProfile}
					roles={[PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN, VISUALIZER]}
				/>
				<div className={classes.grow}></div>
				<Authorization
					profile={userProfile}
					allowedRoles={[
						PORTAL_ADMINISTRATOR,
						DISPATCHER,
						IMBERA_TECHNICIAN,
						SERVICE_PROVIDER_MANAGER,
						SERVICE_PROVIDER_TECHNICIAN,
						SALES_MANAGER,
						SALES_REPRESENTATIVE_MANAGER,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN,
						VISUALIZER
					]}
				>
					<ReportDateRange onDownloadReport={onDownloadReportOpenServices} isDownloadReport={isDownloadReport} />
				</Authorization>
				<ButtonGroup component='span' size='small' variant='outlined' color='primary' className={classes.buttonsGroupContainer}>
					{isServicesOpen && (
						<Authorization
							profile={userProfile}
							allowedRoles={[
								DISPATCHER,
								CUSTOMER_MANAGER,
								CUSTOMER_TECHNICIAN,
								SERVICE_PROVIDER_MANAGER,
								SERVICE_PROVIDER_TECHNICIAN,
								SALES_REPRESENTATIVE_MANAGER,
								SALES_MANAGER
							]}
						>
							<>
								<Tooltip component='span' title='Create service'>
									<Button className={classes.buttonsGroup} onClick={redirectToCreateService} disabled={isDownloadReport}>
										<AddIcon />
									</Button>
								</Tooltip>
								<Authorization profile={props.userProfile} allowedRoles={[SERVICE_PROVIDER_TECHNICIAN, SERVICE_PROVIDER_MANAGER]}>
									<Tooltip component='span' title='Import Comments History'>
										<Button onClick={props.openImportCommentsPanel} className={classes.buttonsGroup}>
											<ImportFile />
										</Button>
									</Tooltip>
								</Authorization>
							</>
						</Authorization>
					)}
				</ButtonGroup>
				<div className={classes.searchRoot}>
					<SearchBox placeholder='Search ticket or model' onChange={onChangeSearchValue} value={searchValue} />
				</div>
			</div>

			<DataGrid
				headers={props.configuration}
				data={props.services}
				isLoading={props.isLoading}
				className={classes.table}
				sortBy={props.sortBy}
				sortDirection={props.sortDirection}
				onChangeSortingCriteria={props.onchangeSortingCriteria}
				onItemClick={onRowClick}
			/>
		</>
	)
}
ServicesTable.propTypes = {
	/**
	 * The list of services
	 */
	services: propTypes.arrayOf(
		propTypes.shape({
			Ticket: propTypes.any.isRequired
		})
	).isRequired,
	/**
	 * Callback executed when the user change the orden criteria
	 */
	onchangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * Determine the sorting column for the service table
	 */
	sortBy: propTypes.string,
	/**
	 * The sort direction
	 */
	sortDirection: propTypes.oneOf(['asc', 'desc']),
	/**
	 * Determine if is loading the services list
	 */
	isLoading: propTypes.bool.isRequired,
	serviceProviders: propTypes.array,
	salesRepresentatives: propTypes.array,
	/**Fuct to redirect to view for creatae service */
	redirectToCreateService: propTypes.func,
	isServicesOpen: propTypes.bool.isRequired
}
ServicesTable.defaultProps = {
	services: [],
	salesRepresentatives: [],
	serviceProviders: [],
	retails: [],
	internalUsers: [],
	isLoading: false,
	isServicesOpen: true
}
export default ServicesTable
