const CustomerTableConfig = [
	
	{
		title: 'Creation Date',
		dataSource: 'CreationDate',
		sortable: true,
		width: 200
	},
	{
        title: 'Code',
		dataSource: 'Code',
		sortable: true,
		width: 200
	},
	{
		title: 'Material Code',
		dataSource: 'CodeMaterial',
		width: 200
	},
	{
		title: 'Material Description',
		dataSource: 'DescriptionMaterial',
		width: 400
	},
    {
		title: 'Description',
		dataSource: 'Description',
		width: 400
	},
]
export default CustomerTableConfig
