/**
 * Defined services status
 */
export const ServicesStatusTypes = {
    ACTIVE: 1,
    ASSIGNED: 2,
    SUSPENDED: 3,
    WAITING: 4,
    COMPLETED: 5,
    REPLACED: 6,
    CANCELLED: 7,
};

export const DELETED = 8
export const CHANGE_INITIATED_DATE = 9
export const ASSIGNED_SERVICE_PROVIDER_TECH = 10
export const CHANGE_REPRESENTATIVE_SALES = 11
export const REASSIGNED_SERVICE_PRIVIDER = 12

export const NOTIFICATION_SERVICE = "Service"

/**Position for text box */
export const PositionPlacement = {
    END: 'end',
    START: 'start',
    TOP: 'top',
    BOTTOM: 'bottom'
}

export const UserTypes = {
    ACTIVE_DIRECTORY        : 'ActiveDirectoryUser',
    NON_ACTIVE_DIRECTORY    : 'NonActiveDirectoryUser'
};

/**
 * Defined type of roles
 */
export const RoleType = {
    UNDEFINED: 0,
    SERVICE_PROVIDER: 1,
    USER_IMBERA: 2,
    SALES_REPRESENTATIVE: 3,
    CUSTOMER: 4,
};

export const AccountCode= {
    IMBERA: "IMB",
};

/**
 * Defined type of failure warranty
 */
export const FailureWarrantyType = {
    REFRIGERATION_NOT_COOL: 1,
    ILUMINATION: 2,
    DOOR: 3
};

/**
 * Get position in pam default address (Imbera Food Service, Kennesaw, Georgia, EE. UU. )
 * latitude: 34.05732, longitude: -84.6088261
 */
export const PositionMapDefault =
    { latitude: 34.05732, longitude: -84.6088261, addresDefault: "Imbera Food Service, Kennesaw, Georgia, EE. UU." };


export const SpareParRequestStatus = {
    DRAFT: 1,
    IN_PROCESS: 2,
    SEND_TO_MX: 3,
    CLOSED: 4,
    DELETED: 5,
    CANCELLED: 6,
    SEND_TECH_USA: 7,
    REJECTED_RESEND: 8,
};

export const WarehouseType = {
    MEXICO: 1,
    USA: 2,
};

export const ServiceProviderStatus = {
    INVITATION_SEND: 1,
    SUPPLIER_ACCEPTED: 2,
    ACCEPTED_IN_COMPANY: 3,
    REJECTED_IN_COMPANY:4,
};

export const PaymentRequestStatus = {
    DRAFT: 1,
    IN_PROCESS: 2,
    REJECTED: 4,
    ACCEPTED: 5,
    CLOSED: 6,
};

export const DELETED_PAYMENT = 3

/**
 * Get description amoutn type
 */
export const PaymentAmountType ={
    AmountLaboral:	"LABORAL",
    AmountMaterial:	"MATERIALS",
    AmountMileage:	"MILEAGE",
    AmountMiscellaneos:	"MISCELLANEOUS",
    AmountSales:	"SALES TX HST",
    AmountSale:	"SALES TXT HST",
    AmountTotal:	"TOTAL",
}

export const WarrantyType ={
    UNDEFINED:0,
    WARRANTY: 1,
    SALE: 2
}
