import React from 'react'
import propTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(1)
		}
	}
}))

/**
 * Slider pagination
 */
const SliderPagination = (props) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Pagination
				count={props.count}
				page={props.page}
				size='small'
				variant='outlined'
				color='secondary'
				onChange={props.onPageButtonClick}
			/>
		</div>
	)
}
SliderPagination.propTypes = {
	/**
	 * The quantity of pagination items to show
	 */
	count: propTypes.number.isRequired,
	/**
	 * The actual page set
	 */
	page: propTypes.number.isRequired,
	/**
	 * Callback execute when the user click page button
	 */
	onPageButtonClick: propTypes.func.isRequired
}
SliderPagination.defaultProps = {
	count: 1,
	page: 1,
	onPageButtonClick: () => {
		console.warn('No [onPageButtonClick] callback defined')
	}
}
export default SliderPagination
