import React, { Fragment } from 'react';
import propTypes from 'prop-types'
import Select from 'react-select'

import { salesRepresentativeStyles } from '../../../resources/styles/SalesRepresentativeStyles';


export const ServiceTypeSelector = (props) => {
    const { onPropertyChange, serviceType, placeHolder, isDisabled } = props

    const serviceTypeOptions = [
        { value: 'Warranty', label: 'Warranty service' },
        { value: 'Supplies', label: 'Supplies' },
        { value: 'Office', label: 'Office service' },
    ]

    const classes = salesRepresentativeStyles();
    return (
        <div className={classes.texboxContainer}>
            <Fragment className={classes.texboxContainer}>
                <Select
                    options={serviceTypeOptions}
                    value={serviceType ? serviceType : ""}
                    onChange={onPropertyChange}
                    placeholder={serviceType ? serviceType : placeHolder}
                    menuPortalTarget={document.parentNode}
                    menuPosition='fixed'
                    menuPlacement={'bottom'}
                    isDisabled={isDisabled}
                />
            </Fragment>
        </div>
    )
}

ServiceTypeSelector.propTypes = {
    /**
     * Placeholder
     */
    placeholder: propTypes.string,
    /**
     * Callback executed when the user change the selected option
     */
    onPropertyChange: propTypes.func,
    /**
     * is disabled selector by users externals
     */
    isDisabled: propTypes.bool
}
ServiceTypeSelector.defaultProps = {
    isDisabled: false
}