/**Import react serction */
import React from 'react'
import propTypes from 'prop-types'
/**Import images sections */
import noInformation01 from '../../resources/images/noInformation/noInformation01.png';
import noInformation02 from '../../resources/images/noInformation/noInformation02.png';
import { useDataGridToolbarStyle } from '../../resources/styles/CommonIndexStyles';
/**Import material UI Section */
import { Typography } from '@material-ui/core'

const ImagesNoInformation = (props) => {
    const classes = useDataGridToolbarStyle();
    const { data, query, isLoading, titleData } = props;

    const renderContent = () => {
        if (data.length <= 0 && query !== "" && !isLoading) {
            return (
                <>
                    <img src={noInformation02}
                        alt='no-search'
                        className={classes.noInformationImage}
                    />
                    <Typography align='center' className={classes.textColorNoData} >There are no search results, please try again.</Typography>
                </>
            )
        }
        else if (data.length <= 0 && !isLoading) {
            return (<>
                <img src={noInformation01}
                    alt='no-data'
                    className={classes.noInformationImage}
                />
                <Typography align='center' className={classes.textColorNoData}>{titleData}</Typography>

            </>
            )
        }
        else {
            return null
        }
    }

    return (
        renderContent()
    )
}

ImagesNoInformation.propTypes = {
    /**Data to show the user*/
    data: propTypes.array.isRequired,
    /**Is loading data*/
    isLoading: propTypes.bool.isRequired,
    /**Determine the title when there are no data results*/
    titleData: propTypes.string.isRequired,
    /**Determines if there are search results*/
    query: propTypes.string.isRequired,
}
ImagesNoInformation.defaultProps = {
    isLoading: false,
    data: [],
    titleData: '',
    query: '',
}

export default ImagesNoInformation
