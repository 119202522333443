/**Import react section */
import React, { useState, useEffect } from 'react'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'

/**Import componnets section */
import Toaster from '../common/Toaster'
import EditSalesRepresentative from './components/EditSalesRepresentative.js'
import UploadImgCropDialog from '../common/cropDialog/UploadImgCropDialog'

import EditUserPanel from './components/EditUserPanel'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'

/**Import actions sections */
import {
    addUserInRole,
    removeUserFromRole,
} from '../../store/users/UsersActions'
import {
    uploadLogoAccount, saveExternalUser,
    uploadLogoExternalUser, SET_ROLES_IN_PROFILE
} from '../../store/salesrepresentatives/salesRepresentativesActions'

/**Import helpers section */
import { isUserInRole } from '../../store/helpers/UserHelper'


const Edit = (props) => {
    const { salesEdit, onClosedEditLateralPanel,
        isSaving, updateSalesRepresentativeData,
        handleOpenModalUploadImg,
        onSetHandleOpenCloseModalUploadImg,
        userInSalesRepresentativeEdit,
        onSetUserActiveExpiredDialog,
        userActiveExpiredConfing,
        onActiveUserExpired,
        onSetResendPasswordDialog,
        resendPasswordDialog,
        onResendPasswordUser,
        onActiveNotifications,
    } = props;

    const { onEditPanelUserConfig,
        isModeSalesRepresentativeUser,
        onRemoveUserFromAccount,
        passwordDialogConfiguration,
        onSetPasswordDialogConfiguration,
        isValidatingPassword,
        isEnable
    } = props;
    /**Hooks sections */
    const dispatch = useDispatch()
    /**Local store from this component */
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [logoFileBase64, setLogoFileBase64] = useState("");
    const [userInSalesRepresentativeModel, setUserInSalesRepresentativeModel] = useState(null);



    useEffect(() => {
        setUserInSalesRepresentativeModel(userInSalesRepresentativeEdit ? userInSalesRepresentativeEdit.user : null)
    }, [userInSalesRepresentativeEdit])

    /**Upload image crop */
    const onUploadLogoUser = (imageBase64) => {
        let token = isModeSalesRepresentativeUser ? (userInSalesRepresentativeModel && userInSalesRepresentativeModel.Id) : (salesEdit && salesEdit.customer.Token);
        let tokenManager = isModeSalesRepresentativeUser ? (userInSalesRepresentativeModel && userInSalesRepresentativeModel.Token) : ''

        return dispatch(isModeSalesRepresentativeUser ? uploadLogoExternalUser(token, imageBase64, tokenManager) : uploadLogoAccount(token, imageBase64))
            .then((representative) => {
                onSetHandleOpenCloseModalUploadImg(representative);
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "An error was generated while updating the logo", variant: 'error' })
                console.error("Error to upload image crop");
            });
    };

    const onUserInSalesRepresentative = (roles, userName) => {
        dispatch({
            type: SET_ROLES_IN_PROFILE,
            roles: roles,
            userName: userName,

        })
    }


    /**Add role to user and remove roles */
    const onToggleUserRole = (roleName, roleType) => {
        let token = userInSalesRepresentativeModel && userInSalesRepresentativeModel.Token;
        let roles = userInSalesRepresentativeModel && userInSalesRepresentativeModel.Roles;
        let userName = userInSalesRepresentativeModel && userInSalesRepresentativeModel.UserName;
        if (isUserInRole(roleName, roles)) {
            return dispatch(removeUserFromRole(token, roleName))
                .then(roles => {
                    onUserInSalesRepresentative(roles, userName)
                    setUserInSalesRepresentativeModel({
                        ...userInSalesRepresentativeModel,
                        "Roles": roles
                    })
                })
                .catch((error) => {
                    setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to remove role from user', variant: 'warning' })
                    console.error("Failed to remove role");
                });
        }
        else {
            return dispatch(addUserInRole(token, roleName, roleType))
                .then(roles => {
                    onUserInSalesRepresentative(roles, userName)
                    setUserInSalesRepresentativeModel({
                        ...userInSalesRepresentativeModel,
                        "Roles": roles
                    })
                })
                .catch((error) => {
                    setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add role', variant: 'error' })
                    console.error("Failed to assign role", error);
                });
        }
    }


    /**Set img base 64 in the local store */
    const onSetLogoFileBase64 = (fileBase64) => {
        setLogoFileBase64(fileBase64);
    };

    /**
    * Handler to edit an user.
    * Execute async action to edit an user.
    * @param {object} user
    */
    const editUserHandler = user => {
        dispatch(saveExternalUser(user))
            .then(() => {
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error updating user information", variant: 'error' })
            });
    };

    return (
        <div>
            {
                (salesEdit && salesEdit.opened) &&
                <EditSalesRepresentative
                    customer={salesEdit.customer}
                    onCloseButtonClick={onClosedEditLateralPanel}
                    onSaveButtonClick={updateSalesRepresentativeData}
                    isSaving={isSaving}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                />
            }
            {
                <UploadImgCropDialog
                    open={handleOpenModalUploadImg}
                    onClose={onSetHandleOpenCloseModalUploadImg}
                    fileBase64={logoFileBase64}
                    isSaving={isSaving}
                    onUploadImagen={onUploadLogoUser}

                />
            }
            {
                (userInSalesRepresentativeEdit ? userInSalesRepresentativeEdit.opened : false) &&
                <EditUserPanel
                    user={userInSalesRepresentativeModel}
                    isSaving={isSaving}
                    onCloseButtonClick={onEditPanelUserConfig}
                    onSaveButtonClick={editUserHandler}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                    onToggleUserRole={onToggleUserRole}
                    onActiveNotifications={onActiveNotifications}
                    isEnable={isEnable}
                />
            }

            {
                (passwordDialogConfiguration && passwordDialogConfiguration.opened) &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={onRemoveUserFromAccount}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetPasswordDialogConfiguration}
                    message1={`This action will remove the access of ${passwordDialogConfiguration.user ? passwordDialogConfiguration.user.Name : ''} to this portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                (resendPasswordDialog && resendPasswordDialog.opened) &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={resendPasswordDialog.opened}
                    onClickButtonConfirm={onResendPasswordUser}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetResendPasswordDialog}
                    message1={`The password will be send to  ${resendPasswordDialog.user ? resendPasswordDialog.user.Name : ''}. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                (userActiveExpiredConfing && userActiveExpiredConfing.opened) && <ConfirmPasswordDialog
                    title={'Confirm activate expired user'}
                    open={userActiveExpiredConfing.opened}
                    onClickButtonConfirm={onActiveUserExpired}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetUserActiveExpiredDialog}
                    message1={`This action will activate the expired ${userActiveExpiredConfing.user ? userActiveExpiredConfing.user.Name : ''} user in the portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </div>
    )
}

Edit.propTypes = {
    /**
     * invoke update sales representative data
     */
    updateSalesRepresentativeData: propTypes.func.isRequired,
    /**
     * Close lateral panel for edit information from sales representative
     */
    onClosedEditLateralPanel: propTypes.func.isRequired,
    /**
     * Close Modal for upload logo user
     */
    onSetHandleOpenCloseModalUploadImg: propTypes.func.isRequired,
    /**
     * Resend password to user
     */
    onResendPasswordUser: propTypes.func.isRequired,
}


Edit.defaultProps = {
    isSaving: false,
    onClosedEditLateralPanel: () =>
        console.warn('Callback [onClosedEditLateralPanel] no defined'),
    updateSalesRepresentativeData: () =>
        console.warn('Callback [updateSalesRepresentativeData] no defined'),
    onSetHandleOpenCloseModalUploadImg: () =>
        console.warn('Callback [onSetHandleOpenCloseModalUploadImg] no defined'),
    onResendPasswordUser: () =>
        console.warn('Callback [onResendPasswordUser] no defined'),
}


export default Edit
