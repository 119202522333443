/**Import react section */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { hasAuthorization } from '../../../../store/session/sessionActions'

/** Import components */
import {
    Layout,
    Content,
    Header,
    HeaderBreadCrum
} from '../../../layouts/AuthenticatedLayout'

/**Import Material UI section */
import { Typography, Card, Fab } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

/**Import components section */
import { profileStyle } from '../../../../resources/styles/ProfileStyles'
import AvatarComponent from '../../../common/AvatarComponent'
import { WhiteTextBox } from '../../../common/TextBox'
import RolesForm from './RolesForm'
import { UserTypes } from '../../../../store/helpers/AppConstants'
import Toolbar from '../../../common/Toolbar'
import ProfileIcon from '../../../../resources/images/profile.svg';
import AlertComponent from '../../../common/AlertComponent'
import { PORTAL_ADMINISTRATOR, SERVICE_PROVIDER_MANAGER, SALES_REPRESENTATIVE_MANAGER, CUSTOMER_MANAGER } from '../../../../resources/RolesEnum';
import ShowViewSkeleton from '../../../skeletons/admin/Show'

/** */
const ShowDesktopView = (props) => {

    const { isModeSaved, isSaving, disabled, userProfile, loggedUser, isLoading } = props;
    /**Functions */
    const { onSaveButtonClick, onEditButtonClick, onSetHandleOpenCloseModalUploadImg, onSetLogoFileBase64 } = props;
    const [userModel, setUserModel] = useState(defaultUserProfile);
    const classes = profileStyle();

    let isActiveTextBox = userProfile ? userProfile.UserType !== UserTypes.ACTIVE_DIRECTORY : false
    let isAdministrator = userProfile ? hasAuthorization(loggedUser, [PORTAL_ADMINISTRATOR, SERVICE_PROVIDER_MANAGER, SALES_REPRESENTATIVE_MANAGER, CUSTOMER_MANAGER]) : true
    useEffect(() => {
        setUserModel(props.userProfile)
    }, [props.userProfile])


    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(userModel)
        }
    }

    const onPropertyChange = event => {
        let { name, value } = event.target;
        setUserModel({
            ...userModel,
            [name]: value
        })
    }

    /**Verify if can be sabed */
    const canBeSaved = () => {
        if (userModel ? (userModel.phoneNumber.length <= 0) : true) return false;
        return true;
    }

    const renderContentRight = () => {
        return (
            <>
                <Fab
                    disabled={(isModeSaved ? !canBeSaved : false) || isSaving}
                    className={isModeSaved ? classes.buttonSaved : classes.buttonEdit}
                    onClick={isModeSaved ? onSave : onEditButtonClick}
                >
                    {isModeSaved ? <SaveIcon /> : <EditIcon />}
                </Fab>
                {
                    isModeSaved && <Fab
                        className={classes.buttonCancel}
                        onClick={onEditButtonClick}
                    >
                        {<CancelIcon />}
                    </Fab>
                }
            </>
        )
    }

    const onRenderConten = () => {
        if (isLoading) {
            return <ShowViewSkeleton />

        }
        else {
            return (
                <div className={classes.container}>
                    <div className={classes.root}>
                        <div className={classes.header}>
                            <Toolbar
                                title="Personal information"
                                renderContentRight={renderContentRight()}
                                icon={ProfileIcon}

                            />
                        </div>
                        <div className={classes.profileRowTwo}>
                            <Card className={classes.panelLeft}>
                                <div className={classes.avatarContainer}>
                                    <AvatarComponent
                                        name={userModel ? userModel.Name : ""}
                                        avatarUrl={userModel ? userModel.AvatarUrl : ""}
                                        onSetHandleOpenModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                                        onSetLogoFileBase64={onSetLogoFileBase64}
                                        isAvatarFromIdentity={true}
                                    />
                                </div>
                                <WhiteTextBox
                                    active={isModeSaved && isActiveTextBox}
                                    fullWidth
                                    autoFocus
                                    name="Name"
                                    variant="outlined"
                                    margin="dense"
                                    label="Name"
                                    onChange={onPropertyChange}
                                    value={userModel ? userModel.Name : ""}
                                />
                                <WhiteTextBox
                                    active={isModeSaved}
                                    fullWidth
                                    autoFocus
                                    name="PhoneNumber"
                                    variant="outlined"
                                    margin="dense"
                                    label="Phone number"
                                    onChange={onPropertyChange}
                                    value={userModel ? userModel.PhoneNumber : ""}
                                />
                                <WhiteTextBox
                                    active={isModeSaved && isActiveTextBox}
                                    fullWidth
                                    autoFocus
                                    name="Email"
                                    variant="outlined"
                                    margin="dense"
                                    label="Email"
                                    onChange={onPropertyChange}
                                    value={userModel ? userModel.Email : ""}
                                />
                                <WhiteTextBox
                                    active={false}
                                    fullWidth
                                    autoFocus
                                    name="UserName"
                                    variant="outlined"
                                    margin="dense"
                                    label="User name"
                                    onChange={onPropertyChange}
                                    value={userModel ? userModel.UserName : ""}
                                />
                            </Card>
                            <Card className={classes.panelRight}>

                                {
                                    !isAdministrator &&
                                    <>
                                        <AlertComponent
                                            title="Information"
                                            severityType="info"
                                            message="Roles cannot be assigned or removed, you need to contact the administrator"
                                        /><br />
                                    </>
                                }
                                <RolesForm
                                    roles={userProfile ? userProfile.Roles : []}
                                    isSaving={isSaving}
                                    disabled={disabled}
                                    isActiveCheck={false}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
            )
        }
    }


    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                user={props.user}
                breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>
                            Administration / Profile
						</Typography>
                    </HeaderBreadCrum>
                }
            />
            <Content key={'AuthenticatedContent'}>
                {onRenderConten()}

            </Content>
        </Layout>
    )
}

const defaultUserProfile = {
    Name: '',
    UserName: '',
    Email: '',
    PhoneNumber: '',
}

ShowDesktopView.propTypes = {
	/**
	 * Determine if the role is saving
	 */
    isSaving: propTypes.bool.isRequired,
    /**Disabled check box */
    disabled: propTypes.bool.isRequired,
    /**Determinate if checkBox is active */
    isActiveCheck: propTypes.bool,
    /**Activate or disabled roles from user */
    onToggleRole: propTypes.func.isRequired,
    /**Activate mode edit */
    onEditButtonClick: propTypes.func.isRequired,
    /**Save information from user */
    onSaveButtonClick: propTypes.func.isRequired,
    /**Opepn / close modal for upload logo */
    onSetHandleOpenCloseModalUploadImg: propTypes.func.isRequired,
    /**set logo upload */
    onSetLogoFileBase64: propTypes.func.isRequired,
}
ShowDesktopView.defaultProps = {
    /**Determine if the role is saving */
    isSaving: false,
    /**Disabled check box */
    disabled: false,
    /**Determinate if checkBox is active */
    isActiveCheck: true,
    /**Activate or disabled roles from user */
    onToggleRole: () =>
        console.warn('Callback [onToggleRole] no defined'),
    /**Activate mode edit */
    onEditButtonClick: () =>
        console.warn('Callback [onEditButtonClick] no defined'),
    /**Save information from user */
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    /**open and close modal for add logo user */
    onSetHandleOpenCloseModalUploadImg: () =>
        console.warn('Callback [onSetHandleOpenCloseModalUploadImg] no defined'),
    /**set logo for user added */
    onSetLogoFileBase64: () =>
        console.warn('Callback [onSetLogoFileBase64] no defined'),
}


export default withImmutablePropsToJS(ShowDesktopView)