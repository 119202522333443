import React from 'react';
import propTypes from 'prop-types'

/** Material UI import section */
import { 
    Drawer, 
} from '@material-ui/core';
import {panelLateralStyles} from '../../../resources/styles/PanelLateralStyles';

const LateralPanel = props => {
    const { onClose, children } = props;

    const classes = panelLateralStyles();

    return (
        <Drawer
            anchor="right" 
            open={props.open} 
            onClose={onClose}
        >
            <div
                className={classes.drawerContent}
                tabIndex={0}
            >
                {children}
            </div>
        </Drawer>
    );
}
LateralPanel.propTypes = {
    open: propTypes.bool.isRequired
}
LateralPanel.defaultProps = {
    open: true
}
export default LateralPanel;