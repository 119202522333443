
/**Import react section */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4'

/**Import MaterialUi section */
import { DialogContent, Grid, LinearProgress } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload';


/**Import resources section */
import PanelHeaderIcon from '../../common/lateralPanel/PanelHeaderIcon'
import { panelEditUseStyles } from '../resources/useStyles'
import iconFailureDoor from '../../../resources/images/importIcon.png';
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import AlertComponent from '../../common/AlertComponent'
import ButtonConfirm from '../../common/Buttons/ButtonConfirm'
import DropDownZone from '../../common/DropDownZone';
import FileContent from '../../common/FileContent';

//*** Excel files imported **/
import downloadTemplate from '../../../resources/files/TemplateSparePart.xlsx';

/**
 * Panel to edit information traking
 * @param {} props 
 */
const PanelImportSparePart = (props) => {

    const classes = panelEditUseStyles();
    const { isSaving, onCloseButtonClick, onImportSpartPartList } = props;
    const [pdfFile, setPdfFile] = useState([]);

    const canBeSaved = () => {
        if (pdfFile.length > 0) return false;
        return true;
    }
    /**
     * Remove file added of state 
     * @param {*} fileId 
     */
    const onRemoveFile = (fileId) => {
        let index = pdfFile.findIndex(attachment => attachment.id === fileId);
        if (index !== -1) {
            let newListFiles = pdfFile.filter(file => {
                return file.id !== fileId
            })
            setPdfFile(newListFiles)
        }
    }

    /**Exist document add with name */
    const onExisteDocumentWithSameName = (fileName) => {
        let existDocument = pdfFile.find(u => u.fileName === fileName);
        return existDocument ? true : false;
    }


    /**
     * Save import information in back end
     */
    const onSaveButtonClick = () => {
        if (props.onImportSpartPartList) {
            onImportSpartPartList(pdfFile);
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeaderIcon
                title={'IMPORT SPARE PARTS'}
                isIconSvgOrPng={true}
                icon={iconFailureDoor}
                onCloseButtonClick={onCloseButtonClick}

            />
            <DialogContent >
                <Grid item xs={12}>
                    <AlertComponent
                        title={"Import Spare Parts in request"}
                        message={"To import the spare parts, download the template, add the requested information, once the information is complete, upload the document."}
                        severityType={"info"}
                    /><br />
                    <br />
                    <span className={classes.fileImport} >
                        <DownloadIcon fontSize="small" color="primary" style={{ marginRight: "5px" }} />
                        <a color="primary" href={downloadTemplate}>{" Click here to download template"}</a>
                    </span>
                    <br />
                </Grid>

                {<Grid item xs={12} >
                    <DropDownZone
                        datacy='drop-zone-documents-operating'
                        classes={classes}
                        multiple={false}
                        disabled={canBeSaved()}
                        accept={".xlsx"}
                        message={"Click here to add file import"}
                        onFilesReceived={(files) => {
                            if (files.length > 0) {
                                const filesAdded = files.map((file) => {
                                    let existFile = onExisteDocumentWithSameName(file.name);
                                    return ({
                                        id: uuidv4(),
                                        attachment: file,
                                        existFile: existFile
                                    })
                                })
                                setPdfFile(pdfFile.concat(filesAdded))
                            }
                        }}
                    />
                </Grid>}

                <Grid item xs={12} >
                    {
                        pdfFile.length > 0 &&
                        <FileContent
                            files={pdfFile}
                            onRemoveFile={onRemoveFile}
                        />
                    }
                </Grid>

                <Grid item xs={12}>
                    <br />
                    {isSaving && <LinearProgress color="primary" />}
                    <br />
                    <div className={classes.fileImport} >
                        <ButtonConfirm
                            title={!isSaving ? "IMPORT" : "IMPORTING..."}
                            onSavedClick={onSaveButtonClick}
                            disabled={canBeSaved() || isSaving}
                            isSaving={isSaving || canBeSaved()}
                        />
                    </div>
                </Grid>

            </DialogContent>
        </LateralPanel>
    )
}
PanelImportSparePart.propTypes = {
    onCloseButtonClick: PropTypes.func.isRequired,
    onImportSpartPartList: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,

}
PanelImportSparePart.defaultProps = {
    isSaving: false,
    onImportSpartPartList: () =>
        console.warn('Callback [onImportSpartPartList] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
}


export default PanelImportSparePart