export default (values, chartDescription) => {
    return {
        type: 'mixed',
        globals: {
            fontFamily: 'Arial'
        },
        backgroundColor: '#FFFFFF',
        width: '100%',
        title: {
            text: 'Services',
            align: 'left',
            backgroundColor: 'none',
            bold: false,
            fontColor: '#000000',
            fontSize: '20px',
            x: '40px',
            y: '5px'
        },
        subtitle: {
            text: 'OPEN AND CLOSED SERVICES',
            align: 'left',
            backgroundColor: 'none',
            bold: false,
            fontColor: '#7E7E7E',
            fontSize: '14px',
            x: '55px',
            y: '35px'
        },
        legend: {
            backgroundColor: 'none',
            borderWidth: '0px',
            item: {
                fontColor: '#7E7E7E'
            },
            layout: 'float',
            marker: {
                type: 'circle'
            },
            shadow: false,
            toggleAction: 'remove',
            x: '32px',
            y: '80px'
        },
        plot: {
            barSpace: '20px',
            animation: {
				effect: 'ANIMATION_UNFOLD_VERTICAL',
				speed: 'ANIMATION_FAST'
			}
        },
        plotarea: {
            margin: '125px 45px 30px 45px'
        },
        scaleX: {
            values: chartDescription,
            guide: {
                visible: false
            },
            item: {
                color: '#8B8B8B'
            },
            lineColor: '#D9D7D5',
            lineWidth: '1px',
            tick: {
                visible: false
            }
        },
        scaleY: {
            values: '0:35:5',
            guide: {
                lineStyle: 'dotted'
            },
            item: {
                color: '#8B8B8B'
            },
            lineColor: '#D9D7D5',
            lineWidth: '1px',
            tick: {
                visible: false
            }
        },
        shapes: [{
            type: 'triangle',
            backgroundColor: '#5297B6',
            size: '5px',
            x: '50px',
            y: '50px'
        }],
        series: values
    }
}