/**
 * Define the common styles used in all the index's views
 * 
 */
import { makeStyles } from '@material-ui/styles'

/**
 * 
 */
export const useDataGridToolbarStyle = makeStyles((theme) => ({
    root: {
        height: theme.mixins.toolbar.minHeight,
        display: 'flex',
        flexDirection: 'row-reverse',
        paddingRight: '10px',
        alignItems: 'center',
        margin: '10px 10px 10px 0px',

    },
    containerIndexModeCard: {
        gridColumn: 1,
        gridRow: 2,
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${220}px)`,
        minHeight: `calc(100vh - ${220}px)`,
        padding: "20px"
    },
    containerCards: {
        display: 'flex',
        marginLeft: "auto",
        marginRight: "auto",
    },
    addIcon: {
        backgroundColor: theme.palette.common.black,
        width: theme.spacing(5),
        height: theme.spacing(5),
        cursor: 'pointer'
    },
    searchButtonIcon: {
        color: theme.palette.primary.light
    },
    disabledCheckbox: {
        marginLeft: '15px'
    },
    flexGrow: {
        flexGrow: 1
    },

    rootIndex: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
    },
    searchIndex: {
        gridColumn: 1,
        gridRow: 1,
        height: theme.mixins.toolbar.minHeight,
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        margin: '10px 10px 10px 0px'
    },
    containerIndex: {
        gridColumn: 1,
        gridRow: 2,
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${180}px)`,

    },
    pagination: {
        gridColumn: 1,
        gridRow: 3,
        marginTop: "4px",
        display: 'flex',
        alignItems: "flex-end"
    },
    btnAdd: {
        marginRight: theme.spacing(1),
    },
    btnChangeMode: {
        marginRight: theme.spacing(1),
    },
    menuItem: {
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            cursor: 'pointer',
            opacity: 0.5
        },
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        border: "2px solid transparent",
        borderColor: theme.palette.secondary.main
    },
    noInformationImage: {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        width: '360px',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '320px',
        },
    },
    textColorNoData: {
        color: theme.palette.serviceStatus.newServiceDark,
    },
    buttonsGroupContainer: {
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroup: {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.primarySelected} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth: "14px !important",
            height: "2rem !important",
        },
    },
    buttonsDownload: {
        backgroundColor: theme.palette.colors.green,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.greenDark} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth: "14px !important",
            height: "2rem !important",
        },
    },
    buttonsGroupManual: {
        backgroundColor: theme.palette.colors.green,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.green} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
            opacity: .75
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth: "14px !important",
            height: "2rem !important",
        },
    },
    buttonsGroupBlue: {
        backgroundColor: theme.palette.colors.purpleRose,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.purpleRose} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
            opacity: .95,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth: "14px !important",
            height: "2rem !important",
        },
    },
    chipView: {
        backgroundColor: `${theme.palette.colors.purple} !important`,
        color: `${theme.palette.common.white} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.purple} !important`,
            color: `${theme.palette.common.white} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.grayLayout} !important`,
            color: `${theme.palette.colors.purple} !important`,
            // opacity: .75,
        },
    },
    chipLocked: {
        backgroundColor: `${theme.palette.colors.gray} !important`,
        color: `${theme.palette.colors.purple} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.gray} !important`,
            color: `${theme.palette.colors.purple} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.gray} !important`,
            color: `${theme.palette.colors.purple} !important`,
            // opacity: .75,
        },
    },
    lockButton: {
        backgroundColor: `${theme.palette.colors.purple}`,
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: `${theme.palette.colors.purple} !important`,
            color: `${theme.palette.common.white} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: "gray",
            color: `${theme.palette.colors.purple} !important`,
        },
    },
    lockOpenButton: {
        backgroundColor: `${theme.palette.colors.gray} !important`,
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: `${theme.palette.colors.gray} !important`,
            color: `${theme.palette.common.white} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.gray} !important`,
            color: `${theme.palette.colors.grayLayout} !important`,
        },
    },
    fabProgress: {
        color: `${theme.palette.colors.green} !important`,
        position: 'absolute',
        top: -2.5,
        left: -2.3,
        zIndex: 1,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    sendInvitation: {
        backgroundColor: `${theme.palette.colors.blue} !important`,
        color: `${theme.palette.common.white} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.blue} !important`,
            color: `${theme.palette.common.white} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.blue} !important`,
            color: `${theme.palette.common.white} !important`,
            // opacity: .75,
        },
    },
    sendInvitationIcon: {
        color: `${theme.palette.common.white} !important`,
    },
    btnSaved: {
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
            opacity: .5,
        },
    },
    invitationNoSendStatus: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.primary.main} !important`,

        color: `${theme.palette.common.white} !important`
    },
    invitationSendStatus: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.waiting} !important`,
        color: `${theme.palette.common.white} !important`
    },
    supplierAcceptedStatus: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.purple} !important`,
        color: `${theme.palette.common.white} !important`
    },
    acceptedInCompanyStatus: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.green} !important`,
        color: `${theme.palette.common.white} !important`
    },
    rejecteInCompanyStatus: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.red} !important`,
        color: `${theme.palette.common.white} !important`
    },
    resendPasswordColorIcon: {
        color: `${theme.palette.colors.waiting} !important`,
    },
    userExpiredColorRed: {
        color: `${theme.palette.colors.red} !important`,
    },
    userExpiredColorYellow: {
        color: `${theme.palette.colors.waiting} !important`,
    },
    userExpiredColorGreen: {
        color: `${theme.palette.colors.green} !important`,
    }
}))