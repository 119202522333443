/** Import react section **/
import React, { useEffect, useState } from 'react'
import { hasAuthorization } from '../../../store/session/sessionActions'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import propTypes from 'prop-types'

/**Import Material UI section */
import { Button, Tooltip, Typography, Badge, ButtonGroup } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/ArrowDropDown'
import DeleteIcon from '@material-ui/icons/DeleteForever';

/**Import components sections */
import Popper from './Popper'

/**Import resources section */
import { FilterSelectStyles } from '../../../resources/styles/ServicesListStyles'

/**
 * 
 * @param {*} props 
 */
const FilterSelect = (props) => {
    const classes = FilterSelectStyles();
    let location = useLocation();
    let history = useHistory();
    const { data, nameFilter, nameFilterUrl, isActiveCleanButton, isVisibleSearch, userProfile, roles } = props;
    const [settingsEl, setSettingsEl] = useState(null);
    const [optionsSavedInUrl, setOptionsSavedInUrl] = useState([]);

    /**Hanlde  menu open popper */
    const handleMenuOpen = (event) => {
        setSettingsEl(event.currentTarget);
    };

    /**Close menu popper filter */
    const onHandleNullSetting = () => {
        setSettingsEl(null);
    };

    /**
     * Check if the url contains the filters and include them in the check 
     */
    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        if (!!queryParams[nameFilterUrl]) {
            if (typeof (queryParams[nameFilterUrl]) !== 'string')
                setOptionsSavedInUrl(queryParams[nameFilterUrl] ? queryParams[nameFilterUrl] : [])
            else
                setOptionsSavedInUrl(queryParams[nameFilterUrl] ? [queryParams[nameFilterUrl]] : [])
        }
        else
            setOptionsSavedInUrl([])


    }, [location.search, nameFilterUrl])

    /**
	 * onChange option selected in filter
	 * @param {*} optionsChecked 
	 */
    const onChangeSelectedOption = (optionsChecked) => {
        let newCodes = optionsIncludesData(optionsChecked);
        let queryParams = queryString.parse(location.search)
        if (newCodes.length <= 0) {
            setOptionsSavedInUrl([])
        }
        queryParams[nameFilterUrl] = newCodes;
        history.push({ search: queryString.stringify(queryParams, { encode: false }) })
    }

    /**
     * Check if the array already includes an option, if not add it
     * @param {*} data 
     */
    const optionsIncludesData = (data) => {
        let newCodes = [];
        data.forEach(option => {
            if (!newCodes.includes(option.key)) {
                newCodes.push(option.key);
            }
        });
        return newCodes;
    }

    /**Delete all filters */
    const deleteAllFilter = () => {
        let queryParams = queryString.parse(location.search)
        queryParams = {};
        history.push({ search: queryString.stringify(queryParams, { encode: false }) })
    }

    let isAuthorizer = hasAuthorization(userProfile, roles)

    return (
        <div className={classes.root}>
            {isAuthorizer &&
                <Badge badgeContent={optionsSavedInUrl.length} color="error" >
                    <Button
                        endIcon={<DownloadIcon />}
                        size='small'
                        onClick={handleMenuOpen}
                        className={optionsSavedInUrl.length <= 0 ? classes.buttonFilter : classes.buttonFilterSelected}
                    >
                        <Typography variant='subtitle2'>{nameFilter}</Typography>
                    </Button>
                </Badge>
            }
            {isActiveCleanButton &&
                <ButtonGroup component="span" size='small' variant="outlined" color="primary" className={classes.buttonsGroupContainer}>
                    <Tooltip component="span" title='Clean filters'  >
                        <Button
                            className={classes.buttonsGroup}
                            onClick={deleteAllFilter}
                            disabled={props.disabled}
                        >
                            <DeleteIcon className={classes.iconDelete} />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            }
            <Popper
                settingsEl={settingsEl}
                setSettingsEl={onHandleNullSetting}
                data={data}
                onChangeSelectedOption={onChangeSelectedOption}
                optionsValues={optionsSavedInUrl}
                isVisibleSearch={isVisibleSearch}
            />
        </div>
    )
}

FilterSelect.propTypes = {
	/**
	 * The data 
	 */
    data: propTypes.arrayOf(
        propTypes.shape({
            key: propTypes.oneOfType([
                propTypes.string,
                propTypes.number
            ]),
            label: propTypes.oneOfType([
                propTypes.string,
                propTypes.number
            ]),
        })
    ),
    /**
	 * Is the information of the logged in user
	 */
    userProfile: propTypes.shape({
        userName: propTypes.string.isRequired,
        name: propTypes.string.isRequired,
        email: propTypes.string.isRequired,
        avatarUrl: propTypes.string,
        enabled: propTypes.bool.isRequired,
        roles: propTypes.array,
    }),

    /**
	 * Determine the name of filter
	 */
    nameFilter: propTypes.string.isRequired,
    /**
	 * Determine the name of filter in url
	 */
    nameFilterUrl: propTypes.string.isRequired,
    /**
	 * Derterminatre if is visible search
	 */
    isVisibleSearch: propTypes.bool.isRequired,
    /**
	 * Determines the roles where the filter should be shown
	 */
    roles: propTypes.array,
}

FilterSelect.defaultProps = {
    name: "Name filter",
    nameFilterUrl: "nameFilterUrl",
    isVisibleSearch: false,
    userProfile:null,
    roles: []
}

export default FilterSelect
