/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { toSafeObject } from '../../store/helpers/StateHelper'
import { getUserManager } from '../../store/helpers/UserManager'
import { useIsMobile } from '../../hooks/useIsMobile'
import { hasAuthorization } from '../../store/session/sessionActions'

/**Import  Componets section*/
import ApplicationHeader from '../common/ApplicationHeader'
import Authorization from '../common/AuthorizationEvaluation'
import AvatarCustomSizes from '../common/AvatarCustomSizes'
/**Import resources section */
import {
	PORTAL_ADMINISTRATOR,
	SERVICE_PROVIDER_MANAGER,
	PROVIDER_REGISTRATION_VALIDATOR,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	DISPATCHER,
	VISUALIZER
} from '../../resources/RolesEnum'
import { twoColumnsStyle as useStyles } from '../../resources/styles/LayoutStyles'
import { useAuthorizedToolbarStyles } from '../../resources/styles/ApplicationHeaderStyles'
import logoHeader from '../../resources/images/logoHeader.png'
import { NOTIFICATION_SERVICE } from '../../store/helpers/AppConstants'
import { executeLogout } from '../../store/session/sessionActions'

/** Material UI components */
import {
	IconButton,
	Avatar,
	Popper,
	ClickAwayListener,
	Typography,
	Paper,
	Divider,
	Button,
	Tooltip,
	List,
	ListItem,
	ListItemText,
	withStyles,
	Breadcrumbs,
	ListItemAvatar,
	Badge,
	ListSubheader
} from '@material-ui/core/'
import SearchIcon from '@material-ui/icons/SearchTwoTone'
import MetricsIcon from '@material-ui/icons/BarChartOutlined'
import ServiceListIcon from '@material-ui/icons/Dashboard'
import SettingsIcon from '@material-ui/icons/Settings'
import ImberaUsersIcon from '@material-ui/icons/PeopleAlt'
import SalesPresentativeIcon from '@material-ui/icons/Business'
import ServiceProviderIcon from '@material-ui/icons/EmojiTransportation'
import ClientsIcon from '@material-ui/icons/LocalShipping'
import ProviderUsersIcon from '@material-ui/icons/PeopleAlt'
import AttachmentsFilesIcon from '@material-ui/icons/PictureAsPdf'
import TermsFilesIcon from '@material-ui/icons/FileCopy'
import NotifictionsIcon from '@material-ui/icons/Notifications'

import { loadNotificationsByUser, removeNotificationFromUserFromBack } from '../../store/users/UsersActions'
import { MonetizationOn } from '@material-ui/icons'

/**
 * Authenticated layout
 */
export const Layout = (props) => {
	const classes = useStyles()

	const allChildElements = React.Children.toArray(props.children)
	const findChildElementByKey = (key) => allChildElements.find((x) => x.key && x.key.includes(key))

	const applicationToolbar = findChildElementByKey('AuthenticatedHeader')
	const sideBar = findChildElementByKey('AuthenticatedSideBar')
	const content = findChildElementByKey('AuthenticatedContent')

	return (
		<div className={classes.root}>
			<div className={classes.header}>{applicationToolbar}</div>
			<div className={classes.sideBar}>{sideBar}</div>
			<div className={classes.content}>{content}</div>
		</div>
	)
}

/**
 * Authenticated content
 */
export const Content = (props) => {
	return props.children
}

/**
 * Authenticated application header
 */
export const Header = (props) => {
	const userManager = getUserManager()
	let isActiveButton = false
	const isMobile = useIsMobile(props.isMobile)
	const loggedUser = useSelector((state) => toSafeObject(state.session.get('profile')))
	const notifications = useSelector((state) => toSafeObject(state.users.get('notifications')))
	const isLoadingNotifications = useSelector((state) => state.users.get('isLoadingNotifications'))
	const toolbarClasses = useAuthorizedToolbarStyles()
	const history = useHistory()
	const dispatch = useDispatch()

	const userProfile = props.user || loggedUser

	useEffect(() => {
		dispatch(loadNotificationsByUser())
	}, [dispatch])

	const openInternalUsersView = () => history.push('/internalusers')
	const openDashboarView = () => history.push('/dashboard')

	/**Redirect to sales representatives*/
	const openSalesRepresentativesClick = () => history.push('/salesRepresentatives')

	/**Redirect to service providers*/
	const openServiceProvidersView = () => history.push('/serviceProviders')

	/**Redirect to service customers*/
	const openCustomersView = () => history.push('/customers')

	/**Redirect to users from service provider logger*/
	const openProviderUsersView = () => {
		const accountProvider = userProfile.Account
		history.push(`/serviceProviders/${accountProvider.Code}/${accountProvider.Name}/${accountProvider.Token}`)
	}

	/**Redirect to files from service provider logger*/
	const openFilesProviderView = () => {
		history.push(`/serviceProviders/register/${userProfile.Account.Token}`)
	}

	const openTermsConditionsView = () => {
		history.push(`/termsConditions`)
	}
	const openWarrantySeriesSapView = () => {
		history.push(`/warrantySeriesSap`)
	}

	/**Redirect to users from sales rep provider logger*/
	const openSalesRepUsersView = () => {
		const accountSales = userProfile.Account
		history.push(`/salesRepresentatives/${accountSales.Code}/${accountSales.Name}/${accountSales.Token}`)
	}

	/**Redirect to users from cutomer user logger*/
	const openCustomerUsersView = () => {
		const accountCustomer = userProfile.Account
		history.push(`/customers/${accountCustomer.Code}/${accountCustomer.Name}/${accountCustomer.Token}`)
	}

	/** Redirect home */
	const redirectToHome = () => history.push(`/`)

	/**
	 * Execute logout
	 */
	const onLogout = () => {
		dispatch(executeLogout())
		userManager.signoutRedirect()
	}

	/**
	 * Redirect profile user logger
	 * @param {*} token
	 */
	const onEditProfile = (token) => {
		history.push(`/profile/${token}`)
	}

	/**
	 * Redirect payment rerquest pendient
	 * @param {*} requestToken
	 */
	const onNotificationRequestView = (notification) => {
		if (notification.RequestType === NOTIFICATION_SERVICE) {
			history.push(`/services/${notification.RequestToken}/-`)
			// Remove notification user
			dispatch(removeNotificationFromUserFromBack(notification.Token))
		} else {
			history.push(`/paymentrequests/${notification.RequestToken}`)
		}
	}

	return (
		<ApplicationHeader
			leftToolbar={
				<div className={toolbarClasses.leftToobarContainer}>
					{userProfile.Account.RequieredTermsAccepted ? (
						<img src={logoHeader} className={toolbarClasses.logoHeaderWithCursor} alt='manager-logo' />
					) : (
						<Tooltip title='Inicio' placement='right'>
							<img src={logoHeader} className={toolbarClasses.logoHeader} alt='manager-logo' onClick={redirectToHome} />
						</Tooltip>
					)}
					{isActiveButton && (
						<div>
							<Tooltip title='Search for'>
								<IconButton edge='start' size='medium' color='secondary'>
									<SearchIcon />
								</IconButton>
							</Tooltip>
						</div>
					)}
					<div>
						{isActiveButton && (
							<Tooltip title='Open service dashboard'>
								<IconButton edge='start' color='secondary' onClick={openDashboarView}>
									<ServiceListIcon />
								</IconButton>
							</Tooltip>
						)}
						{isActiveButton && (
							<Tooltip title='View service metrics'>
								<IconButton color='inherit'>
									<MetricsIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>
					<div>{props.breadcrumbs}</div>
				</div>
			}
			rightToolbar={
				<div className={toolbarClasses.rightToobarContainer}>
					{!isMobile && (
						<Authorization
							profile={userProfile}
							allowedRoles={[
								PORTAL_ADMINISTRATOR,
								PROVIDER_REGISTRATION_VALIDATOR,
								DISPATCHER,
								SALES_REPRESENTATIVE_MANAGER,
								SALES_MANAGER,
								CUSTOMER_MANAGER,
								CUSTOMER_TECHNICIAN
							]}
						>
							<NotificationsButton
								notifications={notifications.filter((x) => x.RequestType !== 'PaymentRequest')}
								isLoadingNotifications={isLoadingNotifications}
								onNotificationRequestView={onNotificationRequestView}
								isPayment={false}
							/>
						</Authorization>
					)}

					{!isMobile && (
						<Authorization profile={userProfile} allowedRoles={[PROVIDER_REGISTRATION_VALIDATOR]}>
							<NotificationsButton
								notifications={notifications.filter((x) => x.RequestType === 'PaymentRequest')}
								isLoadingNotifications={isLoadingNotifications}
								onNotificationRequestView={onNotificationRequestView}
								isPayment={true}
							/>
						</Authorization>
					)}

					{!userProfile.Account.RequieredTermsAccepted && !isMobile && notifications && notifications.length !== 0 && (
						<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_MANAGER]}>
							<NotificationsButton
								notifications={notifications}
								isLoadingNotifications={isLoadingNotifications}
								onNotificationRequestView={onNotificationRequestView}
								isPayment={false}
							/>
						</Authorization>
					)}

					{!isMobile && (
						<Authorization profile={userProfile} allowedRoles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR, VISUALIZER]}>
							<SettingsButton
								user={userProfile}
								onInternalUsersClick={openInternalUsersView}
								openSalesRepresentativesClick={openSalesRepresentativesClick}
								openServiceProvidersView={openServiceProvidersView}
								openCustomersView={openCustomersView}
								openTermsConditionsView={openTermsConditionsView}
								openWarrantySeriesSapView={openWarrantySeriesSapView}
							/>
						</Authorization>
					)}

					{!userProfile.Account.RequieredTermsAccepted && !isMobile && (
						<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_MANAGER]}>
							<SettingsProviderButton
								user={userProfile}
								openProviderUsersViewClick={openProviderUsersView}
								openFilesProviderViewClick={openFilesProviderView}
								openTermsConditionsView={openTermsConditionsView}
							/>
						</Authorization>
					)}

					{!isMobile && (
						<Authorization profile={userProfile} allowedRoles={[SALES_REPRESENTATIVE_MANAGER]}>
							<SettingsSalesRepButton user={userProfile} openSalesRepUsersViewClick={openSalesRepUsersView} />
						</Authorization>
					)}

					{!isMobile && (
						<Authorization profile={userProfile} allowedRoles={[CUSTOMER_MANAGER]}>
							<SettingsCustomerButton user={userProfile} openCustomerUsersViewClick={openCustomerUsersView} />
						</Authorization>
					)}

					{/** avatar and logount */}
					<UserAvatarButton user={userProfile} onCloseSession={onLogout} onEditProfile={onEditProfile} />
				</div>
			}
		/>
	)
}
Header.propTypes = {
	/**
	 * The logged user information to show in the user's profile
	 */
	user: propTypes.shape({
		name: propTypes.string.isRequired,
		email: propTypes.string.isRequired,
		roles: propTypes.array.isRequired
	}),

	/**
	 * The additional right toolbar options
	 */
	breadcrumbs: propTypes.element
}
Header.defaultProps = {
	breadcrumbs: null
}

/**
 * A styled breadcrumb for the authenticated header
 */
export const HeaderBreadCrum = withStyles((theme) => ({
	root: {
		marginTop: '12px'
	},
	separator: {
		color: theme.palette.common.white
	}
}))(Breadcrumbs)

const UserAvatarButton = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const classes = useAuthorizedToolbarStyles()
	const { user, onCloseSession, onEditProfile } = props
	return (
		<div>
			<IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
				<AvatarCustomSizes name={user.name} avatarUrl={user.avatarUrl} avatarStyle={classes.profileIconButton} isAvatarFromIdentity={true} />
			</IconButton>
			<Popper anchorEl={anchorEl} open={anchorEl !== null}>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2}>
						<div className={classes.profilerForm}>
							<AvatarCustomSizes
								name={user.name}
								avatarUrl={user.avatarUrl}
								avatarStyle={classes.profileUserAvatar}
								isAvatarFromIdentity={true}
							/>
							<div className={classes.profilerUserInformation}>
								<Typography variant='subtitle2'>{user.name}</Typography>
								<Typography variant='caption'>{user.email}</Typography>
							</div>
						</div>
						<Divider />
						<div className={classes.profileFormButtons}>
							<Button size='small' color='primary' onClick={onCloseSession}>
								Close session
							</Button>
							<Button size='small' color='primary' onClick={() => onEditProfile(user ? user.Token : '')}>
								Edit profile
							</Button>
						</div>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}

const SettingsButton = (props) => {
	const classes = useAuthorizedToolbarStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	return (
		<div>
			<IconButton edge='start' size='medium' color='secondary' onClick={(event) => setAnchorEl(event.currentTarget)}>
				<Avatar>
					<SettingsIcon color='secondary' />
				</Avatar>
			</IconButton>
			<Popper anchorEl={anchorEl} open={anchorEl !== null}>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2}>
						<List dense>
							{hasAuthorization(props.user, [PORTAL_ADMINISTRATOR, VISUALIZER]) && (
								<>
									{hasAuthorization(props.user, [PORTAL_ADMINISTRATOR]) && (
										<ListItem button onClick={props.onInternalUsersClick}>
											<ListItemAvatar>
												<Avatar className={classes.avatarListIcon}>
													<ImberaUsersIcon className={classes.iconAvatar} />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary='Imbera users' />
										</ListItem>
									)}

									<ListItem button onClick={props.openSalesRepresentativesClick}>
										<ListItemAvatar>
											<Avatar className={classes.avatarListIcon}>
												<SalesPresentativeIcon className={classes.iconAvatar} />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary='Sales representatives' />
									</ListItem>
									<ListItem button onClick={props.openServiceProvidersView}>
										<ListItemAvatar>
											<Avatar className={classes.avatarListIcon}>
												<ServiceProviderIcon className={classes.iconAvatar} />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary='Service Providers' />
									</ListItem>
									<ListItem button onClick={props.openCustomersView}>
										<ListItemAvatar>
											<Avatar className={classes.avatarListIcon}>
												<ClientsIcon className={classes.iconAvatar} />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary='Customers' />
									</ListItem>
								</>
							)}
							<ListItem button onClick={props.openWarrantySeriesSapView}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<TermsFilesIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='SAP Filters Warranty Series' />
							</ListItem>

							<ListItem button onClick={props.openTermsConditionsView}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<TermsFilesIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Terms and Conditions' />
							</ListItem>
						</List>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}
SettingsButton.propTypes = {
	onInternalUsersClick: propTypes.func.isRequired,
	openRetailsView: propTypes.func.isRequired,
	openServiceProvidersView: propTypes.func.isRequired
}
SettingsButton.defaultProps = {
	onInternalUsersClick: () => {
		console.warn('Callback [onInternalUsersClick] not defined')
	},
	openRetailsView: () => {
		console.warn('Callback [openRetailsView] not defined')
	},
	openServiceProvidersView: () => {
		console.warn('Callback [openServiceProvidersView] not defined')
	}
}

/**
 * Setting options service provider manager
 * @param {*} props
 */
const SettingsProviderButton = (props) => {
	const classes = useAuthorizedToolbarStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	return (
		<div>
			<IconButton edge='start' size='medium' color='secondary' onClick={(event) => setAnchorEl(event.currentTarget)}>
				<Avatar>
					<SettingsIcon color='secondary' />
				</Avatar>
			</IconButton>
			<Popper anchorEl={anchorEl} open={anchorEl !== null}>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2}>
						<List dense>
							<ListItem button onClick={props.openProviderUsersViewClick}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<ProviderUsersIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Users' />
							</ListItem>
							<ListItem button onClick={props.openFilesProviderViewClick}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<AttachmentsFilesIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Attachments' />
							</ListItem>
							<ListItem button onClick={props.openTermsConditionsView}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<TermsFilesIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Terms and Conditions' />
							</ListItem>
						</List>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}
SettingsProviderButton.propTypes = {
	openProviderUsersViewClick: propTypes.func.isRequired,
	openFilesProviderViewClick: propTypes.func.isRequired
}
SettingsProviderButton.defaultProps = {
	openProviderUsersViewClick: () => {
		console.warn('Callback [openProviderUsersViewClick] not defined')
	},
	openFilesProviderViewClick: () => {
		console.warn('Callback [openFilesProviderViewClick] not defined')
	}
}

/**
 * Setting options sales representatives manager
 * @param {*} props
 */
const SettingsSalesRepButton = (props) => {
	const classes = useAuthorizedToolbarStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	return (
		<div>
			<IconButton edge='start' size='medium' color='secondary' onClick={(event) => setAnchorEl(event.currentTarget)}>
				<Avatar>
					<SettingsIcon color='secondary' />
				</Avatar>
			</IconButton>
			<Popper anchorEl={anchorEl} open={anchorEl !== null}>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2}>
						<List dense>
							<ListItem button onClick={props.openSalesRepUsersViewClick}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<ProviderUsersIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Users' />
							</ListItem>
						</List>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}
SettingsSalesRepButton.propTypes = {
	openSalesRepUsersViewClick: propTypes.func.isRequired
}
SettingsSalesRepButton.defaultProps = {
	openSalesRepUsersViewClick: () => {
		console.warn('Callback [openSalesRepUsersViewClick] not defined')
	}
}

/**
 * Setting options customer manager
 * @param {*} props
 */
const SettingsCustomerButton = (props) => {
	const classes = useAuthorizedToolbarStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	return (
		<div>
			<IconButton edge='start' size='medium' color='secondary' onClick={(event) => setAnchorEl(event.currentTarget)}>
				<Avatar>
					<SettingsIcon color='secondary' />
				</Avatar>
			</IconButton>
			<Popper anchorEl={anchorEl} open={anchorEl !== null}>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2}>
						<List dense>
							<ListItem button onClick={props.openCustomerUsersViewClick}>
								<ListItemAvatar>
									<Avatar className={classes.avatarListIcon}>
										<ProviderUsersIcon className={classes.iconAvatar} />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Users' />
							</ListItem>
						</List>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}
SettingsCustomerButton.propTypes = {
	openCustomerUsersViewClick: propTypes.func.isRequired
}
SettingsCustomerButton.defaultProps = {
	openCustomerUsersViewClick: () => {
		console.warn('Callback [openCustomerUsersViewClick] not defined')
	}
}

const NotificationsButton = (props) => {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState(null)
	const { notifications, onNotificationRequestView, isPayment } = props

	/**
	 * On redirect view payment request
	 * @param {*} requestToken
	 */
	const onNotificationView = (pathView, requestToken) => {
		onNotificationRequestView(pathView, requestToken)
		setAnchorEl(null)
	}

	return (
		<div>
			<Tooltip component='span' title={isPayment ? 'Payment Notifications' : 'Notifications'}>
				<IconButton edge='start' size='medium' onClick={(event) => setAnchorEl(event.currentTarget)}>
					<Badge
						badgeContent={notifications.length}
						color='error'
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
					>
						{!isPayment ? <NotifictionsIcon style={{ color: '#FFFF' }} /> : <MonetizationOn style={{ color: '#FCCB56' }} />}
					</Badge>
				</IconButton>
			</Tooltip>

			<Popper className={classes.notificationPaper} anchorEl={anchorEl} open={anchorEl !== null}>
				<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
					<Paper elevation={2}>
						<List
							dense
							className={classes.contentNotifications}
							subheader={
								<ListSubheader component='div' id='nested-list-subheader' className={classes.rootTitle}>
									<strong>{'NOTIFICATIONS'}</strong>
								</ListSubheader>
							}
						>
							{notifications.map((notification) => {
								return (
									<ListItem
										key={notification.Token}
										divider
										button
										className={classes.outlinedRoot}
										classes={{
											root: classes.outlinedRoot,
											selected: classes.outlinedRoot
										}}
										onClick={() => onNotificationView(notification)}
									>
										{notification.RequestType === 'PaymentRequest' && (
											<ListItemText
												primary={
													<Typography variant='caption'>{`PAYMENT REQUEST: ${notification.Folio} | ${notification.Description}`}</Typography>
												}
												secondary={`Send: ${moment(notification.CreationDate).format('MM/DD/YYYY, h:mm:ss a')}`}
											/>
										)}
										{notification.RequestType === 'Service' && (
											<ListItemText
												primary={
													<Typography variant='caption'>{`SERVICE WITH TICKET: ${notification.Folio} | ${notification.Description}`}</Typography>
												}
												secondary={`Send: ${moment(notification.CreationDate).format('MM/DD/YYYY, h:mm:ss a')}`}
											/>
										)}
									</ListItem>
								)
							})}
							{notifications.length === 0 && (
								<ListItem key={'nopending'} divider>
									<ListItemText secondary={'No pending requests'} />
								</ListItem>
							)}
						</List>
					</Paper>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}
NotificationsButton.propTypes = {
	onNotificationRequestView: propTypes.func.isRequired
}
NotificationsButton.defaultProps = {
	onNotificationRequestView: () => {
		console.warn('Callback [openRequestView] not defined')
	}
}
