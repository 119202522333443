/**Import react section */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import propTypes from 'prop-types'
import Select from 'react-select'

/**import resources styles */
import iconFailureDoor from '../../../resources/images/failureWarranty/door.svg'
import { panelLateralServicesStyles } from '../../../resources/styles/PanelLateralServicesStyles'
import {
	ServicesStatusTypes,
	DELETED,
	CHANGE_INITIATED_DATE,
	ASSIGNED_SERVICE_PROVIDER_TECH,
	CHANGE_REPRESENTATIVE_SALES,
	REASSIGNED_SERVICE_PRIVIDER
} from '../../../store/helpers/AppConstants'
import { getPreferentServiceProviders } from '../../../store/serviceproviders/serviceProvidersActions'
/** Material UI import section */
import { Fab, Typography, DialogContent, Chip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'

/**Import components section */
import AssignementService from './StatusPanel/AssignementService'
import CancelledService from './StatusPanel/CancelledService'
import SuspendedService from './StatusPanel/SuspendedService'
import CompletedService from './StatusPanel/CompletedService'
import ReplacedService from './StatusPanel/ReplacedService'
import DeleteService from './StatusPanel/DeleteService'
import ChangeInitialDate from './StatusPanel/ChangeInitialDate'
import AssgmentTechServiceProvider from './StatusPanel/AssgmentTechServiceProvider'
import ChangeRepSales from './StatusPanel/ChangeRepSales'
import ReassignedProvider from './StatusPanel/ReassignedProvider'

const ChangeStatusPanel = (props) => {
	const {
		onCloseButtonClick,
		onSaveButtonClick,
		onSetPasswordDialogConfiguration
	} = props
	/**Asigned props cmponent */
	const {
		onAssignementService,
		isSavingService,
		onAssignementTechServiceProvider,
		onChangeRepresentanteSales,
		onReasisngedServiceProviderr
	} = props
	let match = useRouteMatch()
	const serie = match.params.serie
	const [serviceModel, setServiceModel] = useState(null)
	const [changeStatusModel, setStatusModel] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		setServiceModel(props.service)
	}, [props.service])

	/** Invoke when select property change*/
	const onSelectChange = (name, option) => {
		setStatusModel({
			...changeStatusModel,
			[name]: option
		})
	}

	/** Invoke preferent providers for assignment */
	useEffect(() => {
		dispatch(getPreferentServiceProviders(
			props.service.Address.state,
			props.service.Address.postal_code
		))
	}, [dispatch, props.service])

	const backgroundColorSelect = () => {
		let status = changeStatusModel ? changeStatusModel.status.value : null
		switch (Number(status)) {
			case ServicesStatusTypes.ASSIGNED:
			case REASSIGNED_SERVICE_PRIVIDER:
				return changeStatusModel.status.color
			case ServicesStatusTypes.REPLACED:
				return changeStatusModel.status.color
			case ServicesStatusTypes.WAITING:
				return changeStatusModel.status.color
			case ServicesStatusTypes.CANCELLED:
				return changeStatusModel.status.color
			case ServicesStatusTypes.SUSPENDED:
				return changeStatusModel.status.color
			case ServicesStatusTypes.COMPLETED:
				return changeStatusModel.status.color
			case DELETED:
				return changeStatusModel.status.color
			case CHANGE_INITIATED_DATE:
				return changeStatusModel.status.color
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return changeStatusModel.status.color
			case CHANGE_REPRESENTATIVE_SALES:
				return changeStatusModel.status.color
			default:
				return null
		}
	}

	const GetComponentByStatus = (status) => {
		switch (Number(status)) {
			case ServicesStatusTypes.ASSIGNED:
				return (
					<AssignementService
						dispatcher={
							serviceModel ? serviceModel.Dispatcher : null
						}
						techAssigned={
							serviceModel ? serviceModel.TechAssigned : null
						}
						serviceProvider={
							serviceModel ? serviceModel.ServiceProvider : null
						}
						onAssignementService={onAssignementService}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={true}
					/>
				)
			case ServicesStatusTypes.REPLACED:
				return (
					<ReplacedService
						serie={serie}
						status={
							changeStatusModel
								? changeStatusModel.status.value
								: null
						}
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
					/>
				)
			case ServicesStatusTypes.CANCELLED:
				return (
					<CancelledService
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
						IsClosed={serviceModel ? serviceModel.IsClosed : true}
						status={
							changeStatusModel
								? changeStatusModel.status.value
								: null
						}
					/>
				)
			case ServicesStatusTypes.SUSPENDED:
				return (
					<SuspendedService
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
						status={
							changeStatusModel
								? changeStatusModel.status.value
								: null
						}
					/>
				)
			case ServicesStatusTypes.COMPLETED:
				return (
					<CompletedService
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
						status={
							changeStatusModel
								? changeStatusModel.status.value
								: null
						}
					/>
				)
			case DELETED:
				return (
					<DeleteService
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
						status={
							changeStatusModel
								? changeStatusModel.status.value
								: null
						}
					/>
				)
			case CHANGE_INITIATED_DATE: // in equipment
				return (
					<ChangeInitialDate
						onSetPasswordDialogConfiguration={
							onSetPasswordDialogConfiguration
						}
						status={
							changeStatusModel
								? changeStatusModel.status.value
								: null
						}
					/>
				)
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return (
					<AssgmentTechServiceProvider
						serviceProviderTechAssigned={
							serviceModel
								? serviceModel.ServiceProviderTechAssigned
								: null
						}
						onAssignementTechServiceProvider={
							onAssignementTechServiceProvider
						}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={true}
					/>
				)
			case CHANGE_REPRESENTATIVE_SALES:
				return (
					<ChangeRepSales
						salesRepresentative={
							serviceModel
								? serviceModel.SalesRepresentative
								: null
						}
						onChangeRepresentanteSales={onChangeRepresentanteSales}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={true}
					/>
				)
			case REASSIGNED_SERVICE_PRIVIDER: {
				return (
					<ReassignedProvider
						serviceProvider={
							serviceModel ? serviceModel.ServiceProvider : null
						}
						onReasisngedServiceProviderr={
							onReasisngedServiceProviderr
						}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={true}
					/>
				)
			}
			default:
				return null
		}
	}

	const backGroundColorDefaultByStatus = () => {
		switch (Number(serviceModel ? serviceModel.Status : null)) {
			case ServicesStatusTypes.ACTIVE:
				return '#5357DC'
			case ServicesStatusTypes.ASSIGNED:
				return '#30B1FC'
			case ServicesStatusTypes.REPLACED:
				return '#2BF6FE'
			case ServicesStatusTypes.CANCELLED:
				return '#D92550'
			case ServicesStatusTypes.SUSPENDED:
				return '#FF8B00'
			case ServicesStatusTypes.WAITING:
				return '#FDDD0E'
			case ServicesStatusTypes.COMPLETED:
				return '#3AC47D'
			case DELETED:
				return '#000000'
			case CHANGE_INITIATED_DATE:
				return '#83588A'
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return '#64C4FC'
			default:
				return '#5357DC'
		}
	}

	const getPlaceholder = () => {
		switch (Number(serviceModel ? serviceModel.Status : null)) {
			case ServicesStatusTypes.ACTIVE:
				return 'SERVICE ACTIVE'
			case ServicesStatusTypes.ASSIGNED:
				return 'SERVICE ASSIGNED'
			case ServicesStatusTypes.REPLACED:
				return 'SERVICE REPLACED'
			case ServicesStatusTypes.WAITING:
				return 'SERVICE WAITING'
			case ServicesStatusTypes.CANCELLED:
				return 'SERVICE CANCELLED'
			case ServicesStatusTypes.SUSPENDED:
				return 'SERVICE SUSPENDED'
			case ServicesStatusTypes.COMPLETED:
				return 'SERVICE COMPLETED'
			case DELETED:
				return 'SERVICE DELETED'
			case CHANGE_INITIATED_DATE:
				return 'CHANGE INITIATED DATE'
			case ASSIGNED_SERVICE_PROVIDER_TECH:
				return 'ASSIGN TECHNICAL PROVIDER'
			default:
				return null
		}
	}

	const colourSelectorStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: changeStatusModel
				? backgroundColorSelect()
				: backGroundColorDefaultByStatus(),
			color: 'white'
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: isDisabled
					? null
					: isSelected
						? `${data.color}80`
						: isFocused
							? `${data.color}80`
							: null,
				cursor: isDisabled ? 'not-allowed' : 'default',
				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled && `${data.color}80`
				}
			}
		},
		input: (styles) => ({ ...styles, ...colorSelected() }),
		placeholder: (styles) => ({ ...styles, ...colorSelected() }),
		singleValue: (styles, { data }) => ({
			...styles,
			...colorSelected(data.color)
		}),
		menu: (styles) => ({ ...styles, zIndex: 10, maxHeight: 300 }),
		menuPortal: (styles) => ({ ...styles, zIndex: 9999 })
	}

	return (
		<>
			<PanelHeader
				onCloseButtonClick={onCloseButtonClick}
				isModeSaved={true}
				onSaveButtonClick={onSaveButtonClick}
				title={'CHANGE STATUS'}
				ticket={serviceModel ? serviceModel.Ticket : ''}
				serie={serie}
			/>
			<DialogContent>
				<Select
					id={'is-status'}
					placeholder={getPlaceholder()}
					name='status'
					autoFocus
					options={
						serviceModel ? serviceModel.AvailableOperations : null
					}
					styles={colourSelectorStyles}
					formatOptionLabel={formatOptionLabel}
					onChange={(value, action) => {
						onSelectChange(action.name, value)
					}}
				/>
				<br />
				<br />
				{GetComponentByStatus(
					changeStatusModel ? changeStatusModel.status.value : null
				)}
			</DialogContent>
		</>
	)
}

const colorSelected = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',
	color: 'white'
})

const formatOptionLabel = ({ value, label, color }) => (
	<div>
		<Chip
			id={value}
			size='small'
			label={label}
			clickable
			style={{ color: 'white', background: color }}
		/>
	</div>
)

ChangeStatusPanel.props = {
	/**open and close lateral panel config*/
	onCloseButtonClick: propTypes.func,
	/**Determinate if is saving data in the back end */
	isSavingService: propTypes.bool.isRequired,
	/**Asigned service to dipatcher, service provider and tech assigned in the service */
	onAssignementService: propTypes.func,
	/**open modal for confirm the canceled service operation */
	onSetPasswordDialogConfiguration: propTypes.func,
	/**Asigned on tech from service provider, in the service */
	onAssignementTechServiceProvider: propTypes.func
}
ChangeStatusPanel.defaultProps = {
	isSavingService: false,
	onCloseButtonClick: () =>
		console.warn('Callback [onCloseButtonClick] no defined'),
	onAssignementService: () =>
		console.warn('Callback [onAssignementService] no defined'),
	onSetPasswordDialogConfiguration: () =>
		console.warn('Callback [onSetPasswordDialogConfiguration] no defined'),
	onAssignementTechServiceProvider: () =>
		console.warn('Callback [onAssignementTechServiceProvider] no defined')
}

const PanelHeader = (props) => {
	const { title } = props
	const {
		onCloseButtonClick,
		onSaveButtonClick,
		onEditButtonClick,
		isModeSaved,
		canBeSaved,
		isSaving,
		ticket,
		serie
	} = props
	const classes = panelLateralServicesStyles()

	return (
		<div className={classes.panelHeader}>
			<div className={classes.containerHeader}>
				<div className={classes.containerIcon}>
					<Fab
						size='medium'
						className={classes.faultWarrantyIconSelected}
						disabled={true}
					>
						<img
							src={iconFailureDoor}
							alt='warranty-icon'
							className={classes.faultWarrantyIcon}
						/>
					</Fab>
				</div>
				<div className={classes.containerText}>
					<Typography
						gutterBottom
						variant='subtitle1'
						className={classes.title}
					>
						{title || ''}
					</Typography>
					<Typography
						gutterBottom
						variant='subtitle2'
						className={classes.subTitle}
					>{`TICKET: ${ticket || ''}`}</Typography>
					{(serie !== '-' || !serie === 'null') && (
						<Typography
							gutterBottom
							variant='subtitle2'
							className={classes.subTitle}
						>{`SERIE: ${serie || ''}`}</Typography>
					)}
				</div>
			</div>
			<div className={classes.grow} />
			{(!!onEditButtonClick || !!onSaveButtonClick) && (
				<Fab
					disabled={(isModeSaved ? !canBeSaved : false) || isSaving}
					className={
						isModeSaved ? classes.buttonSaved : classes.buttonEdit
					}
					onClick={
						isModeSaved ? onSaveButtonClick : onEditButtonClick
					}
				>
					{isModeSaved ? <SaveIcon /> : <EditIcon />}
				</Fab>
			)}
			{!!onCloseButtonClick && (
				<Fab
					className={classes.buttonClose}
					onClick={onCloseButtonClick}
				>
					<DeleteIcon />
				</Fab>
			)}
		</div>
	)
}
PanelHeader.propTypes = {
	/**
	 * Determine if is saving
	 */
	isSaving: propTypes.bool,
	/**
	 * Determine if is mode saved
	 */
	isModeSaved: propTypes.bool,
	/**
	 * On change button saved
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * On change button edit
	 */
	onEditButtonClick: propTypes.func
}

export default ChangeStatusPanel
