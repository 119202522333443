/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
/** Material UI import section */
import {
    Fab,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
/**import resources styles */
import { panelLateralIconStyles } from '../../../resources/styles/PanelLateralServicesStyles';

const PanelHeaderIcon = (props) => {
    const { backgroundColorHeader,
        backgroundIcon, title, onCloseButtonClick,
        onSaveButtonClick, icon, canBeSaved,
        isSaving, isIconSvgOrPng } = props;
    const classes = panelLateralIconStyles({ backgroundColorHeader, backgroundIcon });
    return (
        <div className={classes.panelHeader}>
            <div className={classes.containerHeader}>
                <div className={classes.containerIcon}>
                    <Fab size="medium" className={classes.faultWarrantyIconSelected} disabled={true}>
                        {isIconSvgOrPng ? <img src={icon}
                            alt='warranty-icon'
                            className={classes.faultWarrantyIcon}
                        /> : icon}
                    </Fab>
                </div>
                <div className={classes.containerText}>
                    <Typography gutterBottom variant="subtitle1" className={classes.title}>{title || ''}</Typography>
                </div>

            </div>
            <div className={classes.grow} />
            {
                onSaveButtonClick &&
                <Fab
                    disabled={(!canBeSaved) || isSaving}
                    className={classes.buttonSaved}
                    onClick={onSaveButtonClick}
                >
                    <SaveIcon />
                </Fab>
            }
            {
                onCloseButtonClick &&
                <Fab
                    className={classes.buttonClose}
                    onClick={onCloseButtonClick}
                >
                    <DeleteIcon />
                </Fab>
            }
        </div>
    )
}

PanelHeaderIcon.propTypes = {
	/**
	 * Determine if is saving
	 */
    isSaving: propTypes.bool,
    /**
	 * On change button saved
	 */
    onSaveButtonClick: propTypes.func,
    /**
     * Background color header
     */
    backgroundColorHeader: propTypes.string,
    /**
     * title
     */
    title: propTypes.string,
}

PanelHeaderIcon.defaultProps = {
    isSaving: false,
    isModeSaved: false,
    backgroundColorHeader: '',
    title: '',
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
}


export default PanelHeaderIcon