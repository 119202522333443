/**Import react section */
import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import { PaymentRequestStatus, DELETED_PAYMENT} from '../../store/helpers/AppConstants'
/**Import components section */
import ChangeStatusPanel from './components/ChangeStatusPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel';
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'
import Toaster from '../common/Toaster'

/**Import actions sections */
import {removeNotificationUser} from '../../store/users/UsersActions'
import {sendToValidate, acceptedOrRejected, deletePaymentRequest, REMOVE_SERVICE_IN_PAYMENT, removeServiceInPayment} from '../../store/paymentrequests/paymentRequestActions'
import { hasAuthorization } from '../../store/session/sessionActions'
import { SERVICE_PROVIDER_MANAGER, PORTAL_ADMINISTRATOR,
    PROVIDER_REGISTRATION_VALIDATOR } from '../../resources/RolesEnum'
import { getUsersByRole } from '../../store/helpers/UserHelper'
import { toSafeObject } from '../../store/helpers/StateHelper'

const Edit = (props) => {
    /**Custom hooks sections */
    const dispatch = useDispatch()
    const history = useHistory()
	let match = useRouteMatch();
    const { lateralPanelStatusConfig, openLateralPanelChangeStatus, isSavingPayment, userProfile, openCloseDialogPassword, dialogPasswordConfig } = props;
    /**State local for this component*/
    const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, statusModel: null })
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    const paymentRequest = useSelector((state) => toSafeObject(state.paymentRequests.get("paymentRequest")))

    const [payment, setPayment] = useState(null);
    let isUserExternal = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER])
    let isUserInternal = hasAuthorization(userProfile, [PORTAL_ADMINISTRATOR])
	let emailsRoleValidator = getUsersByRole(PROVIDER_REGISTRATION_VALIDATOR, internalUsers)

    useEffect(() => {
        setPayment(lateralPanelStatusConfig.payment)
    }, [lateralPanelStatusConfig.payment])

    /**Open/close dialog confirm dialog user*/
    const onSetPasswordDialogConfiguration = (statusModel) => {
        setPasswordDialogConfiguration({ opened: !passwordDialogConfiguration.opened, statusModel: statusModel });
    };

    /**
	 * Send to validate request by provider
	 */
    const onSendToValidate = () => {
        return dispatch(sendToValidate(payment.Token, passwordDialogConfiguration.statusModel, emailsRoleValidator))
            .then((payment) => {
                dispatch(removeNotificationUser(payment.Token))
                onSetPasswordDialogConfiguration()
                setPayment(payment)
                setToasterOptions({ open: true, message: "Payment request send success", variant: 'success' })
                openLateralPanelChangeStatus();
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to send payment request', variant: 'warning' })
            });
    };

    /**
     * Accepted or rejected request in plant 
     */
    const onAcceptedOrRejectedRequest = () => {
        return dispatch(acceptedOrRejected(payment.Token, passwordDialogConfiguration.statusModel))
            .then((payment) => {
                dispatch(removeNotificationUser(payment.Token))
                onSetPasswordDialogConfiguration()
                setPayment(payment)
                setToasterOptions({ open: true, message: "Payment request validate success", variant: 'success' })
                openLateralPanelChangeStatus();
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to validate payment request', variant: 'warning' })
            });
    };

    /**
     * Delete payment request from provider
     */
    const onDeletePaymentRequest = () => {
        return dispatch(deletePaymentRequest(payment.Token))
            .then((_) => {
                onSetPasswordDialogConfiguration()
                setToasterOptions({ open: true, message: "Spare part requests deleted success", variant: 'success' })
                history.push('/paymentrequests')
                openLateralPanelChangeStatus();
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to deleted spart part requests', variant: 'warning' })
                console.error("Failed to removed spare part requests", error);
            });
    };

    	/**
	 * Remove service in payment
	 * @param {*} serviceToken 
	 */
	const removeServiceToPayment = (passwordModel) =>{
		return dispatch(removeServiceInPayment(match.params.token, dialogPasswordConfig ? dialogPasswordConfig.item.Token: "", passwordModel.comments))
            .then((_)=>{
                openCloseDialogPassword()
            })
			.catch((error) => {
				setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to remove service in payment', variant: 'error' })
			});
	}


    const GetFunctionToInvoke = (status) => {
        switch (Number(status)) {
            case PaymentRequestStatus.IN_PROCESS:
                return onSendToValidate
            case PaymentRequestStatus.REJECTED:
            case PaymentRequestStatus.ACCEPTED:
                return onAcceptedOrRejectedRequest
            case DELETED_PAYMENT:
                return onDeletePaymentRequest
            default:
                return null
        }
    }

    const GetStatusDescription = (status) => {
        switch (Number(status)) {
            case PaymentRequestStatus.DRAFT:
                return "This action will change the status of the payment request to Draft to Submitted"
            case PaymentRequestStatus.IN_PROCESS:
                return "This operation send to validate the payment request to plant"
            case PaymentRequestStatus.ACCEPTED:
                return "This operation accepts the payment request generated by the provider"
            case PaymentRequestStatus.REJECTED:
                return "This operation rejects the request for payment and shipping to the supplier for modification"
            case DELETED_PAYMENT:
                return "This action will delete the payment request, the information cannot be restored."
            default:
                return null
        }
    }

    const GetFunctionToInvokePasswordDialog = () => {
        switch (dialogPasswordConfig ? dialogPasswordConfig.identifier:"") {
            case REMOVE_SERVICE_IN_PAYMENT:
                return removeServiceToPayment
            default:
                return null
        }
    }

    const GetMessagePasswordDialog= () => {
        switch (dialogPasswordConfig ? dialogPasswordConfig.identifier:"") {
            case REMOVE_SERVICE_IN_PAYMENT:
                return "This action deletes the service from the payment request"
            default:
                return null
        }
    }

    const GetIsRequiredComments= () => {
        switch (dialogPasswordConfig ? dialogPasswordConfig.identifier:"") {
            case REMOVE_SERVICE_IN_PAYMENT:
                return (paymentRequest && paymentRequest.Status !== PaymentRequestStatus.DRAFT) ? true : false
            default:
                return false
        }
    }

    return (
        <>
            {lateralPanelStatusConfig.opened &&
                <LateralPanel
                    onClose={openLateralPanelChangeStatus}
                >
                    <ChangeStatusPanel
                        onCloseButtonClick={openLateralPanelChangeStatus}
                        payment={payment}
                        onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                        isUserExternal={isUserExternal}
                        isUserInternal={isUserInternal}
                    />
                </LateralPanel>
            }

            {
                passwordDialogConfiguration.opened &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={GetFunctionToInvoke(passwordDialogConfiguration ? (passwordDialogConfiguration.statusModel && passwordDialogConfiguration.statusModel.status) : null)}
                    isSaving={isValidatingPassword || isSavingPayment}
                    onClose={onSetPasswordDialogConfiguration}
                    message1={`${GetStatusDescription(passwordDialogConfiguration.statusModel && passwordDialogConfiguration.statusModel.status)} . Are you sure to proceed  ${userProfile ? userProfile.name : ''}?`}
                    message2="Enter the password to confirm the operation"
                />
            }
              {
                dialogPasswordConfig.opened &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={dialogPasswordConfig.opened}
                    onClickButtonConfirm={GetFunctionToInvokePasswordDialog()}
                    isSaving={isValidatingPassword || isSavingPayment}
                    onClose={openCloseDialogPassword}
                    message1={`${GetMessagePasswordDialog()} . Are you sure to proceed  ${userProfile ? userProfile.name : ''}?`}
                    isVisibleComments = {GetIsRequiredComments()}
                    commentRequired={GetIsRequiredComments()}
                    message2="Enter the password to confirm the operation"
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

Edit.props = {
    /**payment to change status*/
    lateralPanelStatusConfig: propTypes.shape({
        opened: propTypes.bool.isRequired,
        payment: propTypes.object.isRequired,
    }),
    /**open and close lateral panel config*/
    openLateralPanelChangeStatus: propTypes.func,
    /**Determinate if is saving data in the back end */
    isSavingPayment: propTypes.bool.isRequired,
}
Edit.defaultProps = {
    lateralPanelStatusConfig: {
        opened: false,
        payment: {}
    },
    isSavingPayment: false,
    openLateralPanelChangeStatus: () =>
        console.warn('Callback [openLateralPanelChangeStatus] no defined'),
}

export default withImmutablePropsToJS(Edit)