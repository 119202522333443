import propTypes from 'prop-types'
import { hasAuthorization } from '../../store/session/sessionActions'

/**
 * Authorization evaluation component
 */
const AuthorizationEvaluation = (props) => hasAuthorization(props.profile, props.allowedRoles) && props.children

AuthorizationEvaluation.propTypes = {
	/**
	 * The element to render
	 */
	children: propTypes.element,
	/**
	 * The list of allowed roles to view or execute
	 */
	allowedRoles: propTypes.arrayOf(propTypes.string),
	/**
	 * The user profile to evaluate
	 */
	profile: propTypes.shape({
		roles: propTypes.arrayOf(propTypes.object)
	})
}
AuthorizationEvaluation.defaultProps = {
	allowedRoles: [],
	profile: {
		roles: []
	}
}
export default AuthorizationEvaluation
