import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'
import { moveForwardStepIsActive } from '../helpers/StepperHelper'
import {
	mapUserServerResponse,
	getFullUsersInformation,
	mapCombineUserFromIdentity
} from '../users/UsersActions'
export const IS_LOADING_SERVICE_PROVIDERS = 'IS_LOADING_SERVICE_PROVIDERS'
export const SET_SERVICE_PROVIDERS = 'SET_SERVICE_PROVIDERS'
export const SET_PREFERENT_SERVICE_PROVIDERS = 'SET_PREFERENT_SERVICE_PROVIDERS'
export const SET_ALL_SERVICE_PROVIDERS = 'SET_ALL_SERVICE_PROVIDERS'
export const UPDATE_SERVICE_PROVIDER = 'UPDATE_SERVICE_PROVIDER'
export const UPDATE_SERVICE_PROVIDER_USERS = 'UPDATE_SERVICE_PROVIDER_USERS'
export const IS_SAVING = 'IS_SAVING'
export const ADD_SERVICE_PROVIDER = 'ADD_SERVICE_PROVIDER'
export const SET_SERVICE_PROVIDERS_USERS = 'SET_SERVICE_PROVIDERS_USERS'
export const SET_SERVICE_PROVIDERS_QUERYPARAMETERS =
	'SET_SERVICE_PROVIDERS_QUERYPARAMETERS'
export const ADD_USER_IN_SERVICE_PROVIDER = 'ADD_USER_IN_SERVICE_PROVIDER'
export const REMOVE_USER_FROM_ACCOUNT = 'REMOVE_USER_FROM_ACCOUNT'
export const SET_ROLES_IN_PROFILE = 'SET_ROLES_IN_PROFILE'
export const SET_SERVICE_PROVIDER = 'SET_SERVICE_PROVIDER'
export const MOVE_FORWARD_STEP = 'MOVE_FORWARD_STEP'
export const MOVE_BACKWARD_STEP = 'MOVE_BACKWARD_STEP'
export const UPDATE_SERVICE_PROVIDER_ACCOUNT = 'UPDATE_SERVICE_PROVIDER_ACCOUNT'
export const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS'
export const UPDATE_LAST_DATE_USER_PROVIDER = 'UPDATE_LAST_DATE_USER_PROVIDER'
export const UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_PROVIDER =
	'UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_PROVIDER'

/**
 * Is loading action constructor
 */
export const isLoadingProvider = (status) => {
	return {
		type: IS_LOADING_SERVICE_PROVIDERS,
		status
	}
}

export const setTermsAndConditions = (data) => {
	return {
		type: SET_TERMS_AND_CONDITIONS,
		data
	}
}

/**
 * Set Service Providers action creator
 */
export const setServiceProviders = (providers) => {
	return {
		type: SET_SERVICE_PROVIDERS,
		providers
	}
}

export const setPreferentServiceProviders = (preferentProviders) => {
	return {
		type: SET_PREFERENT_SERVICE_PROVIDERS,
		preferentProviders
	}
}

/**
 * Set all service providers users
 */
export const setServiceProvidersUsers = (users) => {
	return {
		type: SET_SERVICE_PROVIDERS_USERS,
		users
	}
}

/**
 * Set internal user sort criteria
 */
export const setInternalQueryParameters = (queryParametes) => {
	return {
		type: SET_SERVICE_PROVIDERS_QUERYPARAMETERS,
		queryParametes
	}
}

/**
 * Set all service providers
 */
export const setAllServiceProviders = (data) => {
	return {
		type: SET_ALL_SERVICE_PROVIDERS,
		data
	}
}

/*
 *Update service providers
 */
export const updateServiceProvider = (serviceProvider) => {
	return {
		type: UPDATE_SERVICE_PROVIDER,
		serviceProvider
	}
}

export const updateServiceProviderUser = (user) => {
	return {
		type: UPDATE_SERVICE_PROVIDER_USERS,
		user
	}
}

/**
 * Is saving
 */
export const isSaving = (status) => {
	return {
		type: IS_SAVING,
		status
	}
}

export const addProvider = (serviceProvider) => {
	return {
		type: ADD_SERVICE_PROVIDER,
		serviceProvider: serviceProvider
	}
}

export const addUserInProvider = (user) => {
	return {
		type: ADD_USER_IN_SERVICE_PROVIDER,
		user
	}
}

export const setServiceProvider = (user) => {
	return {
		type: SET_SERVICE_PROVIDER,
		user
	}
}

export const updateServiceProviderAccount = (user) => {
	return {
		type: UPDATE_SERVICE_PROVIDER_ACCOUNT,
		user
	}
}

/**
 * Set last date User
 */
export const updateLastDateUser = (user) => {
	return {
		type: UPDATE_LAST_DATE_USER_PROVIDER,
		user
	}
}

/** -
 * Set the notifications state
 */
export const updateActiveNotifications = (user) => {
	return {
		type: UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_PROVIDER,
		user
	}
}

export const loadAllServiceProviderWithFilters = (
	queryParameters,
	pageSize = 10
) => {
	return function (dispatch, getState) {
		let loadedServiceProviders = getState().serviceProviders.get(
			'serviceProviders'
		)
		if (
			loadedServiceProviders &&
			loadedServiceProviders.size > 0 &&
			queryParameters === {}
		) {
			return Promise.resolve()
		}

		dispatch(isLoadingProvider(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders`
		let command = {
			PageNumber: queryParameters ? queryParameters.pageNumber : 1,
			PageSize: pageSize,
			OrderBy: queryParameters ? queryParameters.sortBy : null,
			OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
			Query:
				queryParameters &&
				(!!queryParameters.query ? queryParameters.query : '')
		}
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				dispatch(isLoadingProvider(false))
				dispatch(setAllServiceProviders(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isLoadingProvider(false))
				console.error('Error loading all reatails', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const updateServiceProviderFromBackEnd = (representativeModel) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders`
		let command = {
			Token: representativeModel ? representativeModel.Token : '',
			Code: representativeModel ? representativeModel.CodeSap : '',
			Name: representativeModel ? representativeModel.Name : '',
			BussinessName: representativeModel
				? representativeModel.BussinessName
				: '',
			ISPName: representativeModel
				? representativeModel.ISPName
				: '',
			Tin: representativeModel ? representativeModel.Tin : '',
			ServiceType:
				representativeModel && representativeModel.ServiceType
					? representativeModel.ServiceType.value
					: '',
			ServiceEmail: representativeModel
				? representativeModel.ServiceEmail
				: '',
			ClosingPhone: representativeModel
				? representativeModel.ClosingPhone
				: '',
			TaxClass: representativeModel ? representativeModel.TaxClass : '',
			MailCountry: representativeModel.MailCountry
				? representativeModel.MailCountry
				: representativeModel.MailAddress.country,
			MailState: representativeModel.MailState
				? representativeModel.MailState
				: representativeModel.MailAddress.state,
			MailZipCode: representativeModel.MailZipCode
				? representativeModel.MailZipCode
				: representativeModel.MailAddress.postal_code,
			MailCity: representativeModel.MailCity
				? representativeModel.MailCity
				: representativeModel.MailAddress.city,
			MailStreet: representativeModel.MailStreet
				? representativeModel.MailStreet
				: representativeModel.MailAddress.street,
			SecondCountry: representativeModel.SecondCountry
				? representativeModel.SecondCountry
				: representativeModel.SecondaryAddress.country,
			SecondState: representativeModel.SecondState
				? representativeModel.SecondState
				: representativeModel.SecondaryAddress.state,
			SecondZipCode: representativeModel.SecondZipCode
				? representativeModel.SecondZipCode
				: representativeModel.SecondaryAddress.postal_code,
			SecondCity: representativeModel.SecondCity
				? representativeModel.SecondCity
				: representativeModel.SecondaryAddress.city,
			SecondStreet: representativeModel.SecondStreet
				? representativeModel.SecondStreet
				: representativeModel.SecondaryAddress.street
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProvider(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to update service provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const addServiceProvider = (providerModel) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders/add`
		let command = {
			Name: providerModel ? providerModel.Name : ''
		}
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(addProvider(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to update service provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**Upload imagen account */
export const uploadLogoAccount = (token, fileBase64) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/salesrepresentatives/uploadAvatar`
		let command = {
			FileParameter: fileBase64,
			Token: token
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProvider(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error uploading account avatar', error)
				return Promise.reject()
			})
	}
}

export const loadUserInServiceProvider = (queryParameters, code) => {
	return async (dispatch, getState) => {
		try {
			const loadUsersFromBackend = async (queryParameters) => {
				dispatch(isLoadingProvider(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Create command
				let command = {
					AccountCode: code
				}
				// Get the users
				let url = `${window.config.serviceManagementBackendUrl}/users/account`
				let serverResponse = await axios.post(url, command, config)
				let usersResult = mapUserServerResponse(serverResponse.data)
				console.log('loadUserInServiceProvider', serverResponse.data)
				await getFullUsersInformation(usersResult, null)
				dispatch(setServiceProvidersUsers(usersResult))
				if (queryParameters) {
					dispatch(setInternalQueryParameters(queryParameters))
				}
			}
			await loadUsersFromBackend(queryParameters)
		} catch (ex) {
			console.error('Errog getting the internal users list', ex)
		}
	}
}

export const addUserInServiceProvider = (providerModel, code) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			Name: providerModel ? providerModel.Name : '',
			AccountCode: code,
			UserName: providerModel ? providerModel.Email : '',
			Email: providerModel ? providerModel.Email : '',
			Password: providerModel ? providerModel.Password : '',
			PhoneNumber: providerModel ? providerModel.Password : ''
		}
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		let url = `${serviceManagementBackendUrl}/user`
		let urlIdentityServer = `${identityServerUrl}/api/externaluser`
		let identityUser
		return axios
			.post(urlIdentityServer, command)
			.then((identityUserResponse) => {
				identityUser = identityUserResponse.data
				command.UserName = identityUser.user_name
				return axios.post(url, command, config)
			})
			.then((serverResponse) => {
				dispatch(isSaving(false))
				let mappedUser = mapCombineUserFromIdentity(
					serverResponse.data,
					identityUser
				)
				dispatch(addUserInProvider(mappedUser))
				return Promise.resolve(mappedUser)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to add user in service provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const lockedUser = (token, locked) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders/locked`
		let command = {
			Token: token,
			Locked: locked
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProvider(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to lock user', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**save information internal user */
export const saveExternalUser = (user) => {
	return function (dispatch, getState) {
		// Get the authorized configuration
		let userConfig = getState().oidc.user
		let config = getAuthorizedConfiguration(userConfig)
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		dispatch(isSaving(true))
		let identityCommand = {
			Email: user.Email,
			Name: user.Name,
			PhoneNumber: user.PhoneNumber
		}
		let endPoint = `${identityServerUrl}/api/user/${user.Id}`
		let url = `${serviceManagementBackendUrl}/user/profile/${user.Token}`
		let identityUser
		return axios
			.put(endPoint, identityCommand)
			.then((identityUserResponse) => {
				identityUser = identityUserResponse.data
				return axios.get(url, config)
			})
			.then((managerResponse) => {
				dispatch(isSaving(false))
				let mappedUser = mapCombineUserFromIdentity(
					managerResponse.data,
					identityUser
				)
				dispatch(updateServiceProviderUser(mappedUser))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to save user', error)
				return Promise.reject(error)
			})
	}
}

/**Upload imagen dialog user */
export const uploadLogoExternalUser = (userId, fileBase64, token) => {
	return function (dispatch, getState) {
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		dispatch(isSaving(true))
		let endPoint = `${identityServerUrl}/api/user/${userId}/avatar`
		let data = { fileBase64 }
		let identityUser
		let userConfig = getState().oidc.user
		let config = getAuthorizedConfiguration(userConfig)
		let url = `${serviceManagementBackendUrl}/user/profile/${token}`
		return axios
			.put(endPoint, data)
			.then((identityUserResponse) => {
				dispatch(isSaving(false))
				identityUser = identityUserResponse.data
				return axios.get(url, config)
			})
			.then((managerResponse) => {
				dispatch(isSaving(false))
				let mappedUser = mapCombineUserFromIdentity(
					managerResponse.data,
					identityUser
				)
				dispatch(updateServiceProviderUser(mappedUser))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error uploading user avatar', error)
				return Promise.reject()
			})
	}
}

export const sendInvitationToServiceServiceProvider = (providerModel, code) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			Name: providerModel ? providerModel.Name : '',
			AccountCode: code,
			UserName: providerModel ? providerModel.Email : '',
			Email: providerModel ? providerModel.Email : '',
			Password: providerModel ? providerModel.Password : ''
		}
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		let url = `${serviceManagementBackendUrl}/serviceproviders/sendInvitation`
		let urlIdentityServer = `${identityServerUrl}/api/externaluser`
		let identityUser
		return axios
			.post(urlIdentityServer, command)
			.then((identityUserResponse) => {
				identityUser = identityUserResponse.data
				command.UserName = identityUser.user_name
				return axios.put(url, command, config)
			})
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProvider(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to add user in service provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to resend invitation from supplier
 * @param {*} administrator
 * @param {*} password
 */
export const resendInvitationProvider = (
	administrator,
	confirmEmail,
	provider,
	password
) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			loggedUserName: administrator ? administrator.user_name : '',
			NewPassword: password,
			UserName: administrator ? administrator.user_name : '',
			Email: confirmEmail ? confirmEmail.Email : '',
			Name: administrator ? administrator.name : '',
			ProviderName: provider ? provider.Name : ''
		}
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		let url = `${serviceManagementBackendUrl}/serviceproviders/resend/invitation`
		let urlIdentityServer = `${identityServerUrl}/api/users/${administrator.id}/password`
		return axios
			.put(urlIdentityServer, command)
			.then(() => {
				return axios.put(url, command, config)
			})
			.then(() => {
				dispatch(isSaving(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error(
					'Error to resend invitation from user in service provider',
					error
				)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const uploadDocuments = (
	files,
	evidenceModel,
	code,
	emailsRoleValidator
) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			Comments: evidenceModel.Comments,
			AccountCode: code,
			Type: evidenceModel ? evidenceModel.DocumentType.value : 0,
			Emails: emailsRoleValidator
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/serviceproviders/attachments`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProviderAccount(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error uploading documents', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const loadServiceProvider = (token) => {
	return function (dispatch, getState) {
		dispatch(isLoadingProvider(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders/${token}`
		return axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(isLoadingProvider(false))
				dispatch(setServiceProvider(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isLoadingProvider(false))
				console.error('Error to load service provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**Remove user from account */
export const removeAttachment = (token, attachmentToken) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)
		const { serviceManagementBackendUrl } = window.config

		let endPoint = `${serviceManagementBackendUrl}/serviceproviders/${token}/${attachmentToken}`
		return axios({
			method: 'delete',
			url: endPoint,
			data: null,
			headers: config.headers
		})
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProviderAccount(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error removing attachment', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to accept terms by provider
 * @param {*} token
 * @param {*} acceptedTermsGuarantee
 */
export const acceptTermsGuarantee = (token, acceptedTermsGuarantee) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders/acceptTerms`
		let command = {
			Token: token,
			AcceptedTermsGuarantee: acceptedTermsGuarantee
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProviderAccount(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to accepted terms', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to send document by provider a plant
 * @param {*} token
 */
export const sendToAuthorizer = (token, emailsRoleValidator) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders/authorizer`
		let command = {
			Token: token,
			Emails: emailsRoleValidator
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProviderAccount(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to send to authorizer informaction', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to accepted or rejected document the provider in plant
 * @param {*} token
 * @param {*} accessIsGranted
 * @param {*} passwordModel
 * @param {*} administrator
 */
export const accessIsGrantedToPortal = (
	token,
	accessIsGranted,
	passwordModel,
	administrator
) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/serviceproviders/grandAcess`
		let command = {
			Token: token,
			AccessIsGranted: accessIsGranted,
			Comments: passwordModel ? passwordModel.comments : '',
			EmailProvider: administrator ? administrator.email : '',
			ValidateBy: user.profile.name
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				dispatch(updateServiceProviderAccount(serverResponse.data))
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error to accepted terms', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const addTerms = (files, evidenceModel) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user
		let command = {
			Comments: evidenceModel.Comments,
			CreatorName: identityUser.profile.name
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/terms`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileName', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.post(endPoint, data, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				if (serverResponse.status === 200) {
					if (serverResponse.data) {
						dispatch(setTermsAndConditions(serverResponse.data))
					}
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error uploading documents', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const getTermsAndConditions = () => {
	return function (dispatch, getState) {
		dispatch(isLoadingProvider(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/terms`
		return axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(isLoadingProvider(false))
				if (serverResponse.status === 200) {
					if (serverResponse.data) {
						dispatch(setTermsAndConditions(serverResponse.data))
					}
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isLoadingProvider(false))
				console.error('Error to get terms', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const updateTerms = (files, evidenceModel, Token, review) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user
		let command = {
			Comments: evidenceModel.Comments,
			CreatorName: identityUser.profile.name,
			Token: Token,
			Review: review
		}
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/terms`
		let data = new FormData()
		data.append('files', files)
		data.append('command', JSON.stringify(command))
		for (var index = 0; index < files.length; index++) {
			data.append('FileName', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				dispatch(isSaving(false))
				if (serverResponse.status === 200) {
					if (serverResponse.data) {
						dispatch(setTermsAndConditions(serverResponse.data))
					}
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error('Error uploading documents', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const moveBackwardStep = () => {
	return function (dispatch, getState) {
		let actualStep = getState().serviceProviders.get('activeStep')
		let prevStep = --actualStep
		dispatch({
			type: MOVE_BACKWARD_STEP,
			activeStep: prevStep,
			isFowardEnabled: true,
			isBackwardEnabled: prevStep !== 0 ? true : false
		})
	}
}

export const moveForwardStep = () => {
	return function (dispatch, getState) {
		let actualStep = getState().serviceProviders.get('activeStep')
		let serviceProvider = getState().serviceProviders.get('serviceProvider')
		let nextStep = ++actualStep
		let isFowardEnabled = moveForwardStepIsActive(serviceProvider, nextStep)
		dispatch({
			type: MOVE_FORWARD_STEP,
			activeStep: nextStep,
			isFowardEnabled: isFowardEnabled,
			isBackwardEnabled: true
		})
	}
}

/** this Function search for imbera users in service providers to find out if it has already been added */
export const lookUserInServiceProvider = (userName) => {
	return function (dispatch, getState) {
		let allUsers = getState().serviceProviders.get('providerUsers')
		let foundUser = allUsers.find(
			(u) => u.get('UserName').toLowerCase() === userName
		)
		return Promise.resolve(foundUser ? true : false)
	}
}

export const editActiveNotifications = (token, activeNotification) => {
	return function (dispatch, getState) {
		const { serviceManagementBackendUrl } = window.config
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			Token: token,
			ActiveNotifications: activeNotification
		}
		let url = `${serviceManagementBackendUrl}/user/notifications/activation`
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(updateActiveNotifications(serverResponse.data))
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to change Notification status', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const downloadServiceProvidersList = () => {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let endPoint = `${window.config.serviceManagementBackendUrl}/serviceproviders/listOfUsers`
		return axios
			.post(
				endPoint,
				{},
				{
					responseType: 'arraybuffer',
					headers: config
				}
			)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('RESPONSE', serverResponse)
					var blob = new Blob([serverResponse.data], {
						type:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Service_Providers_List.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error donwload list of service providers', error)
				return Promise.reject()
			})
	}
}

export const getPreferentServiceProviders = (state, zipCode) => {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			State: state,
			ZipCode: zipCode
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/serviceproviders/preferential`
		return axios
			.post(endPoint, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPreferentServiceProviders(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				console.error(
					'Error loading preferent service providers',
					error
				)
				return Promise.reject(error.response.data.Message)
			})
	}
}
