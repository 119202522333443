/**Import raect section */
import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

/**Import compenents skeletons section */
import ToolbarSkeleton from '../services/componets/ToolbarSkeleton'
import { profileStyle } from '../../../resources/styles/ProfileStyles'

/**Import material ui section */
import { Card } from '@material-ui/core'


const ShowViewSkeleton = () => {
    const classes = profileStyle();

    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <ToolbarSkeleton />
                </div>
                <div className={classes.profileRowTwo}>
                    <Card className={classes.panelLeftSkeleton}>
                        <div className={classes.avatarContainer}>
                            <Skeleton variant="circle" animation='wave' width={"30%"} height={`100px`} /><br />
                        </div><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />



                    </Card>
                    <Card className={classes.panelRight}>
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />
                        <Skeleton variant="rect" animation='wave' width={"100%"} height="30px" /><br />


                    </Card>

                </div>
            </div>
        </div>
    )
}

export default ShowViewSkeleton