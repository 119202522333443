/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/**Import material UI Section */
import { Tooltip, Button, ButtonGroup, Link, Typography } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/DeleteOutline'

/**Import resources section */
import registerTableConfig from '../../resources/RegisterTableConfig'
import { desktopViewRegisterStyles } from '../../resources/DesktopViewRegisterStyles'

/**Import components section */
import DataGrid from '../../../common/apsys.datagrid.materialui/DataGrid'
import ValidateInformation from './ValidateInformation'
import AlertComponent from '../../../common/AlertComponent'
import { ServiceProviderStatus } from '../../../../store/helpers/AppConstants'

const RegisterTable = (props) => {
    const { serviceProvider, isLoading, isSaving, urlDoument, onOpenDialogPassword,
        onOpenEvidenceDialog, onSendToAuthorizerDocuments, userProfile,
        onOpenDialogForGrantedAccess, onRedirectToServiceProvider, isValidToAddOtherFiles} = props;

    const getRegisterTableConfig = () => {
        let iconDownLoad = registerTableConfig.find((x) => x.dataSource === 'Options')
        iconDownLoad.onRenderProperty = (item) => {
            return (<>
                    {(serviceProvider ? serviceProvider.CanBeEditedDataInRegister : false) && userProfile.Account.RequieredTermsAccepted &&
                        <ButtonGroup variant='text' size='small'>
                        
                                <Tooltip component="span" title='Remove document'>
                                    <Button
                                        onClick={() => onOpenDialogPassword(item)}
                                        disabled={!(serviceProvider ? serviceProvider.CanBeEditedDataInRegister : false)}
                                    >
                                        <DeleteIcon color='action' />
                                    </Button>
                                </Tooltip>
                        </ButtonGroup>
                    }

                    {isValidToAddOtherFiles && (serviceProvider ? serviceProvider.CanBeAddOthersAttachment : false) && item.Status !== 3 && // Different status accpeted in plant
                        <ButtonGroup variant='text' size='small'>
                        
                                <Tooltip component="span" title='Remove document'>
                                    <Button
                                        onClick={() => onOpenDialogPassword(item)}
                                        disabled={item.Status === 3 } // You can delete different from accepted
                                    >
                                        <DeleteIcon color='action' />
                                    </Button>
                                </Tooltip>
                        </ButtonGroup>
                    }

                </>
            )
        }

        let fileName = registerTableConfig.find((x) => x.dataSource === 'FileName')
        fileName.onRenderProperty = (item) => {
            return (
                <Typography noWrap={true} variant='subtitle2'>
                    <CloudDownloadIcon color='primary' style={{ marginBottom: "-5px", marginRight: "7px" }} />
                    <Link target="_blank" href={`${window.config.serviceManagementBackendUrl}/${urlDoument}/${item.FileNamePath}`}>{item.FileName}</Link>
                </Typography>
            )
        }
        return registerTableConfig
    }

    return (
        <>
            <DataGridToolbar
                isSaving={isSaving}
                onAddDocument={onOpenEvidenceDialog}
                serviceProvider={serviceProvider}
                onSenToAutorizer={onSendToAuthorizerDocuments}
                userProfile={userProfile}
                isValidToAddOtherFiles={isValidToAddOtherFiles}
            />
            <DataGrid
                headers={getRegisterTableConfig()}
                data={serviceProvider ? serviceProvider.Attachments : []}
                isLoading={isLoading}
            />

            {props.activeStep === 2 && <div style={{marginTop: "50px"}}>
                <ValidateInformation
                    onOpenDialogForGrantedAccess={onOpenDialogForGrantedAccess}
                    isSaving={isSaving}
                    userProfile={userProfile}
                    serviceProvider={serviceProvider}
                    status={serviceProvider ? serviceProvider.Status :0}
                    onRedirectToServiceProvider={onRedirectToServiceProvider}
                    CanBeValidateOtherFiles={serviceProvider ? serviceProvider.CanBeAcceptedOthersFiles : false}
                />
            </div>}

            { serviceProvider.Status === ServiceProviderStatus.REJECTED_IN_COMPANY && <div style={{marginTop: "50px"}}>
                <AlertComponent
                    title={"Document rejected in plant"}
                    message={serviceProvider.Comments}
                    severityType={"error"}
                />
            </div>}

        </>
    )
}

RegisterTable.propTypes = {
	/**
	 * Determine if the isLoading data from database
	 */
    isLoading: propTypes.bool.isRequired,
    isSaving: propTypes.bool.isRequired,
	/**
	 * The information render in the table
	 */
    serviceProvider: propTypes.object.isRequired,
    /**
	 * Open modal for deleted document
	 */
    onOpenDialogPassword: propTypes.func.isRequired,
}
RegisterTable.defaultProps = {
    isLoading: false,
    isSaving: false,
    serviceProvider: {},
    onOpenDialogPassword: () =>
        console.warn('Callback [onOpenDialogPassword] no defined'),
}

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
    const { serviceProvider, isSaving, userProfile, isValidToAddOtherFiles } = props;
    const classes = desktopViewRegisterStyles()

    return (
        <div className={classes.searchIndex}>
            <ButtonGroup component="span" size='small' variant="outlined" color="primary" className={classes.buttonsGroupContainer}>
                {serviceProvider.CanBeEditedDataInRegister  && userProfile.Account.RequieredTermsAccepted && <Tooltip component="span" title='Add document'>
                    <Button
                        className={classes.buttonsGroup}
                        onClick={props.onAddDocument}
                        disabled={!(serviceProvider ? serviceProvider.CanBeEditedDataInRegister : false)}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>}
                {serviceProvider.CanBeAddOthersAttachment && isValidToAddOtherFiles && <Tooltip component="span" title='Add more files'>
                    <Button
                        className={classes.buttonsGroup}
                        onClick={props.onAddDocument}
                        disabled={!(serviceProvider ? serviceProvider.CanBeAddOthersAttachment : false)}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>}
                {
                    (serviceProvider ? (serviceProvider.CanBeSendToAuthorizer && serviceProvider.CanBeEditedDataInRegister) : false)
                    && userProfile.Account.RequieredTermsAccepted &&
                    <Tooltip component="span" title='Send to authorize'>
                        <Button
                            className={classes.buttonsSend}
                            onClick={props.onSenToAutorizer}
                            disabled={isSaving || !(serviceProvider ? (serviceProvider.CanBeSendToAuthorizer && serviceProvider.CanBeEditedDataInRegister) : false)}
                        >
                            <SendIcon />
                        </Button>
                    </Tooltip>
                }
            </ButtonGroup>
        </div>
    )
}

DataGridToolbar.propTypes = {

}

DataGridToolbar.defaultProps = {

}


export default RegisterTable
