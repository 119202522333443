import {
	ServicesStatusTypes,
	SpareParRequestStatus,
	WarehouseType,
	PaymentRequestStatus
} from './AppConstants'
import { isNullOrEmpty } from './StringHelper'

/**
 * Select option for all services status open
 */
const allServicesStatus = [
	{ label: 'Active', key: ServicesStatusTypes.ACTIVE },
	{ label: 'Assigned ', key: ServicesStatusTypes.ASSIGNED },
	{ label: 'Suspended', key: ServicesStatusTypes.SUSPENDED },
	{ label: 'Waiting', key: ServicesStatusTypes.WAITING }
]

/***
 * Function to get all status for services
 */
export const GetStatusOpenServices = () => {
	return allServicesStatus
}

/**
 *  Select option for all services status closed
 */
const closedServicesStatus = [
	{ label: 'Completed', key: ServicesStatusTypes.COMPLETED },
	{ label: 'Replaced ', key: ServicesStatusTypes.REPLACED },
	{ label: 'Cancelled', key: ServicesStatusTypes.CANCELLED }
]

/***
 * Function to get all status for services closed
 */
export const GetStatusClosedServices = () => {
	return closedServicesStatus
}

/**
 * Function to get all status spare part request open
 */
const allSparePartStatus = [
	{ label: 'Draft', key: SpareParRequestStatus.DRAFT },
	{ label: 'Submitted ', key: SpareParRequestStatus.IN_PROCESS },
	{ label: 'Send from Mexico', key: SpareParRequestStatus.SEND_TO_MX },
	{ label: 'Send to validate', key: SpareParRequestStatus.SEND_TECH_USA },
	{ label: 'Rejected in plant', key: SpareParRequestStatus.REJECTED_RESEND }
]

export const GetStatusSpareParts = () => {
	return allSparePartStatus
}

/**
 * Function to get all status spare part request closed
 */
const allSparePartStatusClosed = [
	{ label: 'Closed', key: SpareParRequestStatus.CLOSED },
	{ label: 'Cancelled', key: SpareParRequestStatus.CANCELLED }
]

export const GetStatusSparePartsClosed = () => {
	return allSparePartStatusClosed
}

/***
 * Function get all status from traking number
 */
const allStatusTrakicking = [
	{ label: 'Transit', key: 'Transit' },
	//{ label: 'Pickup', key: 'Pickup' },
	{ label: 'Delivered', key: 'Delivered' },
	//{ label: 'Undelivered', key: 'Undelivered' },
	//{ label: 'Expired', key: 'Expired' },
	//{ label: 'Exception', key: 'Exception' },
	{ label: 'Cancelled', key: 'Cancelled' },
	//{ label: 'Not Found', key: 'Not Found' },
	{ label: 'Info Received', key: 'Info Received' },
	{ label: 'Out for Delivery', key: 'Out for Delivery' }
]

export const GetStatusTrackingNumber = () => {
	return allStatusTrakicking
}

/**
 * Function get all warehouse by filter
 */
const allWarehouseType = [
	{ label: 'Mexico', key: WarehouseType.MEXICO },
	{ label: 'USA ', key: WarehouseType.USA }
]

export const GetWarehouseType = () => {
	return allWarehouseType
}

/**
 * Function to get all status payment requests
 */
const allPaymentRequestsStatus = [
	{ label: 'Draft', key: PaymentRequestStatus.DRAFT },
	{ label: 'Submitted ', key: PaymentRequestStatus.IN_PROCESS },
	{ label: 'Accepted ', key: PaymentRequestStatus.ACCEPTED },
	{ label: 'Rejected ', key: PaymentRequestStatus.REJECTED },
	{ label: 'Paid ', key: PaymentRequestStatus.CLOSED }
]

export const GetStatusPaymentRequest = () => {
	return allPaymentRequestsStatus
}

/**Transform option for used in filter selector */
const getSelectOptionData = (option) => ({
	key: option.Code,
	label: `${option.Name}`
})

/**Get options for filter selectors */
export const getOptions = (data) => {
	return data.map((option) => getSelectOptionData(option))
}

export const getOptionsCustomer = (data) => {
	return data.map((option) => getOptionsSelectCustomer(option))
}

export const getOptionsSelectCustomer = (option) => ({
	value: option.Code,
	label: `${option.Code}-${option.Name}`
})

/**Transform option for used in filter selector */
const getSelectOptionInternal = (option) => ({
	key: option.UserName,
	label: `${option.Name}`,
	isAvatarFromIdentity: true
})

/**Get options for filter selectors */
export const getOptionsInternalUsers = (data) => {
	return data.map((option) => getSelectOptionInternal(option))
}

/**Transform option for used in filter selector */
export const getOptionsForReactSelect = (option) => ({
	value: option.Code,
	label: option.ISPName ? (`${option.Code}-${option.ISPName}`) : (`${option.Code}-${option.Name}`),
	avatarName: `${option.Name}`,
	address: option.Address,
	avatarUrl: option.AvatarUrl,
	isAvatarFromIdentity: false
})

/**Get options for filter selectors */
export const getOptionsReactSelect = (data) => {
	return data.map((option) => getOptionsForReactSelect(option))
}

/**Transform option for used in filter selector */
export const getOptionsForPreferentReactSelect = (option) => ({
	value: option.ServiceProvider.Code,
	label: `${option.ServiceProvider.Code}-${option.ServiceProvider.Name} services: ${option.ServicesCount}`,
	avatarName: `${option.ServiceProvider.Name}`,
	address: option.ServiceProvider.Address,
	avatarUrl: option.ServiceProvider.AvatarUrl,
	isAvatarFromIdentity: false
})

/**Get options for preferential providers */
export const getOptionsPreferential = (data) => {
	return data.map((option) => getOptionsForPreferentReactSelect(option))
}

/**
 * services closed
 * @param {*} services
 */
export const serviceOptions = (services) => {
	return services.map((option) => serviceOptionsFor(option))
}

const onMapperLabelServiceOption = (srv) => {
	let description = ''
	let result = ''
	if (!isNullOrEmpty(srv.Ticket))
		result = description.concat(`${srv.Ticket} `)
	if (
		!isNullOrEmpty(srv.RepresentativeName) &&
		srv.RepresentativeName !== 'UNDEFINED'
	)
		result = result.concat(` | ${srv.RepresentativeName} `)
	if (!isNullOrEmpty(srv.RetailerName))
		result = result.concat(` | ${srv.RetailerName} `)
	return result
}

export const serviceOptionsFor = (option) => ({
	value: option.Ticket,
	label: onMapperLabelServiceOption(option),
	Name: option.Ticket
})

/**Transform option for part */
export const getOptionsForPartReactSelect = (option) => ({
	value: option.Code,
	label: `${option.Code}`,
	Name: option.Name
})

export const getOptionsForPart = (data) => {
	return data.map((option) => getOptionsForPartReactSelect(option))
}
