import React, { useEffect, useState } from 'react'
import ServiceProviderRegisterDesktopView from './components/StatisticsDesktopView'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadAllCustomers } from '../../store/customers/customersActions'
import { moveBackwardStep, moveForwardStep, getMaterialsByGae, getCustomerInvoice, downloadStatisticsReport, setActiveStep  } from '../../store/statistics/statisticsActions'
import moment from 'moment'

const Index = () => {
  const dispatch = useDispatch()
  const isSelectAnyItem = true
  const userProfile = useSelector((state) => state.session.get('profile'))
  const history = useHistory()
  const onChangeLocation = (location) => history.push(location)
  const firstData = useSelector((state) => state.statistics.get('materialsByGae'))
  const secondData = useSelector((state) => state.statistics.get('customersInvoice'))
	const isLoadingFirstData = useSelector((state) => state.statistics.get('isLoadingFirstData'))
	const isLoadingSecondData = useSelector((state) => state.statistics.get('isLoadingSecondData'))
	const isDownloadReport = useSelector((state) => state.statistics.get('isDownloadStatisticsReport'))

  const isFowardEnabled = useSelector((state) =>
    state.statistics.get('isFowardEnabled')
  )
  const isBackwardEnabled = useSelector((state) =>
    state.statistics.get('isBackwardEnabled')
  )
  const activeStep = useSelector((state) =>
		state.statistics.get('activeStep')
	)
    /**store local this components */
    const [statisticsModel, setStatisticsModel] = useState({
  })

  useEffect(() => {
    dispatch(loadAllCustomers(null, 1000))
}, [dispatch])


  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().startOf('day'));
  const onDateRangeChange = (range) => {
    setStartDate(range.startDate)
    setEndDate(range.endDate)
}

  /**store local redux */
  const customers = useSelector((state) => state.customers.get('customers'))
      /** Invoke when select property change*/
      const onSelectChange = (name, valueProperty) => {
        setStatisticsModel({
            ...statisticsModel,
            [name]: valueProperty
        })
    }

    const onSelectAllChange = (name, customersOptions) => {
      setStatisticsModel({
        ...statisticsModel,
        [name]: []
      })

      setStatisticsModel({
        ...statisticsModel,
        [name]: customersOptions
      })
    }

    const isAnyCustomerSelected = !!statisticsModel.Customer && statisticsModel.Customer.length > 0;
    /**
	 * Here we dispatch the action for go to next step.
	 */
	const onMoveBackWardHandler = () => {
		dispatch(moveBackwardStep())
	}

	const onMoveForwardHandler = () => {
		dispatch(moveForwardStep())
	}
  const handleGetCustomerInvoice = (matnr, startDate, endDate, customers) => dispatch(getCustomerInvoice(matnr, startDate, endDate, customers));
  const handleGetMaterialsByGae = (gae) => dispatch(getMaterialsByGae(gae));
 
    const handleDownloadStatisticsReport = (result) => {
    dispatch(downloadStatisticsReport(result))

  }
  const handleSetActiveStep = (step) => {
    dispatch(setActiveStep(step));
  }

  return (
    <ServiceProviderRegisterDesktopView
      onDateRangeChange={onDateRangeChange}
      onSelectChange={onSelectChange}
      customers = {customers}
      onMoveForwardHandler={onMoveForwardHandler}
      onMoveBackWardHandler={onMoveBackWardHandler}
      userProfile={userProfile}
      onChangeLocation={onChangeLocation}
      isFowardEnabled={isFowardEnabled}
      isBackwardEnabled={isBackwardEnabled}
      isSelectAnyItem={isSelectAnyItem}
      activeStep = {activeStep}
      getMaterialsByGae = {handleGetMaterialsByGae}
      getCustomerInvoice = {handleGetCustomerInvoice}
      firstData = {firstData}
      customersInvoiceSearchResult = {secondData}
      isLoadingFirstData = {isLoadingFirstData}
      isLoadingSecondData = {isLoadingSecondData}
      isAnyCustomerSelected={isAnyCustomerSelected}
      onSelectAllChange ={onSelectAllChange}
      statisticsModel={statisticsModel}
      startDate={startDate}
      endDate={endDate}
      handleDownloadStatisticsReport = {handleDownloadStatisticsReport}
      isDownloadReport = {isDownloadReport}
      setActiveStep = {handleSetActiveStep}
    />
    )
}

export default Index