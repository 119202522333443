/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/**Import Material UI Section */
import { Card, Typography, Chip, CardContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

/**Import styles section */
import { createPaymentStyle } from '../resources/CreatePaymentStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'

import {
	SERVICE_PROVIDER_MANAGER,
	PORTAL_ADMINISTRATOR
} from '../../../resources/RolesEnum'
import { PaymentRequestStatus } from '../../../store/helpers/AppConstants'

import Toolbar from '../../common/Toolbar'
import Authorization from '../../common/AuthorizationEvaluation'
import ServiceSelector from '../../services/components/ServiceSelector'
import ExpansionPanelService from './ExpansionPanelService'

/**
 * Component to add header form payment
 * @param {*} props
 */
const PaymentHeaderComponent = (props) => {
	const classes = createPaymentStyle()
	const {
		status,
		paymentRequest,
		userProfile,
		colorByStatus,
		isSavingService,
		closedServices,
		updateAmount,
		isAuthorizerCanEdit,
		onServiceSelected,
		onShowWarranty,
		onCommentsServiceChange,
		isPaymentRequestAuthorizerCanEdit,
		openCloseDialogPassword
	} = props

	const onUpdateSerieInService = (
		service,
		equipmentToken,
		refrigerationNotCoolFailure,
		iluminationFailure,
		doorFailure,
		comments,
		faultWarranty
	) => {
		props.onUpdateSerieInService(
			service,
			equipmentToken,
			refrigerationNotCoolFailure,
			iluminationFailure,
			doorFailure,
			comments,
			faultWarranty
		)
	}
	const onRenderViewOptions = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{paymentRequest.CanBeEditedProvider && (
					<Authorization
						profile={userProfile}
						allowedRoles={[SERVICE_PROVIDER_MANAGER]}
					>
						<Chip
							label='SEND TO VALIDATE'
							clickable
							color='primary'
							onClick={() =>
								props.openLateralPanelChangeStatus(
									props.paymentRequest
								)
							}
						/>
					</Authorization>
				)}
				{status === PaymentRequestStatus.IN_PROCESS && (
					<Authorization
						profile={userProfile}
						allowedRoles={[PORTAL_ADMINISTRATOR]}
					>
						<Chip
							label='VALIDATE REQUEST'
							clickable
							color='primary'
							onClick={() =>
								props.openLateralPanelChangeStatus(
									props.paymentRequest
								)
							}
						/>
					</Authorization>
				)}
			</div>
		)
	}

	return (
		<Card className={classes.container}>
			<Toolbar
				title={`View payment request | FOLIO: ${paymentRequest.Folio} | PROVIDER: ${paymentRequest.ServiceProvider.Name}`}
				renderContentRight={onRenderViewOptions()}
				icon={iconSparePart}
				renderBackgroundColor={colorByStatus}
			/>

			<CardContent>
				<br />
				<Typography className={classes.title}>
					<strong>{'SERVICES'}</strong>
				</Typography>
				{paymentRequest.CanBeEditedProvider && isAuthorizerCanEdit && (
					<>
						<ServiceSelector
							autoFocus
							placeholder='Add other service'
							services={closedServices}
							onChange={onServiceSelected}
						/>
						<br />
					</>
				)}

				{paymentRequest.Services.length !== 0 ? (
					paymentRequest.Services.map((service) => {
						return (
							<ExpansionPanelService
								key={service.Token}
								service={service}
								isSavingService={isSavingService}
								updateAmountItem={updateAmount}
								canBeEdit={
									paymentRequest &&
									paymentRequest.CanBeEditedProvider &&
									isAuthorizerCanEdit
								}
								isAuthorizerCanRemoveServices={
									paymentRequest &&
									paymentRequest.Status ===
										PaymentRequestStatus.IN_PROCESS &&
									isAuthorizerCanEdit
								}
								onShowWarranty={onShowWarranty}
								onCommentsServiceChange={
									onCommentsServiceChange
								}
								isPaymentRequestAuthorizerCanEdit={
									paymentRequest &&
									paymentRequest.CanBeEditedPaymentManager &&
									isPaymentRequestAuthorizerCanEdit
								}
								openCloseDialogPassword={
									openCloseDialogPassword
								}
								onUpdateSerieInService={(
									equipmentToken,
									refrigerationNotCoolFailure,
									iluminationFailure,
									doorFailure,
									comments,
									faultWarranty
								) =>
									onUpdateSerieInService(
										service,
										equipmentToken,
										refrigerationNotCoolFailure,
										iluminationFailure,
										doorFailure,
										comments,
										faultWarranty
									)
								}
							/>
						)
					})
				) : (
					<Alert severity='warning'>
						{
							'There are no added services in the payment request, you must select a service to send to validate the request'
						}
					</Alert>
				)}
			</CardContent>
		</Card>
	)
}

PaymentHeaderComponent.propTypes = {
	paymentRequest: propTypes.object,
	/**Function invoke callback for show warranty for equipment */
	onShowWarranty: propTypes.func.isRequired
}
PaymentHeaderComponent.defaultProps = {
	paymentRequest: {
		Folio: '',
		ServiceProvider: {
			Name: ''
		},
		CanBeEditedProvider: false,
		Services: []
	},
	onShowWarranty: () => console.warn('Callback [onShowWarranty] no defined')
}

export default PaymentHeaderComponent
