import React from 'react'
import Selector from '../../common/Selector'
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'

/**
 * Service selector
 */
const ServiceSelector = (props) => {

	const onGetDescription = (srv) => {
		let description = "";
		let result = "";
		if (!isNullOrEmpty(srv.Ticket))
			result = description.concat(`${srv.Ticket} `)
		if (!isNullOrEmpty(srv.RepresentativeName))
			result = result.concat(` | ${srv.RepresentativeName} `)
		if (!isNullOrEmpty(srv.RetailerName))
			result = result.concat(` | ${srv.RetailerName} `)
		return result
	}

	/**
	 * Get the selector options from the services
	 */
	const serviceOptions = props.services.map((srv) => {
		return {
			value: srv.Ticket,
			label: `${onGetDescription(srv)}`
		}
	})

	/**
	 * On change
	 */
	const onChange = (option) => {
		if (props.onChange) {
			let selectedService = props.services.find((srv) => srv.Ticket === option.value)
			props.onChange(selectedService)
		}
	}

	/**
	 * Get the default value
	 */
	const defaultValue = () => {
		if (props.defaultValue) {
			return serviceOptions.find((opt) => opt.value === props.defaultValue.Ticket)
		}
	}

	return (
		<Selector
			options={serviceOptions}
			hasError={props.hasError}
			errorMessage={props.errorMessage}
			onChange={onChange}
			defaultValue={defaultValue()}
			placeholder={props.placeholder}
		/>
	)
}
ServiceSelector.propTypes = {
	/**
	 * The list of services in the selector
	 */
	services: propTypes.arrayOf(
		propTypes.shape({
			Ticket: propTypes.string.isRequired,
			RepresentativeCode: propTypes.string.isRequired,
			RepresentativeName: propTypes.string.isRequired,
			RetailerCode: propTypes.string.isRequired,
			RetailerName: propTypes.string.isRequired,
			ServiceProviderCode: propTypes.string,
			ServiceProviderName: propTypes.string
		})
	).isRequired,
	/**
	 * If true, the selector shows an red error state
	 */
	hasError: propTypes.bool.isRequired,
	/**
	 * If true, the selector show an red error message
	 */
	errorMessage: propTypes.string,
	/**
	 * Placeholder
	 */
	placeholder: propTypes.string,
	/**
	 * Callback executed when the user change the selected service
	 */
	onChange: propTypes.func,
	/**
	 * Autofocus
	 */
	autoFocus: propTypes.bool,
	/**
	 * Default service
	 */
	defaultValue: propTypes.shape({
		Ticket: propTypes.string.isRequired,
		RepresentativeCode: propTypes.string.isRequired,
		RepresentativeName: propTypes.string.isRequired,
		RetailerCode: propTypes.string.isRequired,
		RetailerName: propTypes.string.isRequired,
		ServiceProviderCode: propTypes.string,
		ServiceProviderName: propTypes.string
	})
}
ServiceSelector.defaultProps = {
	services: [],
	hasError: false,
	autoFocus: false
}
export default ServiceSelector
