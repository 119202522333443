import { fromJS } from 'immutable';
import * as customersActions from './customersActions';

let initialState = fromJS({
    isLoadingCustomers: false,
    isSaving: false,
    customers: [],
    customerUsers: [],
    internalQueryParameters: null,
    pagination: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        orderBy: "code",
        orderType: "asc",
    },
})

/**
 * Sales Representative's reducer
 */
const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case customersActions.IS_LOADING_CUSTOMERS:
            return state.merge({
                isLoadingCustomers: action.status
            })
        case customersActions.SET_ALL_CUSTOMERS: {
            return state.merge({
                customers: fromJS(action.data.customers),
                isLoadingCustomers: false,
                pagination: fromJS({
                    pageNumber: action.data.pageNumber,
                    pageSize: action.data.pageSize,
                    total: action.data.total,
                    orderBy: action.data.orderBy,
                    orderType: action.data.orderType,
                })
            })
        }
        case customersActions.ADD_CUSTOMER: {
            let newCustomer = fromJS(action.customer);
            return state.update('customers', allCustomer => pushCustomer(allCustomer, newCustomer));
        }
        case customersActions.IS_SAVING: {
            return state.merge({
                isSaving: action.status
            })
        }
        case customersActions.UPDATE_CUSTOMER: {
            let customerUpdate = fromJS(action.customer);
            return state.update('customers', allCustomers => updateCustomer(allCustomers, customerUpdate));
        }
        case customersActions.SET_CUSTOMERS_USERS: {
            return state.merge({
                customerUsers: fromJS(action.users),
                isLoadingCustomers: false
            })
        }
        case customersActions.SET_CUSTOMER_QUERYPARAMETERS: {
            return state.merge({
                internalQueryParameters: fromJS(action.queryParametes)
            })
        }
        case customersActions.ADD_USER_IN_CUSTOMER: {
            let newUser = fromJS(action.user);
            return state.update('customerUsers', allUsers => pushUser(allUsers, newUser));
        }
        case customersActions.UPDATE_CUSTOMER_USERS: {
            let userUpdate = fromJS(action.user);
            return state.update('customerUsers', allIntenalUsers => updateCustomer(allIntenalUsers, userUpdate));
        }
        case customersActions.UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_CUSTOMER: {
            let lastActivation = action.user.ActiveNotifications
            return state.update('customerUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.UserName, 'ActiveNotifications', lastActivation));
        }
        case customersActions.SET_ROLES_IN_PROFILE: {
            let roles = fromJS(action.roles);
            return state.update('customerUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.userName, 'Roles', fromJS(roles)));
        }
        case customersActions.UPDATE_LAST_DATE_USER_CUSTOMER: {
            let lastDate = action.user.last_authentication_date;
            return state.update('customerUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.user_name, 'LastAuthenticationDate', lastDate));
        }
        default:
            return state
    }
}

const pushCustomer = (allCustomers, newCustomer) => {
    if (allCustomers.some(user => user.get('Code') === newCustomer.get('Code'))) {
        return allCustomers;
    }
    return allCustomers.push(newCustomer);
}

const updateCustomer = (allCustomer, updateCustomer) => {
    let indexOfCustomer = allCustomer.findIndex(representative => representative.get('Token') === updateCustomer.get('Token'));
    if (indexOfCustomer >= 0) {
        return allCustomer.setIn([indexOfCustomer], updateCustomer);
    }
    return allCustomer;
}

const pushUser = (allIntenalUsers, newUser) => {
    if (allIntenalUsers.some(user => user.get('UserName') === newUser.get('UserName'))) {
        return allIntenalUsers;
    }
    return allIntenalUsers.push(newUser);
}

const updateUserProperty = (allIntenalUsers, userName, propertyName, propertyValue) => {
    let indexOfUser = allIntenalUsers.findIndex(user => user.get('UserName') === userName);
    if (indexOfUser >= 0) {
        return allIntenalUsers.setIn([indexOfUser, propertyName], propertyValue);
    }
    return allIntenalUsers;
}

export default customersReducer