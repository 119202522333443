import React from 'react';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment'
import propTypes from 'prop-types'

/**
 * Date time picker
 *
 * @export
 * @class DateSelector
 * @extends {Component}
 */
const DateSelector = props => {
    moment.locale('es');

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            {props.showIncon && <DatePicker
                fullWidth
                inputVariant="outlined"
                format= {props.format ? props.format : "MM/DD/YYYY" }
                disabled={props.disabled}
                views={props.views}
                margin="dense"
                value={props.value} 
                label={props.label}
                openTo={props.openTo}
                name={props.name}
                disableFuture={props.disableFuture ? true: false}
                disablePast={props.disablePast ? true: false}
                onChange={(value)  => {
                    if (props.onChange) {
                        props.onChange({target:{ value: value, name: props.name}})
                    }
                }} 
                inputProps={{
                    style: { fontSize: 12 }
                }}
            />}
               {!props.showIncon && <KeyboardDatePicker 
                data-cy={props.dataCy}
                fullWidth
                autoOk
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format= {props.format ? props.format : "MM/DD/YYYY" }
                disabled={props.disabled}
                views={props.views}
                margin="dense"
                value={props.value} 
                label={props.label}
                openTo={props.openTo}
                disableFuture={props.disableFuture ? true: false}
                disablePast={props.disablePast ? true: false}
                onChange={(value)  => {
                    if (props.onChange) {
                        props.onChange({target:{ value: value, name: props.name}})
                    }
                }} 
                inputProps={{
                    style: { fontSize: 12 }
                }}
            />}
        </MuiPickersUtilsProvider>  
    );
}
DateSelector.propTypes = {
	/**
	 * The show icon in date 
	 */
	showIncon: propTypes.bool.isRequired,
	/**
	 * The final disabled date
	 */
	disabled: propTypes.bool.isRequired,
	/**
	 * Callback executed when changed
	 */
    onChange: propTypes.func.isRequired,
    /**
     * The text field name variable
     */
	name: propTypes.string,
	/**
	 * The valie in text
	 */
	value: propTypes.object
}
DateSelector.defaultProps = {
	showIncon: true,
	disabled: true,
    onChange: () => console.warn('No [onChange] callback defined'),
	name: '',
	value: '',
}
export default DateSelector