import { makeStyles } from '@material-ui/styles'

/**
 * Custom crop dialog Styles
 */
export const cropDialogStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
        minHeight: "300px"
    },
    buttonClose: {
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.red,
        "&:hover": {
            backgroundColor: theme.palette.colors.red,
            color: theme.palette.common.white,
        },
        width: "37px",
        height: "36px",
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    colorIconClose:{
        color: theme.palette.common.white,
    },
    buttonSave:{
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.green,

        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colors.green,
        },
    },
    title:{
        color: theme.palette.serviceStatus.newServiceDark,
    },
    actionsButton:{
        marginRight:"40px"
    }
}))