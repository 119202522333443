/**Import react section */
import React, { useState, Fragment, useEffect } from 'react';
import propTypes from 'prop-types'
/** Material UI import section */
import {
    Button,
} from '@material-ui/core';
/** Custom components import section */
import Dialog from '../Dialog'
import CropImageSelector from './CropImageSelector'
import { cropDialogStyles } from '../../../resources/styles/CropDialogStyles'



/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const UploadImageDialog = props => {
    const classes = cropDialogStyles();
    // Define local state
    const [imagenAvatarBase64, setImagenAvatarBase64] = useState(null);

    /**Get roles from user to back end */
    useEffect(() => {
        if (props.fileBase64)
            setImagenAvatarBase64(props.fileBase64)
    }, [props.fileBase64])

    /**
    * canBeSaved
    */
    const canBeSaved = () => {
        return true;
    }

    const onUploadImagen = () => {
        if (props.onUploadImagen) {
            props.onUploadImagen(imagenAvatarBase64);
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={()=>props.onClose(null)}
            isSaving={props.isSaving}
            header={
                <strong>Upload image</strong>
            }
            actions={
                <Fragment>
                    <Button
                        color="primary"
                        className={classes.buttonSave}
                        disabled={props.isSaving || !canBeSaved()}
                        onClick={onUploadImagen}
                    >
                        Save
                </Button>
                </Fragment>
            }
        >
            <CropImageSelector
                fileBase64={props.fileBase64}
            />
        </Dialog>

    );
}

UploadImageDialog.propTypes = {
    /**
     * Determine if the isSaving data in database
     */
    open: propTypes.bool,

	/**
	 * Invoke update LOGO IMAGE
	 */
    onUploadImagen: propTypes.func,
}


UploadImageDialog.defaultProps = {
    open: false,
    onUploadImagen: () =>
        console.warn('Callback [onUploadImagen] no defined'),
}


export default UploadImageDialog;