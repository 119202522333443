
import React from 'react'
import { useDashboardCardStyles } from '../resources/NetworkDayStyles'
import { Typography } from '@material-ui/core'


const CardScoreTotal = (props) => {
    const {label, total} = props;
    const classes = useDashboardCardStyles({
        mainColor: props.mainColor,
        secondaryColor: props.secondaryColor,
        height: props.height
    })
    return (
        <div className={classes.root}>
            <div className={classes.rightContainer}>
                <Typography variant='h3' className={classes.primaryText}> {total}</Typography>
                <Typography className={classes.secondaryText} variant='h5'>{label}</Typography>
            </div>
            <div className={classes.bottomBorder}></div>
        </div>
    )
}

export default CardScoreTotal