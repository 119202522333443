/**Import react section */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
/**import components section */
import AddLateralPanel from './components/AddLateralPanel'
import Toaster from '../common/Toaster'
import AddUserInCustomerPanel from './components/AddUserInCustomerPanel'

/**import actions section */
import { addCustomerFromBackEnd, addUserInCustomer } from '../../store/customers/customersActions'

const Create = (props) => {
    /**Hooks sections */
    const dispatch = useDispatch()

    /**Functions props section */
    const { onAddLateralPanelConfig, onAddUserInCustomerPanelConfig } = props

    /**Bools props section */
    const { isSaving } = props

    /**Objects props section */
    const { addPanelConfiguration, addUserInCustomerPanelConfig } = props

    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    const onAddCustomer = (customerModel) => {
        dispatch(addCustomerFromBackEnd(customerModel))
            .then(_ => {
                onAddLateralPanelConfig()
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to create customer', variant: 'warning' })
            });
    }

    const onAddUserInCustomer= (customerModel, code) => {
        dispatch(addUserInCustomer(customerModel, code))
            .then(_ => {
                onAddUserInCustomerPanelConfig()
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to create customer', variant: 'warning' })
            });
    }


    return (
        <>
            {
                (addPanelConfiguration && addPanelConfiguration.opened) &&
                <AddLateralPanel
                    onSaveButtonClick={onAddCustomer}
                    onCloseButtonClick={onAddLateralPanelConfig}
                    isSaving={isSaving}
                />
            }
                        {
                (addUserInCustomerPanelConfig && addUserInCustomerPanelConfig.opened) &&
                <AddUserInCustomerPanel
                    onSaveButtonClick={onAddUserInCustomer}
                    onCloseButtonClick={onAddUserInCustomerPanelConfig}
                    isSaving={isSaving}
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }

        </>
    )
}

Create.propTypes = {
	/**
	 * open lateral panel for add an new customer
	 */
    onAddLateralPanelConfig: propTypes.func,
    /**
	 * open lateral panel for add an new user in customer
	 */
    onAddUserInCustomerPanelConfig: propTypes.func,
    /**
	 * Determinate if is saving data
	 */
    isSaving: propTypes.bool.isRequired,
}


Create.defaultProps = {
    onAddLateralPanelConfig: () =>
        console.warn('Callback [onAddLateralPanelConfig] no defined'),
    isSaving: false
}

export default withImmutablePropsToJS(Create)
