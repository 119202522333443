import React from 'react'
import { v4 as uuidV4 } from 'uuid'
import propTypes from 'prop-types'
import {
	Table,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	TableSortLabel,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(() => ({
	tableContainer: {
		overflowY: 'auto'
	},
	cell: {
		padding: '6px 10px 6px 10px',
	},
}))

/**
 * Application table
 */
export const DataGrid = (props) => {
	const classes = useStyle();
	const hasHeaderConfiguration = props.headers.length > 0
	const hasDataItems = props.data.length > 0
	const {sortBy, sortDirection} = props;
	/**
	 * Get the visible columns to display in the datagrid
	 */
	const getVisibleColumns = (headerConfig) => headerConfig.filter((header) =>  header.visible !== false)

	/**
	 * On sort criteria change
	 * @param {*} dataSource 
	 */
	const onSortCriteriaChange = (dataSource) => {
		let updatedSortCriteria
		if (dataSource === sortBy) {
			updatedSortCriteria = {
				by: dataSource,
				direction: sortDirection === 'asc' ? 'desc' : 'asc'
			}
		} else {
			updatedSortCriteria = { by: dataSource, direction: 'desc' }
		}
		props.onChangeSortingCriteria(updatedSortCriteria)
	}

	/**
	 * Get the table's header rows
	 */
	const getTableHeadersRows = () => {
		if (hasHeaderConfiguration) {
			return getVisibleColumns(props.headers).map((header) => {
				let isSorted = sortBy === header.dataSource
				return (
					<DataGridColumn
						{...header}
						key={uuidV4()}
						isSorted={isSorted}
						sortDirection={sortDirection}
						onSortCriteriaChange={onSortCriteriaChange}
					/>
				)
			})
		} else {
			if (hasDataItems) {
				let firstitem = props.data[0]
				return Object.entries(firstitem).map((entry) => {
					return (
						<DataGridColumn
							title={entry[0]}
							dataSource={entry[0]}
							key={uuidV4()}
						/>
					)
				})
			}
		}
	}

	/**
	 * Get the columns width
	 */
	const getColumnsWidth = () => {
		if (hasHeaderConfiguration) {
			return (
				<colgroup>
					{props.headers.map((header) => {
						if (header.width) {
							return (
								<col width={`${header.width}`} key={uuidV4()} />
							)
						} else {
							return <col width='*' key={uuidV4()} />
						}
					})}
				</colgroup>
			)
		} else {
			return null
		}
	}

	/**
	 * Get the table rows
	 */
	const getTableRows = (tableColumns) => {
		if (props.isLoading) {
			let emptyArray = Array.from(Array(5).keys()).map(() => null)
			return emptyArray.map((emptyItem) => {
				return (
					<DataGridRow
						classes={classes}
						item={emptyItem}
						tableColumns={tableColumns}
						key={uuidV4()}
					/>
				)
			})
		} else {
			return props.data.filter(item => item.ParentId === null).map((item, index) => {
				const rowBackgroundColor = index %2 === 0 ? '#fff' : '#eaf3f8 ';
				return (
					<DataGridRow
						classes={classes}
						item={item}
						tableColumns={tableColumns}
						onItemClick={props.onItemClick}
						isExpandedPanel={props.isExpandedPanel}
						expandedPanelComponent={props.expandedPanelComponent}
						key={uuidV4()}
						data={props.data}
						rowBackgroundColor={rowBackgroundColor}
					/>
				)
			})
		}
	}

	let headerRows = getTableHeadersRows()
	let tableRows = getTableRows(headerRows)

	return (
		<TableContainer>
			<Table size='small'>
				{getColumnsWidth()}
				<TableHead>
					<TableRow>{headerRows}</TableRow>
				</TableHead>
				<TableBody className={classes.tableContainer}>{tableRows}</TableBody>
			</Table>
		</TableContainer>
	)
}
DataGrid.propTypes = {
	/**
	 * The data displayed in the table
	 */
	data: propTypes.arrayOf(propTypes.object).isRequired,

	/**
	 * The configuration of the tables
	 */
	headers: propTypes.arrayOf(
		propTypes.shape({
			title: propTypes.string.isRequired,
			dataSource: propTypes.string,
			sortable: propTypes.bool,
			onRenderProperty: propTypes.func,
			width: propTypes.any,
			visible: propTypes.bool,
		})
	),

	/**
	 * Defines the initial sort column
	 */
	sortBy: propTypes.string,
	/**
	 * Defines the initial sort direction
	 */
	sortDirection: propTypes.oneOf(['asc', 'desc']),

	/**
	 * Determine if the datagrid is in loading mode. When the loading mode is active, the datagrid show a skeleton
	 */
	isLoading: propTypes.bool.isRequired,
	/**
	 * Callback executed when the sorting criteria has been changed
	 */
	onChangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * Callback executed when the user click on a item row
	 */
	onItemClick: propTypes.func,
	/**
	 * Determines if one more row is rendered to expand the row and display the expanded panel
	 */
	isExpandedPanel: propTypes.bool,
}
DataGrid.defaultProps = {
	data: [],
	headers: [],
	sortBy: null,
	isLoading: false,
	sortDirection: 'desc',
	onChangeSortingCriteria: () =>
		console.warn('Callback [onChangeSortingCriteria] no defined'),
}

export default DataGrid

/**
 * Table header cell
 */
const DataGridColumn = (props) => {
	let { title, dataSource, sortable, isSorted, sortDirection } = props

	return (
		<TableCell variant='head'>
			{sortable ? (
				<TableSortLabel
					active={isSorted}
					direction={sortDirection == null ? 'desc': sortDirection}
					onClick={() => props.onSortCriteriaChange(dataSource)}
				>
					{title}
				</TableSortLabel>
			) : (
				title
			)}
		</TableCell>
	)
}
DataGridColumn.propTypes = {
	title: propTypes.string.isRequired,
	dataSource: propTypes.string,
	sortable: propTypes.bool,
	isSorted: propTypes.bool,
	sortDirection: propTypes.string,
	onSortCriteriaChange: propTypes.func.isRequired
}
DataGridColumn.defaultProps = {
	title: '',
	sortable: false,
	isSorted: false,
	sortDirection: 'desc',
	onSortCriteriaChange: () =>
		console.warn('No [onSortLabelClick] callback defined')
}

/**
 * Item table row component
 */
const DataGridRow = (props) => {
	const { item, tableColumns, rowBackgroundColor, classes } = props

	/**
	 * Handle the onItemClick callback
	 */
	const onItemClick = (sender, item) => {
		if (props.onItemClick) props.onItemClick(sender, item)
	}
	function getChildren(parent) {
		if (props.data) {

		  return props.data.filter(item => item.ParentId === parent.Id);
		} else {
		  return [];
		}
	}
	/**
	 * Get the cell content
	 */
	const getCellContent = (column, item) => {
		/**
		 * Check if the column has an override callback
		 */
		let value = column.props.onRenderProperty
			? column.props.onRenderProperty(item)
			: item[column.props.dataSource]

		// Validate if boolean
		if (typeof value === 'boolean') value = value.toString()
		return value
	}

	if (item === null) {
		return (
			<TableRow>
				{tableColumns.map(() => {
					return (
						<TableCell key={uuidV4()}>
							<Skeleton animation='wave' />
						</TableCell>
					)
				})}
			</TableRow>
		)
	} else if (props.isExpandedPanel) {
		return (
			<>
				<TableRow hover onClick={(sender) => onItemClick(sender, item)}>
					{tableColumns.map((column) => {
						let value = getCellContent(column, item)
						return <TableCell key={uuidV4()}>{value}</TableCell>
					})}
				</TableRow>
				{props.expandedPanelComponent(item)}
			</>
		)
	} else {
		return (
			<React.Fragment key={item.Id}>
				<TableRow hover onClick={(sender) => onItemClick(sender, item)} style={{backgroundColor: rowBackgroundColor}}>
					{tableColumns.map((column) => {
						let value = getCellContent(column, item)
						return <TableCell className={classes.cell} key={uuidV4()}>{value}</TableCell>
					})}
				</TableRow>
				{getChildren(item).map((child) => (
					<TableRow key={child.Id} style={{backgroundColor: rowBackgroundColor}}>
						{tableColumns.map((column) => {
							// Filtra solo las columnas que deseas renderizar para los hijos
							if (["Warehouse", "Partial Quantity", "Tracking number", "Carrier", "ETA", "Options", "Status" ].includes(column.props.title)) {
								let childValue = getCellContent(column, child); 
								return (
									<TableCell className={classes.cell} key={uuidV4()}>
										{childValue}
									</TableCell>
								);
							} else {
								return <TableCell className={classes.cell} key={uuidV4()} />;
							}
						})}
					</TableRow>
				))}
			</React.Fragment>
		)
	}
}
DataGridRow.props = {
	item: propTypes.object,
	tableColumns: propTypes.arrayOf(propTypes.element),
	onItemClick: propTypes.func,
	isExpandedPanel: propTypes.bool
}