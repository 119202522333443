import React, { useEffect, useState } from 'react'
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import Pagination from '../../common/Pagination/Pagination'
import { sortBy } from '../../../store/helpers/SortingHelper'

const FirstStepView = (props) => {
    const {
        classes,
        selectedItems,
        filteredData,
        getSearchHeaderConfiguration
    } = props

    // pagination props
    const [allFilteredData, setAllfilteredData] = useState([])

    const [pagination, setPagination] = useState({ pageSize: 5, total: 0 })
    const [currentPage, setCurrentPage] = useState(1)

    const onChangePage = (event, page) => {
		setCurrentPage(page)
	}
    useEffect(() => {
		let filteredDataPerPage = 10
		let total = allFilteredData.length / filteredDataPerPage
		let allTotal = Math.round(Math.ceil(total))
		const indexOfLastFilteredData = currentPage * filteredDataPerPage
		let indexOfFirtsFilteredData = indexOfLastFilteredData - filteredDataPerPage
		let currentFilteredData = allFilteredData.slice(
			indexOfFirtsFilteredData,
			indexOfLastFilteredData
		)
		setAllFilteredDataPaginated(currentFilteredData)
		setPagination({ pageSize: filteredDataPerPage, total: allTotal })
	}, [allFilteredData, pagination.total, currentPage])

    const [sortingCriteria, setSortCriteria] = useState({
		by: 'Gae',
		sortDirection: 'asc'
	})
    const [allFilteredDataPaginated, setAllFilteredDataPaginated] = useState([])
    const onChangeSortingCriteria = (criteria) => {
		let resultSort = sortBy(
			filteredData,
			criteria.by,
			criteria.direction
		)
		setAllfilteredData(resultSort)
		setSortCriteria({
			by: criteria.by,
			sortDirection: criteria.direction
		})
	}

    useEffect(() => {
        setAllfilteredData(filteredData);
    }, [filteredData]);
  return (

    <div>
	    <DataGrid
	        headers={getSearchHeaderConfiguration(selectedItems)}
	        data={allFilteredDataPaginated}
            onChangeSortingCriteria={
                onChangeSortingCriteria
            }	
            sortBy={sortingCriteria.by}
            sortDirection={
                sortingCriteria.sortDirection
            }
	    />
        <div className={classes.pagination}>
	        <div className={classes.flexGrow} />
	        <Pagination
				count={
				pagination.total
				? pagination.total
				: 0
			    }
			    page={currentPage ? currentPage : 0}
				onChangePage={onChangePage}
		    />
	    </div>
	</div>											
												
    )
}

export default FirstStepView