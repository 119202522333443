import { makeStyles } from '@material-ui/styles'

/**
 * custom lateral panel
 */
export const profileStyle = makeStyles((theme) => ({
    container:{
        maxWidth: "1000px", 
        marginLeft: "auto", 
        marginRight: "auto", 
        marginTop: "10px"
    },
    root: {
        display: 'grid',
        gridTemplateRows: '1fr, auto',
    },
    profileRowTwo: {
        display: 'grid',
        gridRow: 2,
        columnGap: '10px',
        gridTemplateColumns: '1fr 2fr',

    },
    header: {
        gridRow: 1,
    },
    panelLeft: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        backgroundColor: theme.palette.colors.purpleDark,
        padding: "10px",
        maxHeight: `calc(100vh - ${160}px)`,
        minHeight: `calc(100vh - ${160}px)`,
        overflowY: "auto"
    },
    panelLeftSkeleton: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        padding: "10px",
        maxHeight: `calc(100vh - ${160}px)`,
        minHeight: `calc(100vh - ${160}px)`,
        overflowY: "auto"
    },
    panelRight: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        backgroundColor: theme.palette.common.white,
        padding: "10px",
        maxHeight: `calc(100vh - ${160}px)`,
        minHeight: `calc(100vh - ${160}px)`,
        overflowY: "auto"
    },
    rolesList: {
        backgroundColor: theme.palette.serviceStatus.waitingSparePartsDark,
        color: theme.palette.common.white,
        marginTop: 7,
        borderRadius: "3px", 
        height:"38px"
    },
    check: {
        color: theme.palette.common.white,
        '&.Mui-checked': {
            color: theme.palette.common.white,
          },
    },
    buttonEdit: {
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.blue,
        "&:hover": {
            backgroundColor: theme.palette.colors.blue
        },
        width: "37px",
        height: "36px",
        margin: 0,
    },
    buttonSaved: {
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.green,
        "&:hover": {
            backgroundColor: theme.palette.colors.green
        },
        width: "37px",
        height: "36px",
        margin: 0,
        "&.Mui-disabled": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colors.green,
            opacity: .5,
        },
        marginLeft: theme.spacing(1)
    },
    buttonCancel:{
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.red,
        "&:hover": {
            backgroundColor: theme.palette.colors.red
        },
        width: "37px",
        height: "36px",
        margin: 0,
        "&.Mui-disabled": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colors.red,
            opacity: .5,
        },
        marginLeft: theme.spacing(1)
    },
    buttonContainer:{
        display: "flex", 
        justifyContent: "flex-end"
    },
    avatarContainer:{
        display: "flex", 
        justifyContent: "center"
    },
    subHeader:{
        color: theme.palette.colors.purple,
        backgroundColor: theme.palette.common.white,
    }

}))