/**Import react section */
import React from 'react'
import { v4 as uuid4 } from 'uuid'
import propTypes from 'prop-types'

/**Import material UI section */
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EditAvatarIcon from '@material-ui/icons/Create';

/**Import resouces section */
import '../../resources/styles/avatar.css'

/**Import store sections */
import { isNullOrEmptyAvatar } from '../../store/helpers/StringHelper';

const AvatarComponent = (props) => {
    const { avatarUrl, onSetLogoFileBase64, name, onSetHandleOpenModalUploadImg, isAvatarFromIdentity } = props;
    const classes = useAvatarStyles();
    const firstLetterName = name ? name.charAt(0).toUpperCase() : "A";
    const uploadLogoFileRef = React.createRef();

    const getAvatarUrl = () => {
        if (!isNullOrEmptyAvatar(avatarUrl) && isAvatarFromIdentity) {
            const { identityServerUrl } = window.config
            let urlImage = `${identityServerUrl}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuid4();
            return logoUrl;
        }
        else if (!isNullOrEmptyAvatar(avatarUrl)) {
            const { serviceManagementBackendUrl } = window.config
            let urlImage = `${serviceManagementBackendUrl}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuid4();
            return logoUrl;
        }
        else
            return null
    }

    const onUploadLogoFile = () =>{
        if(onSetHandleOpenModalUploadImg){
            uploadLogoFileRef.current.click();
        }
    }

    const onSelecteFile = (fileBase64) => {
        if (onSetLogoFileBase64)
            onSetLogoFileBase64(fileBase64)
    }

    const uploadLogoFile = (file) => {
        let fileUpload = file.target.files[0];
        /// Determine if the image to add not is undefined or null
        if (fileUpload !== undefined && fileUpload !== null) {
            var myFileItemReader = new FileReader();
            myFileItemReader.addEventListener("load", () => {
                const myResult = myFileItemReader.result;
                onSelecteFile(myResult);
                onSetHandleOpenModalUploadImg();

            }, false)
            myFileItemReader.readAsDataURL(fileUpload)
        }
    }

    return (
        <>
            <Avatar
                alt="Avatar"
                src={getAvatarUrl()}
                className="container"
                onClick={onUploadLogoFile}
            >
                <span styles={{ fontSize: 40 }}>{isNullOrEmptyAvatar(avatarUrl) ? firstLetterName.toUpperCase() : null}</span>
                <div className="overlay">
                    <EditAvatarIcon className="icon" />
                </div>

            </Avatar>
            <input className={classes.logoFileInput}
                type='file'
                accept="image/*"
                ref={uploadLogoFileRef}
                multiple={false}
                onChange={uploadLogoFile}
            />
        </>
    )
}

AvatarComponent.propTypes = {
    avatarUrl: propTypes.string,
    name: propTypes.string.isRequired,
    onSetHandleOpenModalUploadImg: propTypes.func,
    isAvatarFromIdentity: propTypes.bool.isRequired
}

AvatarComponent.defaultProps = {
    name: "name",
    isAvatarFromIdentity:false
}


const useAvatarStyles = makeStyles((theme) => ({
    root: {
        marginTop: "10px"
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    subtitle: {
        backgroundColor: '#D0DEED'
    },
    logoFileInput: {
        display: "none",
    },
}))

export default AvatarComponent;