import { makeStyles } from '@material-ui/styles'

/**
 * One column view styles
 */
export const existencesTableStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.colors.grayLayout,
      paddingBottom:"100px"
    },
    btnGetExistence:{
      marginTop: "10px",
      marginBottom: "10px",
    },
    noExistStock:{
      color:theme.palette.colors.red
    },
    stock:{
      color:theme.palette.colors.green
    }
}))