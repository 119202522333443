import React from 'react';

/* Import from MUI */
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';

import { salesRepresentativeStyles } from '../../resources/styles/SalesRepresentativeStyles';

export const TaxClassCheckbox = (props) => {
    const { taxClassValue, onPropertyChange } = props

    const classes = salesRepresentativeStyles();

    return (
        <div className={classes.controlRadioContainer}>
            <FormControl component="fieldset">
                <RadioGroup aria-label="taxClass" name="TaxClass" value={taxClassValue ? taxClassValue : ''} onChange={onPropertyChange}>
                    <Typography style={{ color: "white" }} variant="subtitle1">Federal Tax Classification</Typography>
                    <FormControlLabel value="Individual" control={<Radio />} label={<Typography>Individual/sole proprietor or single-member LLC</Typography>} />
                    <FormControlLabel value="CCorporation" control={<Radio />} label={<Typography>C Corporation</Typography>} />
                    <FormControlLabel value="SCorporation" control={<Radio />} label={<Typography>S Corporation</Typography>} />
                    <FormControlLabel value="Partnership" control={<Radio />} label={<Typography>Partnership</Typography>} />
                    <FormControlLabel value="TrustEstate" control={<Radio />} label={<Typography>Trust/estate</Typography>} />
                    <FormControlLabel value="LimitedLiability" control={<Radio />} label={<Typography>Limited liability company</Typography>} />
                    <FormControlLabel value="Other" control={<Radio />} label={<Typography>Other</Typography>} />
                </RadioGroup>
            </FormControl>
        </div>
    )
}