/**Import react section */
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import propTypes from 'prop-types'
/**Import components section */
import TextBox from '../../common/TextBox'
import TabsWrapper from '../../common/TabsWrapper'
import PanelHeader from '../../common/lateralPanel/PanelHeaderIcon'
import TimeLineCarrier from './TimeLineCarrier'
import PackageTracking from './PackageTracking'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import Select from '../../common/Selector'

/**Import MaterialUi section */
import { Grid, Typography, Drawer } from '@material-ui/core'
/**Import resources section */
import {
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX
} from '../../../resources/RolesEnum'
import { WarehouseType } from '../../../store/helpers/AppConstants'
import { hasAuthorization } from '../../../store/session/sessionActions'
import { panelEditUseStyles } from '../resources/useStyles'
import { GetStatusTracking } from '../../../store/helpers/SelectOptions'
import iconFailureDoor from '../../../resources/images/failureWarranty/doorWhite.svg'

/**
 * Panel to edit information traking
 * @param {} props
 */
const EditPanel = (props) => {
	const classes = panelEditUseStyles()
	let match = useRouteMatch()
	const token = match.params.token
	const statusOptions = GetStatusTracking()
	const {
		onUpdateSpartPart,
		isSaving,
		onCloseButtonClick,
		userProfile,
		onCheckShipping,
		isGettingTrackingData
	} = props
	const [partModel, setPartModel] = useState(null)
	const [carrierData, setCarrierData] = useState(null)
	let isAuthorizerCanEditRoleImberaMx = hasAuthorization(userProfile, [
		SPARE_PARTS_IMBERA_MX
	])
	let isAuthorizerCanEditRoleImberaUsa = hasAuthorization(userProfile, [
		SPARE_PARTS_IMBERA_USA
	])

	let canBeEdit =
		(isAuthorizerCanEditRoleImberaMx &&
			partModel &&
			partModel.Warehouse === WarehouseType.MEXICO) ||
		(isAuthorizerCanEditRoleImberaUsa &&
			partModel)
	//partModel.Warehouse === WarehouseType.USA)

	useEffect(() => {
		setPartModel(props.part)
		setCarrierData(props.carrierData)
	}, [props.part, props.carrierData])

	/**
	 * Update comments
	 */
	const updateSpartPart = () => {
		if (onUpdateSpartPart) {
			onUpdateSpartPart(partModel, 'STATUS')
		}
	}

	/**
	 * Function ot update status traking
	 * @param {*} name
	 * @param {*} value
	 */
	const updateStatusTraking = (name, value) => {
		partModel[name] = value
		setPartModel({
			...partModel,
			[name]: value
		})
		if (onUpdateSpartPart) {
			onUpdateSpartPart(partModel)
		}
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setPartModel({
			...partModel,
			[name]: value
		})
	}

	const tabsConfig = [
		{
			code: 'comments',
			label: 'COMMENTS',
			content: ''
		},
		{
			code: 'TrackingNumber',
			label: 'DETAIL VIEW',
			content: '',
			disabled: !(
				carrierData &&
				carrierData.origin_info &&
				carrierData.origin_info.trackinfo
			)
		}
	]
	/**Consult tracking number */
	const consultDataByTrackingnNumber = () => {
		if (onCheckShipping) {
			onCheckShipping(partModel.Carrier, partModel.NumberTracking, token)
		}
	}

	const onRenderTabContent = (tab) => {
		let tabContent = null
		switch (tab.code) {
			case 'comments': {
				tabContent = (
					<Grid item xs={12} className={classes.editTab}>
						<TextBox
							active={true}
							disabled={isSaving || !canBeEdit}
							fullWidth
							autoFocus
							multiline
							rows={4}
							name='Comments'
							variant='outlined'
							margin='dense'
							label='COMMENTS'
							onChange={onPropertyChange}
							value={partModel ? partModel.Comments : ''}
						/>
					</Grid>
				)
				break
			}
			case 'TrackingNumber': {
				tabContent = carrierData &&
					carrierData.origin_info &&
					carrierData.origin_info.trackinfo && (
						<>
							<TimeLineCarrier
								shippingHistory={
									carrierData
										? carrierData.origin_info.trackinfo
										: []
								}
							/>
						</>
					)
				break
			}
			default: {
				tabContent = null
				break
			}
		}
		return tabContent
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				title={`TRACKING DETAILS`}
				onSaveButtonClick={updateSpartPart}
				onCloseButtonClick={onCloseButtonClick}
				canBeSaved={canBeEdit}
				icon={iconFailureDoor}
				isIconSvgOrPng={true}
			/>
			<Grid container>
				<Grid item xs={12} className={classes.titleContainer}>
					<div className={classes.titleLeftContent}>
						<div className={classes.flex}>
							<Typography
								variant='subtitle1'
								className={classes.titleBold}
							>{`Status: `}</Typography>
							{partModel && (
								<div style={{ width: '150px' }}>
									<Select
										options={statusOptions}
										isDisabled={!canBeEdit}
										value={
											partModel.Status
												? statusOptions.find(
													(x) =>
														x.label ===
														partModel.Status
												)
												: null
										}
										onChange={(option) =>
											updateStatusTraking(
												'Status',
												option.label
											)
										}
										margin='dense'
									/>
								</div>
							)}
						</div>
					</div>
					{partModel && partModel.NumberTracking && (
						<Grid
							item
							xs={12}
							className={classes.titleRightContent}
						>
							<Grid
								container
								spacing={1}
								style={{
									display: 'flex',
									alignSelf: 'center',
									alignItems: 'center'
								}}
							>
								<Grid item xs={9}>
									<TextBox
										active={true}
										disabled={true}
										fullWidth
										autoFocus
										name='NumberTracking'
										variant='outlined'
										margin='dense'
										label='Tracking number'
										onChange={onPropertyChange}
										value={
											partModel
												? partModel.NumberTracking
												: ''
										}
									/>
								</Grid>
								{!partModel.IsOtherCarrier &&
									partModel.Carrier && (
										<Grid item xs={3}>
											<ButtonSaved
												title={'Search'}
												onSavedClick={
													consultDataByTrackingnNumber
												}
												isSaving={isGettingTrackingData}
											/>
										</Grid>
									)}
							</Grid>
							<br />
						</Grid>
					)}
				</Grid>
				<Grid item xs={12}>
					{carrierData && (
						<PackageTracking carrierData={carrierData} />
					)}
				</Grid>
				<Grid item xs={12}>
					<TabsWrapper
						tabs={tabsConfig}
						onRenderTabContent={onRenderTabContent}
						className={classes.tabs}
					/>
				</Grid>
			</Grid>
		</LateralPanel>
	)
}

const LateralPanel = (props) => {
	const { onClose, children } = props

	const classes = panelEditUseStyles()

	return (
		<Drawer anchor='right' open={props.open} onClose={onClose}>
			<div className={classes.drawerContent} tabIndex={0}>
				{children}
			</div>
		</Drawer>
	)
}
LateralPanel.propTypes = {
	open: propTypes.bool.isRequired
}
LateralPanel.defaultProps = {
	open: true
}

export default EditPanel
