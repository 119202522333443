/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types'

/**Import Material UI section */
import {
    CircularProgress,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    ListSubheader
} from '@material-ui/core'

/**Import helpers section */
import { getAvailableRolesByUserType } from '../../../../store/helpers/RoleHelper'
import { isUserInRole } from '../../../../store/helpers/UserHelper';
import { profileStyle } from '../../../../resources/styles/ProfileStyles'

/**Import components section */
import AlertComponent from '../../../common/AlertComponent'
const RolesForm = (props) => {
    const classes = profileStyle();
    const [selectedRole, setSelectedRole] = useState(null);

    /**Functions */
    const { onToggleUserRole } = props;
    /**Arrays */
    const { roles } = props;

     /**string */
     const { type } = props;

    /**bool */
    const { isSaving, isActiveCheck } = props;


    const disabled = props.disabled ? true : false;
    const cursor = !isActiveCheck ? 'not-allowed' : 'pointer';
    
    let allRoles = getAvailableRolesByUserType(type, roles);


    /**This function Active or disabled roles */
    const onToggleRoleChange = event => {
        let roleName = event.currentTarget.id;
        let findRole = allRoles.find(r=>r.key===roleName)

        if (!disabled && !isSaving && onToggleUserRole) {
            setSelectedRole(roleName);
            onToggleUserRole(roleName, findRole.type)
        }
    }

    return (
        <List subheader={
            <ListSubheader className={classes.subHeader}>
                <div>
                    <Typography variant="subtitle2" className={classes.titleList}>
                        <strong>Permissions</strong>
                    </Typography>
                </div>
            </ListSubheader>}>
            {
                allRoles.length!==0 ? allRoles.map(roleOption => {
                    let isUserInRoleOption = isUserInRole(roleOption.key, (roles));
                    let showSpinner = isSaving && selectedRole === roleOption.key;
                    let isDisabled = disabled || props.isSaving;
                    return (
                        <ListItem
                            dense
                            id={roleOption.key}
                            key={roleOption.key}
                            className={classes.rolesList}
                            onClick={onToggleRoleChange}
                            disabled={isDisabled}
                            style={{ cursor: cursor }}
                        >
                            {
                                (isUserInRoleOption || isActiveCheck) ?
                                    <ListItemIcon>
                                        {
                                            {
                                                true: <CircularProgress className={classes.circularProgress} size={30} />,
                                                false: <Checkbox
                                                    className={classes.check}
                                                    disabled={isDisabled || isActiveCheck}
                                                    style={{ cursor: cursor }}
                                                    checked={isUserInRoleOption}
                                                />
                                            }[showSpinner]
                                        }

                                    </ListItemIcon> : <ListItemIcon />

                            }
                            <ListItemText
                                primary={<Typography variant="subtitle2"><strong>{roleOption.label}</strong></Typography>}
                            />
                        </ListItem>
                    )
                }):<AlertComponent
                    title="Information"
                    severityType="info"
                    message="There are no added roles"
                />

            }
        </List>
    )
}

RolesForm.propTypes = {
	/**
	 * The user roles's list
	 */
    roles: propTypes.arrayOf(
        propTypes.shape({
            ClaimType: propTypes.string.isRequired,
            ClaimValue: propTypes.string.isRequired,
        })
    ),

	/**
	 * Determine if the role is saving
	 */
    isSaving: propTypes.bool.isRequired,
    /**Disabled check box */
    disabled: propTypes.bool.isRequired,
    /**Determinate if checkBox is active */
    isActiveCheck: propTypes.bool.isRequired,
    /**Determinate if checkBox is active */
    onToggleUserRole: propTypes.func,
}
RolesForm.defaultProps = {
    /**All roles from user logged */
    roles: [],
    /**Determine if the role is saving */
    isSaving: false,
    /**Disabled check box whent is saving role in database*/
    disabled: false,
    /**Determinate if checkBox is active */
    isActiveCheck: true,
}

export default RolesForm
