import React from 'react'
import propTypes from 'prop-types'
import { AppBar, Toolbar } from '@material-ui/core'

import {
	useBaseHeaderStyles as useStyles,
	useAuthorizedToolbarStyles as authUserstyles
} from '../../resources/styles/ApplicationHeaderStyles'

/**
 * Layout header component
 */
const ApplicationHeader = (props) => {
	const classes = useStyles()
	const authClasses = authUserstyles()

	return (
		<AppBar position='static'>
			<Toolbar className={authClasses.toolbar}>
				<div className={classes.root}>
					<div className={classes.leftContainer}>
						{props.leftToolbar}
					</div>
					<div className={classes.rightContainer}>
						{props.rightToolbar}
					</div>
				</div>
			</Toolbar>
		</AppBar>
	)
}
ApplicationHeader.propTypes = {
	/**
	 * Displays a toolbar in the left side of the header. You can pass a simple or composed component
	 */
	leftToolbar: propTypes.element,

	/**
	 * Displays a toolbar in the right side of the header. You can pass a simple or composed component
	 */
	rightToolbar: propTypes.element
}

export default ApplicationHeader
