/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/**Import material UI section */
import { Card, Typography } from '@material-ui/core';
import { useCardStyles } from '../../../resources/styles/mobile/CardStyle'
const CardComponent = (props) => {
    const { title, changeHeaderColor, onRenderContent, item, onCardClick, onRenderContentRight, } = props;
    const classes = useCardStyles();

    return (
        <Card className={classes.root} onClick={() => onCardClick(item)} key={item.Token}>
            <div className={changeHeaderColor ? classes.headerNoDate : classes.header}>
                <div className={classes.titleHeader}>
                    <Typography noWrap >{title}</Typography>
                    <div className={classes.flexGrow} />
                    {
                        onRenderContentRight && <div className={classes.icon}>
                            {onRenderContentRight()}
                        </div>
                    }
                </div>

            </div>
            <div className={classes.content}>
                {onRenderContent()}
            </div>
        </Card>
    )
}

CardComponent.propTypes = {
    /**Render title left header card */
    title: propTypes.string.isRequired,
    /**Function render contentCard*/
    onRenderContent: propTypes.func.isRequired,
    /**On CarClick to edit*/
    onCardClick: propTypes.func.isRequired,
    /**item identifier card*/
    item: propTypes.object.isRequired,
}

CardComponent.defaultProps = {
    title: "",
    item: {},
    onRenderContent: () =>
        console.warn('Callback [onRenderContent] no defined'),
    onCardClick: () =>
        console.warn('Callback [onCardClick] no defined'),
}

export default CardComponent
