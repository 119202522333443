import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'
import { mapUserServerResponse, getFullUsersInformation, mapCombineUserFromIdentity } from '../users/UsersActions'

export const IS_LOADING_CUSTOMERS = 'IS_LOADING_CUSTOMERS'
export const SET_ALL_CUSTOMERS = 'SET_ALL_CUSTOMERS'
export const IS_SAVING = 'IS_SAVING'
export const ADD_CUSTOMER = "ADD_CUSTOMER"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const SET_CUSTOMER_QUERYPARAMETERS = "SET_CUSTOMER_QUERYPARAMETERS"
export const SET_CUSTOMERS_USERS = "SET_CUSTOMERS_USERS"
export const ADD_USER_IN_CUSTOMER = "ADD_USER_IN_CUSTOMER"
export const REMOVE_USER_FROM_ACCOUNT = 'REMOVE_USER_FROM_ACCOUNT'
export const SET_ROLES_IN_PROFILE = 'SET_ROLES_IN_PROFILE'
export const UPDATE_CUSTOMER_USERS = 'UPDATE_CUSTOMER_USERS'
export const UPDATE_LAST_DATE_USER_CUSTOMER = "UPDATE_LAST_DATE_USER_CUSTOMER"
export const UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_CUSTOMER = "UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_CUSTOMER"

/**
 * Is loading action customers
 */
export const isLoadingCustomers = (status) => {
    return {
        type: IS_LOADING_CUSTOMERS,
        status
    }
}

/**
 * Set customers action 
 */
export const setAllCustomers = (data) => {
    return {
        type: SET_ALL_CUSTOMERS,
        data
    }
}

/**
 * Is saving
 */
export const isSaving = (status) => {
    return {
        type: IS_SAVING,
        status
    }
}

export const addCustomer = (customer) => {
    return {
        type: ADD_CUSTOMER,
        customer: customer
    }
}

/*
Update sales representative* */
export const setUpdateCustomer = (customer) => {
    return {
        type: UPDATE_CUSTOMER,
        customer: customer
    }
}

/**
 * Set internal user sort criteria
 */
export const setInternalQueryParameters = (queryParametes) => {
    return {
        type: SET_CUSTOMER_QUERYPARAMETERS,
        queryParametes
    }
}

/**
 * Set all CUSTOMERS users
 */
export const setCustomersUsers = (users) => {
    return {
        type: SET_CUSTOMERS_USERS,
        users
    }
}

export const setUserInCustomer = (user) => {
    return {
        type: ADD_USER_IN_CUSTOMER,
        user
    }
}

export const updateCustomersUser = (user) => {
    return {
        type: UPDATE_CUSTOMER_USERS,
        user
    }
}

/**
 * Set last date User
 */
export const updateLastDateUser = (user) => {
    return {
        type: UPDATE_LAST_DATE_USER_CUSTOMER,
        user
    }
}

/** 
 * Set the notifications state
*/
export const updateActiveNotifications = (user) => {
    return {
        type: UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_CUSTOMER,
        user
    }
}


export const loadAllCustomers = (queryParameters, pageSize = 10) => {
    return function (dispatch, getState) {
        let loadedCustomers = getState().customers.get('customers')
        if (loadedCustomers && loadedCustomers.size > 0 && queryParameters === {}) {
            return Promise.resolve()
        }

        dispatch(isLoadingCustomers(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/customers`
        let command = {
            PageNumber: queryParameters ? queryParameters.pageNumber : 1,
            PageSize: pageSize,
            OrderBy: queryParameters ? queryParameters.sortBy : null,
            OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
            Query: queryParameters && (!!queryParameters.query ? queryParameters.query : "")
        }
        return axios.post(url, command, config)
            .then(serverResponse => {
                dispatch(isLoadingCustomers(false));
                if (serverResponse.status === 200) {
                    dispatch(setAllCustomers(serverResponse.data));
                }
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isLoadingCustomers(false));
                console.error("Error loading all customers", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const addCustomerFromBackEnd = (customerModel) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/customers/add`
        let command = {
            Name: customerModel ? customerModel.Name : "",
            Code: customerModel ? customerModel.Code : "",
        }
        return axios.post(url, command, config)
            .then(serverResponse => {
                dispatch(isSaving(false))
                if (serverResponse.status === 200) {
                    dispatch(addCustomer(serverResponse.data));
                }
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to add customer", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const updateCustomer = (representativeModel) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/customers`
        let command = {
            Token: representativeModel ? representativeModel.Token : "",
            Code: representativeModel ? representativeModel.Code : "",
            Name: representativeModel ? representativeModel.Name : "",

        }
        return axios.put(url, command, config)
            .then(serverResponse => {
                dispatch(isSaving(false))
                if (serverResponse.status === 200) {
                    dispatch(setUpdateCustomer(serverResponse.data));
                }
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to update customer", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const loadUserInCustomer = (queryParameters, code) => {
    return async (dispatch, getState) => {
        try {
            const loadUsersFromBackend = async (queryParameters) => {
                dispatch(isLoadingCustomers(true))
                // Get the authorized configuration
                let user = getState().oidc.user
                let config = getAuthorizedConfiguration(user)
                // Create command
                let command = {
                    AccountCode: code,
                }
                // Get the users
                let url = `${window.config.serviceManagementBackendUrl}/users/account`
                let serverResponse = await axios.post(url, command, config)
                let usersResult = mapUserServerResponse(serverResponse.data)
                await getFullUsersInformation(usersResult, null)
                dispatch(setCustomersUsers(usersResult))
                if (queryParameters) {
                    dispatch(setInternalQueryParameters(queryParameters))
                }
            }
            await loadUsersFromBackend(queryParameters)

        } catch (ex) {
            console.error('Errog getting the internal users in customers list', ex)
        }
    }
}

export const addUserInCustomer = (userModel, code) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let command = {
            Name: userModel ? userModel.Name : "",
            AccountCode: code,
            UserName: userModel ? userModel.Email : "",
            Email: userModel ? userModel.Email : "",
            Password: userModel ? userModel.Password : "",
            PhoneNumber: userModel ? userModel.Password : "",
        }
        const { identityServerUrl, serviceManagementBackendUrl } = window.config
        let url = `${serviceManagementBackendUrl}/user`
        let urlIdentityServer = `${identityServerUrl}/api/externaluser`
        let identityUser;
        return axios.post(urlIdentityServer, command)
            .then(identityUserResponse => {
                identityUser = identityUserResponse.data;
                command.UserName = identityUser.user_name
                return axios.post(url, command, config)
            })
            .then(serverResponse => {
                dispatch(isSaving(false))
                let mappedUser = mapCombineUserFromIdentity(serverResponse.data, identityUser)
                dispatch(setUserInCustomer(mappedUser));
                return Promise.resolve(mappedUser);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to add user in customer", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const lockedCustomer = (token, locked) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/customers/locked`
        let command = {
            Token: token,
            Locked: locked
        }
        return axios.put(url, command, config)
            .then(serverResponse => {
                dispatch(isSaving(false))
                if (serverResponse.status === 200) {
                    dispatch(setUpdateCustomer(serverResponse.data));
                }
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false))
                console.error("Error to lock customer", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

/**Upload imagen account */
export const uploadLogoAccount = (token, fileBase64) => {
    return function (dispatch, getState) {
        dispatch(isSaving(true))
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/customers/uploadAvatar`
        let command = {
            FileParameter: fileBase64,
            Token: token
        };
        return axios.put(url, command, config)
            .then((serverResponse) => {
                dispatch(isSaving(false));
                dispatch(setUpdateCustomer(serverResponse.data));
                return Promise.resolve(serverResponse.data);
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error uploading account avatar", error);
                return Promise.reject();
            });
    };
};

/**Upload imagen dialog user */
export const uploadLogoExternalUser = (userId, fileBase64, token) => {
    return function (dispatch, getState) {
        const { identityServerUrl, serviceManagementBackendUrl } = window.config
        dispatch(isSaving(true))
        let endPoint = `${identityServerUrl}/api/user/${userId}/avatar`;
        let data = { fileBase64 };
        let identityUser;
        let userConfig = getState().oidc.user
        let config = getAuthorizedConfiguration(userConfig)
        let url = `${serviceManagementBackendUrl}/user/profile/${token}`;
        return axios.put(endPoint, data)
            .then((identityUserResponse) => {
                dispatch(isSaving(false));
                identityUser = identityUserResponse.data;
                return axios.get(url, config)
            })
            .then((managerResponse) => {
                dispatch(isSaving(false));
                let mappedUser = mapCombineUserFromIdentity(managerResponse.data, identityUser)
                dispatch(updateCustomersUser(mappedUser));
                return Promise.resolve(mappedUser);
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error uploading user avatar", error);
                return Promise.reject();
            });
    };
};

/**save information internal user */
export const saveExternalUser = (user) => {
    return function (dispatch, getState) {
        // Get the authorized configuration
        let userConfig = getState().oidc.user
        let config = getAuthorizedConfiguration(userConfig)
        const { identityServerUrl, serviceManagementBackendUrl } = window.config;
        dispatch(isSaving(true))
        let identityCommand = {
            Email: user.Email,
            Name: user.Name,
            PhoneNumber: user.PhoneNumber
        };
        let endPoint = `${identityServerUrl}/api/user/${user.Id}`;
        let url = `${serviceManagementBackendUrl}/user/profile/${user.Token}`;
        let identityUser;
        return axios.put(endPoint, identityCommand)
            .then((identityUserResponse) => {
                identityUser = identityUserResponse.data;
                return axios.get(url, config)
            })
            .then((managerResponse) => {
                dispatch(isSaving(false));
                let mappedUser = mapCombineUserFromIdentity(managerResponse.data, identityUser)
                dispatch(updateCustomersUser(mappedUser));
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isSaving(false));
                console.error("Error to save user", error);
                return Promise.reject(error);
            });
    };
};

/** This function search for imbera users in customer to find out if it has already been added */
export const lookUserInCustomer = userName => {
    return function (dispatch, getState) {
        let allUsers = getState().customers.get('customerUsers');
        let foundUser = allUsers.find(u => u.get('UserName').toLowerCase() === userName)
        return Promise.resolve(foundUser ? true : false);
    }
}

/** This function determine if notifications will be send to selected user */
export const editActiveNotifications = (token, activeNotification) => {
    return function (dispatch, getState) {
        const { serviceManagementBackendUrl } = window.config
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let command = {
            Token: token,
            ActiveNotifications: activeNotification
        };
        let url = `${serviceManagementBackendUrl}/user/notifications/activation`
        return axios.put(url, command, config)
            .then(serverResponse => {
                dispatch(updateActiveNotifications(serverResponse.data))
                return Promise.resolve();
            })
            .catch((error) => {
                console.error("Error to change Notification status", error)
                return Promise.reject(error.response.data.Message);
            });
    }
}

export const downloadCustomersUsersList = () => {
    return function (dispatch, getState) {
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let endPoint = `${window.config.serviceManagementBackendUrl}/customers/customersReport`
        return axios
            .post(
                endPoint,
                {},
                {
                    responseType: 'arraybuffer',
                    headers: config
                }
            )
            .then((serverResponse) => {
                if (serverResponse.status === 200) {
                    var blob = new Blob([serverResponse.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    })
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = `Customers_users_list.xlsx`
                    a.click()
                    return Promise.resolve('Ok')
                }
            })
            .catch((error) => {
                console.error('Error donwload list of customers users', error)
                return Promise.reject()
            })
    }
}
