/**Import react section */
import React, { useState, Fragment, useEffect } from 'react';
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

/**Import actions sections */
import {loadUserByUserName} from '../../../store/users/UsersActions'
import { generatedNewPassword } from '../../../store/helpers/PasswordHelper'
/** Material UI import section */
import {
    Button,
    DialogContent,
    Grid
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'


/** Custom components import section */
import Dialog from '../../common/Dialog'
import AlertComponent from '../../common/AlertComponent'
import ValidatedTextField from '../../common/hooksForms/ValidatedTextField'

/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const ResendInvitation = props => {
    const dispatch = useDispatch()
    const classes = useDataGridToolbarStyle();
    const { handleSubmit, errors, control } = useForm()

    const {isLoadingUser, isSaving } = props;
    const [administrator, setAdministrator] = useState(null);

    useEffect(() => {
         if(props.provider.UserName){
             dispatch(loadUserByUserName(props.provider.UserName))
                .then(user => {
                    setAdministrator(user)
                })
         }
    }, [dispatch, props.provider])

    /**
     * function to on send invitation
     */
    const onSendInvitation = (confirmEmail) => {
        let newPassword = generatedNewPassword();
        if(props.onSendInvitation){
            props.onSendInvitation(administrator, confirmEmail, newPassword);
        }
    };

    /**
     * Can be send invitation
     */
    const canBeSaved = () => {
        if(administrator)
            return true;
        return false;
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                isSaving={isSaving}
                maxWidth={props.maxWidth}
                header={
                    <strong>{"Resend Invitation to Provider"}</strong>
                }
                actions={
                    <Fragment >
                        <Button
                            variant="contained"
                            className={classes.btnSaved}
                            disabled={isLoadingUser || isSaving || !canBeSaved()}
                            onClick={handleSubmit(onSendInvitation)}
                        >
                            SEND
                    </Button>
                    </Fragment>
                }
            >
                <DialogContent>
                    { isLoadingUser ?
                        <Grid container spacing={1} style={{width:"350px"}}>
                            <Grid item xs={12} md={12}>
                                <Skeleton variant="rect" width={"100%"} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Skeleton variant="rect" width={"100%"}/>
                            </Grid>
                        </Grid>:
                        <>
                            <AlertComponent
                                title={"Resend invitation"}
                                message={`The invitation will be forwarded to the supplier administrator: ${administrator && administrator.name} to enter the portal to the mail: ${administrator && administrator.user_name}`}
                                severityType={"info"}
                            />
                            <Grid item xs={12}>
                                <ValidatedTextField
                                    autoFocus
                                    name="Email"
                                    label="Confirm email to send"
                                    margin="dense"
                                    rules={{
                                        required: { value: true, message: 'The email is required' },
                                        pattern: {
                                            value: /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Enter a valid e-mail address",
                                        },
                                    }}
                                    errors={errors}
                                    control={control}
                                />
                            </Grid>
                        </>
                    }<br />
                </DialogContent>
            </Dialog>
        </>

    );
}

ResendInvitation.propTypes = {
    /**Determinate if saving an operation */
    isSaving: propTypes.bool,
    /**Determinate if dialog password is open or close */
    open: propTypes.bool.isRequired,
    /**Determinate if close the dialog  */
    onClose: propTypes.func.isRequired,
    onSendInvitation: propTypes.func.isRequired,
}

ResendInvitation.defaultProps = {
    isSaving: false,
    onSendInvitation: () =>
        console.warn('Callback [onSendInvitation] no defined'),

}


export default ResendInvitation;