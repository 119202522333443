import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import defaultTheme from '../../../resources/themes/default'

import Authorization from '../../common/AuthorizationEvaluation'
import LateralPanel from '../../common/lateralPanel/LateralPanel'
import PanelHeaderIcon from '../../common/lateralPanel/PanelHeaderIcon'
import Selector from '../../common/Selector'
import Toaster from '../../common/Toaster'

import { downloadReportByCustomer } from '../../../store/sparePartSales/sparePartSalesAction'
import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER
} from '../../../resources/RolesEnum'

import { downloadPricesStyles } from '../resources/downloadPricesStyles'
import moment from 'moment'
import DateRangePicker from '../../common/daterangepicker/DateRangePicker'

const PanelDownloadPrices = (props) => {
	const {
		open,
		onClose,
		customers,
		isDownloadingReport,
		allowedRoles,
		userProfile
	} = props

	const classes = downloadPricesStyles()

	const dispatch = useDispatch()
	let isCustomer = hasAuthorization(userProfile, [
		CUSTOMER_MANAGER,
		CUSTOMER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER,
		SERVICE_PROVIDER_TECHNICIAN,
		SALES_REPRESENTATIVE_MANAGER,
		SALES_MANAGER
	])

	const [customModel, setCustomModel] = useState(null)
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [startDate, setStartDate] = useState(moment().startOf('year'))
	const [endDate, setEndDate] = useState(moment().endOf('year'))

	const onSelectChange = (custom) => {
		setCustomModel(custom)
	}

	const onDonwloadReportByCustomer = () => {
		return dispatch(
			downloadReportByCustomer(customModel, startDate, endDate)
		).catch(() => {
			setToaster({
				open: true,
				message: 'An error to download report by serie',
				variant: 'error'
			})
		})
	}

	const canBeDownload = () => {
		if (!customModel) {
			if (isCustomer) {
				if (userProfile)
					setCustomModel({ value: userProfile?.Account.CodeSap })
			}
		}
		if (customModel) return true
		return false
	}
	const onDateRangeChange = (range) => {
		setStartDate(range.startDate)
		setEndDate(range.endDate)
	}
	return (
		<LateralPanel onClose={onClose} open={open}>
			<PanelHeaderIcon
				title={'DOWNLOAD PRICES'}
				icon={
					<CloudDownloadOutlinedIcon className={classes.headerIcon} />
				}
				onCloseButtonClick={onClose}
			/>
			<>
				<div>
					<Authorization
						profile={userProfile}
						allowedRoles={allowedRoles}
					>
						<div>
							<Grid className={classes.panelContent}>
								<Grid item xs={10}>
									<Typography>
										Enter a customer to download prices
										report.
									</Typography>
								</Grid>
							</Grid>
							<Grid className={classes.panelContent}>
								<Grid item xs={10}>
									<Selector
										options={customers}
										isDisabled={false}
										defaultValue={
											customModel
												? customers.find(
														(x) =>
															x.key ===
															customers.key
												  )
												: null
										}
										onChange={(option) =>
											onSelectChange(option)
										}
										margin='dense'
									/>
									<div style={{ marginTop: '10px' }}>
										<NetworkDayDateRangePicker
											endDate={endDate}
											startDate={startDate}
											onDateRangeChange={
												onDateRangeChange
											}
										/>
									</div>
								</Grid>
							</Grid>
						</div>
					</Authorization>
					<Grid className={classes.panelContent}>
						<Grid item xs={10}>
							<Typography>
								{isCustomer
									? 'Press button to download the current prices report.'
									: ''}
							</Typography>
						</Grid>
					</Grid>
					<Grid className={classes.panelButtoncontainer}>
						<Grid item xs={4}>
							<Button
								variant='contained'
								className={
									!canBeDownload() || isDownloadingReport
										? ''
										: classes.panelButton
								}
								onClick={onDonwloadReportByCustomer}
								disabled={
									!canBeDownload() || isDownloadingReport
								}
							>
								DOWNLOAD
							</Button>
						</Grid>
					</Grid>
				</div>
			</>
			<Toaster
				toasterOptions={toaster}
				onClose={() => setToaster({ open: false })}
			/>
		</LateralPanel>
	)
}
PanelDownloadPrices.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	customersOptions: PropTypes.array
}
PanelDownloadPrices.defaultProps = {
	onClose: () => console.warn('Callback [onClose] no defined'),
	open: false,
	customersOptions: []
}

const NetworkDayDateRangePicker = (props) => {
	const predefinedRanges = [
		{
			label: 'This Year',
			startDate: moment().startOf('year'),
			endDate: moment()
		},
		{
			label: 'Last Year',
			startDate: moment().add(-1, 'year'),
			endDate: moment()
		},
		{
			label: 'Last Semester',
			startDate: moment().add(-6, 'month'),
			endDate: moment()
		},
		{
			label: 'Last Trimester',
			startDate: moment().add(-3, 'month'),
			endDate: moment()
		}
	]

	return (
		<DateRangePicker
			textColor={defaultTheme.palette.primary.light}
			variant='outlined'
			startDate={props.startDate}
			endDate={props.endDate}
			predefinedRanges={predefinedRanges}
			onChangeRange={props.onDateRangeChange}
		/>
	)
}

export default PanelDownloadPrices
