/**Import react section */
import React, { useState, Fragment, useEffect } from 'react';
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import { useDispatch } from 'react-redux'

/**Import actions sections */
import {
    confirmUserPassword,
} from '../../store/users/UsersActions'
/** Material UI import section */
import {
    Button,
    DialogContent,
    TextField,
    IconButton,
    Typography,
    InputAdornment
} from '@material-ui/core';
import {ButtonCreateStyles} from '../../resources/styles/ButtonCreateStyles'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/** Custom components import section */
import Dialog from './Dialog'
import TextBox from './TextBox'
import AlertComponent from '../common/AlertComponent'
import Toaster from '../common/Toaster'

/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const ConfirmPasswordDialog = props => {
    const dispatch = useDispatch()
    const classes = ButtonCreateStyles();

    const { commentRequired, isSaving, onClickButtonConfirm, isVisibleComments, 
        message1: alertMesage, message2, title, labelForIdentifier, isVisibleTicket } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [passwordModel, setPasswordModel] = useState({ password: '', comments: '' });
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [showHelperPassword, setShowHelperPassword] = useState(false);

    useEffect(() => {
        setShowPassword(false)
        setPasswordModel({ password: '', comments: '', identifier: '' })
    }, [])

    /**
 * Determine if the accepted button can be pressed
 */
    const canBeSaved = () => {
        if (isVisibleTicket) {
            if (isVisibleTicket && (isNullOrEmpty(passwordModel ? passwordModel.identifier : '') || (passwordModel ? passwordModel.identifier.trim().length <= 0 : ''))) { return false; }
            if (isNullOrEmpty(passwordModel ? passwordModel.password : '') || (passwordModel ? passwordModel.password.length < 6 : '')) { return false; }
        }
        else if (commentRequired) {
            if (commentRequired && (isNullOrEmpty(passwordModel ? passwordModel.comments : '') || (passwordModel ? passwordModel.comments.trim().length < 5 : ''))) { return false; }
            if (isNullOrEmpty(passwordModel ? passwordModel.password : '') || (passwordModel ? passwordModel.password.length < 6 : '')) { return false; }
        }
        else {
            if (isNullOrEmpty(passwordModel ? passwordModel.password : '') || (passwordModel ? passwordModel.password.length < 6 : '')) { return false; }
        }
        return true;
    }


    /**On change properties for the confirm password */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setPasswordModel({
            ...passwordModel,
            [name]: value
        })
    }

    /**
     * Hanlde click event from accept button
     */
    const onAcceptClick = () => {
        if (onClickButtonConfirm) {
            onClickButtonConfirm(passwordModel);
        }
    }

    const onPasswordConfirm = () => {
        let resultResponse = false;
        return dispatch(confirmUserPassword(passwordModel.password))
            .then((result) => {
                resultResponse = result.Success;
                if (resultResponse) {
                    onAcceptClick(passwordModel)
                } else {
                    setShowHelperPassword(true);
                }
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "Error to confirm password", variant: 'error' })
                console.error("Error to confirm password");
            });
    };


    /** 
 * Handle view actual password 
 */
    const onToggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    let placeholder = "Enter a comment.";
    if (commentRequired) {
        placeholder = placeholder.concat(" You need to complete this section.")
    }
    else {
        placeholder = placeholder.concat(" This section is optional.")
    }


    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                isSaving={isSaving}
                maxWidth={props.maxWidth}
                header={
                    <strong>{title}</strong>
                }
                actions={
                    <Fragment >
                        <Button
                            variant="contained"
                            className={classes.btnSaved}
                            disabled={isSaving || !canBeSaved()}
                            onClick={onPasswordConfirm}
                        >
                            CONFIRM
                    </Button>
                    </Fragment>
                }
            >
                <DialogContent>
                    {
                        <AlertComponent
                            title={"Warning"}
                            message={alertMesage}
                            severityType={"warning"}
                        />
                    }<br />
                    {
                        isVisibleTicket &&
                        <><TextBox
                            active={true}
                            fullWidth
                            name="identifier"
                            variant="outlined"
                            margin="dense"
                            label={labelForIdentifier}
                            placeholder={placeholder}
                            onChange={onPropertyChange}
                            value={passwordModel ? passwordModel.identifier : ""}
                            autoComplete='off'
                        /><br /><br /></>
                    }
                    {
                        isVisibleComments &&
                        <TextBox
                            active={true}
                            fullWidth
                            name="comments"
                            variant="outlined"
                            margin="dense"
                            label="Comments"
                            placeholder={placeholder}
                            multiline
                            rows={4}
                            onChange={onPropertyChange}
                            value={passwordModel ? passwordModel.comments : ""}
                            autoComplete='off'
                        />
                    }
                    {message2 && <><Typography variant='subtitle2'>{message2}</Typography><br /></>}
                    <TextField
                        fullWidth
                        autoComplete='off'
                        label={"Password"}
                        variant="outlined"
                        data-cy="txt-confirm-password"
                        value={passwordModel ? passwordModel.password : ""}
                        onChange={onPropertyChange}
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        helperText={showHelperPassword ? <Typography color='error' variant='subtitle2'><strong>{"Incorrect password, please verify."}</strong></Typography> : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="toggle password visibility"
                                        onClick={onToggleShowPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            inputMode: "text",
                        }}
                    />

                </DialogContent>
            </Dialog>

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>

    );
}

ConfirmPasswordDialog.propTypes = {
    /**Confirm the operation to be performed*/
    onClickButtonConfirm: propTypes.func,
    /**Determinate if saving an operation */
    isSaving: propTypes.bool,
    /**Determinate if is visible the textbox commnets */
    isVisibleComments: propTypes.bool,
    /**Determinate if the commnets are required*/
    commentRequired: propTypes.bool,
    /**Determinate if dialog password is open or close */
    open: propTypes.bool.isRequired,
    /**Determinate if close the dialog  */
    onClose: propTypes.func.isRequired,
    /**See mensaje of operation alert*/
    alertMesage: propTypes.string,
    /**See mensaje of operation*/
    message2: propTypes.string
}

ConfirmPasswordDialog.defaultProps = {
    isSaving: false,
    isVisibleComments: false,
    commentRequired: false,
    alertMesage: '',
    message2: '',
    onClickButtonConfirm: () =>
        console.warn('Callback [onClickButtonConfirm] no defined'),

}


export default ConfirmPasswordDialog;