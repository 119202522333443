import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import Select from 'react-select'
import { FormHelperText } from '@material-ui/core'

/**
 * Service selector component
 */
const Selector = (props) => {
	return (
		<Fragment>
			<Select
				autoFocus
				options={props.options}
				styles={getSelectorStyles(props.hasError)}
				defaultValue={props.defaultValue}
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
				menuPortalTarget={document.parentNode}
				menuPosition='fixed'
				menuPlacement={'bottom'}
				isDisabled={props.isDisabled}
			/>
			{props.hasError && (
				<FormHelperText error>{props.errorMessage}</FormHelperText>
			)}
		</Fragment>
	)
}
Selector.propTypes = {
	/**
	 * The list of options in the selector
	 */
	options: propTypes.arrayOf(
		propTypes.shape({
			value: propTypes.string,
			label: propTypes.string
		})
	),
	/**
	 * The default option selected
	 */
	defaultValue: propTypes.shape({
		value: propTypes.string,
		label: propTypes.string
	}),
	/**
	 * If true, the selector shows an red error state
	 */
	hasError: propTypes.bool.isRequired,
	/**
	 * If true, the selector show an red error message
	 */
	errorMessage: propTypes.string,
	/**
	 * Placeholder
	 */
	placeholder: propTypes.string,
	/**
	 * Callback executed when the user change the selected option
	 */
	onChange: propTypes.func,
	/**
	 * Autofocus
	 */
	autoFocus: propTypes.bool,
	/**
	 * is disabled selector by users externals
	 */
	isDisabled: propTypes.bool
}
Selector.defaultProps = {
	hasError: false,
	autoFocus: false,
	isDisabled: false
}
export default Selector

const getSelectorStyles = (hasError) => ({
	menu: (base) => ({
		...base,
		zIndex: 62,
		maxHeight: 150
	}),
	menuList: (base) => ({
		...base,
		maxHeight: 150,
		paddingTop: 0,
		fontSize: '14px'
	}),
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
	clearIndicator: (base) => ({
		...base,
		display: 'none'
	}),
	control: (base, state) => ({
		...base,
		borderColor: !hasError ? '#ddd' : '#f44336',
		borderWidth: '1px',
		fontSize: '14px',
		'&:hover': {
			borderColor: !hasError ? '#ddd' : '#f44336'
		}
	})
})
