/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/**Import Material UI Section */
import { Grid, Paper } from '@material-ui/core'

import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import CardAvatar from '../CardAvatar';
import CardAvatarSkeleton from '../CardAvatarSkeleton'
import DataGrid from '../apsys.datagrid.materialui/DataGrid'
import ImagesNoInformation from '../ImagesNoInformation'


const RenderContentIndex = (props) => {
    const classes = useDataGridToolbarStyle();
    const { gridMode, isLoading, data, onEditClick,
        onChangeSortingCriteria, pagination,
        headerConfig, onRowClick, searchValue, titleData,
        renderOptionMenu, isActiveButtonOptions, isAvatarFromIdentity, identifierCardSeleted } = props;
    
        /** */
    const renderNoInformation = () => {
        return (
            <ImagesNoInformation
                query={searchValue}
                data={data}
                isLoading={props.isLoading}
                titleData={titleData}
            />
        )
    }
    /**Render content en mode card */
    const renderContentInModeCard = () => {
        if (!props.isLoading) {
            return (
                <div className={classes.containerIndexModeCard}>
                    <Grid container spacing={1}  direction="row"  justify="flex-start"  alignItems="flex-start" className={classes.containerCards}>
                        {
                            data.length >= 1 ? data.map((item) => {
                                return (
                                    < Grid item xs={4} md={3} lg={2} xl={2} key={item.Token}>
                                        <CardAvatar
                                            userProfile={item}
                                            onClickEdit={onEditClick}
                                            renderOptionMenu={renderOptionMenu ? renderOptionMenu(item) : null}
                                            isActiveButtonOptions={isActiveButtonOptions ? true : false}
                                            isLoading={isLoading}
                                            isAvatarFromIdentity={isAvatarFromIdentity}
                                            identifierCardSeleted={identifierCardSeleted}
                                        />
                                    </Grid>
                                )
                            }) : < Grid item xs={12}>
                                    {renderNoInformation()}
                                </Grid>

                        }

                    </Grid>
                </div>
            )
        }
        else {
            return (
                <CardAvatarSkeleton />
            )
        }
    }

    /**Render content en mode list */
    const renderContentInModeList = () => {
        return (
            <Paper className={classes.containerIndex} >
                <DataGrid
                    headers={headerConfig}
                    isLoading={isLoading}
                    data={data}
                    onChangeSortingCriteria={onChangeSortingCriteria}
                    sortBy={pagination ? pagination.orderBy : props.sortBy}
                    sortDirection={pagination ? pagination.orderType : props.sortDirection}
                    onItemClick={onRowClick}
                />
                {renderNoInformation()}
            </Paper>
        )

    }

    /**Render content for Index common */
    const renderContent = () => {
        return (
            gridMode ? renderContentInModeCard() : renderContentInModeList()
        )
    }

    return (renderContent())
}

RenderContentIndex.propTypes = {
	/**
	 * Determines whether card mode is stored in local storage
	 */
    gridMode: propTypes.bool,
    /**
	 * Callback executed when the user click on a item row or card click
	 */
    onRowClick: propTypes.func,
    /**
     * Determine if the user list's is loading
     */
    isLoading: propTypes.bool.isRequired,
    /**
     * Determine if the list's to render
     */
    data: propTypes.array.isRequired,
    /**Determine the title when there are no data results*/
    titleData: propTypes.string.isRequired,
    /**Determines if there are search results*/
    searchValue: propTypes.string.isRequired,
    /**
     * The object contains the paginated  
     */
    pagination: propTypes.shape({

		/**
		 * Determine the initial sort by column
		 */
        orderBy: propTypes.string,
		/** 
		* Defines the initial sort direction
		*/
        orderType: propTypes.oneOf(['asc', 'desc']),
		/** 
		* Defines the total de retails pagination
		*/
        total: propTypes.number,
		/** 
		* Defines the page number pagination
		*/
        pageNumber: propTypes.number,
    }),
    /**
 * On change sort criteria callback
 */
    onChangeSortingCriteria: propTypes.func.isRequired,
    identifierCardSeleted: propTypes.string

}

RenderContentIndex.defaultProps = {
    gridMode: false,
    isLoading: false,
    searchValue: "",
    titleData: "",
    identifierCardSeleted: "",
    data: [],
    pagination: {
        orderBy: "",
        orderType: 'asc',
        total: 0,
        pageNumber: 1,
    },
    onRowClick: () =>
        console.warn('Callback [onRowClick] no defined'),
    onChangeSortingCriteria: () =>
        console.warn('Callback [onChangeSortingCriteria] no defined'),
}

export default RenderContentIndex