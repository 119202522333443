/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
/**Import Material UI section */
import { Avatar, Tooltip, CircularProgress } from '@material-ui/core'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'

const LockButton = (props) => {
    const classes = useDataGridToolbarStyle();
    const { icon, isLocked, onLocked, title, disabled } = props
    return (
        <Tooltip title={title}>
            <div className={classes.wrapper}>
                <Avatar className={(isLocked || disabled) ? classes.lockOpenButton : classes.lockButton} onClick={onLocked} disabled={disabled}>
                    <img
                        src={icon}
                        alt='locked-icon'
                        onClick={onLocked}
                    />
                </Avatar>
                {disabled && <CircularProgress size={35} className={classes.fabProgress} />}

            </div>
        </Tooltip>
    )
}

/**Proptypes this component */
LockButton.propTypes = {
    /**
	 * Render the botton icon in imported svg format
	 */
    icon: propTypes.string,
    /**
     * Render the title shown in the tooltip
     */
    title: propTypes.string,
    /**
     * Determine if the user is blocked and change the botton color
     */
    isLocked: propTypes.bool,
    /**
     * Determine if the information is being saved and disable the botton and show the loader
     */
    disabled: propTypes.bool,
    /**
    * Callback blocks and enables a service provider
    */
    onLocked: propTypes.func.isRequired,
}


LockButton.defaultProps = {
    icon:'',
    title:'',
    isLocked:false,
    disabled:false,
    onLocked: () =>
        console.warn('Callback [onLocked] no defined'),
}

export default LockButton
