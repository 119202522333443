/**Import react section */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'
import { RoleType } from '../../../store/helpers/AppConstants';
/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import { WhiteTextBox } from '../../common/TextBox'
import AvatarComponent from '../../common/AvatarComponent';
import RolesForm from '../../admin/internalUsers/components/RolesForm'
import NotificationsButton from '../../common/NotificationsButton';
/**Import resources section */
import { editLateralPanelStyles } from '../../../resources/styles/EditLateralPanelCommon'

const EditUserPanel = (props) => {
    const classes = editLateralPanelStyles();

    const { onSaveButtonClick,
        onCloseButtonClick,
        isSaving,
        user,
        onSetHandleOpenCloseModalUploadImg,
        onSetLogoFileBase64,
        onActiveNotifications,
        onToggleUserRole,
        isEnable } = props;
    const [userModel, setUserModel] = useState(defaultUser);

    useEffect(() => {
        setUserModel(user)
    }, [user])


    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(userModel)
        }
    }

    const onPropertyChange = event => {
        let { name, value } = event.target;
        setUserModel({
            ...userModel,
            [name]: value
        })
    }

    /**Verify if cam be sabed */
    const canBeSaved = () => {
        if (userModel ? (!!userModel.Name ? userModel.Name.length <= 0 : true) : true) return false;
        if (userModel ? (!!userModel.PhoneNumber ? userModel.PhoneNumber.length <= 0 : true) : true) return false;
        return true;
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"USER INFORMATION"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                onSaveButtonClick={onSave}
                isSaving={isSaving}
                canBeSaved={canBeSaved()}
            />
            <div >
                <div className={classes.avatar}>
                    <AvatarComponent
                        name={userModel ? userModel.Name : ""}
                        onSetHandleOpenModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                        onSetLogoFileBase64={onSetLogoFileBase64}
                        avatarUrl={userModel ? userModel.AvatarUrl : null}
                        isAvatarFromIdentity={true}
                    />
                </div>
                <div className={classes.texboxContainer}>
                    <WhiteTextBox
                        active={false}
                        disabled
                        fullWidth
                        autoFocus
                        name="Mail"
                        variant="outlined"
                        margin="dense"
                        label="Mail"
                        value={userModel ? userModel.Email : ""}
                    />
                    <WhiteTextBox
                        active={false}
                        disabled
                        fullWidth
                        autoFocus
                        name="Company"
                        variant="outlined"
                        margin="dense"
                        label="Company"
                        value={userModel ? (!!userModel.Account ? userModel.Account.Name : "") : ""}
                    />
                    <WhiteTextBox
                        active={true}
                        fullWidth
                        autoFocus
                        name="Name"
                        variant="outlined"
                        margin="dense"
                        label="Name"
                        onChange={onPropertyChange}
                        value={userModel ? userModel.Name : ""}
                    />
                    <WhiteTextBox
                        active={true}
                        fullWidth
                        autoFocus
                        name="PhoneNumber"
                        variant="outlined"
                        margin="dense"
                        label="Phone number"
                        onChange={onPropertyChange}
                        value={userModel ? userModel.PhoneNumber : ""}
                    />
                </div>
                <div className={classes.contentRolesForm}>
                    <NotificationsButton
                        onActiveNotifications={onActiveNotifications}
                        isEnable={isEnable}
                        user={user}
                    />
                    <RolesForm
                        onToggleUserRole={onToggleUserRole}
                        roles={userModel ? userModel.Roles : []}
                        type={RoleType.CUSTOMER}
                        isSaving={isSaving}
                    />
                </div>
            </div>

        </LateralPanel>
    )
}

const defaultUser = {
    Active: '',
    Email: '',
    Id: '',
    Name: '',
    UserName: '',
}

/**Proptypes this component */
EditUserPanel.propTypes = {
    /**
     * On close lateral menu
     */
    onCloseButtonClick: propTypes.func.isRequired,
    /**
    * On save new properties
    */
    onSaveButtonClick: propTypes.func.isRequired,
}

EditUserPanel.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined')
}

export default EditUserPanel