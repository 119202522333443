/**Import react section */
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
/**Import react section */
import Typography from "@material-ui/core/Typography";
import {
    ButtonGroup, Tooltip, Button, Card, CardHeader,
    CardContent, Divider, Grid
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/DeleteForever';

/**Import components section */
import SearchBox from '../../../common/SearchToolbox'
/**Import resources section */
import { useCreateMobileStyle } from '../../resources/useStyles'
import Pagination from '../../../common/Pagination/Pagination'
import ExpandedSummary from '../../../services/componentsMobile/ExpandedSummary'
const EquipmentsSap = (props) => {
    const classes = useCreateMobileStyle()
    /**hooks section */

    /**Props functions sections */
    const { onChangeQuerySap, onAddManual } = props

    /**string props section */
    const { querySparePart, } = props
    /**Bools props section */
    const { isModeCreate, isDownloadReport, canBeRemoveFile } = props
    /**Store local this component */
    const [equipment, setEquipment] = useState(null)
    const [allSpareParts, setAllSpareParts] = useState([])
    const [pagination, setPagination] = useState({ pageSize: 5, total: 0 });
    const [allSparePartPaginated, setAllSparePartPaginated] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setEquipment(props.equipmentsSap)
    }, [props])

    //filter all spare parts
    useEffect(() => {
        setAllSpareParts(props.sparePartsSap)
    }, [props.sparePartsSap])

    useEffect(() => {
        let sparePartsPerPage = 10;
        let total = allSpareParts.length / sparePartsPerPage;
        let allTotal = Math.round(Math.ceil(total))
        const indexOfLastSparePart = currentPage * sparePartsPerPage;
        let indexOfFirtsSparePart = indexOfLastSparePart - sparePartsPerPage;
        let currentSparePart = allSpareParts.slice(indexOfFirtsSparePart, indexOfLastSparePart)
        setAllSparePartPaginated(currentSparePart)
        setPagination({ pageSize: sparePartsPerPage, total: allTotal });

    }, [allSpareParts, pagination.total, currentPage])

    const onChangePage = (event, page) => {
        setCurrentPage(page)
    }

    const onChangeQuery = (query) => {
        setCurrentPage(1)
        if (onChangeQuerySap) {
            onChangeQuerySap(query)
        }
    }

    const onButtonAddManualClick = (equipment) => {
        if (onAddManual) {
            onAddManual(equipment)
        }
    }

    const onRenderSparePart = (sparePart) => {
        return (
            <Grid container>
                <Grid item={12}>
                    <Typography variant='subtitle2'><strong >{`Code: `}</strong>{` ${sparePart.Code} `}</Typography>
                    <Typography variant='subtitle2'><strong >{`Description: `}</strong>{` ${sparePart.Description} `}</Typography>
                    <Typography variant='subtitle2'><strong >{`Material: `}</strong>{` ${sparePart.Material} `}</Typography>
                    <Typography variant='subtitle2'><strong >{`Material description: `}</strong>{` ${sparePart.DescriptionMat} `}</Typography>
                </Grid>

            </Grid>
        )

    }

    return (
        <>
            {equipment && <Card>
                <Grid container>
                    <Grid item={12}>
                        <Typography variant='subtitle2' className={classes.textExpanded} align="center"> {` SPARE PARTS`} </Typography>
                        <Typography variant='subtitle2'><strong >{`Serie: `}</strong>{` ${equipment.Serie} `} | <strong >{`Model: `}</strong> {` ${equipment.Model} `}</Typography>
                        <Typography variant='subtitle2'><strong>{`Description: `}</strong> {` ${equipment.DescriptionMaterial} `} </Typography>
                        <Typography variant='subtitle2'><strong>{`Customer: `}</strong> {` ${equipment.CustomerCode} | ${equipment.CustomerName} `} </Typography>
                        <Typography variant='subtitle2'><strong>{`Destination: `}</strong> {` ${equipment.DestinatarionCode} | ${equipment.DestinatarionName} `} </Typography>

                    </Grid>
                </Grid>

                <Divider />
                <CardHeader
                    action={
                        <div className={classes.buttonCleanContainer}>
                            <SearchBox
                                placeholder='Search spare part'
                                onChange={onChangeQuery}
                                value={querySparePart}
                            />
                            <ButtonGroup component='span' variant='text' size='small' className={classes.buttonsGroupEquipmentContainer} >
                                {isModeCreate && <Tooltip component='span' title='Add other material'>
                                    <Button onClick={() => onButtonAddManualClick(equipment)} className={classes.buttonsGroup} disabled={isDownloadReport}>
                                        <AddIcon />
                                    </Button>
                                </Tooltip>}
                                <Tooltip component='span' title='Clean equipment'>
                                    <Button onClick={() => props.onUpdateQuantityEquipment(null)} className={classes.buttonsGroup} disabled={isDownloadReport}>
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                                {canBeRemoveFile && <Tooltip component='span' title='Delete in portal to get from SAP'>
                                    <Button onClick={() => props.onRemoveFileJsonSerieSap()} className={classes.buttonsGroup} disabled={isDownloadReport}>
                                        <RemoveIcon color="error" />
                                    </Button>
                                </Tooltip>}
                            </ButtonGroup>
                        </div>
                    }
                />

                <CardContent className={classes.spartPartsExpanders}>
                    <div className={classes.containerSpareParts}>
                        <div className={classes.tableSpareParts}>
                            {
                                allSparePartPaginated && allSparePartPaginated.map(part => {
                                    return (
                                        <ExpandedSummary
                                            id={part.Token}
                                            title={`${part.Code} | ${part.Description}`}
                                            isPaddingContent={true}
                                            isVisibleColor={true}
                                            onRenderContent={() => onRenderSparePart(part)}
                                            isWrapTitle={true}

                                        />

                                    )
                                })
                            }
                            <div className={classes.pagination}>
                                <div className={classes.flexGrow} />
                                <Pagination count={pagination.total ? pagination.total : 0} page={currentPage ? currentPage : 0} onChangePage={onChangePage} />
                            </div>

                        </div>
                    </div>
                </CardContent>
            </Card>
            }
        </>
    )
}


EquipmentsSap.propTypes = {

    /**Equipments fount with serie in SAP */
    equipmentsSap: propTypes.object,

    /**
     * Determine if the isSaving data in database
     */
    isSaving: propTypes.bool.isRequired,

    /**
     * Create spare part in the back end
     */
    onAddSparePart: propTypes.func.isRequired,
    /**
     * Callback update information in the store redux equipment sap
     */
    onUpdateQuantityEquipment: propTypes.func.isRequired,
}
EquipmentsSap.defaultProps = {
    equipmentsSap: null,
    isSaving: false,
    onAddSparePart: () =>
        console.warn('Callback [onAddSparePart] no defined'),
    onUpdateQuantityEquipment: () =>
        console.warn('Callback [onUpdateQuantityEquipment] no defined')
}

export default EquipmentsSap