import { makeStyles } from '@material-ui/styles'

export const downloadPricesStyles = makeStyles((theme) => ({
	root: {
		maxWidth: theme.views.maxWidth,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '10px',
		maxHeight: `calc(100vh - ${85}px)`,
		overflowY: 'auto'
	},
	headerIcon: {
		color: `${theme.palette.common.white} !important`
	},
	panelContent: {
		marginTop: '20px',
		spacing: 2,
		display: 'flex',
		justifyContent: 'center'
	},
	panelButtoncontainer: {
		marginTop: '20px',
		spacing: 2,
		display: 'flex',
		justifyContent: 'flex-end',
		direction: 'row'
	},
	panelButton: {
		color: `${theme.palette.common.white} !important`,
		backgroundColor: `${theme.palette.colors.green} !important`
	},
	panelButtonDisabled: {
		color: `${theme.palette.common.white} !important`,
		backgroundColor: `${theme.palette.text.secondary} !important`
	}
}))
