import React, { useState, Fragment } from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'
import moment from 'moment'

/** Import components */
import { Layout, Content, Header } from '../../layouts/AuthenticatedLayout'
import NetworkdaysChart from './NetworkdaysChart'
import ServiceProvidersGrid from './ServiceProvidersNetworkDays'
import CustomersNetworkDays from './CustomersNetworkDays'
import SalesRepresentativeNetworkDays from './SalesRepresentativeNetworkDays'

import SideBar from '../../common/DashboadSideBar'
import DateRangePicker from '../../common/daterangepicker/DateRangePicker'
import ServicesSideBar from './ServicesSideBar'

import { useDesktopViewStyles } from '../resources/NetworkDayStyles'
import { Paper, Button } from '@material-ui/core'
import CardScoreTotal from './CardScoreTotal'
import defaultTheme from '../../../resources/themes/default'
import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	SALES_MANAGER,
	SALES_REPRESENTATIVE_MANAGER,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN
} from '../../../resources/RolesEnum'

/**
 * Authenticated desktop view
 */
const DesktopView = (props) => {
	const classes = useDesktopViewStyles()
	const [servicesInDate, setServicesInDate] = useState({
		open: false,
		services: []
	})
	let isUserServiceProvider = hasAuthorization(props.userProfile, [
		SERVICE_PROVIDER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER
	])
	let isUserSalesRepresentative = hasAuthorization(props.userProfile, [
		SALES_REPRESENTATIVE_MANAGER,
		SALES_MANAGER
	])
	let isUserCustomer = hasAuthorization(props.userProfile, [
		CUSTOMER_MANAGER,
		CUSTOMER_TECHNICIAN
	])

	let isUserLoggedExternal =
		isUserCustomer || isUserSalesRepresentative || isUserServiceProvider

	const onSelectDateOnChart = (date) => {
		let servicesInDate = props.services.filter((service) =>
			service.CompletionDate.isSame(date)
		)
		setServicesInDate({ open: true, services: servicesInDate })
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<div className={classes.containerDateRange}>
						<NetworkDayDateRangePicker
							onDateRangeChange={props.onDateRangeChange}
							startDate={props.startDate}
							endDate={props.endDate}
						/>
						<Button
							variant='outlined'
							color='secondary'
							disabled={props.isDownloadReport}
							className={classes.buttonReport}
							onClick={() =>
								props.onDownloadReportNetworkDateServices()
							}
						>
							{props.isDownloadReport ? 'Loading..' : 'Report'}
						</Button>
					</div>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={9}
				userProfile={props.userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<Paper className={classes.content}>
					<div className={classes.cardScoreTotal}>
						<ScoreTotalCardLists
							scoreTotalCard={props.scoreTotalCard[0]}
						/>
					</div>
					<div className={classes.chart}>
						<NetworkdaysChart
							weeks={props.weeks}
							services={props.services}
							startDate={props.startDate}
							endDate={props.endDate}
							onSelectDateOnChart={onSelectDateOnChart}
						/>
					</div>
					<div
						className={
							isUserLoggedExternal
								? classes.rootContainerTableExternal
								: classes.rootContainerTable
						}
					>
						{!isUserServiceProvider && (
							<div
								className={
									!isUserLoggedExternal
										? classes.serviceProviderTable
										: classes.serviceProviderTableExternal
								}
							>
								<ServiceProvidersGrid
									serviceProviders={props.servicesProviders}
									sortingCriteria={
										props.servicesSortingCriteria
									}
									onChangeSortingCriteria={
										props.onChangeServicesSortingCriteria
									}
									onServiceProviderClick={
										props.onServiceProviderClick
									}
									onQueryProviderChange={
										props.onQueryProviderChange
									}
									searchValue={props.serviceProviderQuery}
								/>
							</div>
						)}
						{!isUserSalesRepresentative && (
							<div
								className={
									!isUserLoggedExternal
										? classes.salesRepresentivesProviderTable
										: classes.salesRepresentivesProviderTableExtenal
								}
							>
								<SalesRepresentativeNetworkDays
									salesRepresentative={
										props.salesRepresentatives
									}
									sortingCriteria={
										props.salesRepresentativesSortingCriteria
									}
									onChangeSortingCriteria={
										props.onChangeSalesRepresentativeSortingCriteria
									}
									onSalesRepresentativeClick={
										props.onSalesRepresentativeClick
									}
									onQueryRepresentativeChange={
										props.onQueryRepresentativeChange
									}
									searchValue={props.salesRepresentativeQuery}
								/>
							</div>
						)}
						{!isUserCustomer && (
							<div
								className={
									!isUserLoggedExternal
										? classes.customerTable
										: !isUserServiceProvider
											? classes.customerTableExternal
											: classes.customerInServiceProviderTableExternal
								}
							>
								<CustomersNetworkDays
									customers={props.customers}
									sortingCriteria={
										props.customersSortingCriteria
									}
									onChangeSortingCriteria={
										props.onChangeCustomersSortingCriteria
									}
									onCustomerClick={props.onCustomerClick}
									onQueryCustomerChange={
										props.onQueryCustomerChange
									}
									searchValue={props.serviceProviderQuery}
								/>
							</div>
						)}
					</div>

					<ServicesSideBar
						{...servicesInDate}
						onClose={() => setServicesInDate({ open: false })}
					></ServicesSideBar>
				</Paper>
			</Content>
		</Layout>
	)
}
DesktopView.propTypes = {
	/**
	 * The list of services included in the date range
	 */
	services: propTypes.arrayOf(
		propTypes.shape({
			Ticket: propTypes.string.isRequired,
			RepresentativeName: propTypes.string.isRequired,
			Serie: propTypes.string.isRequired,
			RetailerName: propTypes.string.isRequired,
			ServiceProviderName: propTypes.string.isRequired,
			IntiatedDate: propTypes.instanceOf(moment),
			CompletionDate: propTypes.instanceOf(moment)
		})
	).isRequired,
	/**
	 * The list of service providers included in the closed services
	 */
	servicesProviders: propTypes.array.isRequired,
	/**
	 * The list of sales representatives included in the closed services
	 */
	salesRepresentatives: propTypes.array.isRequired,
	/**
	 * The start of the date range selected by the user to show in the report
	 */
	startDate: propTypes.instanceOf(moment),
	/**
	 * The end of the date range selected by the user to show in the report
	 */
	endDate: propTypes.instanceOf(moment),
	/**
	 * The sorting criteria for the service table
	 */
	servicesSortingCriteria: propTypes.shape({
		by: propTypes.string.isRequired,
		direction: propTypes.string.isRequired
	}).isRequired,
	/**
	 * The sorting criteria for the service table
	 */
	salesRepresentativesSortingCriteria: propTypes.shape({
		by: propTypes.string.isRequired,
		direction: propTypes.string.isRequired
	}).isRequired,
	/**
	 * Callback executed when the user request a change location
	 */
	onChangeLocation: propTypes.func.isRequired,
	/**
	 * Callback executed when the user change the selected date range
	 */
	onDateRangeChange: propTypes.func.isRequired,
	/**
	 * Callback executed when the user change the service sorting criteria
	 */
	onChangeServicesSortingCriteria: propTypes.func.isRequired,
	/**
	 * Callback executed when the user change the sales representatives sorting criteria
	 */
	onChangeSalesRepresentativeSortingCriteria: propTypes.func.isRequired,
	/**
	 * Callback executed when the user click on a service provider
	 */
	onServiceProviderClick: propTypes.func,
	/**
	 * Callback executed when the user click on a sales representative
	 */
	onSalesRepresentativeClick: propTypes.func,
	/**
	 * Callback executed when the user double click on a chart node
	 */
	onSelectDateOnChart: propTypes.func
}
DesktopView.defaultProps = {
	services: [],
	servicesProviders: [],
	salesRepresentatives: [],
	startDate: moment(),
	endDate: moment()
}
export default withImmutablePropsToJS(DesktopView)

/**
 * NetworkDay DateRangePicker configured
 */
const NetworkDayDateRangePicker = (props) => {
	const predefinedRanges = [
		{
			label: 'This Year',
			startDate: moment().startOf('year'),
			endDate: moment()
		},
		{
			label: 'Last Year',
			startDate: moment().add(-1, 'year'),
			endDate: moment()
		},
		{
			label: 'Last Semester',
			startDate: moment().add(-6, 'month'),
			endDate: moment()
		},
		{
			label: 'Last Trimester',
			startDate: moment().add(-3, 'month'),
			endDate: moment()
		}
	]

	return (
		<DateRangePicker
			textColor='white'
			variant='outlined'
			startDate={props.startDate}
			endDate={props.endDate}
			predefinedRanges={predefinedRanges}
			onChangeRange={props.onDateRangeChange}
		/>
	)
}

/**
 * DashboardCardLists component
 */
const ScoreTotalCardLists = (props) => {
	return (
		<Fragment>
			<CardScoreTotal
				mainColor={
					defaultTheme.palette.serviceStatus.completedServiceMain
				}
				secondaryColor={
					defaultTheme.palette.serviceStatus.completedServiceDark
				}
				label={'MINIMUM'}
				total={
					props.scoreTotalCard && !!props.scoreTotalCard.Minimum
						? props.scoreTotalCard.Minimum
						: 0
				}
			/>
			<CardScoreTotal
				mainColor={defaultTheme.palette.serviceStatus.suspendedMain}
				secondaryColor={
					defaultTheme.palette.serviceStatus.suspendedDark
				}
				label={'MAXIMUM'}
				total={
					props.scoreTotalCard && !!props.scoreTotalCard.Maximum
						? props.scoreTotalCard.Maximum
						: 0
				}
			/>
			<CardScoreTotal
				mainColor={defaultTheme.palette.serviceStatus.assignedMain}
				secondaryColor={defaultTheme.palette.serviceStatus.assignedDark}
				label={'AVERAGE'}
				total={
					props.scoreTotalCard && !!props.scoreTotalCard.Average
						? props.scoreTotalCard.Average
						: 0
				}
			/>
			<CardScoreTotal
				mainColor={defaultTheme.palette.primary.light}
				secondaryColor={defaultTheme.palette.primary.main}
				label={'TOTAL'}
				total={
					props.scoreTotalCard && !!props.scoreTotalCard.Count
						? props.scoreTotalCard.Count
						: 0
				}
			/>
		</Fragment>
	)
}
