import { createSelector } from 'reselect'
import { toSafeArray, toSafeObject } from '../../store/helpers/StateHelper'
import { getGroupedNetworkDay } from '../networkdays/networkdaysActions'
import { sortBy } from '../helpers/SortingHelper'

let closedServices = (state) => state.networkDays.get('services')
let serviceProviderSortingCriteria = (state) =>
	state.networkDays.get('servicesSortingCriteria')
let customersSortCriteria = (state) =>
	state.networkDays.get('customersSortingCriteria')
let salesRepSortingCriteria = (state) =>
	state.networkDays.get('salesRepresentativesSortingCriteria')
let serviceProviderFilter = (state) =>
	state.networkDays.get('serviceProviderFilter')
let salesRepresentativeFilter = (state) =>
	state.networkDays.get('salesRepresentativeFilter')
let serviceProviderQuery = (state) =>
	state.networkDays.get('serviceProviderQuery')
let salesRepresentativeQuery = (state) =>
	state.networkDays.get('salesRepresentativeQuery')
let customerQuery = (state) =>
	state.networkDays.get('customerQuery')
let customerFilter = (state) =>
	state.networkDays.get('customerFilter')	
/**
 * Get the closed services list
 */
export const closedServicesSelector = createSelector(
	closedServices,
	serviceProviderFilter,
	salesRepresentativeFilter,
	serviceProviderQuery,
	salesRepresentativeQuery,
	customerFilter,
	customerQuery,
	(closedServicesMap, serviceProviderFilter, salesRepresentativeFilter, serviceProviderQuery, salesRepresentativeQuery, customerFilter, customerQuery) => {
		let closedServices = toSafeArray(closedServicesMap)
		if (serviceProviderFilter !== null) {
			closedServices = closedServices.filter(
				(closedService) =>
					closedService.ServiceProviderCode === serviceProviderFilter
			)
		}
		if (salesRepresentativeFilter !== null) {
			closedServices = closedServices.filter(
				(closedService) =>
					closedService.RepresentativeCode ===
					salesRepresentativeFilter
			)
		}
		if (customerFilter !== null) {
			closedServices = closedServices.filter(
				(closedService) =>
					closedService.CustomerCode === customerFilter
			)
		}
		if(serviceProviderQuery !==null && serviceProviderQuery !==undefined){
			let fixedQuery = serviceProviderQuery.trim().toLowerCase()
			closedServices = closedServices.filter(
				(closedService) => {
					if(closedService.ServiceProviderCode && closedService.ServiceProviderName){
						return (closedService.ServiceProviderCode.trim().toLowerCase().startsWith(fixedQuery)
						|| closedService.ServiceProviderName.trim().toLowerCase().includes(fixedQuery))
					}
					return null
				}
			)
		}
		if(salesRepresentativeQuery !==null && salesRepresentativeQuery !==undefined){
			let fixedQuery = salesRepresentativeQuery.trim().toLowerCase()
			closedServices = closedServices.filter(
				(closedService) => {
					if(closedService.RepresentativeCode && closedService.RepresentativeName){
						return closedService.RepresentativeCode.trim().toLowerCase().startsWith(fixedQuery)
						|| closedService.RepresentativeName.trim().toLowerCase().includes(fixedQuery)
					}
					return null
				}
			)
		}
		if(customerQuery !==null && customerQuery !==undefined){
			let fixedQuery = customerQuery.trim().toLowerCase()
			closedServices = closedServices.filter(
				(closedService) => {
					if(closedService.CustomerCode && closedService.CustomerName){
						return closedService.CustomerCode.trim().toLowerCase().startsWith(fixedQuery)
						|| closedService.CustomerName.trim().toLowerCase().includes(fixedQuery)
					}
					return null
				}
			)
		}
		closedServices.forEach((service) => {
			if(service.RepresentativeCode === null) service.RepresentativeCode = "No Rep"
			if(service.RepresentativeName === null) service.RepresentativeName = "No Rep"
			if(service.ServiceProviderCode === null) service.ServiceProviderCode = "No Prov"
			if(service.ServiceProviderName === null) service.ServiceProviderName = "No Prov"
		})
		return closedServices
	}
)

/**
 * Get the services providers included in the list
 * of closed services
 */
export const serviceProvidersSelector = createSelector(
	closedServicesSelector,
	serviceProviderSortingCriteria,
	(closedServices, sortingCriteriaMap) => {
	let sortingCriteria = toSafeObject(sortingCriteriaMap)
	// let queryObject = toSafeObject(queryParameters)
	let serviceProviderIncluded = GetDistinctEntityFromServiceList(
		closedServices,
		'ServiceProviderCode',
		'ServiceProviderName'
		)
		serviceProviderIncluded.forEach((provider) => {
			let servicesOfServiceProvider = closedServices.filter((srv) => {
				return srv.ServiceProviderCode === provider.Code
			})
			if(provider.Code === null) { provider.Code = "No Prov" }
			if(provider.Name === null) { provider.Name = "No Prov" }
			provider.Minimum = getGroupedNetworkDay(
				servicesOfServiceProvider,
				'minimum'
			)
			provider.Average = getGroupedNetworkDay(
				servicesOfServiceProvider,
				'average'
			)
			provider.Maximum = getGroupedNetworkDay(
				servicesOfServiceProvider,
				'maximum'
			)
			provider.Count = servicesOfServiceProvider.length
		})

		return sortBy(
			serviceProviderIncluded,
			sortingCriteria.by,
			sortingCriteria.direction
		)
	}
)

/**
 * Get the sales representatives included in the list
 * of closed services
 */
export const salesRepresentativesSelector = createSelector(
	closedServicesSelector,
	salesRepSortingCriteria,
	(closedServices, salesRepSortingMap) => {
		let salesRepSorting = toSafeObject(salesRepSortingMap)
		let salesRepsIncluded = GetDistinctEntityFromServiceList(
			closedServices,
			'RepresentativeCode',
			'RepresentativeName'
		)
		salesRepsIncluded.forEach((salesRep) => {
			let servicesOfRep = closedServices.filter((srv) => {
				return srv.RepresentativeCode === salesRep.Code
			})
			if(salesRep.Code === null) { salesRep.Code = 'No Rep' }
			if(salesRep.Name === null) { salesRep.Name = 'No Rep' }
			salesRep.Minimum = getGroupedNetworkDay(servicesOfRep, 'minimum')
			salesRep.Average = getGroupedNetworkDay(servicesOfRep, 'average')
			salesRep.Maximum = getGroupedNetworkDay(servicesOfRep, 'maximum')
			salesRep.Count = servicesOfRep.length
		})
		return sortBy(
			salesRepsIncluded,
			salesRepSorting.by,
			salesRepSorting.direction
		)
	}
)

/**
 * Get the services providers included in the list
 * of closed services
 */
 export const customerSelector = createSelector(
	closedServicesSelector,
	customersSortCriteria,
	(closedServices, sortingCriteriaMap) => {
	let sortingCriteria = toSafeObject(sortingCriteriaMap)
	// let queryObject = toSafeObject(queryParameters)
	let customerIncluded = GetDistinctEntityFromServiceList(
		closedServices,
		'CustomerCode',
		'CustomerName'
		)
		customerIncluded.forEach((customer) => {
			let servicesOfCustomer = closedServices.filter((srv) => {
				return srv.CustomerCode === customer.Code
			})
			customer.Minimum = getGroupedNetworkDay(
				servicesOfCustomer,
				'minimum'
			)
			customer.Average = getGroupedNetworkDay(
				servicesOfCustomer,
				'average'
			)
			customer.Maximum = getGroupedNetworkDay(
				servicesOfCustomer,
				'maximum'
			)
			customer.Count = servicesOfCustomer.length
		})

		return sortBy(
			customerIncluded,
			sortingCriteria.by,
			sortingCriteria.direction
		)
	}
)

export const GetDistinctEntityFromServiceList = (
	services,
	entityCodeProperty,
	entityNameProperty
) => {
	if (!services) {
		return []
	}
	return services.reduce((resultArray, service) => {
		let addedEntity = resultArray.find(
			(entity) => entity.Code === service[entityCodeProperty]
		)
		if (!addedEntity) {
			resultArray.push({
				Code: service[entityCodeProperty],
				Name: service[entityNameProperty]
			})
		}
		return resultArray
	}, [])
}


export const metricsCardSelector = createSelector(
	closedServicesSelector,
	(closedServices) => {
		let allServices = GetDistinctEntityFromServiceList(
			closedServices,
		)
		allServices.forEach((service) => {
			service.Minimum = getGroupedNetworkDay(closedServices, 'minimum')
			service.Average = getGroupedNetworkDay(closedServices, 'average')
			service.Maximum = getGroupedNetworkDay(closedServices, 'maximum')
			service.Count = closedServices.length
		})
		return sortBy(
			allServices,
		)
	}
)