import { makeStyles } from '@material-ui/styles'

/**
 * Service table styles
 */
export const useServiceTableStyles = makeStyles((theme) => ({
    table: {
        overflowY: 'auto'
    },
    newService: {
        color: theme.palette.serviceStatus.newServiceMain
    },
    completedService: {
        color: theme.palette.serviceStatus.suspendedMain
    },
    statusActive:{
        color: theme.palette.serviceStatus.newServiceDark,
    },
    statusAssigned:{
        color: theme.palette.serviceStatus.assignedDark
    },
    statusSuspended:{
        color: theme.palette.serviceStatus.suspendedDark
    },
    statusWaiting:{
        color: theme.palette.serviceStatus.waitingSparePartsMain
    },
    networkDaysLessThan4:{
        background: theme.palette.colors.waiting
    },
    networkDaysLessThan6GreaterThan4:{
        background: theme.palette.colors.green
    },
    greaterThan6:{
        background: theme.palette.colors.red
    },
    root: {
        height: theme.mixins.toolbar.minHeight,
        display: "flex",
        justifyContent: "flex-end",
        gridColumn: 1,
		gridRow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    searchRoot: {
        marginRight:"15px",
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    btnCreateService:{
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    btnConfigureTable: {
        marginRight: theme.spacing(1),
    },
    buttonsGroupContainer:{
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroup:{
        display:"flex",
        alignSelf:"center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(-1),
        border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        "&:hover": {
            backgroundColor: theme.palette.colors.primarySelected,
            color: theme.palette.common.white,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.primaryDisabled,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.MuiButton-root": {
            minWidth:"14px !important",
            height:"2rem !important",
        },
    }
}))


export const FilterSelectStyles = makeStyles((theme) => ({
    root: {
        display: "flex", 
        justifyContent: "center", 
        alignSelf:"center"
    },
    btnFilter: {
        boxShadow: "0px 0px 0px 0px", 
        backgroundColor: theme.palette.colors.grayLayout,
        "&:hover": {
            backgroundColor: theme.palette.colors.grayLayout,
            color: theme.palette.colors.red,
        },
    },
    iconDelete:{
        width: "19px", 
        color: theme.palette.colors.red,
    },
    papperPopper:{
        padding: "7px"
    },
    containerDataFilterSelect:{
        maxHeight: "12rem", 
        overflowY: 'auto'
    },
    actionButtons:{
        display: "flex",
        justifyContent: "flex-end",
        marginTop:"20px"
    },
    buttonsGroupContainer:{
        marginLeft: theme.spacing(2),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroup:{
        "&.MuiButton-outlined": {
          border:"none !important"
        },
    },
    buttonFilter:{
        marginLeft: theme.spacing(1.5),
    },
    buttonFilterSelected:{
        marginLeft: theme.spacing(1.5),
        backgroundColor: theme.palette.colors.purple,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.purple,
			opacity:.75,
            color: theme.palette.common.white,
        },
    }

}))

export const DateRangeSelectStyles = makeStyles((theme) => ({
    root: {
        display: "flex", 
        justifyContent: "center", 
        alignSelf:"center",
    },
    actionButtons:{
        display: "flex",
        justifyContent: "flex-end",
        marginTop:"20px"
    },
    papperPopper:{
        padding: "10px",
    },
    papperPopperMobile:{
        padding: "10px",
        width:"195px",
        overflowX:"hidden",
        overflowY:"hidden"
    },
    containerData:{
        maxHeight: "12rem", 
        overflowX:"hidden",
        overflowY:"hidden"
    },
    buttonsGroupContainer:{
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroup:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        "&:hover": {
            backgroundColor: theme.palette.colors.primarySelected,
            color: theme.palette.common.white,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.primaryDisabled,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
    },
    titlePopper:{
        color: theme.palette.colors.purple,
    },
    flex:{
        display:"flex"
    },
    flexGrow:{
        flexGrow:1
    },
    closedButton:{
        color:theme.palette.colors.red
    }

}))