import React, { useEffect, useState } from 'react'
import DesktopView from './components/DesktopView'
import { useDispatch, useSelector } from 'react-redux'
import AttachmentDialog from './components/AttachmentDialog'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'


// import actions section
import { toggleSwitch, AddWarrantySeriesSap, loadAllWarrantySeriesSap, downloadWarrantySeriesList } from '../../store/parserSeriesSap/ParserSeriesSapActions'
import Toaster from '../common/Toaster'

const Index = () => {
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	

	const isSaving = useSelector((state) => state.warrantySeriesSap.get('isSaving'))

	const [query, setQuery] = useState('')
	const pagination = useSelector((state) => state.warrantySeriesSap.get('pagination'))
	const isLoading = useSelector((state) => state.warrantySeriesSap.get('isLoading'))

    const warrantySeriesSap = useSelector((state) => state.warrantySeriesSap.get('items'))
	
	const userProfile = useSelector((state) => state.session.get('profile'))
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

	// Load warranty series sap
	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query) setQuery(queryParameters.query)
		dispatch(loadAllWarrantySeriesSap(queryParameters))
	}, [dispatch, location.search])


	
	const onAddWarrantySeriesSap = (pdfFile) => {
        dispatch(AddWarrantySeriesSap(pdfFile))
            .then(_ => {
                onOpenAttanchemtDialog()
                setToasterOptions({ open: true, message: 'File upload success', variant: 'success' })

            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add warranty series SAP', variant: 'warning' })
            });
    }

		/**
	 * Update sort criteria
	 */
		const updateSortCriteria = (criteria) => {
			const queryParams = queryString.parse(location.search)
			queryParams.sortBy = criteria.by
			queryParams.sortDirection = criteria.direction
			history.push({ search: queryString.stringify(queryParams) })
		}

		const updateSearchQuery = (searchQuery) => {
			const queryParams = queryString.parse(location.search)
			if (searchQuery) {
				queryParams.query = searchQuery
				setQuery(searchQuery)
			} else {
				delete queryParams.query
				setQuery('')
			}
			history.push({ search: queryString.stringify(queryParams) })
		}

		/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}




    const [attanchmentDialog, setAttachmentDialog] = useState(false)

	const onOpenAttanchemtDialog = () => {
        setAttachmentDialog(!attanchmentDialog)
    }
  
	const onDownloadDocument = () => {
		dispatch(downloadWarrantySeriesList())
	}
	
	return (
		<>
		<DesktopView 
		isLoading={isLoading}
		allSeries = {warrantySeriesSap}
		searchValue={query}
		onChangeSortingCriteria={updateSortCriteria}
		onChangeSearchValue={(query) => updateSearchQuery(query)}
		onChangePage={onChangePage}
		pagination={pagination}
		userProfile={userProfile}
		onOpenAttanchemtDialog={onOpenAttanchemtDialog}
		onDownloadDocument = {onDownloadDocument}
		toggleSwitch={toggleSwitch}
		isSaving={isSaving}
		
		/>
		{
			attanchmentDialog && < AttachmentDialog
			title={"ADD WARRANTY SERIES"}
			open={attanchmentDialog}
			onClose={onOpenAttanchemtDialog}
			onSaveEvidence={onAddWarrantySeriesSap}
			isSaving={isSaving}

		/>
		}
		{/** Toaster */}
		{
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
		</>
	)
}

export default Index
