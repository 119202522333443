/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
/**Import Material UI section */
import {
	Stepper,
	Step,
	StepLabel,
	Typography,
	StepContent,
	Grid,
	Tooltip,
	IconButton,
} from '@material-ui/core'
/**Import Components section */
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import TextBox from '../../common/TextBox'

/**Import Helper section */
import { toSafeObject } from '../../../store/helpers/StateHelper'
import ConfirmCommentHistoryUpdateDialog from './ConfirmCommentHistoryUpdateDialog'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'

const TimeLineCommentHistory = (props) => {
	const {
		serviceToken,
		onUpdateCommentHistory,
		onUpdateComment,
		isAuthorizedForChangingStatus,
		isAuthorizedForCreatingComment
	} = props
	const [activeStep, setActiveStep] = useState(0)
	const [viewAllHistory, setViewAllHistory] = useState(null)
	const [comments, setComments] = useState([])
	const [isOpenModal, setIsOpenModal] = React.useState(false)
	const [commentsToken, setCommentsToken] = React.useState('')

	useEffect(() => {
		setComments(props.commentsHistory)
	}, [props.commentsHistory])

	const [activeComment, setActiveComment] = useState('')
	const internalUsers = useSelector((state) =>
		toSafeObject(state.users.get('internalUsers'))
	)

	const onClickShowItem = (event, stepNumber) => {
		event.stopPropagation()
		setActiveStep((prevActiveStep) => stepNumber)
		setActiveComment(comments[stepNumber].Comments)
	}

	const onSaveStatus = (commentToken) => {
		if (onUpdateCommentHistory) {
			onUpdateCommentHistory(serviceToken, commentToken)
			let newCommentsList = comments.map((c) => {
				if (c.Token === commentToken) {
					c.Status = 2
				}
				return c
			})
			setComments(newCommentsList)
		}
	}

	const onChangeComment = (event, commentToken) => {
		event.stopPropagation()
		let newCommentValue = event.target.value
		let newCommentsList = comments.map((c) => {
			if (c.Token === commentToken) {
				c.Comments = newCommentValue
			}
			return c
		})
		setActiveComment(newCommentValue)
		setComments(newCommentsList)
	}

	const onSaveComment = (event, commentToken) => {
		if (onUpdateComment) {
			onUpdateComment(serviceToken, commentToken, activeComment)
		}
	}
	const viewAll = (event, index) => {
		event.stopPropagation()
		setViewAllHistory(index)
	}

	const onOpenModal = (event, commentToken) => {
		setIsOpenModal(!isOpenModal)
		setCommentsToken(commentToken)
	}

	const onCloseModal = () => {
		setIsOpenModal(false)
	}

	const onSaveCommentFromModal = () => {
		if (onUpdateComment) {
			onUpdateComment(serviceToken, commentsToken, activeComment)
		}
	}

	return (
		<div>
			{comments !== null ? (
				<Typography variant='h6'>Comments history</Typography>
			) : (
				<Typography variant='h6'>
					There are no comments in the history to show
				</Typography>
			)}
			{comments !== null && (
				<Stepper
					activeStep={activeStep}
					orientation='vertical'
					style={{ margin: 0, padding: 0 }}
				>
					{comments.map((comment, index) => {
						let nameOfUser = comment.CreatorUser
							? internalUsers.find(
								(x) =>
									x.UserName ===
									comment.CreatorUser.UserName
							)?.Name
							: ''
						let statusText = comment.Status
							? comment.Status === 2
								? 'Published'
								: comment.Status === 1
									? 'In Process'
									: 'Undefined'
							: 'Undefined'
						let enableActions =
							comment.Status === 1 &&
							index === activeStep &&
							isAuthorizedForChangingStatus
						let activateComments =
							index === activeStep &&
							isAuthorizedForChangingStatus
						let activateCommentsRepSales = index === activeStep
						return isAuthorizedForCreatingComment ||
							comment.Status === 2 ? (
							<Step key={index}>
								<StepLabel
									onClick={(event) =>
										onClickShowItem(event, index)
									}
								>
									{!enableActions && (
										<div style={{ display: 'flex' }}>
											{viewAllHistory === index ? <Typography variant='body1'>
												{comment.CreationType === 1 &&
													comment.CreatorUser.Account
														.Name !== 'KONOP'
													? `${moment(
														comment.CreationDate
													).format(
														'MMM DD, YYYY - MM/D'
													)}- ${comment.Comments}`
													: `${comment.Comments}`}
											</Typography> :
												<Typography variant='body1' style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '640px', whiteSpace: 'nowrap' }}>
													{comment.CreationType === 1 &&
														comment.CreatorUser.Account
															.Name !== 'KONOP'
														? `${moment(
															comment.CreationDate
														).format(
															'MMM DD, YYYY - MM/D'
														)}- ${comment.Comments}`
														: `${comment.Comments}`}
												</Typography>} <Tooltip component="span" title={viewAllHistory === index ? 'less' : 'more'}><IconButton onClick={viewAllHistory === index ? (e) => viewAll(e, null) : (e) => viewAll(e, index)}>{viewAllHistory === index ? <ArrowDropUp onClick={(e) => viewAll(e, null)} style={{ cursor: 'pointer' }} /> : <ArrowDropDown style={{ cursor: 'pointer' }} />}</IconButton></Tooltip>
										</div>
									)}
									{(activateComments ||
										activateCommentsRepSales) && (
											<Grid item xs={12}>
												<br />
												<TextBox
													active={true}
													fullWidth
													name='comments'
													variant='outlined'
													margin='dense'
													label='COMMENTS'
													multiline
													rows={3}
													autoFocus
													onChange={(event) =>
														onChangeComment(
															event,
															comment.Token
														)
													}
													value={
														activeComment
															? activeComment
															: ''
													}
													disabled={
														!isAuthorizedForCreatingComment
													}
												/>
											</Grid>
										)}
									<Grid
										item
										xs={12}
										style={{
											display: 'flex',
											justifyContent: 'flex-end'
										}}
									>
										{isAuthorizedForCreatingComment &&
											(activateComments ||
												activateCommentsRepSales) && (
												<ButtonSaved
													title='Save'
													onSavedClick={
														index === 0
															? (event) =>
																onOpenModal(
																	event,
																	comment.Token
																)
															: (event) =>
																onSaveComment(
																	event,
																	comment.Token
																)
													}
												/>
											)}
									</Grid>
								</StepLabel>
								<StepContent>
									<Typography
										color='textSecondary'
										variant='caption'
									>{`${statusText} - ${moment(
										comment.CommentCreationDate
									).format('MM/DD/YYYY')}`}</Typography>
									<br />
									<Typography
										color='textSecondary'
										variant='caption'
									>
										{nameOfUser
											? nameOfUser
											: comment.CreatorUser.UserName}
									</Typography>
									<br />
									{comment.Status === 1 &&
										index === activeStep && <br />}
									{enableActions && (
										<ButtonSaved
											title='Published'
											onSavedClick={(event) =>
												onSaveStatus(comment.Token)
											}
										/>
									)}
								</StepContent>
							</Step>
						) : (
							<></>
						)
					})}
				</Stepper>
			)}
			<ConfirmCommentHistoryUpdateDialog
				open={isOpenModal}
				onClose={onCloseModal}
				onSaveCommentFromModal={onSaveCommentFromModal}
			/>
		</div>
	)
}

export default TimeLineCommentHistory
