/**Import react section */
import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import moment from 'moment'
/**Import material ui section */
import { Typography, Grid, Divider, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

/**Import components section */
import CardComponent from '../../common/mobile/CardComponent'
import CardMobileSkeleton from '../../skeletons/services/componets/CardMobile'
/**Import resources styles */
import { ServiceCardMobileStyle } from '../../../resources/styles/mobile/ServiceCardMobileStyle'
import CallIcon from '@material-ui/icons/Call'
import DateRangeIcon from '@material-ui/icons/DateRange'
import NoDateIcon from '@material-ui/icons/EventBusy'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import PopperComponent from '../componentsMobile/AttentionDatePopper'

/**Import store section */
import { getUserByUserName } from '../../../store/helpers/UserHelper'
import { toSafeObject } from '../../../store/helpers/StateHelper'
import { hasAuthorization } from '../../../store/session/sessionActions'
import { SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN } from '../../../resources/RolesEnum'
const ServicesCards = (props) => {
    const classes = ServiceCardMobileStyle();
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    /**Array props section */
    const { services, userProfile } = props

    /**Constant functions */
    const { redirectToServiceDetail, isLoading, onAddDateAttention } = props
    const [settingsEl, setSettingsEl] = useState(null);
    const [dateAtention, setDateAtention] = useState(null);
    const [serviceSelected, setServiceSelected] = useState(null);
    let canBeAsignedDate = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN])

    let dispatcherService = getUserByUserName(internalUsers, serviceSelected ? serviceSelected.Dispatcher : serviceSelected)
    const onCardClick = (item) => {
        if (redirectToServiceDetail) {
            redirectToServiceDetail(item.Token, item.Serie)
        }
    }


    const onRenderIconDateAttention = (differenceInDays, attentionDate) => {
        return (
            <div className={classes.flex}>
                {attentionDate &&<Typography variant="subtitle2" align="right"> {`${differenceInDays} Days`}</Typography>}
                {!attentionDate &&<Typography variant="subtitle2" align="right"> {`No date`}</Typography>}
                {attentionDate && <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    className={differenceInDays <= 0 ? classes.iconButtonImportantRed : (differenceInDays >= 0 &&differenceInDays < 5)?classes.iconButtonImportantYellow: classes.iconButtonImportantGreen}
                    size="small"
                >
                    <NotificationImportantIcon size="small" />
                </IconButton>}
                {!attentionDate && <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    className={classes.iconButtonImportantRed}
                    size="small"
                >
                    <NoDateIcon size="small" />
                </IconButton>}
            </div>
        )
    }
    
    /**Close menu popper filter */
    const onHandleNullSetting = () => {
        setServiceSelected(null)
        setSettingsEl(null);
    };

    /**Hanlde  menu open popper */
    const handleMenuOpen = (event, service) => {
        event.stopPropagation()
        setSettingsEl(event.currentTarget);
        setServiceSelected(service)
    };

    /**On change properties */
    const onPropertyChange = event => {
        let { value } = event.target;
        setDateAtention(value);
    }

    const onUpdateAttentionDate = () => {
        let dateAtentionModel = {
            comments: serviceSelected.TechnicianComments,
            dateAttention: dateAtention,
        }
        if (onAddDateAttention) {
            onAddDateAttention(serviceSelected, dateAtentionModel, dispatcherService)
            setServiceSelected(null)
            setSettingsEl(null);
        }

    }

    /**Render text with name color bold */
    const onRenderText = (titleBold, title, phoneNumber, attentionDate, DifferenceInDays, service) => {
        return (
            <>
                <div className={classes.flex}>
                    {
                        service && service.TechnicianServiceDate && <>
                            <Typography variant="subtitle2" className={classes.titleBold}> {"Date attention:"}</Typography>
                            <Typography variant="subtitle2" align="right"> {`${attentionDate ? moment(attentionDate).format("MM/DD/YYYY") : "-"}`}</Typography>

                            <div className={classes.grow} />
                            <>
                                {
                                    canBeAsignedDate && <DateRangeIcon color="primary" className={classes.calendarIcon} size="small" onClick={settingsEl ? onHandleNullSetting : (event) => handleMenuOpen(event, service)} />
                                }
                            </>
                        </>
                    }
                    {
                        titleBold && <><Typography variant="subtitle2" className={classes.titleValue}>
                            <strong className={classes.titleBold}>{titleBold}</strong>
                            {title}
                        </Typography>
                            <br /><div className={classes.grow} />
                        </>
                    }

                    {
                        phoneNumber &&
                        <Typography noWrap variant="subtitle2" className={classes.titlePhone}>
                            <CallIcon className={classes.iconButtonPhone} size="small" /> {phoneNumber}
                        </Typography>
                    }
                </div>
                <Divider />
            </>
        )

    }

    /**Render content card text */
    const onRenderContenCard = (service) => {
        return (
            <>
                {service.TechnicianServiceDate ? null : <div style={{ display: "flex" }}>
                    <Typography className={classes.titleBold}>No date attention</Typography>
                    <div className={classes.grow} />
                    { canBeAsignedDate && <DateRangeIcon color="primary" className={classes.calendarIcon} size="small" onClick={settingsEl
                     ? onHandleNullSetting : (event) => handleMenuOpen(event, service)} />}
                </div>}
                {service.Serie && onRenderText("", "", "", service.TechnicianServiceDate, service.DifferenceInDays, service)}
                {service.Serie && onRenderText("Serie:", service.Serie)}
                {(service.SiteContactName || service.SiteContactPhone) && onRenderText("Site contact name:", service.SiteContactName, service.SiteContactPhone, "", "")}
                {(service.ContactName || service.ContactPhone) && onRenderText("Contact name:", service.ContactName, service.ContactPhone)}
                {service.full_address && onRenderText("Address:", service.full_address)}
            </>
        )
    }

    const onRenderCardContent = () => {
        if (isLoading) {
            return <CardMobileSkeleton />
        }
        else {
            return (
                <Grid
                    container
                    spacing={1}
                >
                    {
                        services.length !== 0 ? services.map((service) => {
                            return (
                                <Grid
                                    key={service.Id}
                                    item
                                    xs={12}
                                    sm={6}
                                >
                                    <CardComponent
                                        title={`Ticket: ${service.Ticket}`}
                                        onRenderContent={() => onRenderContenCard(service)}
                                        item={service}
                                        onCardClick={onCardClick}
                                        onRenderContentRight={() => onRenderIconDateAttention(service.DifferenceInDays, service.TechnicianServiceDate)}
                                        changeHeaderColor={service.TechnicianServiceDate ? false : true}
                                    /><br />
                                </Grid>
                            )
                        }) : <Alert severity="info" icon={false}>{" It has no services assigned by your administrator."}</Alert>
                    }

                </Grid>

            )
        }
    }

    return <>
        {onRenderCardContent()}

        <PopperComponent
            settingsEl={settingsEl}
            setSettingsEl={onHandleNullSetting}
            onPropertyChange={onPropertyChange}
            value={dateAtention}
            onButtonSavedClick={onUpdateAttentionDate}
            title="Add attention date"

        />
    </>
}

export default ServicesCards
