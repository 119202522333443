/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import { Layout, Content, Header } from '../../layouts/AuthenticatedLayout'
import ServiceSelector from '../../services/components/ServiceSelector'
import Toolbar from '../../common/Toolbar'
import Authorization from '../../common/AuthorizationEvaluation'
import AddressComponent from '../components/Address'
import AlertComponent from '../../common/AlertComponent'
import EquipmentsSapMovil from './EquipmentsSapMovil'
import ExpansionComponent from './SparePartsExpansion'

/** Import material UI */
import { Card, CardContent, Paper, Chip, Grid } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
/** Import styles */
import { showMobileStyles } from '../resources/ShowMobileStyles'
import { toolbarStyles } from '../../../resources/styles/ToolbarStyles'
import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	IMBERA_TECHNICIAN,
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER
} from '../../../resources/RolesEnum'
import iconSparePart from '../../../resources/images/sparePart.svg'
import { GetBackgroundColorSparePartRequestByStatus } from '../../../store/helpers/StatusHelper'

import ShowMobileSkeleton from '../../skeletons/spareParts/ShowMobileSkeleton'

/**
 * View create desktop
 */
const CreateMovilView = (props) => {
	const {
		userProfile,
		isLoadingFromSAP,
		sparePartsSap,
		querySparePart,
		onSearchSerieInSap,
		equipmentsSap,
		onAddSparePart,
		onUpdateQuantityEquipment,
		onChangeQuerySap,
		isGettingDraft
	} = props

	const [serieSelected, setSerieSelected] = useState(null)
	const [status, setStatus] = useState(null)
	const [expanded, setExpanded] = React.useState('')
	const classes = showMobileStyles()
	const toolbarStylesClasses = toolbarStyles()

	useEffect(() => {
		if (!!props.request.draft && props.request.draft.Status) {
			setStatus(props.request.draft.Status)
		}
	}, [props.request.draft])

	/**
	 * Search materials with function from sap
	 * @param {*} equipment
	 */
	const onSearchSerie = (equipment) => {
		if (onSearchSerieInSap) {
			onSearchSerieInSap(equipment.Serie)
			setSerieSelected(equipment.Serie)
		}
	}

	/**
	 * Add spare part in request selected
	 * @param {*} equipment
	 * @param {*} sparePart
	 */
	const onButtonAddClick = (equipment, sparePart) => {
		if (onAddSparePart) {
			let addEquipment = {
				Serie: serieSelected,
				CustomerCode: equipment.CustomerCode,
				CustomerName: equipment.CustomerName
			}
			onAddSparePart(addEquipment, sparePart)
		}
	}

	let isAuthorizadoForEditedData = hasAuthorization(userProfile, [
		IMBERA_TECHNICIAN,
		SERVICE_PROVIDER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER
	])
	const colorByStatus =
		status && GetBackgroundColorSparePartRequestByStatus(status, toolbarStylesClasses)
	const onRenderViewService = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{
					<Authorization profile={userProfile} allowedRoles={[IMBERA_TECHNICIAN]}>
						{!!props.request.draft && props.request.draft && (
							<Chip
								label='CHANGE STATUS'
								clickable
								color='primary'
								onClick={() =>
									props.openLateralPanelChangeStatus(props.request.draft)
								}
							/>
						)}
					</Authorization>
				}
				{props.request.draft && props.request.draft.CanBeSendToValidate && (
					<Authorization
						profile={userProfile}
						allowedRoles={[SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN]}
					>
						<Chip
							label='SUBMIT'
							clickable
							color='primary'
							onClick={() => props.openLateralPanelSendProvider(props.request.draft)}
						/>
					</Authorization>
				)}
			</div>
		)
	}

	/**
	 * On select expander serial
	 * @param {*} panel
	 */
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return (
		<Layout>
			<Header key={'AuthenticatedHeader'} />
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={3}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar
						title='CREATE SPARE PART REQUEST'
						renderContentRight={onRenderViewService()}
						icon={iconSparePart}
						renderBackgroundColor={colorByStatus}
					/>

					<Card>
						<CardContent>
							<ServiceSelector
								autoFocus
								placeholder='Select the service'
								services={props.activeServices}
								onChange={props.onServiceSelected}
							/>
						</CardContent>
					</Card>
					{isGettingDraft ? (
						<ShowMobileSkeleton />
					) : (
						<div>
							<Paper>
								<div className={classes.root}>
									{props.request.equipments.map((item) => {
										return (
											<ExpansionComponent
												key={item.Serie}
												expanded={expanded}
												item={item}
												isCreation={true}
												handleChange={handleChange}
												onSearchSerie={onSearchSerie}
												onRemoveSparePart={props.onRemoveSparePart}
												isSaving={props.request.isSavingDraft}
												isAuthorizadoForEditedData={
													isAuthorizadoForEditedData
												}
												identifierSparePartDelete={
													props.identifierSparePartDelete
												}
												isLoadingFromSAP={isLoadingFromSAP}
											/>
										)
									})}
								</div>

								<Grid item xs={12}>
									{isLoadingFromSAP && (
										<>
											<br />
											<AlertComponent
												title={'Getting series from SAP'}
												message={
													'This process may take a while, please wait...'
												}
												severityType={'info'}
											/>
											<LinearProgress />
										</>
									)}
									<EquipmentsSapMovil
										equipmentsSap={equipmentsSap}
										onAddSparePart={onButtonAddClick}
										onUpdateQuantityEquipment={onUpdateQuantityEquipment}
										onChangeQuerySap={onChangeQuerySap}
										sparePartsSap={sparePartsSap}
										querySparePart={querySparePart}
										isModeCreate={true}
										isMobile={props.isMobile}
									/>
								</Grid>
							</Paper>

							{props.request.draft && (
								<Card>
									<CardContent>
										<AddressComponent
											onSaveSparePartRequest={props.onSaveSparePartRequest}
											requestDraft={props.request.draft}
											isSaving={props.request.isSavingDraft}
											isAuthorizadoForEditedData={isAuthorizadoForEditedData}
											isVisibleChipsAssignement={true}
											isMobile={true}
										/>
									</CardContent>
								</Card>
							)}
						</div>
					)}
				</div>
			</Content>
		</Layout>
	)
}

CreateMovilView.propTypes = {
	/**
	 * The list of all the active services, shown in the service selector
	 */
	activeServices: propTypes.array.isRequired,
	/**
	 * Callback executed when an active service is selected
	 */
	onServiceSelected: propTypes.func.isRequired,
	/**
	 * Callback executed when the user save or add a spare part
	 */
	onAddSparePart: propTypes.func.isRequired,
	/**
	 * Callback executed when removing a spare part
	 */
	onRemoveSparePart: propTypes.func,
	/**
	 * The edited spare part request
	 */
	request: propTypes.object.isRequired,
	/**Function invoke callback for open searh materials in sap equipments*/
	onSearchSerieInSap: propTypes.func.isRequired,
	/**Function invoke callback for update quatity in redux store */
	onUpdateQuantityEquipment: propTypes.func.isRequired
}
CreateMovilView.defaultProps = {
	activeServices: [],
	request: {
		service: null,
		spareParts: []
	},
	equipmentsSap: [],
	onSearchSerieInSap: () => console.warn('Callback [onSearchSerieInSap] no defined'),
	onAddSparePart: () => console.warn('Callback [onAddSparePart] no defined'),
	onUpdateQuantityEquipment: () => console.warn('Callback [onUpdateQuantityEquipment] no defined')
}

export default withImmutablePropsToJS(CreateMovilView)
