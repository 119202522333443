import React, { useEffect, useState } from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DesktopView from './components/IndexClosedDesktopView'
import MobileViewClosed from './componentsMobile/MobileViewClosed'
import {
	loadServices,
	loadDashboardServices,
	downloadReportOpenServices
} from '../../store/services/serviceActions'
import { loadAllServiceProviderWithFilters } from '../../store/serviceproviders/serviceProvidersActions'
import { loadAllSalesRepresentatives } from '../../store/salesrepresentatives/salesRepresentativesActions'
import { loadInternalUsers } from '../../store/users/UsersActions'
import { loadAllRetailss } from '../../store/retails/retailsActions'
import { toSafeObject } from '../../store/helpers/StateHelper'

/***
 * Dashboard view container
 *
 *
 * @param {*} props
 * @returns
 */
const IndexClosed = (props) => {
	const isMobile = useIsMobile(props.isMobile)

	const servicesList = useSelector((state) =>
		toSafeObject(state.services.get('servicesList'))
	)
	const isLoading = useSelector((state) => state.services.get('isLoading'))
	const isDownloadReport = useSelector((state) => state.services.get('isDownloadReport'))
	const isLoadingDashboard = useSelector((state) =>
		state.services.get('isLoadingDashboard')
	)
	const dashboard = useSelector((state) => state.services.get('dashboard'))

	const serviceProviders = useSelector((state) =>
		state.serviceProviders.get('serviceProviders')
	)
	const salesRepresentatives = useSelector((state) =>
		state.salesRepresentatives.get('salesRepresentatives')
	)
	const retails = useSelector((state) => state.retails.get('retails'))
	const internalUsers = useSelector((state) =>
		state.users.get('internalUsers')
	)
	const userProfile = useSelector((state) => state.session.get('profile'))

	/**Local store this component section */
	const [query, setQuery] = useState('')
	const dispatch = useDispatch()

	let location = useLocation()
	let history = useHistory()

	/**
	 * Load services list
	 */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadServices("closed", queryParams)).then(() => { })
	}, [dispatch, location.search, history])

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		if(servicesList.services && servicesList?.services.length === 0 && queryParams.pageNumber >1)
			{
				queryParams.pageNumber = 1
				history.push({ search: queryString.stringify(queryParams) })
			}
	}, [servicesList, location.search, history])

	/**
	 * Load dasborad list
	 */
	useEffect(() => {
		dispatch(loadDashboardServices("closed")).then(() => { })
	}, [dispatch])

	useEffect(() => {
		dispatch(loadAllServiceProviderWithFilters(null, 1000))
		dispatch(loadAllSalesRepresentatives(null, 1000))
		dispatch(loadAllRetailss())
		dispatch(loadInternalUsers())
	}, [dispatch])

	/**
	 * On change page number
	 */
	const onChangeServicePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change sortin criteria
	 * @param {} sender
	 * @param {*} sortingCriteria
	 */
	const onchangeServiceSortingCriteria = (sortingCriteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortingCriteria.by
		queryParams.sortDirection = sortingCriteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**Search value */
	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeLocation = (location) => history.push(location)

	const redirectToServiceDetail = (token, serie) => {
		history.push(`/services/${token}/${serie}`)
	}

	const onDownloadReportOpenServices = (startDate, endDate) => {
		const queryParams = queryString.parse(location.search)
		return dispatch(downloadReportOpenServices(toSafeObject(internalUsers), "closed", startDate, endDate, queryParams))
			.then(() => {
				return Promise.resolve();
			})
			.catch((error) => {
				console.error("Failed to download report services open", error);
			});
	};

	return (
		<>
			{
				isMobile ?
					<MobileViewClosed
						services={servicesList.services}
						totalServicesPages={servicesList.total}
						servicePageNumber={servicesList.pageNumber}
						onChangeServicePage={onChangeServicePage}
						onchangeServiceSortingCriteria={onchangeServiceSortingCriteria}
						sortServiceBy={servicesList.orderBy}
						sortServiceDirection={servicesList.orderType}
						isLoading={isLoading}
						isLoadingDashboard={isLoadingDashboard}
						dashboard={dashboard}
						serviceProviders={serviceProviders}
						salesRepresentatives={salesRepresentatives}
						retails={retails}
						searchValue={query}
						onChangeSearchValue={(query) => updateSearchQuery(query)}
						internalUsers={internalUsers}
						onChangeLocation={onChangeLocation}
						redirectToServiceDetail={redirectToServiceDetail}
						userProfile={userProfile}
						onDownloadReportOpenServices={onDownloadReportOpenServices}
						isDownloadReport={isDownloadReport}

					/> :
					<DesktopView
						services={servicesList.services}
						totalServicesPages={servicesList.total}
						servicePageNumber={servicesList.pageNumber}
						onChangeServicePage={onChangeServicePage}
						onchangeServiceSortingCriteria={onchangeServiceSortingCriteria}
						sortServiceBy={servicesList.orderBy}
						sortServiceDirection={servicesList.orderType}
						isLoading={isLoading}
						isLoadingDashboard={isLoadingDashboard}
						dashboard={dashboard}
						serviceProviders={serviceProviders}
						salesRepresentatives={salesRepresentatives}
						retails={retails}
						searchValue={query}
						onChangeSearchValue={(query) => updateSearchQuery(query)}
						internalUsers={internalUsers}
						onChangeLocation={onChangeLocation}
						redirectToServiceDetail={redirectToServiceDetail}
						userProfile={userProfile}
						onDownloadReportOpenServices={onDownloadReportOpenServices}
						isDownloadReport={isDownloadReport}
					/>
			}
		</>
	)
}
export default IndexClosed
