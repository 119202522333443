/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import AddUserPanel from './components/AddUserPanel'

/**Import actions sections */
import { addNewUser } from '../../../store/users/UsersActions'

const Create = (props) => {
    const { isOpenLateralPanelAdd, openLateralPanelOnClick, isSaving } = props;
	/**Hooks sections */
    const dispatch = useDispatch()
    
    /**
     * Handler to add a new user.
     * Execute async action to add new user.
     * @param {object} user
     */
    const addUserHandler = user => {
        dispatch(addNewUser(user))
            .then(_ => openLateralPanelOnClick());
    };

    return (
        <>
            {
                isOpenLateralPanelAdd &&
                <AddUserPanel
                    onSaveButtonClick={addUserHandler}
                    onCloseButtonClick={openLateralPanelOnClick}
                    isSaving={isSaving}
                />
            }

        </>
    )
}

Create.propTypes = {
	/**
	 * Determine if the isSaving data in database
	 */
	isSaving: propTypes.bool.isRequired,

	/**
	 * Opens side panel to add new  user's
	 */
	openLateralPanelOnClick: propTypes.func.isRequired,
}
Create.defaultProps = {
	isSaving: false,
	openLateralPanelOnClick: () =>
		console.warn('Callback [openLateralPanelOnClick] no defined'),
}

export default Create
