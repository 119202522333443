const ServiceProviderRegisterTableSearchConfig = [
	{
		title: 'Gae',
		dataSource: 'EXTWG',
		sortable: true,
		width: 150
	},
	{
		title: 'Material Code',
		dataSource: 'MATNR',
		sortable: true,
		width: 250
	},
	{
		title: 'Material Description',
		dataSource: 'MAKTX',
		sortable: true,
		width: 500
	},
	{
		title: 'Article Group',
		dataSource: 'EWBEZ',
		width: 250
	}
]
export default ServiceProviderRegisterTableSearchConfig
