import React from 'react'
import { ServicesStatusTypes, SpareParRequestStatus, PaymentRequestStatus,
    PaymentAmountType } from './AppConstants'
import { Typography } from '@material-ui/core'

/**Import styles section */
import { useServiceTableStyles } from '../../resources/styles/ServicesListStyles'
import { toolbarStyles } from '../../resources/styles/ToolbarStyles'

import iconLaboral from '../../resources/images/amounts/laboral.svg';
import iconMaterials from '../../resources/images/amounts/materials.svg';
import iconMileage from '../../resources/images/amounts/mileage.svg';
import iconMicellaneous from '../../resources/images/amounts/micellaneous.svg';
import iconSales from '../../resources/images/amounts/sales.svg';
import iconTotal from '../../resources/images/amounts/total.svg';

/**
 * Function to get description of status of a service
 * @param {*} statusId 
 */
export function GetServiceStatus(statusId) {
    const classes = useServiceTableStyles()
    switch (Number(statusId)) {
        case ServicesStatusTypes.ACTIVE:
            return <Typography variant='subtitle2' className={classes.statusActive}>Active</Typography>
        case ServicesStatusTypes.ASSIGNED:
            return <Typography variant='subtitle2' className={classes.statusAssigned}>Assigned</Typography>
        case ServicesStatusTypes.SUSPENDED:
            return <Typography variant='subtitle2' className={classes.statusSuspended}>Suspended</Typography>
        case ServicesStatusTypes.WAITING:
            return <Typography variant='subtitle2' className={classes.statusWaiting}>Waiting</Typography>
        case ServicesStatusTypes.COMPLETED:
            return <Typography variant='subtitle2'>Completed</Typography>
        case ServicesStatusTypes.REPLACED:
            return <Typography variant='subtitle2'>Replaced</Typography>
        case ServicesStatusTypes.CANCELLED:
            return <Typography variant='subtitle2'>Cancelled</Typography>
        default:
            return <Typography variant='subtitle2'>Undefined</Typography>
    }
}

/**
 * Get color for newwor days condition
 * @param {*} networkDays 
 */
export function GetColorNetWorkDays(networkDays) {
    const classes = useServiceTableStyles()
    if (networkDays < 4)
        return <Typography variant='subtitle2' align="center" className={classes.networkDaysLessThan4}>{' '}{networkDays}</Typography>
    else if (networkDays > 3 && networkDays < 6)
        return <Typography variant='subtitle2' align="center" className={classes.networkDaysLessThan6GreaterThan4}>{' '}{networkDays}</Typography>
    else if (networkDays >= 6)
        return <Typography variant='subtitle2' align="center" className={classes.greaterThan6}>{' '}{networkDays}</Typography>
    else
        return <Typography variant='subtitle2' align="center">{' '}{networkDays}</Typography>
}


export function GetBackgroundColorServiceByStatus(status) {
    const classes = toolbarStyles()
    switch (Number(status)) {
        case ServicesStatusTypes.ACTIVE:
            return (classes.active)
        case ServicesStatusTypes.ASSIGNED:
            return (classes.assigned)
        case ServicesStatusTypes.SUSPENDED:
            return (classes.suspended)
        case ServicesStatusTypes.WAITING:
            return (classes.waiting)
        case ServicesStatusTypes.COMPLETED:
            return (classes.completed)
        case ServicesStatusTypes.REPLACED:
            return (classes.replaced)
        case ServicesStatusTypes.CANCELLED:
            return (classes.cancel)
        default:
            return null
    }
}

/** Color spare part request */
export function GetBackgroundColorSparePartRequestByStatus(status, classes) {
    if (status && classes) {
        switch (Number(status)) {
            case SpareParRequestStatus.DRAFT:
                return (classes.active)
            case SpareParRequestStatus.SEND_TECH_USA:
                return (classes.assigned)
            case SpareParRequestStatus.IN_PROCESS:
                return (classes.waiting)
            case SpareParRequestStatus.SEND_TO_MX:
                return (classes.assigned)
            case SpareParRequestStatus.CLOSED:
                return (classes.completed)
            case SpareParRequestStatus.CANCELLED:
                return (classes.cancel)
            case SpareParRequestStatus.REJECTED_RESEND:
                return (classes.cancel)
            default:
                return (classes.completed)
        }
    }
    return null
}

/** Color service */
export function GetColorStatusService(status, classes) {
    if (status && classes) {
        switch (Number(status)) {
            case ServicesStatusTypes.ACTIVE:
                return (classes.active)
            case ServicesStatusTypes.ASSIGNED:
                return (classes.assigned)
            case ServicesStatusTypes.SUSPENDED:
                return (classes.suspended)
            case ServicesStatusTypes.WAITING:
                return (classes.waiting)
            case ServicesStatusTypes.COMPLETED:
                return (classes.completed)
            case ServicesStatusTypes.REPLACED:
                return (classes.replaced)
            case ServicesStatusTypes.CANCELLED:
                return (classes.cancel)
            default:
                return (classes.completed)
        }
    }
    return null
}

/**
 * Color spare part
 * @param {*} status 
 * @param {*} classes 
 */
export function GetColorStatusRequest(status, classes) {
    if (status && classes) {
        switch (Number(status)) {
            case SpareParRequestStatus.DRAFT:
                return (classes.active)
            case SpareParRequestStatus.SEND_TECH_USA:
                return (classes.assigned)
            case SpareParRequestStatus.IN_PROCESS:
                return (classes.waiting)
            case SpareParRequestStatus.SEND_TO_MX:
                return (classes.assigned)
            case SpareParRequestStatus.CLOSED:
                return (classes.completed)
            case SpareParRequestStatus.REJECTED_RESEND:
            case SpareParRequestStatus.CANCELLED:
                return (classes.cancel)
            default:
                return (classes.completed)
        }
    }
    return null
}

/** Color payment request */
export function GetColorPaymentRequestByStatus(status, classes) {
    if (status && classes) {
        switch (Number(status)) {
            case PaymentRequestStatus.DRAFT:
                return (classes.active)
            case PaymentRequestStatus.IN_PROCESS:
                return (classes.waiting)
            case PaymentRequestStatus.ACCEPTED:
            case PaymentRequestStatus.CLOSED:
                return (classes.completed)
            case PaymentRequestStatus.REJECTED:
                return (classes.cancel)
            default:
                return (classes.active)
        }
    }
    return null
}

export function GetServiceStatusDescription(statusId) {
    switch (Number(statusId)) {
        case ServicesStatusTypes.ACTIVE:
            return "Active"
        case ServicesStatusTypes.ASSIGNED:
            return "Assigned"
        case ServicesStatusTypes.SUSPENDED:
            return "Suspended"
        case ServicesStatusTypes.WAITING:
            return "Waiting"
        case ServicesStatusTypes.COMPLETED:
            return "Completed"
        case ServicesStatusTypes.REPLACED:
            return "Replaced"
        case ServicesStatusTypes.CANCELLED:
            return "Cancelled"
        default:
            return "Undefined"
    }
}

/**
 * Function to get name
 */
export function GetNameAmount(amountType) {
    switch(amountType){
        case PaymentAmountType.AmountLaboral:
            return "LABOR"
        case PaymentAmountType.AmountMaterial:
            return "MATERIALS"
        case PaymentAmountType.AmountMileage:
            return "MILEAGE"
        case PaymentAmountType.AmountMiscellaneos:
            return "MISCELLANEOUS"
        case PaymentAmountType.AmountSales:
        case PaymentAmountType.AmountSale:
            return "SALES TAX"
        case PaymentAmountType.AmountTotal:
                return "TOTAL"
        default:
            return ""
    }
}

/**
 * Function to get description de amounts
 * @param {*} amountType 
 */
export function GetDescriptionAmount(amountType) {
    switch(amountType){
        case PaymentAmountType.AmountLaboral:
            return "Please insert only labor costs associated to this service (USD)"
        case PaymentAmountType.AmountMaterial:
            return "Please insert only cost of materials used associated to this service (USD)"
        case PaymentAmountType.AmountMileage:
            return "Please insert only mileage costs incurred to perform service (USD)"
        case PaymentAmountType.AmountMiscellaneos:
            return "Please insert only any additional costs incurred not related to Labor, Materials and Mileage (USD)"
        case PaymentAmountType.AmountSales:
        case PaymentAmountType.AmountSale:
            return "HST only for Canada service partners (USD)"
        case PaymentAmountType.AmountTotal:
                return "The total amount of costs associated to this service. (USD)"
        default:
            return ""
    }
}

/**
 * Function to get icon de amounts
 * @param {*} amountType 
 */
export function GetIconAmount(amountType) {
    switch(amountType){
        case "LABOR":
        case PaymentAmountType.AmountLaboral:
            return iconLaboral
        case PaymentAmountType.AmountMaterial:
            return iconMaterials
        case PaymentAmountType.AmountMileage:
            return iconMileage
        case PaymentAmountType.AmountMiscellaneos:
            return iconMicellaneous
        case PaymentAmountType.AmountSales:
        case PaymentAmountType.AmountSale:
        case "SALES TAX":
            return iconSales
        case PaymentAmountType.AmountTotal:
                return iconTotal
        default:
            return iconTotal
    }
}
