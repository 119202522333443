import { makeStyles } from '@material-ui/styles'
import image01 from '../../resources/images/carousel_01.png'
import image02 from '../../resources/images/carousel_02.gif'
import image03 from '../../resources/images/carousel_03.gif'
import image04 from '../../resources/images/carousel_04.gif'

import mobileBack from '../../resources/images/mobileBackground.png'

/**
 * Mobile view styles
 */
export const mobileUseStyle = makeStyles((theme) => ({
	root: {
	},
	content: {
		height: 'calc(100vh - 62px)',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${mobileBack})`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0px 10px 0px 10px',
		color: theme.palette.common.white
	}
}))

/**
 * Desktop view styles
 */
export const desktopUseStyles = makeStyles((theme) => ({
	root: {		
		marginTop: '3px',
	},
	slideContent: {		
		height: 'calc(100vh - 77px)',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		paddingBottom: '10px'
	},
	slideMessage: {
		gridColumn: '1/2',
		justifySelf: 'center',
		alignSelf: 'center',
		textAlign: 'center',
		padding: '10px',
		maxWidth: '600px',
		color: theme.palette.common.white
	},
	slide01: {
		backgroundImage: `url(${image01})`
	},
	slide02: {
		backgroundImage: `url(${image02})`
	},
	slide03: {
		backgroundImage: `url(${image03})`
    },
	slide04: {
		backgroundImage: `url(${image04})`
	},
	pagination: {
		position: 'fixed',
		right: 10,
		bottom: 10,
	}
}))
