import React, { useEffect } from 'react'
import queryString from 'query-string'
import DesktopView from './components/DesktopView'

import {
	closedServicesSelector,
	serviceProvidersSelector,
	salesRepresentativesSelector,
	metricsCardSelector,
	customerSelector
} from '../../store/networkdays/networkdaysSelector'

import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	loadNetworkDaysChart,
	setServiceSortingCriteria,
	setSalesRepresentativesSortingCriteria,
	setCustomersSortingCriteria,
	setServiceProviderFilter,
	setSalesRepresentativeFilter,
	setStartDateAndEndDate,
	setServiceProviderQuery,
	setSalesRepresentativeQuery,
	setCustomerFilter,
	setCustomerQuery
} from '../../store/networkdays/networkdaysActions'
import {
	downloadReportNetworkDateServices
} from '../../store/services/serviceActions'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { loadInternalUsers } from '../../store/users/UsersActions'
/**
 * Network days container
 */
const NetworkDaysView = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const location = useLocation()
	const startDate = useSelector((state) => state.networkDays.get('startDate'))
	const endDate = useSelector((state) => state.networkDays.get('endDate'))
	const serviceProviderQuery = useSelector((state) =>state.networkDays.get('serviceProviderQuery'))
	const salesRepresentativeQuery = useSelector((state) =>state.networkDays.get('salesRepresentativeQuery'))
	const userProfile = useSelector((state) => state.session.get('profile'))
	const closedServices = useSelector(closedServicesSelector)
	const servicesProviders = useSelector(serviceProvidersSelector)
	const salesRepresentatives = useSelector(salesRepresentativesSelector)
	const allCustomers = useSelector(customerSelector)
	const scoreTotalCard = useSelector(metricsCardSelector)
	const isDownloadReport = useSelector((state) => state.services.get('isDownloadReport'))
	const internalUsers = useSelector((state) =>
		state.users.get('internalUsers')
	)
	const servicesSortingCriteria = useSelector((state) =>
		toSafeObject(state.networkDays.get('servicesSortingCriteria'))
	)
	const salesRepresentativesSortingCriteria = useSelector((state) =>
		toSafeObject(
			state.networkDays.get('salesRepresentativesSortingCriteria')
		)
	)
	const customersSortingCriteria = useSelector((state) =>
		toSafeObject(
			state.networkDays.get('customersSortingCriteria')
		)
	)
	/**
	 * Load the networkday report
	 */
	useEffect(() => {
		dispatch(loadNetworkDaysChart(startDate, endDate)).then(() => {})
	}, [dispatch, startDate, endDate])

	useEffect(() => {
		dispatch(loadInternalUsers())
	}, [dispatch])

	/**
	 * Load the networkday report
	 */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(setServiceProviderFilter(queryParams.serviceProvider))
		dispatch(setSalesRepresentativeFilter(queryParams.salesRep))
		dispatch(setServiceProviderQuery(queryParams.providerQuery))
		dispatch(setSalesRepresentativeQuery(queryParams.representativeQuery))
		dispatch(setCustomerFilter(queryParams.customerCode))
		dispatch(setCustomerQuery(queryParams.customerQuery))
	}, [dispatch, location.search])

	const onChangeLocation = (location) => history.push(location)

	const onChangeServicesSortingCriteria = (criteria) => {
		dispatch(setServiceSortingCriteria(criteria))
	}

	const onChangeSalesRepresentativeSortingCriteria = (criteria) => {
		dispatch(setSalesRepresentativesSortingCriteria(criteria))
	}

	const onChangeCustomersSortingCriteria = (criteria) => {
		dispatch(setCustomersSortingCriteria(criteria))
	}

	const onDateRangeChange = (range) => {
		dispatch(setStartDateAndEndDate(range.startDate, range.endDate))
	}

	/**
	 * Update the service provider filter
	 */
	const onServiceProviderClick = (serviceProvider) => {
		const queryParams = queryString.parse(location.search)
		if (queryParams.serviceProvider === serviceProvider.Code) {
			delete queryParams.serviceProvider
		} else {
			queryParams.serviceProvider = serviceProvider.Code
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Update the customer filter
	 */
	const onCustomerClick = (customer) => {
		const queryParams = queryString.parse(location.search)
		if (queryParams.customerCode === customer.Code) {
			delete queryParams.customerCode
		} else {
			queryParams.customerCode = customer.Code
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Update the sales representative filter
	 */
	const onSalesRepresentativeClick = (salesRepresentative) => {
		const queryParams = queryString.parse(location.search)
		if (queryParams.salesRep === salesRepresentative.Code) {
			delete queryParams.salesRep
		} else {
			queryParams.salesRep = salesRepresentative.Code
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onQueryProviderChange = (searchQuery) =>{
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.providerQuery = searchQuery
		else delete queryParams.providerQuery
		history.push({ search: queryString.stringify(queryParams) })
		// dispatch(setInternalQueryParameters(queryParams))
	}

	const onQueryRepresentativeChange = (searchQuery) =>{
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.representativeQuery = searchQuery
		else delete queryParams.representativeQuery
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onQueryCustomerChange = (searchQuery) =>{
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.customerQuery = searchQuery
		else delete queryParams.customerQuery
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onDownloadReportNetworkDateServices = () =>{
		const queryParams = queryString.parse(location.search)
		dispatch(downloadReportNetworkDateServices(toSafeObject(internalUsers), "closed", startDate, endDate, queryParams))
	}

	return (
		<DesktopView
			onChangeLocation={onChangeLocation}
			services={closedServices}
			startDate={startDate}
			endDate={endDate}
			servicesProviders={servicesProviders}
			salesRepresentatives={salesRepresentatives}
			customers={allCustomers}
			onDateRangeChange={onDateRangeChange}
			servicesSortingCriteria={servicesSortingCriteria}
			onChangeServicesSortingCriteria={onChangeServicesSortingCriteria}
			salesRepresentativesSortingCriteria={
				salesRepresentativesSortingCriteria
			}
			onChangeSalesRepresentativeSortingCriteria={
				onChangeSalesRepresentativeSortingCriteria
			}
			customersSortingCriteria = {customersSortingCriteria}
			onChangeCustomersSortingCriteria = {onChangeCustomersSortingCriteria}
			onServiceProviderClick={onServiceProviderClick}
			onCustomerClick={onCustomerClick}
			onSalesRepresentativeClick={onSalesRepresentativeClick}
			scoreTotalCard={scoreTotalCard}
			onQueryProviderChange={(query) => onQueryProviderChange(query)}
			onQueryRepresentativeChange={(query) => onQueryRepresentativeChange(query)}
			onQueryCustomerChange={(query)=>onQueryCustomerChange(query)}
			serviceProviderQuery={serviceProviderQuery}
			salesRepresentativeQuery={salesRepresentativeQuery}
			userProfile={userProfile}
			onDownloadReportNetworkDateServices={onDownloadReportNetworkDateServices}
			isDownloadReport={isDownloadReport}
		/>
	)
}
export default NetworkDaysView
