/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';

/**IMport components section */
import DesktopView from './components/DesktopView'
import Toaster from '../../common/Toaster'
import Create from './Create'
import Edit from './Edit'

/**Import actions sections */
import {
	loadInternalUsers,
	setInternalQueryParameters,
	addUserInRole,
	removeUserFromRole,
	removeUserFromAccount,
	editActiveNotifications,
	downloadInternalUsersList,
} from '../../../store/users/UsersActions'
import {
	internalUsersSelector,
	queryParametersSelector
} from '../../../store/users/UsersSelectors'

/**Import helpers section */
import { isUserInRole } from '../../../store/helpers/UserHelper'
import { AccountCode } from '../../../store/helpers/AppConstants'

/**
 * This is the index of the imbera users account
 */
const Index = () => {
	/**Hooks sections */
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()

	const isLoading = useSelector((state) => state.users.get('isLoading'))
	const loggedUserProfile = useSelector((state) => state.session.get('profile'))
	const isSaving = useSelector((state) => state.users.get('isSaving'))
	const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
	const internalUser = useSelector(internalUsersSelector)
	const queryParameters = useSelector(queryParametersSelector)
	const [isOpenLateralPanelAdd, setIsOpenLateralPanelAdd] = useState(false);
	const [lateralPanelEditConfig, setLateralPanelConfig] = useState({ opened: false, user: null });
	const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, user: null });
	const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
	const [identifierCardSeleted, setIdentifierCardSelected] = useState();
	const [pagination, setPagination] = useState({ pageSize: 5, total: 0 });
	const [currentPage, setCurrentPage] = useState(1);

	/**Defined mode view */
	const [gridMode, setGridMode] = useState(false);
	const [allUserPaginated, setAllUsersPaginated] = useState([]);

	/**
	 * Load internal user
	 */
	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.pageNumber) {
			setCurrentPage(queryParameters.pageNumber)
		}
		dispatch(loadInternalUsers(queryParameters))
	}, [dispatch, location.search])

	/**
	 * Here we save and switch view mode grid|list and persist on local storage.
	 */
	useEffect(() => {
		if (gridMode === false) {
			setGridMode(false);
		}
		else {
			setGridMode(true);
		}
	}, [gridMode]);

	useEffect(() => {
		let usersPerPage = 50;
		let total = internalUser.length / usersPerPage;
		let allTotal = Math.round(Math.ceil(total))
		const indexOfLastUser = currentPage * usersPerPage;
		let indexOfFirtsUser = indexOfLastUser - usersPerPage;
		let currentUsers = internalUser.slice(indexOfFirtsUser, indexOfLastUser)
		setAllUsersPaginated(currentUsers)
		setPagination({ pageSize: usersPerPage, total: allTotal });
	}, [internalUser, pagination.total, currentPage])


	/**
	 * Update sourt criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
		dispatch(setInternalQueryParameters(queryParams))
	}

	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
		dispatch(setInternalQueryParameters(queryParams))
	}

	/**
 * On change page number
 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		setCurrentPage(page)
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**Open lateral menu for add an internal user */
	const openLateralPanelOnClick = () => {
		setIsOpenLateralPanelAdd(!isOpenLateralPanelAdd)
	}

	/**Open lateral menu for add an internal user */
	const openLateralPanelEditOnClick = (user) => {
		setIdentifierCardSelected(user.Token ? user.Token : "")
		setLateralPanelConfig({ opened: !lateralPanelEditConfig.opened, user: user })
	}

	/**Open/close dialog confirm dialog user*/
	const onSetPasswordDialogConfiguration = (user) => {
		setPasswordDialogConfiguration({ opened: !passwordDialogConfiguration.opened, user: user });
	};

	/**Change mode view in users */
	const switchViewMode = () => {
		if (gridMode === false) {
			setGridMode(true);
		} else {
			setGridMode(false);
		}
	}

	/**Add role to user and remove roles */
	const onToggleUserRole = (roleName, roleType) => {
		let token = lateralPanelEditConfig && lateralPanelEditConfig.user.Token;
		let roles = lateralPanelEditConfig && lateralPanelEditConfig.user.Roles;
		if (isUserInRole(roleName, roles)) {
			return dispatch(removeUserFromRole(token, roleName))
				.then(roles => {
					setLateralPanelConfig({
						...lateralPanelEditConfig,
						user: {
							...lateralPanelEditConfig.user,
							"Roles": roles
						}
					})
				})
				.catch((error) => {
					setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to remove role from user', variant: 'warning' })
					console.error("Failed to remove role");
				});
		}
		else {
			return dispatch(addUserInRole(token, roleName, roleType))
				.then(roles => {
					setLateralPanelConfig({
						...lateralPanelEditConfig,
						user: {
							...lateralPanelEditConfig.user,
							"Roles": roles
						}
					})
				})
				.catch((error) => {
					setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add role', variant: 'error' })
					console.error("Failed to assign role", error);
				});
		}
	}

	/**
	 * Remove an user fron this list user for company IMBERA
	 */
	const onRemoveUserFromAccount = () => {
		dispatch(removeUserFromAccount(passwordDialogConfiguration.user.Token, AccountCode.IMBERA))
			.then(() => {
				onSetPasswordDialogConfiguration()
				return Promise.resolve();
			})
			.catch((error) => {
				setToasterOptions({ open: true, message: "Error removing user from account", variant: 'error' })
				console.error('Error removing user from account', error);
			});
	};

	/** Function to active/deactive notifications for selected user */
	const onActiveNotifications = (token, value) => {

		dispatch(editActiveNotifications(token, value))
	}

	/**Function to download a list of internal users in an excel file */
	const onDownloadListOfInternalUsers = () => {
		dispatch(downloadInternalUsersList())
	}

	return (
		<>
			<DesktopView
				isLoading={isLoading}
				users={allUserPaginated}
				searchValue={queryParameters ? queryParameters.query : ''}
				sortBy={queryParameters ? queryParameters.sortBy : null}
				sortDirection={queryParameters ? queryParameters.sortDirection : null}
				onChangeSortingCriteria={(sortCriteria) =>
					updateSortCriteria(sortCriteria)
				}
				onChangeSearchValue={(query) => updateSearchQuery(query)}
				openLateralPanelOnClick={openLateralPanelOnClick}
				isSaving={isSaving}
				openLateralPanelEditOnClick={openLateralPanelEditOnClick}
				switchViewMode={switchViewMode}
				gridMode={gridMode}
				onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
				loggedUserProfile={loggedUserProfile}
				identifierCardSeleted={identifierCardSeleted}
				pagination={pagination}
				onChangePage={onChangePage}
				onDownloadReport={onDownloadListOfInternalUsers}
			/>
			<Create
				isOpenLateralPanelAdd={isOpenLateralPanelAdd}
				openLateralPanelOnClick={openLateralPanelOnClick}
				isSaving={isSaving}
			/>
			<Edit
				passwordDialogConfiguration={passwordDialogConfiguration}
				isValidatingPassword={isValidatingPassword}
				isSaving={isSaving}
				onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
				onRemoveUserFromAccount={onRemoveUserFromAccount}
				openLateralPanelEditOnClick={openLateralPanelEditOnClick}
				lateralPanelEditConfig={lateralPanelEditConfig}
				onToggleUserRole={onToggleUserRole}
				onActiveNotifications={onActiveNotifications}
			/>

			{/** Toaster */}
			{
				toasterOptions.open &&
				<Toaster
					toasterOptions={toasterOptions}
					onClose={() => setToasterOptions({ open: false })}
				/>
			}
		</>
	)
}
export default Index
