import { createSelector } from 'reselect'
import { toSafeArray } from '../../store/helpers/StateHelper'
import { getServiceFromEquipmentList } from './serviceActions'

let servicesMetrics = (state) => state.services.get('metrics')
/**
 * Selector for service equipmentes
 */
export const servicesEquipmentsSelector = createSelector(
	(state) => state.services.get('servicesList').get('services'),
	(equipmentsMaps) => toSafeArray(equipmentsMaps)
)

/**
 * Selector for services included in the equipment list
 */
export const servicesSelector = createSelector(
	servicesEquipmentsSelector,
	(equipments) => {
		return getServiceFromEquipmentList(equipments)
	}
)


export const servicesMetricsSelector = createSelector(
	servicesMetrics,
	(services)=>{
		let servicesMapped = toSafeArray(services)
		return servicesMapped
	}
)

/**
 * Selector for service closed
 */
export const servicesClosed = createSelector(
	(state) => state.services.get('servicesClosed'),
	(equipmentsMaps) => toSafeArray(equipmentsMaps)
)

/**
 * Selector for services closed included in the equipment list
 */
export const servicesClosedSelector = createSelector(
	servicesClosed,
	(equipments) => {
		return getServiceFromEquipmentList(equipments)
	}
)