/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'

/**Import styles section */
import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'
import inconWarrantyActive from '../../../resources/images/warrantyActive.svg'
import inconWarrantyDesactive from '../../../resources/images/warrantyDesactive.svg'
import inconWarrantyPartial from '../../../resources/images/warrantyPartial.svg'
import iconFailureDoor from '../../../resources/images/failureWarranty/iconsFailureDoorNoSelected.png'
import iconFailureDoorSelected from '../../../resources/images/failureWarranty/iconsFailureDoorActive.png'
import iconFailureRefrigerationNotCool from '../../../resources/images/failureWarranty/iconFailureRefrigerationNoSelected.png'
import iconFailureRefrigerationNotCoolSelected from '../../../resources/images/failureWarranty/failureRefrigerationActive.png'
import iconFailureIluminationSelected from '../../../resources/images/failureWarranty/failureLightsActive.png'
import iconFailureIlumination from '../../../resources/images/failureWarranty/iconsFailureLightsNoSelected.png'

/**Import Material Ui section */
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Tooltip,
	IconButton,
	Avatar,
	LinearProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button
} from '@material-ui/core'

/** Components */
import TextBox from '../../common/TextBox'
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import ExpandedPanelComponent from './ExpandedPanelComponent'
import equimpentTablaConfig from '../resources/EquimpentTablaConfig'
import FaultWarrantyIcon from './FaultWarrantyIcon'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Select from '../../common/ReactSelectAvatar'
import AlertComponent from '../../common/AlertComponent'
import { GetFaultWarranty } from '../../../store/helpers/SelectOptions'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import { Delete } from '@material-ui/icons'
import DateSelector from '../../common/DateSelector'
import moment from 'moment'
import { hasAuthorization } from '../../../store/session/sessionActions'
import { PORTAL_ADMINISTRATOR } from '../../../resources/RolesEnum'


/**
 * Component show list equipments
 */
const EquipmentsList = (props) => {
	const classes = createServicesStyle()
	const subCategoriesOptions = GetFaultWarranty()
	const { errors, control } = useForm()
	/** Props */
	const {
		equipments,
		isAddingSeries,
		onAddSeries,
		onUpdateSerieInService,
		onDeleteSerieInService,
		onUpdateSubCategoryInSerivce,
		isLoading,
		onShowWarranty,
		isSavingSeries,
		isAuthorizadoForEditedData,
		modeView,
		CanBeEdited,
		isWarrantySales,
		open,
		handleClick,
		onDeleteButton = false,
		userProfile
	} = props
	const [seriesModel, setSeriesModel] = useState({ serialNumber: '' })
	const [identifierPanel, setIdentifierPanel] = useState([])
	let inModedSaved = modeView === 'SPARE_PART_REQUETS' ? true : false
	let canbeEditDataInStatusActive = !isAuthorizadoForEditedData && CanBeEdited

	/**On change properties serie */
	const changeSerialToAdd = (event) => {
		let { name, value } = event.target
		setSeriesModel({
			...seriesModel,
			[name]: value
		})
	}

	/**
	 * Function on Add and search serie to add in service
	 */
	const onSearchSerie = () => {
		if (onAddSeries && seriesModel.serialNumber.length === 12) {
			onAddSeries(seriesModel.serialNumber).then(() => {
				setSeriesModel({ serialNumber: '' })
			})
		}
	}

	const getBackgroundColorwarrantyIcon = (WarrantyStatus) => {
		if (WarrantyStatus === 'UnderWarranty') return classes.warrantyIconGreen
		if (WarrantyStatus === 'OutWarranty') return classes.warrantyIconRed
		else return classes.warrantyIconOrange
	}

	/**
	 * Get icon for warranty status
	 * @param {*} WarrantyStatus
	 */
	const getIncon = (WarrantyStatus) => {
		if (WarrantyStatus === 'UnderWarranty') return inconWarrantyActive
		if (WarrantyStatus === 'OutWarranty') return inconWarrantyDesactive
		else return inconWarrantyPartial
	}

	/**
	 * Get title warranty status
	 * @param {*} WarrantyStatus
	 */
	const getTitleWarranty = (WarrantyStatus) => {
		if (WarrantyStatus === 'UnderWarranty') return classes.iconWarranty
		if (WarrantyStatus === 'OutWarranty') return 'OUT OF WARRANTY'
		else return 'EXPIRATION WARRANTY'
	}

	const onExpandedPanelClick = (item) => {
		let index = identifierPanel.includes(inModedSaved ? item.Id : item.Token)
		if (index) {
			let newList = identifierPanel.filter((identifier) => {
				return identifier !== (inModedSaved ? item.Id : item.Token)
			})
			setIdentifierPanel(newList)
		} else {
			let identifier = inModedSaved ? item.Id : item.Token
			setIdentifierPanel(identifierPanel.concat(identifier))
		}
	}

	const onDeleteSerie = (item) => {
		onDeleteSerieInService(item.Token)
		handleClick()
	}

	const onRenderButtonCollapse = (item) => {
		let index = identifierPanel.includes(inModedSaved ? item.Id : item.Token)
		return index
	}

	const expandedPanelComponent = (item) => {
		return (
			<ExpandedPanelComponent
				columnSpam={getEquimpentTablaConfig().length}
				onPropertyChange={onUpdateSerieInService}
				item={item}
				identifierPanel={identifierPanel}
				isAuthorizadoForEditedData={isAuthorizadoForEditedData}
				content={props.expandablePanelContent}
				modeView={inModedSaved}
				ticketServicesChipStyle={classes.ticketServicesChip}
			/>
		)
	}

	/**
	 * Get the header configuration
	 */
	const getEquimpentTablaConfig = () => {
		let iconProperty = equimpentTablaConfig.find((x) => x.dataSource === 'WarrantyStatus')
		iconProperty.onRenderProperty = (item) => {
			return !isWarrantySales ? (
				<Tooltip component='span' title={getTitleWarranty(item.WarrantyStatus)}>
					<Avatar className={getBackgroundColorwarrantyIcon(item.WarrantyStatus)}>
						<img
							src={getIncon(item.WarrantyStatus)}
							alt='warranty-icon'
							className={classes.iconWarranty}
							onClick={(event) => onShowWarranty(item)}
						/>
					</Avatar>
				</Tooltip>
			) : null
		}

		let modelProperty = equimpentTablaConfig.find((x) => x.dataSource === 'Model')
		modelProperty.onRenderProperty = (item) => {
			return !isWarrantySales ? <Typography noWrap>{item.Model}</Typography> : null
		}
		let gasProperty = equimpentTablaConfig.find((x) => x.dataSource === 'Gas')
		gasProperty.onRenderProperty = (item) => {
			return !isWarrantySales ? <Typography noWrap>{item.Gas}</Typography> : null
		}

		let faulProperty = equimpentTablaConfig.find((x) => x.dataSource === 'DoorFailure')
		faulProperty.onRenderProperty = (item) => {
			return !isWarrantySales ? (
				<div className={classes.containerFailureButtons}>
					<FaultWarrantyIcon
						title={'Door'}
						icon={item.DoorFailure ? iconFailureDoorSelected : iconFailureDoor}
						classImg={classes.faultWarrantyIcon}
						fabStyle={item.DoorFailure ? classes.faultWarrantyIconSelected : classes.fabStyle}
						onChange={() =>
							onUpdateSerieInService(
								item.Token,
								item.RefrigerationNotCoolFailure,
								item.IluminationFailure,
								!item.DoorFailure,
								item.Comments,
								item.FaultWarranty,
								item.CompletionDate ? item.CompletionDate : null
							)
						}
						isSaving={isSavingSeries}
						disabled={isAuthorizadoForEditedData}
					/>
					<FaultWarrantyIcon
						title={'Refrigeration (Does not cool)'}
						icon={item.RefrigerationNotCoolFailure ? iconFailureRefrigerationNotCoolSelected : iconFailureRefrigerationNotCool}
						classImg={classes.faultWarrantyIcon}
						fabStyle={item.RefrigerationNotCoolFailure ? classes.faultWarrantyIconSelected : classes.fabStyle}
						onChange={() =>
							onUpdateSerieInService(
								item.Token,
								!item.RefrigerationNotCoolFailure,
								item.IluminationFailure,
								item.DoorFailure,
								item.Comments,
								item.FaultWarranty,
								item.CompletionDate ? item.CompletionDate : null
							)
						}
						isSaving={isSavingSeries}
						disabled={isAuthorizadoForEditedData}
					/>
					<FaultWarrantyIcon
						title={'Lights'}
						icon={item.IluminationFailure ? iconFailureIluminationSelected : iconFailureIlumination}
						classImg={classes.faultWarrantyIcon}
						fabStyle={item.IluminationFailure ? classes.faultWarrantyIconSelected : classes.fabStyle}
						onChange={() =>
							onUpdateSerieInService(
								item.Token,
								item.RefrigerationNotCoolFailure,
								!item.IluminationFailure,
								item.DoorFailure,
								item.Comments,
								item.FaultWarranty,
								item.CompletionDate ? item.CompletionDate : null
							)
						}
						isSaving={isSavingSeries}
						disabled={isAuthorizadoForEditedData}
					/>
				</div>
			) : null
		}
		let subCategoryProperty = equimpentTablaConfig.find((x) => x.dataSource === 'FaultWarranty')
		subCategoryProperty.onRenderProperty = (item) => {
			return !isWarrantySales ? (
				<div style={{ width: '250px' }}>
					<Select
						key={'FaultWarranty'}
						fullWidth
						defaultValue={item.FaultWarranty && null}
						options={subCategoriesOptions}
						name='FaultWarranty'
						isDisabled={props.IsPayment ? props.CanBeAddSubCategoria : isAuthorizadoForEditedData}
						value={item.FaultWarranty ? subCategoriesOptions.find((x) => x.label === item.FaultWarranty) : null}
						onChange={(name, option) =>
							props.IsPayment
								? onUpdateSubCategoryInSerivce(
										item.Token,
										item.RefrigerationNotCoolFailure,
										item.IluminationFailure,
										item.DoorFailure,
										item.Comments,
										option.label,
										item.CompletionDate ? item.CompletionDate : null
								  )
								: onUpdateSerieInService(
										item.Token,
										item.RefrigerationNotCoolFailure,
										item.IluminationFailure,
										item.DoorFailure,
										item.Comments,
										option.label,
										item.CompletionDate ? item.CompletionDate : null
								  )
						}
						register={null}
						errors={errors}
						control={control}
					/>
				</div>
			) : null
		}

		let completionDateProperty = equimpentTablaConfig.find((x) => x.dataSource === 'CompletionDate')

		completionDateProperty.onRenderProperty = (item) => {
			return (
				<div style={{ width: '100px' }}>
					<DateSelector
						showIncon={true}
						dataCy='date'
						name='completionDate'
						disabled={props.IsPayment ? props.CanBeAddSubCategoria : isAuthorizadoForEditedData}
						value={item.CompletionDate ? moment(item.CompletionDate, 'MM/DD/YYYY') : null}
						onChange={(event) =>
							onUpdateSerieInService(
								item.Token,
								item.RefrigerationNotCoolFailure,
								item.IluminationFailure,
								item.DoorFailure,
								item.Comments,
								item.FaultWarranty,
								event.target.value
							)
						}
					/>
				</div>
			)
		}

		let expandedProperty = equimpentTablaConfig.find((x) => x.dataSource === 'Expanded')
		expandedProperty.onRenderProperty = (item) => {
			let changeButton = onRenderButtonCollapse(item)
			const isAuthorized = hasAuthorization(userProfile, [PORTAL_ADMINISTRATOR])

			return (
				<div style={{ display: 'flex' }}>
					<Dialog open={open} onClose={handleClick} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
						<DialogTitle id='alert-dialog-title'>{'Are you sure to delete this series?'}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>Will be permanently deleted.</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClick}>Disagree</Button>
							<Button onClick={() => onDeleteSerie(item)} autoFocus>
								delete
							</Button>
						</DialogActions>
					</Dialog>
					<IconButton size='small' edge='start' color='inherit' aria-label='menu' onClick={() => onExpandedPanelClick(item)}>
						{changeButton ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>


					{onDeleteButton && (
						isAuthorized ?
						<IconButton
							id={`label${item.token}`}
							size='small'
							// onClick={() => onDeleteSerie(item)}
							onClick={handleClick}
						>
							<Delete color='error' fontSize='small' />
						</IconButton>
						: null
					)}
				</div>
			)
		}
		return equimpentTablaConfig
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				{canbeEditDataInStatusActive && (
					<Grid item xs={12} md={12}>
						<Card variant='outlined'>
							<CardContent>
								<Typography variant='subtitle2'>
									Enter the serial number of your product, it must contain 12 digits, to validate serial in SAP.
								</Typography>
								<Grid container spacing={1}>
									<Grid item xs={11}>
										<TextBox
											active={true}
											fullWidth
											name='serialNumber'
											variant='outlined'
											margin='dense'
											label={'MFG Serial number'}
											onChange={changeSerialToAdd}
											value={seriesModel ? seriesModel.serialNumber : ''}
										/>
									</Grid>
									<Grid item xs={1} style={{ marginTop: '8px' }}>
										<ButtonSaved
											title='Search'
											isSaving={(seriesModel ? seriesModel.serialNumber.length !== 12 : false) || isAddingSeries}
											onSavedClick={onSearchSerie}
										/>
									</Grid>
								</Grid>

								{isAddingSeries && (
									<>
										<br />
										<AlertComponent
											title={'Getting series from SAP'}
											message={'This process may take a while, please wait...'}
											severityType={'info'}
										/>
										<LinearProgress />
									</>
								)}
							</CardContent>
						</Card>
					</Grid>
				)}
				<div className={props.IsPayment ? '' : classes.tableEquipment}>
					<DataGrid
						headers={getEquimpentTablaConfig()}
						data={equipments}
						className={classes.table}
						isLoading={isLoading}
						isExpandedPanel={true}
						expandedPanelComponent={expandedPanelComponent}
					/>
				</div>
			</Grid>
		</Grid>
	)
}
EquipmentsList.propTypes = {
	/**List of equipments */
	equipments: propTypes.array.isRequired,
	/**On action to is saving series */
	isSavingSeries: propTypes.bool.isRequired,
	isAddingSeries: propTypes.bool.isRequired,
	/**Function invoke callback for add series in service */
	onAddSeries: propTypes.func.isRequired,
	/**Function invoke callback for update series in service */
	onUpdateSerieInSerivce: propTypes.func.isRequired,
	/**Function invoke callback for show warranty for equipment */
	onShowWarranty: propTypes.func,
	/**Determinate if user canbe edited data */
	isAuthorizadoForEditedData: propTypes.bool.isRequired,
	/**
	 * Use this property to override the default expandable panel shown in each equipment row
	 */
	expandablePanelContent: propTypes.node,
	/**
	 * Is loading
	 */
	isLoading: propTypes.bool.isRequired,
	/**
	 * Determinate if is warranty sales
	 */
	isWarrantySales: propTypes.bool.isRequired
}
EquipmentsList.defaultProps = {
	equipments: [],
	isWarrantySales: false,
	isSavingSeries: false,
	isAddingSeries: false,
	isAuthorizadoForEditedData: false,
	onAddSeries: () => console.warn('Callback [onAddSeries] no defined'),
	onUpdateSerieInSerivce: () => console.warn('Callback [onUpdateSerieInSerivce] no defined'),
	onShowWarranty: () => console.warn('Callback [onShowWarranty] no defined'),
	expandablePanelContent: null,
	isLoading: false,
	IsPayment: false,
	CanBeAddSubCategoria: false
}

export default EquipmentsList
