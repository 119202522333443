/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'

/**Import components section */
import TextBox from '../../common/TextBox'
import ButtonConfirm from '../../common/Buttons/ButtonConfirm'
import ReactSelectAvatar from '../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import AlertComponent from '../../common/AlertComponent'
import Datagrid from '../../common/apsys.datagrid.materialui/DataGrid'

/** Import material UI */
import {
	Typography,
	LinearProgress,
	Grid,
	Chip,
	ButtonGroup,
	Tooltip,
	Button,
	Checkbox
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/SearchSharp'
import CloudDownload from '@material-ui/icons/CloudDownload'
import PaymentIcon from '@material-ui/icons/CreditCard'

/**Import resources section */
import { getDatagridSummaryConfiguration } from '../resources/requestSummaryConfig'
import { useCreateDesktopStyle } from '../resources/useStyles'
import { currencyFormat } from '../../../store/helpers/AmountHelper'

const CreateSummaryForm = (props) => {
	/**onjects props sections */
	const {
		summaryModel,
		paymentRequests,
		seviceProviderOptions,
		caBeEdit
	} = props
	const { isLoadingSummary, isSavingSummary } = props
	const {
		onSearchPayments,
		onSaveGenerateSummary,
		onPropertyChange,
		onDonwloadReportSummary
	} = props

	let configuration = getDatagridSummaryConfiguration()
	const classes = useCreateDesktopStyle()
	const { register, errors, control, handleSubmit } = useForm()

	const setFolio = (item) => {
		var folios = summaryModel.checkFolios.split(',')

		var newFolios = ''
		if (summaryModel.checkFolios.includes(item.Folio)) {
			folios.forEach((element) =>
				element === item.Folio
					? ''
					: (newFolios = newFolios.concat(
							newFolios.length > 0 ? ',' : '',
							element
					  ))
			)
		} else {
			folios = folios.concat(item.Folio)
			folios.forEach(
				(element) =>
					(newFolios = newFolios.concat(
						newFolios.length > 0 ? ',' : '',
						element
					))
			)
		}

		let total = getTotalPayments(newFolios)
		props.onSelectChangeS('checkFolios', newFolios, 'totaltoPay', total)
	}
	configuration[0].onRenderProperty = (item) => {
		return (
			<Checkbox
				onClick={() => setFolio(item)}
				checked={summaryModel.checkFolios.includes(item.Folio)}
			/>
		)
	}

	/**Verify if can be search request list */
	const canBeSaved = () => {
		if (summaryModel ? summaryModel.serviceProvider === null : true)
			return false
		if (
			summaryModel && summaryModel.startDate
				? summaryModel.startDate.length <= 1
				: true
		)
			return false
		if (
			summaryModel && summaryModel.endDate
				? summaryModel.endDate.length <= 1
				: true
		)
			return false
		return true
	}

	/**
	 * Varify in can be save summary request
	 */
	const canBeGenerated = () => {
		if (summaryModel ? summaryModel.serviceProvider === null : true)
			return false
		if (
			summaryModel && summaryModel.startDate
				? summaryModel.startDate.length <= 1
				: true
		)
			return false
		if (
			summaryModel && summaryModel.endDate
				? summaryModel.endDate.length <= 1
				: true
		)
			return false
		if (
			summaryModel && summaryModel.checkFolio
				? summaryModel.checkFolio.length <= 3
				: true
		)
			return false
		return true
	}

	/**
	 * Function to get total sum from payments
	 */
	const getTotalPayments = (folios) => {
		const sumTotal = paymentRequests.reduce((sum, payment) => {
			return (
				sum + (folios.includes(payment.Folio) ? payment.TotalAmount : 0)
			)
		}, 0)
		return currencyFormat(sumTotal)
	}

	return (
		<div>
			<Typography variant='subtitle2'>
				Select the date range and service provider to be able to
				generate a summary of expenses with accepted payment requests
			</Typography>
			<Grid container spacing={1}>
				<Grid item xs={8}>
					<ReactSelectAvatar
						id='serviceProvider'
						name='serviceProvider'
						notShowTitle={true}
						placeholder={'SERVICE PROVIDER'}
						options={seviceProviderOptions}
						onChange={props.onSelectChange}
						formatOptionLabel={formatOptionLabel}
						value={
							summaryModel.serviceProvider &&
							!!summaryModel.serviceProvider.Code
								? seviceProviderOptions.find(
										(x) =>
											x.value ===
											summaryModel.serviceProvider.Code
								  )
								: null
						}
						register={
							summaryModel && !!summaryModel.serviceProvider
								? register(
										{ name: 'serviceProvider' },
										{ required: { value: false } }
								  )
								: register(
										{ name: 'serviceProvider' },
										{
											required: {
												value: true,
												message:
													'The service provider is required'
											}
										}
								  )
						}
						errors={errors}
						control={control}
					/>
				</Grid>
				<Grid item xs={4}>
					<ButtonGroup
						component='span'
						size='small'
						className={classes.buttonsGroupEquipmentContainer}
					>
						<Button
							onClick={handleSubmit(onSearchPayments)}
							disabled={
								isLoadingSummary ||
								isSavingSummary ||
								!canBeSaved()
							}
							className={classes.buttonsGroup}
						>
							<SearchIcon style={{ marginRight: '10px' }} />{' '}
							{' Search'}
						</Button>
						<Button
							onClick={() => props.changeView(props.optionView)}
							className={classes.buttonsGroup}
						>
							<PaymentIcon style={{ marginRight: '10px' }} />{' '}
							{'  Search Check'}
						</Button>
						<Tooltip component='span' title='Download report'>
							<Button
								onClick={() =>
									onDonwloadReportSummary(summaryModel)
								}
								className={classes.buttonsGroup}
								disabled={
									paymentRequests.length === 0 ||
									isSavingSummary
								}
							>
								<CloudDownload
									style={{
										marginRight: '5px',
										marginLeft: '5px'
									}}
								/>
							</Button>
						</Tooltip>
					</ButtonGroup>
				</Grid>
			</Grid>

			{paymentRequests.length !== 0 && (
				<Grid container className={classes.setMarginGrid}>
					<Typography className={classes.title}>
						<strong>{'DETAIL PAYMENT SUMMARY'}</strong>
					</Typography>
				</Grid>
			)}
			{paymentRequests.length !== 0 && (
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<TextBox
							active={true}
							disabled={true}
							fullWidth
							name='totalAmount'
							variant='outlined'
							margin='dense'
							label={'Total to pay'}
							onChange={onPropertyChange}
							value={summaryModel.totaltoPay}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextBox
							active={true}
							fullWidth
							name='checkFolio'
							variant='outlined'
							margin='dense'
							label={'Check folio'}
							onChange={onPropertyChange}
							value={summaryModel.checkFolio}
							disabled={caBeEdit}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextBox
							active={true}
							fullWidth
							name='email'
							variant='outlined'
							margin='dense'
							label={'Email for PDF report'}
							onChange={onPropertyChange}
							value={summaryModel.email}
							disabled={caBeEdit}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextBox
							active={true}
							fullWidth
							name='note'
							variant='outlined'
							margin='dense'
							label={'Note'}
							onChange={onPropertyChange}
							value={summaryModel.note}
							disabled={caBeEdit}
						/>
					</Grid>
					<Grid item xs={1} style={{ marginTop: '8px' }}>
						<ButtonConfirm
							title='Create'
							isSaving={
								isLoadingSummary ||
								!canBeGenerated() ||
								caBeEdit
							}
							onSavedClick={handleSubmit(onSaveGenerateSummary)}
						/>
					</Grid>
				</Grid>
			)}

			{paymentRequests.length !== 0 ? (
				<Grid item xs={12} className={classes.setMarginGrid}>
					<Typography className={classes.title}>
						<strong>{'PAYMENT REQUESTS'}</strong>
					</Typography>
					<div className={classes.containerCreation}>
						<Datagrid
							headers={configuration}
							isLoading={isLoadingSummary}
							data={paymentRequests}
						/>
					</div>
				</Grid>
			) : (
				<Grid item xs={12} className={classes.setMarginGrid}>
					{!isLoadingSummary && (
						<AlertComponent
							title={'Payment summary'}
							message={
								'There is no payment request accepted for the expense summary.'
							}
							severityType={'info'}
						/>
					)}
				</Grid>
			)}

			{isLoadingSummary && (
				<Grid item xs={12} className={classes.setMarginGrid}>
					<AlertComponent
						title={'Getting payment requests'}
						message={
							'Getting payment requests to generated summary...'
						}
						severityType={'info'}
					/>
					<LinearProgress />
				</Grid>
			)}
		</div>
	)
}

CreateSummaryForm.propTypes = {
	isLoadingSummary: propTypes.bool.isRequired,
	summaryModel: propTypes.object.isRequired,
	onSaveGenerateSummary: propTypes.func,
	onSearchPayments: propTypes.func,
	paymentRequests: propTypes.arrayOf(
		propTypes.shape({
			Folio: propTypes.string
		})
	).isRequired
}
CreateSummaryForm.defaultProps = {
	summaryModel: {},
	isLoadingSummary: false,
	paymentRequests: [],
	onSaveGenerateSummary: () =>
		console.warn('No [onSaveGenerateSummary] callback defined'),
	onSearchPayments: () =>
		console.warn('No [onSearchPayments] callback defined')
}

/**
 * formatOptionLabel
 * @param {*} param0
 */
const formatOptionLabel = ({
	value,
	label,
	avatarUrl,
	isAvatarFromIdentity
}) => (
	<div>
		<Chip
			id={value}
			size='small'
			avatar={
				<AvatarCustomSize
					avatarUrl={avatarUrl}
					name={label}
					isAvatarFromIdentity={isAvatarFromIdentity}
				/>
			}
			label={label}
			clickable
			style={{ color: 'white', background: '#5357DC' }}
		/>
	</div>
)

export default CreateSummaryForm
