/**Import react section */
import React, { useState } from 'react'
/**Import common componets */
import TextBox from './../../../common/TextBox'
import ButtonConfirm from './../../../common/Buttons/ButtonConfirm'
/**Import MaterialUi section */
import { Grid } from '@material-ui/core'
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import AlertComponent from '../../../common/AlertComponent'

const ReplacedService = (props) => {
    const { onSetPasswordDialogConfiguration, IsClosed } = props;
    const classes = createServicesStyle();
    const [replacedModel, setReplacedModel] = useState({ comments: '', status: props.status, serie: props.serie });

    const openModalForReplacedService = () => {
        if (onSetPasswordDialogConfiguration)
            onSetPasswordDialogConfiguration(replacedModel)
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setReplacedModel({
            ...replacedModel,
            [name]: value
        });
    }

    /**Verify if can be sabed */
    const canBeSaved = () => {
        if (replacedModel ? (replacedModel.comments.length <= 0) : true) return false;
        if (replacedModel ? (replacedModel.serie.length <= 1 || replacedModel.serie==="null") : true) return false;
        return true;
    }

    return (
        <div>
            <Grid item xs={12}>
                {
                    (replacedModel.serie.length <= 1 || replacedModel.serie==="null") &&
                    <AlertComponent
                        title={"Error"}
                        message={"In order to mark as replaced it is necessary to select a series"}
                        severityType={"error"}
                    />
                }<br/>
            </Grid>
            <Grid item xs={12}>
                <TextBox
                    active={true}
                    fullWidth
                    autoFocus
                    disabled={IsClosed||(replacedModel.serie.length <= 1 || replacedModel.serie==="null")}
                    name="comments"
                    variant="outlined"
                    margin="dense"
                    label="REASON FOR REPLACED"
                    multiline
                    rows={4}
                    onChange={onPropertyChange}
                    value={replacedModel ? replacedModel.comments : ""}
                />
            </Grid>
            {
                !IsClosed && <Grid item xs={12} className={classes.updateButton}>
                    <ButtonConfirm
                        title="CONFIRM"
                        isSaving={!canBeSaved()}
                        onSavedClick={openModalForReplacedService}
                    />
                </Grid>
            }
        </div>
    )
}

export default ReplacedService
