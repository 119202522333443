import axios from 'axios'

import { getAuthorizedConfiguration } from '../helpers/RequestHelper'
import { AccountCode } from '../helpers/AppConstants'

export const IS_LOADING_USERS = 'IS_LOADING_USERS'
export const SET_INTERNAL_USERS = 'SET_INTERNAL_USERS'
export const SET_INTERNAL_USER_QUERYPARAMETERS = 'SET_INTERNAL_USER_QUERYPARAMETERS'
export const SET_USER = 'SET_USER'
export const ADD_NEW_USER = 'ADD_NEW_USER'
export const IS_SAVING = 'IS_SAVING'
export const SET_USER_PHONE_NUMBER = 'SET_USER_PHONE_NUMBER'
export const SET_ROLES_IN_PROFILE = 'SET_ROLES_IN_PROFILE'
export const REMOVE_USER_FROM_ACCOUNT = 'REMOVE_USER_FROM_ACCOUNT'
export const SET_VALIDATING_PASSWORD = 'SET_VALIDATING_PASSWORD'
export const IS_LOADING_USER = "IS_LOADING_USER"
export const IS_LOADING_NOTIFICATIONS = "IS_LOADING_NOTIFICATIONS"
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const REMOVE_NOTIFICATION_TO_USER = "REMOVE_NOTIFICATION_TO_USER"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER = "UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER"

/**
 * Is loading action constructor
 */
export const isLoadingUser = (status) => {
	return {
		type: IS_LOADING_USERS,
		status
	}
}

/**
 * Set internal users action creator
 */
export const setInternalUsers = (users) => {
	return {
		type: SET_INTERNAL_USERS,
		users
	}
}

/**
 * Set internal user sort criteria
 */
export const setInternalQueryParameters = (queryParametes) => {
	return {
		type: SET_INTERNAL_USER_QUERYPARAMETERS,
		queryParametes
	}
}

/**
 * Set user
 */
export const setUser = (user) => {
	return {
		type: SET_USER,
		user
	}
}

/**
 * Set user
 */
export const addUser = (user) => {
	return {
		type: ADD_NEW_USER,
		user
	}
}

/**
 * Is saving
 */
export const isSaving = (status) => {
	return {
		type: IS_SAVING,
		status
	}
}

/**Determinate if is validating password */
export const setValidatingPassword = (status) => {
	return {
		type: SET_VALIDATING_PASSWORD,
		status
	}
}

/**Add phone number for internal user */
export const setUserPhoneNumber = (userName, phoneNumber) => ({
	type: SET_USER_PHONE_NUMBER,
	userName,
	phoneNumber
});

const setRolesInProfile = (roles, userName) => {
	return {
		type: SET_ROLES_IN_PROFILE,
		roles: roles,
		userName: userName,
	};
};

/**Remove user from account */
const removeFromAccount = (token) => {
	return {
		type: REMOVE_USER_FROM_ACCOUNT,
		token
	};
};

/** Is loading user by userName */
export const isLoadingOnUser = (status) => {
	return {
		type: IS_LOADING_USER,
		status
	}
}

/**
 * Is loading action constructor
 */
export const isLoadingNotifications = (status) => {
	return {
		type: IS_LOADING_NOTIFICATIONS,
		status
	}
}

/**
 * Set notifications action creator
 */
export const setNotifications = (notifications) => {
	return {
		type: SET_NOTIFICATIONS,
		notifications
	}
}

export const removeNotificationUser = (requestToken) => {
	return {
		type: REMOVE_NOTIFICATION_TO_USER,
		requestToken
	}
}

const removeNotification = (notificationToken) => {
	return {
		type: REMOVE_NOTIFICATION,
		notificationToken
	}
}

/** 
 * Set the notifications state
*/
export const updateActiveNotifications = (user) => {
	return {
		type: UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER,
		user
	}
}

/**
 * Load the internal users page
 */
export const loadInternalUsers = (queryParameters) => {
	return async (dispatch, getState) => {
		try {
			let loadedUsers = getState().users.get('internalUsers')
			const hasBeenLoaded = loadedUsers.size > 0
			const loadUsersFromBackend = async (queryParameters) => {
				dispatch(isLoadingUser(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Create command
				let command = {
					AccountCode: AccountCode.IMBERA,
				}
				// Get the users
				let url = `${window.config.serviceManagementBackendUrl}/users/account`
				let serverResponse = await axios.post(url, command, config)
				let usersResult = mapUserServerResponse(serverResponse.data)
				await getFullUsersInformation(usersResult, null)
				dispatch(setInternalUsers(usersResult))
				if (queryParameters) {
					dispatch(setInternalQueryParameters(queryParameters))
				}
			}

			if (!hasBeenLoaded) {
				await loadUsersFromBackend(queryParameters)
			}

		} catch (ex) {
			console.error('Errog getting the internal users list', ex)
		}
	}
}

export const loadUserProfile = (token) => {
	return async function (dispatch, getState) {
		// Get the authorized configuration
		dispatch(isLoadingUser(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		let url = `${serviceManagementBackendUrl}/user/profile/${token}`
		var identityUser = getState().oidc.user

		let usersResult = null;
		let usersIdentity = null;
		return axios.get(url, config)
			.then(serverResponse => {
				usersResult = serverResponse.data;
				let urlIdentityServer = `${identityServerUrl}/api/user/${identityUser.profile.sub}`;
				return axios.get(urlIdentityServer, null)
			})
			.then(responseIdentity => {
				usersIdentity = responseIdentity.data
				let userMapped = mapCombineUserFromIdentity(usersResult, usersIdentity);
				dispatch(setUser(userMapped))
				dispatch(isLoadingUser(false))
				return Promise.resolve();

			})
			.catch((error) => {
				dispatch(isLoadingUser(false))
				console.error("Error loading user", error)
				return Promise.reject();
			});
	}
}

/**Fuction add new user in this project */
export const addNewUser = user => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let config = getAuthorizedConfiguration(getState().oidc.user);
		let command = {
			UserName: user.userName,
			Email: user.email,
			Name: user.name,
			Password: "sesamo",
			AccountCode: user.account.code,
		};
		const { identityServerUrl, serviceManagementBackendUrl } = window.config

		let identityUser;
		let endPoint = `${identityServerUrl}/api/internaluser`;
		return axios.post(endPoint, command, null)
			.then(userResponse => {
				identityUser = userResponse.data;
				command.IdentitySeverId = userResponse.data.id;
				return axios.post(`${serviceManagementBackendUrl}/user`, command, config);
			})
			.then(serviceProtalResponse => {
				dispatch(isSaving(false))
				let servicePortalUser = serviceProtalResponse.data;
				let mappedUser = mapCombineUserFromIdentity(servicePortalUser, identityUser || {});
				dispatch(addUser(mappedUser));
				return Promise.resolve();
			})
			.catch(error => {
				dispatch(isSaving(false))
				console.error("Error adding user", error)
				return Promise.reject();
			});
	}
}

/**Search user in directory active for add in this project */
export const lookForUser = userName => {
	const { identityServerUrl } = window.config
	let endPoint = `${identityServerUrl}/api/activedirectory/${userName}`;
	return axios.post(endPoint)
		.then(result => {
			let foundUser = null;
			if (result.data) {
				let userData = result.data;
				foundUser = {
					id: userData.id,
					userName: userData.user_name,
					name: userData.full_name,
					email: userData.email,
					type: userData.user_type
				}
			}
			return Promise.resolve(foundUser);
		})
		.catch(error => {
			console.error('Error finding user', error)
			return Promise.reject();
		});
}

/**Upload imagen dialog user */
export const uploadLogoUser = (userId, fileBase64) => {
	return function (dispatch, getState) {
		const { identityServerUrl } = window.config
		dispatch(isSaving(true))
		let endPoint = `${identityServerUrl}/api/user/${userId}/avatar`;
		let data = { fileBase64 };
		return axios.put(endPoint, data)
			.then(() => {
				dispatch(isSaving(false));
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(isSaving(false));
				console.error("Error uploading user avatar", error);
				return Promise.reject();
			});
	};
};

/**save information internl user */
export const saveUser = (user) => {
	return function (dispatch, getState) {
		let userConfig = getState().oidc.user
		let config = getAuthorizedConfiguration(userConfig)
		var userState = getState().users.toJS()
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		dispatch(isSaving(true))
		let identityCommand = {
			Email: user.Email,
			Name: user.Name,
			PhoneNumber: user.PhoneNumber
		};
		let usersResult = null;
		let usersIdentity = null;
		let endPoint = `${identityServerUrl}/api/user/${user.Id}`;
		return axios.put(endPoint, identityCommand)
			.then((identityUserResponse) => {
				usersIdentity = identityUserResponse.data;
				let url = `${serviceManagementBackendUrl}/user/profile/${user.Token}`
				return axios.get(url, config)
			})
			.then(responseIdentity => {
				dispatch(isSaving(false));
				usersResult = responseIdentity.data
				let userMapped = mapCombineUserFromIdentity(usersResult, usersIdentity);
				userMapped.AvatarUrl = userState ? userState.user.AvatarUrl : ""
				dispatch(setUser(userMapped))
				return Promise.resolve();

			})
			.catch((error) => {
				dispatch(isSaving(false));
				console.error("Error to save user", error);
				return Promise.reject(error);
			});
	};
};

export const addUserInRole = (token, roleName, roleType) => {
	return function (dispatch, getState) {
		let config = getAuthorizedConfiguration(getState().oidc.user);
		const { serviceManagementBackendUrl } = window.config;
		dispatch(isSaving(true))
		let command = {
			UserToken: token,
			RoleName: roleName,
			Type: roleType
		}
		let endPoint = `${serviceManagementBackendUrl}/user/roles`;
		return axios.post(endPoint, command, config)
			.then(response => {
				dispatch(isSaving(false))
				dispatch(setRolesInProfile(response.data.Roles, response.data.UserName));
				return Promise.resolve(response.data.Roles);
			})
			.catch(error => {
				dispatch(isSaving(false))
				console.error("Error to add role to user", error);
			})
	}
}

export const removeUserFromRole = (token, claimValue) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let config = getAuthorizedConfiguration(getState().oidc.user);
		const { serviceManagementBackendUrl } = window.config;

		let command = {
			UserToken: token,
			ClaimValue: claimValue,
		}
		let endPoint = `${serviceManagementBackendUrl}/user/${token}/roles/${claimValue}`;
		return axios({
			method: 'delete',
			url: endPoint,
			data: command,
			headers: config.headers
		})
			.then(response => {
				dispatch(isSaving(false))
				dispatch(setRolesInProfile(response.data.Roles, response.data.UserName));
				return Promise.resolve(response.data.Roles);
			})
			.catch(error => {
				dispatch(isSaving(false))
				console.error('Error removing user from role.', error);
				return Promise.reject(error.response.data.Message);
			});
	}

}

/**Remove user from account */
export const removeUserFromAccount = (token, code) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let config = getAuthorizedConfiguration(getState().oidc.user);
		const { serviceManagementBackendUrl } = window.config;

		let endPoint = `${serviceManagementBackendUrl}/user/${token}/account/${code}`;
		return axios({
			method: 'delete',
			url: endPoint,
			data: null,
			headers: config.headers
		})
			.then(_ => {
				dispatch(removeFromAccount(token))
				dispatch(isSaving(false))
				return Promise.resolve();
			})
			.catch(error => {
				dispatch(isSaving(false))
				console.error('Error removing user from account', error);
				return Promise.reject();
			});

	}
}

/**
 * Action to load user
 * @param {*} userName 
 */
export const loadUserByUserName = (userName) => {
	return function (dispatch, getState) {
		dispatch(isLoadingOnUser(true))
		const { identityServerUrl } = window.config
		let url = `${identityServerUrl}/api/user/userName/${userName}`
		return axios.get(url)
			.then(serverResponse => {
				dispatch(isLoadingOnUser(false))
				return Promise.resolve(serverResponse.data);
			})
			.catch((error) => {
				dispatch(isLoadingOnUser(false))
				console.error("Error loading user", error)
				return Promise.reject();
			});
	}
}

export const confirmUserPassword = (password) => {
	return function (dispatch, getState) {
		dispatch(setValidatingPassword(true));
		// Get the authorized configuration
		let userToken = getState().oidc.user.profile.sub;
		let command = {
			UserId: userToken,
			UserPassword: password
		};
		let endPoint = `${window.config.identityServerUrl}/api/user/credentialsconfirm`;

		return axios.post(endPoint, command)
			.then(response => {
				dispatch(setValidatingPassword(false));
				return Promise.resolve(response.data);
			})
			.catch(error => {
				dispatch(setValidatingPassword(false));
				return Promise.reject();
			})
	}
}

/** this Function search for imbera users to find out if it has already been added */
export const findUserByUserName = userName => {
	return function (dispatch, getState) {
		let allUsers = getState().users.get('internalUsers');
		let foundUser = allUsers.find(u => u.get('UserName').toLowerCase() === userName)
		return Promise.resolve(foundUser ? true : false);
	}
}

/**
 * Get the full user information for a list of users
 */
export const getFullUsersInformation = async (usersList, configuration) => {
	try {
		let url = `${window.config.identityServerUrl}/api/users`
		const content = usersList.map((user) => user.UserName)
		let useIdentityData = await axios.post(url, content, configuration)
		return usersList.map((user) => {
			let usesData = useIdentityData.data.find(
				(x) => x.user_name === user.UserName
			)
			user.Name = usesData ? usesData.full_name : ''
			user.Email = usesData ? usesData.email : ''
			user.Id = usesData ? usesData.id : ''
			user.AvatarUrl = usesData ? usesData.avatar_url : ''
			user.UserType = usesData ? usesData.user_type : ''
			user.PhoneNumber = usesData ? (usesData.phone_number ? usesData.phone_number : "") : ''
			user.LastAuthenticationDate = usesData ? (usesData.last_authentication_date ? usesData.last_authentication_date : '') : ''
			return user
		})
	} catch (ex) {
		console.error('Errog getting the internal users list', ex)
	}
}

export const mapUserServerResponse = (usersServerRespose) => {
	return usersServerRespose.map((userServer) => {
		return {
			UserName: userServer.UserName,
			Active: true,
			Token: userServer.Token,
			Account: userServer.Account,
			Roles: userServer.Roles,
			Claims: userServer.Claims,
			ActiveNotifications: userServer.ActiveNotifications,
		}
	})
}


export const mapCombineUserFromIdentity = (usersResult, usersIdentity) => {
	let mappedUser = {};
	mappedUser.Token = usersResult ? usersResult.Token : ""
	mappedUser.Roles = usersResult ? usersResult.Roles : ""
	mappedUser.Account = usersResult ? usersResult.Account : ""
	mappedUser.Active = usersResult ? usersResult.Enabled : ""
	mappedUser.Name = usersIdentity ? usersIdentity.name : ""
	mappedUser.Id = usersIdentity ? usersIdentity.id : ""
	mappedUser.Email = usersIdentity ? usersIdentity.email : ""
	mappedUser.FullName = usersIdentity ? usersIdentity.full_name : ""
	mappedUser.PhoneNumber = usersIdentity ? usersIdentity.phone_number : ""
	mappedUser.UserName = usersIdentity ? usersIdentity.user_name : ""
	mappedUser.AvatarUrl = usersIdentity ? usersIdentity.avatar_url : ""
	mappedUser.UserType = usersIdentity ? usersIdentity.user_type : ""
	mappedUser.LastAuthenticationDate = usersIdentity ? (usersIdentity.last_authentication_date ? usersIdentity.last_authentication_date : '') : ''
	mappedUser.ActiveNotifications = usersResult ? usersResult.ActiveNotifications : ""
	return mappedUser;

}

/**
 * Action to load notificataions from user
 */
export const loadNotificationsByUser = () => {
	return async (dispatch, getState) => {
		try {
			let loadedNotifications = getState().users.get('notifications')
			const hasBeenLoaded = loadedNotifications.size > 0
			const loadUNotificationsFromBackend = async () => {
				dispatch(isLoadingNotifications(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Get the notifications
				let url = `${window.config.serviceManagementBackendUrl}/user/notifications`
				let serverResponse = await axios.post(url, null, config)
				dispatch(setNotifications(serverResponse.data))
			}

			if (!hasBeenLoaded) {
				await loadUNotificationsFromBackend()
			}

		} catch (ex) {
			console.error('Errog getting the notifications by user list', ex)
		}
	}
}

/**
 * Action to remove notification from user
 * @param {*} token 
 * @param {*} requestToken 
 */
export const removeNotificationFromUserFromBack = (token) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		let config = getAuthorizedConfiguration(getState().oidc.user);
		const { serviceManagementBackendUrl } = window.config;

		let endPoint = `${serviceManagementBackendUrl}/user/notifications/${token}`;
		return axios({ method: 'delete', url: endPoint, data: null, headers: config.headers })
			.then(_ => {
				dispatch(removeNotification(token))
				dispatch(isSaving(false))
				return Promise.resolve();
			})
			.catch(error => {
				dispatch(isSaving(false))
				console.error('Error removing notification from user', error);
				return Promise.reject();
			});
	}
}

/**
 * Action to set setUserNameLoggedInSupplier
 * @param {*} provider 
 */
export const activeUserExpiredToSingPortal = (userIdActive) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		const { identityServerUrl } = window.config
		// Get the authorized configuration
		let endPoint = `${identityServerUrl}/api/users/active/${userIdActive}/expired`;
		return axios.put(endPoint, null)
			.then((response) => {
				dispatch(isSaving(false));
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				console.error("Error update user", error.response);
				dispatch(isSaving(false));
				return Promise.reject();
			})
	}
}

/**
* ACTION DIALOG TO RESEND USER  PASSWORD 
*/
export const resendPasswordInvitation = (userUpdate, password) => {
	return function (dispatch, getState) {
		dispatch(isSaving(true))
		// GET USER INFORMATION
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			loggedUserName: userUpdate.UserName ? userUpdate.UserName : "",
			NewPassword: password,
			UserName: userUpdate.UserName ? userUpdate.UserName : "",
			Email: userUpdate.Email ? userUpdate.Email : "",
			Name: userUpdate.Name ? userUpdate.Name : '',
		}
		const { identityServerUrl, serviceManagementBackendUrl } = window.config
		let urlIdentityServer = `${identityServerUrl}/api/users/${userUpdate.Id}/password`
		let urlServiceUsa = `${serviceManagementBackendUrl}/user/resend/password`
		return axios.put(urlIdentityServer, command)
			.then(() => {
				return axios.put(urlServiceUsa, command, config)
			})
			.then(() => {
				dispatch(isSaving(false))
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(isSaving(false))
				console.error("Error to resend passwor from user", error)
				return Promise.reject();
			});
	}
}

export const editActiveNotifications = (token, activeNotification) => {
	return function (dispatch, getState) {
		const { serviceManagementBackendUrl } = window.config
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			Token: token,
			ActiveNotifications: activeNotification
		};
		let url = `${serviceManagementBackendUrl}/user/notifications/activation`
		return axios.put(url, command, config)
			.then(serverResponse => {
				dispatch(updateActiveNotifications(serverResponse.data))
				return Promise.resolve();
			})
			.catch((error) => {
				console.error("Error to change Notification status", error)
				return Promise.reject(error.response.data.Message);
			});
	}
}

export const downloadInternalUsersList = () => {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let endPoint = `${window.config.serviceManagementBackendUrl}/user/internalUsersReport`
		return axios
			.post(
				endPoint,
				{},
				{
					responseType: 'arraybuffer',
					headers: config
				}
			)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Internal_users_list.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error donwload list of internal users', error)
				return Promise.reject()
			})
	}
}
