/**Import react section */
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'

/**Import react geogle maps */
import { withScriptjs, withGoogleMap, GoogleMap, InfoWindow, Marker } from "react-google-maps"
import Autocomplete from 'react-google-autocomplete';
import { compose } from "recompose"

/** Import icon map */
import inconMap from '../../../resources/images/ubicacionEnfriadores2rojo.png';

/**
 * Conponent to show Map gelocalitaion
 * @param {*} props 
 */
const MapWrapper = compose(withScriptjs, withGoogleMap)(props => {
    const { directions, fullAddress, markerPosition, modeCreate } = props;
    const { onChangeAddress, onPlaceSelected, onMarkerDragEnd } = props;
    const [defaultZoom, setDefaultZoom] = useState(13);
    const [openOInfoId, setOpenInfoId] = useState('');

    useEffect(() => {
        setDefaultZoom(props.defaultZoom)
    }, [props.defaultZoom])
    /**
     * Action to seelct address retail
     * @param {*} address 
     */
    const onSelectDirectionMap = (address) => {
        if (onChangeAddress)
            onChangeAddress(address);
    }

    const handleToggleMarker = (markerId) => {
        setOpenInfoId(markerId)
    }

    /**
     * Render component
     */
    return (
        <GoogleMap
            defaultZoom={defaultZoom}
            zoom={defaultZoom}
            defaultCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        >
            {modeCreate && <Autocomplete
                placeholder="Search address"
                style={{ display: "flex", width: '98%', height: '35px', marginTop: '-350px', paddingLeft: "5px" }}
                onPlaceSelected={onPlaceSelected}
                types={[]}
            />}

            {fullAddress && <InfoWindow
                position={{ lat: (markerPosition.lat + 0.0018), lng: markerPosition.lng }}
            >
                <div>
                    <span style={{ padding: 0, margin: 0 }}>{fullAddress}</span>
                </div>
            </InfoWindow>}

            <Marker
                position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
                draggable={true}
                onDragEnd={modeCreate && onMarkerDragEnd}
                onMouseOver={handleToggleMarker}
            />

            {directions.map((direction) => {
                return (<Marker
                    key={direction.token}
                    position={{ lat: direction.latitude, lng: direction.longitude }}
                    onClick={() => onSelectDirectionMap(direction)}
                    icon={inconMap}
                    onMouseOver={() => handleToggleMarker(direction.token)}
                >
                    {directions.length < 2 && <InfoWindow
                        position={{ lat: direction.latitude, lng: direction.longitude }}
                    >
                        <div>
                            <span style={{ padding: 0, margin: 0 }}>{direction.full_address}</span>
                        </div>
                    </InfoWindow>}

                    {openOInfoId === direction.token && directions.length > 2 && <InfoWindow
                        position={{ lat: direction.latitude, lng: direction.longitude }}
                    >
                        <div>
                            <span style={{ padding: 0, margin: 0 }}>{direction.full_address}</span>
                        </div>
                    </InfoWindow>}
                </Marker>
                )
            })}

        </GoogleMap>
    )
})

MapWrapper.propTypes = {
    /**Function invoke callback for set properties in the state local from address*/
    onChangeAddress: propTypes.func,
    onPlaceSelected: propTypes.func,
    onMarkerDragEnd: propTypes.func,
    /** object to get position defautl from map */
    markerPosition: propTypes.object.isRequired,
    fullAddress: propTypes.string,
    directions: propTypes.array,
}
MapWrapper.defaultProps = {
    directions: [],
    fullAddress: "",
    onChangeAddress: () =>
        console.warn('Callback [onChangeAddress] no defined'),
    onPlaceSelected: () =>
        console.warn('Callback [onPlaceSelected] no defined'),
    onMarkerDragEnd: () =>
        console.warn('Callback [onMarkerDragEnd] no defined'),
}

export default MapWrapper;

