import { fromJS } from 'immutable'
import * as actions from '../sparePartSales/sparePartSalesAction'

const initialSparePartState = fromJS({
	isLoadingDashboard: false,
	dashboard: null,
	sparePartSelector: [],
	create: {
		isGettingDraft: false,
		isSavingDraft: false,
		spareParts: [],
		draft: null,
		isSavingRequestDraft: false
	},
	index: {
		isLoading: false,
		requests: [],
		pagination: {
			pageNumber: 1,
			pageSize: 10,
			total: 0,
			orderBy: 'Folio',
			orderType: 'desc'
		}
	},
	show: {
		isLoading: false,
		open: null,
		isSaving: false,
		isGettingTrackingData: false,
		isLoadingFromSAP: false,
		equipmentsSap: null,
		isGetStock: false,
		querySparePart: '',
		carrier: null
	}
})

/**
 * spare part sales requests reducer
 */
const sparePartSalesReducer = (state = initialSparePartState, action) => {
	switch (action.type) {
		case actions.IS_LOADING_SALES_LIST: {
			return state.setIn(['index', 'isLoading'], action.status)
		}
		case actions.SET_SPAREPARTRSALES_LIST: {
			let actualIndex = state.get('index').toJS()
			let updatedIndex = {
				...actualIndex,
				isLoading: false,
				requests: action.requests,
				pagination: fromJS({
					pageNumber: action.pagination.pageNumber,
					pageSize: action.pagination.pageSize,
					total: action.pagination.total,
					orderBy: action.pagination.orderBy,
					orderType: action.pagination.orderType
				})
			}
			return state.setIn(['index'], fromJS(updatedIndex))
		}
		case actions.IS_SAVING_DRAFT_SPAREPARTSALES: {
			return state.setIn(['create', 'isSavingRequestDraft'], action.status)
		}
		case actions.SET_SPAREPARSALES_DRAFT: {
			let actualRequest = state.get('create').toJS()
			let updatedRequest = {
				...actualRequest,
				draft: action.draft,
				isSavingDraft: false
			}
			return state.setIn(['create'], fromJS(updatedRequest))
		}
		case actions.IS_DONWLOAD_REPORT_PRICES: {
			return state.setIn(['index', 'isDownloadReport'], action.status)
		}
		case actions.IS_LOADING_DASHBOAR_SALES:
			return state.merge({
				isLoadingDashboard: action.status
			})
		case actions.SET_DASHBOAR_SALES:
			return state.merge({
				isLoadingDashboard: false,
				dashboard: fromJS(action.dashboard)
			})
		case actions.SET_SPAREPART_INFO_SELECTOR:
			return state.merge({
				sparePartSelector: action.sparePartsInfo
			})
		case actions.IS_LOADING_SPARE_PART_SALES: {
			return state.setIn(['show', 'isLoading'], action.status)
		}
		case actions.SET_SPAREPARTSALES_OPEN: {
			let actualRequest = state.get('show').toJS()
			let updatedRequest = {
				...actualRequest,
				open: fromJS(action.sparePart),
				isLoading: false
			}
			return state.setIn(['show'], fromJS(updatedRequest))
		}
		case actions.IS_SAVING_SPARE_PART_DETAIL: {
			return state.setIn(['show', 'isSaving'], action.status)
		}
		case actions.SET_SPAREPARTDETAIL_OPEN: {
			let actualRequest = state.get('show').toJS()
			let updatedRequest = {
				...actualRequest,
				open: fromJS(action.sparePart),
				isLoading: false
			}
			return state.setIn(['show'], fromJS(updatedRequest))
		}
		case actions.IS_GET_STOCK: {
			return state.setIn(['show', 'isGetStock'], action.status)
		}
		case actions.SET_EXISTENCE_STOCK: {
			let existenceList = action.data
			return state.updateIn(['show', 'open', 'SpareParts'], (allSpareParts) => updateEquipmentSAP(allSpareParts, action.code, existenceList))
		}
		case actions.ADD_FILES_SALES: {
			let files = fromJS(action.evidences)
			return state.setIn(['show', 'open', 'Files'], files)
		}
		case actions.REMOVE_FILES_SALES: {
			return state.updateIn(['show', 'open', 'Files'], (files) => files.filter((file) => file.get('name') !== action.fileName))
		}
		case actions.REMOVE_SPARE_PART_DETAIL: {
			return state.updateIn(['show', 'open', 'SpareParts'], (details) =>
				details.filter((detail) => detail.get('Token') !== action.sparePart.Token)
			)
		}
		case actions.REMOVE_SPARE_PART_SALE: {
			let updatedRequestCreate = {
				isLoading: false,
				open: null,
				isSaving: false,
				isGettingTrackingData: false,
				isLoadingFromSAP: false,
				equipmentsSap: null,
				isGetStock: false,
				querySparePart: ''
			}
			return state.setIn(['show', 'open'], fromJS(updatedRequestCreate))
		}
		case actions.UPDATE_SPARE_PART_SALE: {
			let actualRequest = state.get('show').toJS()
			let updatedRequest = {
				...actualRequest,
				open: fromJS(action.sparePart),
				isLoading: false
			}
			return state.setIn(['show'], fromJS(updatedRequest))
		}
		case actions.UPDATE_SPARE_PART_DETAIL: {
			return state.updateIn(['show', 'open', 'SpareParts'], (allSpareParts) => {
				let index = allSpareParts.findIndex((item) => {
					return item.get('Token') === action.sparePart.Token
				})
				return allSpareParts.setIn([index], fromJS(action.sparePart))
			})
		}
		case actions.GETTING_INFORMATION_TRACKING_SALE: {
			return state.setIn(['show', 'isGettingTrackingData'], action.status)
		}
		case actions.SET_CARRIER_DATA_SALES: {
			let actualRequest = state.get('show').toJS()
			let updatedRequest = {
				...actualRequest,
				carrier: fromJS(action.data),
				isLoading: false
			}
			return state.setIn(['show'], fromJS(updatedRequest))
		}
		case actions.UPDATE_STATUS_SALES: {
			return state.updateIn(['show', 'open', 'SpareParts'], (allSpareParts) => {
				let index = allSpareParts.findIndex((item) => {
					return item.get('NumberTracking') === action.trackingNumber
				})
				return allSpareParts.setIn([index, 'Status'], action.status)
			})
		}
		default:
			return state
	}
}

const updateEquipmentSAP = (allSpareParts, code, data) => {
	let indexOfSparePart = allSpareParts.findIndex((e) => e.get('Code') === code)
	if (indexOfSparePart >= 0) {
		let equipment = allSpareParts.get(indexOfSparePart).toJS()
		equipment.Existences = data
		return allSpareParts.setIn([indexOfSparePart], fromJS(equipment))
	}
	return allSpareParts
}

export default sparePartSalesReducer
