import { makeStyles } from '@material-ui/core/styles';

export const DateRangeSelectStyles = makeStyles((theme) => ({
  root: {
    
    bottom: 0,
    height: '100%'
  },
  buttonsGroupContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
},
buttonsGroup: {
    borderRadius: '6px',
    height: '40px',
    width: '100%',
    border: '1px solid #ddd',
},
}));