/** Import react section **/
import React, { useState } from 'react'
import moment from 'moment'
import propTypes from 'prop-types'

/**Import Material UI section */
import { Button, Tooltip, ButtonGroup } from '@material-ui/core'
import CloudDownload from '@material-ui/icons/CloudDownload'

/**Import components sections */
import Popper from './Popper'

/**Import resources section */
import { DateRangeSelectStyles } from '../../../resources/styles/ServicesListStyles'
/**
 * 
 * @param {*} props 
 */
const ReportDateRange = (props) => {
    const classes = DateRangeSelectStyles();
    const { data, onDownloadReport, isDownloadReport } = props;

    const [settingsEl, setSettingsEl] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf('year'));
    const [endDate, setEndDate] = useState(moment().startOf('day'));

    /**Hanlde  menu open popper */
    const handleMenuOpen = (event) => {
        setSettingsEl(event.currentTarget);
    };

    /**Close menu popper filter */
    const onHandleNullSetting = () => {
        setSettingsEl(null);
    };

    const onDateRangeChange = (range) => {
        setStartDate(range.startDate)
        setEndDate(range.endDate)
    }
    

    return (
        <div className={classes.root}>
            <ButtonGroup component="span" size='small' variant="outlined" color="primary" className={classes.buttonsGroupContainer}>
                <Tooltip component="span" title='Download reports'  >
                    <Button
                        className={classes.buttonsGroup}
                        onClick={settingsEl ? onHandleNullSetting: handleMenuOpen}
                        disabled={isDownloadReport}
                    >
                        <CloudDownload/>
                        {/* <img src={ReportIcon} alt='reportIcon' style={{width:"25px", height:"auto"}}/> */}
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <Popper
                settingsEl={settingsEl}
                setSettingsEl={onHandleNullSetting}
                data={data}
                startDate={startDate}
                endDate={endDate}
                onDateRangeChange={onDateRangeChange}
                onDownloadReport={onDownloadReport}
                isDownloadReport={isDownloadReport}
            />
        </div>
    )
}

ReportDateRange.propTypes = {
	/**
	 * Callback execute for fownload report by range date 
	 */
    onDownloadReport: propTypes.func.isRequired,
    /**
     * Determinate if is download report
     */
    isDownloadReport: propTypes.bool.isRequired,
}

ReportDateRange.defaultProps = {
    isDownloadReport: false,
    onDownloadReport: () =>
        console.warn('Callback [onDownloadReport] no defined'),
}

export default ReportDateRange
