import { makeStyles } from '@material-ui/styles'

export const useCreateDesktopStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        maxHeight: `calc(100vh - ${85}px)`,
        overflowY: 'auto'
    },
    rootIndex: {
        display: 'grid',
		gridTemplateRows: 'auto auto 1fr'
    },
    containerIndex: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		gridColumn: 1,
		gridRow: 2,
		overflowY: 'auto',
		maxHeight: `calc(100vh - ${300}px)`,
		minHeight: `calc(100vh - ${300}px)`,
    },
    container: {
        padding: "5px",
    },
    containerData: {
        maxHeight: `calc(100vh - ${150}px)`,
        overflowY: "auto",
    },
    titleTicket: {
        color: theme.palette.colors.purple,
        padding: "10px"
    },
    headerPanelClose:{
        minHeight: "0px !important",
        height: "50px !important",
    },
    headerPanelOpen:{
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        minHeight: "0px !important",
        height: "50px !important"
    },
    title: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        marginTop: "10px"
    },
    titleSerie:{
        marginTop: "5px"
    },
    bottonRemove:{
        marginLeft: "12px",
        marginTop: "8px",
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.red,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.red,
            color: theme.palette.common.white,
            opacity: .65,
        },
    },
    flexGrow: {
        flexGrow: 1
    },
    titleServices:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        minHeight: "0px !important",
        height: "50px !important"
    },
    pagination: {
        gridColumn: 1,
		gridRow: 3,
		display: 'flex',
		alignItems:"flex-end"
    },
    cardList: {
		gridRow: '1 / 2',
		margin: '10px 10px 0px 0px',
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		columnGap: '5px'
    },
    buttonsGroupContainer: {
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroupEquipmentContainer: {
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroupReport: {
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.gray,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
    },
    buttonsGroup: {
        display:"flex",
        padding: "20px",
        paddingBottom: "18px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        "&:hover": {
            backgroundColor: theme.palette.colors.primarySelected,
            color: theme.palette.common.white,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.primaryDisabled,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.MuiButton-root": {
            minWidth:"14px !important",
            height:"2.3rem !important",
        },
    },
    textExpanded:{
        color: theme.palette.colors.purple,
    },
    textExpandedBold:{
        marginLeft:theme.spacing(2),
    },
    containerSpareParts:{
        display: "grid",
        gridTemplateRows: 'auto auto ',
        width:"100%"
    },
    buttonCleanContainer:{
        display:"flex",
        gridRow: 1,
        marginTop: "20px",
        height: "47px !important"
    },
    tableSpareParts:{
        gridRow: 2,
    },
    notchedOutlineSuccess: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8
    },
    iconAmount:{
        color: `${theme.palette.serviceStatus.newServiceMain}`,
    },
    headerIconAmount:{
        width: "120px",
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paperSummary: {
        padding: "20px",
        overflowY: "auto",
    },
    setMarginGrid:{
        paddingTop:"25px"
    },
    iconSummary:{
        color: "white", 
        background: `${theme.palette.colors.purple}`,
    },
    containerCreation: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		gridColumn: 1,
		gridRow: 2,
		overflowY: 'auto',
		maxHeight: `calc(100vh - ${410}px)`,
    },
    containerDesktop: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		gridColumn: 1,
		gridRow: 2,
		overflowY: 'auto',
		maxHeight: `calc(100vh - ${450}px)`,
    },
    inconMargin:{
        marginBottom: '-5px',
        paddingRight: "5px"
    },
    amountIconSelected: {
        marginRight: theme.spacing(0.5),
    },
    amountIcon: {
        width: '45px',
        height: 'auto',
    },
}))

