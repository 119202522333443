import React, { useState, Fragment } from 'react'
import propTypes from 'prop-types'
import defaultTheme from '../../../resources/themes/default'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
/** Import components */
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import headerConfig from '../../services/resources/ServiceTableConfig'

import SideBar from '../../common/DashboadSideBar'
import DashboardCard from '../../dashboard/components/DashboardCard'
import ServicesTables from '../../services/components/ServicesTable'
import Pagination from '../../common/Pagination/Pagination'
import { useDashboardContainer } from '../../../resources/styles/DashboardStyles'

import { useConfigurator } from '../../common/apsys.datagrid.materialui/UseConfigurator'
import Configurator from '../../common/apsys.datagrid.materialui/PopperConfigurator'
import { IconButton } from '@material-ui/core'
import ConfigureIcon from '@material-ui/icons/SettingsOutlined'
import {Typography} from '@material-ui/core'

import {GetStatusOpenServices} from '../../../store/helpers/FilterHelper'

/**
 * Authenticated desktop view
 */
const DesktopView = (props) => {
	/**Functions */
	const {
		onChangeSearchValue,
		redirectToCreateService,
		redirectToServiceDetail,
		onDownloadReportOpenServices,
		openImportCommentsPanel
	} = props
	/** Arrays*/
	const {
		serviceProviders,
		salesRepresentatives,
		retails,
		internalUsers
	} = props
	/**String values */
	const { searchValue } = props
	/**Objects */
	const { userProfile } = props
	/**bools */
	const { isDownloadReport } = props
	const contentClasses = useDashboardContainer()
	/** Table configuration */
	const [serviceTableConfiguration, onChangeConfiguration] = useConfigurator(
		'dashboard-services-datagrid-config',
		headerConfig
	)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)

	/**
	 * The user change the service table configuration
	 */
	const onConfigurationChange = (configuration) => {
		onChangeConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	return (
		<Layout>
			<Header 
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Services</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={1}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={contentClasses.root}>
					<div className={contentClasses.cardList}>
						<DashboardCardLists
							isLoadingDashboard={props.isLoadingDashboard}
							dashboard={props.dashboard}
						/>
					</div>
					<div className={contentClasses.container}>
						<ServicesTables
							services={props.services}
							isLoading={props.isLoading}
							onchangeSortingCriteria={
								props.onchangeServiceSortingCriteria
							}
							sortBy={props.sortServiceBy}
							sortDirection={props.sortServiceDirection}
							serviceProviders={serviceProviders}
							salesRepresentatives={salesRepresentatives}
							retails={retails}
							onChangeSearchValue={onChangeSearchValue}
							searchValue={searchValue}
							internalUsers={internalUsers}
							isServicesOpen={true}
							redirectToCreateService={redirectToCreateService}
							redirectToServiceDetail={redirectToServiceDetail}
							userProfile={userProfile}
							configuration={serviceTableConfiguration}
							optionsStatus={GetStatusOpenServices()}
							onDownloadReportOpenServices={onDownloadReportOpenServices}
							isDownloadReport={isDownloadReport}
							openImportCommentsPanel={openImportCommentsPanel}
						/>
					</div>
					<div className={contentClasses.pagination}>

						<div className={contentClasses.grow} />
						<Pagination
							count={props.totalServicesPages}
							page={props.servicePageNumber}
							onChangePage={props.onChangeServicePage}
						/>
						<IconButton
							size='small'
							color='primary'
							onClick={(event) =>
								setConfigurationAnchorEl(
									configuratorAnchorEl
										? null
										: event.currentTarget
								)
							}
						>
							<ConfigureIcon size='small' />
						</IconButton>
					</div>
					<Configurator
						open={configuratorAnchorEl ? true : false}
						anchorEl={configuratorAnchorEl}
						configuration={serviceTableConfiguration}
						onConfigurationChange={onConfigurationChange}
						onClickAway={() => setConfigurationAnchorEl(null)}
					/>
				</div>
			</Content>
		</Layout>
	)
}
DesktopView.propTypes = {
	/**
	 * The list of services
	 */
	services: propTypes.arrayOf(
		propTypes.shape({
			Ticket: propTypes.any.isRequired,
			Serie: propTypes.any.isRequired,
			Model: propTypes.any.isRequired
		})
	).isRequired,
	/**
	 * The total service's pages
	 */
	totalServicesPages: propTypes.number.isRequired,
	/**
	 * The actual page number
	 */
	servicePageNumber: propTypes.number.isRequired,
	/**
	 * Callback executed when the user change the service's page
	 */
	onChangeServicePage: propTypes.func,
	/**
	 * On change service sorting criteria
	 */
	onchangeServiceSortingCriteria: propTypes.func,
	/**
	 * Determine the sorting column for the service table
	 */
	sortServiceBy: propTypes.string,
	/**
	 * The sort direction for the service table
	 */
	sortServiceDirection: propTypes.oneOf(['asc', 'desc']),
	/**
	 * Determine if is loading the services list
	 */
	isLoading: propTypes.bool.isRequired,
	/**
	 * Callback executed when the user request a change location
	 */
	onChangeLocation: propTypes.func.isRequired,
	/**
	 * This function executes the download of the open services
	 */
	onDownloadReportOpenServices: propTypes.func,
}
DesktopView.defaultProps = {
	services: [],
	totalServicesPages: 0,
	servicePageNumber: 0,
	isLoading: false
}

/**
 * DashboardCardLists component
 */
const DashboardCardLists = (props) => {
	const { dashboard } = props

	return (
		<Fragment>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.newServiceMain}
				secondaryColor={
					defaultTheme.palette.serviceStatus.newServiceDark
				}
				totalServices={dashboard ? dashboard.totalActive : 0}
				percentajeValue={
					dashboard ? dashboard.percentageTotalActive : 0
				}
				label={'New Services'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.assignedMain}
				secondaryColor={defaultTheme.palette.serviceStatus.assignedDark}
				totalServices={dashboard ? dashboard.totalAssigned : 0}
				percentajeValue={
					dashboard ? dashboard.percentageTotalAssigned : 0
				}
				label={'Assigned Services'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.suspendedMain}
				secondaryColor={
					defaultTheme.palette.serviceStatus.suspendedDark
				}
				totalServices={dashboard ? dashboard.totalSuspendend : 0}
				percentajeValue={
					dashboard ? dashboard.percentageTotalSuspendend : 0
				}
				label={'Suspended  Services'}
			/>
			<DashboardCard
				mainColor={
					defaultTheme.palette.serviceStatus.waitingSparePartsMain
				}
				secondaryColor={
					defaultTheme.palette.serviceStatus.waitingSparePartsDark
				}
				totalServices={dashboard ? dashboard.totalWaiting : 0}
				percentajeValue={
					dashboard ? dashboard.percentageTotalWaiting : 0
				}
				label={'Awaiting Spare Parts'}
			/>
		</Fragment>
	)
}

export default withImmutablePropsToJS(DesktopView)
