/**Import react section */
import React, { useState } from 'react'
import moment from 'moment'
/**Import Material UI section */
import { Stepper, Step, StepLabel, Typography, StepContent } from '@material-ui/core'

const TimeLineAttentionDate = (props) => {
    const { dateAttentions } = props;
    const [activeStep, setActiveStep] = useState(0)

    const onClickShowItem = (event,stepNumber) => {
        event.stopPropagation()
        setActiveStep(prevActiveStep => stepNumber);
    };

    return (
        <div>
            <Typography variant="subtitle1">History attention date</Typography>
            <Stepper activeStep={activeStep} orientation="vertical" style={{margin:0, padding:0}}>
                {dateAttentions.map((d, index) => {
                    return (
                        <Step key={index}>
                            <StepLabel onClick={(event) => onClickShowItem(event, index)}>
                                <div >
                                    <Typography variant='caption' >{`Attention date: ${moment(d.DateAttention).format("MM/DD/YYYY")}`}</Typography>
                                </div>
                            </StepLabel>
                            <StepContent>
                                <Typography color="textSecondary" variant='caption'>{`Creator: ${d.CreatorUser ? d.CreatorUser.UserName : ""}`}</Typography><br/>
                                <Typography color="textSecondary" variant='caption'>{`Creation date: ${moment(d.CreationDate).format("MM/DD/YYYY")}`}</Typography><br/>
                                {d.Comments && <Typography color="textSecondary" variant='caption'>{`Comments: ${d.Comments ? d.Comments : "No comment"}`}</Typography>}
                            </StepContent>
                        </Step>
                    )
                })}
            </Stepper>
        </div>
    )
}

export default TimeLineAttentionDate