/**
 * Determine if a string is null or empty
 *
 * @export
 * @param value
 */
export function isNullOrEmpty(value) {
	if (!value) {
		return true
	}
	
	if (value === null || value === undefined) {
		return true
	}

	let trimmed = value.trim()
	if (trimmed.length === 0) {
		return true
	}
	return false
}

/**
 * Determine is null or empty avatar
 * @param {*} value 
 */
export function isNullOrEmptyAvatar(value) {
	if (!value) {
		return true
	}
	
	if (value === null || value === undefined) {
		return true
	}

	let trimmed = value.trim()
	if (trimmed.length === 0) {
		return true
	}
	return false
}
