export const PORTAL_ADMINISTRATOR = 'PORTAL_ADMINISTRATOR'
export const IMBERA_TECHNICIAN = 'IMBERA_TECHNICIAN'
export const DISPATCHER = 'DISPATCHER'
export const SPARE_PARTS_IMBERA_USA = 'SPARE_PARTS_IMBERA_USA'
export const SPARE_PARTS_IMBERA_MX = 'SPARE_PARTS_IMBERA_MX'
export const PROVIDER_REGISTRATION_VALIDATOR = 'PROVIDER_REGISTRATION_VALIDATOR'
export const VISUALIZER = 'VISUALIZER'
export const PAYMENT_MANAGER = 'PAYMENT_MANAGER'

export const SERVICE_PROVIDER_MANAGER = 'SERVICE_PROVIDER_MANAGER'
export const SERVICE_PROVIDER_TECHNICIAN = 'SERVICE_PROVIDER_TECHNICIAN'

export const SALES_MANAGER = 'SALES_MANAGER'
export const SALES_REPRESENTATIVE_MANAGER = 'SALES_REPRESENTATIVE_MANAGER'

export const CUSTOMER_MANAGER = 'CUSTOMER_MANAGER'
export const CUSTOMER_TECHNICIAN = 'CUSTOMER_TECHNICIAN'
