/**Import react section */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, Grid } from '@material-ui/core';
/**Import squeletons components section */
import ToolbarSkeleton from '../services/componets/ToolbarSkeleton'
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import { useCreateDesktopStyle } from '../../spareParts/resources/useStyles'
import EquimpentTablaConfig from '../../services/resources/EquimpentTablaConfig'

const OpenDesktopViewSkeleton = () => {
    const classes = useCreateDesktopStyle()
    return (
        <div className={classes.root}>
            <ToolbarSkeleton />
            <div className={classes.containerData}>
                <Card >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <RequestTableSkeleton />
                        </Grid>
                    </Grid>
                </Card><br/>
                <Card>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Skeleton variant="rect" width={"100%"} height={250} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddressSkeleton/>
                        </Grid>

                    </Grid>

                </Card>
            </div>

        </div>
    )
}

const RequestTableSkeleton = () => {
    const classes = useCreateDesktopStyle();

    return (
        <Grid item xs={12} >
            <Skeleton variant="text" />
            <div className={classes.tableEquipment}>
                <DataGrid
                    headers={EquimpentTablaConfig}
                    isLoading={true}
                />
            </div>
        </Grid>
    )
}

const AddressSkeleton = () => {
    return (
        <Grid container spacing={1}>
            {renderTextBoxSkeleton(12, 100, "100%")}
            {renderTextBoxSkeleton(6, 30, "100%")}
            {renderTextBoxSkeleton(6, 30, "100%")}
            <Grid item xs={12}>
                <Skeleton variant="text" />
            </Grid>
            {renderTextBoxSkeleton(6, 30, "100%")}
            {renderTextBoxSkeleton(6, 30, "100%")}
        </Grid>
    )
}

/**Render textBox Squeleton */
const renderTextBoxSkeleton = (size = 6, height, width) => {
    return (
        <Grid item xs={size}>
            <Skeleton variant="rect" animation="wave" width={width} height={height} />
        </Grid>
    )
}

export default OpenDesktopViewSkeleton
