const ServiceProviderRegisterTableSearchConfig = [
	{
		title: 'Material Code',
		dataSource: 'MATNR',
		sortable: true,
		width: 150
	},
	{
		title: 'Description',
		dataSource: 'ARKTX',
		sortable: true,
		width: 500
	},
	{
		title: 'Quantity Invoice',
		dataSource: 'FKIMG',
		sortable: true,
		width: 250
	},
	{
		title: 'Customer Number',
		dataSource: 'KUNRG',
		width: 250
	},
	{
		title: 'Invoice Date',
		dataSource: 'FKDAT',
		width: 250
	}
]
export default ServiceProviderRegisterTableSearchConfig
