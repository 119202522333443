/**IMport react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**Import components section */
import CreateDesktopView from './components/CreateDesktopView'
import Toaster from '../common/Toaster'

/**Import actions section */
import { loadAllCustomers } from '../../store/customers/customersActions'
import { addSparePartSales } from '../../store/sparePartSales/sparePartSalesAction'

const Create = (props) => {
    const dispatch = useDispatch()
    let history = useHistory();
    /**store local redux */
    const customers = useSelector((state) =>
        state.customers.get('customers')
    )
    console.log("customers en desktop sales",customers)

    const userProfile = useSelector((state) => state.session.get('profile'))
    const isSavingDraft = useSelector((state) => state.sparePartSales.getIn(['create', 'isSavingRequestDraft']))
    /**store local this components */
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });


    useEffect(() => {
        dispatch(loadAllCustomers(null, 1000))
    }, [dispatch])

    const redirectToSpartPart = () =>{
        history.push(`/sparepartsales`)
    }

    const redirectToSpartPartSales = (token) =>{
       history.push(`/sparepartsales/open/${token}`)
    }
    
    /**
     * Function to create spart part
     * @param {*} service 
     * @param {*} position 
     */
    const onCreateSpartPartSalesRequest = (spartPartSaleModel, mapPositionModel, accountCode) => {
        return dispatch(addSparePartSales(spartPartSaleModel, mapPositionModel, accountCode)).then((token) => {
            redirectToSpartPartSales(token)
        })
            .catch(() => {
                setToasterOptions({ open: true, message: "An error was generated while generating the sapare part sales", variant: 'error' })
            });
    }

	const onChangeLocation = (location) => history.push(location)

    return (
        <>
            <CreateDesktopView
                onChangeLocation={onChangeLocation}
                customers={customers}
                onCreateSpartPartSalesRequest={onCreateSpartPartSalesRequest}
                userProfile={userProfile}
                redirectToSpartPart={redirectToSpartPart}
                isSaving={isSavingDraft}
            />

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

export default Create
