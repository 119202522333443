import { fromJS } from 'immutable'
import * as actions from '../spareParts/sparePartsActions'
const initialSparePartState = fromJS({
	isLoaddingSerialHistory: false,
	serialHistory: null,
	isLoaddingAddresses: false,
	serviceAddresses: null,
	isLoadingDashboard: false,
	dashboard: null,
	create: {
		isGettingDraft: false,
		isSavingDraft: false,
		service: null,
		spareParts: [],
		draft: null,
		isSavingRequestDraft: false
	},
	index: {
		isLoading: false,
		isDownloadReport: false,
		isDownloadPlane: false,
		requests: [],
		pagination: {
			pageNumber: 1,
			pageSize: 10,
			total: 0,
			orderBy: 'Folio',
			orderType: 'desc'
		}
	},
	show: {
		isLoading: false,
		open: null,
		isSaving: false,
		carrier: null,
		isGettingTrackingData: false,
		isLoadingFromSAP: false,
		equipmentsSap: null,
		querySparePart: ''
	}
})

/**
 * spare parts requests reducer
 */
const sparePartsReducer = (state = initialSparePartState, action) => {
	switch (action.type) {
		case actions.IS_GETTING_SPAREPARTREQUEST_DRAFT: {
			return state.setIn(['create', 'isGettingDraft'], action.status)
		}
		case actions.SET_SELECTED_SERVICE: {
			let actualRequest = state.get('create').toJS()
			let updatedRequest = {
				...actualRequest,
				service: action.service,
				draft: action.draft,
				isGettingDraft: false
			}
			return state.setIn(['create'], fromJS(updatedRequest))
		}
		case actions.IS_SAVING_DRAFT: {
			return state.setIn(['create', 'isSavingDraft'], action.status)
		}
		case actions.SET_SPAREPARTREQUEST_DRAFT:
			let actualRequest = state.get('create').toJS()
			let updatedRequest = {
				...actualRequest,
				draft: action.draft,
				isSavingDraft: false
			}
			return state.setIn(['create'], fromJS(updatedRequest))
		case actions.IS_SAVING_DRAFT_REQUEST: {
			return state.setIn(['create', 'isSavingRequestDraft'], action.status)
		}
		case actions.REMOVE_SPARE_PART: {
			let actualRequest = state.get('create').toJS()
			let updatedRequest = {
				...actualRequest,
				spareParts: []
			}
			updatedRequest.draft.SpareParts = actualRequest.draft.SpareParts.filter((sp) => sp.Token !== action.sparePart.Token)
			return state.setIn(['create'], fromJS(updatedRequest))
		}
		case actions.IS_LOADING_REQUEST_LIST: {
			return state.setIn(['index', 'isLoading'], action.status)
		}
		case actions.SET_SPAREPARTREQUEST_LIST: {
			let actualIndex = state.get('index').toJS()
			let updatedIndex = {
				...actualIndex,
				isLoading: false,
				requests: action.requests,
				pagination: fromJS({
					pageNumber: action.pagination.pageNumber,
					pageSize: action.pagination.pageSize,
					total: action.pagination.total,
					orderBy: action.pagination.orderBy,
					orderType: action.pagination.orderType
				})
			}
			return state.setIn(['index'], fromJS(updatedIndex))
		}
		case actions.UPDATE_SPARE_PART_REQUEST: {
			if (action.sparePart.IsManual) {
				let actualRequest = state.get('show').toJS()
				let updatedRequest = {
					...actualRequest,
					open: fromJS(action.sparePart),
					isLoading: false
				}
				return state.setIn(['show'], fromJS(updatedRequest))
			} else {
				let actualRequest = state.get('create').toJS()
				let updatedRequest = {
					...actualRequest,
					Address: {},
					Comments: '',
					EmailsContact: '',
					ContactPhone: '',
					ShipTo: ''
				}
				updatedRequest.draft.Address = action.sparePart.Address
				updatedRequest.draft.Comments = action.sparePart.Comments
				updatedRequest.draft.ShipTo = action.sparePart.ShipTo
				updatedRequest.draft.EmailsContact = action.sparePart.EmailsContact
				updatedRequest.draft.ContactPhone = action.sparePart.ContactPhone
				return state.setIn(['create'], fromJS(updatedRequest))
			}
		}
		case actions.SET_SPAREPARTREQUEST_OPEN: {
			let actualRequest = state.get('show').toJS()
			let updatedRequest = {
				...actualRequest,
				open: fromJS(action.sparePart),
				isLoading: false
			}
			return state.setIn(['show'], fromJS(updatedRequest))
		}
		case actions.IS_LOADING_SPARE_PART_REQUEST: {
			return state.setIn(['show', 'isLoading'], action.status)
		}
		case actions.UPDATE_SPARE_PART: {
			return state.updateIn(['show', 'open', 'SpareParts'], (allSpareParts) => {
				let index = allSpareParts.findIndex((item) => {
					return item.get('Token') === action.sparePart.Token
				})
				return allSpareParts.setIn([index], fromJS(action.sparePart))
			})
		}
		case actions.REMOVE_FILES: {
			return state.updateIn(['show', 'open', 'Files'], (files) => files.filter((file) => file.get('name') !== action.fileName))
		}
		case actions.ADD_FILES: {
			let files = fromJS(action.evidences)
			return state.setIn(['show', 'open', 'Files'], files)
		}
		case actions.IS_SAVING_SPARE_PART_REQUEST: {
			return state.setIn(['show', 'isSaving'], action.status)
		}
		case actions.IS_DONWLOAD_REPORT: {
			return state.setIn(['index', 'isDownloadReport'], action.status)
		}
		case actions.IS_DOWNLOAD_PLANE: {
			return state.setIn(['index', 'isDownloadPlane'], action.status)
		}
		case actions.SET_CARRIER_DATA: {
			let actualRequest = state.get('show').toJS()
			let updatedRequest = {
				...actualRequest,
				carrier: fromJS(action.data),
				isLoading: false
			}
			return state.setIn(['show'], fromJS(updatedRequest))
		}
		case actions.GETTING_INFORMATION_TRACKING: {
			return state.setIn(['show', 'isGettingTrackingData'], action.status)
		}
		case actions.IS_LOADDING_HISTORYA_SERIES: {
			return state.merge({
				isLoaddingSerialHistory: action.status
			})
		}
		case actions.SET_HISTORY_SERIAL_NUMBER: {
			return state.set('serialHistory', fromJS(action.data))
		}
		case actions.IS_LOADDING_ADDRESS: {
			return state.merge({
				isLoaddingAddresses: action.status
			})
		}
		case actions.SET_SERVICE_ADDRESSES: {
			return state.set('serviceAddresses', fromJS(action.data))
		}
		case actions.UPDATE_STATUS: {
			return state.updateIn(['show', 'open', 'SpareParts'], (allSpareParts) => {
				let index = allSpareParts.findIndex((item) => {
					return item.get('NumberTracking') === action.trackingNumber
				})
				return allSpareParts.setIn([index, 'Status'], fromJS(action.status))
			})
		}
		case actions.IS_LOADING_DASHBOAR:
			return state.merge({
				isLoadingDashboard: action.status
			})
		case actions.SET_DASHBOAR:
			return state.merge({
				isLoadingDashboard: false,
				dashboard: fromJS(action.dashboard)
			})
		case actions.REMOVE_SPARE_PART_REQUESTS: {
			let updatedRequestCreate = {
				isGettingDraft: false,
				isSavingDraft: false,
				service: null,
				spareParts: [],
				draft: null
			}
			return state.setIn(['create'], fromJS(updatedRequestCreate))
		}
		case actions.UPDATE_EQUIPMENT_MANUAL: {
			let equipment = fromJS(action.equipment)
			return state.updateIn(['show', 'open', 'Equipments'], (allEquipments) =>
				updateEquipment(allEquipments, action.equipment.Token, equipment)
			)
		}
		case actions.IS_LOADNG_FROM_SAP: {
			return state.setIn(['show', 'isLoadingFromSAP'], action.status)
		}
		case actions.SET_SPARE_PARTS_FROM_SAP: {
			let newSparePart = fromJS(action.Equipment)
			return state.setIn(['show', 'equipmentsSap'], newSparePart)
		}
		case actions.SET_QUERY_SAP: {
			return state.setIn(['show', 'querySparePart'], action.query)
		}
		case actions.SET_EXISTENCE_MATERIAL: {
			let existenceList = action.data
			if (action.updateType === 'equipmentsSap') {
				return state.updateIn(['show', 'equipmentsSap', 'SpareParts'], (allSpareParts) =>
					updateEquipmentSAP(allSpareParts, action.code, existenceList)
				)
			} else {
				return state.updateIn(['show', 'open', 'SpareParts'], (allSpareParts) =>
					updateEquipmentSAP(allSpareParts, action.code, existenceList)
				)
			}
		}
		default:
			return state
	}
}

const updateEquipment = (allEquipments, token, equipment) => {
	let indexOfEquipment = allEquipments.findIndex((e) => e.get('Token') === token)
	if (indexOfEquipment >= 0) {
		return allEquipments.setIn([indexOfEquipment], equipment)
	}
	return allEquipments
}

const updateEquipmentSAP = (allSpareParts, code, data) => {
	let indexOfSparePart = allSpareParts.findIndex((e) => e.get('Code') === code)
	if (indexOfSparePart >= 0) {
		let equipment = allSpareParts.get(indexOfSparePart).toJS()
		equipment.Existences = data
		return allSpareParts.setIn([indexOfSparePart], fromJS(equipment))
	}
	return allSpareParts
}
export default sparePartsReducer
