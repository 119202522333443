/**Import react section */
import React from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/** Import components */
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import { Typography } from '@material-ui/core'
import SideBar from '../../common/DashboadSideBar'
import ToolbarSearchBox from '../../common/mobile/ToolbarSearchBox'
import SparePartsCards from './SparePartsCards'
import Pagination from '../../common/Pagination/Pagination'

/**Import resources section */
import { ServiceCardMobileStyle } from '../../../resources/styles/mobile/ServiceCardMobileStyle'

import { IMBERA_TECHNICIAN, SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN } from '../../../resources/RolesEnum'
import { hasAuthorization } from '../../../store/session/sessionActions'
const MobileView = (props) => {
    const classes = ServiceCardMobileStyle()
    const { userProfile, sparePartsIndex, onOpenRequest, pagination, onChangePage, isClosed } = props

    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                user={props.user}
                breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>Part request</Typography>
                    </HeaderBreadCrum>
                }
            />
            <SideBar
                key={'AuthenticatedSideBar'}
                onChangeLocation={props.onChangeLocation}
                selectedButtonIndex={isClosed?4:3}
                userProfile={userProfile}
            />
            <Content key={'AuthenticatedContent'}>
                <div className={classes.root}>
                    <div className={classes.searchBox}>
                        <div className={classes.grow} />
                        <ToolbarSearchBox
                            placeholder="Search part request"
                            searchValue={props.searchValue}
                            onChange={props.onChangeSearchValue}
                            userProfile={userProfile}
                            roles={[IMBERA_TECHNICIAN, SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN ]}
                            isActiveBtnAdd={isClosed ? false : hasAuthorization(userProfile, [IMBERA_TECHNICIAN, SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN ])}
                            isActiveBtnManual={isClosed ? false : hasAuthorization(userProfile, [IMBERA_TECHNICIAN])}
                            isLoading={sparePartsIndex.isLoading}
                            onButtonAddClick={props.onCreateRequest}
                            onButtonAddManualClick={props.onCreateRequestManual}
                        />
                    </div>
                    <div className={classes.container}>
                        <SparePartsCards
                            spareParts={sparePartsIndex.requests}
                            isLoading={sparePartsIndex.isLoading}
                            redirectToDetail={onOpenRequest}
                            isClosed={isClosed}
                        />
                    </div>
                    <div className={classes.pagination}>
                        <div className={classes.grow} />
                        <Pagination count={pagination.total ? pagination.total : 0} page={pagination.pageNumber ? pagination.pageNumber : 0} onChangePage={onChangePage} />
                    </div>
                </div>
            </Content>
        </Layout >
    )
}

export default withImmutablePropsToJS(MobileView)