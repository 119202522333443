/**Import react section */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**Import componnets section */
import SummaryDesktopView from './components/SummaryDesktopView'
import Toaster from '../common/Toaster'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'

/**Import actions section */
import { loadAllServiceProviderWithFilters } from '../../store/serviceproviders/serviceProvidersActions'
import {loadSummaryRequests, savePaymentSummary, getPaymentSummary, 
    setSummaryRequests, setSummaryPayment, downloadReportPaymentSummary,
    downloadReportPaymentSummarySupplier} from '../../store/paymentrequests/paymentRequestActions'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

const PaymentSummary = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const userProfile = useSelector((state) => state.session.get('profile'))

    /**Load all serviceProviders from redux store for filters */
    const serviceProviders = useSelector((state) =>state.serviceProviders.get('serviceProviders'))
    const isLoadingSummary = useSelector((state) => state.paymentRequests.get('isLoadingSummary'))
    const paymentRequests = useSelector((state) =>state.paymentRequests.get('summaryRequests'))
    const isSavingSummary = useSelector((state) => state.paymentRequests.get('isSavingSummary'))
    const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
    const paymentSummary = useSelector((state) =>state.paymentRequests.get('paymentSummary'))

    const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, summaryModel: null })

    const [toaster, setToaster] = useState({
        open: false,
        variant: null,
        message: null
    })

    /**
     * Load dasborad list spare parts
     */
	useEffect(() => {
        dispatch(setSummaryRequests([]))
        dispatch(setSummaryPayment(null))
		dispatch(loadAllServiceProviderWithFilters(null, 1000))
	}, [dispatch])

    const onSearchPayments = (modelsummary) => {
        dispatch(setSummaryRequests([]))
        dispatch(setSummaryPayment(null))
        dispatch(loadSummaryRequests(modelsummary))
    }


    /**Open/close dialog confirm dialog user*/
    const onSetPasswordDialogConfiguration = (summaryModel) => {
        setPasswordDialogConfiguration({ opened: !passwordDialogConfiguration.opened, summaryModel: summaryModel });
    };

        /**
	 * Send to validate request by provider
	 */
    const onSaveSummaryPayment = () => {
        return dispatch(savePaymentSummary(passwordDialogConfiguration.summaryModel))
            .then(() => {
                onSetPasswordDialogConfiguration()
                setToaster({ open: true, message: "Payment request send success", variant: 'success' })
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to send payment request', variant: 'warning' })
            });
    };

    /**
     * Function to get payment request
     * @param {*} checkFolio 
     */
    const onGetPaymentRequest = (checkFolio) => {
        dispatch(setSummaryRequests([]))
        dispatch(setSummaryPayment(null))
        return dispatch(getPaymentSummary(checkFolio))
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to consult materials by serie from SAP', variant: 'warning' })
            })
    }

        /**
     * Function to donwload report by payment summary
     */
    const onDonwloadReportPayentSummary = (checkFolio) =>{
		return dispatch(downloadReportPaymentSummary(checkFolio))
			.catch(() => {
                setToaster({ open: true, message: 'An error to download report by payment summary', variant: 'error' })
			})
    }

    const onDownloadPaymentRequestsReport = (summaryModel) =>{
        return dispatch(downloadReportPaymentSummarySupplier(summaryModel, paymentRequests))
        .catch(() => {
            setToaster({ open: true, message: 'An error to download report by payment summary', variant: 'error' })
        })
    }
    
    const onChangeLocation = (location) => history.push(location)

    return (
        <>
            <SummaryDesktopView
                userProfile={userProfile}
                onChangeLocation={onChangeLocation}
                onSearchPayments={onSearchPayments}
                serviceProviders={serviceProviders}
                isLoadingSummary={isLoadingSummary}
                paymentRequests={paymentRequests}
                onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                paymentSummary={paymentSummary}
                onGetPaymentRequest={onGetPaymentRequest}
                isSavingSummary={isSavingSummary}
                onDonwloadReportSummary={onDonwloadReportPayentSummary}
                onDownloadPaymentRequestsReport={onDownloadPaymentRequestsReport}
            />

            {
                passwordDialogConfiguration.opened &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={onSaveSummaryPayment}
                    isSaving={isValidatingPassword || isSavingSummary}
                    onClose={onSetPasswordDialogConfiguration}
                    message1={`Sure you want to generate the summary of expenses with these payment requests. Are you sure to proceed  ${userProfile ? userProfile.get("name") : ''}?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            <Toaster
                toasterOptions={toaster}
                onClose={() => setToaster({ open: false })}
            />
        </>
    )
}

export default PaymentSummary
