import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = {
    palette: {
        primary: {
            main: '#10054D',
            light: '#473F74',
        },
        secondary: {
            main: '#2BF6FE',
            contrastText: '#FFFFFF'
        },
        gray: '#CACACA',
        serviceStatus: {
            newServiceMain: '#7F82DD',
            newServiceDark: '#5357DC',
            assignedMain: '#64C4FC',
            assignedDark: '#30B1FC',
            suspendedMain: '#FCA945',
            suspendedDark: '#FF8B00',
            waitingSparePartsMain: '#FCCB56',
            waitingSparePartsDark: '#F7B924',
            completedServiceDark: '#3AC47D',
            completedServiceMain: '#3EDC8B',
            replacedServiceDark: '#20B9BF',
            replacedServiceMain: '#2BF6FE',
            cancelServiceDark: '#D92550',
            cancelServiceMain: '#Ef7E7E',
        },
        colors: {
            red: "#D92550",
            blue: "#30B1FC",
            blueLight: "#E0F3FF",
            green: "#3AC47D",
            greenDark: "#2A8154",
            waiting: "#FDDD0E",
            purple: "#5357DC",
            purpleDark: "#444054",
            purpleRose: "#83588A",
            orange: "#FF8B00",
            gray: "#DCDCDC",
            grayLayout: "#F5F5F5",
            primaryDisabled: "#473F74",
            primarySelected: "#080327",
            greenDisabled: "#a6e0c2",
        },
        text: {
            primary: "#000000",
            secondary: "#6C757D",
        }
    },
    shape: {
        borderRadius: 2
    },
    overrides: {
        MuiPaginationItem: {
            outlinedSecondary: {
                color: 'white'
            },
        }
    },
    views: {
        maxWidth: '1200px'
    }
}

export default createMuiTheme(defaultTheme)
