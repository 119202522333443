/**
 * Define the create services styles
 * 
 */
import { makeStyles } from '@material-ui/styles'

/**
 * 
 */
export const createServicesStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        maxHeight: `calc(100vh - ${85}px)`,
        overflowY: 'auto',
        "& .MuiFormLabel-root": {
            color: theme.palette.text.primary,
        },
    },
    container: {
        padding: "5px",
    },
    containerMobile: {
        paddingBottom: `0px !important`,
        minHeight: `calc(100vh - ${387}px)`,
        maxHeight: `calc(100vh - ${487}px)`,
        overflowY: 'hidden'
    },
    contentMap: {
        padding: 0,
    },
    avatarColor: {
        color: "#6C757D",
        backgroundColor: "#EEEEEE"
    },
    grow: {
        flexGrow: 1,
    },
    headerContainer: {
        display: "flex",
        marginTop: "5px",
        marginBottom: "5px",
    },
    textHeader: {
        marginLeft: "10px",
        marginTop: "10px"
    },
    buttonNext: {
        margin: theme.spacing(1),
    },
    buttonAdd: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px"
    },
    locationLogo: {
        width: "100%",
        height: "40%",
    },
    table: {
        overflowY: 'auto'
    },
    tableEquipment: {
        minHeight: "160px",
        overflowY: "auto"
    },
    iconWarranty: {
        width: "1.6rem",
        height: "1.6rem",
        cursor: "pointer",
    },
    faultWarrantyIcon: {
        width: "2.1rem",
        height: "2.1rem",
        cursor: "pointer",
    },
    fabStyle: {
        marginRight: theme.spacing(0.5),
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: "#bdbdbd",
    },
    containerFailureButtons: {
        display: 'flex',
        direction: "row"
    },
    faultWarrantyIconSelected: {
        marginRight: theme.spacing(0.5),
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '0.9rem',
        fontWeight: 'bold',
    },
    textColor: {
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        marginLeft: "7px"
    },
    updateButton: {
        display: "flex",
        justifyContent: "flex-end",
    },
    warrantyIconRed: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.red}80`,
    },
    warrantyIconGreen: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.green}26`,
    },
    warrantyIconOrange: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.orange}26`,
    },
    ticketServicesChip: {
        backgroundColor: `${theme.palette.colors.purple}!important`,
        color: 'white !important',
        with: "100% !important",
        display: "flex !important",
        justifyContent: "left !important",
        border: `1px solid ${theme.palette.secondary.main} !important`,
    },
    amountIconSelected: {
        marginRight: theme.spacing(0.5),
    },
    amountIcon: {
        width: '45px',
        height: 'auto',
    },
    rootFiles: {
        padding: "12px",
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: "center",
        overflow: 'hidden',
    },
    gridListFile: {
        width: 1000,
        transform: 'translateZ(0)',
    },
    iconImage: {
        color: `${theme.palette.secondary.contrastText}!important`,
    },
    gridTitle: {
        marginBottom: "15px"
    },
    gridListFileMovile: {
        width: 300,
        transform: 'translateZ(0)',
    },
    helperText: {
        color: theme.palette.colors.purpleDark,
        fontSize: '0.9rem',
    },
}))