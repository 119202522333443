/**Import react section */
import React from 'react'
/**Import material Ui section */
import { Box} from '@material-ui/core'
/** Import styles */
import { regionsStyles } from '../../../resources/styles/RegionsStyles'

const TotalServicesByRegion = (props) => {
    const classes = regionsStyles({color: props.color});
    const {total, description } = props;
    return (
        <div className={classes.contentTotal}>
            <Box borderRadius="50%" className={classes.boxCircle} />
            <span className={classes.description}>{`${total} ${description}`}</span><br />
        </div>
    )
}

export default TotalServicesByRegion
