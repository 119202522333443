import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUser } from 'redux-oidc'
import { store } from './views/RootView'
import { useHistory } from 'react-router-dom'
/** Import views */
import AnonymousView from './views/home/AnonymousView'
import AuthenticatedView from './views/home/AuthenticatedView'
import UnauthorizedView from './views/errors/UnauthorizedView'
import ExecutingLogout from './views/home/ExecutingLogout'

import { loadUserProfile } from './store/session/sessionActions'
import { getUserManager } from './store/helpers/UserManager'
import { determinateUrlToRedirectUserProfile } from './store/helpers/UserHelper'

/**
 * App component
 */
const App = () => {
	let userManager = getUserManager()
	const dispatch = useDispatch()

	let history = useHistory()
	const isLoadingUser = useSelector((state) => state.oidc.isLoadingUser)
	const oidcUser = useSelector((state) => state.oidc.user)
	const isExecutingLogout = useSelector((state) => state.session.get('isExecutingLogout'))
	const isGettingProfile = useSelector((state) => state.session.get('isGettingProfile'))
	const userProfile = useSelector((state) => state.session.get('profile'))

	/**
	 * Load the user profile
	 */
	useEffect(() => {
		loadUser(store, userManager)
			.then(() => {
				return userManager.getUser()
			})
			.then((user) => {
				if (user) {
					return dispatch(loadUserProfile())
						.then((fullUserProfile) => {
							if (!fullUserProfile || (fullUserProfile)) {
								let url = determinateUrlToRedirectUserProfile(fullUserProfile)
								if(url){
									history.push(`/${url}`)
								}
							}
						})
				}
			})
			.catch((ex) => {
				console.error('Error loading the user profile', ex)
			})
	}, [dispatch, userManager, history])



	/**
	 * Determine if the user is authenticated
	 */
	const isAuthenticated = () => {
		if (!(isLoadingUser === false && oidcUser)) {
			return false
		}
		return true
	}

	/**
	 * Determine is a user is authorized
	 */
	const isAuthorized = () => {
		if (!userProfile || userProfile.get('Account') == null)
			return false
		// Requiered Terms Accepted by provider
		if (userProfile && (userProfile.getIn(['Account', 'RequieredTermsAccepted'])))
			return true
		if (!userProfile || userProfile.get('roles').size === 0)
			return false
		return true
	}


	if (isExecutingLogout) return <ExecutingLogout />

	if (!isAuthenticated()) {
		return <AnonymousView />
	} else {
		if (isGettingProfile)
			return <span>Loading profile...</span>
		else if (isAuthorized())
			return <AuthenticatedView />
		else
			return <UnauthorizedView />
	}
}

export default App
