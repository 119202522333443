/**Import react section */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**Import components section */
import DesktopView from './components/DesktopView'

/**Import actions section */
import {
    loadServices,
} from '../../store/regions/regionsActions'
import {
    getServicesByRegions,
} from '../../store/regions/regionsSelector'

import { loadInternalUsers } from '../../store/users/UsersActions'

const Index = () => {
    const dispatch = useDispatch()
    let history = useHistory()
    const allServicesByRegions = useSelector(getServicesByRegions)
    const isLoading = useSelector((state) => state.regions.get('isLoading'))
	const userProfile = useSelector((state) => state.session.get('profile'))
    
	/**
	 * Load services list
	 */
    useEffect(() => {
        dispatch(loadServices("open"))
    }, [dispatch])

    useEffect(() => {
        dispatch(loadInternalUsers())
    }, [dispatch])

    const onChangeLocation = (location) => history.push(location)

    return (
        <DesktopView
            allServicesByRegions={allServicesByRegions}
            isLoading={isLoading}
            onChangeLocation={onChangeLocation}
            userProfile={userProfile}
        />
    )
}

export default Index
