import React from 'react'
import propTypes from 'prop-types'
import { oneColumnStyles as useStyles } from '../../resources/styles/LayoutStyles'

import { Button } from '@material-ui/core'
import Header from '../common/ApplicationHeader'
import logo from '../../resources/images/isplogo.png'

/**
 * A layout with one column and a header
 */
const AnonymousLayout = (props) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Header
					leftToolbar={
						<div>
							<img
								src={logo}
								className={classes.appLogo}
								alt='isp-logo'
							/>
						</div>
					}
					rightToolbar={
						<Button color='secondary' variant='outlined' onClick={props.onLoginClick}>
							Login
						</Button>
					}
				/>
			</div>
			<div className={classes.content}>{props.children}</div>
		</div>
	)
}
AnonymousLayout.propTypes = {
	onLoginClick: propTypes.func,
}
AnonymousLayout.defaultProps = {
	onLoginClick: () => console.warn('No [onLogin] callback defined'),
}
export default AnonymousLayout