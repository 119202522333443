/**Import react section */
import React, { useState } from 'react'
/**Import common componets */
import ButtonConfirm from './../../../common/Buttons/ButtonConfirm'
import AlertComponent from '../../../common/AlertComponent'

/**Import MaterialUi section */
import { Grid } from '@material-ui/core'
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'

const DeleteService = (props) => {
    const { onSetPasswordDialogConfiguration } = props;
    const classes = createServicesStyle();
    const [deleteModel] = useState({status: props.status});

    const openModalForDeleteService = () => {
        if (onSetPasswordDialogConfiguration)
            onSetPasswordDialogConfiguration(deleteModel)
    }

    return (
        <div>
            <Grid item xs={12}>
                {
                    <AlertComponent
                        title={"Delete service"}
                        message={"Sure you want to delete the service, once deleted the changes cannot be reversed"}
                        severityType={"warning"}
                    />
                }<br/>
            </Grid>
            <Grid item xs={12} className={classes.updateButton}>
                <ButtonConfirm
                    title="CONFIRM"
                    onSavedClick={openModalForDeleteService}
                />
            </Grid>
        </div>
    )
}

export default DeleteService
