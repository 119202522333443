export const dataGridMovilConfig = [
	{
		title: 'Spare Part',
		dataSource: 'Code',
		sortable: false,
		onRenderProperty: (item) => {
			return `${item.Code} | ${item.Description}`
		}
    },
]
export default dataGridMovilConfig