import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import classNames from 'classnames'

/** Import MaterialUI components */
import { Typography } from '@material-ui/core'

import Pagination from '../components/SliderPagination'

/**Import images */
import { desktopUseStyles as useStyles } from '../../../resources/styles/AnonymousStyles'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const AnonymousDesktopView = () => {
	const classes = useStyles()
	const [page, setPage] = useState(0)

	return (
		<div className={classes.root}>
			<AutoPlaySwipeableViews
				index={page}
				resistance={true}
				interval={5000}
				onChangeIndex={(index) => setPage(index)}
			>
				<div
					className={classNames(
						classes.slideContent,
						classes.slide01
					)}
				>
					<div className={classes.slideMessage}></div>
				</div>
				<div
					className={classNames(
						classes.slideContent,
						classes.slide02
					)}
				>
					<div className={classes.slideMessage}>
						<Typography variant='h3' gutterBottom color='secondary'>
							Welcome to Imbera's comprehensive self-service
							portal.
						</Typography>
						<Typography variant='subtitle1' gutterBottom>
							Here you can access all the tools you need for your
							Imbera products, from opening a warranty service
							ticket to researching parts.
						</Typography>
					</div>
				</div>
			</AutoPlaySwipeableViews>
			<div className={classes.pagination}>
				<Pagination
					count={2}
					page={page + 1}
					onPageButtonClick={(sender, index) => setPage(index - 1)}
				/>
			</div>
		</div>
	)
}
export default AnonymousDesktopView
