const RegisterTableConfig = [
	{
		title: 'Creation Date',
		dataSource: 'CreationDate',
		sortable: true,
		onRenderProperty: (item) => {
			return item.CreationDate
		}
	},
	{
		title: 'Document type',
		dataSource: 'TypeDescription',
	},
	{
		title: 'File name',
		dataSource: 'FileName',
	},
	{
		title: 'Comments',
		dataSource: 'Comments',
	},
	{
		title: 'Status',
		dataSource: 'Status',
		onRenderProperty: (item) => {
			return getStatusDescription(item.Status)
		}
	},
	{
		title: 'Options',
		dataSource: 'Options',
	},
]

export const getStatusDescription = (statusId) => {
	switch (statusId) {
		case 3:
			return 'Accepted'
		case 4:
			return "Rejected"
		default:
			return ''
	}
}

export default RegisterTableConfig
