import { createSelector } from 'reselect'
import { toSafeObject, toSafeArray } from '../../store/helpers/StateHelper'
import { servicesEquipmentsSelector } from '../services/servicesSelectors'

/**
 * Get the spare part request opened
 */
export const sparePartIndexSelector = createSelector(
	(state) => state.spareParts.get('index'),
	(requestMap) => {
		return toSafeObject(requestMap)
	}
)

/**
 * Selector to get the selected service when creating a spare part request
 */
export const selectedServiceSelector = createSelector(
	(state) => state.spareParts.get('create').get('service'),
	(selectedService) => {
		return toSafeObject(selectedService)
	}
)

/**
 * Selector to get the equipments in the selected service when creating the spare part request
 */
export const equipmentsInServiceSelector = createSelector(
	selectedServiceSelector,
	servicesEquipmentsSelector,
	(selectedService, allEquipments) => {
		if (!selectedService) return []
		else {
			return allEquipments.filter((srv) => {
				return srv.Ticket === selectedService.Ticket
			})
		}
	}
)

/**
 * Get the spare part request opened
 */
export const sparePartCreateSelector = createSelector(
	(state) => state.spareParts.get('create'),
	equipmentsInServiceSelector,
	(createRequestMap, equipments) => {

		let createRequest = toSafeObject(createRequestMap)

		// Define all the equipments included in the create view
		// from the list of equipment is the selected service.
		// This allows to show a list of equipments when the draft is null
		createRequest.equipments = equipments.map((equip) => {
			equip.spareParts = createRequest.draft
				? createRequest.draft.SpareParts.filter(sp => sp.Equipment.Serie === equip.Serie)
				: []
			return equip
		})
		return createRequest
	}
)

/**
 * Get the spare part request opened
 */
export const sparePartShowSelector = createSelector(
	(state) => state.spareParts.get('show'),
	(showRequestMap) => {
		let showRequest = toSafeObject(showRequestMap)
		// Define all the equipments included in the create view
		if (showRequest.open) {
			showRequest.equipments = showRequest.open.Equipments.map((equip) => {
				equip.spareParts = showRequest.open
					? showRequest.open.SpareParts.filter(sp => sp.Equipment.Serie === equip.Serie)
					: []
				return equip
			})
		}

		return showRequest
	}
)

/**
 * Action to serch by multitple query to parts
 */
export const sparePartsSAPSelector = createSelector(
	(state) => state.spareParts.getIn(['show', 'equipmentsSap', 'SpareParts']),
	(state) => state.spareParts.getIn(['show', 'querySparePart']),
	(sparePartsSAP, query) => {
		const getFilteredSpareParts = (spareParts) => {
			if (query && sparePartsSAP) {
				var listSearch = query.split("*");
				var filtered = listSearch.filter(v=>v!=='' && v!==' ');
				let serchRegexp = '';
				filtered.map((querySearch) => {
					return serchRegexp +=`(?=.*${querySearch.trim().toLowerCase()})`
				});

				// Search with match regexp 
				return spareParts.filter((sparePart) => {
					return (
						sparePart.Code.trim().match(serchRegexp)
						|| (sparePart.Description && sparePart.Description.trim().toLowerCase().match(serchRegexp))
						|| (sparePart.DescriptionMat && sparePart.DescriptionMat.trim().toLowerCase().match(serchRegexp))
						|| (sparePart.Material && sparePart.Material.trim().match(serchRegexp))
					)
				});
			} else 
				return spareParts
		}
		let result = sparePartsSAP ? toSafeArray(sparePartsSAP): []
		result = getFilteredSpareParts(result)
		return result
	}
)