/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import styles section */
import { createPaymentStyle} from '../resources/CreatePaymentStyles'

/**Import Material Ui section */
import {Typography} from '@material-ui/core'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import Toolbar from '../../common/Toolbar'

import {
	Layout,
	Content,
	Header,
	HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'

/** Components */
import PaymentHeaderForm from './PaymentHeaderForm'
import { serviceOptions } from '../../../store/helpers/FilterHelper'
import iconFailureDoor from '../../../resources/images/failureWarranty/door.svg';

/**
 * View create desktop 
 */
const CreateDesktopView = (props) => {
	const classes = createPaymentStyle()

	const {
		createPayment,
		redirectToHomeCancelPayment,
		closedServices
	} = props
	const { isSavingPaymentRequest } = props

	const [paymentModel, setPaymentModel] = useState({Comments: "", InvoiceFolio:""})
	let servicesOptions = serviceOptions(closedServices)

	/**On change properties for the payment request */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setPaymentModel({
			...paymentModel,
			[name]: value
		})
	}

	/** Invoke when select property change*/
	const onSelectChange = (name, valueProperty) => {
		setPaymentModel({
			...paymentModel,
			[name]: valueProperty
		})
	}
	
	/**Create payment request in the back end */
	const addPaymentRequestOnClick = () => {
		if (createPayment) {
			createPayment(paymentModel)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>
							Payment request / Create
						</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={6}
				userProfile={props.userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar
						title='CREATE PAYMENT REQUEST'
						icon={iconFailureDoor}
					/>
					<div className={classes.container}>
						<PaymentHeaderForm
                            userProfile={props.userProfile}
							onPropertyChange={onPropertyChange}
							isSavingPaymentRequest={isSavingPaymentRequest}
							paymentModel={paymentModel}
							onSelectChange={onSelectChange}
							redirectToHomeCancelPayment={redirectToHomeCancelPayment}
							addPaymentRequestOnClick={addPaymentRequestOnClick}
							closedServices={servicesOptions}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}

CreateDesktopView.propTypes = {
	/**List of service providers */
	closedServices: propTypes.array.isRequired,
	/**Function invoke callback for create a payment */
	createPayment: propTypes.func.isRequired,
	/**Redirects to the payment view when you don't want to create a new payment */
    redirectToHomeCancelPayment: propTypes.func.isRequired,
    isSavingPaymentRequest: propTypes.bool.isRequired,
}
CreateDesktopView.defaultProps = {
	isSavingPaymentRequest: false,
	closedServices: [],
	createPayment: () => console.warn('Callback [createPayment] no defined'),
	redirectToHomeCancelPayment: () =>
		console.warn('Callback [redirectToHomeCancelPayment] no defined')
}

export default withImmutablePropsToJS(CreateDesktopView)
