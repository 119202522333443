/**Import react section */
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { sparePartSaleShowSelector } from '../../store/sparePartSales/sparePartSalesSelectors'
/**Import componenst secytion */
import OpenDesktopView from './components/OpenDesktopView'
import Toaster from '../common/Toaster'
import PanelImportSparePart from './components/PanelImportSparePart'
import Edit from './Edit'
import EditSpareCustomer from './EditSpareCustomer'

/**Import actions section */
import {
	getSparePartSales,
	addSpartPartInRequest,
	importSparePartFromBackend,
	addFilesSparePartSales,
	removeFilesInSparePartSales,
	removeSparePartDetail,
	updateSparePartSales,
	updateSpareaPartDetail,
	loadSparePartByCustomer,
	updateSparePartsPrices,
	checkShipping,
	addPartialityInRequest
} from '../../store/sparePartSales/sparePartSalesAction'

import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { loadInternalUsers } from '../../store/users/UsersActions'
import { SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN } from '../../resources/RolesEnum'
import { getEmailInternalUsersByRole } from '../../store/helpers/UserHelper'
import { toSafeObject } from '../../store/helpers/StateHelper'

const OpenManual = (props) => {
	/**Section hooks */
	let match = useRouteMatch()
	const dispatch = useDispatch()
	const token = match.params.token
	const history = useHistory()
	/**store from redux store */
	const isLoading = useSelector((state) => state.sparePartSales.getIn(['show', 'isLoading']))
	const userProfile = useSelector((state) => state.session.get('profile'))
	const isSaving = useSelector((state) => state.sparePartSales.getIn(['show', 'isSaving']))
	const isGetStock = useSelector((state) => state.spareParts.getIn(['show', 'isGetStock']))
	const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
	const sparePartsOptions = useSelector((state) => state.sparePartSales.get('sparePartSelector'))
	const isSavingDraft = useSelector((state) => state.sparePartSales.getIn(['create', 'isSavingDraft']))
	const isGettingTrackingData = useSelector((state) => state.sparePartSales.getIn(['show', 'isGettingTrackingData']))
	const carrierData = useSelector((state) => state.sparePartSales.getIn(['show', 'carrier']))
	let emailsInternalUsers = getEmailInternalUsersByRole(
		[SPARE_PARTS_IMBERA_MX, SPARE_PARTS_IMBERA_USA, PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN],
		internalUsers
	)

	/**Store local this component */
	const showSpareRequestSale = useSelector(sparePartSaleShowSelector)
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [addLateralPanelConfig, setAddLateralPanelConfig] = useState({
		opened: false
	})

	const [identifierSparePartDelete, setIdentifierSparePartDelete] = useState('')
	const [lateralPanelStatusConfig, setLateralPanelStatusConfig] = useState({ opened: false, spartPart: null })
	const [lateralPanelImportFile, setLateralPanelImportFile] = useState({ opened: false })
	const [editLateralPanelConfig, setEditLateralPanelConfig] = useState({ opened: false, part: null })
	const [lateralPanelCustomerConfig, setLateralPanelCustomerConfig] = useState({ opened: false, spartPart: null })

	/**Get spare part request from back end */
	useEffect(() => {
		dispatch(getSparePartSales(token)).then((result) => {
			let custom = result.Customer
			dispatch(loadSparePartByCustomer(custom.CodeSap))
		})
	}, [dispatch, token])

	useEffect(() => {
		dispatch(loadInternalUsers())
	}, [dispatch])

	/**Open lateral panel for get equipment from SAP */
	const openLateralPanelSeries = () => {
		setAddLateralPanelConfig({ opened: !addLateralPanelConfig.opened })
	}

	/**
	 * Function to create spart part
	 * @param {*} service
	 * @param {*} position
	 */
	const onAddSparePart = (sparePartSale, sparePart, parentToken) => {
		return dispatch(addSpartPartInRequest(sparePartSale, sparePart, parentToken))
			.then(() => {
				setToaster({ open: true, message: 'Spare part adding successfully in request', variant: 'success' })
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error was generated while generating the add apare part',
					variant: 'warning'
				})
			})
	}

	const onAddPartiality = (token, code) => {
		return dispatch(addPartialityInRequest(token, code))
			.then(() => {
				setToaster({ open: true, message: 'Spare part partiality adding successfully in request', variant: 'success' })
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error.toString()) ? error.toString() : 'An error was generated while generating the add apare part partiality',
					variant: 'warning'
				})
			})
	}

	/**
	 * Check shipping by tracking number
	 * @param {*} sparePart
	 */
	const onCheckShipping = (carrierCode, trackingNumber, token) => {
		return dispatch(checkShipping(carrierCode, trackingNumber.trim(), token))
			.then((data) => {
				editLateralPanelConfig.part['Status'] = data.status
				let updatedSpare = editLateralPanelConfig.part
				setEditLateralPanelConfig({
					...editLateralPanelConfig,
					part: updatedSpare
				})
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to close spare part sale',
					variant: 'warning'
				})
			})
	}

	/**
	 * function to on update spare part in equipment
	 * @param {*} sparePart
	 */
	const onUpdateSpartPart = (sparePart, propertyToUpdate) => {
		return dispatch(updateSpareaPartDetail(token, sparePart, emailsInternalUsers, propertyToUpdate)).catch((error) => {
			setToaster({ open: true, message: 'An error occurred while updating your spare part', variant: 'error' })
		})
	}

	const onRemoveSparePartDetail = (sparePart) => {
		setIdentifierSparePartDelete(sparePart.Token)
		return dispatch(removeSparePartDetail(sparePart, showSpareRequestSale ? showSpareRequestSale.open.Token : '', true))
			.then(() => {
				dispatch(getSparePartSales(token))
				setIdentifierSparePartDelete('')
				setToaster({ open: true, message: 'Spare part remove successfully', variant: 'success' })
			})
			.catch((error) => {
				setIdentifierSparePartDelete('')
				setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to delete spare part', variant: 'error' })
			})
	}

	const onSaveSparePartRequest = (sparePartRequestsForm) => {
		return dispatch(updateSparePartSales(showSpareRequestSale ? showSpareRequestSale.open.Token : '', sparePartRequestsForm))
			.then(() => {
				setToaster({ open: true, message: 'Spare part request sales update successfully', variant: 'success' })
			})
			.catch((error) => {})
	}

	const onUpdateSparePartsPrices = (token) => {
		return dispatch(updateSparePartsPrices(token))
			.then(() => {
				setToaster({ open: true, message: 'Spare part request sales update successfully', variant: 'success' })
			})
			.catch((error) => {})
	}

	const onChangeLocation = (location) => history.push(location)

	/**Open lateral panel for change status spare part requests*/
	const openLateralPanelChangeStatus = (spartPart) => {
		setLateralPanelStatusConfig({ opened: !lateralPanelStatusConfig.opened, spartPart: spartPart })
	}

	/**Open lateral panel for import file spare parts */
	const openImportSparePart = () => {
		setLateralPanelImportFile({ opened: !lateralPanelImportFile.opened })
	}

	const onEditLateralPanelConfig = (part) => {
		setEditLateralPanelConfig({ opened: !editLateralPanelConfig.opened, part: part })
	}

	/**Open lateral panel for show send by provider */
	const openLateralPanelSendCustomer = (sparePartSale) => {
		setLateralPanelCustomerConfig({ opened: !lateralPanelCustomerConfig.opened, sparePartSale: sparePartSale })
	}

	/**
	 * On import file spare parte
	 */
	const onImportSpartPartList = (files) => {
		let sparePartFolio
		let sparePartOpen
		let open
		sparePartFolio = showSpareRequestSale
		if (sparePartFolio != null) {
			sparePartOpen = sparePartFolio.open
			if (sparePartOpen != null) open = sparePartOpen
			else open = null
		}
		dispatch(importSparePartFromBackend(token, files, open))
			.then(() => {
				openImportSparePart()
				return Promise.resolve()
			})
			.catch((error) => {
				setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to import references files', variant: 'error' })
			})
	}

	const onAddFilesInSparePartSales = (files, sparePartSale) => {
		return dispatch(addFilesSparePartSales(sparePartSale.Token, files)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while adding file', variant: 'warning' })
		})
	}

	const onRemoveFilesInSparePartSales = (fileName, sparePartSale) => {
		return dispatch(removeFilesInSparePartSales(sparePartSale.Token, fileName)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while remove file', variant: 'warning' })
		})
	}

	return (
		<>
			{
				<OpenDesktopView
					sparePartSales={showSpareRequestSale}
					sparePartsOptions={sparePartsOptions}
					isLoading={isLoading}
					onButtonSerieClick={openLateralPanelSeries}
					onAddSparePart={onAddSparePart}
					onAddPartiality={onAddPartiality}
					isSaving={isSaving}
					onSaveSparePartRequest={onSaveSparePartRequest}
					onRemoveSparePart={onRemoveSparePartDetail}
					identifierSparePartDelete={identifierSparePartDelete}
					userProfile={userProfile}
					onChangeLocation={onChangeLocation}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					isGetStock={isGetStock}
					openImportSparePart={openImportSparePart}
					onEditLateralPanelConfig={onEditLateralPanelConfig}
					openLateralPanelSendCustomer={openLateralPanelSendCustomer}
					onAddFilesInSparePartSales={onAddFilesInSparePartSales}
					onRemoveFilesInSparePartSales={onRemoveFilesInSparePartSales}
					onUpdateSpartPart={onUpdateSpartPart}
					onUpdateSparePartsPrices={onUpdateSparePartsPrices}
					isSavingDraft={isSavingDraft}
				/>
			}

			{lateralPanelImportFile.opened && (
				<PanelImportSparePart onCloseButtonClick={openImportSparePart} onImportSpartPartList={onImportSpartPartList} isSaving={isSaving} />
			)}

			<Edit
				onCloseButtonClick={onEditLateralPanelConfig}
				part={editLateralPanelConfig.part}
				onUpdateSpartPart={onUpdateSpartPart}
				userProfile={userProfile}
				status={showSpareRequestSale.open && showSpareRequestSale.open.Status}
				editLateralPanelConfig={editLateralPanelConfig}
				carrierData={carrierData}
				onCheckShipping={onCheckShipping}
				isGettingTrackingData={isGettingTrackingData}
				lateralPanelStatusConfig={lateralPanelStatusConfig}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
			/>

			<EditSpareCustomer lateralPanelCustomerConfig={lateralPanelCustomerConfig} openLateralPanelSendCustomer={openLateralPanelSendCustomer} />

			<Toaster toasterOptions={toaster} onClose={() => setToaster({ open: false })} />
		</>
	)
}

export default OpenManual
