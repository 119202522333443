/** Import react section **/
import React from 'react'

import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
// import * as Sentry from '@sentry/react'
// import { Integrations } from "@sentry/tracing";

/** Import reducers and helpers */
import rootReducer from '../store/RootReducer'
import { getUserManager } from '../store/helpers/UserManager'

//** Import theme componentes */
import { MuiThemeProvider } from '@material-ui/core/styles'
import defaultTheme from '../resources/themes/default.js'

/** Import styles */
import '../resources/fonts/roboto/font-face.css'
import '../resources/fonts/open-sans-condensed/font-face.css'
import '../resources/styles/main.css'

//const sentryReduxEnhancer = Sentry.createReduxEnhancer()
// Sentry.init({
//     dsn:
//         'https://44aafc972151421eaeb9b803a481e1c4@o298946.ingest.sentry.io/5424665',
//     environment: 'production',
//     normalizeDepth: 10,
//     release: 'isp@1.0.1-alpha',
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0
// })

export let store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk),
        //sentryReduxEnhancer
    )
)

export default (props) => (
	<MuiThemeProvider theme={defaultTheme}>
		<Provider store={store}>
			<OidcProvider store={store} userManager={getUserManager()}>
				{props.children}
			</OidcProvider>
		</Provider>
	</MuiThemeProvider>
)
