import { makeStyles } from '@material-ui/styles'

/**
 * One column view styles
 */
export const ServiceCardMobileStyle = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr'
    },
    containerText: {
        display: "flex",
    },
    titleBold: {
        color: theme.palette.colors.purpleDark,
        fontWeight: "bold",
        marginRight: "5px"
    },
    titleBold2: {
        color: theme.palette.colors.purpleDark,
        fontWeight: "bold",
        marginRight: "5px",
        marginLeft: "5px"
    },
    pagination: {
        gridColumn: 1,
        gridRow: 3,
        display: 'flex',
        alignItems: "flex-end"
    },
    grow: {
        flexGrow: 1,
    },
    container: {
        gridColumn: 1,
        gridRow: 2,
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: `calc(100vh - ${160}px)`,
        padding: "2px"
    },
    searchBox: {
        padding: "5px"
    },
    iconButtonImportantRed:{
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        marginLeft:theme.spacing(0.5)
    },
    iconButtonImportantGreen:{
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        marginLeft:theme.spacing(0.5)
        
    },
    iconButtonImportantYellow:{
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: theme.palette.colors.waiting,
        color: theme.palette.common.white,
        marginLeft:theme.spacing(0.5)
    },
    flex: {
        display: "flex",
    },
    calendarIcon: {
        marginLeft: "1px",
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
    },
    titlePhone:{
        maxWidth: '120px',
        overflow: 'hidden'
    },
    phoneIcon: {
        marginLeft: "1px",
        width: theme.spacing(1.8),
        height: theme.spacing(1.8),
    },
    iconButtonPhone: {
        color: theme.palette.colors.purple,
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        marginBottom: "-5px"
    },
    expirationDateIcon: {
        color: theme.palette.colors.red,
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
    },
    faultWarrantyIcon: {
        width: '18px',
        height: '16px',
        paddingLeft: "4px",
        color: theme.palette.common.white,
    },
    titleNoList: {
        display: "flex",
        justifyContent: "center"
    },
    active:{
        backgroundColor: theme.palette.colors.purple,
        width: 24,
        height: 24,
        marginLeft: 5,
    },
    assigned:{
        backgroundColor: theme.palette.colors.blue,
        width: 24,
        height: 24,
        marginLeft: 5,
    },
    suspended:{
        backgroundColor :theme.palette.colors.orange,
        width: 24,
        height: 24,
        marginLeft: 5,
    },
    waiting:{
        backgroundColor :theme.palette.colors.waiting,
        width: 24,
        height: 24,
        marginLeft: 5,
    },
    completed:{
        backgroundColor :theme.palette.colors.green,
        width: 24,
        height: 24,
        marginLeft: 5,
    },
    replaced:{
        backgroundColor: theme.palette.secondary.main,
        width: 24,
        height: 24,
        marginLeft: 5,
    },
    cancel:{
        backgroundColor :theme.palette.colors.red,
        width: 24,
        height: 24,
        marginLeft: 5,
    },

}))