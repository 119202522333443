import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { calculateRegressionLineSeries } from '../../../store/helpers/ChartHelper'

import ZingChart from 'zingchart-react'
import createChartConfig from './NetworkdaysChart.config'
import { getGroupedNetworkDay } from '../../../store/networkdays/networkdaysActions'

/**
 * Networkday chart report
 */
const NetworkdaysChart = (props) => {
	let getDaysInRangeResult = getCloseDatesFromServices(props.services)
	let chartSeries = getDaysInRangeResult.map((date) => {
		let value = getValueForDate(props.services, date, 'average')
		return [date.unix() * 1000, value]
	})
	chartSeries = chartSeries.filter(x => !isNaN(x[0]))
	let trendDataset = chartSeries.map((item) => {
		return {
			x: item[0] / 1000,
			y: item[1]
		}
	})
	let trendSerie = []
	if (trendDataset.length > 2) {
		trendSerie = calculateRegressionLineSeries(trendDataset).series.map(
			(point) => {
				return [point.x * 1000, point.y]
			}
			)
		}
		
		/**
		 * On node double click
		 */
		const nodeDoubleclick = (event) => {
			let selectedDate = moment(event.key)
			props.onSelectDateOnChart(selectedDate)
		}
		
	const charData = createChartConfig(chartSeries, trendSerie)
	return <ZingChart data={charData} node_doubleclick={nodeDoubleclick} />
}
NetworkdaysChart.propTypes = {
	/**
	 * The services included in the chart
	 */
	services: propTypes.arrayOf(
		propTypes.shape({
			Ticket: propTypes.string.isRequired,
			RepresentativeName: propTypes.string.isRequired,
			Serie: propTypes.string.isRequired,
			RetailerName: propTypes.string.isRequired,
			ServiceProviderName: propTypes.string.isRequired,
			IntiatedDate: propTypes.instanceOf(moment),
			CompletionDate: propTypes.instanceOf(moment)
		})
	).isRequired,
	/**
	 * The initial date to shown in the x-axis chart
	 */
	startDate: propTypes.instanceOf(moment),
	/**
	 * The final date to shown in the x-axis chart
	 */
	endDate: propTypes.instanceOf(moment),
	/**
	 * The interval represented in the chart
	 */
	interval: propTypes.oneOf(['day', 'week', 'month']),
	/**
	 * Callback executed when the user double click on a chart node
	 */
	onSelectDateOnChart: propTypes.func
}
NetworkdaysChart.defaultProps = {
	services: [],
	startDate: moment(),
	endDate: moment(),
	interval: 'day',
	onSelectDateOnChart: () =>
		console.warn('No [onSelectDateOnChart] callback defined')
}
export default NetworkdaysChart

/**
 * Get all the close dates included in a service list
 * @param {*} services
 */
export const getCloseDatesFromServices = (services) => {
	let allDays = services
		.reduce((allDates, closedService) => {
			let includedDate = allDates.find((x) =>
				x.isSame(closedService.CompletionDate)
			)
			if (!includedDate)
				allDates.push(moment(closedService.CompletionDate))
			return allDates
		}, [])
		.sort((a, b) => {
			return a > b ? 1 : -1
		})
	return allDays
}

/**
 * Get the dates contained in a range
 * Note: This method was used to show the chart grouped by week.
 * Delete if this feature is not longer used
 */
export const getDatesInRange = (startDate, endDate) => {
	let allDays = []
	let pivoteDate = moment(startDate)
	while (pivoteDate.isAfter(endDate, 'day') === false) {
		allDays.push(moment(pivoteDate))
		pivoteDate.add(1, 'day')
	}
	return allDays
}

/**
 * Get the value to be graphed in the chart
 * @param {*} date
 * @param {*} service
 */
export const getValueForDate = (services, date, criteria) => {
	let servicesInDate = services.filter((srv) =>
		srv.CompletionDate.isSame(date, 'day')
	)
	return getGroupedNetworkDay(servicesInDate, criteria)
}
