
import { fromJS } from 'immutable';
import * as serviceProvidersAction from './serviceProvidersActions';

let initialState = fromJS({
    activeStep: 0,
    isFowardEnabled: true,
    isBackwardEnabled: false,
    isLoadingProviders: false,
    isSaving: false,
    serviceProviders: [],
    preferentServiceProviders: [],
    serviceProvider: null,
    internalQueryParameters: null,
    termsAndConditions: null,
    providerUsers: [],
    pagination: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        orderBy: "code",
        orderType: "asc",
    },
})

/**
 * Sales Representative's reducer
 */
const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case serviceProvidersAction.IS_LOADING_SERVICE_PROVIDERS:
            return state.merge({
                isLoadingProviders: action.status
            })
        case serviceProvidersAction.SET_SERVICE_PROVIDERS: {
            return state.merge({
                serviceProviders: fromJS(action.providers),
                isLoadingProviders: false
            })
        }
        case serviceProvidersAction.SET_PREFERENT_SERVICE_PROVIDERS: {
            return state.merge({
                preferentServiceProviders: action.preferentProviders
            })
        }
        case serviceProvidersAction.SET_ALL_SERVICE_PROVIDERS: {
            return state.merge({
                serviceProviders: fromJS(action.data.serviceProviders).set('isLoadingProviders', false),
                pagination: fromJS({
                    pageNumber: action.data.pageNumber,
                    pageSize: action.data.pageSize,
                    total: action.data.total,
                    orderBy: action.data.orderBy,
                    orderType: action.data.orderType,
                })
            })
        }
        case serviceProvidersAction.UPDATE_SERVICE_PROVIDER: {
            let providerUpdate = fromJS(action.serviceProvider);
            return state.update('serviceProviders', allServiceProviders => updateServiceProvider(allServiceProviders, providerUpdate));
        }
        case serviceProvidersAction.IS_SAVING:
            return state.merge({
                isSaving: action.status
            })
        case serviceProvidersAction.ADD_SERVICE_PROVIDER: {
            let newServiceProvider = fromJS(action.serviceProvider);
            return state.update('serviceProviders', allServiceProvider => pushServiceProvider(allServiceProvider, newServiceProvider));
        }
        case serviceProvidersAction.SET_SERVICE_PROVIDERS_USERS: {
            return state.merge({
                providerUsers: fromJS(action.users),
                isLoadingProviders: false
            })
        }
        case serviceProvidersAction.SET_SERVICE_PROVIDERS_QUERYPARAMETERS: {
            return state.merge({
                internalQueryParameters: fromJS(action.queryParametes)
            })
        }
        case serviceProvidersAction.ADD_USER_IN_SERVICE_PROVIDER: {
            let newUser = fromJS(action.user);
            return state.update('providerUsers', allUsers => pushUser(allUsers, newUser));
        }
        case serviceProvidersAction.REMOVE_USER_FROM_ACCOUNT: {
            return state.updateIn(['providerUsers'], allInternalUsers => allInternalUsers.filter(user => user.get('Token') !== action.token))
                .set('isSaving', false);
        }
        case serviceProvidersAction.SET_ROLES_IN_PROFILE: {
            let roles = fromJS(action.roles);
            return state.update('providerUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.userName, 'Roles', fromJS(roles)));
        }
        case serviceProvidersAction.UPDATE_LAST_DATE_USER_PROVIDER: {
            let lastDate = action.user.last_authentication_date;
            return state.update('providerUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.user_name, 'LastAuthenticationDate', lastDate));
        }
        case serviceProvidersAction.UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER_PROVIDER: {
            let lastActivation = action.user.ActiveNotifications
            return state.update('providerUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.UserName, 'ActiveNotifications', lastActivation));
        }
        case serviceProvidersAction.UPDATE_SERVICE_PROVIDER_USERS: {
            let userUpdate = fromJS(action.user);
            return state.update('providerUsers', allIntenalUsers => updateUserInServiceProvider(allIntenalUsers, userUpdate));
        }
        case serviceProvidersAction.SET_SERVICE_PROVIDER: {
            let activeStep = state.get('activeStep')
            let isBackwardEnabled = (activeStep > 0) ? false : true
            return state.merge({
                serviceProvider: fromJS(action.user),
                activeStep: !!action.user.activeStep ? action.user.activeStep : activeStep,
                isBackwardEnabled: isBackwardEnabled,
            })
        }
        case serviceProvidersAction.UPDATE_SERVICE_PROVIDER_ACCOUNT: {
            return state.merge({
                serviceProvider: fromJS(action.user),
            })
        }
        case serviceProvidersAction.MOVE_FORWARD_STEP: {
            return state.merge({
                activeStep: action.activeStep,
                isFowardEnabled: action.isFowardEnabled,
                isBackwardEnabled: action.isBackwardEnabled
            });
        }
        case serviceProvidersAction.MOVE_BACKWARD_STEP: {
            return state.merge({
                activeStep: action.activeStep,
                isFowardEnabled: action.isFowardEnabled,
                isBackwardEnabled: action.isBackwardEnabled,
            });
        }
        case serviceProvidersAction.SET_TERMS_AND_CONDITIONS: {
            return state.merge({
                termsAndConditions: fromJS(action.data),
                isLoadingProviders: false
            })
        }
        default:
            return state
    }
}

const updateServiceProvider = (allServicePrfovider, updateProvider) => {
    let indexOfProvider = allServicePrfovider.findIndex(provider => provider.get('Token') === updateProvider.get('Token'));
    if (indexOfProvider >= 0) {
        return allServicePrfovider.setIn([indexOfProvider], updateProvider);
    }
    return allServicePrfovider;
}

const updateUserInServiceProvider = (allUsers, updateUser) => {
    let indexOfProvider = allUsers.findIndex(user => user.get('Token') === updateUser.get('Token'));
    if (indexOfProvider >= 0) {
        return allUsers.setIn([indexOfProvider], updateUser);
    }
    return allUsers;
}


const pushServiceProvider = (allServiceProvider, newServiceProvider) => {
    if (allServiceProvider.some(user => user.get('Code') === newServiceProvider.get('Code'))) {
        return allServiceProvider;
    }
    return allServiceProvider.push(newServiceProvider);
}

const pushUser = (allIntenalUsers, newUser) => {
    if (allIntenalUsers.some(user => user.get('UserName') === newUser.get('UserName'))) {
        return allIntenalUsers;
    }
    return allIntenalUsers.push(newUser);
}

const updateUserProperty = (allIntenalUsers, userName, propertyName, propertyValue) => {
    let indexOfUser = allIntenalUsers.findIndex(user => user.get('UserName') === userName);
    if (indexOfUser >= 0) {
        return allIntenalUsers.setIn([indexOfUser, propertyName], propertyValue);
    }
    return allIntenalUsers;
}

export default usersReducer

