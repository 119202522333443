import React from 'react'
import propTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
/** Material UI */
import { makeStyles } from '@material-ui/core/styles'

/**
 * A react-hook-form validated text field
 */
const ValidatedTextField = (props) => {
    const classes = useDefaultStyles();
    const { errors, name, autoFocus, label, type, value, margin } = props

    const hasError = () => (errors[name] ? true : false)
    const errorMessage = () => hasError() ? errors[name].message : null

    const onChangeHandler = event => {
        if (props.onChange) {
            props.onChange(event);
        }
        else {
            console.warn("onChange callback not found.")
        }
    }

    return (
        <Controller
            as={<TextField
                className={classes.textField}
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: (!errors?.[name]) ? classes.notchedOutlineSuccess : classes.notchedOutline,

                    },
                    inputMode: "text",
                    className: classes.input,
                }}

            />}
            autoFocus={autoFocus}
            fullWidth
            margin={margin}
            name={name}
            variant='outlined'
            label={label}
            size='small'
            type={type}
            error={hasError()}
            helperText={errorMessage()}
            rules={props.rules}
            defaultValue={props.defaultValue}
            control={props.control}
            valueName={value}
            mode={onChangeHandler}
            
        />
    )
}
ValidatedTextField.propTypes = {
    autoFocus: propTypes.bool.isRequired,
    name: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    defaultValue: propTypes.string.isRequired,
    rules: propTypes.object,
    control: propTypes.object.isRequired,
    errors: propTypes.object
}
ValidatedTextField.defaultProps = {
    autoFocus: false,
    hasError: false
}

const useDefaultStyles = makeStyles((theme) => ({
    cssLabel: {
    },
    cssFocused: {
        color: 'black !important'
    },
    cssOutlinedInput: {
        color: 'black !important',
    },
    notchedOutline: {
        borderColor: 'red !important',
        borderRadius: 8
    },
    notchedOutlineSuccess: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8
    },
    input: {
        "&.Mui-disabled": {
            color: "black",
            opacity: 1,
        },
    },

}));
export default ValidatedTextField
