/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
/**Import material UI Section */
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const AlertComponent = (props) => {
    const { title ,message, severityType, variant } = props;
    return (
        <Alert variant={variant} severity={severityType}>
            <AlertTitle><Typography align='justify'>{title}</Typography></AlertTitle>
            {message}
        </Alert>
    )
}

AlertComponent.propTypes = {
    message: propTypes.string.isRequired,
    severityType: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    variant: propTypes.string.isRequired,
}
AlertComponent.defaultProps = {
    message: '',
    severityType: 'success',
    title: '',
    variant:"outlined"
}

export default AlertComponent
