import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import { Card, CardContent, Grid } from '@material-ui/core';
import EquimpentTablaConfig from '../../../services/resources/EquimpentTablaConfig'
/**Import squeletons components section */
import ToolbarSkeleton from './ToolbarSkeleton'
import DataGrid from '../../../common/apsys.datagrid.materialui/DataGrid'

const ShowDesktopViewSkeleton = (props) => {
    const classes = createServicesStyle();
    return (
        <div className={classes.root}>
            <ToolbarSkeleton />
            <Card className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressSkeleton/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EquipmentsListSkeleton/>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}

const AddressSkeleton = () => {
    return (
        <Grid container spacing={1}>
            {renderTextBoxSkeleton(12, 100, "100%")}
            {renderTextBoxSkeleton(6, 30, "100%")}
            {renderTextBoxSkeleton(6, 30, "100%")}
            <Grid item xs={12}>
                <Skeleton variant="text" />
            </Grid>
            {renderTextBoxSkeleton(6, 30, "100%")}
            {renderTextBoxSkeleton(6, 30, "100%")}
        </Grid>
    )
}

const EquipmentsListSkeleton = () => {
    const classes = createServicesStyle();

    return (
        <Grid item xs={12} >
            <Skeleton variant="text" />
            <Grid item xs={12} md={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Skeleton variant="rect" width={"100%"} height={80} />
                        <div>
                            <Skeleton variant="rect" width={"20%"} height={30} />
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <div className={classes.tableEquipment}>
                <DataGrid
                    headers={EquimpentTablaConfig}
                    isLoading={true}
                />
            </div>
        </Grid>
    )
}


/**Render textBox Squeleton */
const renderTextBoxSkeleton = (size = 6, height, width) => {
    return (
        <Grid item xs={size}>
            <Skeleton variant="rect" animation="wave" width={width} height={height} />
        </Grid>
    )
}


export default ShowDesktopViewSkeleton