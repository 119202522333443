/**Import react section */
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMobile } from '../../hooks/useIsMobile'

import { sparePartsSAPSelector } from '../../store/spareParts/sparePartsSelectors'
import { sparePartShowSelector } from '../../store/spareParts/sparePartsSelectors'
/**Import componenst secytion */
import OpenManualDesktopView from './components/OpenManualDesktopView'
import Toaster from '../common/Toaster'
import AddPanel from './components/manual/AddPanel'
import OpenViewMovil from './componentsMobile/OpenViewMovil'

/**Import actions section */
import {
	addSpartPartManual,
	updateSparePartRequest,
	removeSparePart,
	updateEquipment,
	downloadReportByserie,
	removeFilesInSparePartRequest,
	getSparePartRequest,
	SET_SPARE_PARTS_FROM_SAP,
	SET_QUERY_SAP,
	addFilesSparePartResquest
} from '../../store/spareParts/sparePartsActions'
import { loadSparePartByCustomer } from '../../store/sparePartSales/sparePartSalesAction'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { loadInternalUsers } from '../../store/users/UsersActions'

const OpenManual = (props) => {
	const isMobile = useIsMobile(props.isMobile)
	/**Section hooks */
	let match = useRouteMatch()
	const dispatch = useDispatch()
	const token = match.params.token
	const history = useHistory()
	/**store from redux store */
	const isLoading = useSelector((state) => state.spareParts.getIn(['show', 'isLoading']))
	const equipmentsSap = useSelector((state) => state.spareParts.getIn(['show', 'equipmentsSap']))
	const userProfile = useSelector((state) => state.session.get('profile'))
	const querySparePart = useSelector((state) => state.spareParts.get(['show', 'querySparePart']))
	const isDownloadReport = useSelector((state) => state.spareParts.getIn(['index', 'isDownloadReport']))
	const isSaving = useSelector((state) => state.spareParts.getIn(['show', 'isSaving']))
	const sparePartsOptions = useSelector((state) => state.sparePartSales.get('sparePartSelector'))

	/**Store local this component */
	const showSpareRequest = useSelector(sparePartShowSelector)
	const sparePartsSap = useSelector(sparePartsSAPSelector)

	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})

	const [addLateralPanelConfig, setAddLateralPanelConfig] = useState({
		opened: false
	})
	const [identifierSparePartDelete, setIdentifierSparePartDelete] = useState('')
	const [lateralPanelStatusConfig, setLateralPanelStatusConfig] = useState({ opened: false, spartPart: null })

	/**Get spare part request from back end */
	useEffect(() => {
		dispatch(getSparePartRequest(token)).then((result) => {
			let custom = result.Customer
			if (custom && custom.CodeSap) {
				dispatch(loadSparePartByCustomer(custom.CodeSap))
			}
		})
	}, [dispatch, token])

	useEffect(() => {
		dispatch(loadInternalUsers())
	}, [dispatch])

	/**Clean state redux equipments sap */
	useEffect(() => {
		dispatch({
			type: SET_SPARE_PARTS_FROM_SAP,
			Equipment: null
		})
		dispatch({
			type: SET_QUERY_SAP,
			query: ''
		})
	}, [dispatch])

	const onChangeQuerySap = (query) => {
		dispatch({
			type: SET_QUERY_SAP,
			query
		})
	}

	/**Open lateral panel for get equipment from SAP */
	const openLateralPanelSeries = () => {
		setAddLateralPanelConfig({ opened: !addLateralPanelConfig.opened })
	}

	/**
	 * Function to create spart part
	 * @param {*} service
	 * @param {*} position
	 */
	const onAddSparePart = (sparePartRequest, equipment, sparePart) => {
		return dispatch(addSpartPartManual(sparePartRequest, equipment, sparePart))
			.then((token) => {
				setToaster({ open: true, message: 'Spare part adding successfully in request', variant: 'success' })
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error was generated while generating the add spare part',
					variant: 'warning'
				})
			})
	}

	const onUpdateQuantityEquipment = (Equipment) => {
		/**If equipment is null clean query in redux store empty */
		if (Equipment === null) {
			onChangeQuerySap('')
		}
		return dispatch({
			type: SET_SPARE_PARTS_FROM_SAP,
			Equipment
		})
	}

	const onSaveSparePartRequest = (mapPositionModel, sparePartRequestsForm) => {
		return dispatch(updateSparePartRequest(showSpareRequest ? showSpareRequest.open.Token : '', mapPositionModel, sparePartRequestsForm))
			.then(() => {
				// Nothing to code
				setToaster({ open: true, message: 'Spare part request update successfully', variant: 'success' })
			})
			.catch((error) => {})
	}

	const onRemoveSparePart = (sparePart) => {
		setIdentifierSparePartDelete(sparePart.Token)
		return dispatch(removeSparePart(sparePart, showSpareRequest ? showSpareRequest.open.Token : '', true))
			.then(() => {
				setIdentifierSparePartDelete('')
				setToaster({ open: true, message: 'Spare part remove successfully', variant: 'success' })
			})
			.catch((error) => {
				setIdentifierSparePartDelete('')
				setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to delete spare part', variant: 'error' })
			})
	}

	const onChangeLocation = (location) => history.push(location)

	/**Open lateral panel for change status spare part requests*/
	const openLateralPanelChangeStatus = (spartPart) => {
		setLateralPanelStatusConfig({ opened: !lateralPanelStatusConfig.opened, spartPart: spartPart })
	}

	const onUpdateEquipment = (equipmentToken, refrigerationNotCoolFailure, iluminationFailure, doorFailure, comments, faultWarranty) => {
		dispatch(updateEquipment(token, equipmentToken, refrigerationNotCoolFailure, iluminationFailure, doorFailure, comments, faultWarranty)).catch(
			() => {
				console.error('Error to update service')
			}
		)
	}

	/**
	 * Function to donwload report by serie
	 */
	const onDonwloadReportBySerie = () => {
		return dispatch(downloadReportByserie()).catch(() => {
			setToaster({ open: true, message: 'An error to download report by serie', variant: 'error' })
		})
	}

	const onAddFilesInSparePartRequest = (files, sparePart) => {
		return dispatch(addFilesSparePartResquest(sparePart.Token, files)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while adding file', variant: 'warning' })
		})
	}

	const onRemoveFilesInSparePartRequests = (fileName, sparePart) => {
		return dispatch(removeFilesInSparePartRequest(sparePart.Token, fileName)).catch((error) => {
			setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error occurred while remove file', variant: 'warning' })
		})
	}

	return (
		<>
			{isMobile ? (
				<OpenViewMovil
					isMobile={isMobile}
					onChangeLocation={onChangeLocation}
					isLoading={isLoading}
					sparePartRequest={showSpareRequest}
					userProfile={userProfile}
				/>
			) : (
				<OpenManualDesktopView
					sparePartRequest={showSpareRequest}
					isLoading={isLoading}
					onButtonSerieClick={openLateralPanelSeries}
					equipmentsSap={equipmentsSap}
					onAddSparePart={onAddSparePart}
					isSaving={isSaving}
					onUpdateQuantityEquipment={onUpdateQuantityEquipment}
					onSaveSparePartRequest={onSaveSparePartRequest}
					onRemoveSparePart={onRemoveSparePart}
					identifierSparePartDelete={identifierSparePartDelete}
					userProfile={userProfile}
					onChangeLocation={onChangeLocation}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					onChangeQuerySap={onChangeQuerySap}
					sparePartsSap={sparePartsSap}
					querySparePart={querySparePart}
					onUpdateEquipment={onUpdateEquipment}
					isDownloadReport={isDownloadReport}
					onDonwloadReportBySerie={onDonwloadReportBySerie}
					onAddFilesInSparePartRequest={onAddFilesInSparePartRequest}
					onRemoveFilesInSparePartRequests={onRemoveFilesInSparePartRequests}
					sparePartsOptions={sparePartsOptions}
				/>
			)}
			<AddPanel
				addLateralPanelConfig={addLateralPanelConfig}
				openLateralPanelSeries={openLateralPanelSeries}
				cleamSparePartSap={onUpdateQuantityEquipment}
				lateralPanelStatusConfig={lateralPanelStatusConfig}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
				userProfile={userProfile}
			/>
			<Toaster toasterOptions={toaster} onClose={() => setToaster({ open: false })} />
		</>
	)
}

export default OpenManual
