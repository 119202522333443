import { fromJS } from 'immutable'
import moment from 'moment'
import * as serviceActions from '../services/serviceActions'

const initialServiceState = fromJS({
	isLoading: false,
	metrics: [],
	startDate: moment().startOf('year'),
	endDate: moment().add(10, 'day'),
	servicesList: {
		total: 0,
		pageNumber: 0,
		pageSize: 0,
		query: '',
		sortBy: null,
		sortDirection: 'desc',
		services: []
	},
	isLoadingDashboard: false,
	dashboard: null,
	isSavingService: false,
	isLoadingService: false,
	service: null,
	isAddingSeries: false,
	isSavingSeries: false,
	isDownloadReport: false,
	servicesClosed: []
})

/**
 * Service reducer
 */
const serviceReducer = (state = initialServiceState, action) => {
	switch (action.type) {
		case serviceActions.IS_LOADING_SERVICES:
			return state.merge({
				isLoading: action.status
			})
		case serviceActions.SET_SERVICES:
			return state.merge({
				isLoading: false,
				servicesList: fromJS(action.services)
			})
		case serviceActions.IS_LOADING_DASHBOAR:
			return state.merge({
				isLoadingDashboard: action.status
			})
		case serviceActions.SET_DASHBOAR:
			return state.merge({
				isLoadingDashboard: false,
				dashboard: fromJS(action.dashboard)
			})
		case serviceActions.IS_SAVING_SERVICE: {
			return state.merge({
				isSavingService: action.status
			})
		}
		case serviceActions.IS_LOADING_SERVICE: {
			return state.merge({
				isLoadingService: action.status
			})
		}
		case serviceActions.SET_SERVICE: {
			return state.merge({
				isLoadingService: false,
				service: fromJS(action.service)
			})
		}
		case serviceActions.IS_SAVING_EQUIPMENT: {
			return state.merge({
				isSavingSeries: action.status
			})
		}
		case serviceActions.IS_ADDING_EQUIPMENT: {
			return state.merge({
				isAddingSeries: action.status
			})
		}
		case serviceActions.ADD_EQUIMPENT_IN_LIST: {
			let equipment = fromJS(action.equipment)
			return state.updateIn(['service', 'Equipments'], (actions) =>
				actions.push(equipment)
			)
		}
		case serviceActions.UPDATE_EQUIPMENT: {
			let equipment = fromJS(action.equipment)
			return state.updateIn(['service', 'Equipments'], (allEquipments) =>
				updateEquipment(
					allEquipments,
					action.equipment.Token,
					equipment
				)
			)
		}
		case serviceActions.DELETE_EQUIPMENT: {
			return state.updateIn(['service', 'Equipments'], (details) =>
				details.filter(
					(detail) => detail.get('Token') !== action.equipmentToken
				)
			)
		}
		case serviceActions.IS_DONWLOAD_REPORT: {
			return state.merge({
				isDownloadReport: action.status
			})
		}
		case serviceActions.SET_START_AND_END_DATE:
			return state.merge({
				startDate: fromJS(action.startDate),
				endDate: fromJS(action.endDate)
			})
		case serviceActions.SET_SERVICE_METRICS: {
			return state.merge({
				metrics: fromJS(action.data)
			})
		}
		case serviceActions.UPDATE_ATTENTION_DATE: {
			let service = fromJS(action.data)
			return state.updateIn(['servicesList', 'services'], (allservices) =>
				updateService(allservices, service)
			)
		}
		case serviceActions.SET_SERVICES_CLOSED: {
			return state.merge({
				servicesClosed: fromJS(action.services)
			})
		}
		case serviceActions.ADD_FILES_SERVICE: {
			let files = fromJS(action.evidences)
			return state.setIn(['service', 'Attachments'], files)
		}
		case serviceActions.REMOVE_FILES_SERVICE: {
			return state.updateIn(['service', 'Attachments'], (files) =>
				files.filter((file) => file.get('name') !== action.fileName)
			)
		}
		case serviceActions.ADD_COMMENTS_HISTORY: {
			let commentHistory = fromJS(action.data)
			return state.updateIn(['service', 'CommentsHistories'], (actions) =>
				actions.push(commentHistory)
			)
		}
		case serviceActions.UPDATE_COMMENTS_HISTORY: {
			let commentHistory = fromJS(action.data)
			return state.updateIn(
				['service', 'CommentsHistories'],
				(allCommentHistories) =>
					updateCommentHistory(
						allCommentHistories,
						action.data.Token,
						commentHistory
					)
			)
		}
		default:
			return state
	}
}

const updateEquipment = (allEquipments, token, equipment) => {
	let indexOfEquipment = allEquipments.findIndex(
		(e) => e.get('Token') === token
	)
	if (indexOfEquipment >= 0) {
		return allEquipments.setIn([indexOfEquipment], equipment)
	}
	return allEquipments
}

const updateService = (allServices, service) => {
	let indexOfService = allServices.findIndex(
		(e) =>
			e.get('Token') === service.get('Token') &&
			e.get('Ticket') === service.get('Ticket')
	)
	if (indexOfService >= 0) {
		return allServices
			.setIn(
				[indexOfService, 'TechnicianServiceDate'],
				service.get('TechnicianServiceDate')
			)
			.setIn(
				[indexOfService, 'DifferenceInDays'],
				service.get('DifferenceInDays')
			)
	}
	return allServices
}

const updateCommentHistory = (allCommentHistories, token, comment) => {
	let indexOfComment = allCommentHistories.findIndex(
		(e) => e.get('Token') === token
	)
	if (indexOfComment >= 0) {
		return allCommentHistories.setIn([indexOfComment], comment)
	}
	return allCommentHistories
}

export default serviceReducer
