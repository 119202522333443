/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/**Import component sections */
import DesktopView from './components/DesktopView'
import Create from './Create'
import Toaster from '../common/Toaster'
import Edit from './Edit'
import ResendInvitation from './components/ResendInvitation'

/**Import ctions sections */
import {
	loadAllServiceProviderWithFilters,
	updateServiceProviderFromBackEnd,
	lockedUser,
	REMOVE_USER_FROM_ACCOUNT,
	resendInvitationProvider,
	downloadServiceProvidersList
} from '../../store/serviceproviders/serviceProvidersActions'

/**
 * This is the index of the service providers */
const Index = () => {
	/**Hooks sections */
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()

	const isLoading = useSelector((state) => state.serviceProviders.get('isLoadingProviders'))
	const isSaving = useSelector((state) => state.serviceProviders.get('isSaving'))
	const loggedUserProfile = useSelector((state) => state.session.get('profile'))
	const allServiceProviders = useSelector((state) => state.serviceProviders.get('serviceProviders'))
	const pagination = useSelector((state) => state.serviceProviders.get('pagination'))
	const isLoadingUser = useSelector((state) => state.users.get('isLoadingUser'))
	const userProfile = useSelector((state) => state.session.get('profile'))

	/**Local store this component section */
	const [query, setQuery] = useState('')
	const [serviceProviderEdit, setServiceProviderEdit] = useState({ opened: false, serviceProvider: null })
	const [gridMode, setGridMode] = useState(false)
	const [addPanelConfiguration, setAddPanelConfiguration] = useState({ opened: false })
	const [sendInvitationPanelConfiguration, setSendInvitationPanelConfiguration] = useState({ opened: false, accountCode: '' })
	const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null })
	const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false)
	const [identifierCardSeleted, setIdentifierCardSelected] = useState()
	const [identifierLockSeleted, setIdentifierLockSelected] = useState()
	const [resendInvitation, setResendInvitation] = useState({ opened: false, provider: null, administrator: null })

	/**
	 * Load all service providers
	 */
	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query) setQuery(queryParameters.query)
		dispatch(loadAllServiceProviderWithFilters(queryParameters))
	}, [dispatch, location.search])

	/**
	 * Here we save and switch view mode grid|list and persist on local storage.
	 */
	useEffect(() => {
		if (gridMode === false) {
			setGridMode(false)
		} else {
			setGridMode(true)
		}
	}, [gridMode])

	/**
	 * Update sourt criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onEditServiceProviderClick = (providerData) => {
		setIdentifierCardSelected(providerData.Token)
		setServiceProviderEdit({ opened: true, serviceProvider: providerData })
	}

	const onClosedLateralPanelEdit = () => {
		setIdentifierCardSelected('')
		setServiceProviderEdit({ opened: false, serviceProvider: null })
	}

	const updateServiceProviderData = (providerData) => {
		dispatch(updateServiceProviderFromBackEnd(providerData))
			.then((provider) => {
				setServiceProviderEdit({ opened: false, serviceProvider: provider })
			})
			.catch((error) => {
				setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to update service provider', variant: 'warning' })
			})
	}

	const onDownloadListOfUsersInProvider = () => {
		dispatch(downloadServiceProvidersList())
	}

	const onRemoveUserFromAccount = (token) => {
		dispatch({
			type: REMOVE_USER_FROM_ACCOUNT,
			token
		})
	}

	const onLocked = (token, isLocked, event) => {
		if (event) event.stopPropagation()
		setIdentifierLockSelected(token)
		dispatch(lockedUser(token, isLocked))
			.then((provider) => {
				setIdentifierLockSelected('')
				onRemoveUserFromAccount(token)
				setServiceProviderEdit({ opened: false, serviceProvider: provider })
			})
			.catch((error) => {
				setIdentifierLockSelected('')
				setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to update service provider', variant: 'warning' })
			})
	}

	/**Open/close dialog modal for upload logo user */
	const onSetHandleOpenCloseModalUploadImg = (serviceProvider) => {
		setHandleOpenModalUploadImg(!handleOpenModalUploadImg)
		if (serviceProvider) {
			setServiceProviderEdit({ opened: true, serviceProvider: serviceProvider })
		}
	}

	/**Change mode view in service provider */
	const switchViewMode = () => {
		if (gridMode === false) {
			setGridMode(true)
		} else {
			setGridMode(false)
		}
	}

	/**On add lateral panel configuration */
	const onAddLateralPanelConfig = () => {
		setAddPanelConfiguration({ opened: !addPanelConfiguration.opened })
	}

	const onSendInvitationButtonClick = (event, accountCode) => {
		setSendInvitationPanelConfiguration({ opened: !sendInvitationPanelConfiguration.opened, accountCode: accountCode })
		if (event) event.stopPropagation()
	}

	const onViewUserClic = (provider) => {
		history.push(`/serviceProviders/${provider.Code}/${provider.Name}/${provider.Token}`)
	}

	const onRedirectToProviderDocuments = (provider) => {
		history.push(`/serviceProviders/register/${provider.Token}`)
	}

	/**
	 * Action to resend invitaiton
	 * @param {*} event
	 * @param {*} provider
	 */
	const onResendInvitation = (event, provider) => {
		setResendInvitation({ opened: !resendInvitation.opened, provider: provider })
		if (event) event.stopPropagation()
	}

	const onCloseResendInvitation = () => {
		setResendInvitation({ opened: !resendInvitation.opened, provider: null })
	}

	/**
	 * Function to resend invitation
	 * @param {*} adminsitrator
	 * @param {*} password
	 */
	const onSendInvitation = (adminsitrator, confirmEmail, password) => {
		dispatch(resendInvitationProvider(adminsitrator, confirmEmail, resendInvitation.provider, password))
			.then(() => {
				onCloseResendInvitation()
				setToasterOptions({ open: true, message: 'Invitation re-send to provider correctly', variant: 'success' })
			})
			.catch((error) => {
				setToasterOptions({ open: true, message: 'An error to update service provider', variant: 'warning' })
			})
	}

	return (
		<>
			<DesktopView
				isLoading={isLoading}
				serviceProviders={allServiceProviders}
				searchValue={query}
				onChangeSortingCriteria={updateSortCriteria}
				onChangeSearchValue={(query) => updateSearchQuery(query)}
				onChangePage={onChangePage}
				pagination={pagination}
				onEditServiceProviderClick={onEditServiceProviderClick}
				serviceProviderEdit={serviceProviderEdit}
				switchViewMode={switchViewMode}
				gridMode={gridMode}
				onAddLateralPanelConfig={onAddLateralPanelConfig}
				identifierCardSeleted={identifierCardSeleted}
				onViewUserClic={onViewUserClic}
				onLocked={onLocked}
				loggedUserProfile={loggedUserProfile}
				identifierLockSeleted={identifierLockSeleted}
				onSendInvitationButtonClick={onSendInvitationButtonClick}
				onRedirectToProviderDocuments={onRedirectToProviderDocuments}
				onResendInvitation={onResendInvitation}
				onDownloadReport={onDownloadListOfUsersInProvider}
				userProfile={userProfile}
			/>

			<Create
				onAddLateralPanelConfig={onAddLateralPanelConfig}
				addPanelConfiguration={addPanelConfiguration}
				isSaving={isSaving}
				sendInvitationPanelConfiguration={sendInvitationPanelConfiguration}
				onSendInvitationButtonClick={onSendInvitationButtonClick}
			/>

			<Edit
				updateServiceProviderData={updateServiceProviderData}
				serviceProviderEdit={serviceProviderEdit}
				isSaving={isSaving}
				onClosedLateralPanelEdit={onClosedLateralPanelEdit}
				handleOpenModalUploadImg={handleOpenModalUploadImg}
				onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
			/>

			{resendInvitation.opened && (
				<ResendInvitation
					open={resendInvitation.opened}
					isSaving={isSaving}
					provider={resendInvitation.provider}
					onClose={onCloseResendInvitation}
					isLoadingUser={isLoadingUser}
					onSendInvitation={onSendInvitation}
				/>
			)}

			{toasterOptions.open && <Toaster toasterOptions={toasterOptions} onClose={() => setToasterOptions({ open: false })} />}
		</>
	)
}
export default Index
