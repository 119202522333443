import { makeStyles } from '@material-ui/styles'

/**
 * One column view styles
 */
export const showMobileStyles = makeStyles((theme) => ({
    root: {
      maxWidth: theme.views.maxWidth,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "10px",
      maxHeight: `calc(100vh - ${85}px)`,
      overflowY: "auto",
      marginBottom: "10px",
    },
    tabs: {
      maxHeight: 30,
      minHeight: 'auto',
    },
    tab: {
      marginTop: "-10px"
    },
    paddingNone:{
      padding:0
    },
    expanderHeader:{
      display: "flex",
      flexDirection: "row"
    },
    flexGrow: {
      flexGrow: 1
  },
  headerPanelSerie:{
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    minHeight: "0px !important",
    height: "50px !important"
  },
  titleSerie:{
    color: theme.palette.primary.main,
  },
  buttonAddMovil: {
    marginRight: 10,
    height: "35px",
    width: "35px",  
    marginLeft: 10,  
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity:.75,
      color: theme.palette.common.white,
    },
  },
  titleSeries: {
    color: theme.palette.colors.purple,
    fontSize: "0.875rem",
    boxSizing: "border-box",
    fontWeight: 500,
    paddingLeft: 15,
    paddingBottom: 8,
    paddingTop: 8,
  },
  textHeader:{
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
  },
  expandContent:{
    padding: "0px !important",
  },
  subListTitle:{
    color: theme.palette.colors.purple,
    lineHeight: "30px !important",
  },
  bagColor:{
    paddingLeft: "10px !important",
  }

}))