/**
 * Sort an list of items by a property value
 */
export const sortBy = (items, by, direction = 'desc') => {
	if (!by) {
		console.warn('undefined [sortBy] parameter')
		return items
	}
    if (items.length <= 1 )
        return items;

    let typeOfOrderedProperty = typeof items[0][by]
    let sortedItems = JSON.parse(JSON.stringify(items))
	sortedItems.sort((a,b) => {
        if (direction === 'asc') { 
            if (typeOfOrderedProperty === 'number') {
                return a[by] > b[by] ? 1: -1
            } else {
                return a[by].toString().toLowerCase() > b[by].toString().toLowerCase() ? 1: -1
            }
        } else if (direction === 'desc') { 
            if (typeOfOrderedProperty === 'number') {
                return a[by] > b[by] ? -1: 1
            } else {
                return a[by].toString().toLowerCase() > b[by].toString().toLowerCase() ? -1: 1
            }
        } else {
            console.warn('invalid [sortDirection] parameter::', direction)
            return 0
        }        
    })
    
    return sortedItems
}
