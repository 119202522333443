/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
/**Import styles section */
import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'
import { PositionMapDefault } from '../../../store/helpers/AppConstants'
import { GetBackgroundColorServiceByStatus } from '../../../store/helpers/StatusHelper'
import { GetDescriptionAmount, GetIconAmount, GetNameAmount } from '../../../store/helpers/StatusHelper'
/**Import Material Ui section */
import {
	Card,
	Grid,
	Typography,
	CardContent,
	Tabs,
	Tab,
	Paper,
	AppBar,
	Chip,
	DialogContent,
	ListItemSecondaryAction,
	ListItemText,
	ListItem,
	ListItemAvatar,
	List,
	Fab
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined'
import { Alert, AlertTitle } from '@material-ui/lab'
import ShowDesktopViewSkeleton from '../../skeletons/services/componets/ShowDesktopViewSkeleton'
/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import Toolbar from '../../common/Toolbar'
import ContactForm from './ContactForm'
import Authorization from '../../common/AuthorizationEvaluation'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import EquipmentsList from './EquipmentsList'
import SparePartsList from './SparePartsList'
import MapComponent from './MapComponent'
import AssignementService from './StatusPanel/AssignementService'
import DateAttentionComponent from './DateAttentionComponent'
import CommentHistoryComponent from './CommentHistoryComponent'
import EvidenceComponent from './EvidenceComponent'
/**Import resources section */
import iconFailureDoor from '../../../resources/images/failureWarranty/door.svg'
import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	DISPATCHER,
	IMBERA_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER,
	PORTAL_ADMINISTRATOR
} from '../../../resources/RolesEnum'
import { currencyFormat } from '../../../store/helpers/AmountHelper'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'

/**
 * Component show desktop view
 * @param {*} props
 */
const ShowDesktopView = (props) => {
	const {
		service,
		isSavingSeries,
		isAddingSeries,
		onAddSeries,
		onUpdateSerieInService,
		onDeleteSerieInService,
		isLoading,
		onShowWarranty,
		userProfile,
		isSavingService,
		onSaveService,
		openLateralPanelChangeStatus,
		openLateralPanelAssignedProvider,
		onAddDateAttention,
		onAddCommentHistory,
		onUpdateCommentHistory,
		onUpdateComment,
		onAddAttachments,
		onRemoveAttachments
	} = props
	const classes = createServicesStyle()
	const [serviceModel, setServiceModel] = useState(service)
	const [serviceProvider, setServiceProvider] = useState(null)

	let isAuthorizerCanEditRoleImberaMx = hasAuthorization(userProfile, [
		DISPATCHER,
		IMBERA_TECHNICIAN,
		CUSTOMER_MANAGER,
		CUSTOMER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER,
		SERVICE_PROVIDER_TECHNICIAN,
		SALES_REPRESENTATIVE_MANAGER,
		SALES_MANAGER
	])
	let canBeEditDataAndAuthorized = isAuthorizerCanEditRoleImberaMx
	// (serviceModel ? !serviceModel.IsClosed : false)

	let canBeAddTechProvider = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER])
	let isAuthorizerCanEditDateAttention = hasAuthorization(userProfile, [SERVICE_PROVIDER_TECHNICIAN, SERVICE_PROVIDER_MANAGER])
	let canBeEditDateAttention = isAuthorizerCanEditDateAttention && (serviceModel ? !serviceModel.IsClosed : false)
	let canBeCreateCommentInServices = isAuthorizerCanEditRoleImberaMx
	let isAuthorizerCanChangeStatusCommentHistory = hasAuthorization(userProfile, [DISPATCHER, PORTAL_ADMINISTRATOR, CUSTOMER_MANAGER])
	let canBeChangeStatusCommentHistory = isAuthorizerCanChangeStatusCommentHistory && (serviceModel ? !serviceModel.IsClosed : false)

	useEffect(() => {
		if (service) {
			if (service && service.ServiceProvider && serviceProvider === null) {
				let objectMapped = {
					value: service.ServiceProvider.Code,
					label: service.ServiceProvider.Name
				}
				setServiceProvider(objectMapped)
			}
			setServiceModel(service)
		}
	}, [service, serviceProvider])

	/**
	 * function on get address from service
	 */
	const getAddress = () => {
		if (service && service.Address !== null) {
			return service.Address
		} else
			return {
				route: '',
				street_number: '',
				locality: '',
				administrative_area_level_1: '',
				country: '',
				postal_code: '',
				latitude: PositionMapDefault.latitude,
				longitude: PositionMapDefault.longitude,
				full_address: ''
			}
	}

	const onRenderContent = () => {
		if (isLoading) {
			return <ShowDesktopViewSkeleton />
		} else {
			return (
				<div className={classes.root}>
					<ServiceHeaderView
						service={service ? service : undefined}
						salesRepresentative={service ? service.SalesRepresentative : undefined}
						retailer={service ? service.Retailer : undefined}
						address={getAddress()}
						subcategory={service ? service.Subcategory : undefined}
						openLateralPanelChangeStatus={openLateralPanelChangeStatus}
						isLoading={isLoading}
						onSaveService={onSaveService}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={!canBeEditDataAndAuthorized}
						canBeAddTechProvider={canBeAddTechProvider}
						userProfile={userProfile}
						openLateralPanelAssignedProvider={openLateralPanelAssignedProvider}
					/>
					<br />
					<TabsView
						service={service ? service : undefined}
						onShowWarranty={onShowWarranty}
						isAddingSeries={isAddingSeries}
						onAddSeries={onAddSeries}
						onUpdateSerieInService={onUpdateSerieInService}
						onDeleteSerieInService={onDeleteSerieInService}
						isSavingSeries={isSavingSeries}
						isSavingService={isSavingService}
						isAuthorizadoForEditedData={!canBeEditDataAndAuthorized}
						isLoading={isLoading}
						onAddDateAttention={onAddDateAttention}
						canBeEditDateAttention={canBeEditDateAttention}
						onAddCommentHistory={onAddCommentHistory}
						onUpdateCommentHistory={onUpdateCommentHistory}
						onUpdateComment={onUpdateComment}
						canBeCreateCommentInServices={canBeCreateCommentInServices}
						canBeChangeStatusCommentHistory={canBeChangeStatusCommentHistory}
						onAddAttachments={onAddAttachments}
						onRemoveAttachments={onRemoveAttachments}
						userProfile={userProfile}
					/>
				</div>
			)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Services / View Service</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={1} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>{onRenderContent()}</Content>
		</Layout>
	)
}

ShowDesktopView.propTypes = {
	service: propTypes.object,
	isLoading: propTypes.bool.isRequired,
	/**Dterminate if is saving data from service */
	isSavingService: propTypes.bool.isRequired,
	/**On action to is saving series */
	isSavingSeries: propTypes.bool.isRequired,
	isAddingSeries: propTypes.bool.isRequired,
	/**Function invoke callback for save series in service */
	onAddSeries: propTypes.func.isRequired,
	/**Function invoke callback for save series in service */
	onButtonUpdateClick: propTypes.func.isRequired,
	/**Function invoke callback for update series in service */
	onUpdateSerieInService: propTypes.func.isRequired,
	/**Function invoke callback for show warranty for equipment */
	onShowWarranty: propTypes.func.isRequired,
	/**User profile for determinate user logged for roles*/
	userProfile: propTypes.object.isRequired,
	/**Determinate if user canbe edited data */
	isAuthorizadoForEditedData: propTypes.bool,
	/**Function invoke callback for on add atentionDate */
	onAddDateAttention: propTypes.func,
	/**Function invoke callback for on add commentHistory */
	onAddCommentHistory: propTypes.func,
	/**Function invoke callback for on add files */
	onAddAttachments: propTypes.func,
	/**Function invoke callback for on remove files */
	onRemoveAttachments: propTypes.func
}
ShowDesktopView.defaultProps = {
	service: {},
	internalUsers: [],
	isLoading: false,
	isAuthorizadoForEditedData: false,
	isSavingService: false,
	isSavingSeries: false,
	isAddingSeries: false,
	onAddSeries: () => console.warn('Callback [onAddSeries] no defined'),
	onButtonUpdateClick: () => console.warn('Callback [onButtonUpdateClick] no defined'),
	onUpdateSerieInService: () => console.warn('Callback [onUpdateSerieInService] no defined'),
	onShowWarranty: () => console.warn('Callback [onShowWarranty] no defined'),
	onAddDateAttention: () => console.warn('Callback [onAddDateAttention] no defined'),
	onAddCommentHistory: () => console.warn('Callback [onAddCommentHistory] no defined'),
	onAddAttachments: () => console.warn('Callback [onAddAttachments] no defined'),
	onRemoveAttachments: () => console.warn('Callback [onRemoveAttachments] no defined'),
	userProfile: {}
}

export default withImmutablePropsToJS(ShowDesktopView)

/**
 * Service header view
 */
export const ServiceHeaderView = (props) => {
	const classes = createServicesStyle()
	const {
		service,
		salesRepresentative,
		retailer,
		address,
		openLateralPanelChangeStatus,
		isLoading,
		onSaveService,
		isSavingService,
		isAuthorizadoForEditedData,
		userProfile,
		subcategory,
		openLateralPanelAssignedProvider,
		isMobile,
		canBeAddTechProvider
	} = props
	const colorByStatus = GetBackgroundColorServiceByStatus(service ? service.Status : null)

	const onGetHeaderDescriptionService = () => {
		let description = 'View services'
		let result = ''
		if (!isNullOrEmpty(service.Ticket)) result = description.concat(` | TICKET: ${service.Ticket} `)
		if (!isNullOrEmpty(salesRepresentative.Name)) result = result.concat(` | REP: ${salesRepresentative.Name} `)
		if (!isNullOrEmpty(retailer.Name))
			result = result.concat(` | ${retailer.Name} ${subcategory !== null ? ' | Subcategory: ' + subcategory : ''} `)
		return result
	}
	const onRenderViewService = () => {
		let isActiveButtonStatus = !isLoading && (service ? !service.IsClosed : false)
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{isActiveButtonStatus ? (
					<Authorization profile={userProfile} allowedRoles={[DISPATCHER, IMBERA_TECHNICIAN]}>
						<Chip
							label='CHANGE STATUS'
							clickable
							color='primary'
							disabled={isLoading}
							onClick={() => openLateralPanelChangeStatus(service)}
						/>
					</Authorization>
				) : null}
				<>
					{canBeAddTechProvider && service && service.CanBeAssignedProvider && (
						<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_MANAGER]}>
							<Chip
								label='ASSIGN TECHNICAN'
								clickable
								color='primary'
								disabled={isLoading}
								onClick={() => openLateralPanelAssignedProvider(service)}
							/>
						</Authorization>
					)}
					{!canBeAddTechProvider && service && service.CanBeCloseTechProvider && (
						<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_TECHNICIAN]}>
							<Chip
								label='CLOSE SERVICE'
								clickable
								color='primary'
								disabled={isLoading}
								onClick={() => openLateralPanelAssignedProvider(service)}
							/>
						</Authorization>
					)}
					{service && service.IsClosed && (
						<Authorization profile={userProfile} allowedRoles={[PORTAL_ADMINISTRATOR]}>
							<Chip
								label='CHANGE STATUS'
								clickable
								color='primary'
								disabled={isLoading}
								onClick={() => openLateralPanelChangeStatus(service)}
							/>
						</Authorization>
					)}
				</>
			</div>
		)
	}
	return (
		<Card className={classes.container}>
			<Toolbar
				title={service ? onGetHeaderDescriptionService() : ''}
				renderContentRight={onRenderViewService()}
				renderBackgroundColor={colorByStatus}
				icon={iconFailureDoor}
			/>
			<CardContent className={!isMobile ? null : classes.contentMap}>
				<Grid container spacing={1}>
					<Grid item xs={isMobile ? 12 : 6}>
						<MapComponent
							position={{
								latitude: address.latitude,
								longitude: address.longitude
							}}
							fullAddress={address.full_address}
							isMobile={isMobile}
						/>
					</Grid>
					{!isMobile && (
						<>
							<Grid item xs={isMobile ? 12 : 6}>
								<ContactForm
									service={service ? service : undefined}
									onSaveService={onSaveService}
									isSavingService={isSavingService}
									isAuthorizadoForEditedData={isAuthorizadoForEditedData}
									isMobile={isMobile}
									userProfile={userProfile}
								/>
							</Grid>
							<Grid item xs={12}>
								<Alert variant='outlined' severity='info' icon={<LocationOnIcon fontSize='inherit' />}>
									<AlertTitle>Address</AlertTitle>
									{`${address.street_number} ${address.route}`} <br />
									{`${address.locality}, ${address.administrative_area_level_1}, ${address.country}`}
									<br />
									{`${address.postal_code}`}
								</Alert>
							</Grid>
						</>
					)}
				</Grid>
			</CardContent>
		</Card>
	)
}
ServiceHeaderView.propTypes = {
	service: propTypes.shape({
		Ticket: propTypes.string.isRequired
	}),
	salesRepresentative: propTypes.shape({
		Name: propTypes.string.isRequired
	}),
	retailer: propTypes.shape({
		Name: propTypes.string.isRequired
	}),
	address: propTypes.shape({
		route: propTypes.string.isRequired,
		street_number: propTypes.string.isRequired,
		locality: propTypes.string.isRequired,
		administrative_area_level_1: propTypes.string.isRequired,
		country: propTypes.string.isRequired,
		postal_code: propTypes.string.isRequired,
		latitude: propTypes.number.isRequired,
		longitude: propTypes.number.isRequired,
		full_address: propTypes.string.isRequired
	})
}
ServiceHeaderView.defaultProps = {
	service: {
		Ticket: '',
		Subcategory: ''
	},
	salesRepresentative: {
		Name: ''
	},
	retailer: {
		Name: ''
	},
	address: {
		route: '',
		street_number: '',
		locality: '',
		administrative_area_level_1: '',
		country: '',
		postal_code: '',
		latitude: PositionMapDefault.latitude,
		longitude: PositionMapDefault.longitude,
		full_address: ''
	}
}

/**
 * Tabs view container
 */
const TabsView = (props) => {
	const [visiblePanelIndex, setVisiblePanelIndex] = useState(0)
	const {
		service,
		onShowWarranty,
		isAddingSeries,
		onAddSeries,
		onUpdateSerieInService,
		onDeleteSerieInService,
		isSavingSeries,
		isLoading,
		isAuthorizadoForEditedData,
		onAddDateAttention,
		onAddCommentHistory,
		onUpdateCommentHistory,
		onUpdateComment,
		isSavingService,
		canBeEditDateAttention,
		canBeCreateCommentInServices,
		canBeChangeStatusCommentHistory,
		onAddAttachments,
		onRemoveAttachments,
		userProfile
	} = props
	return (
		<Paper>
			<AppBar position='static'>
				<Tabs value={visiblePanelIndex} onChange={(event, index) => setVisiblePanelIndex(index)}>
					<Tab label='Series' />
					<Tab label='Assignments' />
					<Tab label='Files' />
					{<Tab label='Comment History' />}
					{service.SpareParts && service.SpareParts.length > 0 && <Tab label='Spare parts' />}
					{service.ServiceProviderTechAssigned && <Tab label='Date attention' />}
					{service.ServiceAmounts && service.ServiceAmounts.length > 0 && <Tab label='Amounts' />}
				</Tabs>
			</AppBar>
			<TabPanel value={visiblePanelIndex} index={0}>
				<SeriesTabPanel
					equipments={service.Equipments}
					isSavingSeries={isSavingSeries}
					isLoading={isLoading}
					onShowWarranty={onShowWarranty}
					isAddingSeries={isAddingSeries}
					onAddSeries={onAddSeries}
					onUpdateSerieInService={onUpdateSerieInService}
					onDeleteSerieInService={onDeleteSerieInService}
					isAuthorizadoForEditedData={isAuthorizadoForEditedData}
					CanBeEdited={service.CanBeEdited}
					userProfile={userProfile}
				/>
			</TabPanel>
			<TabPanel value={visiblePanelIndex} index={1}>
				<DialogContent>
					<AssignementService
						dispatcher={service.Dispatcher}
						techAssigned={service.TechAssigned}
						serviceProvider={service.ServiceProvider}
						customer={service.Customer}
						serviceProviderTechAssigned={service.ServiceProviderTechAssigned}
						isAuthorizadoForEditedData={false}
					/>
					<br />
					<br />
				</DialogContent>
			</TabPanel>
			<TabPanel value={visiblePanelIndex} index={2}>
				<DialogContent>
					<EvidenceComponent
						Attachments={service.Attachments}
						onAddAttachments={onAddAttachments}
						onRemoveAttachments={onRemoveAttachments}
						canBeEdit={!isAuthorizadoForEditedData || canBeEditDateAttention}
					/>
				</DialogContent>
			</TabPanel>
			<TabPanel value={visiblePanelIndex} index={3}>
				<DialogContent>
					<CommentHistoryComponent
						service={service}
						onAddCommentHistory={onAddCommentHistory}
						onUpdateCommentHistory={onUpdateCommentHistory}
						onUpdateComment={onUpdateComment}
						isSavingService={isSavingService}
						isAuthorizedForCreatingComment={canBeCreateCommentInServices}
						isAuthorizedForChangingStatus={canBeChangeStatusCommentHistory}
					/>
					<br />
					<br />
				</DialogContent>
			</TabPanel>
			{service.SpareParts && service.SpareParts.length > 0 && (
				<TabPanel value={visiblePanelIndex} index={4}>
					<DialogContent>
						<SparePartsList sparParts={service.SpareParts} />
						<br />
						<br />
					</DialogContent>
				</TabPanel>
			)}
			{service.ServiceProviderTechAssigned && (
				<TabPanel value={visiblePanelIndex} index={service.SpareParts && service.SpareParts.length > 0 ? 5 : 4}>
					<DialogContent>
						<DateAttentionComponent
							service={service}
							onAddDateAttention={onAddDateAttention}
							isSavingService={isSavingService}
							isAuthorizadoForEditedData={canBeEditDateAttention}
						/>
						<br />
						<br />
					</DialogContent>
				</TabPanel>
			)}
			{service.ServiceAmounts && service.ServiceAmounts.length > 0 && (
				<TabPanel
					value={visiblePanelIndex}
					index={
						service.SpareParts && service.SpareParts.length > 0 && service.ServiceProviderTechAssigned
							? 6
							: service.SpareParts.length > 0 || service.ServiceProviderTechAssigned
							? 5
							: 4
					}
				>
					<DialogContent>
						<AmountsTabPanel ServiceAmounts={service.ServiceAmounts} TotalAmounts={service.TotalAmounts} />
						<br />
						<br />
					</DialogContent>
				</TabPanel>
			)}
		</Paper>
	)
}
TabsView.propTypes = {
	service: propTypes.shape({
		Equipments: propTypes.array.isRequired
	}).isRequired,
	isSavingSeries: propTypes.bool.isRequired,
	isAddingSeries: propTypes.bool.isRequired,
	isLoading: propTypes.bool.isRequired,
	onShowWarranty: propTypes.func,
	onAddSeries: propTypes.func,
	onUpdateSerieInService: propTypes.func
}
TabsView.defaultProps = {
	service: {
		Equipments: [],
		ServiceAmounts: [],
		SpareParts: []
	}
}

/**
 * The tab panel with service list
 */
export const SeriesTabPanel = (props) => {
	const {
		equipments,
		onShowWarranty,
		isAddingSeries,
		onAddSeries,
		onUpdateSerieInService,
		onDeleteSerieInService,
		isSavingSeries,
		isLoading,
		isAuthorizadoForEditedData,
		CanBeEdited,
		userProfile
	} = props
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<EquipmentsList
			equipments={equipments}
			isAddingSeries={isAddingSeries}
			isSavingSeries={isSavingSeries}
			onAddSeries={onAddSeries}
			onUpdateSerieInService={onUpdateSerieInService}
			onDeleteSerieInService={onDeleteSerieInService}
			isLoading={isLoading}
			onShowWarranty={onShowWarranty}
			isAuthorizadoForEditedData={isAuthorizadoForEditedData}
			modeView='SHOW_SERVICE'
			CanBeEdited={CanBeEdited}
			handleClick={handleClick}
			open={open}
			onDeleteButton={true}
			userProfile={userProfile}
		/>
	)
}
SeriesTabPanel.props = {
	equipments: propTypes.array.isRequired,
	isSavingSeries: propTypes.bool.isRequired,
	isAddingSeries: propTypes.bool.isRequired,
	CanBeEdited: propTypes.bool.isRequired,
	isLoading: propTypes.bool.isRequired,
	onShowWarranty: propTypes.func,
	onAddSeries: propTypes.func,
	onUpdateSerieInService: propTypes.func
}
SeriesTabPanel.defaultProps = {
	equipments: [],
	isSavingSeries: false,
	isAddingSeries: false,
	CanBeEdited: false
}

/**
 * The tab panel with amounts list
 * @param {*} props
 */
export const AmountsTabPanel = (props) => {
	const classes = createServicesStyle()
	const { ServiceAmounts, TotalAmounts } = props

	return (
		<Grid item xs={12}>
			<List>
				{ServiceAmounts.map((mount) => {
					return (
						<ListItem divider key={mount.Token}>
							<ListItemAvatar>
								<Fab size='small' className={classes.amountIconSelected} disabled={true}>
									<img src={GetIconAmount(mount.Description)} alt='warranty-icon' className={classes.amountIcon} />
								</Fab>
							</ListItemAvatar>
							<ListItemText primary={GetNameAmount(mount.Description)} secondary={GetDescriptionAmount(mount.Description)} />
							<ListItemSecondaryAction>{`${currencyFormat(mount.Amount)}`}</ListItemSecondaryAction>
						</ListItem>
					)
				})}
				<ListItem>
					<ListItemAvatar>
						<Fab size='small' className={classes.amountIconSelected} disabled={true}>
							<img src={GetIconAmount('TOTAL')} alt='warranty-icon' className={classes.amountIcon} />
						</Fab>
					</ListItemAvatar>
					<ListItemText primary='TOTAL' secondary={GetDescriptionAmount('TOTAL')} />
					<ListItemSecondaryAction>
						<strong>{`${currencyFormat(TotalAmounts)}`}</strong>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		</Grid>
	)
}
AmountsTabPanel.props = {
	ServiceAmounts: propTypes.array.isRequired
}
AmountsTabPanel.defaultProps = {
	ServiceAmounts: [],
	TotalAmounts: 0
}

/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	)
}
TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired
}
