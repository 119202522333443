import { fromJS } from 'immutable'
import {
    IS_LOADING_USERS, SET_INTERNAL_USERS, SET_INTERNAL_USER_QUERYPARAMETERS,
    SET_USER, ADD_NEW_USER, IS_SAVING, SET_USER_PHONE_NUMBER, SET_ROLES_IN_PROFILE,
    REMOVE_USER_FROM_ACCOUNT, SET_VALIDATING_PASSWORD, IS_LOADING_USER,
    IS_LOADING_NOTIFICATIONS, SET_NOTIFICATIONS, REMOVE_NOTIFICATION_TO_USER,
    REMOVE_NOTIFICATION, UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER
} from './UsersActions'

let initialState = fromJS({
    isLoading: false,
    internalUsers: [],
    internalQueryParameters: null,
    user: null,
    isSaving: false,
    isValidatingPassword: false,
    isLoadingUser: false,
    isLoadingNotifications: false,
    notifications: []
})

/**
 * User's reducer
 */
const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING_USERS:
            return state.merge({
                isLoading: action.status
            })
        case SET_INTERNAL_USERS: {
            return state.merge({
                internalUsers: fromJS(action.users),
                isLoading: false
            })
        }
        case SET_INTERNAL_USER_QUERYPARAMETERS: {
            return state.merge({
                internalQueryParameters: fromJS(action.queryParametes)
            })
        }
        case SET_USER: {
            return state.merge({
                user: fromJS(action.user)
            })
        }
        case ADD_NEW_USER: {
            let newUser = fromJS(action.user);
            return state.update('internalUsers', allIntenalUsers => pushUser(allIntenalUsers, newUser));

        }
        case IS_SAVING:
            return state.merge({
                isSaving: action.status
            })
        case SET_USER_PHONE_NUMBER: {
            return state.update('internalUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.userName, 'PhoneNumber', fromJS(action.phoneNumber)));
        }
        case UPDATE_LAST_ACTIVE_NOTIFICATIONS_USER: {
            let lastActivation = action.user.ActiveNotifications
            return state.update('internalUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.UserName, 'ActiveNotifications', lastActivation));
        }
        case SET_ROLES_IN_PROFILE: {
            let roles = fromJS(action.roles);
            return state.update('internalUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.userName, 'Roles', fromJS(roles)));
        }
        case REMOVE_USER_FROM_ACCOUNT: {
            return state.updateIn(['internalUsers'], allInternalUsers => allInternalUsers.filter(user => user.get('Token') !== action.token))
                .set('isSaving', false);
        }
        case SET_VALIDATING_PASSWORD: {
            return state.merge({
                isValidatingPassword: action.status
            })
        }
        case IS_LOADING_USER: {
            return state.merge({
                isLoadingUser: action.status
            })
        }
        case IS_LOADING_NOTIFICATIONS:
            return state.merge({
                isLoadingNotifications: action.status
            })
        case SET_NOTIFICATIONS: {
            return state.merge({
                notifications: fromJS(action.notifications),
                isLoadingNotifications: false
            })
        }
        case REMOVE_NOTIFICATION_TO_USER: {
            return state.updateIn(['notifications'], allNotifications => allNotifications.filter(notification => notification.get('RequestToken') !== action.requestToken));
        }
        case REMOVE_NOTIFICATION: {
            return state.updateIn(['notifications'], allNotifications => allNotifications.filter(notification => notification.get('Token') !== action.notificationToken));
        }
        default:
            return state
    }
}

const pushUser = (allIntenalUsers, newUser) => {
    if (allIntenalUsers.some(user => user.get('UserName') === newUser.get('UserName'))) {
        return allIntenalUsers;
    }
    return allIntenalUsers.push(newUser);
}

const updateUserProperty = (allIntenalUsers, userName, propertyName, propertyValue) => {
    let indexOfUser = allIntenalUsers.findIndex(user => user.get('UserName') === userName);
    if (indexOfUser >= 0) {
        return allIntenalUsers.setIn([indexOfUser, propertyName], propertyValue);
    }
    return allIntenalUsers;
}

export default usersReducer
