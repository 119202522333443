/**Import react section */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'

/**Import componnets section */
import SerialSapDesktopView from './components/SerialSapDesktopView'
import SerialSapDesktopViewMobile from './componentsMobile/SerialSapDesktopViewMobile'
import Toaster from '../common/Toaster'

/**Import actions section */
import {
    consultBySerie,
    SET_QUERY_SAP,
    SET_SPARE_PARTS_FROM_SAP,
    downloadReportByserie,
    getMaterialPlaneInfo,
    removeJsonSerieSap
} from '../../store/spareParts/sparePartsActions'
import { sparePartsSAPSelector } from '../../store/spareParts/sparePartsSelectors'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

const SerialSapIndex = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const isMobile = useIsMobile(props.isMobile)
    const userProfile = useSelector((state) => state.session.get('profile'))
    const equipmentsSap = useSelector((state) => state.spareParts.getIn(['show', 'equipmentsSap']))
    const querySparePart = useSelector((state) => state.spareParts.get(['show', 'querySparePart']))
    const isLoadingFromSAP = useSelector((state) => state.spareParts.getIn(['show', 'isLoadingFromSAP']))
    const isDownloadReport = useSelector((state) => state.spareParts.getIn(["index", "isDownloadReport"]))
    const isDownloadPlane = useSelector((state) => state.spareParts.getIn(["index", "isDownloadPlane"]))

    const sparePartsSap = useSelector(sparePartsSAPSelector)
    const [toaster, setToaster] = useState({
        open: false,
        variant: null,
        message: null
    })

    useEffect(() => {
        dispatch({
            type: SET_SPARE_PARTS_FROM_SAP,
            Equipment: null
        })
    }, [dispatch])

    const onChangeLocation = (location) => history.push(location)

    const onConsultBySerie = (serie) => {
        onUpdateQuantityEquipment(null)
        return dispatch(consultBySerie(serie))
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to consult materials by serie from SAP', variant: 'warning' })
            })
    }

    const onChangeQuerySap = (query) => {
        dispatch({
            type: SET_QUERY_SAP,
            query
        })
    }

    const onUpdateQuantityEquipment = (Equipment) => {
        /**If equipment is null clean query in redux store empty */
        if (Equipment === null) {
            onChangeQuerySap('')
        }
        return dispatch({
            type: SET_SPARE_PARTS_FROM_SAP,
            Equipment
        })
    }

    /**
 * Function to donwload report by serie
 */
    const onDonwloadReportBySerie = () => {
        return dispatch(downloadReportByserie())
            .catch(() => {
                setToaster({ open: true, message: 'An error to download report by serie', variant: 'error' })
            })
    }

    /**
     * Function to get material plane info and download it
     */
    const onDownloadMaterialPlane = (materialNumber, productionDate) => {
        return dispatch(getMaterialPlaneInfo(materialNumber, productionDate))
            .catch(() => {
                setToaster({ open: true, message: 'We have a problem while downloading the PDF file', variant: 'error' })
            })
    }

    /**
     * Action to remove file json with serie sap to get the SAP information again
     * @param {*} serie 
     */
    const onRemoveFileJsonSerie = (serie) => {
        onUpdateQuantityEquipment(null)
        return dispatch(removeJsonSerieSap(serie))
            .catch(() => {
                setToaster({ open: true, message: 'An error to remove file to get serie from SAP', variant: 'warning' })
            })
    }


    return (
        <>
            {!isMobile ? <SerialSapDesktopView
                userProfile={userProfile}
                onChangeLocation={onChangeLocation}
                onConsultBySerie={onConsultBySerie}
                equipmentsSap={equipmentsSap}
                sparePartsSap={sparePartsSap}
                onChangeQuerySap={onChangeQuerySap}
                querySparePart={querySparePart}
                isLoadingFromSAP={isLoadingFromSAP}
                onUpdateQuantityEquipment={onUpdateQuantityEquipment}
                onDonwloadReportBySerie={onDonwloadReportBySerie}
                onDownloadMaterialPlane={onDownloadMaterialPlane}
                isDownloadReport={isDownloadReport}
                isDownloadPlane={isDownloadPlane}
                onRemoveFileJsonSerie={onRemoveFileJsonSerie}
            /> :
                <SerialSapDesktopViewMobile
                    userProfile={userProfile}
                    onChangeLocation={onChangeLocation}
                    onConsultBySerie={onConsultBySerie}
                    equipmentsSap={equipmentsSap}
                    sparePartsSap={sparePartsSap}
                    onChangeQuerySap={onChangeQuerySap}
                    querySparePart={querySparePart}
                    isLoadingFromSAP={isLoadingFromSAP}
                    onUpdateQuantityEquipment={onUpdateQuantityEquipment}
                    onDonwloadReportBySerie={onDonwloadReportBySerie}
                    isDownloadReport={isDownloadReport}
                    onRemoveFileJsonSerie={onRemoveFileJsonSerie}
                />
            }

            <Toaster
                toasterOptions={toaster}
                onClose={() => setToaster({ open: false })}
            />
        </>
    )
}

export default SerialSapIndex
