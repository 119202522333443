const sparePartTableConfig = [
	{
		title: 'Serial',
		dataSource: 'Serial',
		onRenderProperty: (item) => (item.Equipment ? item.Equipment.Serie : '')
	},
	{
		title: 'Folio',
		dataSource: 'FolioRequest'
	},
	{
		title: 'Quantity',
		dataSource: 'Quantity'
	},
	{
		title: '#Part',
		dataSource: 'Code'
	},
	{
		title: 'Description',
		dataSource: 'Description'
	},
	{
		title: 'Warehouse',
		dataSource: 'Warehouse'
	},
	{
		title: 'Number tracking',
		dataSource: 'NumberTracking'
	},
	{
		title: 'Status',
		dataSource: 'Status'
	}
]
export default sparePartTableConfig
