import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import { Card, Grid } from '@material-ui/core';

/**Import squeletons components section */
import ToolbarSkeleton from './ToolbarSkeleton'
const ShowMobileSkeleton=()=> {
    const classes = createServicesStyle();
    return (
        <div className={classes.root}>
            <ToolbarSkeleton />
            <Card className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressSkeleton/>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}

const AddressSkeleton = () => {
    return (
        <Grid container spacing={1}>
            {renderTextBoxSkeleton(12, 100, "100%")}
            {renderTextBoxSkeleton(12, 30, "100%")}
            {renderTextBoxSkeleton(12, 30, "100%")}
            <Grid item xs={12}>
                <Skeleton variant="text" />
            </Grid>
            {renderTextBoxSkeleton(12, 30, "100%")}
        </Grid>
    )
}

/**Render textBox Squeleton */
const renderTextBoxSkeleton = (size = 6, height, width) => {
    return (
        <Grid item xs={size}>
            <Skeleton variant="rect" animation="wave" width={width} height={height} />
        </Grid>
    )
}

export default ShowMobileSkeleton