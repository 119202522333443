import { fromJS } from 'immutable'
import  * as regionsActions from '../regions/regionsActions'

const initialServiceState = fromJS({
	isLoading: false,
	services: []
})

/**
 * Service reducer
 */
const regionsReducer = (state = initialServiceState, action) => {
	switch (action.type) {
		case regionsActions.IS_LOADING_SERVICES: 
			return state.merge({
				isLoading: action.status
			})
		case regionsActions.SET_SERVICES:
			return state.merge({
				isLoading: false,
				services: fromJS(action.services)
			})
		default:
			return state
	}
}

export default regionsReducer
