import React, { useState } from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import {
	InputAdornment,
	Popper,
	Paper,
	ClickAwayListener,
	TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRangeTwoTone'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { DateRangePicker as ReactDateRagenPicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

/**
 * Date time picker component
 */
const DateRangePicker = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const classes = useStyles({
		textColor: props.textColor
	})

	/**
	 * Toggle picker status
	 */
	const onTogglePicker = (event) => {
		let updatedAnchor = anchorEl ? null : event.currentTarget
		setAnchorEl(updatedAnchor)
	}

	const isOpen = anchorEl !== null ? true : false

	/** Get the seleccion date */
	const getSelectionRange = () => {
		return [
			{
				startDate: props.startDate.toDate(),
				endDate: props.endDate.toDate()
			}
		]
	}

	/**
	 * onChangeRange callback
	 */
	const onChangeRange = (range) => {
		let startDate = moment(range.range1.startDate)
		let endDate = moment(range.range1.endDate)
		props.onChangeRange({ startDate: startDate, endDate: endDate })
	}

	/**
	 * Get the static ranges shown in the lateral panel
	 */
	const getStaticRanges = () => {
		if (!props.predefinedRanges) return undefined

		return props.predefinedRanges.map((predefinedRange) => {
			return {
				label: predefinedRange.label,
				range: () => ({
					startDate: predefinedRange.startDate.toDate(),
					endDate: predefinedRange.endDate.toDate()
				}),
				isSelected() {
					return (
						props.startDate.isSame(
							predefinedRange.startDate,
							'day'
						) &&
						props.endDate.isSame(predefinedRange.endDate, 'day')
					)
				}
			}
		})
	}

	return (
		<div>
			<TextField
				fullWidth
				variant={props.variant}
				size='small'
				onClick={onTogglePicker}
				value={`${props.startDate.format(
					'MM/DD/YYYY'
				)}  -  ${props.endDate.format('MM/DD/YYYY')}`}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<DateRangeIcon />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position='start'>
							<ExpandMoreIcon />
						</InputAdornment>
					),
					className: classes.text
				}}
			/>
			<Popper
				open={isOpen}
				anchorEl={anchorEl}
				placement='bottom-start'
				style={{ backgroundColor: 'white', zIndex: 9999 }}
			>
				<Paper>
					<ClickAwayListener onClickAway={onTogglePicker}>
						<ReactDateRagenPicker
							ranges={getSelectionRange()}
							onChange={onChangeRange}
							months={2}
							direction='horizontal'
							showDateDisplay={false}
							staticRanges={getStaticRanges()}
						/>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</div>
	)
}
DateRangePicker.propTypes = {
	/**
	 * The initial selected range date
	 */
	startDate: propTypes.instanceOf(moment).isRequired,
	/**
	 * The final selected range date
	 */
	endDate: propTypes.instanceOf(moment).isRequired,
	/**
	 * Callback executed when the range has changed
	 */
	onChangeRange: propTypes.func.isRequired,
	/**
	 * The text field variant value
	 */
	variant: propTypes.oneOf(['filled', 'outlined']),
	/**
	 * The predefined range shown in the lateral panel
	 */
	predefinedRanges: propTypes.arrayOf(
		propTypes.shape({
			label: propTypes.string.isRequired,
			startDate: propTypes.instanceOf(moment).isRequired,
			endDate: propTypes.instanceOf(moment).isRequired
		})
	),
	/**
	 * The color to use in the text and icons
	 */
	textColor: propTypes.string
}
DateRangePicker.defaultProps = {
	startDate: moment(),
	endDate: moment(),
	onChangeRange: () => console.warn('No [onChangeRange] callback defined'),
	variant: 'outlined',
	predefinedRanges: undefined,
	textColor: 'rgba(0, 0, 0, 0.87)'
}
export default DateRangePicker

const useStyles = makeStyles((theme) => ({
	text: {
		color: ({ textColor }) => `${textColor}`
	}
}))
