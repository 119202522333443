/**Import react select */
import React, { Fragment, useState } from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Select from 'react-select'

import LateralPanel from '../../common/lateralPanel/LateralPanel'
import { panelLateralServicesStyles } from '../../../resources/styles/PanelLateralServicesStyles'

/** Material UI import section */
import { Fab, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Clear'
import SaveIcon from '@material-ui/icons/Save'

import iconFailureDoor from '../../../resources/images/failureWarranty/door.svg'
import { Skeleton } from '@material-ui/lab'
import TextBox from '../../common/TextBox'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'
/**
 * Spare part lateral panels
 * @param {*} props
 */
const AddSparePartPanel = (props) => {
	const classes = panelLateralServicesStyles()
	const { handleSubmit, control, errors, register } = useForm()
	const [isManually, setIsManually] = useState(false)
	const [sparePartModel, setSparePartModel] = useState(null);

	const onSubmit = (sparePart) => {
		props.onAddSparePart(sparePartModel)
	}

	/**On change properties for the spare part */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setSparePartModel({
			...sparePartModel,
			[name]: value
		})
	}

	const onUpdateDescription = (event) => {
		let { label, value } = event
		setSparePartModel({
			...sparePartModel,
			Description: value,
			Code: label,
		})
	}

	const canBeSaved = () => {
		if (isNullOrEmpty(sparePartModel ? sparePartModel.Code : '')
			|| isNullOrEmpty(sparePartModel.Description)
			|| isNullOrEmpty(sparePartModel.Quantity)
			|| sparePartModel.Quantity <= 0) return true
		else return false
	}

	const onChangeToManually = (event) => {
		setIsManually(event.target.checked)
	}

	return (
		<LateralPanel open={props.open} onClose={props.onClose}>
			<form >
				<PanelHeader
					onClose={props.onClose}
					isSaving={props.isSaving || canBeSaved()}
					title={props.title}
					onSave={onSubmit}
					handleSubmit={handleSubmit}
				/>
				<div className={classes.panelContent}>
					{props.isSaving ? (
						<SavingSkeleton />
					) : (
						<Fragment><br />
							{sparePartModel && sparePartModel.Code && !isManually && <Typography variant='caption'>Code *</Typography>}
							{!isManually && <Select
								placeholder={'Code*'}
								options={props.options}
								onChange={onUpdateDescription}
								styles={{
									menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
									menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
									menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
								}}
								menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
								menuPlacement={'auto'}
							/>}
							{isManually && <TextBox
								active={true}
								required={true}
								disabled={false}
								fullWidth
								name={"Code"}
								variant="outlined"
								margin="dense"
								label={"Code"}
								defaultValue={sparePartModel ? sparePartModel.Code : ''}
								onChange={onPropertyChange}
								value={sparePartModel ? sparePartModel.Code : ""}
								register={register({ required: { value: true, message: 'The code is required' } })}
								errors={errors}
								control={control}
							/>}
							<FormControlLabel
								control={<Checkbox onChange={onChangeToManually} />}
								label="Switch to manual code entry mode"
							/>
							<TextBox
								active={true}
								required={true}
								disabled={false}
								fullWidth
								name={"Description"}
								variant="outlined"
								margin="dense"
								label={"Description"}
								defaultValue={sparePartModel ? sparePartModel.Description : ''}
								onChange={onPropertyChange}
								value={sparePartModel ? sparePartModel.Description : ""}
								register={register({ required: { value: true, message: 'The description is required' } })}
								errors={errors}
								control={control}
							/>
							<TextBox
								type="number"
								active={true}
								required={true}
								disabled={false}
								fullWidth
								name={"Quantity"}
								variant="outlined"
								margin="dense"
								label={"Quantity"}
								onChange={onPropertyChange}
								value={sparePartModel ? sparePartModel.Quantity : ""}
								register={register({ required: { value: true, message: 'The quantity is required' } })}
								errors={errors}
								control={control}
							/>
						</Fragment>
					)}
				</div>
			</form>
		</LateralPanel>
	)
}
AddSparePartPanel.propTypes = {
	open: propTypes.bool.isRequired,
	onSaveSparePart: propTypes.func,
	isSaving: propTypes.bool
}
AddSparePartPanel.defaultPanel = {
	open: false,
	isSaving: false,
	onSaveSparePart: () => console.warn('No [onSaveSparePart] callback defined')
}
export default AddSparePartPanel

/**
 * Panel header
 * @param {*} props
 */
const PanelHeader = (props) => {
	const { title } = props
	const { onClose, isSaving, onSave, handleSubmit } = props
	const classes = panelLateralServicesStyles()

	return (
		<div className={classes.panelHeader}>
			<div className={classes.containerHeader}>
				<div className={classes.containerIcon}>
					<Fab
						size='medium'
						className={classes.faultWarrantyIconSelected}
						disabled={true}
					>
						<img
							src={iconFailureDoor}
							alt='warranty-icon'
							className={classes.faultWarrantyIcon}
						/>
					</Fab>
				</div>
				<div className={classes.containerText}>
					<Typography
						gutterBottom
						variant='subtitle1'
						className={classes.title}
					>
						{title}
					</Typography>
				</div>
			</div>
			<div className={classes.grow} />
			<Fab
				className={classes.buttonSaved}
				type='submit'
				disabled={isSaving}
				onClick={handleSubmit(onSave)}
			>
				<SaveIcon />
			</Fab>
			<Fab className={classes.buttonClose} onClick={onClose}>
				<DeleteIcon />
			</Fab>
		</div>
	)
}

const SavingSkeleton = () => {
	return (
		<Fragment>
			<Skeleton animation='wave' height={60} />
			<Skeleton animation='wave' height={60} />
			<Skeleton animation='wave' height={60} />
		</Fragment>
	)
}
