/**
 * Method to get failure warranty
 */
export function GetFaultWarranty() {
	let types = []
	types.push({ value: 1, label: 'Capillary tube' })
	types.push({ value: 2, label: 'Clogged System' })
	types.push({ value: 3, label: 'Compressor Components' })
	types.push({ value: 4, label: 'Compressor Replacement' })
	types.push({ value: 5, label: 'Condenser' })
	types.push({ value: 6, label: 'Components' })
	types.push({ value: 7, label: 'Cooler replacement' })
	types.push({ value: 8, label: 'Door adjustment' })
	types.push({ value: 9, label: 'Drain' })
	types.push({ value: 10, label: 'Evaporator' })
	types.push({ value: 11, label: 'False contacts - Electrical' })
	types.push({ value: 12, label: 'Freight' })
	types.push({ value: 13, label: 'Gas leak' })
	types.push({ value: 14, label: 'Heater VFS24' })
	types.push({ value: 15, label: 'KIT CO2 (NO LABOR)' })
	types.push({ value: 16, label: 'KIT CO2 Canada' })
	types.push({ value: 17, label: 'LED (light  /driver)' })
	types.push({ value: 18, label: 'Low charge' })
	types.push({ value: 19, label: 'Motors - Electrical' })
	types.push({ value: 20, label: 'No Failure' })
	types.push({ value: 21, label: 'Noise' })
	types.push({ value: 22, label: 'Switch - Electrical' })
	types.push({ value: 23, label: 'Temp control - Electrical' })
	return types
}

/**
 * Method to get service type
 */
export function GetServiceType() {
	let types = []
	types.push({ value: 1, label: 'FOR COCA COLA (CC)' })
	types.push({ value: 2, label: 'MICRO MARKET (MM)' })
	return types
}
export function GetParcelName() {
	let types = []
	types.push({ value: 1, label: 'FEDEX' })
	types.push({ value: 2, label: 'UPS' })
	types.push({ value: 3, label: 'SAP' })
	return types
}

export function GetWarehouse() {
	let types = []
	types.push({ value: 0, label: 'No Selected' })
	types.push({ value: 1, label: 'Mexico' })
	types.push({ value: 2, label: 'USA' })
	return types
}

/**
 * Method to get all carriers selected
 */
export function GetCarriers() {
	let types = []
	types.push({ value: 0, label: ' ' })
	types.push({ value: 1, label: 'FedEx' })
	types.push({ value: 2, label: 'UPS' })
	types.push({ value: 3, label: 'DHL' })
	types.push({ value: 4, label: 'Estes' })
	types.push({ value: 5, label: 'RL-Carriers' })
	types.push({ value: 6, label: 'Saia-Freight' })
	types.push({ value: 7, label: 'aaacooper' })
	types.push({ value: 8, label: 'ABF freight' })
	types.push({ value: 9, label: 'Averitt Express' })
	types.push({ value: 10, label: 'Estes Express' })
	types.push({ value: 11, label: 'Old Dominion Freight' })
	types.push({ value: 12, label: 'R&L Carriers IncSAIA' })
	types.push({ value: 13, label: 'Southeastern freight lines' })
	types.push({ value: 14, label: 'XPO logistics' })
	types.push({ value: 16, label: 'Daylight Transport' })
	types.push({ value: 17, label: 'Roadrunner Freight' })
	types.push({ value: 15, label: 'Other' })
	return types
}

/**
 * Method to gett status traking selected
 */
export function GetStatusTracking() {
	let types = []
	types.push({ value: '', label: 'No status' })
	types.push({ value: 'Transit', label: 'Transit' })
	types.push({ value: 'Delivered', label: 'Delivered' })
	types.push({ value: 'Cancelled', label: 'Cancelled' })
	return types
}

/**
 * Method to get documents type to requeres add supplier
 */
export function GetDocumentType() {
	let types = []
	types.push({ value: 4, label: 'W8' })
	types.push({ value: 1, label: 'W9' })
	types.push({ value: 2, label: 'Proof of Insurance' })
	types.push({ value: 5, label: 'Others' })
	return types
}

/**
 * Method to get warranty type
 */
export function GetWarrantyType() {
	let types = []
	types.push({ value: 1, label: 'Warranty' })
	types.push({ value: 2, label: 'Sale' })
	return types
}
