/**
 * Get the header for an authorized request
 * @param {*} user
 */
export function getAuthorizedConfiguration(user) {
    if(user){
        let configuration = {
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`
            }
        };
        return configuration;
    }
}

/**
 * Get the header for an authorized request 
 * and add parameters like object
 * @param {*} user
 * @param {*} params
 */
export function getAuthorizedConfigurationParameters(user, data) {
    if(user){
        let configuration = {
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.access_token}`
            },
            data
        };
        return configuration;
    }
}