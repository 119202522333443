/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
/**Import Material Ui section */
import { Grid, Typography } from '@material-ui/core'

/**Import components section */
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'

/**Import resources section */
import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'
import Authorization from '../../common/AuthorizationEvaluation'

import {
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	PORTAL_ADMINISTRATOR,
	DISPATCHER,
	IMBERA_TECHNICIAN,
	SPARE_PARTS_IMBERA_USA,
	SALES_MANAGER,
	SALES_REPRESENTATIVE_MANAGER,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN
} from '../../../resources/RolesEnum'
const ContactForm = (props) => {
	const { onSaveService, isSavingService, isAuthorizadoForEditedData, isMobile, userProfile } = props
	const [contactForm, setContactForm] = useState(null)
	useEffect(() => {
		setContactForm(props.service)
	}, [props.service])

	/**Custom hooks an styles */
	const classes = createServicesStyle()

	const updateData = () => {
		if (onSaveService) {
			onSaveService(contactForm)
		}
	}

	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setContactForm({
			...contactForm,
			[name]: value
		})
	}

	const renderTextBox = (key, item, label, isActive, isDisabled, size = 6, multiline = false, rows = 0, required) => {
		let value = item ? (item[key] ? item[key] : '') : ''
		return (
			<Grid item xs={size}>
				<TextBox
					key={key}
					active={isActive}
					disabled={isDisabled || isAuthorizadoForEditedData}
					fullWidth
					multiline={multiline}
					name={key}
					rows={rows}
					variant='outlined'
					margin='dense'
					label={label}
					onChange={onPropertyChange}
					value={value}
				/>
			</Grid>
		)
	}

	return (
		<>
			<Grid container spacing={2}>
				{contactForm &&
					contactForm.ServiceDescription &&
					renderTextBox('ServiceDescription', contactForm, 'COMMENTS', true, false, 12, true, 4, false)}
				{contactForm && !contactForm.ServiceDescription && (
					<Typography className={classes.textColor}>
						{' To add comments Click into the comment history tab below'} <br />
						<br />
					</Typography>
				)}

				<Grid item xs={12}>
					<Typography className={classes.title}>{'CONTACT INFORMATION'}</Typography>
				</Grid>
				{renderTextBox('ContactName', contactForm, 'Contact name', true, false, isMobile ? 12 : 6, false, 0, true)}
				{renderTextBox('ContactPhone', contactForm, 'Contact phone (mobile)', true, false, isMobile ? 12 : 6, false, 0, true)}
				{renderTextBox('SiteContactName', contactForm, 'Site contact name', true, false, isMobile ? 12 : 6, false, 0, true)}
				{renderTextBox('SiteContactPhone', contactForm, 'Site contact phone (mobile)', true, false, isMobile ? 12 : 6, false, 0, true)}
				{renderTextBox('ContactEmail', contactForm, 'Emails to send notifications e.g. (mail@mail.com,)', true, false, 12, false, 4, true)}
				{renderTextBox('StatusComments', contactForm, 'Status', true, false, 12, true, 4, true)}
				{renderTextBox('DispatchStatus', contactForm, 'Dispatch Status', true, false, 9, true, 0, true)}
				<Authorization
					profile={userProfile}
					allowedRoles={[
						SERVICE_PROVIDER_TECHNICIAN,
						SERVICE_PROVIDER_MANAGER,
						PORTAL_ADMINISTRATOR,
						DISPATCHER,
						IMBERA_TECHNICIAN,
						SPARE_PARTS_IMBERA_USA,
						SALES_MANAGER,
						SALES_REPRESENTATIVE_MANAGER,
						CUSTOMER_MANAGER,
						CUSTOMER_TECHNICIAN
					]}
				>
					<Grid item xs={3} className={classes.updateButton}>
						<ButtonSaved onSavedClick={updateData} title='Update' isSaving={isSavingService} />
					</Grid>
				</Authorization>
			</Grid>
		</>
	)
}

ContactForm.propTypes = {
	/**Determinate if update service data */
	isSavingService: propTypes.bool.isRequired,
	/**Function invoke callback for update contact info */
	onSaveService: propTypes.func.isRequired,
	isMobile: propTypes.bool.isRequired
}
ContactForm.defaultProps = {
	isSavingService: false,
	isMobile: false,
	onSaveService: () => console.warn('Callback [onSaveService] no defined')
}

export default ContactForm
