/**Import react section */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import propTypes from 'prop-types'
import { useForm } from "react-hook-form";
/**Import Material Ui section */
import { Grid, Typography, Chip } from '@material-ui/core'
/**Import Material UI Section */
import DialogActions from '@material-ui/core/DialogActions';

/**Import styles section */
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import { getOptionsReactSelect } from '../../../../store/helpers/FilterHelper'
import { toSafeObject } from '../../../../store/helpers/StateHelper'
/**Import components section */
import ButtonConfirm from '../../../common/Buttons/ButtonConfirm'
import ReactSelectAvatar from '../../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'

const ReassignedProvider = (props) => {
    /**Props section */
    const { isSavingService, isAuthorizadoForEditedData } = props;
    const { onReasisngedServiceProviderr } = props;

    /**Custom hooks an styles */
    const classes = createServicesStyle();
    const { register, errors, control, handleSubmit } = useForm();
    const [assignementModel, setAssignementModel] = useState({serviceProvider: props.serviceProvider});

    /**Get option from store redux */
    const allServiceProviders = useSelector((state) => toSafeObject(state.serviceProviders.get('serviceProviders')))
    /**Map the options to show in the selector */
    let seviceProviderOptions = getOptionsReactSelect(allServiceProviders)

    /**Verify if can be sabed */
    const canBeSaved = () => {
        if (assignementModel ? (assignementModel.serviceProvider === null) : true) return false;
        return true;
    }

    /** Invoke when select property change*/
    const onSelectChange = (name, option) => {
        setAssignementModel({
            ...assignementModel,
            [name]: option
        })
    }

    /**
     * function to assigned service
     */
    const reasisngedServiceProviderr = () => {
        if (onReasisngedServiceProviderr) {
            onReasisngedServiceProviderr(assignementModel);
        }
    }

    return (
        <Grid item xs={12}>
            <Typography className={classes.title}>{"REASSIGN SERVICE PROVIDER"}</Typography>
            <ReactSelectAvatar
                id='serviceProvider'
                name="serviceProvider"
                placeholder={"SERVICE PROVIDER"}
                options={seviceProviderOptions}
                onChange={onSelectChange}
                formatOptionLabel={formatOptionLabel}
                value={(assignementModel.serviceProvider && !!assignementModel.serviceProvider.Code) ? seviceProviderOptions.find(x => x.value === assignementModel.serviceProvider.Code) : null}
                register={(assignementModel && !!assignementModel.serviceProvider) ? register({ name: 'serviceProvider' }, { required: { value: false } }) : register({ name: 'serviceProvider' }, { required: { value: true, message: "The provider is required" } })}
                errors={errors}
                control={control}
                isDisabled={!isAuthorizadoForEditedData}
            /><br />

            {isAuthorizadoForEditedData && <DialogActions>
                <ButtonConfirm
                    isSaving={isSavingService || !canBeSaved()}
                    onSavedClick={handleSubmit(reasisngedServiceProviderr)} title='CONFIRM' />
            </DialogActions>}
        </Grid>
    )
}

const formatOptionLabel = ({ value, label, avatarUrl, isAvatarFromIdentity }) => (
    <div>
        <Chip
            id={value}
            size="small"
            avatar={
                <AvatarCustomSize
                    avatarUrl={avatarUrl}
                    name={label}
                    isAvatarFromIdentity={isAvatarFromIdentity}
                />}
            label={label}
            clickable
            style={{ color: "white", background: "#5357DC" }}
        />
    </div>
);


ReassignedProvider.propTypes = {
    isSavingService: propTypes.bool.isRequired,
    /**Function invoke callback for assigned service */
    onChangeRepresentanteSales: propTypes.func.isRequired,
    isAuthorizadoForEditedData: propTypes.bool.isRequired,
}
ReassignedProvider.defaultProps = {
    isSavingService: false,
    isAuthorizadoForEditedData: false,
    onReasisngedServiceProviderr: () =>
        console.warn('Callback [onReasisngedServiceProviderr] no defined'),
}


export default ReassignedProvider

