/**
 * Get currency format to a number
 *
 * @param {number} amount
 */
export const currencyFormat = (amount) => {
	let result = amount
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})
	return `${result && formatter.format(result)}`
}
