/**Import react sections */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'

/**Import components section */
import Toaster from '../common/Toaster'
import EditCustomerPanel from './components/EditCustomerPanel'
import UploadImgCropDialog from '../common/cropDialog/UploadImgCropDialog'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'
import EditUserPanel from './components/EditUserPanel'

/**Import store section */
import { isNullOrEmpty } from '../../store/helpers/StringHelper';
import {
    uploadLogoAccount, saveExternalUser,
    uploadLogoExternalUser, SET_ROLES_IN_PROFILE
} from '../../store/customers/customersActions'
import {
    addUserInRole,
    removeUserFromRole,
} from '../../store/users/UsersActions'

/**Import helpers section */
import { isUserInRole } from '../../store/helpers/UserHelper'

const Edit = (props) => {
    /**Hooks sections */
    const dispatch = useDispatch()

    /**Functions props section */
    const { onClosedEditLateralPanel, onUpdateCustomer,
        onSetHandleOpenCloseModalUploadImg,
        onRemoveUserFromAccount, onSetPasswordDialogConfiguration,
        onEditPanelUserConfig,
        onSetUserActiveExpiredDialog,
        userActiveExpiredConfing,
        onActiveUserExpired,
        onSetResendPasswordDialog,
        onResendPasswordUser,
        onActiveNotifications,
        isEnable } = props

    /**object props section */
    const { customerEdit, userInCustomerEditConfig, passwordDialogConfiguration, resendPasswordDialog } = props

    /**boolean props section */
    const { isSaving, handleOpenModalUploadImg, isModeCustomerUser, isValidatingPassword } = props

    /**Local store from this component */
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [logoFileBase64, setLogoFileBase64] = useState("");
    const [userInCustomerModel, setUserInCustomerModel] = useState(null);

    useEffect(() => {
        setUserInCustomerModel(userInCustomerEditConfig ? userInCustomerEditConfig.user : null)
    }, [userInCustomerEditConfig])


    const onUserInCustomer = (roles, userName) => {
        dispatch({
            type: SET_ROLES_IN_PROFILE,
            roles: roles,
            userName: userName,

        })
    }

    /**
    * Handler to edit an user.
    * Execute async action to edit an user.
    * @param {object} user
    */
    const editUserHandler = user => {
        dispatch(saveExternalUser(user))
            .then(() => {
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error updating user information", variant: 'error' })
            });
    };

    /**Set img base 64 in the local store */
    const onSetLogoFileBase64 = (fileBase64) => {
        setLogoFileBase64(fileBase64);
    };

    /**Upload image crop */
    const onUploadLogoUser = (imageBase64) => {
        let token = isModeCustomerUser ? (userInCustomerModel && userInCustomerModel.Id) : (customerEdit && customerEdit.customer.Token);
        let tokenManager = isModeCustomerUser ? (userInCustomerModel && userInCustomerModel.Token) : ''

        return dispatch(isModeCustomerUser ? uploadLogoExternalUser(token, imageBase64, tokenManager) : uploadLogoAccount(token, imageBase64))
            .then((representative) => {
                onSetHandleOpenCloseModalUploadImg(representative);
            })
            .catch(() => {
                setToasterOptions({ open: true, message: "An error was generated while updating the logo", variant: 'error' })
                console.error("Error to upload image crop");
            });
    };

    /**Add role to user and remove roles */
    const onToggleUserRole = (roleName, roleType) => {
        let token = userInCustomerModel && userInCustomerModel.Token;
        let roles = userInCustomerModel && userInCustomerModel.Roles;
        let userName = userInCustomerModel && userInCustomerModel.UserName;
        if (isUserInRole(roleName, roles)) {
            return dispatch(removeUserFromRole(token, roleName))
                .then(roles => {
                    onUserInCustomer(roles, userName)
                    setUserInCustomerModel({
                        ...userInCustomerModel,
                        "Roles": roles
                    })
                })
                .catch((error) => {
                    setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to remove role from user', variant: 'warning' })
                    console.error("Failed to remove role");
                });
        }
        else {
            return dispatch(addUserInRole(token, roleName, roleType))
                .then(roles => {
                    onUserInCustomer(roles, userName)
                    setUserInCustomerModel({
                        ...userInCustomerModel,
                        "Roles": roles
                    })
                })
                .catch((error) => {
                    setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add role', variant: 'error' })
                    console.error("Failed to assign role", error);
                });
        }
    }


    return (
        <>
            {
                (customerEdit && customerEdit.opened) &&
                <EditCustomerPanel
                    customer={customerEdit.customer}
                    onCloseButtonClick={onClosedEditLateralPanel}
                    onSaveButtonClick={onUpdateCustomer}
                    isSaving={isSaving}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                />
            }
            <UploadImgCropDialog
                open={handleOpenModalUploadImg}
                onClose={onSetHandleOpenCloseModalUploadImg}
                fileBase64={logoFileBase64}
                isSaving={isSaving}
                onUploadImagen={onUploadLogoUser}

            />
            {
                (userInCustomerEditConfig ? userInCustomerEditConfig.opened : false) &&
                <EditUserPanel
                    user={userInCustomerModel}
                    isSaving={isSaving}
                    onCloseButtonClick={onEditPanelUserConfig}
                    onSaveButtonClick={editUserHandler}
                    onSetLogoFileBase64={onSetLogoFileBase64}
                    onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                    onToggleUserRole={onToggleUserRole}
                    onActiveNotifications={onActiveNotifications}
                    isEnable={isEnable}
                />
            }

            {
                (passwordDialogConfiguration && passwordDialogConfiguration.opened) &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={passwordDialogConfiguration.opened}
                    onClickButtonConfirm={onRemoveUserFromAccount}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetPasswordDialogConfiguration}
                    message1={`This action will remove the access of ${passwordDialogConfiguration.user ? passwordDialogConfiguration.user.Name : ''} to this portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                (resendPasswordDialog && resendPasswordDialog.opened) &&
                <ConfirmPasswordDialog
                    title={'Confirm operation'}
                    open={resendPasswordDialog.opened}
                    onClickButtonConfirm={onResendPasswordUser}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetResendPasswordDialog}
                    message1={`The password will be send to ${resendPasswordDialog.user ? resendPasswordDialog.user.Name : ''}. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {
                (userActiveExpiredConfing && userActiveExpiredConfing.opened) && <ConfirmPasswordDialog
                    title={'Confirm activate expired user'}
                    open={userActiveExpiredConfing.opened}
                    onClickButtonConfirm={onActiveUserExpired}
                    isSaving={isValidatingPassword || isSaving}
                    onClose={onSetUserActiveExpiredDialog}
                    message1={`This action will activate the expired ${userActiveExpiredConfing.user ? userActiveExpiredConfing.user.Name : ''} user in the portal. Are you sure to proceed?`}
                    message2="Enter the password to confirm the operation"
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }



        </>
    )
}

Edit.propTypes = {
    /**
     * invoke update close lateral panel edit customer
     */
    onClosedEditLateralPanel: propTypes.func,
    /**
     * Resend password to user
     */
    onResendPasswordUser: propTypes.func.isRequired,
}


Edit.defaultProps = {
    isSaving: false,
    onClosedEditLateralPanel: () =>
        console.warn('Callback [onClosedEditLateralPanel] no defined'),
    onResendPasswordUser: () =>
        console.warn('Callback [onResendPasswordUser] no defined'),
}

export default Edit
