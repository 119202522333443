/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'

/** MapWrapper */
import {  PositionMapDefault } from '../../../store/helpers/AppConstants';
import MapWrapper from './MapWrapper';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyB-XC24Vm9GJKhQ9ZFqVUvyIPj2HVCumSQ");
Geocode.enableDebug();

/**
 * Conponent to show Map gelocalitaion
 * @param {*} props 
 */
const MapComponent = (props) => {
    const { directions} = props;
    const[markerPosition] = useState({lat:PositionMapDefault.latitude, lng: PositionMapDefault.longitude})

    /**
     * Render component
     */
    return (
        <MapWrapper 
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-XC24Vm9GJKhQ9ZFqVUvyIPj2HVCumSQ&v=3.exp&libraries=geometry,drawing,places&sensor=false"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `calc(100vh - ${150}px)` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            directions={directions}
            markerPosition={markerPosition }
        />
    )
}

MapComponent.propTypes = {
    /**Function invoke callback for set properties in the state local from address*/
    onChangeAddress: propTypes.func,
    onChangePosition:propTypes.func,
}
MapComponent.defaultProps = {
    directions:[],
    onChangeAddress: () =>
        console.warn('Callback [onChangeAddress] no defined'),
    onChangePosition: () =>
        console.warn('Callback [onChangePosition] no defined'),
        
}
export default MapComponent
