/**Import react section */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
/**Import MaterialUi section */
import { Grid } from '@material-ui/core'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import ValidatedTextField from '../../common/hooksForms/ValidatedTextField'

/**Import store section */
import { generatePassword } from '../../../store/helpers/PasswordHelper'

const SendInvitationLateralPanel = (props) => {
    let { code } = useParams()
    const { handleSubmit, errors, control } = useForm()
    /**Local store this component */
    const { onSaveButtonClick, onCloseButtonClick, isSaving } = props;
    const [passwordGenerated, setPasswordGenerated] = useState('')

    useEffect(() => {
        var finalPassword = '';

        var consecutiveCharacters = 0;			// Counter of the characters we've got
        var temporaryCharacter = '';

        var minimumNumberLowercaseLetters = 1;			// In this and the following variables we define how many 
        var minimumNumberCapitalLetters = 1;			// Characters of each type we want in each
        var minimumNumberNumbers = 1;
        var symbolNumber = 1;

        var lowercaseLettersAchieved = 0;
        var uppercaseLettersAchieved = 0;
        var numbersObtained = 0;
        var symbolsObtained = 0;
        var tamanyoPassword = 9;			// We define the size of our password
        var arrayCaracteres = [];             // Array to store characters temporarily
        for (var i = 0; i < tamanyoPassword; i++) {		// We initialize the array with the null value
            arrayCaracteres[i] = null;
        }
        let generatePaswordString = generatePassword(lowercaseLettersAchieved, minimumNumberLowercaseLetters,
            temporaryCharacter, tamanyoPassword, arrayCaracteres, consecutiveCharacters,
            uppercaseLettersAchieved, minimumNumberCapitalLetters, numbersObtained, minimumNumberNumbers,
            symbolsObtained, symbolNumber, finalPassword)
        setPasswordGenerated(generatePaswordString)
    }, [])

    const onSave = (provider) => {
        provider.Password = passwordGenerated
        if (props.onSaveButtonClick) {
            onSaveButtonClick(provider, code)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Send invitation user"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={true}
                onSaveButtonClick={handleSubmit(onSave)}
                isSaving={isSaving}
            />
            <div style={{ padding: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <ValidatedTextField
                            autoFocus
                            name="Email"
                            label="Email"
                            margin="dense"
                            rules={{
                                required: { value: true, message: 'The email is required' },
                                pattern: {
                                    value: /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Enter a valid e-mail address",
                                },
                            }}
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                        <ValidatedTextField
                            name="Name"
                            label="Name"
                            margin='dense'
                            rules={{ required: 'The name is required' }}
                            errors={errors}
                            defaultValue={''}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </div>

        </LateralPanel>
    )
}

/**Proptypes this component */
SendInvitationLateralPanel.propTypes = {
    /**
	 * On close lateral menu
	 */
    onCloseButtonClick: propTypes.func.isRequired,
    /**
    * Invoke function for add new user in service provider
    */
    onSaveButtonClick: propTypes.func.isRequired,
    /**
    * Determinate is saving when add new service provider
    */
    isSaving: propTypes.bool.isRequired,
}


SendInvitationLateralPanel.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    isSaving: false
}

export default SendInvitationLateralPanel
