/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'

/**Import components section */
import EquipmentsList from '../../services/components/EquipmentsList'
import EquipmentSparePartList from '../../spareParts/components/EquipmentSparePartList'
/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useStyles'
import { currencyFormat } from '../../../store/helpers/AmountHelper'
import {
	GetDescriptionAmount,
	GetIconAmount,
	GetNameAmount
} from '../../../store/helpers/StatusHelper'

/**Import Material Ui section section */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import MoneyIcon from '@material-ui/icons/AttachMoney'

/** Import material UI */
import {
	Typography,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	ExpansionPanel,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Grid,
	Chip,
	TextField,
	Fab
} from '@material-ui/core'

/**import actions section */
import { REMOVE_SERVICE_IN_PAYMENT } from '../../../store/paymentrequests/paymentRequestActions'
/**
 * View create desktop
 */
const ExpansionPanelService = (props) => {
	const {
		service,
		updateAmountItem,
		canBeEdit,
		onShowWarranty,
		onCommentsServiceChange,
		isPaymentRequestAuthorizerCanEdit,
		openCloseDialogPassword,
		isAuthorizerCanRemoveServices,
		onUpdateSerieInService
	} = props
	const classes = useCreateDesktopStyle()
	const [expanded, setExpanded] = React.useState(false)
	const [identifierExpanded, setIdentifierExpanded] = useState([])
	/**
	 * Hander to expander panel
	 * @param {*} event
	 */
	const handleChange = (event) => {
		let tagName = event.target.tagName
		if (tagName !== 'SPAN') {
			event.stopPropagation()
			setExpanded(!expanded)
		}
	}
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(!open)
	}
	return (
		<ExpansionPanel key={service.Token} expanded={expanded} onChange={handleChange}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='panel1a-content'
				className={expanded ? classes.headerPanelOpen : classes.headerPanelClose}
			>
				<Typography className={classes.titleSerie}>
					{'Service Ticket: '} {service.Ticket}
					{'  | '}{' '}
					{`RETAILER: ${service.Retailer ? service.Retailer.Name : ''} ${
						service.Subcategory !== null ? ' | Subcategory: ' + service.Subcategory : ''
					}`}
				</Typography>
				<div className={classes.flexGrow}></div>
				<Typography className={classes.title}>
					<strong>{`TOTAL ${currencyFormat(service.TotalAmounts)}`}</strong>
				</Typography>
				{(canBeEdit ||
					isPaymentRequestAuthorizerCanEdit ||
					isAuthorizerCanRemoveServices) && (
					<Chip
						className={classes.bottonRemove}
						icon={<DeleteIcon />}
						size='small'
						label='Remove'
						disabled={props.isSavingService}
						onClick={() => openCloseDialogPassword(service, REMOVE_SERVICE_IN_PAYMENT)}
					/>
				)}
			</ExpansionPanelSummary>

			<ExpansionPanelDetails>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<EquipmentsList
							equipments={service.Equipments}
							isAddingSeries={false}
							isSavingSeries={false}
							isLoading={false}
							isAuthorizadoForEditedData={true}
							modeView='SPARE_PART_REQUETS_SHOW'
							CanBeEdited={false}
							IsPayment={true}
							CanBeAddSubCategoria={!canBeEdit}
							onShowWarranty={onShowWarranty}
							onUpdateSubCategoryInSerivce={onUpdateSerieInService}
							expandablePanelContent={
								<EquipmentSparePartList
									isLoading={false}
									mode={'MODE_SHOW'}
									IsPayment={true}
									identifierExpanded={identifierExpanded}
									setIdentifierExpanded={setIdentifierExpanded}
								/>
							}
							handleClick={handleClick}
							open={open}
						/>
						<List className={classes.root}>
							<ListItem>
								<ListItemText
									primary={
										<TextField
											label={'Comments'}
											key={'Comments'}
											disabled={!canBeEdit}
											fullWidth
											multiline={true}
											rows={3}
											defaultValue={service.CommentProvider}
											onBlur={(event) =>
												onCommentsServiceChange(service, event.target.value)
											}
											margin='dense'
											variant='outlined'
										/>
									}
								/>
							</ListItem>
							<ListItem divider>
								<ListItemText
									primary={
										<Typography className={classes.title}>
											<strong>{'SERVICE AMOUNTS'}</strong>
										</Typography>
									}
								/>
							</ListItem>
							{service.ServiceAmounts.map((mount) => {
								return (
									<ListItem divider key={mount.Token}>
										<ListItemAvatar>
											<Fab
												size='small'
												className={classes.amountIconSelected}
												disabled={true}
											>
												<img
													src={GetIconAmount(mount.Description)}
													alt='warranty-icon'
													className={classes.amountIcon}
												/>
											</Fab>
										</ListItemAvatar>
										<ListItemText
											primary={GetNameAmount(mount.Description)}
											secondary={GetDescriptionAmount(mount.Description)}
										/>
										<ListItemSecondaryAction>
											{canBeEdit && (
												<div className={classes.headerIconAmount}>
													<MoneyIcon className={classes.iconAmount} />
													<TextField
														key={'Amount'}
														defaultValue={mount.Amount}
														onBlur={(event) =>
															updateAmountItem(
																service.Token,
																mount.Token,
																event.target.value
															)
														}
														margin='dense'
														variant='outlined'
														type='number'
														step={0.5}
														inputProps={{
															style: {
																fontSize: 12
															}
														}}
													/>
												</div>
											)}
											{!canBeEdit && `${currencyFormat(mount.Amount)}`}
										</ListItemSecondaryAction>
									</ListItem>
								)
							})}
							<ListItem>
								<ListItemAvatar>
									<Fab
										size='small'
										className={classes.amountIconSelected}
										disabled={true}
									>
										<img
											src={GetIconAmount('TOTAL')}
											alt='warranty-icon'
											className={classes.amountIcon}
										/>
									</Fab>
								</ListItemAvatar>
								<ListItemText
									primary='TOTAL'
									secondary={GetDescriptionAmount('TOTAL')}
								/>
								<ListItemSecondaryAction>
									<strong>{`${currencyFormat(service.TotalAmounts)}`}</strong>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	)
}

ExpansionPanelService.propTypes = {
	expanded: propTypes.bool.isRequired,
	service: propTypes.shape({
		Ticket: propTypes.string.isRequired
	}),
	handleChange: propTypes.func.isRequired,
	/**Function invoke callback for show warranty for equipment */
	onShowWarranty: propTypes.func.isRequired
}
ExpansionPanelService.defaultProps = {
	expanded: false,
	canBeEdit: false,
	service: {
		Ticket: '',
		Equipments: []
	},
	handleChange: () => console.warn('Callback [handleChange] no defined'),
	onShowWarranty: () => console.warn('Callback [onShowWarranty] no defined')
}

export default ExpansionPanelService
