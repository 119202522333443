/**Import react section */
import React from 'react'
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import sparePartTableConfig from '../resources/SparePartTableConfig'
/**Import Material Ui section */
import { Typography, Grid } from '@material-ui/core'
import { GetWarehouseDescription } from '../../../store/helpers/SparePartHelper'
import { ServiceCardMobileStyle } from '../../../resources/styles/mobile/ServiceCardMobileStyle'
import CardComponent from '../../common/mobile/CardComponent'
const SparePartsList = (props) => {
	const { sparParts, isLoading, isModeCard } = props
	const classes = ServiceCardMobileStyle()
	/**
	 * Get the header configuration
	 */
	const getEquimpentTablaConfig = () => {
		let warehouseProperty = sparePartTableConfig.find((x) => x.dataSource === 'Warehouse')
		warehouseProperty.onRenderProperty = (item) => {
			return <Typography>{GetWarehouseDescription(item.Warehouse)}</Typography>
		}
		return sparePartTableConfig
	}

	/**Render text with name color bold */
	const onRenderText = (titleBold, title, phoneNumber) => {
		return (
			<div className={classes.flex}>
				<Typography variant='subtitle2' className={classes.titleValue}>
					<strong className={classes.titleBold}>{titleBold}</strong>
					{title}
				</Typography>
			</div>
		)
	}

	/**Render content card text */
	const onRenderContenCard = (sparePart) => {
		return (
			<>
				{sparePart.CreationDate && onRenderText('Status:', sparePart.Status)}
				{sparePart.Description && onRenderText('Description:', sparePart.Description)}
				{sparePart.NumberTracking && onRenderText('Number tracking:', sparePart.NumberTracking)}
				{sparePart.Quantity && onRenderText('Quantity:', sparePart.Quantity)}
			</>
		)
	}

	const onRenderContent = () => {
		if (isModeCard) {
			return (
				<Grid container spacing={1}>
					{sparParts.map((p) => {
						return (
							<Grid item xs={12} sm={6}>
								<CardComponent
									title={`Code: ${p.Code}`}
									onRenderContent={() => onRenderContenCard(p)}
									item={p}
									// onCardClick={onCardClick}
								/>
								<br />
							</Grid>
						)
					})}
				</Grid>
			)
		} else {
			return <DataGrid headers={getEquimpentTablaConfig()} data={sparParts} isLoading={isLoading} />
		}
	}

	return onRenderContent()
}

export default SparePartsList
