
/** Import react section **/
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'
import { v4 as uuid4 } from 'uuid'
/** Import Material UI section **/
import {
    ClickAwayListener,
    Paper,
    Popper,
    Checkbox,
    Typography
} from '@material-ui/core';

/**Import componets section */
import SearchToolbox from '../../common/SearchToolbox'
import { FilterSelectStyles } from '../../../resources/styles/ServicesListStyles'


export const PopperComponent = (props) => {
    const classes = FilterSelectStyles();
    const { settingsEl, data, onChangeSelectedOption, optionsValues, isVisibleSearch } = props;

    let open = !!settingsEl;
    /**Local storage this component */
    const [selectedOption, setSelectedOption] = useState([])
    const [query, setQuery] = useState(null);


    /**
	 * Load services list
	 */
    useEffect(() => {
        let allOptionsSaved = [];
        if (optionsValues) {
            for (let option of data) {
                let existRegister = optionsValues.includes(option.key.toString())
                if (existRegister)
                    allOptionsSaved.push(option);

            }
        }
        setSelectedOption(allOptionsSaved)
    }, [optionsValues, data])

    /**Oon change option check */
    const onChangeOptionCheck = (option) => {
        let updatesOptionList = null
        let addedStatus = selectedOption.find(
            (x) => x.key === option.key
        )
        if (addedStatus)
            updatesOptionList = selectedOption.filter(
                (o) => o.key !== option.key
            )
        else updatesOptionList = [...selectedOption, option]
        setSelectedOption(updatesOptionList)
        onChangeSelectedOption(updatesOptionList)
    }

    /**Filter options  from data the props */
    const optionsFiltered = () => {
        if (query) {
            return data.filter((filter) => {
                return (
                    filter.label.toLowerCase().trim().includes(query.toLowerCase().trim())
                    || (filter.key && filter.key.toLowerCase().trim().includes(query.trim().toLowerCase()))
                );
            });
        }
        else {
            return data;
        }
    }

    /**option selected*/
    const isSelected = (status) => selectedOption.find((x) => x.key === status.key) ? true : false

    return (
        <Popper
            anchorEl={settingsEl}
            open={open}
            placement={'bottom'}
        >
            <ClickAwayListener onClickAway={props.setSettingsEl}>
                <Paper
                    elevation={2}
                    className={classes.papperPopper}
                >
                    {
                        isVisibleSearch && <SearchToolbox
                            placeholder='Search options'
                            onChange={(value) => setQuery(value)}
                        />
                    }
                    <div className={classes.containerDataFilterSelect}>
                        {optionsFiltered && optionsFiltered().map((d) => {
                            return (
                                <div
                                    key={uuid4()}
                                >
                                    <Checkbox
                                        size='small'
                                        onChange={() =>
                                            onChangeOptionCheck(d)
                                        }
                                        checked={isSelected(d)}
                                    />
                                    <Typography
                                        variant='caption'
                                    >
                                        {d.label}
                                    </Typography>
                                </div>
                            )
                        })}
                    </div>


                </Paper>
            </ClickAwayListener>
        </Popper>
    )
}

PopperComponent.propTypes = {
	/** 
	 * Callback executed when the option change
	*/
    onChangeSelectedOption: propTypes.func.isRequired,
    /**
	 * Option values saved in url
	 */
    optionsValues: propTypes.array,
    /**
     * The data 
     */
    data: propTypes.arrayOf(
        propTypes.shape({
            key: propTypes.oneOfType([
                propTypes.string,
                propTypes.number
            ]),
            label: propTypes.oneOfType([
                propTypes.string,
                propTypes.number
            ]),
        })
    ),
    /**Determinate if search is visible for user */
    isVisibleSearch: propTypes.bool.isRequired,
}

PopperComponent.defaultProps = {
    isVisibleSearch: false,
    onChangeSelectedOption: () =>
        console.warn('Callback [onChangeSelectedOption] no defined'),

}


export default PopperComponent
