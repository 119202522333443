/**Import react section */
import React from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'

/**Import components sections */
import headerConfig from '../resources/RepresentativeTableConfig'
import SearchBox from '../../common/SearchToolbox'
import Pagination from '../../common/Pagination/Pagination'
import RenderContentIndex from '../../common/renderContentIndex/RenderContentIndex'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import LockButton from '../../serviceProviders/components/LockButton'

import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'

/**Import material UI Section */
import { Typography, ButtonGroup, Tooltip, Button, Chip, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import GridIcon from '@material-ui/icons/GridOn'
import AddIcon from '@material-ui/icons/Add'
import LockOpenMeteriaIcon from '@material-ui/icons/LockOpen'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
/**Import resources section */
import LockIcon from '../../../resources/images/lock/Lock.svg'
import LockOpenIcon from '../../../resources/images/lock/LockOpen.svg'

//Roles
import { PORTAL_ADMINISTRATOR } from '../../../resources/RolesEnum'
import AuthorizationEvaluation from '../../common/AuthorizationEvaluation'
import { hasAuthorization } from '../../../store/session/sessionActions'
/**
 * Desktop view for the list on sales representatives
 */
const DesktopView = (props) => {
	/**Functions */
	const {
		onChangePage,
		onEditSalesRepresentativeClick,
		switchViewMode,
		onAddLateralPanelConfig,
		onViewUserClick,
		onLocked,
		onDownloadReport,
		userProfile
	} = props
	/**Objects */
	const { allSalesRepresentatives, pagination, loggedUserProfile } = props
	/**bool props section */
	const { gridMode, isSaving } = props
	/**String props section */
	const { identifierCardSeleted, identifierLockSeleted } = props
	const classes = useDataGridToolbarStyle()

	/**
	 * Get the header configuration
	 */
	const getHeaderConfiguration = () => {
		let avatarProperty = headerConfig.find((x) => x.dataSource === 'avatar')
		avatarProperty.onRenderProperty = (item) => {
			return <AvatarCustomSize name={item.Name} avatarUrl={item.AvatarUrl} avatarStyle={classes.avatar} isAvatarFromIdentity={false} />
		}
		let detailsProperty = headerConfig.find((x) => x.dataSource === 'Details')

		detailsProperty.onRenderProperty = (item) => {
			return (
				<Chip
					label='View users'
					className={item.Locked ? classes.chipLocked : classes.chipView}
					onClick={() => onViewUserClick(item)}
					disabled={item.Locked}
				/>
			)
		}
		let statusProperty = headerConfig.find((x) => x.dataSource === 'Status')

		statusProperty.onRenderProperty = (item) => {
			const isAuthorized = hasAuthorization(props.userProfile, [PORTAL_ADMINISTRATOR])

			return (
				isAuthorized ?
					<LockButton
						icon={item.Locked ? LockOpenIcon : LockIcon}
						onLocked={(event) => onLocked(item.Token, !item.Locked, event)}
						isLocked={item.Locked}
						title={item.Locked ? 'Unlock representative' : 'Block representative'}
						disabled={identifierLockSeleted === item.Token}
					/>
					: item.Locked ? 'Unlock representative' : 'Block representative'
			)
		}

		return headerConfig
	}

	/** Render menu options for list in mode cards*/
	const renderOptionMenu = (user) => {
		let isDisabledOption = loggedUserProfile.userName !== user.UserName ? false : true
		return (
			<>
				{
					<ListItem
						button
						data-cy='item-remove-access'
						disabled={isSaving || isDisabledOption}
						onClick={(event) => onLocked(user.Token, !user.Locked, event)}
					>
						<ListItemIcon>{user.Locked ? <img src={LockOpenIcon} alt='icon' /> : <LockOpenMeteriaIcon />}</ListItemIcon>
						<ListItemText primary={user.Locked ? 'Unlock representative' : 'Block representative'} />
					</ListItem>
				}
			</>
		)
	}

	const onRowClick = (sender, item) => {
		if (props.onEditSalesRepresentativeClick) {
			props.onEditSalesRepresentativeClick(item)
		}
	}

	/**Render content in cads o en mode list */
	const renderContent = () => {
		return (
			<RenderContentIndex
				headerConfig={getHeaderConfiguration()}
				isLoading={props.isLoading}
				pagination={pagination}
				onRowClick={onRowClick}
				data={allSalesRepresentatives}
				gridMode={gridMode}
				onChangeSortingCriteria={props.onChangeSortingCriteria}
				searchValue={props.searchValue}
				titleData={'There is no added data, please add a sales representative.'}
				onEditClick={onEditSalesRepresentativeClick}
				isAvatarFromIdentity={false}
				identifierCardSeleted={identifierCardSeleted}
				isActiveButtonOptions={true}
				renderOptionMenu={renderOptionMenu}
			/>
		)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Administration / Imbera sales representative</Typography>
					</HeaderBreadCrum>
				}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<DataGridToolbar
						onChange={props.onChangeSearchValue}
						searchValue={props.searchValue}
						switchViewMode={switchViewMode}
						gridMode={gridMode}
						onAddLateralPanelConfig={onAddLateralPanelConfig}
						onDownloadReport={onDownloadReport}
						userProfile={userProfile}
					/>
					{renderContent()}
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination
							count={pagination.total ? pagination.total : 0}
							page={pagination.pageNumber ? pagination.pageNumber : 0}
							onChangePage={onChangePage}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}
DesktopView.propTypes = {
	/**
	 * Determine if the user list's is loading
	 */
	isLoading: propTypes.bool.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * On change sort criteria callback
	 */
	onChangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * On change search value
	 */
	onChangeSearchValue: propTypes.func.isRequired,
	/**
	 * The imbera allSalesRepresentative's list
	 */
	allSalesRepresentatives: propTypes.arrayOf(
		propTypes.shape({
			Name: propTypes.string.isRequired,
			Code: propTypes.string.isRequired
		})
	),

	/**
	 * The object contains the paginated
	 */
	pagination: propTypes.shape({
		/**
		 * Determine the initial sort by column
		 */
		orderBy: propTypes.string,
		/**
		 * Defines the initial sort direction
		 */
		orderType: propTypes.oneOf(['asc', 'desc']),
		/**
		 * Defines the total de retails pagination
		 */
		total: propTypes.number,
		/**
		 * Defines the page number pagination
		 */
		pageNumber: propTypes.number
	}),
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool,

	/**
	 * Opens side panel to edit a sales representative information
	 */
	onEditSalesRepresentativeClick: propTypes.func.isRequired,
	/**
	 * On change number of page
	 */
	onChangePage: propTypes.func.isRequired,
	/**
	 * Close lateral panel for edit information from sales representative
	 */
	onClosedEditLateralPanel: propTypes.func.isRequired,
	/**
	 * open panel lateral for add sales representative
	 */
	onAddLateralPanelConfig: propTypes.func.isRequired
}

DesktopView.defaultProps = {
	allSalesRepresentatives: [],
	isLoading: false,
	searchValue: '',
	pagination: {
		orderBy: '',
		orderType: 'asc',
		total: 0,
		pageNumber: 1
	},
	onChangeSortingCriteria: () => console.warn('Callback [onChangeSortingCriteria] no defined'),
	onChangeSearchValue: () => console.warn('Callback [onChangeSearchValue] no defined'),
	onEditSalesRepresentativeClick: () => console.warn('Callback [onEditSalesRepresentativeClick] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined'),
	onChangePage: () => console.warn('Callback [onChangePage] no defined'),
	onClosedEditLateralPanel: () => console.warn('Callback [onClosedEditLateralPanel] no defined'),
	updateSalesRepresentativeData: () => console.warn('Callback [updateSalesRepresentativeData] no defined'),
	onAddLateralPanelConfig: () => console.warn('Callback [onAddLateralPanelConfig] no defined')
}

export default withImmutablePropsToJS(DesktopView)

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()

	return (
		<div className={classes.searchIndex}>
			<SearchBox placeholder='Search representative' onChange={props.onChange} value={props.searchValue} />
			<ButtonGroup component='span' size='small' variant='outlined' color='primary' className={classes.buttonsGroupContainer}>
				<Tooltip component='span' title='Download list of sales representatives'>
					<Button className={classes.buttonsGroup} onClick={props.onDownloadReport}>
						<CloudDownloadIcon />
					</Button>
				</Tooltip>
				<Tooltip component='span' title='Mode list'>
					<Button className={classes.buttonsGroup} disabled={!props.gridMode} onClick={props.switchViewMode}>
						<ListIcon />
					</Button>
				</Tooltip>
				<Tooltip component='span' title='Mode card'>
					<Button className={classes.buttonsGroup} disabled={props.gridMode} onClick={props.switchViewMode}>
						<GridIcon />
					</Button>
				</Tooltip>

				<AuthorizationEvaluation profile={props.userProfile} allowedRoles={[PORTAL_ADMINISTRATOR]}>
					<Tooltip component='span' title='Add sales representative'>
						<Button className={classes.buttonsGroup} onClick={props.onAddLateralPanelConfig}>
							<AddIcon />
						</Button>
					</Tooltip>
				</AuthorizationEvaluation>
			</ButtonGroup>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool
}

DataGridToolbar.defaultProps = {
	gridMode: false,
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined')
}
