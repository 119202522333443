/**Import react section */
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
/**Import material UI Section */
import {
    DialogContent,
    Chip
} from '@material-ui/core';

/**Import components section */
import PanelHeaderIcon from '../../common/lateralPanel/PanelHeaderIcon'
import iconFailureDoor from '../../../resources/images/failureWarranty/doorWhite.svg';

/**Import resouces section */
import { PaymentRequestStatus, DELETED_PAYMENT } from '../../../store/helpers/AppConstants'
import defaultTheme from '../../../resources/themes/default.js'
import ChangeStatusMessage from './ChangeStatusMessage'

/**
 * Componente chage status panel
 * @param {*} props 
 */
const ChangeStatusPanel = (props) => {
    const { onCloseButtonClick, isUserExternal, isUserInternal } = props;

    const { onSetPasswordDialogConfiguration } = props;

    const [paymentRequestModel, setPaymentRequestModel] = useState(null);
    const [changeStatusModel, setStatusModel] = useState(null);

    useEffect(() => {
        setPaymentRequestModel(props.payment)
    }, [props.payment])

    /**
     * get coponentt to shos in status
     * @param {*} status 
     */
    const GetComponentByStatus = () => {
        let status = changeStatusModel ? changeStatusModel.status.value : null;
        switch (Number(status)) {
            case PaymentRequestStatus.IN_PROCESS:
                return <ChangeStatusMessage
                    onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                    status={status}
                    message={"Comments on the application submission"}
                />
            case PaymentRequestStatus.ACCEPTED:
                return <ChangeStatusMessage
                    onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                    status={status}
                    message={"Comments"}
                />
            case PaymentRequestStatus.REJECTED:
                return <ChangeStatusMessage
                    onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                    status={status}
                    message={"Reason for rejection"}
                />
            case DELETED_PAYMENT:
                return <ChangeStatusMessage
                    onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                    status={status}
                    canBeDelete={true}
                />
            default:
                return null
        }
    }

    const getPlaceholder = () => {
        switch (Number(paymentRequestModel ? paymentRequestModel.Status : null)) {
            case PaymentRequestStatus.DRAFT:
                return "PAYMENT REQUEST DRAFT"
            case PaymentRequestStatus.IN_PROCESS:
                return "PAYMENT REQUEST IN SUBMIT"
            case PaymentRequestStatus.ACCEPTED:
                return "PAYMENT REQUEST ACCEPTED"
            case PaymentRequestStatus.REJECTED:
                return "PAYMENT REQUEST REJECTED"
            case DELETED_PAYMENT:
                return "PAYMENT REQUEST DELETE"
            default:
                return null
        }
    }

    const backgroundColorSelect = () => {
        let status = changeStatusModel ? changeStatusModel.status.value : null;
        switch (Number(status)) {
            case PaymentRequestStatus.DRAFT:
                return changeStatusModel.status.color
            case PaymentRequestStatus.IN_PROCESS:
                return changeStatusModel.status.color
            case PaymentRequestStatus.ACCEPTED:
                return changeStatusModel.status.color
            case PaymentRequestStatus.REJECTED:
                return changeStatusModel.status.color
            case DELETED_PAYMENT:
                return changeStatusModel.status.color
            default:
                return null
        }
    };

    const backGroundColorDefaultByStatus = () => {
        switch (Number(paymentRequestModel ? paymentRequestModel.Status : null)) {
            case PaymentRequestStatus.DRAFT:
                return defaultTheme.palette.colors.purple
            case PaymentRequestStatus.IN_PROCESS:
                return defaultTheme.palette.colors.waiting
            case PaymentRequestStatus.ACCEPTED:
                return defaultTheme.palette.colors.green
            case PaymentRequestStatus.REJECTED:
                return defaultTheme.palette.colors.red
            case DELETED_PAYMENT:
                return defaultTheme.palette.colors.red
            default:
                return defaultTheme.palette.colors.purple
        }
    }


    const colorSelectorStyles = {
        control: styles => ({ ...styles, backgroundColor: changeStatusModel ? backgroundColorSelect() : backGroundColorDefaultByStatus(), color: "white" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? `${data.color}80` : isFocused ? `${data.color}80`
                    : null,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (`${data.color}80`),
                },
            };
        },
        input: styles => ({ ...styles, ...colorSelected() }),
        placeholder: styles => ({ ...styles, ...colorSelected() }),
        singleValue: (styles, { data }) => ({ ...styles, ...colorSelected(data.color) }),
        menu: styles => ({ ...styles, zIndex: 10, maxHeight: 200 }),
        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
    };

    /** Invoke when select property change*/
    const onSelectChange = (name, option) => {
        setStatusModel({
            ...changeStatusModel,
            [name]: option
        })
    }

    return (
        <>
            <PanelHeaderIcon
                title={'Change status'}
                isIconSvgOrPng={true}
                icon={iconFailureDoor}
                onCloseButtonClick={onCloseButtonClick}
            />
            <DialogContent >
                {isUserExternal && <Select
                    id={"id-status"}
                    placeholder={getPlaceholder()}
                    name="status"
                    autoFocus
                    options={paymentRequestModel ? paymentRequestModel.GetAvailableOperationsProvider : null}
                    styles={colorSelectorStyles}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(value, action) => { onSelectChange(action.name, value) }}
                />}
                {isUserInternal && <Select
                    id={"id-status"}
                    placeholder={getPlaceholder()}
                    name="status"
                    autoFocus
                    options={paymentRequestModel ? paymentRequestModel.AvailableOperations : null}
                    styles={colorSelectorStyles}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(value, action) => { onSelectChange(action.name, value) }}

                />}
                <br /><br />
                {GetComponentByStatus()}
            </DialogContent>

        </>
    )
}


const formatOptionLabel = ({ value, label, color }) => (
    <div>
        <Chip
            id={value}
            size="small"
            label={label}
            clickable
            style={{ color: defaultTheme.palette.common.white, background: color }}
        />
    </div>
);

const colorSelected = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    color: defaultTheme.palette.common.white
});

export default ChangeStatusPanel