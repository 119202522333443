import React from 'react'
import propTypes from 'prop-types'
import {Pagination as MaterialPagination} from '@material-ui/lab/';

/**
 * Table pagination component
 */
const Pagination = (props) => {
    return (
        <MaterialPagination count={props.count} page={props.page}  onChange={props.onChangePage}/>
    )
}
Pagination.propTypes = {
    /**
     * The total count of pages 
     */
    count: propTypes.number.isRequired,
    /**
     * The actual page
     */
    page: propTypes.number.isRequired,
    /**
    * 
    */
    onChangePage: propTypes.func.isRequired
}
Pagination.defaultPage = {
    count: 0,
    page: 0
}
export default Pagination