/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'
/**Import components section */
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import { Alert, AlertTitle } from '@material-ui/lab'
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined'
/**Import Material Ui section */
import { Grid, Chip, Typography } from '@material-ui/core'
import AvatarCustomSize from '../../common/AvatarCustomSizes'

const SparePartRequestForm = (props) => {
    const { onSaveSparePartRequest, isSaving, isAuthorizadoForEditedData, isModeCreate,
         isVisibleChipsAssignement, isMobile } = props;
    const [sparePartRequestsForm, setSparePartRequestsForm] = useState(null);

    const [mapPositionModel, setPositionModel] = useState({
        latitude: "",
        longitude: ""
    })

    useEffect(() => {
        setSparePartRequestsForm(props.sparePartRequest)
    }, [props.sparePartRequest])

    useEffect(() => {
        setPositionModel(props.mapPosotionModel)
    }, [props.mapPosotionModel])

    /**Custom hooks an styles */
    const classes = createServicesStyle();

    const onUpdateData = () => {
        if (onSaveSparePartRequest) {
            onSaveSparePartRequest(mapPositionModel, sparePartRequestsForm)
        }
    }

    /**Verify if cam be sabed */
    const canBeSaved = () => {
        if (!sparePartRequestsForm) return false;
        if (sparePartRequestsForm.Address ? (sparePartRequestsForm.address) : true) return false;
        return true;
    }

    const onPropertyChange = event => {
        let { name, value } = event.target;
        setSparePartRequestsForm({
            ...sparePartRequestsForm,
            [name]: value
        })
    }
    const renderTextBox = (key, item, label, isActive, isDisabled, size = 6, multiline = false, rows = 0, required) => {
        let value = item ? item[key] ? item[key] : '' : '';
        return (
            <Grid item xs={size}>
                <TextBox
                    key={key}
                    active={isActive}
                    disabled={isDisabled || !isModeCreate}
                    fullWidth
                    multiline={multiline}
                    name={key}
                    rows={rows}
                    variant="outlined"
                    margin="dense"
                    label={label}
                    onChange={onPropertyChange}
                    value={value}
                    inputProps={{
                        style: { fontSize: 12 }
                    }}
                    />
            </Grid>
        )
    }
    let sparePartRequestsAddress = sparePartRequestsForm && sparePartRequestsForm.Address && sparePartRequestsForm.Address.locality;
    
    return (
        <>
            {
                isVisibleChipsAssignement && !isMobile && <Grid container spacing={2}>
                    {
                        sparePartRequestsForm && sparePartRequestsForm.ServiceProvider &&
                        <Grid item xs={6}>
                            <Typography className={classes.title}>{"SERVICES PROVIDER ASSIGNEMENT"}</Typography>
                            <Chip
                                id={"value"}
                                size="small"
                                avatar={
                                    <AvatarCustomSize
                                        avatarUrl={null}
                                        name={"SERVICES PROVIDER"}
                                        isAvatarFromIdentity={false}
                                    />}
                                label={sparePartRequestsForm && sparePartRequestsForm.ServiceProvider && sparePartRequestsForm.ServiceProvider.Name}
                                style={{ color: "white", background: "#5357DC" }}
                            /><br />
                        </Grid>
                    }
                    {
                        (sparePartRequestsForm && sparePartRequestsForm.Customer) && <Grid item xs={6}>
                            <Typography className={classes.title}>{"CUSTOMER ASSIGNEMENT"}</Typography>
                            <Chip
                                id={"value"}
                                size="small"
                                avatar={
                                    <AvatarCustomSize
                                        avatarUrl={null}
                                        name={"CUSTOMER"}
                                        isAvatarFromIdentity={false}
                                    />}
                                label={sparePartRequestsForm && sparePartRequestsForm.Customer && (`${sparePartRequestsForm.Customer.Code} | ${sparePartRequestsForm.Customer.Name}`)}
                                style={{ color: "white", background: "#5357DC" }}
                            /><br />
                        </Grid>
                    }
                </Grid>
            }
            

            <Grid container spacing={2}>
                {renderTextBox("ShipTo", sparePartRequestsForm, "SHIP TO", true, !isAuthorizadoForEditedData, 12, true, (isMobile ? 3 : 6), false)}  
                {renderTextBox("Comments", sparePartRequestsForm, "COMMENTS", true, !isAuthorizadoForEditedData, 12, true, (isMobile ? 3 : 6), false)}
                {renderTextBox("EmailsContact", sparePartRequestsForm, "EMAILS TO SEND NOTIFICATIONS e.g. (correo@correo.com,)", true, !isAuthorizadoForEditedData, 12, true, 1, false)}
                {renderTextBox("ContactPhone", sparePartRequestsForm, "PHONES FOR CLOSING SHIPMENT e.g. (7712333456,7798762345)", true, !isAuthorizadoForEditedData, 12, true, 1, false)}
                <Grid item xs={12}>
                    {<Alert
                        variant='outlined'
                        severity='info'
                        icon={<LocationOnIcon fontSize='inherit' />}
                    >
                        <AlertTitle>Address</AlertTitle>
                        {`${sparePartRequestsAddress ? sparePartRequestsForm.Address.street_number : ""} ${sparePartRequestsAddress ? sparePartRequestsForm.Address.route : ""}`} <br />
                        {`${sparePartRequestsAddress ? sparePartRequestsForm.Address.locality : ""}, ${sparePartRequestsAddress ? sparePartRequestsForm.Address.administrative_area_level_1 : ""}, ${sparePartRequestsAddress ? sparePartRequestsForm.Address.country : ""}`}
                        <br />
                        {`${sparePartRequestsAddress ? sparePartRequestsForm.Address.postal_code : ""}`}
                    </Alert>}
                </Grid>

                {isAuthorizadoForEditedData && isModeCreate &&
                    <Grid item xs={12} className={classes.updateButton}>
                        <ButtonSaved
                            onSavedClick={onUpdateData}
                            title="Update"
                            isSaving={isSaving || !canBeSaved()}
                        />
                    </Grid>
                }
            </Grid>
        </>
    )
}

SparePartRequestForm.propTypes = {
    /**Determinate if update spare Part requests address  data */
    isSaving: propTypes.bool.isRequired,
    /**Function invoke callback for update contact info */
    onSaveSparePartRequest: propTypes.func.isRequired,
    /**Determinate if edit spare Part requests address  data */
    isAuthorizadoForEditedData: propTypes.bool.isRequired,
}
SparePartRequestForm.defaultProps = {
    isSaving: false,
    isAuthorizadoForEditedData: false,
    onSaveSparePartRequest: () =>
        console.warn('Callback [onSaveSparePartRequest] no defined'),
}


export default SparePartRequestForm
