import { ServicesStatusTypes } from './AppConstants'

import { GetServiceStatusDescription } from './StatusHelper'
import defaultTheme from '../../resources/themes/default.js'
export function calculateRegressionLineEquation(dataSet) {
    let n = dataSet.length;
    if (n < 2) {
        throw new Error("Data set must contains at least two points");
    }
    let sumX = sum(dataSet.map(item => item.x));
    let sumY = sum(dataSet.map(item => item.y));
    let sumXY = sum(dataSet.map(item => item.x * item.y));
    let sumXX = sum(dataSet.map(item => item.x * item.x));
    let m = ((n * sumXY) - (sumX * sumY)) / ((n * sumXX) - (sumX * sumX));
    m = Math.round(m * 10000000000) / 10000000000;
    let b = (sumY * sumXX - sumX * sumXY) / (n * sumXX - sumX * sumX);
    b = Math.round(b * 10000000000) / 10000000000;
    return { m, b };
}

export function calculateRegressionLineSeries(dataSet) {
    let lineEquation = calculateRegressionLineEquation(dataSet);
    let trend = lineEquation.m === 0 ? 0 : lineEquation.m > 0 ? 1 : -1;
    let series = dataSet.map(point => {
        let y = lineEquation.m * point.x + lineEquation.b;
        y = Math.round(y * 10000000000) / 10000000000;
        return { x: point.x, y }
    });
    return { trend, series };
}

export function sum(values) {
    if (!values) {
        throw new Error("Parameter can't be empty or null");
    }
    return values.reduce((prevValue, currentValue) => prevValue + currentValue, 0);
}


export const getRegresionLineForMeasurements = (measurements) => {
    if (measurements.length > 1) {
        let regresionInput = measurements.map((measurement) => {
            return { x: measurement.date.unix(), y: measurement.value }
        })
        return calculateRegressionLineSeries(regresionInput)
    }
}

const getBackgroundColordChart = (statusId) => {
    switch (Number(statusId)) {
        case ServicesStatusTypes.ACTIVE:
            return defaultTheme.palette.serviceStatus.newServiceDark
        case ServicesStatusTypes.ASSIGNED:
            return defaultTheme.palette.serviceStatus.assignedDark
        case ServicesStatusTypes.SUSPENDED:
            return defaultTheme.palette.colors.orange
        case ServicesStatusTypes.WAITING:
            return defaultTheme.palette.colors.waiting
        case ServicesStatusTypes.COMPLETED:
            return defaultTheme.palette.colors.green
        case ServicesStatusTypes.REPLACED:
            return defaultTheme.palette.secondary.main
        case ServicesStatusTypes.CANCELLED:
            return defaultTheme.palette.colors.red
        default:
            return "Undefined"
    }
}

/**
 * Get a grouped services metrics open and closed
 */
export const getGroupedServicesMetrics = (services) => {
    let allWeeks = services.map(w => w.WeekCompleted);
    let minWeek = Math.min(...allWeeks)
    let maxWeek = Math.max(...allWeeks)

    let chartArrayTotal = maxWeek - minWeek;
    let chartData = services.reduce((data, service) => {
        let weekCompleted = service.WeekCompleted;
        let status = service.Status;
        let week = data.find(d => d.Status === status)

        if (!week) {
            week = {
                Status: service.Status,
                WeekCompleted: service.WeekCompleted,
                Total: 1,
                type: (service.Status === ServicesStatusTypes.CANCELLED ? 'line' : 'bar'),
                text: GetServiceStatusDescription(service.Status),
                backgroundColor: getBackgroundColordChart(service.Status),
                aspect: 'spline',
                barWidth: '10px',
                marker: (service.Status === ServicesStatusTypes.CANCELLED) ? {
                    backgroundColor: '#FFFFFF',
                    borderColor: getBackgroundColordChart(service.Status),
                    borderWidth: '2px',
                    shadow: false
                } : null,
                values: [...Array(chartArrayTotal).keys()].map(() => 0),
            }
            data = [...data, week]
        }
        week.values[weekCompleted - minWeek] += 1
        week.Total += 1
        return data
    }, [])

    return chartData.sort((item1, item2) => item1.WeekCompleted - item2.WeekCompleted)
}


export const getLabelFooterWeewGraph = (services) => {
    let allWeeks = services.map(w => w.WeekCompleted);
    allWeeks.sort((item1, item2) => item1 - item2);

    let allWeekDescription = [];
    allWeeks.forEach(week => {
        if(!allWeekDescription.includes(`WEEK ${week}`)){
            allWeekDescription.push(`WEEK ${week}`)
        }

    });
    return allWeekDescription;
}