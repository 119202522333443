import React from 'react'
import { Switch } from 'react-router-dom'
import { ProtecteRoute } from '../common/ProtectedRoute'

// Import views
import DashboardView from '../dashboard/View'
import NetworkDaysView from '../networkdays/View'
import InternalUsersIndex from '../admin/internalUsers/Index'
import ProfileIndex from '../admin/internalUsers/Show'
import UnauthorizedView from '../errors/UnauthorizedView'
import UsersInRepresentativesIndex from '../salesRepresentatives/usersInRepresentatives'
import SalesRepresentativesIndex from '../salesRepresentatives/Index'
import ServiceProviderIndex from '../serviceProviders/Index'
import UsersInProviderIndex from '../serviceProviders/UsersInProviderIndex'
import IndexRegister from '../serviceProviders/IndexRegister'
import CreateServices from '../services/Create'
import ServiceShow from '../services/Show'
import GraphIndex from '../services/GraphIndex'
import IndexClosedService from '../services/IndexClosed'
import OpenSparePartRequestManual from '../spareParts/OpenManual'
import CreateSparePartRequest from '../spareParts/Create'
import CreateSparePartRequestManual from '../spareParts/CreateManual'
import OpenSparePartRequest from '../spareParts/Open'
import SparePartIndex from '../spareParts/Index'
import SparePartIndexClosed from '../spareParts/IndexClosed'
import SerialHistoryIndex from '../spareParts/SerialHistoryIndex'
import SerialSapIndex from '../spareParts/SerialSapIndex'

import SparePartSalesIndex from '../sparePartSales/Index'
import SparePartSaleCreate from '../sparePartSales/Create'
import SparePartSaleOpen from '../sparePartSales/Open'

import PaymentRequestsIndex from '../paymentRequests/Index'
import CreatePaymentRequest from '../paymentRequests/Create'
import ShowPaymentRequest from '../paymentRequests/Show'
import PaymentSummary from '../paymentRequests/PaymentSummary'

import RegionsIndex from '../regions/Index'
import UsersInCustomersIndex from '../customers/UsersInCustomersIndex'
import CustomerIndex from '../customers/Index'

import TermsConditionsIndex from '../termsConditions/Index'
import WarrantySeriesSapIndex from '../warrantySeriesSAP/Index'
import ServiceProviderRegisterIndex from '../statistics/Index'

import {
	PORTAL_ADMINISTRATOR,
	DISPATCHER,
	IMBERA_TECHNICIAN,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	CUSTOMER_MANAGER,
	PROVIDER_REGISTRATION_VALIDATOR,
	PAYMENT_MANAGER,
	CUSTOMER_TECHNICIAN,
	VISUALIZER
} from '../../resources/RolesEnum'

/**
 * Authenticated view
 */
const AuthenticatedView = (props) => {
	/**
	 * TODO: Set protected routes for spare parts related url
	 * https://apsys.atlassian.net/browse/APS2015-332
	 */
	return (
		<Switch>
			<ProtecteRoute exact path='/' component={DashboardView} />
			<ProtecteRoute path='/profile/:token' component={ProfileIndex} />
			<ProtecteRoute path='/internalusers' allowedRoles={[PORTAL_ADMINISTRATOR]} component={InternalUsersIndex} />
			<ProtecteRoute
				path='/salesRepresentatives/:code/:name/:token'
				allowedRoles={[PORTAL_ADMINISTRATOR, SALES_REPRESENTATIVE_MANAGER]}
				component={UsersInRepresentativesIndex}
			/>
			<ProtecteRoute path='/salesRepresentatives' allowedRoles={[PORTAL_ADMINISTRATOR, VISUALIZER]} component={SalesRepresentativesIndex} />
			<ProtecteRoute
				path='/serviceProviders/:code/:name/:token'
				allowedRoles={[PORTAL_ADMINISTRATOR, SERVICE_PROVIDER_MANAGER]}
				component={UsersInProviderIndex}
			/>
			<ProtecteRoute path='/serviceProviders/register/:token' component={IndexRegister} />
			<ProtecteRoute path='/serviceProviders' allowedRoles={[PORTAL_ADMINISTRATOR, VISUALIZER]} component={ServiceProviderIndex} />
			<ProtecteRoute
				path='/customers/:code/:name/:token'
				allowedRoles={[PORTAL_ADMINISTRATOR, CUSTOMER_MANAGER]}
				component={UsersInCustomersIndex}
			/>
			<ProtecteRoute path='/customers' allowedRoles={[PORTAL_ADMINISTRATOR, VISUALIZER]} component={CustomerIndex} />
			<ProtecteRoute path='/warrantySeriesSap' allowedRoles={[PORTAL_ADMINISTRATOR]} component={WarrantySeriesSapIndex} />
			<ProtecteRoute
				path='/termsConditions'
				allowedRoles={[
					PORTAL_ADMINISTRATOR,
					PROVIDER_REGISTRATION_VALIDATOR,
					SERVICE_PROVIDER_MANAGER,
					SALES_REPRESENTATIVE_MANAGER,
					VISUALIZER
				]}
				component={TermsConditionsIndex}
			/>
			<ProtecteRoute path='/dashboard' component={DashboardView} />
			<ProtecteRoute path='/closed' component={IndexClosedService} />
			<ProtecteRoute
				path='/create'
				allowedRoles={[
					DISPATCHER,
					CUSTOMER_MANAGER,
					CUSTOMER_TECHNICIAN,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					SALES_REPRESENTATIVE_MANAGER,
					SALES_MANAGER
				]}
				component={CreateServices}
			/>
			<ProtecteRoute path='/graph' allowedRoles={[PORTAL_ADMINISTRATOR, VISUALIZER, DISPATCHER]} component={GraphIndex} />

			<ProtecteRoute
				path='/spareparts/manual/open/:token'
				allowedRoles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					CUSTOMER_TECHNICIAN
				]}
				component={OpenSparePartRequestManual}
			/>
			<ProtecteRoute
				path='/spareparts/manual/create'
				allowedRoles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					CUSTOMER_MANAGER,
					CUSTOMER_TECHNICIAN,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN
				]}
				component={CreateSparePartRequestManual}
			/>
			<ProtecteRoute
				path='/spareparts/create/:ticket'
				allowedRoles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					CUSTOMER_TECHNICIAN
				]}
				component={CreateSparePartRequest}
			/>
			<ProtecteRoute path='/spareparts/closed' component={SparePartIndexClosed} />
			<ProtecteRoute
				path='/spareparts/create'
				allowedRoles={[IMBERA_TECHNICIAN, SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN]}
				component={CreateSparePartRequest}
			/>
			<ProtecteRoute path='/spareparts/open/:token' component={OpenSparePartRequest} />
			<ProtecteRoute path='/spareparts' component={SparePartIndex} />

			<ProtecteRoute
				path='/sparepartsales/create'
				allowedRoles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					CUSTOMER_MANAGER,
					CUSTOMER_TECHNICIAN,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					SALES_REPRESENTATIVE_MANAGER,
					SALES_MANAGER
				]}
				component={SparePartSaleCreate}
			/>
			<ProtecteRoute path='/sparepartsales/open/:token' component={SparePartSaleOpen} />
			<ProtecteRoute path='/sparepartsales' component={SparePartSalesIndex} />

			<ProtecteRoute path='/paymentrequests/create' allowedRoles={[SERVICE_PROVIDER_MANAGER]} component={CreatePaymentRequest} />
			<ProtecteRoute
				path='/paymentrequests/:token'
				allowedRoles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR, SERVICE_PROVIDER_MANAGER, PAYMENT_MANAGER, VISUALIZER]}
				component={ShowPaymentRequest}
			/>
			<ProtecteRoute
				path='/paymentrequests'
				allowedRoles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR, SERVICE_PROVIDER_MANAGER, PAYMENT_MANAGER, VISUALIZER]}
				component={PaymentRequestsIndex}
			/>
			<ProtecteRoute
				path='/paymentsummary'
				allowedRoles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR, SERVICE_PROVIDER_MANAGER, PAYMENT_MANAGER, VISUALIZER]}
				component={PaymentSummary}
			/>

			<ProtecteRoute path='/regions' component={RegionsIndex} />
			<ProtecteRoute path='/serialSap' component={SerialSapIndex} />
			<ProtecteRoute path='/serialHistory' component={SerialHistoryIndex} />
			<ProtecteRoute exact path='/services/:token/:serie' component={ServiceShow} />
			<ProtecteRoute path='/networkdays' component={NetworkDaysView} />
			<ProtecteRoute path='/unauthorized' component={UnauthorizedView} />
			<ProtecteRoute path='/statistics' allowedRoles={[PORTAL_ADMINISTRATOR, PAYMENT_MANAGER]} component={ServiceProviderRegisterIndex} />
		</Switch>
	)
}
AuthenticatedView.propTypes = {}
AuthenticatedView.defaultProps = {}
export default AuthenticatedView
