/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/**Import components section */
import DesktopView from './components/DesktopView'
import Create from './Create'
import Edit from './Edit'
import Toaster from '../common/Toaster'

/**Import action section */
import {
	loadAllCustomers,
	updateCustomer,
	REMOVE_USER_FROM_ACCOUNT,
	lockedCustomer,
	downloadCustomersUsersList
} from '../../store/customers/customersActions'

const Index = () => {
	/**Hooks sections */
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()

	/**Redux store */
	const isLoading = useSelector((state) => state.customers.get('isLoadingCustomers'))
	const isSaving = useSelector((state) => state.customers.get('isSaving'))
	const allCustomers = useSelector((state) => state.customers.get('customers'))
	const pagination = useSelector((state) => state.customers.get('pagination'))
	const loggedUserProfile = useSelector((state) => state.session.get('profile'))

	const userProfile = useSelector((state) => state.session.get('profile'))
	/**Local store this component section */
	const [query, setQuery] = useState('')
	const [customerEdit, setCustomerEdit] = useState({ opened: false, customer: null })
	const [addPanelConfiguration, setAddPanelConfiguration] = useState({ opened: false })
	const [gridMode, setGridMode] = useState(false)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false)
	const [identifierCardSeleted, setIdentifierCardSelected] = useState()
	const [identifierLockSeleted, setIdentifierLockSelected] = useState()

	/**
	 * Load internal user
	 */
	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query) setQuery(queryParameters.query)
		dispatch(loadAllCustomers(queryParameters))
	}, [dispatch, location.search])

	/**
	 * Here we save and switch view mode grid|list and persist on local storage.
	 */
	useEffect(() => {
		if (gridMode === false) {
			setGridMode(false)
		} else {
			setGridMode(true)
		}
	}, [gridMode])

	/**
	 * Update sort criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**On add lateral panel configuration */
	const onAddLateralPanelConfig = () => {
		setAddPanelConfiguration({ opened: !addPanelConfiguration.opened })
	}

	/**Change mode view in sales representative */
	const switchViewMode = () => {
		if (gridMode === false) {
			setGridMode(true)
		} else {
			setGridMode(false)
		}
	}

	const onEditCustomerClick = (customerData) => {
		setIdentifierCardSelected(customerData.Token ? customerData.Token : '')
		setCustomerEdit({ opened: true, customer: customerData })
	}

	/**On redirect view users in customers */
	const onViewUserClick = (customer) => {
		history.push(`/customers/${customer.Code}/${customer.Name}/${customer.Token}`)
	}

	const onUpdateCustomer = (customerData) => {
		dispatch(updateCustomer(customerData))
			.then((customer) => {
				setCustomerEdit({ opened: false, customer: customer })
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to update customer',
					variant: 'warning'
				})
			})
	}

	const onClosedEditLateralPanel = () => {
		setIdentifierCardSelected('')
		setCustomerEdit({ opened: false, customer: null })
	}

	/**Open/close dialog modal for upload logo user */
	const onSetHandleOpenCloseModalUploadImg = (customer) => {
		setHandleOpenModalUploadImg(!handleOpenModalUploadImg)
		if (customer) {
			setCustomerEdit({ opened: true, customer: customer })
		}
	}

	const onRemoveUserFromAccount = (token) => {
		dispatch({
			type: REMOVE_USER_FROM_ACCOUNT,
			token
		})
	}

	const onDownloadCustomersUsersList = () => {
		dispatch(downloadCustomersUsersList())
	}

	const onLocked = (token, isLocked, event) => {
		if (event) event.stopPropagation()
		setIdentifierLockSelected(token)
		dispatch(lockedCustomer(token, isLocked))
			.then((_) => {
				setIdentifierLockSelected('')
				onRemoveUserFromAccount(token)
			})
			.catch((error) => {
				setIdentifierLockSelected('')
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to locked customer',
					variant: 'warning'
				})
			})
	}

	return (
		<>
			<DesktopView
				isLoading={isLoading}
				allCustomers={allCustomers}
				searchValue={query}
				onChangeSortingCriteria={updateSortCriteria}
				onChangeSearchValue={(query) => updateSearchQuery(query)}
				onChangePage={onChangePage}
				onEditCustomerClick={onEditCustomerClick}
				pagination={pagination}
				switchViewMode={switchViewMode}
				gridMode={gridMode}
				onAddLateralPanelConfig={onAddLateralPanelConfig}
				identifierCardSeleted={identifierCardSeleted}
				onViewUserClick={onViewUserClick}
				onLocked={onLocked}
				identifierLockSeleted={identifierLockSeleted}
				loggedUserProfile={loggedUserProfile}
				onDownloadReport={onDownloadCustomersUsersList}
				userProfile={userProfile}
			/>
			<Create
				onAddLateralPanelConfig={onAddLateralPanelConfig}
				addPanelConfiguration={addPanelConfiguration}
				isSaving={isSaving}
			/>

			<Edit
				onUpdateCustomer={onUpdateCustomer}
				customerEdit={customerEdit}
				isSaving={isSaving}
				onClosedEditLateralPanel={onClosedEditLateralPanel}
				handleOpenModalUploadImg={handleOpenModalUploadImg}
				onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
			/>
			{/** Toaster */}
			{toasterOptions.open && (
				<Toaster
					toasterOptions={toasterOptions}
					onClose={() => setToasterOptions({ open: false })}
				/>
			)}
		</>
	)
}

export default Index
