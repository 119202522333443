import { makeStyles } from '@material-ui/styles'

/**
 * One column view styles
 */
export const showMobileStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.views.maxWidth,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    maxHeight: `calc(100vh - ${85}px)`,
    overflowY: "auto",

  },
  tabs: {
    maxHeight: 30,
    minHeight: 'auto',
  },
  tab: {
    marginTop: "-10px"
  },
  paddingNone: {
    padding: 0
  },
  papperTabs: {
    width: "100%",
    overflowX: "hidden"
  },
  textHeader:{
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
  },
  contenrSnack:{
    backgroundColor: theme.palette.colors.grayLayout,
    color: theme.palette.text.primary,
    boxShadow: "none"
  },
  titleSeries: {
    color: theme.palette.colors.purple,
    fontSize: "0.875rem",
    boxSizing: "border-box",
    fontWeight: 500,
    paddingLeft: 15,
    paddingBottom: 8,
    paddingTop: 8,
  },
  titleSerie:{
    color: theme.palette.primary.main,
  },
}))

export const serieComponentStyles = makeStyles((theme) => ({
  containerExpanded: {
    display: "grid",
    gridTemplateRows: "auto auto",
    width: "100%"
  },
  expanderHeader:{
    display: "flex",
    marginTop: "6px",
    marginRight: "5px",
  },
  header: {
    gridRow: 1,
    display: "flex",
    width: "100%"
  },
  table: {
    gridRow: 2,
    width: "100%",
    marginTop:"4px",
    paddingLeft:"7px",
    paddingRight:"7px"
  },
  flexGrow: {
    flexGrow: 1
  },
  iconButton: {
    backgroundColor: theme.palette.colors.purple,
    color: theme.palette.common.white,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    "&.MuiIconButton-sizeSmall.MuiIconButton-edgeEnd": {
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    marginTop: "-3px",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  }
}))