/**Import react section */
import React from 'react'
/**Import Material Ui section section */
import { Grid, Chip, Typography } from '@material-ui/core'
import ButtonSaved from '../../../common/Buttons/ButtonSaved'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'

import { WarrantyType } from '../../../../store/helpers/AppConstants'
const HeaderOpen = (props) => {
	const { onButtonSerieClick, sparePartRequest, onAddManual } = props
	return (
		<Grid
			container
			spacing={1}
			direction='row'
			justifyContent='center'
			alignItems='center'
			style={{ padding: 10 }}
		>
			{sparePartRequest && sparePartRequest.ServiceProvider && (
				<Grid
					item
					xs={sparePartRequest && sparePartRequest.Customer ? 4 : 8}
				>
					<Typography
						color='primary'
						style={{ fontSize: '0.9rem', fontWeight: 'bold' }}
					>
						{'SERVICES PROVIDER ASSIGNEMENT'}
					</Typography>
					<Chip
						id={'value'}
						size='small'
						avatar={
							<AvatarCustomSize
								avatarUrl={null}
								name={'SERVICES PROVIDER'}
								isAvatarFromIdentity={false}
							/>
						}
						label={
							sparePartRequest &&
							sparePartRequest.ServiceProvider &&
							sparePartRequest.ServiceProvider.Name
						}
						style={{ color: 'white', background: '#5357DC' }}
					/>

					<br />
				</Grid>
			)}
			{sparePartRequest && sparePartRequest.Customer && (
				<Grid item xs={4}>
					<Typography
						color='primary'
						style={{ fontSize: '0.9rem', fontWeight: 'bold' }}
					>
						{'CUSTOMER ASSIGNEMENT'}
					</Typography>
					<Chip
						id={'value'}
						size='small'
						avatar={
							<AvatarCustomSize
								avatarUrl={null}
								name={'CUSTOMER'}
								isAvatarFromIdentity={false}
							/>
						}
						label={
							sparePartRequest &&
							sparePartRequest.Customer &&
							sparePartRequest.Customer.Name
						}
						style={{ color: 'white', background: '#5357DC' }}
					/>
					<br />
				</Grid>
			)}
			<Grid
				item
				xs={
					sparePartRequest &&
						sparePartRequest.ServiceProvider &&
						sparePartRequest.Customer
						? 4
						: sparePartRequest &&
							sparePartRequest.ServiceProvider &&
							sparePartRequest.Customer === null
							? 4
							: sparePartRequest &&
								sparePartRequest.ServiceProvider === null &&
								sparePartRequest.Customer
								? 8
								: 12
				}
				style={{ display: 'flex', justifyContent: 'flex-end' }}
			>
				{(sparePartRequest && sparePartRequest.WarrantyType === WarrantyType.SALE) ?
					<ButtonSaved
						title='Add spare part'
						onSavedClick={() =>
							onAddManual()}
					/>
					:
					<ButtonSaved
						title='Add serie'
						onSavedClick={onButtonSerieClick}
					/>
				}
			</Grid>
		</Grid>
	)
}

export default HeaderOpen
