
import { makeStyles } from '@material-ui/styles'
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

/**
 * Service table styles
 */

export const toasterStyles = makeStyles((theme) => ({
    root: {
        zIndex: 99999
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 12,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}))