import { makeStyles } from '@material-ui/styles'

/**
 * One column view styles
 */
export const graphIndexStyles = makeStyles((theme) => ({
	root: {
        maxWidth:theme.views.maxWidth,
        marginLeft:"auto",
        marginRight:"auto",
        marginTop:"10px",
        maxHeight: `calc(100vh - ${85}px)`,
        overflowY: "auto"
    },
}))