/**Import react section */
import React from 'react'
import propTypes from 'prop-types';

/** Material UI import section */
import {
    Fab,
    Typography,
    Paper,
} from '@material-ui/core';
/**import resources styles */
import { toolbarStyles } from '../../resources/styles/ToolbarStyles'
const ToolbarComponent = (props) => {
    const classes = toolbarStyles();
    const { title, renderContentRight, renderBackgroundColor, icon } = props;
    return (
        <Paper className={renderBackgroundColor ? renderBackgroundColor: classes.root} elevation={0} >
            <Fab size="small" className={classes.faultWarrantyIconSelected} disabled={true}>
                <img src={icon}
                    alt='warranty-icon'
                    className={classes.faultWarrantyIcon}
                />
            </Fab>
            <Typography gutterBottom variant='subtitle2' className={classes.title}> {title} </Typography>
            <div className={classes.grow}></div>
            {renderContentRight}

        </Paper>
    )
}

ToolbarComponent.propTypes = {
    /**determinate the title of header*/
    title: propTypes.string.isRequired,
}
ToolbarComponent.defaultProps = {
    title: "",
}

export default ToolbarComponent
