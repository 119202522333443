/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/** Material UI import section */
import {
    Button,
} from '@material-ui/core';

import {ButtonCreateStyles} from '../../../resources/styles/ButtonCreateStyles'

const ButtonConfirm =(props)=> {
    const {isSaving, onSavedClick, title} = props;
    const classes = ButtonCreateStyles();
    const onButtonSavedClick = ()=>{
        if(onSavedClick){
            onSavedClick()
        }
    }

    return (
        <Button
            // color='secondary'
            variant="contained"
            disabled={isSaving}
            className={classes.btnConfirm}
            onClick={onButtonSavedClick}
        >
            {title}
        </Button>
    )
}

ButtonConfirm.propTypes = {
    /**Confirm the operation to be performed*/
    onSavedClick: propTypes.func.isRequired,
    /**Determinate if saving an operation */
    isSaving: propTypes.bool,
    /**Determinate el title of button */
    title: propTypes.string.isRequired,
}

ButtonConfirm.defaultProps = {
    isSaving: false,
    title: '',
    onSavedClick: () =>
        console.warn('Callback [onSavedClick] no defined'),

}

export default ButtonConfirm
