/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
/**Import components section */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add'; 
import DeleteIcon from '@material-ui/icons/DeleteOutline'

/** Import material UI */
import {
	Typography,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
	ExpansionPanel,
	ListSubheader,
	List,
	ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Badge,
    Chip,
    Tooltip,
    Button,
    Grid
} from '@material-ui/core'
/** Import styles */
import { showMobileStyles } from '../resources/ShowMobileStyles'
/**
 * Component o shsow list series
 * @param {*} props 
 */
const ExpansionComponent = (props) => {
	const classes = showMobileStyles()
    const {isCreation, item, expanded, isAuthorizadoForEditedData} = props
    const {handleChange, onSearchSerie} = props

    return (
        <ExpansionPanel expanded={expanded === item.Serie} onChange={handleChange(item.Serie)}>
			<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id={item.Serie}
					className={expanded === item.Serie ? classes.headerPanelSerie : ""}
				>
				<Typography><span className={expanded === item.Serie ? "" : classes.titleSerie}>
					{"Serial: "}</span>{item.Serie}{"  | "}{item.Model}
				</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {isCreation && <div className={classes.expanderHeader}>
                            <div className={classes.flexGrow}></div>
                            <Chip
                                label="Add spare parts sap"
                                onClick={() => onSearchSerie(item)}
                                avatar={<AddIcon />}
                                color='primary'
                                disabled={props.isLoadingFromSAP}
                            />
                        </div>}
                    </Grid>
                    <Grid item xs={12}>
                        <List subheader={<ListSubheader className={classes.subListTitle}>{item.spareParts.length !== 0 ? "SPARE PARTS" : "WITH SPARE PARTS"}</ListSubheader>} >
                            {item.spareParts.map((item) => {
                                return(
                                <ListItem key={item.Token} divider alignItems="flex-start">
                                    <ListItemText
                                    secondary={
                                    <React.Fragment>
                                        <Typography component="span" variant="body2" className={classes.inline}  color="textPrimary" >
                                            <strong>Part number: </strong> 
                                        </Typography>
                                        {` ${item.Code} | ${item.Description}`} <br/>
                                        <Typography component="span" variant="body2" className={classes.inline}  color="textPrimary" >
                                            <strong> Quantity: </strong><Badge className={classes.bagColor} color="error" badgeContent={item.Quantity}></Badge>
                                        </Typography> <br/>
                                        {!isCreation && <>
                                            <Typography component="span" variant="body2" className={classes.inline}  color="textPrimary" >
                                                <strong> Tracking number: </strong>
                                            </Typography>
                                            {` ${item.NumberTracking ? item.NumberTracking : ""} `}  <br/>
                                            <Typography component="span" variant="body2" className={classes.inline}  color="textPrimary" >
                                                Carrier:
                                            </Typography>
                                            {` ${item.Carrier ? item.Carrier: ""} `}
                                            <Typography component="span" variant="body2" className={classes.inline}  color="textPrimary" >
                                                {` | `} Status:
                                            </Typography>
                                            {` ${item.Status ? item.Status: ""} `}
                                        </>}
                                    </React.Fragment>
                                    }/>
                                    {isCreation && isAuthorizadoForEditedData && <ListItemSecondaryAction><br/><br/>
                                        <Tooltip component='span' title='Remove Spare Part'>
                                            <Button
                                                onClick={() =>
                                                    props.onRemoveSparePart(item)
                                                }
                                                disabled={props.isSaving && item.Token === props.identifierSparePartDelete}
                                            >
                                                <DeleteIcon color='action' />
                                            </Button>
                                        </Tooltip>
                                    </ListItemSecondaryAction>}
                                </ListItem>
                            )})}
                        </List>
                    </Grid>
                </Grid>
			</ExpansionPanelDetails>
		</ExpansionPanel>
    )
}

ExpansionComponent.propTypes = {
    /**List of service Providers */
    item: propTypes.object.isRequired,
    expanded: propTypes.string,
    identifierSparePartDelete :propTypes.bool,
    isSaving :propTypes.bool,
    /**Function invoke callback for create a spart part manual */
    handleChange: propTypes.func.isRequired,
     /**Redirects to the spare part view when you don't want to create a new spare part*/
     onSearchSerie: propTypes.func
}

export default ExpansionComponent