/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/** Material UI import section */
import {
    Button,
} from '@material-ui/core';

import { ButtonCreateStyles } from '../../../resources/styles/ButtonCreateStyles'

const ButtonCancel = (props) => {
    const { isSaving, onCancelClick, title } = props;
    const classes = ButtonCreateStyles();

    const onButtonCancelClick = () => {
        if (onCancelClick) {
            onCancelClick()
        }
    }

    return (
        <Button
            color='default'
            variant='outlined'
            disabled={isSaving}
            className={classes.btnCancel}
            onClick={onButtonCancelClick}
        >
            {title}
        </Button>
    )
}

ButtonCancel.propTypes = {
    /**Confirm the operation to be performed*/
    onCancelClick: propTypes.func.isRequired,
    /**Determinate if saving an operation */
    isSaving: propTypes.bool,
    /**Determinate el title of button */
    title: propTypes.string.isRequired,
}

ButtonCancel.defaultProps = {
    isSaving: false,
    title: '',
    onCancelClick: () =>
        console.warn('Callback [onCancelClick] no defined'),

}

export default ButtonCancel
