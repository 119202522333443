import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { buildUserManager, setUserMananger } from './store/helpers/UserManager'
import RootView from './views/RootView'
import App from './App'
import CallbackPage from './views/home/CallbackPage'

fetch('../../../web.config.json')
	.then((response) => response.json())
	.then((config) => {
		
		// Create the usermananger and sets in window global 
		window.config = config
		let userManager = buildUserManager(config)
		setUserMananger(userManager)

		ReactDOM.render(
			<RootView>
				<BrowserRouter basename={`${config.applicationRoot}`}>
					<Switch>
						<Route
							exact
							path='/callback'
							component={CallbackPage}
						/>
						<Route path='/' component={App} />
					</Switch>
				</BrowserRouter>
			</RootView>,
			document.getElementById('root')
		)
	})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
