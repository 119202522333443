import { fromJS } from 'immutable'
import moment from 'moment'
import {
	SET_NETWORKDAYS_CHART,
	SET_SERVICE_SORTING_CRITERIA,
	SET_SALESREPRENTATIVES_SORTING_CRITERIA,
	SET_CUSTOMERS_SORTING_CRITERIA,
	SET_SERVICE_PROVIDER_FILTER,
	SET_CUSTOMER_FILTER,
	SET_SALES_REPRESENTATIVE_FILTER,
	SET_START_AND_END_DATE,
	SET_SERVICE_PROVIDER_QUERY,
	SET_SALES_REPRESENTATIVE_QUERY,
	SET_CUSTOMER_QUERY
} from '../networkdays/networkdaysActions'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'

const initialServiceState = fromJS({
	isLoading: false,
	startDate: moment().startOf('year'),
	endDate: moment().add(10, 'day'),
	services: [],
	serviceProvidersNetworkDays: [],
	salesRepresentativeNetworkDays: [],
	servicesSortingCriteria: {
		by: 'Average',
		direction: 'desc'
	},
	serviceProviderFilter: null,
	serviceProviderQuery: null,
	customerFilter: null,
	customerQuery: null,
	salesRepresentativeFilter: null,
	salesRepresentativeQuery: null,
	salesRepresentativesSortingCriteria: {
		by: 'Average',
		direction: 'desc'
	},
	customersSortingCriteria: {
		by: 'Average',
		direction: 'desc'
	}
})

/**
 * Service reducer
 */
const serviceReducer = (state = initialServiceState, action) => {
	switch (action.type) {
		case SET_NETWORKDAYS_CHART:
			return state.merge({
				services: fromJS(action.services),
				serviceProvidersNetworkDays: fromJS(
					action.serviceProvidersNetworkDays
				),
				salesRepresentativeNetworkDays: fromJS(
					action.salesRepresentativeNetworkDays
				)
			})
		case SET_SERVICE_SORTING_CRITERIA:
			return state.merge({
				servicesSortingCriteria: fromJS(action.criteria)
			})
		case SET_SALESREPRENTATIVES_SORTING_CRITERIA:
			return state.merge({
				salesRepresentativesSortingCriteria: fromJS(action.criteria)
			})
		case SET_CUSTOMERS_SORTING_CRITERIA:
			return state.merge({
				customersSortingCriteria: fromJS(action.criteria)
			})
		case SET_SERVICE_PROVIDER_FILTER:
			return state.merge({
				serviceProviderFilter: isNullOrEmpty(action.serviceProvider)
					? null
					: action.serviceProvider
			})
		case SET_CUSTOMER_FILTER:
			return state.merge({
				customerFilter: isNullOrEmpty(action.customer)
					? null
					: action.customer
			})
		case SET_CUSTOMER_QUERY:{
			return state.merge({
				customerQuery: isNullOrEmpty(action.queryCustomer)
					? null
					: action.queryCustomer
			})
		}
		case SET_SALES_REPRESENTATIVE_FILTER:
			return state.merge({
				salesRepresentativeFilter: isNullOrEmpty(action.salesRepresentative)
					? null
					: action.salesRepresentative
			})
		case SET_START_AND_END_DATE:
			return state.merge({
				startDate: fromJS(action.startDate),
				endDate: fromJS(action.endDate),
			})
		case SET_SERVICE_PROVIDER_QUERY:{
			return state.merge({
				serviceProviderQuery: isNullOrEmpty(action.queryProvider)
					? null
					: action.queryProvider
			})
		}
		case SET_SALES_REPRESENTATIVE_QUERY:{
			return state.merge({
				salesRepresentativeQuery: isNullOrEmpty(action.queryRepresentative)
					? null
					: action.queryRepresentative
			})
		}
		default:
			return state
	}
}
export default serviceReducer
