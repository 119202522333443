/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/** Material UI import section */
import {Fab,Typography, Chip,
    List,
    ListItem ,
    ListItemSecondaryAction ,
    ListItemText ,
    Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';

/** Import icons */
import inconWarrantyActive from '../../../resources/images/warrantyActive.svg';
import inconWarrantyDesactive from '../../../resources/images/warrantyDesactive.svg';
import inconWarrantyPartial from '../../../resources/images/warrantyPartial.svg';

/** Import componet */
import { panelLateralStyles } from '../../../resources/styles/PanelLateralStyles';

const WarrantyChecksPanel = (props) => {
    /** props sections */
    const classes = panelLateralStyles();
    const { equipment, onCloseButtonClick, serviceType} = props;

    /**
     * Function to get icons
     * @param {*} WarrantyStatus 
     */
    const getIncon = (WarrantyStatus) =>{
        if(WarrantyStatus ==="UnderWarranty")
            return inconWarrantyActive;
        if(WarrantyStatus === "OutWarranty")
            return inconWarrantyDesactive;
        else
            return inconWarrantyPartial;
    }

    /**
     * Get description pre-validation
     * @param {*} warranty 
     */
    const getPrevalidation = (warranty) => {
        if(warranty.DaysWarranty === 0)
            return "DOA";
        else if(warranty.PreValidation)
            return "UNDER WARRANTY";
        else 
            return "OUT OF WARRANTY";
    }

     /**
     * Get title warranty status
     * @param {*} WarrantyStatus 
     */
    const getTitleWarranty = (WarrantyStatus) =>{
        if(WarrantyStatus ==="UnderWarranty")
            return "UNDER WARRANTY";
        if(WarrantyStatus === "OutWarranty")
            return "OUT OF WARRANTY";
        else
            return "EXPIRATION WARRANTY";
    }

    const getSetviceType =(equipmentServiceType) =>{
        if(serviceType === 1)
            return ("CC (FOR COCA COLA)")
        else if(serviceType === 2)
            return ("MICRO MARKET (MM)")
        if(serviceType === 0){
            if(equipmentServiceType === 1)
            return ("CC (FOR COCA COLA)")
        else if(equipmentServiceType === 2)
            return ("MICRO MARKET (MM)")
        }
        else return ("")
    }

    return (
        <>
            <div className={classes.panelHeader}>
                <Tooltip title={getTitleWarranty(equipment.WarrantyStatus)}>
                    <img src={getIncon(equipment ? equipment.WarrantyStatus : "")}
                        alt='warranty-icon'
                        className={classes.iconPanel}
                    />
                </Tooltip> 
                <Typography gutterBottom variant="subtitle2" className={classes.title}>
                    {"PARTS WARRANTY"} <br/>
                    {"SERIAL: "} {equipment && equipment.Serie}<br/>
                    {"SERVICE: "} {equipment && getSetviceType(equipment.ServiceType)}<br/>
                </Typography>
                <div className={classes.grow}></div>
                <Fab
                    className={classes.buttonClose}
                    onClick={onCloseButtonClick}
                >
                    <DeleteIcon />
                </Fab>
            </div>

            <List className={classes.contentPanelLsit}>
                {equipment.WarrantyChecks.map((warranty) => {
                    return (<ListItem key={warranty.Token}>
                        <ListItemText 
                            secondary={<Typography variant="subtitle2">{warranty.Name}</Typography>}
                        />
                        <ListItemSecondaryAction component="span">
                            <Chip 
                                size="small" 
                                component="span"
                                className={warranty.PreValidation ? classes.iconColorGreen : classes.iconColorRed}
                                label={getPrevalidation(warranty)}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    )
                })}
                {equipment.WarrantyChecks.length === 0 &&
                <ListItem  key={"warranties"} >
                    <ListItemText primary={"Series without warranty checklist to display"}/>
                </ListItem>
                }
            </List>
        </>
    )
}

WarrantyChecksPanel.propTypes = {
    /**Object equipment */
    equipment: propTypes.object.isRequired,
    /**Function invoke callback for close panel warranty for equipment */
    onCloseButtonClick: propTypes.func.isRequired,
}
WarrantyChecksPanel.defaultProps = {
    equipment: {
        Token: "",
        Serie: "",
        WarrantyChecks:[]
    },
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
}

export default WarrantyChecksPanel
