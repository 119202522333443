

export const dataGridConfiguration = [
	{
		title: 'Code',
		dataSource: 'Code',
		sortable: true,
    },
    {
		title: 'Description',
		dataSource: 'Description',
		sortable: true,
    },
    {
		title: 'Material',
		dataSource: 'Material',
		sortable: true,
    },
    {
		title: 'Description',
		dataSource: 'DescriptionMat',
		sortable: true,
	},
	
]

export default dataGridConfiguration
