import { createSelector } from 'reselect'
import { toSafeObject } from '../../store/helpers/StateHelper'

/**
 * Get the spare part request opened
 */
export const sparePartSaleIndexSelector = createSelector(
	(state) => state.sparePartSales.get('index'),
	(requestMap) => {
		return toSafeObject(requestMap)
	}
)

/**
 * Get the spare part sales opened
 */
 export const sparePartSaleShowSelector = createSelector(
	(state) => state.sparePartSales.get('show'),
	(showRequestMap) => {
		let showRequest = toSafeObject(showRequestMap)
		// Define all the equipments included in the create view
		// TODO Mostrar equipos
		// if (showRequest.open) {
		// 	showRequest.equipments = showRequest.open.Equipments.map((equip) => {
		// 		equip.spareParts = showRequest.open
		// 			? showRequest.open.SpareParts.filter(sp => sp.Equipment.Serie === equip.Serie)
		// 			: []
		// 		return equip
		// 	})
		// }

		return showRequest
	}
)