/**Import react section */
import React from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'

/**Import components sections */
import headerConfig from '../resources/ProvidersTableConfig'
import SearchBox from '../../common/SearchToolbox'
import RenderContentIndex from '../../common/renderContentIndex/RenderContentIndex'
import LockButton from './LockButton'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import Pagination from '../../common/Pagination/Pagination'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import { ServiceProviderStatus } from '../../../store/helpers/AppConstants'
import AvatarCustomSize from '../../common/AvatarCustomSizes'

/**Import material UI Section */
import {
	Typography,
	ButtonGroup,
	Tooltip,
	Button,
	Chip,
	ListItem,
	ListItemText,
	ListItemIcon,
	Avatar
} from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import GridIcon from '@material-ui/icons/GridOn'
import AddIcon from '@material-ui/icons/Add'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import LockOpenMeteriaIcon from '@material-ui/icons/LockOpen'
import EmailIcon from '@material-ui/icons/Email'
import EmailNoSendIcon from '@material-ui/icons/CancelScheduleSend'
import EmailReadIcon from '@material-ui/icons/EmailTwoTone'
import EmailResendIcon from '@material-ui/icons/Send'
import AddAttachmentIcon from '@material-ui/icons/CreateNewFolder'
import AcceptedByCompanyIcon from '@material-ui/icons/CheckCircle'
import RejectedByCompanyIcon from '@material-ui/icons/HighlightOff'

/**Import  */
import LockIcon from '../../../resources/images/lock/Lock.svg'
import LockOpenIcon from '../../../resources/images/lock/LockOpen.svg'
import Authorization from '../../common/AuthorizationEvaluation'

//roles
import { PORTAL_ADMINISTRATOR } from '../../../resources/RolesEnum'
import { hasAuthorization } from '../../../store/session/sessionActions'

/**
 * Desktop view for the list on service providers
 */
const DesktopView = (props) => {
	/**Functions */
	const {
		onChangePage,
		onEditServiceProviderClick,
		switchViewMode,
		onAddLateralPanelConfig,
		onViewUserClic,
		onLocked,
		onSendInvitationButtonClick,
		onRedirectToProviderDocuments,
		onResendInvitation,
		onDownloadReport,
		userProfile
	} = props
	/**Arrays props sections */
	const { serviceProviders } = props
	/**Objects props sections */
	const { pagination, loggedUserProfile } = props
	/**bool props section */
	const { gridMode, isSaving, identifierLockSeleted } = props
	/**string props section */
	const { identifierCardSeleted } = props
	const classes = useDataGridToolbarStyle()

	const onRowClick = (sender, item) => {
		if (props.onEditServiceProviderClick) {
			props.onEditServiceProviderClick(item)
		}
	}

	const GetIconByStatus = (status) => {
		switch (Number(status)) {
			case ServiceProviderStatus.INVITATION_SEND:
				return <EmailReadIcon />
			case ServiceProviderStatus.SUPPLIER_ACCEPTED:
				return <AddAttachmentIcon />
			case ServiceProviderStatus.ACCEPTED_IN_COMPANY:
				return <AcceptedByCompanyIcon />
			case ServiceProviderStatus.REJECTED_IN_COMPANY:
				return <RejectedByCompanyIcon />
			default:
				return <EmailNoSendIcon />
		}
	}

	const GetClassByStatus = (status) => {
		switch (Number(status)) {
			case ServiceProviderStatus.INVITATION_SEND:
				return classes.invitationSendStatus
			case ServiceProviderStatus.SUPPLIER_ACCEPTED:
				return classes.supplierAcceptedStatus
			case ServiceProviderStatus.ACCEPTED_IN_COMPANY:
				return classes.acceptedInCompanyStatus
			case ServiceProviderStatus.REJECTED_IN_COMPANY:
				return classes.rejecteInCompanyStatus
			default:
				return classes.invitationNoSendStatus
		}
	}

	/** Render menu options for list in mode cards*/
	const renderOptionMenu = (user) => {
		let isDisabledOption = loggedUserProfile.userName !== user.UserName ? false : true
		return (
			<>
				{
					<ListItem
						button
						data-cy='item-remove-access'
						disabled={isSaving || isDisabledOption}
						onClick={(event) => onLocked(user.Token, !user.Locked, event)}
					>
						<ListItemIcon>
							{user.Locked ? (
								<img src={LockOpenIcon} alt='icon' />
							) : (
								<LockOpenMeteriaIcon />
							)}
						</ListItemIcon>
						<ListItemText
							primary={
								user.Locked ? 'Unlock service provider' : 'Block service provider'
							}
						/>
					</ListItem>
				}
			</>
		)
	}

	/**
	 * Get the header configuration
	 */
	const getHeaderConfiguration = () => {
		let avatarProperty = headerConfig.find((x) => x.dataSource === 'avatar')
		avatarProperty.onRenderProperty = (item) => {
			return (
				<AvatarCustomSize
					name={item.Name}
					avatarUrl={item.AvatarUrl}
					avatarStyle={classes.avatar}
					isAvatarFromIdentity={false}
				/>
			)
		}

		let detailsProperty = headerConfig.find((x) => x.dataSource === 'Details')

		detailsProperty.onRenderProperty = (item) => {
			return (
				<>
					{!item.RequieredTermsAccepted && (
						<Chip
							label='View users'
							className={item.Locked ? classes.chipLocked : classes.chipView}
							onClick={() => onViewUserClic(item)}
							disabled={item.Locked}
						/>
					)}

					{item.RequieredTermsAccepted && (
						<Chip
							label='View users'
							className={classes.chipView}
							onClick={() => onViewUserClic(item)}
							disabled={true}
						/>
					)}
				</>
			)
		}

		let sendInvitationProperty = headerConfig.find((x) => x.dataSource === 'SendInvitation')

		sendInvitationProperty.onRenderProperty = (item) => {
			if (item.Status === 0) {
				return (
					<Chip
						icon={<EmailIcon className={classes.sendInvitationIcon} />}
						label='Send invitation'
						className={classes.sendInvitation}
						onClick={(event) => onSendInvitationButtonClick(event, item.Code)}
					/>
				)
			} else if (item.Status === 1) {
				return (
					<Chip
						icon={<EmailResendIcon className={classes.sendInvitationIcon} />}
						label='Resend invitation'
						className={classes.sendInvitation}
						onClick={(event) => onResendInvitation(event, item)}
					/>
				)
			} else if (item.Status === 2 || item.Status === 3 || item.Status === 4) {
				return (
					<Chip
						label='View Documents'
						className={classes.sendInvitation}
						onClick={() => onRedirectToProviderDocuments(item)}
					/>
				)
			} else {
				return (
					<Chip
						label='Registration pending'
						className={classes.sendInvitation}
						disabled={true}
					/>
				)
			}
		}

		let lockedServiceProperty = headerConfig.find((x) => x.dataSource === 'LockedService')

		
		lockedServiceProperty.onRenderProperty = (item) => {
			const isAuthorized = hasAuthorization(props.userProfile, [PORTAL_ADMINISTRATOR])

			return (
				isAuthorized ?
					<LockButton
						icon={item.Locked ? LockOpenIcon : LockIcon}
						onLocked={(event) => onLocked(item.Token, !item.Locked, event)}
						isLocked={item.Locked}
						title={item.Locked ? 'Unlock service provider' : 'Block service provider'}
						disabled={identifierLockSeleted === item.Token}
					/>
					: item.Locked ? 'Unlock service provider' : 'Block service provider'
			)
		}

		let statusProperty = headerConfig.find((x) => x.dataSource === 'Status')

		statusProperty.onRenderProperty = (item) => {
			return (
				<Tooltip title={item.StatusDescription}>
					<Avatar className={GetClassByStatus(item.Status)}>
						{GetIconByStatus(item.Status)}
					</Avatar>
				</Tooltip>
			)
		}

		return headerConfig
	}

	/**Render content in cads o en mode list */
	const renderContent = () => {
		return (
			<RenderContentIndex
				headerConfig={getHeaderConfiguration()}
				isLoading={props.isLoading}
				pagination={pagination}
				onRowClick={onRowClick}
				data={serviceProviders}
				gridMode={gridMode}
				onChangeSortingCriteria={props.onChangeSortingCriteria}
				searchValue={props.searchValue}
				titleData={'There is no added data, please add a service provider.'}
				onEditClick={onEditServiceProviderClick}
				isAvatarFromIdentity={false}
				identifierCardSeleted={identifierCardSeleted}
				isActiveButtonOptions={true}
				renderOptionMenu={renderOptionMenu}
			/>
		)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>
							Administration / Imbera service providers
						</Typography>
					</HeaderBreadCrum>
				}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<DataGridToolbar
						onChange={props.onChangeSearchValue}
						searchValue={props.searchValue}
						switchViewMode={switchViewMode}
						gridMode={gridMode}
						onAddLateralPanelConfig={onAddLateralPanelConfig}
						onDownloadReport={onDownloadReport}
						userProfile={userProfile}
					/>
					{renderContent()}
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination
							count={pagination.total ? pagination.total : 0}
							page={pagination.pageNumber ? pagination.pageNumber : 0}
							onChangePage={onChangePage}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}
DesktopView.propTypes = {
	/**
	 * The imbera service Provider's list
	 */
	serviceProviders: propTypes.arrayOf(
		propTypes.shape({
			Name: propTypes.string.isRequired,
			Code: propTypes.string.isRequired
		})
	),
	/**
	 * Determine if the user list's is loading
	 */
	isLoading: propTypes.bool.isRequired,

	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * On change sort criteria callback
	 */
	onChangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * On change search value
	 */
	onChangeSearchValue: propTypes.func.isRequired,
	/**
	 * On change number of page
	 */
	onChangePage: propTypes.func.isRequired,
	/**
	 * Opens side panel to edit a service provider's information
	 */
	onEditServiceProviderClick: propTypes.func.isRequired,
	/**
	 * Close lateral panel for edit information from service provider
	 */
	onClosedLateralPanelEdit: propTypes.func.isRequired,

	/**
	 * The object contains the paginated
	 */
	pagination: propTypes.shape({
		/**
		 * Determine the initial sort by column
		 */
		orderBy: propTypes.string,
		/**
		 * Defines the initial sort direction
		 */
		orderType: propTypes.oneOf(['asc', 'desc']),
		/**
		 * Defines the total de retails pagination
		 */
		total: propTypes.number,
		/**
		 * Defines the page number pagination
		 */
		pageNumber: propTypes.number
	}),

	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool,
	/**
	 * open panel lateral for add serviceProvider
	 */
	onAddLateralPanelConfig: propTypes.func.isRequired,
	/**
	 * Panel lateral open for send invitation user from service provider
	 */
	onSendInvitationButtonClick: propTypes.func.isRequired
}

DesktopView.defaultProps = {
	serviceProviders: [],
	isLoading: false,
	gridMode: false,
	searchValue: '',
	pagination: {
		orderBy: '',
		orderType: 'asc',
		total: 0,
		pageNumber: 1
	},

	onChangeSortingCriteria: () => console.warn('Callback [onChangeSortingCriteria] no defined'),
	onChangeSearchValue: () => console.warn('Callback [onChangeSearchValue] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined'),
	onClosedLateralPanelEdit: () => console.warn('Callback [onClosedLateralPanelEdit] no defined'),
	onEditServiceProviderClick: () =>
		console.warn('Callback [onEditServiceProviderClick] no defined'),
	onChangePage: () => console.warn('Callback [onChangePage] no defined'),
	onAddLateralPanelConfig: () => console.warn('Callback [onAddLateralPanelConfig] no defined'),
	onSendInvitationButtonClick: () =>
		console.warn('Callback [onSendInvitationButtonClick] no defined')
}
export default withImmutablePropsToJS(DesktopView)

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()
	return (
		<div className={classes.searchIndex}>
			<SearchBox
				placeholder='Search service provider'
				onChange={props.onChange}
				value={props.searchValue}
			/>
			<ButtonGroup
				component='span'
				size='small'
				variant='outlined'
				color='primary'
				className={classes.buttonsGroupContainer}
			>
				<Tooltip component='span' title='Download list of providers'>
					<Button className={classes.buttonsGroup} onClick={props.onDownloadReport}>
						<CloudDownloadIcon />
					</Button>
				</Tooltip>
				<Tooltip component='span' title='Mode list'>
					<Button
						className={classes.buttonsGroup}
						disabled={!props.gridMode}
						onClick={props.switchViewMode}
					>
						<ListIcon />
					</Button>
				</Tooltip>
				<Tooltip component='span' title='Mode card'>
					<Button
						className={classes.buttonsGroup}
						disabled={props.gridMode}
						onClick={props.switchViewMode}
					>
						<GridIcon />
					</Button>
				</Tooltip>

				<Authorization profile={props.userProfile} allowedRoles={[PORTAL_ADMINISTRATOR]}>
					<Tooltip component='span' title='Add Service Provider'>
						<Button
							className={classes.buttonsGroup}
							onClick={props.onAddLateralPanelConfig}
						>
							<AddIcon />
						</Button>
					</Tooltip>
				</Authorization>
			</ButtonGroup>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool
}

DataGridToolbar.defaultProps = {
	gridMode: false,
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined')
}
