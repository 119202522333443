/**Import react section */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

/**Import Material UI section */
import { Stepper, Step, StepLabel, Typography, Card } from '@material-ui/core'

/**Import resources section */
import { useSerialHistoryDesktopStyle } from '../resources/useStyles'
// import { GetServiceStatus , GetColorNetWorkDays } from '../../../store/helperss/StatusHelper'
import { GetServiceStatus , GetColorNetWorkDays } from '../../../store/helpers/StatusHelper'

const ServiceTimeLine = (props) => {
    const { serviceHistory } = props;
    const classes = useSerialHistoryDesktopStyle();
    let history = useHistory()
    const [activeStep] = useState(0)

    const onRedirectToService = (token, serie) => {
        history.push(`/services/${token}/${serie}`)
    }

    return (
        <div >
            <Card className={classes.serviceHeader}>
                <Typography className={classes.serviceHeaderTicket}>{`Ticket`}</Typography>
                <Typography className={classes.serviceHeaderDate}>{`Creation date`}</Typography>
                <Typography className={classes.serviceHeaderStatus}>{`Serie`}</Typography>
                <Typography className={classes.serviceHeaderDescription}>{`Model`}</Typography>
                <Typography className={classes.serviceHeaderUltim}>{`Status`}</Typography>
                <Typography className={classes.serviceHeaderItem7}>{`NetworkDays`}</Typography>
            </Card>
            <br />

            <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepperContainer}>
                {serviceHistory.map((service, index) => {
                    return (
                        <Step key={index} onClick={() => onRedirectToService(service.Token, service.Serie)}>
                            <StepLabel className={classes.stepLabel}>
                                <div className={classes.serviceContent} >
                                    <Typography className={classes.serviceHeaderTicket}>{service.Ticket}</Typography>
                                    <Typography className={classes.serviceHeaderDate}>{service.IntiatedDate}</Typography>
                                    <Typography className={classes.serviceHeaderStatus}>{service.Serie}</Typography>
                                    <Typography className={classes.serviceHeaderDescription}>{service.Model}</Typography>
                                    <div className={classes.serviceHeaderUltim}>{GetServiceStatus(service.Status)}</div>
                                    <div className={classes.serviceHeaderItem7}>{GetColorNetWorkDays(service.NetworkDays)}</div>
                                </div>
                            </StepLabel>
                        </Step>
                    )

                })}
            </Stepper>
        </div>

    )
}

export default ServiceTimeLine