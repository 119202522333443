import { makeStyles } from '@material-ui/styles'

export const useCreateDesktopStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        maxHeight: `calc(100vh - ${85}px)`,
        overflowY: 'auto'

    },
    buttonActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px"
    },
    container: {
        padding: "5px",
    },
}))