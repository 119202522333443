/**Import react section */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

import { addServiceProvider, addUserInServiceProvider, sendInvitationToServiceServiceProvider } from '../../store/serviceproviders/serviceProvidersActions'


/**Import components section  */
import AddLateralPanel from './components/AddLateralPanel'
import AddUserInProviderPanel from './components/AddUserInProviderPanel'
import SendInvitationLateralPanel from './components/SendInvitationLateralPanel'
import Toaster from '../common/Toaster'

const Create = (props) => {
    const { onAddLateralPanelConfig, addPanelConfiguration, isSaving,
        addUserInProviderPanelConfig, onAddUsersProvideOpenClosePanel,
        sendInvitationPanelConfiguration, onSendInvitationButtonClick } = props;
        const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    /**Hooks sections */
    const dispatch = useDispatch()

    const onAddServiceProvider = (serviceProviderModel) => {
        dispatch(addServiceProvider(serviceProviderModel))
            .then(_ => {
                onAddLateralPanelConfig()
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to create service provider', variant: 'warning' })
            });
    }

    const onAddUserInServiceProvider = (serviceProviderModel, code) => {
        dispatch(addUserInServiceProvider(serviceProviderModel, code))
            .then(_ => {
                onAddUsersProvideOpenClosePanel()
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to create service provider', variant: 'warning' })
            });
    }

    const onSendInvitationUser = (serviceProviderModel) => {
        return dispatch(sendInvitationToServiceServiceProvider(serviceProviderModel, sendInvitationPanelConfiguration? sendInvitationPanelConfiguration.accountCode: ""))
            .then(_ => {
                onSendInvitationButtonClick()
                setToasterOptions({ open: true, message: "Invitation sent successfully", variant: 'success' })
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: 'Error to send invitation', variant: 'error' })
            });
    }


    return (
        <>
            {
                (addPanelConfiguration && addPanelConfiguration.opened) &&
                <AddLateralPanel
                    onSaveButtonClick={onAddServiceProvider}
                    onCloseButtonClick={onAddLateralPanelConfig}
                    isSaving={isSaving}
                />
            }

            {
                (addUserInProviderPanelConfig && addUserInProviderPanelConfig.opened) && <AddUserInProviderPanel
                    onSaveButtonClick={onAddUserInServiceProvider}
                    onCloseButtonClick={onAddUsersProvideOpenClosePanel}
                    isSaving={isSaving}
                />
            }

            {
                (sendInvitationPanelConfiguration && sendInvitationPanelConfiguration.opened) &&
                    <SendInvitationLateralPanel
                        onCloseButtonClick={onSendInvitationButtonClick}
                        onSaveButtonClick={onSendInvitationUser}
                        isSaving={isSaving}

                    />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

Create.propTypes = {
	/**
	 * open lateral panel for add an new service provider
	 */
    onAddLateralPanelConfig: propTypes.func,

    /**
    * open lateral panel for add an new service provider
    */
    onAddUsersProvideOpenClosePanel: propTypes.func,

    /**
	 * Determinate if is saving data
	 */
    isSaving: propTypes.bool.isRequired,

    sendInvitationPanelConfiguration: propTypes.shape({
        opened: propTypes.bool,
        accountCode: propTypes.string
    }),

    /**
    * Close lateral panel for send invitation
    */
   onSendInvitationButtonClick: propTypes.func,
}


Create.defaultProps = {
    sendInvitationPanelConfiguration: {
        opened: false,
        accountCode:''
    },
    onAddLateralPanelConfig: () =>
        console.warn('Callback [onAddLateralPanelConfig] no defined'),
    onAddUserInProviderPanelConfig: () =>
        console.warn('Callback [onAddUserInProviderPanelConfig] no defined'),
    onAddUsersProvideOpenClosePanel: () =>
        console.warn('Callback [onAddUsersProvideOpenClosePanel>] no defined'),
    onSendInvitationButtonClick: () =>
        console.warn('Callback [onSendInvitationButtonClick>] no defined'),
    isSaving: false
}

export default Create