import React, { useEffect, useState } from 'react'

import {
    FormGroup,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
} from "@material-ui/core";


const NotificationsButton = (props) => {

    const { onActiveNotifications, user } = props
    const [enabledNotifications, setEnabledNotifications] = useState(false)

    const onChangeEnable = (value) => {
        setEnabledNotifications(value)
        onActiveNotifications(user.Token, value)
    }

    useEffect(() => {
        if (user) {
            setEnabledNotifications(user.ActiveNotifications)
        }
    }, [user, setEnabledNotifications])

    return (
        <div>
            <Box width="80%" display='flex' justifyConten='center'>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={enabledNotifications} color="primary" onChange={(event) => onChangeEnable(event.target.checked)} />}
                        label={
                            <Typography variant='body1'>
                                Enable Notifications
                            </Typography>
                        } />
                </FormGroup>
            </Box>
        </div>
    )
}

export default NotificationsButton