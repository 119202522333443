import { makeStyles } from '@material-ui/styles'

/**
 * custom sales representative style
 */
export const salesRepresentativeStyles = makeStyles((theme) => ({
    avatar: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.colors.purpleDark
    },
    texboxContainer: {
        padding: "10px",
        backgroundColor: theme.palette.colors.purpleDark
    },
    controlRadioContainer: {
        padding: "20px",
        backgroundColor: theme.palette.colors.purpleDark,
        color: theme.palette.common.white
    },
    AddressContainer: {
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: theme.palette.colors.purpleDark
    },
    lateralPanelForm: {
        overflowY: 'auto',
    },
    radioTitle: {
        color: theme.palette.common.white,
        '&$focused': {
            color: theme.palette.common.white,
        },
    },
}))