import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Button, Checkbox  } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

/**
 * This component contains the datagrid BaseConfigurator
 */
const BaseConfigurator = (props) => {
	const [localConfiguration, setLocalConfiguration] = useState([])
	const classes = useStyles({ maxHeight: props.maxHeight })

	useEffect(() => {
		let config = props.configuration.map((columnConfig) => {
			return { ...columnConfig }
		})
		setLocalConfiguration(config)
	}, [props.configuration])

	/**
	 * On change visible value for a column configuration
	 */
	const onChangeVisible = (title, value) => {
		let config = localConfiguration.map((columnConfig) => {
			if (columnConfig.title === title)
				return { ...columnConfig, visible: value }
			else return { ...columnConfig }
		})
		setLocalConfiguration(config)
	}

	/**
	 * Move an item from a location to another
	 * @param {*} from
	 * @param {*} to
	 */
	const onMoveItem = (from, to) => {
		let itemFrom = localConfiguration[from]
		let itemTo = localConfiguration[to]
		localConfiguration[from] = { ...itemTo }
		localConfiguration[to] = { ...itemFrom }
		setLocalConfiguration([...localConfiguration])
	}

	/**
	 * Render configuration
	 */
	return (
		<div className={classes.root}>
			<div className={classes.columItemList}>
				{localConfiguration.map((columnConfig, index) => {
					return (
						<ColumnItem
							{...columnConfig}
							index={index}
							count={localConfiguration.length}
							key={columnConfig.dataSource}
							onChangeVisible={onChangeVisible}
							onMoveItem={onMoveItem}
						/>
					)
				})}
			</div>
			<div className={classes.acceptButton}>
				<Button
					size='small'
					fullWidth
					color={'primary'}
					variant='contained'
					onClick={() =>props.onConfigurationChange(localConfiguration)}
				>
					{props.saveButtonText}
				</Button>
			</div>
		</div>
	)
}
BaseConfigurator.propTypes = {
	/**
	 * The table configuration with visible and index properties
	 */
	configuration: propTypes.arrayOf(
		propTypes.shape({
			title: propTypes.string.isRequired,
			visible: propTypes.bool.isRequired
		})
	).isRequired,
	/**
	 * Callback executed when the user save the configuration
	 */
	onConfigurationChange: propTypes.func.isRequired,
	/**
	 * The max height of the columns configuration container
	 */
	maxHeight: propTypes.string,
	/**
	 * The text in the save button
	 */
	saveButtonText: propTypes.string.isRequired
}
BaseConfigurator.defaultProps = {
	configuration: [],
	onConfigurationChange: () =>
		console.warn('No [onConfigurationChange] callback defined'),
	saveButtonText: 'Save Configuration'
}
export default BaseConfigurator

/**
 * A header item
 */
const ColumnItem = (props) => {
	const classes = useStyles()

	/**
	 * Return header item
	 */
	return (
		<div className={classes.columnItem}>
			<div className={classes.moveButtonContainer}>
				<IconButton
					disabled={props.index === 0}
					size='small'
					onClick={() =>
						props.onMoveItem(props.index, props.index - 1)
					}
				>
					<ArrowDropUpIcon fontSize='inherit' />
				</IconButton>
				<IconButton
					disabled={props.index === props.count - 1}
					size='small'
					onClick={() =>
						props.onMoveItem(props.index, props.index + 1)
					}
				>
					<ArrowDropDownIcon fontSize='inherit' />
				</IconButton>
			</div>
			<Typography variant='caption'>{props.title}</Typography>
			<div className={classes.visibilityContainer}>
				<Checkbox
					size='small'
					color='primary'
					checked={props.visible === true ? true : false}
					onChange={(event) =>
						props.onChangeVisible(props.title, event.target.checked)
					}
				/>
			</div>
		</div>
	)
}
ColumnItem.propTypes = {
	title: propTypes.string.isRequired,
	visible: propTypes.bool.isRequired,
	index: propTypes.number.isRequired,
	count: propTypes.number.isRequired,
	onChangeVisible: propTypes.func,
	onMoveItem: propTypes.func
}
ColumnItem.defaultProps = {
	title: '',
	visible: true,
	index: 0
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateRows: 'auto 40px'
	},
	columItemList: {
		gridRow: '1 / 2',
		overflowY: 'auto',
		maxHeight: ({ maxHeight }) => `${maxHeight}`
	},
	acceptButton: {
		//marginLeft: 'auto',
		//marginRight: 'auto',
		backgroundColor: theme.palette.primary.main,
		marginTop: '10px',
		gridRow: '2 / 3'
	},
	columnItem: {
		border: '1px solid #f0f0f0',
		padding: '10px',
		marginBottom: '5px',
		display: 'flex',
		alignItems: 'center'
	},
	moveButtonContainer: {
		marginRight: '10px'
	},
	visibilityContainer: {
		marginLeft: 'auto'
	}
}))
