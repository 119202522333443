import React, { useState } from 'react';
import PropTypes from 'prop-types';

/** Material UI import section */
import {
    makeStyles,
    Box,
    Divider,
    Paper,
    Tabs,
    Tab,
} from '@material-ui/core';

/**
 * Presentional componet to show the suppliers on tabs
 * 
 * @param {*} props 
 */
const TabsWrapper = props => {
    const { defaultTab, disabled, onTabChange, onRenderTabContent, tabs, tabContentStyle } = props;
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0].code);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
        if (onTabChange) {
            onTabChange(newValue);
        }
    };

    const getTabPanel = item => {
        let customContent;
        if (onRenderTabContent) {
            customContent = onRenderTabContent(item);
        }
        return (
            <TabPanel
                key={item.code}
                code={item.code}
                value={selectedTab}
                style={tabContentStyle}
            >
                {customContent || item.content}
            </TabPanel>
        );
    }

    return (
        <Box id="tabs-container" className={classes.root}>
            <Paper>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    className={props.className ||classes.tabs}
                >
                    {
                        tabs.map(t => (
                            <Tab
                                className={ props.className ||classes.tab}
                                key={t.code}
                                value={t.code}
                                label={t.label}
                                disabled={t.disabled || disabled}
                                {...getTabProps(t.code)}
                            />
                        ))
                    }
                </Tabs>
            </Paper>
            <Divider />
            {
                tabs.map(t => getTabPanel(t))
            }
        </Box>
    );
}
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '90%',
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
    },
}));

TabsWrapper.propTypes = {
    defaultTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    onTabChange: PropTypes.func,
    onRenderTabContent: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        content: PropTypes.node
    })).isRequired
};

TabsWrapper.defaultProps = {
    disabled: false
}

export default React.memo(TabsWrapper);

/**
 * Presentional component to show a tab panel
 * 
 * @param {*} props 
 */
const TabPanel = props => {
    const { children, value, code, style, ...other } = props;
    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== code}
            id={`tabpanel-${code}`}
            aria-labelledby={`tab-${code}`}
            style={{ width: "100%", height: ((style && style.height) || `calc(100vh - ${180}px)`), overflowY: ((style && style.overflowY) ||  'hidden'), overflowX: 'hidden' }}
            {...other}
        >
            {children}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    code: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const getTabProps = key => {
    return {
        id: `tab-${key}`,
        'aria-controls': `tabpanel-${key}`
    };
}