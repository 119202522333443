/**Import react section */
import React from 'react'
/**Import Material Ui section */
import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

/**Import styles section */
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'

const ToolbarSkeleton = () => {
    const classes = createServicesStyle();
    return (
        <Grid item xs={12} className={classes.headerContainer}>
            <Skeleton animation='wave' variant="circle" width={30} height={30}/>
            <Skeleton animation='wave' variant="rect" width={"100%"} height={28} />
        </Grid>
    )
}

export default ToolbarSkeleton

