/**Import react section */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, Grid } from '@material-ui/core';
/**Import squeletons components section */
import ToolbarSkeleton from '../services/componets/ToolbarSkeleton'
import { useCreateDesktopStyle } from '../../spareParts/resources/useStyles'

const ShowViewSekeleton = () => {
    const classes = useCreateDesktopStyle()
    return (
        <div className={classes.root}>
            <ToolbarSkeleton />
            <div className={classes.containerData}>
                <Card>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            {renderTextBoxSkeleton(12, 30, "100%")}
                            <Skeleton variant="text" />
                            {renderTextBoxSkeleton(12, 30, "100%")}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {renderTextBoxSkeleton(12, 30, "100%")}
                            <Skeleton variant="text" />
                            {renderTextBoxSkeleton(12, 30, "100%")}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Skeleton variant="rect" width={"100%"} height={80} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ListSkeleton/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Skeleton variant="rect" width={"100%"} height={180} />
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </div>
    )
}

const ListSkeleton = () => {
    return (
        <Grid container spacing={1}>
            {renderTextBoxSkeleton(12, 30, "100%")}
            {renderTextBoxSkeleton(12, 30, "100%")}
            <Grid item xs={12}>
                <Skeleton variant="text" />
            </Grid>
            {renderTextBoxSkeleton(12, 30, "100%")}
            {renderTextBoxSkeleton(12, 30, "100%")}
        </Grid>
    )
}

/**Render textBox Squeleton */
const renderTextBoxSkeleton = (size = 6, height, width) => {
    return (
        <Grid item xs={size}>
            <Skeleton variant="rect" animation="wave" width={width} height={height} />
        </Grid>
    )
}

export default ShowViewSekeleton
