/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import moment from 'moment'

/**Import Material UI Section */
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/GridOn';
import { Typography, ButtonGroup, Tooltip, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core'
/**Import component sectcion */
import {
    Layout,
    Content,
    Header,
    HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'
import Pagination from '../../common/Pagination/Pagination'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import RenderContentIndex from '../../common/renderContentIndex/RenderContentIndex'
import SearchBox from '../../common/SearchToolbox'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import UserProfileIcon from '@material-ui/icons/AccountBoxOutlined'
import UserExpiredIcon from '@material-ui/icons/VisibilityOff'
import MailIcon from '@material-ui/icons/Mail';
/**Import resources section */
import userInProviderConfig from '../resources/UserInProviderConfig'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'

const UserInProviderDesktopView = (props) => {
    const { users, switchViewMode, pagination,
        onChangePage, openLateralPanelOnClick,
        identifierCardSeleted, onEditPanelUserConfig,
        onSetPasswordDialogConfiguration,
        onSetResendPasswordDialog,
        onSetUserActiveExpiredDialog,
    } = props;
    /**Objects props sections */
    const { gridMode, loggedUserProfile } = props;
    const { isSaving } = props;
    const classes = useDataGridToolbarStyle();

    /** Render menu options for list in mode cards*/
    const renderOptionMenu = (user) => {
        let isDisabledOption = (loggedUserProfile.userName !== user.UserName) ? false : true
        return (
            <>
                {
                    <ListItem
                        button
                        data-cy='item-remove-access'
                        disabled={isSaving || isDisabledOption}
                        onClick={() => onSetPasswordDialogConfiguration(user)}
                    >
                        <ListItemIcon>
                            <DeleteIcon color='action' />
                        </ListItemIcon>
                        <ListItemText primary={"Remove Access"} />
                    </ListItem>}
            </>

        )
    }

    /**
     * USER EXPIRED COLOR 
     */
    const userExpiredColor = (LastDate) => {
        let actualDate = moment().format();
        let diffe = moment(actualDate).diff(LastDate, 'day');
        if (diffe === null)
            return classes.userExpiredColorGreen;
        if (diffe >= 45)
            return classes.userExpiredColorRed;
        if (diffe >= 30 && diffe <= 15)
            return classes.userExpiredColorYellow;
        else
            return classes.userExpiredColorGreen;
    }

    /**
     * Get the header configuration
     */
    const getHeaderConfiguration = () => {
        let activeProperty = userInProviderConfig.find(
            (x) => x.dataSource === 'Active'
        )
        activeProperty.onRenderProperty = (item) => {
            let isDisabledOption = (loggedUserProfile.userName !== item.UserName) ? false : true
            return (
                <ButtonGroup variant='text' size='small'>
                    <Tooltip component="span" title='View Profile'>
                        <Button
                            onClick={() => onRowClick(item)}
                        >
                            <UserProfileIcon color='primary' />
                        </Button>
                    </Tooltip>
                    <Tooltip component="span" title='Enable user Expired'>
                        <Button
                            onClick={() => onSetUserActiveExpiredDialog(item)}
                        >
                            <UserExpiredIcon className={userExpiredColor(item.LastAuthenticationDate)} />
                        </Button>
                    </Tooltip>
                    <Tooltip component="span" title='Remove Access'>
                        <Button
                            disabled={isDisabledOption}
                            onClick={() => onSetPasswordDialogConfiguration(item)}
                        >
                            <DeleteIcon color='action' />
                        </Button>
                    </Tooltip>
                    <Tooltip component="span" title='Resend Password'>
                        <Button
                            disabled={isDisabledOption}
                            onClick={() => onSetResendPasswordDialog(item)}
                        >
                            <MailIcon className={classes.resendPasswordColorIcon} />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            )
        }
        let avatarProperty = userInProviderConfig.find(
            (x) => x.dataSource === 'avatar'
        )
        avatarProperty.onRenderProperty = (item) => {
            return (
                <AvatarCustomSize
                    name={item.Name}
                    avatarUrl={item.AvatarUrl}
                    avatarStyle={classes.avatar}
                    isAvatarFromIdentity={true}
                />
            )
        }
        let lastDateProperty = userInProviderConfig.find(
            (x) => x.dataSource === 'LastAuthenticationDate'
        )
        lastDateProperty.onRenderProperty = (item) => {
            return (
                `${item.LastAuthenticationDate && moment(item.LastAuthenticationDate).format("MM/DD/YYYY HH:mm:ss")}`
            )
        }
        return userInProviderConfig
    }

    const onRowClick = (item) => {
        if (props.onEditPanelUserConfig) {
            props.onEditPanelUserConfig(item)
        }
    }

    /**Render content in cads o en mode list */
    const renderContent = () => {
        return (
            <RenderContentIndex
                headerConfig={getHeaderConfiguration()}
                isLoading={props.isLoading}
                data={users}
                gridMode={gridMode}
                onChangeSortingCriteria={props.onChangeSortingCriteria}
                searchValue={props.searchValue}
                titleData={"There is no added data, please add a user."}
                sortBy={props.sortBy}
                sortDirection={props.sortDirection}
                onEditClick={onEditPanelUserConfig}
                renderOptionMenu={renderOptionMenu}
                isActiveButtonOptions={true}
                isAvatarFromIdentity={true}
                identifierCardSeleted={identifierCardSeleted}
            />
        )
    }

    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                user={props.user}
                breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>
                            Administration / {props.name}
                        </Typography>
                    </HeaderBreadCrum>
                }
            />
            <Content key={'AuthenticatedContent'}>
                <div className={classes.rootIndex}>
                    <DataGridToolbar
                        onChange={props.onChangeSearchValue}
                        searchValue={props.searchValue}
                        openLateralPanelOnClick={openLateralPanelOnClick}
                        switchViewMode={switchViewMode}
                        gridMode={gridMode}
                    />
                    {renderContent()}
                    <div className={classes.pagination}>
                        <div className={classes.flexGrow} />
                        <Pagination count={pagination.total ? pagination.total : 0} page={pagination.pageNumber ? pagination.pageNumber : 0} onChangePage={onChangePage} />
                    </div>
                </div>


            </Content>
        </Layout>

    )
}

UserInProviderDesktopView.propTypes = {
    /**
     * The imbera users's list
     */
    users: propTypes.arrayOf(
        propTypes.shape({
            Name: propTypes.string.isRequired,
            UserName: propTypes.string.isRequired,
        })
    ),
    /**
     * Determine if the user list's is loading
     */
    isLoading: propTypes.bool.isRequired,

    /**
     * Initial search value
     */
    searchValue: propTypes.string,
    /**
     * On change sort criteria callback
     */
    onChangeSortingCriteria: propTypes.func.isRequired,
    /**
     * On change search value
     */
    onChangeSearchValue: propTypes.func.isRequired,
    /**
     * On change number of page
     */
    onChangePage: propTypes.func.isRequired,
    /**
     * Opens side panel to edit a user's information
     */
    onEditPanelUserConfig: propTypes.func.isRequired,
    /**
     * add user information to dialog password configuration
     */
    onSetPasswordDialogConfiguration: propTypes.func.isRequired,
    /**
     * Resend user password to dialog password configuration
     */
    onSetResendPasswordDialog: propTypes.func.isRequired,

    /**
     * The object contains the paginated  
     */
    pagination: propTypes.shape({

        /**
         * Determine the initial sort by column
         */
        orderBy: propTypes.string,
        /** 
        * Defines the initial sort direction
        */
        orderType: propTypes.oneOf(['asc', 'desc']),
        /** 
        * Defines the total de retails pagination
        */
        total: propTypes.number,
        /** 
        * Defines the page number pagination
        */
        pageNumber: propTypes.number,
    }),

    /**
     * Change the list mode to card and vice versa
     * */
    switchViewMode: propTypes.func.isRequired,
    /**
     * Determines whether card mode is stored in local storage
     */
    gridMode: propTypes.bool,
    /**
     * open panel lateral for add new user
     */
    openLateralPanelOnClick: propTypes.func.isRequired,
}

UserInProviderDesktopView.defaultProps = {
    users: [],
    isLoading: false,
    gridMode: false,
    searchValue: "",
    pagination: {
        orderBy: "",
        orderType: 'asc',
        total: 0,
        pageNumber: 1,
    },

    onChangeSortingCriteria: () =>
        console.warn('Callback [onChangeSortingCriteria] no defined'),
    onChangeSearchValue: () =>
        console.warn('Callback [onChangeSearchValue] no defined'),
    switchViewMode: () =>
        console.warn('Callback [switchViewMode] no defined'),
    onEditPanelUserConfig: () =>
        console.warn('Callback [onEditPanelUserConfig] no defined'),
    onChangePage: () =>
        console.warn('Callback [onChangePage] no defined'),
    openLateralPanelOnClick: () =>
        console.warn('Callback [openLateralPanelOnClick] no defined'),
    onSetPasswordDialogConfiguration: () =>
        console.warn('Callback [onSetPasswordDialogConfiguration] no defined'),
    onSetResendPasswordDialog: () =>
        console.warn('Callback [onSetResendPasswordDialog] no defined'),
}




/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
    const classes = useDataGridToolbarStyle()

    return (
        <div className={classes.searchIndex}>
            <SearchBox
                placeholder='Search user'
                onChange={props.onChange}
                value={props.searchValue}
            />
            <ButtonGroup component="span" size='small' variant="outlined" color="primary" className={classes.buttonsGroupContainer}>
                <Tooltip component="span" title='Mode list'  >
                    <Button
                        className={classes.buttonsGroup}
                        disabled={!props.gridMode}
                        onClick={props.switchViewMode}
                    >
                        <ListIcon />
                    </Button>
                </Tooltip>
                <Tooltip component="span" title='Mode card' >
                    <Button
                        className={classes.buttonsGroup}
                        disabled={props.gridMode}
                        onClick={props.switchViewMode}
                    >
                        <GridIcon />
                    </Button>
                </Tooltip>
                <Tooltip component="span" title='Add new user'>
                    <Button
                        className={classes.buttonsGroup}
                        onClick={props.openLateralPanelOnClick}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            </ButtonGroup>

        </div>
    )
}

DataGridToolbar.propTypes = {
    /**
     * On change search value
     */
    onChange: propTypes.func.isRequired,
    /**
     * Initial search value
     */
    searchValue: propTypes.string,
    /**
     * Change the list mode to card and vice versa
     * */
    switchViewMode: propTypes.func.isRequired,
    /**
     * Determines whether card mode is stored in local storage
     */
    gridMode: propTypes.bool,
    /**
     * Opens side panel to add new  user's
     */
    openLateralPanelOnClick: propTypes.func.isRequired,

}

DataGridToolbar.defaultProps = {
    gridMode: false,
    searchValue: "",
    onChange: () =>
        console.warn('Callback [onChange] no defined'),
    switchViewMode: () =>
        console.warn('Callback [switchViewMode] no defined'),
    openLateralPanelOnClick: () =>
        console.warn('Callback [openLateralPanelOnClick] no defined'),
}


export default withImmutablePropsToJS(UserInProviderDesktopView)
