/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import Skeleton from '@material-ui/lab/Skeleton';

/**Import material ui section */
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import AddSapIcon from '@material-ui/icons/LoupeOutlined'

/**Import componets section */
import SearchToolbox from '../../common/SearchToolbox'
import Authorization from '../../common/AuthorizationEvaluation'

/**Import reasources section */
import { toolbarSearchBoxStyle } from '../../../resources/styles/mobile/ToolbarSearchBoxStyle'

const ToolbarSearchBox = (props) => {
    /**String props section */
    const { placeholder, searchValue } = props
    /**Functions props section */
    const { onChange, onButtonAddClick, onButtonAddManualClick } = props
    /**objects prosp section */
    const { userProfile } = props
    /**Array props section */
    const { roles} = props
    /**Bools props section */
    const { isLoading, isActiveBtnAdd, isActiveBtnManual } = props
    const classes = toolbarSearchBoxStyle({ isActiveBtnAdd: props.isActiveBtnAdd });

    const onRenderContent = () => {
        if (isLoading) {
            return (
                <div className={isActiveBtnAdd ? classes.container : null}>
                    <Skeleton animation='wave' variant="rect" width={"100%"} height={28} />
                    {isActiveBtnAdd && <Skeleton animation='wave' variant="circle" width={30} height={30} />}
                </div>
            )
        }
        else {
            return (
                <div className={isActiveBtnAdd ? classes.container : null}>
                    <div className={(isActiveBtnAdd && !isActiveBtnManual) ? classes.searchMovil : null}>
                        <SearchToolbox
                            placeholder={placeholder}
                            onChange={onChange}
                            value={searchValue}
                        />
                    </div>
                    
                    {
                        isActiveBtnAdd
                        && <Authorization
                            profile={userProfile}
                            allowedRoles={roles}
                        >
                            <Fab size="small" className={classes.fab} onClick={onButtonAddClick}>
                                <AddIcon />
                            </Fab>
                        </Authorization>
                    }
                    {
                        isActiveBtnManual
                        && <Authorization
                            profile={userProfile}
                            allowedRoles={roles}
                        >
                            <Fab size="small" className={classes.fabSap} onClick={onButtonAddManualClick}>
                                <AddSapIcon />
                            </Fab>
                        </Authorization>
                    }
                </div>
            )

        }
    }

    return (
        onRenderContent()
    )
}

ToolbarSearchBox.propTypes = {
    /**
     * Place holder search
     */
    placeholder: propTypes.string.isRequired,
    /**
     * Value search
     */
    searchValue: propTypes.string.isRequired,
    /**
     * See skeleton toolbar
     */
    isLoading: propTypes.bool.isRequired,
    /**
     * Determinate is is visible btn add
     */
    isActiveBtnAdd: propTypes.bool.isRequired,
    /**
     * Determinate is is visible btn add manual
     */
    isActiveBtnManual: propTypes.bool.isRequired,
    /**
     * callback execute creation option
     */
    onButtonAddClick: propTypes.func.isRequired,
    /**
     * Callback execute when change search value
     */
	onChange: propTypes.func.isRequired,
}
ToolbarSearchBox.defaultProps = {
    placeholder:"",
    searchValue:"",
    isLoading:false,
    isActiveBtnAdd:false,
    isActiveBtnManual:false,
	onButtonAddClick: () => console.warn('Callback [onButtonAddClick] no defined'),
	onChange: () => console.warn('Callback [onChange] no defined')
}
export default ToolbarSearchBox
