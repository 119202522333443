import React from 'react'
import { Content, Header, HeaderBreadCrum, Layout } from '../../layouts/AuthenticatedLayout'
import { 
	Typography, 
	ButtonGroup, 
	Tooltip, 
	Button, 
	ListItem, 
	ListItemIcon,
	ListItemText,
	FormControlLabel,
	Switch
	 } from '@material-ui/core'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Authorization from '../../common/AuthorizationEvaluation'
import { PORTAL_ADMINISTRATOR } from '../../../resources/RolesEnum'
import AddIcon from '@material-ui/icons/Add'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import RenderContentIndex from '../../common/renderContentIndex/RenderContentIndex'
import headerConfig from '../resources/CustomerTableConfig'
import LockOpenIcon from '../../../resources/images/lock/LockOpen.svg'
import LockOpenMeteriaIcon from '@material-ui/icons/LockOpen'
import { useDispatch } from 'react-redux'


import SearchBox from '../../common/SearchToolbox'
import Pagination from '../../common/Pagination/Pagination'

const DesktopView = (props) => {
	const dispatch = useDispatch()

    const classes = useDataGridToolbarStyle()
	const { 
		gridMode, 
		isSaving, 
        userProfile, 
        onChangePage,
		onLocked,
		onEditCustomerClick,
		switchViewMode,
		onAddLateralPanelConfig,
        onOpenAttanchemtDialog,
		pagination,
		allSeries,
		identifierCardSeleted,
		onDownloadDocument,
		isLoading,	
		toggleSwitch,
			
		} = props
		/**
	 * Get the header configuration
	 */
	const getHeaderConfiguration = () => {
		
		let CreationDateProperty = headerConfig.find((x) => x.dataSource === 'CreationDate')

		CreationDateProperty.onRenderProperty = (item) => {
			return (
				<Typography>
					{item.CreationDate}
				</Typography>
			)
		}
		
		let codeProperty = headerConfig.find((x) => x.dataSource === 'Code')
		codeProperty.onRenderProperty = (item) => {
			return (
				<Typography>
					{item.Code}
				</Typography>
			)
		}
		let codeMaterialProperty = headerConfig.find((x) => x.dataSource === 'CodeMaterial')
		codeMaterialProperty.onRenderProperty = (item) => {
			return (
				<Typography>
					{item.CodeMaterial}
				</Typography>
			)
		}
		let descriptionMaterialProperty = headerConfig.find((x) => x.dataSource === 'DescriptionMaterial')
		descriptionMaterialProperty.onRenderProperty = (item) => {
			return (
				<Typography>
					{item.DescriptionMaterial}
				</Typography>
			)
		}
		let descriptionProperty = headerConfig.find((x) => x.dataSource === 'Description')
		descriptionProperty.onRenderProperty = (item) => {
			return (
				<Typography>
					{item.Description}
				</Typography>
			)
		}

		return headerConfig
	}


	/** Render menu options for list in mode cards*/
	const renderOptionMenu = (user) => {
		let isDisabledOption = userProfile.userName !== user.UserName ? false : true
		return (
			<>
				{
					<ListItem
						button
						data-cy='item-remove-access'
						disabled={isSaving || isDisabledOption}
						onClick={(event) => onLocked(user.Token, !user.Locked, event)}
					>
						<ListItemIcon>
							{user.Locked ? (
								<img src={LockOpenIcon} alt='icon' />
							) : (
								<LockOpenMeteriaIcon />
							)}
						</ListItemIcon>
						<ListItemText
							primary={user.Locked ? 'Unlock customer' : 'Block customer'}
						/>
					</ListItem>
				}
			</>
		)
	}
	const onRowClick = (sender, item) => {
		if (onEditCustomerClick) {
			onEditCustomerClick(item)
		}
	}

			/**Render content in cads o en mode list */
	const renderContent = () => {
		return (
			<RenderContentIndex
				headerConfig={getHeaderConfiguration()}
				isLoading={isLoading}
				pagination={pagination}
				onRowClick={onRowClick}
				data={allSeries}
				gridMode={gridMode}
				onChangeSortingCriteria={props.onChangeSortingCriteria}
				searchValue={props.searchValue}
				titleData={'There is no added data, please add a warranty series file.'}
				onEditClick={onEditCustomerClick}
				isAvatarFromIdentity={false}
				identifierCardSeleted={identifierCardSeleted}
				isActiveButtonOptions={true}
				renderOptionMenu={renderOptionMenu}
			/>
		)
	}

	const isChecked = allSeries.length > 0 ? allSeries[0].FilterIsActive : false;

	const onToggleSwitch = (event) => {
		dispatch(toggleSwitch(event.target.checked ));
	};

  return (
    <div><Layout>
    <Header
        key={'AuthenticatedHeader'}
        user={props.user}
        breadcrumbs={
            <HeaderBreadCrum>
                <Typography color='secondary'>Administration / Series under warranty SAP Filters</Typography>
            </HeaderBreadCrum>
        }
    />
    <Content key={'AuthenticatedContent'}>
        <div className={classes.rootIndex}>
             <DataGridToolbar
                onChange={props.onChangeSearchValue}
                searchValue={props.searchValue}
                switchViewMode={switchViewMode}
                gridMode={gridMode}
                onAddLateralPanelConfig={onAddLateralPanelConfig}
                onDownloadDocument={onDownloadDocument}
                userProfile={userProfile}
                onOpenAttanchemtDialog={onOpenAttanchemtDialog}
				isChecked={isChecked}
				onToggleSwitch={onToggleSwitch}
				allSeries={allSeries}
				isSaving={isSaving}

            />
            {
            renderContent()
            }
            <div className={classes.pagination}>
                <div className={classes.flexGrow} />
                <Pagination
                    count={pagination ? pagination.total : 0}
                    page={pagination ? pagination.pageNumber : 0}
                    onChangePage={onChangePage}
                />
            </div>
        </div>
    </Content>
</Layout></div>
  )
}


/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()

	return (
		<div className={classes.searchIndex}>
			<SearchBox
				placeholder='Search warranty serie'
				onChange={props.onChange}
				value={props.searchValue}
			/>
			<ButtonGroup
				component='span'
				size='small'
				variant='outlined'
				color='primary'
				className={classes.buttonsGroupContainer}
			>
				<FormControlLabel
					disabled={props.isSaving || props.allSeries.length === 0}

					control={
						<Switch
							checked={props.isChecked}
							onChange={props.onToggleSwitch}
							value="checkedB"
							color="primary"
							
						/>
					}
					label="Active filter"
				/>

				<Tooltip component='span' title='Download list of warranty series SAP'>
					<Button className={classes.buttonsGroup} onClick={props.onDownloadDocument}>
						<CloudDownloadIcon />
					</Button>
				</Tooltip>
				
				
				 <Authorization profile={props.userProfile} allowedRoles={[PORTAL_ADMINISTRATOR]}> 
					<Tooltip component='span' title='Add warranty series SAP'>
						<Button
							className={classes.buttonsGroup}
							onClick={props.onOpenAttanchemtDialog}
                            
						>
							<AddIcon />
						</Button>
					</Tooltip>
				</Authorization> 
			</ButtonGroup>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool
}

DataGridToolbar.defaultProps = {
	gridMode: false,
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined')
}


export default withImmutablePropsToJS(DesktopView)