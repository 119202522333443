/**Import react section */
import React from 'react'

import { Grid} from '@material-ui/core'
/**Import components section */
import TextBox from '../../common/TextBox'
import AlertComponent from '../../common/AlertComponent'

const CreateAddress = (props) => {
    const { onPropertyChange } = props;
    const { addressModel, spartPartModel } = props;
    const { register, errors } = props;

    /**
     * Method to render Text Field input
     * @param {*} sectionToken 
     * @param {*} key 
     * @param {*} item 
     * @param {*} isDisabled 
     */
    const renderTextBox = (key, item, label, isActive, isDisabled, size = 6, multiline = false, rows = 0, required, registerValues) => {
        let value = item ? item[key] ? item[key] : '' : '';
        if (key === 'route' && value)
            value = `${item.street_number} ${item[key]} `

        return (
            <Grid item xs={size}>
                <TextBox
                    key={key}
                    active={isActive}
                    required={(required && errors?.[key]) ? true : false}
                    disabled={isDisabled}
                    fullWidth
                    multiline={multiline}
                    name={key}
                    rows={rows}
                    variant="outlined"
                    margin="dense"
                    label={label}
                    onChange={onPropertyChange}
                    value={value}
                    register={registerValues}
                    errors={errors}
                />
            </Grid>
        )
    }

    return (
        <Grid container spacing={1}>
            {renderTextBox("country", addressModel, "Country", true, true, 12, false, 0, true, register({ required: { value: true, message: 'The country is required' } }))}
            {renderTextBox("administrative_area_level_1", addressModel, "State", true, true, 8, false, 0, true, "")}
            {renderTextBox("postal_code", addressModel, "Zip code", true, true, 4, false, 0, true, "")}
            {renderTextBox("locality", addressModel, "Location", true, true, 12, false, 0, true, register({ required: { value: true, message: 'The location is required' } }))}
            {renderTextBox("route", addressModel, "Street", true, true, 12, false, 0, false, "")}
            <Grid item xs={12}>
                {renderTextBox("Comments", spartPartModel, "COMMENTS", true, false, 12, true, 4, false)}
                {errors === null && <AlertComponent
                    title={"Error"}
                    message={"Please enter the information required to continue the process."}
                    severityType={"error"}
                />}
            </Grid>
        </Grid>
    )
}

export default CreateAddress