/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'

/**Import actions sectiuon */
import {
	loadSparePartRequests, downloadReportSparePartRequestGral,
	loadDashboardSparePartRequest, importReferencesFromBackend
} from '../../store/spareParts/sparePartsActions'
import { sparePartIndexSelector } from '../../store/spareParts/sparePartsSelectors'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { SpareParRequestStatus } from '../../store/helpers/AppConstants'
import { loadInternalUsers } from '../../store/users/UsersActions'
import { loadAllServiceProviderWithFilters } from '../../store/serviceproviders/serviceProvidersActions'
import { loadAllCustomers } from '../../store/customers/customersActions'
/**Import components section */
import Toaster from '../common/Toaster'
import DesktopView from './components/IndexDesktopView'
import MobileView from './componentsMobile/MobileView'
import PanelImportReference from './components/PanelImportReference'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

/**
 * Create sparte parts view
/**
 *
 *
 * @returns
 */
const Index = (props) => {
	const dispatch = useDispatch()
	const history = useHistory()
	let location = useLocation()
	const isMobile = useIsMobile(props.isMobile)

	const sparePartsIndex = useSelector(sparePartIndexSelector)
	const userProfile = useSelector((state) => toSafeObject(state.session.get('profile')))
	const pagination = useSelector((state) => state.spareParts.getIn(["index", "pagination"]))
	const isDownloadReport = useSelector((state) => state.spareParts.getIn(["index", "isDownloadReport"]))
	const isLoadingDashboard = useSelector((state) => state.spareParts.get('isLoadingDashboard'))
	const dashboard = useSelector((state) => state.spareParts.get('dashboard'))

	/**Load all customers from redux store for filters */
	const allCustomers = useSelector((state) => state.customers.get('customers'))
	const serviceProviders = useSelector((state) =>
		state.serviceProviders.get('serviceProviders')
	)

	const internalUsers = useSelector((state) =>
		state.users.get('internalUsers')
	)

	/**Local store this component section */
	const [query, setQuery] = useState("");
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [lateralPanelImportFile, setLateralPanelImportFile] = useState({ opened: false });

	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query)
			setQuery(queryParameters.query)
		dispatch(loadSparePartRequests("open", queryParameters, isMobile, []))
			.then(() => {
			})
			.catch(() => {
			})
	}, [dispatch, location.search, isMobile])

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		if (sparePartsIndex?.requests.length === 0 && queryParams.pageNumber > 1) {
			queryParams.pageNumber = 1
			history.push({ search: queryString.stringify(queryParams) })
		}
	}, [sparePartsIndex, location.search, history])

	/**
 * Load dasborad list spare parts
 */
	useEffect(() => {
		dispatch(loadDashboardSparePartRequest("open")).then(() => { })
		dispatch(loadAllServiceProviderWithFilters(null, 1000))
		dispatch(loadAllCustomers(null, 1000))
	}, [dispatch])


	const onChangeLocation = (location) => history.push(location)

	const onCreateRequest = () => history.push('/spareparts/create')

	const onCreateRequestManual = () => history.push('/spareparts/manual/create')

	const onDownloadReportGral = (startDate, endDate) => {
		const queryParameters = queryString.parse(location.search)
		return dispatch(downloadReportSparePartRequestGral("open", queryParameters, startDate, endDate))
			.then(() => {
			})
			.catch(() => {
				setToaster({
					open: true,
					variant: 'error',
					message: "Error to download report"
				})
			})
	}

	/**
	 * OpenRequest 
	 */
	const onOpenRequest = (sparePartRequest) => {
		if ((sparePartRequest.Status === SpareParRequestStatus.DRAFT ||
			sparePartRequest.Status === SpareParRequestStatus.REJECTED_RESEND) && !sparePartRequest.IsManual) {
			history.push(`/spareparts/create/${sparePartRequest.Ticket}`)
		}
		else if (sparePartRequest.Status === SpareParRequestStatus.DRAFT && sparePartRequest.IsManual) {
			history.push(`/spareparts/manual/open/${sparePartRequest.Token}`)
		}
		else {
			history.push(`/spareparts/open/${sparePartRequest.Token}`)
		}
	}

	useEffect(() => {
		dispatch(loadInternalUsers())
	}, [dispatch])



	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		}
		else {
			delete queryParams.query
			setQuery("")
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Update sort criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}


	/**Open lateral panel for import file reference SAP */
	const openImportReferenceSAP = () => {
		setLateralPanelImportFile({ opened: !lateralPanelImportFile.opened })
	}

	/**
	 * On clancel spare part request
	 */
	const onImportReferenseSAP = (files, getType) => {
		const queryParameters = queryString.parse(location.search)
		dispatch(importReferencesFromBackend(files, getType))
			.then(() => {
				openImportReferenceSAP();
				dispatch(loadSparePartRequests("open", queryParameters, false, [])).then(() => { })
				return Promise.resolve();
			})
			.catch((error) => {
				setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to import references files', variant: 'error' })
			})
	}

	return (
		<>

			{
				isMobile ?
					<MobileView
						onChangeLocation={onChangeLocation}
						onCreateRequest={onCreateRequest}
						onOpenRequest={onOpenRequest}
						sparePartsIndex={sparePartsIndex}
						userProfile={userProfile}
						onChangeSearchValue={(query) => updateSearchQuery(query)}
						searchValue={query}
						pagination={pagination}
						onChangePage={onChangePage}
						onChangeSortingCriteria={updateSortCriteria}
						internalUsers={internalUsers}
						onDownloadReportGral={onDownloadReportGral}
						isDownloadReport={isDownloadReport}
						isLoadingDashboard={isLoadingDashboard}
						dashboard={dashboard}
						onCreateRequestManual={onCreateRequestManual}
						allCustomers={allCustomers}
						serviceProviders={serviceProviders}
						isClosed={false}
					/> :
					<DesktopView
						onChangeLocation={onChangeLocation}
						onCreateRequest={onCreateRequest}
						onOpenRequest={onOpenRequest}
						sparePartsIndex={sparePartsIndex}
						userProfile={userProfile}
						onChangeSearchValue={(query) => updateSearchQuery(query)}
						searchValue={query}
						pagination={pagination}
						onChangePage={onChangePage}
						onChangeSortingCriteria={updateSortCriteria}
						internalUsers={internalUsers}
						onDownloadReportGral={onDownloadReportGral}
						isDownloadReport={isDownloadReport}
						isLoadingDashboard={isLoadingDashboard}
						dashboard={dashboard}
						onCreateRequestManual={onCreateRequestManual}
						allCustomers={allCustomers}
						serviceProviders={serviceProviders}
						openImportReferenceSAP={openImportReferenceSAP}
					/>
			}

			{lateralPanelImportFile.opened &&
				<PanelImportReference
					onCloseButtonClick={openImportReferenceSAP}
					onImportReferenseSAP={onImportReferenseSAP}
					isSaving={isDownloadReport}
				/>
			}

			<Toaster
				toasterOptions={toaster}
				onClose={() => setToaster({ open: false })}
			/>
		</>
	)
}
export default Index
