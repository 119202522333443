/**Import react section */
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
/**Import material UI Section */
import {
    DialogContent,
    Chip
} from '@material-ui/core';

/**Import components section */
import PanelHeaderIcon from '../../common/lateralPanel/PanelHeaderIcon'
import iconFailureDoor from '../../../resources/images/failureWarranty/doorWhite.svg';

/**Import resouces section */
import { SpareParRequestStatus } from '../../../store/helpers/AppConstants'
import defaultTheme from '../../../resources/themes/default.js'
import ChangeStatusMessage from './ChangeStatusMessage'

/**
 * Componente chage status panel
 * @param {*} props 
 */
const ChangeStatusPanel = (props) => {
    const { onCloseButtonClick } = props;

    const { onSetPasswordDialogConfiguration } = props;

    const [spartPartModel, setSpartPartModel] = useState(null);
    const [changeStatusModel, setStatusModel] = useState(null);
    useEffect(() => {
        setSpartPartModel(props.sparePart)
    }, [props.sparePart])


    /**
     * get coponentt to shos in status
     * @param {*} status 
     */
    const GetComponentByStatus = () => {
        let status = changeStatusModel ? changeStatusModel.status.value : null;
        switch (Number(status)) {
            case SpareParRequestStatus.REJECTED_RESEND:
                return <ChangeStatusMessage
                    onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                    IsClosed={false}
                    status={status}
                />
            default:
                return null
        }
    }

    const getPlaceholder = () => {
        switch (Number(spartPartModel ? spartPartModel.Status : null)) {
            case SpareParRequestStatus.DRAFT:
                return "SPARE PART REQUEST DRAFT"
            case SpareParRequestStatus.IN_PROCESS:
                return "SPARE PART REQUEST IN SUBMIT"
            case SpareParRequestStatus.SEND_TO_MX:
                return "SPARE PART REQUEST SEND MEXICO"
            case SpareParRequestStatus.CLOSED:
                return "SPARE PART REQUEST CLOSED"
            case SpareParRequestStatus.DELETED:
                return "SPARE PART REQUEST DELETED"
            case SpareParRequestStatus.SEND_TECH_USA:
                return "VALIDATE REQUEST FROM PROVIDER"
            case SpareParRequestStatus.REJECTED_RESEND:
                return "SPARE PART REJECTED"
            case SpareParRequestStatus.CANCELLED:
                return "SPARE PART REQUEST CANCELLED"
            default:
                return null
        }
    }

    const backgroundColorSelect = () => {
        let status = changeStatusModel ? changeStatusModel.status.value : null;
        switch (Number(status)) {
            case SpareParRequestStatus.DRAFT:
                return changeStatusModel.status.color
            case SpareParRequestStatus.IN_PROCESS:
                return changeStatusModel.status.color
            case SpareParRequestStatus.SEND_TO_MX:
                return changeStatusModel.status.color
            case SpareParRequestStatus.CLOSED:
                return changeStatusModel.status.color
            case SpareParRequestStatus.DELETED:
                return changeStatusModel.status.color
            case SpareParRequestStatus.SEND_TECH_USA:
                return changeStatusModel.status.color
            case SpareParRequestStatus.REJECTED_RESEND:
                return changeStatusModel.status.color
            case SpareParRequestStatus.CANCELLED:
                return changeStatusModel.status.color
            default:
                return null
        }
    };

    const backGroundColorDefaultByStatus = () => {
        switch (Number(spartPartModel ? spartPartModel.Status : null)) {
            case SpareParRequestStatus.DRAFT:
                return defaultTheme.palette.colors.purple
            case SpareParRequestStatus.IN_PROCESS:
                return defaultTheme.palette.colors.waiting
            case SpareParRequestStatus.SEND_TECH_USA:
            case SpareParRequestStatus.SEND_TO_MX:
                return defaultTheme.palette.colors.blue
            case SpareParRequestStatus.CLOSED:
                return defaultTheme.palette.colors.green
            case SpareParRequestStatus.DELETED:
                return defaultTheme.palette.colors.red
            case SpareParRequestStatus.REJECTED_RESEND:
                return defaultTheme.palette.colors.red 
            case SpareParRequestStatus.CANCELLED:
                return defaultTheme.palette.colors.red
            default:
                return defaultTheme.palette.colors.purple
        }
    }


    const colorSelectorStyles = {
        control: styles => ({ ...styles, backgroundColor: changeStatusModel ? backgroundColorSelect() : backGroundColorDefaultByStatus(), color: "white" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? `${data.color}80` : isFocused ? `${data.color}80`
                    : null,
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (`${data.color}80`),
                },
            };
        },
        input: styles => ({ ...styles, ...colorSelected() }),
        placeholder: styles => ({ ...styles, ...colorSelected() }),
        singleValue: (styles, { data }) => ({ ...styles, ...colorSelected(data.color) }),
        menu: styles => ({ ...styles, zIndex: 10, maxHeight: 200 }),
        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
    };

    /** Invoke when select property change*/
    const onSelectChange = (name, option) => {
        setStatusModel({
            ...changeStatusModel,
            [name]: option
        })

        /// Show component to add message
        if(option.value !== SpareParRequestStatus.REJECTED_RESEND){
            onSetPasswordDialogConfiguration({ status: option.value })
        }
    }

    return (
        <>
            <PanelHeaderIcon
                title={'Change status'}
                isIconSvgOrPng={true}
                icon={iconFailureDoor}
                onCloseButtonClick={onCloseButtonClick}
            />
            <DialogContent >
                <Select
                    id={"id-status"}
                    placeholder={getPlaceholder()}
                    name="status"
                    autoFocus
                    options={spartPartModel ? spartPartModel.AvailableOperations : null}
                    styles={colorSelectorStyles}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(value, action) => { onSelectChange(action.name, value) }}

                /><br /><br />
                {GetComponentByStatus()}
            </DialogContent>

        </>
    )
}


const formatOptionLabel = ({ value, label, color }) => (
    <div>
        <Chip
            id={value}
            size="small"
            label={label}
            clickable
            style={{ color: defaultTheme.palette.common.white, background: color }}
        />
    </div>
);

const colorSelected = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    color: defaultTheme.palette.common.white
});

export default ChangeStatusPanel