/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import styles section */
import { createServicesStyle } from '../../../resources/styles/CreateServiceStyles'

/**Import Material Ui section */
import {
	Typography,
} from '@material-ui/core'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import Toolbar from '../../common/Toolbar'


import {
	Layout,
	Content,
	Header,
	HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'

/** Components */
import ServiceHeaderForm from './ServiceHeaderForm'
import { getOptionsReactSelect } from '../../../store/helpers/FilterHelper'
import { PositionMapDefault } from '../../../store/helpers/AppConstants'
import iconFailureDoor from '../../../resources/images/failureWarranty/door.svg';

/**
 * View create desktop 
 */
const CreateDesktopView = (props) => {
	const classes = createServicesStyle()

	const {
		salesRepresentatives,
		retails,
		createService,
		redirectToHomeCancelService
	} = props
	const { isSavingService } = props

	const [serviceModel, setServiceModel] = useState({
		Address: {},
	})
	const [mapPosotionModel, setPositionModel] = useState({
		latitude: PositionMapDefault.latitude,
		longitude: PositionMapDefault.longitude
	})
	const [retailAddressModel, setRetilsAddressModel] = useState([])
	let representativesOptions = getOptionsReactSelect(salesRepresentatives)
	let retailsOptions = getOptionsReactSelect(retails)

	/**On change properties for the service */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setServiceModel({
			...serviceModel,
			[name]: value
		})
	}

	/** Invoke when select property change*/
	const onSelectChange = (name, valueProperty) => {
		setServiceModel({
			...serviceModel,
			[name]: valueProperty
		})
		if (name === "Retailer") {
			setRetilsAddressModel(valueProperty.address)
		}
	}

	/**
	 * Action ok Change address
	 * @param {*} address
	 */
	const onChangeAddress = (address) => {
		setServiceModel({
			...serviceModel,
			Address: address
		})
	}

	/**
	 * Function add map position
	 * @param {*} position
	 */
	const onChangePosition = (position) => {
		setPositionModel({ latitude: position.lat, longitude: position.lng })
	}

	/**Create service in the back end */
	const addServiceOnClick = () => {
		if (createService) {
			createService(serviceModel, mapPosotionModel, props.userProfile ? props.userProfile.Account.Code:"")
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.userProfile}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>
							Services / Create
						</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={1}
				userProfile={props.userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar
						title='CREATE SERVICE'
						icon={iconFailureDoor}
					/>
					<div className={classes.container}>
						<ServiceHeaderForm
							onPropertyChange={onPropertyChange}
							representativesOptions={representativesOptions}
							retailsOptions={retailsOptions}
							isSavingService={isSavingService}
							serviceModel={serviceModel}
							onSelectChange={onSelectChange}
							position={mapPosotionModel}
							onChangePosition={onChangePosition}
							onChangeAddress={onChangeAddress}
							retailDirections={retailAddressModel}
							redirectToHomeCancelService={redirectToHomeCancelService}
							addServiceOnClick={addServiceOnClick}
							userProfile={props.userProfile}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}

CreateDesktopView.propTypes = {
	/**List of sales representative */
	salesRepresentatives: propTypes.array.isRequired,
	/**List of retails */
	retails: propTypes.array.isRequired,
	internalUsers: propTypes.array.isRequired,
	/**Function invoke callback for create a service */
	createService: propTypes.func.isRequired,
	/**Redirects to the service view when you don't want to create a new service */
	redirectToHomeCancelService: propTypes.func.isRequired
}
CreateDesktopView.defaultProps = {
	isSavingService: false,
	retails: [],
	salesRepresentatives: [],
	internalUsers: [],
	createService: () => console.warn('Callback [createService] no defined'),
	redirectToHomeCancelService: () =>
		console.warn('Callback [redirectToHomeCancelService] no defined')
}

export default withImmutablePropsToJS(CreateDesktopView)
