/**Import react section */
import React, { useState } from 'react'

/**Import Material UI section */
import { Stepper, Step, StepLabel, Typography, Card } from '@material-ui/core'

/**Import resources section */
import { panelEditUseStyles } from '../resources/useStyles'

const TimeLineCarrier = (props) => {
    const { shippingHistory } = props;
    const classes = panelEditUseStyles();
    const [activeStep] = useState(0)
    return (
        <div>
            <Card className={classes.carrierHeader}>
                <Typography className={classes.cell2}>{`Status`}</Typography>
                <Typography className={classes.cell3}>{`Date`}</Typography>
                <Typography className={classes.cell4}>{`Location`}</Typography>
            </Card>
            <br />

            <Stepper activeStep={activeStep} orientation="vertical" className={classes.containerLineTime}>
                {shippingHistory.map((shipping, index) => {
                    return (
                        <Step key={index}>
                            <StepLabel>
                                <div className={classes.carrierContent} >
                                    <Typography variant='caption' className={classes.cell1}>{shipping.StatusDescription}</Typography>
                                    <Typography variant='caption' className={classes.cell2}>{shipping.Date}</Typography>
                                    <Typography variant='caption' className={classes.cell3}>{shipping.Details}</Typography>
                                </div>
                            </StepLabel>
                        </Step>
                    )

                })}
            </Stepper>
        </div>

    )
}

export default TimeLineCarrier