/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import ServiceSelector from '../../services/components/ServiceSelector'
import Toolbar from '../../common/Toolbar'
import Authorization from '../../common/AuthorizationEvaluation'
import EquipmentsList from '../../services/components/EquipmentsList'
import EquipmentSparePartList from './EquipmentSparePartList'
import AddressComponent from './Address'
import AlertComponent from '../../common/AlertComponent'
import EquipmentsSap from './manual/EquipmentsSap'
import AddSparePartPanel from './SparePartPanel'

/** Import material UI */
import { Typography, Card, CardContent, Paper, Chip } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
/** Import styles */
import { useCreateDesktopStyle } from '../resources/useStyles'
import { toolbarStyles } from '../../../resources/styles/ToolbarStyles'
import { hasAuthorization } from '../../../store/session/sessionActions'
import {
	IMBERA_TECHNICIAN,
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN
} from '../../../resources/RolesEnum'
import iconSparePart from '../../../resources/images/sparePart.svg'
import { GetBackgroundColorSparePartRequestByStatus } from '../../../store/helpers/StatusHelper'

/**
 * View create desktop
 */
const CreateDesktopView = (props) => {
	const {
		userProfile,
		isLoadingFromSAP,
		sparePartsSap,
		querySparePart,
		onSearchSerieInSap,
		equipmentsSap,
		onAddSparePart,
		onUpdateQuantityEquipment,
		onChangeQuerySap,
		isDownloadReport,
		onDonwloadReportBySerie
	} = props

	const [serieSelected, setSerieSelected] = useState(null)
	const [status, setStatus] = useState(null)
	const [identifierExpanded, setIdentifierExpanded] = useState([])
	const [lateralPanelState, setLateralPanelState] = useState({
		open: false,
		equipment: null
	})

	useEffect(() => {
		if (!!props.request.draft && props.request.draft.Status) {
			setStatus(props.request.draft.Status)
		}
	}, [props.request.draft])
	const [mode] = useState('MODE_CREATE')

	const classes = useCreateDesktopStyle()
	const toolbarStylesClasses = toolbarStyles()

	const onAddSparePartRequest = (equipment) => {
		setLateralPanelState({ open: true, equipment })
	}

	/**
	 * Search materials with function from sap
	 * @param {*} equipment
	 */
	const onSearchSerie = (equipment) => {
		if (onSearchSerieInSap) {
			onSearchSerieInSap(equipment.Serie)
			setSerieSelected(equipment.Serie)
		}
	}

	/**
	 * Add spare part in request selected
	 * @param {*} equipment
	 * @param {*} sparePart
	 */
	const onButtonAddClick = (equipment, sparePart) => {
		if (onAddSparePart) {
			let addEquipment = {
				Serie: serieSelected,
				CustomerCode: equipment.CustomerCode,
				CustomerName: equipment.CustomerName
			}
			onAddSparePart(addEquipment, sparePart)
		}
	}

	let isAuthorizadoForEditedData = hasAuthorization(userProfile, [
		IMBERA_TECHNICIAN,
		SERVICE_PROVIDER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER,
		CUSTOMER_MANAGER,
		CUSTOMER_TECHNICIAN
	])

	const colorByStatus = status && GetBackgroundColorSparePartRequestByStatus(status, toolbarStylesClasses)
	const onRenderViewService = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{
					<Authorization profile={userProfile} allowedRoles={[IMBERA_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN]}>
						{!!props.request.draft && props.request.draft && (
							<Chip
								label='CHANGE STATUS'
								clickable
								color='primary'
								onClick={() => props.openLateralPanelChangeStatus(props.request.draft)}
							/>
						)}
					</Authorization>
				}
				{props.request.draft && props.request.draft.CanBeSendToValidate && (
					<Authorization profile={userProfile} allowedRoles={[SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN]}>
						<Chip
							label='CHANGE STATUS'
							clickable
							color='primary'
							onClick={() => props.openLateralPanelSendProvider(props.request.draft)}
						/>
					</Authorization>
				)}
			</div>
		)
	}
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Spare Part Request / Create</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={3} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar
						title='CREATE SPARE PART REQUEST'
						renderContentRight={onRenderViewService()}
						icon={iconSparePart}
						renderBackgroundColor={colorByStatus}
					/>

					<Card>
						<CardContent>
							<ServiceSelector
								autoFocus
								placeholder='Select the service'
								services={props.activeServices}
								onChange={props.onServiceSelected}
							/>
						</CardContent>
					</Card>
					<Paper>
						<EquipmentsList
							equipments={props.request.equipments}
							isAuthorizadoForEditedData={isAuthorizadoForEditedData}
							modeView='SPARE_PART_REQUETS'
							expandablePanelContent={
								<EquipmentSparePartList
									onAddSparePartRequest={onSearchSerie}
									onRemoveSparePart={props.onRemoveSparePart}
									isLoading={props.request.isGettingDraft}
									isSaving={props.request.isSavingDraft}
									identifierSparePartDelete={props.identifierSparePartDelete}
									isAuthorizadoForEditedData={isAuthorizadoForEditedData}
									onUpdateSpartPart={props.onUpdateSpartPart}
									userProfile={userProfile}
									status={status}
									mode={mode}
									isSparePartManual={false}
									isLoadingFromSAP={isLoadingFromSAP}
									identifierExpanded={identifierExpanded}
									setIdentifierExpanded={setIdentifierExpanded}
									handleClick={handleClick}
									open={open}
								/>
							}
						/>
						{isLoadingFromSAP && (
							<>
								<br />
								<AlertComponent
									title={'Getting series from SAP'}
									message={'This process may take a while, please wait...'}
									severityType={'info'}
								/>
								<LinearProgress />
							</>
						)}

						{/**Render list equipments with spare parts to get from sap */}
						<EquipmentsSap
							equipmentsSap={equipmentsSap}
							onAddSparePart={onButtonAddClick}
							onUpdateQuantityEquipment={onUpdateQuantityEquipment}
							onChangeQuerySap={onChangeQuerySap}
							sparePartsSap={sparePartsSap}
							querySparePart={querySparePart}
							isDownloadReport={isDownloadReport}
							onDonwloadReportBySerie={onDonwloadReportBySerie}
							isModeCreate={true}
							onAddManual={onAddSparePartRequest}
							userProfile={userProfile}
						/>
					</Paper>
					<br />
					{props.request.draft && (
						<Card>
							<CardContent>
								<AddressComponent
									onSaveSparePartRequest={props.onSaveSparePartRequest}
									requestDraft={props.request.draft}
									isSaving={props.request.isSavingDraft}
									isAuthorizadoForEditedData={isAuthorizadoForEditedData}
									isVisibleChipsAssignement={true}
								/>
							</CardContent>
						</Card>
					)}
				</div>

				{lateralPanelState.open && (
					<AddSparePartPanel
						{...lateralPanelState}
						title='ADD SPARE PART'
						onClose={() => setLateralPanelState({ open: false })}
						onAddSparePart={onButtonAddClick}
						isSaving={props.request.isSavingDraft}
					/>
				)}
			</Content>
		</Layout>
	)
}
CreateDesktopView.propTypes = {
	/**
	 * The list of all the active services, shown in the service selector
	 */
	activeServices: propTypes.array.isRequired,
	/**
	 * Callback executed when an active service is selected
	 */
	onServiceSelected: propTypes.func.isRequired,
	/**
	 * Callback executed when the user save or add a spare part
	 */
	onAddSparePart: propTypes.func.isRequired,
	/**
	 * Callback executed when removing a spare part
	 */
	onRemoveSparePart: propTypes.func,
	/**
	 * The edited spare part request
	 */
	request: propTypes.object.isRequired,
	/**Function invoke callback for open searh materials in sap equipments*/
	onSearchSerieInSap: propTypes.func.isRequired,
	/**Function invoke callback for update quatity in redux store */
	onUpdateQuantityEquipment: propTypes.func.isRequired,
	/**
	 * Download report by serie for spare part
	 */
	onDonwloadReportBySerie: propTypes.func.isRequired,
	/**
	 * Determinate if is download report by serie for spare parts
	 */
	isDownloadReport: propTypes.bool
}
CreateDesktopView.defaultProps = {
	activeServices: [],
	request: {
		service: null,
		spareParts: []
	},
	equipmentsSap: [],
	isDownloadReport: false,
	onSearchSerieInSap: () => console.warn('Callback [onSearchSerieInSap] no defined'),
	onAddSparePart: () => console.warn('Callback [onAddSparePart] no defined'),
	onUpdateQuantityEquipment: () => console.warn('Callback [onUpdateQuantityEquipment] no defined'),
	onDonwloadReportBySerie: () => console.warn('Callback [onDonwloadReportBySerie] no defined')
}

export default withImmutablePropsToJS(CreateDesktopView)
