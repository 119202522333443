import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCardAvatarStyles } from '../../resources/styles/CardAvatarStyle'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';


const CardAvatarSkeleton = (props) => {
    const { isActiveButtonOptions } = props;
    const classes = useCardAvatarStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={4} md={3} lg={2} xl={2}>
                <Card className={classes.root}>
                    {isActiveButtonOptions && <CardHeader
                        className={classes.actionsButton}
                        action={
                            <Skeleton variant="circle" width={20} height={20} />
                        }
                    />}

                    <div className={classes.avatarContainer}>
                        <Skeleton variant="circle" width={90} height={90} />
                    </div>
                    <CardContent className={classes.textContainer}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2} >
                <Card className={classes.root}>
                    {isActiveButtonOptions && <CardHeader
                        className={classes.actionsButton}
                        action={
                            <Skeleton variant="circle" width={20} height={20} />
                        }
                    />}

                    <div className={classes.avatarContainer}>
                        <Skeleton variant="circle" width={90} height={90} />
                    </div>
                    <CardContent className={classes.textContainer}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2}>
                <Card className={classes.root}>
                    {isActiveButtonOptions && <CardHeader
                        className={classes.actionsButton}
                        action={
                            <Skeleton variant="circle" width={20} height={20} />
                        }
                    />}

                    <div className={classes.avatarContainer}>
                        <Skeleton variant="circle" width={90} height={90} />
                    </div>
                    <CardContent className={classes.textContainer}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2}>
                <Card className={classes.root}>
                    {isActiveButtonOptions && <CardHeader
                        className={classes.actionsButton}
                        action={
                            <Skeleton variant="circle" width={20} height={20} />
                        }
                    />}

                    <div className={classes.avatarContainer}>
                        <Skeleton variant="circle" width={90} height={90} />
                    </div>
                    <CardContent className={classes.textContainer}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2}>
                <Card className={classes.root}>
                    {isActiveButtonOptions && <CardHeader
                        className={classes.actionsButton}
                        action={
                            <Skeleton variant="circle" width={20} height={20} />
                        }
                    />}

                    <div className={classes.avatarContainer}>
                        <Skeleton variant="circle" width={90} height={90} />
                    </div>
                    <CardContent className={classes.textContainer}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} md={3} lg={2} xl={2}>
                <Card className={classes.root}>
                    {isActiveButtonOptions && <CardHeader
                        className={classes.actionsButton}
                        action={
                            <Skeleton variant="circle" width={20} height={20} />
                        }
                    />}

                    <div className={classes.avatarContainer}>
                        <Skeleton variant="circle" width={90} height={90} />
                    </div>
                    <CardContent className={classes.textContainer}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CardAvatarSkeleton