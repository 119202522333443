/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import Select from 'react-select'
/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import TextBox from '../../common/TextBox'
import AlertComponent from '../../common/AlertComponent'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import ServiceTimeLine from './ServiceTimeLine'
import SparePartTimeLine from './SparePartTimeLine'
import PaymentsTimeLine from './PaymentsTimeLine'
/** Import material UI */
import { Typography, Paper, Tabs, Tab, AppBar, FormHelperText, LinearProgress } from '@material-ui/core'

/**Import resources section */
import { useSerialHistoryDesktopStyle } from '../resources/useStyles'
const SerialHistoryDesktopView = (props) => {
	const {
		isLoaddingAddresses,
		isLoaddingSerialHistory,
		serieHistory,
		onSearchHistoryBySerie,
		onSearchHistoryByAddress,
		userProfile,
		serviceAddresses
	} = props
	const classes = useSerialHistoryDesktopStyle()

	/**Store local this component  */
	const [visiblePanelIndex, setVisiblePanelIndex] = useState(0)
	const [tabValue, setTabValue] = useState(0)
	const [serialNumber, setSerialNumber] = useState('')
	const [serviceAddress, setServiceAddress] = useState('')
	const [isVisibleHelper, setIsVisibleHelper] = useState(false)

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		}
	}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue)
	}

	const onKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === 'Tab') {
			if (onSearchHistoryBySerie && serialNumber.length === 12) {
				setIsVisibleHelper(false)
				onSearchHistoryBySerie(serialNumber)
			} else {
				setIsVisibleHelper(true)
			}
		}
	}

	const onPropertySerieChange = (event) => {
		let { value } = event.target
		setSerialNumber(value)
	}

	const onPropertyAddressChange = (value) => {
		setServiceAddress(value)
		onSearchHistoryByAddress(serviceAddress)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Serial history</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={11} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<AppBar className={classes.searchTabs}>
						<Tabs value={tabValue} onChange={handleTabChange} variant='fullWidth' indicatorColor='secondary'>
							<Tab label='SEARCH HISTORY SERIAL IN PORTAL' {...a11yProps(0)} />
							<Tab label='ADDRESS SEARCH HISTORY ON THE PORTAL' {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<TabPanel value={tabValue} index={0}>
						<Paper className={classes.paperSearch}>
							<Typography variant='subtitle2'>Enter the serial number of your Imbera Product to view more information</Typography>
							<TextBox
								active={true}
								fullWidth
								name='serialNumber'
								variant='outlined'
								margin='dense'
								label={'Serial number'}
								onKeyDown={onKeyDown}
								onChange={onPropertySerieChange}
								value={serialNumber}
							/>
							{isVisibleHelper && <FormHelperText error>An Imberar serial number consists of 12 digits</FormHelperText>}

							{serieHistory && serieHistory.Services.length === 0 && serieHistory.SpareParts.length === 0 && (
								<AlertComponent
									title='Series without information'
									message='Serial number history not found, please try a new serial number.'
									severityType='warning'
								/>
							)}
							{isLoaddingSerialHistory && (
								<>
									<AlertComponent title={'Getting series from Services'} message={'Please wait...'} severityType={'info'} />
									<LinearProgress />
								</>
							)}
						</Paper>
						<br />
						{serieHistory && serieHistory.Services && serieHistory.Services.length > 0 && (
							<Paper>
								<div className={classes.titleHistory}>
									<Typography color='primary' variant='subtitle1'>{`RECORD | ${
										serieHistory ? serieHistory.Serie : ''
									}`}</Typography>
								</div>
								<AppBar position='static'>
									<Tabs value={visiblePanelIndex} onChange={(event, index) => setVisiblePanelIndex(index)}>
										<Tab label='SERVICES' />
										<Tab label='SPARE PARTS' />
										<Tab label='Payments' />
									</Tabs>
								</AppBar>
								<TabPanel value={visiblePanelIndex} index={0}>
									<ServiceTimeLine serviceHistory={serieHistory ? serieHistory.Services : []} />
								</TabPanel>
								<TabPanel value={visiblePanelIndex} index={1}>
									<SparePartTimeLine spareParts={serieHistory ? serieHistory.SpareParts : []} />
								</TabPanel>

								<TabPanel value={visiblePanelIndex} index={2}>
									<PaymentsTimeLine paymentRequests={serieHistory ? serieHistory.PaymentRequests : []} />
								</TabPanel>
							</Paper>
						)}
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<Paper className={classes.paperSearch}>
							<Typography variant='subtitle2'>Enter the service address of your Imbera Product to view more information</Typography>
							<Select
								options={serviceAddresses ? serviceAddresses : null}
								onChange={onPropertyAddressChange}
								styles={{
									menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
									menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
									menuPortal: (base) => ({ ...base, zIndex: 9999 }) /// THIS IS TO SHOW MENU OVER MODAL
								}}
								menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
								menuPlacement={'auto'}
							/>

							{serieHistory && serieHistory.Services.length === 0 && serieHistory.SpareParts.length === 0 && (
								<AlertComponent
									title='Service Address without information'
									message='Service Address history not found, please try a new address.'
									severityType='warning'
								/>
							)}
							{isLoaddingAddresses && (
								<>
									<AlertComponent title={'Getting series from Address'} message={'Please wait...'} severityType={'info'} />
									<LinearProgress />
								</>
							)}
						</Paper>
						<br />
						{serieHistory && serieHistory.Services && serieHistory.Services.length > 0 && (
							<Paper>
								<div className={classes.titleHistory}>
									<Typography color='primary' variant='subtitle1'>{`RECORD | ${
										serieHistory ? serieHistory.Address : ''
									}`}</Typography>
								</div>
								<AppBar position='static'>
									<Tabs value={visiblePanelIndex} onChange={(event, index) => setVisiblePanelIndex(index)}>
										<Tab label='SERVICES' />
										<Tab label='SPARE PARTS' />
										<Tab label='Payments' />
									</Tabs>
								</AppBar>
								<TabPanel value={visiblePanelIndex} index={0}>
									<ServiceTimeLine serviceHistory={serieHistory ? serieHistory.Services : []} />
								</TabPanel>
								<TabPanel value={visiblePanelIndex} index={1}>
									<SparePartTimeLine spareParts={serieHistory ? serieHistory.SpareParts : []} />
								</TabPanel>

								<TabPanel value={visiblePanelIndex} index={2}>
									<PaymentsTimeLine paymentRequests={serieHistory ? serieHistory.PaymentRequests : []} />
								</TabPanel>
							</Paper>
						)}
					</TabPanel>
				</div>
			</Content>
		</Layout>
	)
}

/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	)
}
TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired
}

export default withImmutablePropsToJS(SerialHistoryDesktopView)
