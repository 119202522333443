const equimpentTablaConfig = [
	{
		title: 'Serial',
		dataSource: 'Serie'
	},
	{
		title: 'Warranty',
		dataSource: 'WarrantyStatus'
	},
	{
		title: 'Model',
		dataSource: 'Model'
	},
	{
		title: 'Gas',
		dataSource: 'Gas'
	},
	{
		title: 'Fault',
		dataSource: 'DoorFailure'
	},
	{
		title: 'Subcategory',
		dataSource: 'FaultWarranty'
	},
	{
		title: 'Completion Date',
		dataSource: 'CompletionDate'
	},
	{
		title: 'Options',
		dataSource: 'Expanded',
		width: 50
	}
]
export default equimpentTablaConfig
