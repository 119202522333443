import { isImmutable } from "immutable";
import { hasAuthorization } from '../session/sessionActions'
import { SPARE_PARTS_IMBERA_MX } from '../../resources/RolesEnum'
import { isNullOrEmpty } from "./StringHelper";

/**
 * Function to determine is user in role 
 * @param {*} roleName 
 * @param {*} roles 
 */
export function isUserInRole(roleName, roles) {
    if (!roles) {
        return false;
    }
    if (isImmutable(roles)) {
        return roles.some(role => role.get('ClaimValue') === roleName);
    }
    if (roles.length > 0) {
        return roles.some(role => role.ClaimValue === roleName);
    }
    return false;
}


/**
 * Function get internals users by role
 * @param {*} roleName 
 * @param {*} internalUsers 
 */
export function getUsersOptionsByRole(roleName, internalUsers, isOptionForSelect) {
    let options = []

    if (internalUsers === null || internalUsers === undefined)
        return options;

    if (internalUsers.length >= 1) {
        internalUsers.map(i => {
            let existOtionWithRole = i.Roles.find(x => x.ClaimValue === roleName);
            if (existOtionWithRole !== undefined && !isOptionForSelect) {
                let objectMapped = {
                    value: i.UserName,
                    label: i.Name,
                    avatarUrl: i.AvatarUrl,
                    isAvatarFromIdentity: true,
                }
                options.push(objectMapped);
            }
            else if (existOtionWithRole !== undefined && isOptionForSelect) {
                let objectMapped = {
                    key: i.UserName,
                    label: i.Name,
                }
                options.push(objectMapped);
            }
            return existOtionWithRole
        })
        return options;
    }
    else
        return options;
}

/**
 * Funcction to get emails from user to send notification
 * @param {*} roleName 
 * @param {*} internalUsers 
 */
export function getEmailUsersByRole(roleName, internalUsers) {
    let emails = []

    if (internalUsers.length >= 1) {
        internalUsers.map(i => {
            let existOtionWithRole = i.Roles.find(x => x.ClaimValue === roleName);
            if (existOtionWithRole !== undefined && !isNullOrEmpty(i.Email)) {
                emails.push(i.Email);
            }
            return existOtionWithRole
        })
        return emails;
    }
    else
        return emails;
}

/**
 * Funcction to get emails from user to send notification
 * @param {*} roleName 
 * @param {*} internalUsers 
 */
 export function getEmailInternalUsersByRole(roleNames = [], internalUsers) {
    let emails = []
    if (internalUsers.length >= 1) {
        internalUsers.map(i => {
            if (hasUserInRoleForGetEmails(i, roleNames)) {
                if(!isNullOrEmpty(i.Email))
                    emails.push(i.Email);
            }
            return i
        })
        return emails;
    }
    else
        return emails;
}

export const hasUserInRoleForGetEmails = (userProfile, allowedRoles = []) => {
	if (!userProfile) return false

	if (allowedRoles.length === 0) return true

	return allowedRoles.reduce((value, allowedRole) => {
		if (value === true) return true
		let foundRole = userProfile.Roles.find((x) => x.ClaimValue === allowedRole)
		return foundRole ? true : false
	}, false)
}

/**
 * Function to get user and emial to add unser in action
 * @param {*} roleName 
 * @param {*} internalUsers 
 */
export function getUsersByRole(roleName, internalUsers) {
    let emails = []

    if (internalUsers.length >= 1) {
        internalUsers.map(i => {
            let existOtionWithRole = i.Roles.find(x => x.ClaimValue === roleName);
            if (existOtionWithRole !== undefined) {
                if(!isNullOrEmpty(i.Email)){
                    emails.push( {UserName: i.UserName, Email: i.Email});
                }
            }
            return existOtionWithRole
        })
        return emails;
    }
    else
        return emails;
}

/**
 * Function to get user by user name
 * @param {*} internalUsers 
 * @param {*} userName 
 */
export function getUserByUserName(internalUsers, dispatcher) {
    let user = null;
    if (internalUsers.length >= 1 && dispatcher) {
        user = internalUsers.find(x=> x.UserName === dispatcher.UserName);
    }
    return user;
}


/**
 * Function to redirect in view home
 * @param {*} userProfile 
 */
export function determinateUrlToRedirectUserProfile(userProfile) {
    if (!userProfile || userProfile.Account == null)
        return null
    // Requiered Terms Accepted by provider
    if ((userProfile.Account.RequieredTermsAccepted))
        return `serviceProviders/register/${userProfile.Account.Token}`
    else if (userProfile.roles.length === 1) {
        let isUserWithRoleImberaMx = (hasAuthorization(userProfile, [SPARE_PARTS_IMBERA_MX]))
        if (isUserWithRoleImberaMx)
            return "spareparts"
    }
    return null
}