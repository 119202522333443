/**Import react section */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import AvatarComponent from '../../common/AvatarComponent';
import { WhiteTextBox } from '../../common/TextBox'

/**Import resources section */
import { salesRepresentativeStyles } from '../../../resources/styles/SalesRepresentativeStyles'

const EditCustomerPanel = (props) => {
    const classes = salesRepresentativeStyles();

    const { onSaveButtonClick, onCloseButtonClick, isSaving, onSetLogoFileBase64, onSetHandleOpenCloseModalUploadImg } = props;
    const [customerModel, setCustomerModel] = useState(props.customer || defaultEditCustomer);

    useEffect(() => {
        setCustomerModel(props.customer)
    }, [props.customer])


    const onPropertyChange = event => {
        let { name, value } = event.target;
        setCustomerModel({
            ...customerModel,
            [name]: value
        })
    }

    const canBeSaved = () => {
        if (customerModel.Code.length <= 0) return false;
        if (customerModel.Name.length <= 0) return false;
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(customerModel)
        }
    }


    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Edit Customer"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={canBeSaved()}
                onSaveButtonClick={onSave}
                isSaving={isSaving}
            />
            <div>
                <div className={classes.avatar}>
                    <AvatarComponent
                        name={customerModel.Name ? customerModel.Name : ""}
                        onSetLogoFileBase64={onSetLogoFileBase64}
                        onSetHandleOpenModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                        avatarUrl={customerModel.AvatarUrl ? customerModel.AvatarUrl : ""}
                        isAvatarFromIdentity={false}
                    />
                </div>
                <div className={classes.texboxContainer}>
                    <WhiteTextBox
                        active={true}
                        fullWidth
                        autoFocus
                        name="Code"
                        variant="outlined"
                        margin="dense"
                        label="Code"
                        onChange={onPropertyChange}
                        value={customerModel ? customerModel.Code : ""}
                    />
                    <WhiteTextBox
                        active={true}
                        fullWidth
                        autoFocus
                        name="Name"
                        variant="outlined"
                        margin="dense"
                        label="Name"
                        onChange={onPropertyChange}
                        value={customerModel ? customerModel.Name : ""}
                    />
                </div>
            </div>
        </LateralPanel>
    )
}

const defaultEditCustomer = {
    Code: '',
    Name: '',
}

EditCustomerPanel.propTypes = {
    isSaving: propTypes.bool.isRequired,
    onSaveButtonClick: propTypes.func,
    onSetHandleOpenCloseModalUploadImg: propTypes.func,
    onCloseButtonClick: propTypes.func,
    customer: propTypes.shape({
        Name: propTypes.string.isRequired,
        Code: propTypes.string.isRequired,
    })
}

EditCustomerPanel.defaultProps = {
    isSaving: false,
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onSetHandleOpenCloseModalUploadImg: () =>
        console.warn('Callback [onSetHandleOpenCloseModalUploadImg] no defined'),
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
}

export default EditCustomerPanel
