/**Import react section */
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
/**Import actions section */
import { setStartDateAndEndDate, loadServicesMetrics } from '../../store/services/serviceActions'
import { servicesMetricsSelector } from '../../store/services/servicesSelectors'
/**Import components section */
import GraphIndexDesktopView from './components/GraphIndexDesktopView'

/**Import resouces section */
import { getGroupedServicesMetrics, getLabelFooterWeewGraph } from '../../store/helpers/ChartHelper'

const GraphIndex = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const startDate = useSelector((state) => state.networkDays.get('startDate'))
  const endDate = useSelector((state) => state.networkDays.get('endDate'))
  const services = useSelector(servicesMetricsSelector)
	const userProfile = useSelector((state) => state.session.get('profile'))

  const [chartData, setChartData] = useState([])
  const [chartDescription, setChartDescription] = useState([])
  useEffect(() => {
    let chartData = getGroupedServicesMetrics(services)
    let weekLabels = getLabelFooterWeewGraph(services)
    setChartData(chartData)
    setChartDescription(weekLabels);

  }, [services])

  const onDateRangeChange = (range) => {
    dispatch(setStartDateAndEndDate(range.startDate, range.endDate))
  }

  useEffect(() => {
    dispatch(loadServicesMetrics(startDate, endDate)).then(() => { })
  }, [dispatch, startDate, endDate])

  const onChangeLocation = (location) => history.push(location)

  return (
    <GraphIndexDesktopView
      startDate={startDate}
      endDate={endDate}
      onDateRangeChange={onDateRangeChange}
      chartData={chartData}
      chartDescription={chartDescription}
      onChangeLocation={onChangeLocation}
      userProfile={userProfile}
    />
  )
}

export default GraphIndex
