/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'

/**Import material UI section */
import { Card, CardContent, Typography, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
/**Import components section */
import { useCardAvatarStyles } from '../../resources/styles/CardAvatarStyle'
import MenuListPopperCommon from '../common/MenuListPopper'
import CardAvatarSkeleton from '../common/CardAvatarSkeleton'
import AvatarCustomSize from '../common/AvatarCustomSizes'

const CardAvatar = (props) => {
    /**bool section props */
    const { isActiveButtonOptions, isAvatarFromIdentity, identifierCardSeleted } = props;
      
    /**Object sections props */
    const { userProfile } = props;
    /**Functions sections props */
    const { onClickEdit, renderOptionMenu, isLoading } = props;

    const classes = useCardAvatarStyles();

    const [settingsElMenu, setSettingsElMenu] = useState(null);

    const handleMenuOpen = (event) => {
        setSettingsElMenu(event.currentTarget);
        if(event)
            event.stopPropagation()
    };

    const onHandleNullMenu = () => {
        setSettingsElMenu(null);
    };

    return (
        {
            false: <>
                <Card className={identifierCardSeleted ===userProfile.Token ? classes.rootSelected :classes.root } onClick={() => onClickEdit(userProfile)}>
                    {isActiveButtonOptions && <CardHeader className={classes.actionsButton}
                        action={
                            <Tooltip title='Options'>
                                <IconButton aria-label="settings" size='small' onClick={handleMenuOpen}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />}
                    <div className={classes.avatarContainer}>
                       
                        <AvatarCustomSize
                            avatarUrl={userProfile ? userProfile.AvatarUrl : null}
                            name={userProfile ? userProfile.Name: ""}
                            avatarStyle={classes.avatar}
                            isAvatarFromIdentity={isAvatarFromIdentity}
                        />
                    </div>

                    <CardContent className={classes.textContainer}>
                        <Typography align="center" variant="subtitle1" noWrap>{userProfile ? userProfile.Name : ""}</Typography>
                        <Typography align="center" variant="subtitle2" noWrap>{userProfile ? (!!userProfile.Code ? userProfile.Code : (userProfile.Account ? userProfile.Account.Name : "-")) : "-"}</Typography>
                    </CardContent>
                </Card>

                <MenuListPopperCommon
                    settingsEl={settingsElMenu}
                    setSettingsEl={onHandleNullMenu}
                    renderOptionMenu={renderOptionMenu}

                /></>,
            true: <CardAvatarSkeleton isActiveButtonOptions={isActiveButtonOptions}/>
        }[isLoading]
    )
}

CardAvatar.propTypes = {
    /**User profile added in database for see card */
    userProfile: propTypes.shape({
        Token: propTypes.string,
        Name: propTypes.string,
    }),
    /**On click in the card for edit information */
    onClickEdit: propTypes.func.isRequired,
    /**On click in the card for edit information */
    isLoading: propTypes.bool,
    identifierCardSeleted : propTypes.string
}

CardAvatar.defaultProps = {
    userProfile: null,
    isLoading:false,
    identifierCardSeleted:"",
    onClickEdit: () =>
        console.warn('Callback [onClickEdit] no defined'),

}

export default CardAvatar
