import axios from 'axios'
import moment from 'moment'
import {
	getAuthorizedConfiguration,
	getAuthorizedConfigurationParameters
} from '../helpers/RequestHelper'

export const IS_LOADING_PAYMENT_REQUESTS = 'IS_LOADING_PAYMENT_REQUESTS'
export const SET_PAYMENT_REQUESTS = 'SET_PAYMENT_REQUESTS'
export const IS_LOADING_DASHBOAR_PAYMENT =
	'IS_LOADIS_LOADING_DASHBOAR_PAYMENTING_DASHBOAR'
export const SET_DASHBOAR_PAYMENTS = 'SET_DASHBOAR_PAYMENTS'
export const IS_SAVING_PAYMENT_REQUEST = 'IS_SAVING_PAYMENT_REQUEST'
export const IS_LOADING_PAYMENT_REQUEST = 'IS_LOADING_PAYMENT_REQUEST'
export const SET_PAYMENT_REQUEST_OPEN = 'SET_PAYMENT_REQUEST_OPEN'
export const ADD_SERVICE_IN_PAYMENT = 'ADD_SERVICE_IN_PAYMENT'
export const IS_SAVING_PAYMENT = 'IS_SAVING_PAYMENT'
export const REMOVE_SERVICE_IN_PAYMENT = 'REMOVE_SERVICE_IN_PAYMENT'
export const UPDATE_SERVICE_IN_PAYMENT = 'UPDATE_SERVICE_IN_PAYMENT'
export const UPDATE_TOTAL_AMOUNT = 'UPDATE_TOTAL_AMOUNT'
export const ADD_FILES_PROVIDER = 'ADD_FILES_PROVIDER'
export const REMOVE_FILES_PROVIDER = 'REMOVE_FILES_PROVIDER'
export const ADD_FILES_PLANT = 'ADD_FILES_PLANT'
export const REMOVE_FILES_PLANT = 'REMOVE_FILES_PLANT'
export const IS_LOADING_SUMMARY_REQUESTS = 'IS_LOADING_SUMMARY_REQUESTS'
export const SET_SUMMARY_REQUESTS = 'SET_SUMMARY_REQUESTS'
export const IS_SAVING_SUMMARY_PAYMENT = 'IS_SAVING_SUMMARY_PAYMENT'
export const SET_SUMMARY_PAYMENT = 'SET_SUMMARY_PAYMENT'
export const IS_DONWLOAD_REPORT = 'IS_DONWLOAD_REPORT'

/**
 * Is loading action constructor
 */
export const isLoadingPayments = (status) => {
	return {
		type: IS_LOADING_PAYMENT_REQUESTS,
		status
	}
}

/**
 * Set payment requests in action list
 */
export const setPaymentRequestsList = (paymentRequests) => {
	return {
		type: SET_PAYMENT_REQUESTS,
		paymentRequests
	}
}

/**
 * Is loading action constructor
 */
export const isLoadingDashboar = (status) => {
	return {
		type: IS_LOADING_DASHBOAR_PAYMENT,
		status
	}
}

/**
 * Set dashboard action dashboard
 */
export const setDashboardList = (dashboard) => {
	return {
		type: SET_DASHBOAR_PAYMENTS,
		dashboard
	}
}

/**
 * Is saving action constructor
 */
export const isSavingPaymentRequest = (status) => {
	return {
		type: IS_SAVING_PAYMENT_REQUEST,
		status
	}
}

const isLoading = (status = true) => {
	return {
		type: IS_LOADING_PAYMENT_REQUEST,
		status: status
	}
}

const isSavingPaymenyRequest = (status) => {
	return {
		type: IS_SAVING_PAYMENT,
		status
	}
}

export const addServiceInPayment = (service) => {
	return {
		type: ADD_SERVICE_IN_PAYMENT,
		service
	}
}

export const setPaymentRequest = (paymentFull) => {
	return {
		type: SET_PAYMENT_REQUEST_OPEN,
		paymentRequest: paymentFull
	}
}

/**
 * Is loading action constructor
 */
export const isLoadingSummary = (status) => {
	return {
		type: IS_LOADING_SUMMARY_REQUESTS,
		status
	}
}

/**
 * Set payment requests summary by provider
 */
export const setSummaryRequests = (summaryRequests) => {
	return {
		type: SET_SUMMARY_REQUESTS,
		summaryRequests
	}
}

/**
 * Is loading action constructor
 */
export const isSavingSummary = (status) => {
	return {
		type: IS_SAVING_SUMMARY_PAYMENT,
		status
	}
}

/**
 * Set payment requests summary by provider
 */
export const setSummaryPayment = (summaryPayment) => {
	return {
		type: SET_SUMMARY_PAYMENT,
		summaryPayment
	}
}

/**
 * Set is donwload report
 */
const setIsDownloadReport = (status) => {
	return {
		type: IS_DONWLOAD_REPORT,
		status
	}
}

/**
 * Load the payment requests
 */
export const loadPaymentRequests = (queryParameters, pageSize = 10) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isLoadingPayments(true))
			const loadPaymentRequestsFromBackend = async (queryParameters) => {
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Define the request
				const command = {
					PageNumber: queryParameters
						? queryParameters.pageNumber
						: 1,
					PageSize: pageSize,
					OrderBy: queryParameters ? queryParameters.sortBy : null,
					OrderType: queryParameters
						? queryParameters.sortDirection
						: 'desc',
					Query:
						!!queryParameters && queryParameters.query
							? queryParameters.query
							: ''
				}
				if (!!queryParameters) {
					if (!!queryParameters.statusFilter) {
						if (typeof queryParameters.statusFilter !== 'string')
							command.StatusFilter = queryParameters
								? queryParameters.statusFilter.map((d) =>
										parseInt(d, 10)
								  )
								: []
						else
							command.StatusFilter = queryParameters
								? [queryParameters.statusFilter]
								: []
					}
					if (!!queryParameters.providers) {
						if (typeof queryParameters.providers !== 'string')
							command.ServiceProviderFilter = queryParameters
								? queryParameters.providers.map((d) => d)
								: []
						else
							command.ServiceProviderFilter = queryParameters
								? [queryParameters.providers]
								: []
					}
					if (!!queryParameters.customers) {
						if (typeof queryParameters.customers !== 'string')
							command.CustomersFilter = queryParameters
								? queryParameters.customers.map((d) => d)
								: []
						else
							command.CustomersFilter = queryParameters
								? [queryParameters.customers]
								: []
					}
				}

				// Get the payment requests
				let url = `${window.config.serviceManagementBackendUrl}/paymentrequests`
				let serverResponse = await axios.post(url, command, config)
				let serviceList = serverResponse.data
				let resultRequests = await getFullRequestWithUsers(
					serviceList.paymentRequests
				)
				let listRequests = {
					paymentRequests: resultRequests,
					pagination: {
						pageNumber: serviceList.pageNumber,
						pageSize: serviceList.pageSize,
						total: serviceList.total,
						orderBy: serviceList.orderBy,
						orderType: serviceList.orderType
					}
				}
				dispatch(setPaymentRequestsList(listRequests))
				dispatch(isLoadingPayments(false))
			}
			return loadPaymentRequestsFromBackend(queryParameters)
		} catch (ex) {
			dispatch(isLoadingPayments(false))
			console.error('Errog getting the payment requests list', ex)
		}
	}
}

/**
 * Action to get full request with diferentes users
 * @param {*} paymentRequests
 * @param {*} configuration
 */
export const getFullRequestWithUsers = async (paymentRequests) => {
	try {
		let url = `${window.config.identityServerUrl}/api/users`
		const content = paymentRequests.map(
			(request) => request.CreatorUserName
		)
		let useIdentityData = await axios.post(url, content, null)
		return paymentRequests.map((request) => {
			let usesData = useIdentityData.data.find(
				(x) => x.user_name === request.CreatorUserName
			)
			request.CreationDate = moment(request.CreationDate, 'MM/DD/YYYY')
			request.CreatorUser = {
				Name: usesData ? usesData.full_name : '',
				Email: usesData ? usesData.email : ''
			}
			return request
		})
	} catch (ex) {
		console.error('Errog getting the internal users list', ex)
	}
}

/**
 * Load payment requests to load dashboard
 */
export const loadDashboardPaymentRequests = () => {
	return async (dispatch, getState) => {
		try {
			const loadPaymentRequestFromBackend = async () => {
				dispatch(isLoadingDashboar(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				let command = {}
				// Get the payment requests from load dashboard
				let url = `${window.config.serviceManagementBackendUrl}/paymentrequests/dashboard`
				let serverResponse = await axios.post(url, command, config)
				dispatch(setDashboardList(serverResponse.data))
			}
			await loadPaymentRequestFromBackend()
		} catch (ex) {
			dispatch(isLoadingDashboar(false))
			console.error('Error getting dashboard payment requests', ex)
		}
	}
}

/**
 * Action to create new payment request
 * @param {*} paymentRequest
 */
export function createPaymentRequest(paymentRequest, userProfile) {
	return function(dispatch, getState) {
		dispatch(isSavingPaymentRequest(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Add payment requests
		let command = {
			Comments: paymentRequest ? paymentRequest.Comments : '',
			ServiceProviderCode: userProfile
				? userProfile.Account
					? userProfile.Account.Code
					: ''
				: '',
			ServiceTicket: paymentRequest.Service
				? paymentRequest.Service.value
				: '',
			InvoiceFolio: paymentRequest ? paymentRequest.InvoiceFolio : ''
		}
		let url = `${window.config.serviceManagementBackendUrl}/paymentrequest`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingPaymentRequest(false))
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingPaymentRequest(false))
				console.error('Error in create payment request', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get payment request
 * @param {*} token
 */
export const getPaymentRequest = (token) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isLoading(true))
			const getPaymentFromBackend = async () => {
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Get pyment request
				let url = `${window.config.serviceManagementBackendUrl}/paymentrequest/${token}`
				let serverResponse = await axios.get(url, config)
				dispatch(setPaymentRequest(serverResponse.data))
				dispatch(isLoading(false))
			}
			await getPaymentFromBackend()

			dispatch(isLoading(false))
		} catch (error) {
			dispatch(isLoading(false))
			console.error('Error to get payment request', error)
		}
	}
}

/**
 * Action creator to add service in payment request
 */
export const addServiceToPayment = (paymentToken, service) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isSavingPaymenyRequest(true))
			// Get the authorized configuration
			let user = getState().oidc.user
			let config = getAuthorizedConfiguration(user)
			// Create command
			let command = {
				PaymentToken: paymentToken,
				ServiceTicket: service ? service.Ticket : ''
			}
			let url = `${window.config.serviceManagementBackendUrl}/paymentrequest/services`
			let serviceResponse = await axios.post(url, command, config)
			dispatch(addServiceInPayment(serviceResponse.data))
		} catch (err) {
			dispatch(isSavingPaymenyRequest(false))
			if (err.response) {
				return Promise.reject(err.response.data.Message)
			} else {
				return Promise.reject({ Message: 'Unexpected error thrown' })
			}
		}
	}
}

/**
 *  Action to remove service from paymente requests
 * @param {*} paymentToken
 * @param {*} serviceToken
 */
export const removeServiceInPayment = (
	paymentToken,
	serviceToken,
	comments
) => {
	return function(dispatch, getState) {
		dispatch(isSavingPaymenyRequest(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/paymentrequest/services/remove`
		let command = {
			Comments: comments,
			Name: user && user.profile ? user.profile.name : '',
			PaymentToken: paymentToken,
			ServiceToken: serviceToken
		}
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				dispatch(setPaymentRequest(serverResponse.data))
				dispatch(isSavingPaymenyRequest(false))
			})
			.catch((error) => {
				dispatch(isSavingPaymenyRequest(false))
				console.error('Error to remove servicerequest', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update number traking spare part
 * @param {*} sparePartRequestToken
 * @param {*} sparePartToken
 * @param {*} numberTraking
 */
export const updateAmountInServiceInPayment = (
	serviceToken,
	amountToken,
	amountValue
) => {
	return async (dispatch, getState) => {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Create command
		let command = {
			ServiceToken: serviceToken,
			AmountToken: amountToken,
			AmountValue: amountValue
		}
		let url = `${window.config.serviceManagementBackendUrl}/services/amounts`
		await axios
			.put(url, command, config)
			.then((serverResponse) => {
				let response = serverResponse.data
				dispatch({
					type: UPDATE_SERVICE_IN_PAYMENT,
					serviceToken: serviceToken,
					amount: response
				})
				dispatch({ type: UPDATE_TOTAL_AMOUNT, amount: response })
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to update amount item in service', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add file by provider
 * @param {*} requestToken
 * @param {*} files
 */
export const addFilesBySupplier = (requestToken, files) => {
	return function(dispatch, getState) {
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let command = {
			RequestToken: requestToken
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/uploadfilesupplier`
		let data = new FormData()
		for (var index = 0; index <= files.length; index++) {
			data.append('file', files[index])
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch({
					type: ADD_FILES_PROVIDER,
					evidences: response.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error uploading evidence by provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove file by provider
 * @param {*} requestToken
 * @param {*} fileName
 */
export function removeFilesProvider(requestToken, fileName) {
	return function(dispatch, getState) {
		let params = {
			Name: fileName
		}
		let configuration = getAuthorizedConfigurationParameters(
			getState().oidc.user,
			params
		)
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/${requestToken}/removefilesbysupplier`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_FILES_PROVIDER,
						fileName: fileName
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error(
					'Error to remove evidence file(s) by provider',
					error.response
				)
				return Promise.reject()
			})
	}
}

/**
 * Action to send to validate by provider
 * @param {*} paymentToken
 */
export function sendToValidate(paymentToken, statusModel, emailsRoleValidator) {
	return function(dispatch, getState) {
		dispatch(isSavingPaymenyRequest(true))

		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			PaymentToken: paymentToken,
			CreatorName: user.profile.name,
			Comments: statusModel ? statusModel.comments : null,
			Users: emailsRoleValidator
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/send`
		return axios
			.put(endPoint, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingPaymenyRequest(false))
					dispatch(setPaymentRequest(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingPaymenyRequest(false))
				console.error(
					'Error to send to validate payment',
					error.response
				)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add file by provider
 * @param {*} requestToken
 * @param {*} files
 */
export const addFilesByPlant = (requestToken, files) => {
	return function(dispatch, getState) {
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let command = {
			RequestToken: requestToken
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/uploadfilesplant`
		let data = new FormData()
		for (var index = 0; index <= files.length; index++) {
			data.append('file', files[index])
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch({
					type: ADD_FILES_PLANT,
					evidences: response.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error uploading evidence by provider', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to remove file by provider
 * @param {*} requestToken
 * @param {*} fileName
 */
export function removeFilesPlant(requestToken, fileName) {
	return function(dispatch, getState) {
		let params = {
			Name: fileName
		}
		let configuration = getAuthorizedConfigurationParameters(
			getState().oidc.user,
			params
		)
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/${requestToken}/removefilesbyplant`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_FILES_PLANT,
						fileName: fileName
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error(
					'Error to remove evidence file(s) by provider',
					error.response
				)
				return Promise.reject()
			})
	}
}

/**
 * Action to accepted or rejected in plant
 * @param {*} paymentToken
 */
export function acceptedOrRejected(paymentToken, statusModel) {
	return function(dispatch, getState) {
		dispatch(isSavingPaymenyRequest(true))

		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			PaymentToken: paymentToken,
			CreatorName: user.profile.name,
			Comments: statusModel ? statusModel.comments : null,
			Status: statusModel ? statusModel.status : null
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/validate`
		return axios
			.put(endPoint, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingPaymenyRequest(false))
					dispatch(setPaymentRequest(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingPaymenyRequest(false))
				console.error(
					'Error to validate payment request',
					error.response
				)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to load requests by range date
 * @param {*} summaryModel
 */
export const loadSummaryRequests = (summaryModel) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isLoadingSummary(true))
			const loadSummaryRequestsFromBackend = async (summaryModel) => {
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Define the request
				const command = {
					ServiceProviderCode: summaryModel.serviceProvider
						? summaryModel.serviceProvider.value
						: '',
					StartDate: summaryModel ? summaryModel.startDate : null,
					EndDate: summaryModel ? summaryModel.endDate : null
				}
				// Get the payment requests
				let url = `${window.config.serviceManagementBackendUrl}/paymentrequests/requestsforsummary`
				let serverResponse = await axios.post(url, command, config)
				dispatch(setSummaryRequests(serverResponse.data))
			}
			return loadSummaryRequestsFromBackend(summaryModel)
		} catch (ex) {
			dispatch(isLoadingSummary(false))
			console.error('Errog getting the payment requests list', ex)
		}
	}
}

/**
 * Action to Save payment summary
 * @param {*} summaryModel
 */
export function savePaymentSummary(summaryModel) {
	return function(dispatch, getState) {
		dispatch(isSavingSummary(true))

		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let command = {
			CreatorName: user.profile.name,
			ServiceProviderCode: summaryModel.serviceProvider
				? summaryModel.serviceProvider.value
				: '',
			StartDate: summaryModel ? summaryModel.startDate : null,
			EndDate: summaryModel ? summaryModel.endDate : null,
			CheckFolio: summaryModel ? summaryModel.checkFolio : null,
			CheckFolios: summaryModel ? summaryModel.checkFolios : null,
			Note: summaryModel ? summaryModel.note : null,
			EmailToSend: summaryModel ? summaryModel.email : null
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/summary`
		return axios
			.post(endPoint, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSummaryPayment(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingSummary(false))
				console.error(
					'Error to validate payment request',
					error.response
				)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to Save payment summary
 * @param {*} summaryModel
 */
export function getPaymentSummary(checkFolio) {
	return function(dispatch, getState) {
		dispatch(isLoadingSummary(true))

		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/summary/${checkFolio}`
		return axios
			.get(endPoint, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSummaryPayment(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isLoadingSummary(false))
				console.error(
					'Error to validate payment request',
					error.response
				)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to donsload dta by serie
 */
export const downloadReportPaymentSummary = (checkFolio) => {
	return function(dispatch, getState) {
		dispatch(isSavingSummary(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let data = {
			CheckFolio: checkFolio
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/summary/download`
		return axios
			.post(endPoint, data, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSummary(false))
					var blob = new Blob([serverResponse.data], {
						type:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Report_Summary_${checkFolio}.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingSummary(false))
				console.error('Error donwload report payment summary', error)
				return Promise.reject()
			})
	}
}

export const downloadReportPaymentSummarySupplier = (
	summaryModel,
	paymentRequests
) => {
	return function(dispatch, getState) {
		dispatch(isSavingSummary(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		const data = {
			ServiceProvider: summaryModel.serviceProvider
				? summaryModel.serviceProvider.label
				: '',
			PaymentRequests: paymentRequests
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/summarysupplier/download`
		return axios
			.post(endPoint, data, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSummary(false))
					var blob = new Blob([serverResponse.data], {
						type:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Report_Summary.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingSummary(false))
				console.error('Error donwload report payment summary', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to delete payment request in status draft
 * @param {*} paymentToken
 */
export function deletePaymentRequest(paymentToken) {
	return function(dispatch, getState) {
		dispatch(isSavingPaymenyRequest(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/delete/${paymentToken}`
		return axios
			.delete(endPoint, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingPaymenyRequest(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingPaymenyRequest(false))
				console.error('Error to delete payment request', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to download repor payment request
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} queryParameters
 */
export const downloadReportPaymentRequest = (
	startDate,
	endDate,
	queryParameters
) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)

		let endPoint = `${window.config.serviceManagementBackendUrl}/paymentrequest/downloadReport`
		let command = {
			StartDate: startDate,
			EndDate: endDate,
			UserName: user.profile.preferred_username
		}
		if (!!queryParameters) {
			if (!!queryParameters.statusFilter) {
				if (typeof queryParameters.statusFilter !== 'string')
					command.StatusFilter = queryParameters
						? queryParameters.statusFilter.map((d) =>
								parseInt(d, 10)
						  )
						: []
				else
					command.StatusFilter = queryParameters
						? [queryParameters.statusFilter]
						: []
			}
			if (!!queryParameters.providers) {
				if (typeof queryParameters.providers !== 'string')
					command.ServiceProviderFilter = queryParameters
						? queryParameters.providers.map((d) => d)
						: []
				else
					command.ServiceProviderFilter = queryParameters
						? [queryParameters.providers]
						: []
			}
		}

		return axios
			.post(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `${moment().format(
						'MM-DD-YYYY'
					)}_ReportPaymentRequest.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report payment', error)
				return Promise.reject()
			})
	}
}
