/**Import react sections */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'

import DesktopView from './components/OpenDesktopView'
import OpenViewMovil from './componentsMobile/OpenViewMovil'
import Toaster from '../common/Toaster'
import Edit from './Edit'
import WarrantyChecksPanel from '../services/components/WarrantyChecksPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel'

/**Import actions section */
import {
	getSparePartRequest,
	updateNumberTrackingSpareaPart,
	checkShipping,
	setCarrierInformation,
	senEmailBySpartPart,
	updateEquipment,
	saveSparePartComments,
	addFilesSparePartResquest,
	removeFilesInSparePartRequest
} from '../../store/spareParts/sparePartsActions'
import { sparePartShowSelector } from '../../store/spareParts/sparePartsSelectors'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { loadInternalUsers } from '../../store/users/UsersActions'
import {
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	PORTAL_ADMINISTRATOR,
	DISPATCHER,
	IMBERA_TECHNICIAN
} from '../../resources/RolesEnum'
import { getEmailInternalUsersByRole } from '../../store/helpers/UserHelper'

/**
 * Open sparte parts view
 */
const Open = (props) => {
	/**Section hooks */
	const isMobile = useIsMobile(props.isMobile)
	const history = useHistory()
	let match = useRouteMatch()
	const dispatch = useDispatch()
	const isLoading = useSelector((state) =>
		state.spareParts.getIn(['show', 'isLoading'])
	)
	const userProfile = useSelector((state) =>
		toSafeObject(state.session.get('profile'))
	)
	const internalUsers = useSelector((state) =>
		toSafeObject(state.users.get('internalUsers'))
	)
	const isGettingTrackingData = useSelector((state) =>
		state.spareParts.getIn(['show', 'isGettingTrackingData'])
	)
	const carrierData = useSelector((state) =>
		state.spareParts.getIn(['show', 'carrier'])
	)
	const isSaving = useSelector((state) =>
		state.spareParts.getIn(['show', 'isSaving'])
	)
	const token = match.params.token
	const showSpareRequest = useSelector(sparePartShowSelector)
	const [editLateralPanelConfig, setEditLateralPanelConfig] = useState({
		opened: false,
		part: null
	})
	const [identifierSparePart, setIdentifierSparePart] = useState('')
	const [lateralPanelStatusConfig, setLateralPanelStatusConfig] = useState({
		opened: false,
		service: null
	})
	const [lateralPanelWarranty, setLateralPanelWarranty] = useState({
		opened: false,
		equipment: null
	})
	let emailsInternalUsers = getEmailInternalUsersByRole(
		[
			SPARE_PARTS_IMBERA_USA,
			PORTAL_ADMINISTRATOR,
			DISPATCHER,
			IMBERA_TECHNICIAN
		],
		internalUsers
	)
	let emailsImberaMX = getEmailInternalUsersByRole(
		[SPARE_PARTS_IMBERA_MX],
		internalUsers
	)

	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})

	useEffect(() => {
		dispatch(getSparePartRequest(token))
	}, [dispatch, token])

	useEffect(() => {
		dispatch(loadInternalUsers())
	}, [dispatch])
	const onChangeLocation = (location) => history.push(location)

	/**
	 * function to on update spare part in equipment
	 * @param {*} sparePart
	 */
	const onUpdateSpartPart = (sparePart, propertyToUpdate) => {
		return dispatch(
			updateNumberTrackingSpareaPart(
				token,
				sparePart,
				emailsInternalUsers,
				emailsImberaMX,
				propertyToUpdate
			)
		).catch((error) => {
			console.error('Error update the spare part', error)
			setToaster({
				open: true,
				message:
					'An error occurred while updating your spare part tracking number',
				variant: 'error'
			})
		})
	}

	/**
	 * Check shipping by tracking number
	 * @param {*} sparePart
	 */
	const onCheckShipping = (carrierCode, trackingNumber, token) => {
		return dispatch(
			checkShipping(carrierCode, trackingNumber.trim(), token)
		)
			.then((data) => {
				editLateralPanelConfig.part['Status'] = data.status
				let updatedSpare = editLateralPanelConfig.part
				setEditLateralPanelConfig({
					...editLateralPanelConfig,
					part: updatedSpare
				})
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to close spare part request',
					variant: 'warning'
				})
			})
	}

	const onSendEmailToTechnical = (sparePartToken) => {
		setIdentifierSparePart(sparePartToken)
		return dispatch(senEmailBySpartPart(token, sparePartToken))
			.then((_) => {
				setIdentifierSparePart('')
				setToaster({
					open: true,
					message: 'Send email to technical successfully',
					variant: 'success'
				})
			})
			.catch((error) => {
				setIdentifierSparePart('')
				setToaster({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'Error to send email to technical',
					variant: 'error'
				})
			})
	}

	const onEditLateralPanelConfig = (part) => {
		setEditLateralPanelConfig({
			opened: !editLateralPanelConfig.opened,
			part: part
		})
		dispatch(setCarrierInformation(null))
	}

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelChangeStatus = (spartPart) => {
		setLateralPanelStatusConfig({
			opened: !lateralPanelStatusConfig.opened,
			spartPart: spartPart
		})
	}

	const onUpdateEquipment = (
		equipmentToken,
		refrigerationNotCoolFailure,
		iluminationFailure,
		doorFailure,
		comments,
		faultWarranty
	) => {
		dispatch(
			updateEquipment(
				token,
				equipmentToken,
				refrigerationNotCoolFailure,
				iluminationFailure,
				doorFailure,
				comments,
				faultWarranty
			)
		).catch(() => {
			console.error('Error to update service')
		})
	}

	/**
	 * Function to update information basic in spare part
	 * @param {*} sparePartRequestsForm
	 */
	const onSaveSparePartRequest = (mapPosition, sparePartRequestsForm) => {
		return dispatch(saveSparePartComments(token, sparePartRequestsForm))
			.then(() => {
				// Nothing to code
				setToaster({
					open: true,
					message: 'Spare part request update successfully',
					variant: 'success'
				})
			})
			.catch((error) => {})
	}

	/**Open lateral panel for show equipment warranties */
	const openPanelWarrantyOnClick = (item) => {
		setLateralPanelWarranty({
			opened: !lateralPanelWarranty.opened,
			equipment: item
		})
	}

	/**Close lateral panel warranty equipment*/
	const onCloseWarrantyClick = () => {
		setLateralPanelWarranty({ opened: false, equipment: null })
	}

	const onAddFilesInSparePartRequest = (files, sparePart) => {
		return dispatch(
			addFilesSparePartResquest(sparePart.Token, files)
		).catch((error) => {
			setToaster({
				open: true,
				message: !isNullOrEmpty(error)
					? error
					: 'An error occurred while adding file',
				variant: 'warning'
			})
		})
	}

	const onRemoveFilesInSparePartRequests = (fileName, sparePart) => {
		return dispatch(
			removeFilesInSparePartRequest(sparePart.Token, fileName)
		).catch((error) => {
			setToaster({
				open: true,
				message: !isNullOrEmpty(error)
					? error
					: 'An error occurred while remove file',
				variant: 'warning'
			})
		})
	}

	return (
		<Fragment>
			{isMobile ? (
				<OpenViewMovil
					isMobile={isMobile}
					onChangeLocation={onChangeLocation}
					isLoading={isLoading}
					sparePartRequest={showSpareRequest}
					userProfile={userProfile}
				/>
			) : (
				<DesktopView
					onChangeLocation={onChangeLocation}
					isLoading={isLoading}
					sparePartRequest={showSpareRequest}
					onUpdateSpartPart={onUpdateSpartPart}
					userProfile={userProfile}
					onEditLateralPanelConfig={onEditLateralPanelConfig}
					onSendEmailToTechnical={onSendEmailToTechnical}
					identifierSparePart={identifierSparePart}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					onUpdateEquipment={onUpdateEquipment}
					onShowWarranty={openPanelWarrantyOnClick}
					isSaving={isSaving}
					onSaveSparePartRequest={onSaveSparePartRequest}
					onAddFilesInSparePartRequest={onAddFilesInSparePartRequest}
					onRemoveFilesInSparePartRequests={
						onRemoveFilesInSparePartRequests
					}
				/>
			)}

			<Edit
				onCloseButtonClick={onEditLateralPanelConfig}
				part={editLateralPanelConfig.part}
				onUpdateSpartPart={onUpdateSpartPart}
				userProfile={userProfile}
				status={showSpareRequest.open && showSpareRequest.open.Status}
				editLateralPanelConfig={editLateralPanelConfig}
				carrierData={carrierData}
				onCheckShipping={onCheckShipping}
				isGettingTrackingData={isGettingTrackingData}
				lateralPanelStatusConfig={lateralPanelStatusConfig}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
			/>

			{/** Equipment Panel */
			lateralPanelWarranty.opened && (
				<LateralPanel onClose={onCloseWarrantyClick}>
					<WarrantyChecksPanel
						onCloseButtonClick={onCloseWarrantyClick}
						equipment={lateralPanelWarranty.equipment}
						serviceType={
							showSpareRequest.open
								? showSpareRequest.open.CustomerType
								: ''
						}
					/>
				</LateralPanel>
			)}

			<Toaster
				toasterOptions={toaster}
				onClose={() => setToaster({ open: false })}
			/>
		</Fragment>
	)
}
export default Open
