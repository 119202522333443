import { makeStyles } from '@material-ui/styles'

export const useCreateDesktopStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px"
    },
    rootSpareParts: {
        position: 'absolute',
        width: 'calc(100% - 50px)',
        marginTop: "10px",
        overflowX: 'hidden',
        maxHeight: 'calc(100% - 75px)',
        overflowY: 'auto',
    },
    rootIndex: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr'
    },
    containerData: {
        maxHeight: `calc(100vh - ${150}px)`,
        overflowY: "auto",
    },
    titleTicket: {
        color: theme.palette.colors.purple,
        padding: "10px"
    },
    flexGrow: {
        flexGrow: 1
    },
    pagination: {
        gridColumn: 1,
        gridRow: 3,
        display: 'flex',
        alignItems: "flex-end"
    },
    containerIndex: {
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${190}px)`,
        minHeight: `calc(100vh - ${190}px)`,
    },
    container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridColumn: 1,
        gridRow: 2,
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${300}px)`,
        minHeight: `calc(100vh - ${300}px)`,
    },
    cardList: {
        gridRow: '1 / 2',
        margin: '10px 10px 0px 0px',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        columnGap: '5px'
    },
    buttonsGroupContainer: {
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroupEquipmentContainer: {
        marginRight: "40px",
        marginTop: "5px",
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroupReport: {
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.gray,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
    },
    buttonsGroup: {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.primarySelected} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth: "14px !important",
            height: "2rem !important",
        },
    },

    textExpanded: {
        color: theme.palette.colors.purple,
    },
    textExpandedBold: {
        marginLeft: theme.spacing(2),
    },
    containerSpareParts: {
        display: "grid",
        gridTemplateRows: 'auto auto ',
        width: "100%"
    },
    buttonCleanContainer: {
        display: "flex",
        gridRow: 1,
        marginTop: "20px",
        height: "47px !important"
    },
    headerSapMovil: {
        display: "flex",
        gridRow: 1,
        justifyContent: "flex-end",
        height: 45,
        marginBottom: 10
    },
    tableSpareParts: {
        gridRow: 2,
    },
    notchedOutlineSuccess: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8
    },
    notchedOutlineSuccessMovile: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8,
        height: 38,
        marginTop: 2
    },
    qualityMovilSap: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonsGroupMovil: {
        paddingLeft: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonCleamMovil: {
        marginRight: 10,
        height: "42px",
        width: "42px",
        marginLeft: 10,
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.red,
            opacity: .75,
            color: theme.palette.common.white,
        },
    },
    warrantyIconRed: {
        marginTop: theme.spacing(1),
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.red}80`,
    },
    warrantyIconGreen: {
        marginTop: theme.spacing(1),
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.green}26`,
    },
    warrantyIconOrange: {
        marginTop: theme.spacing(1),
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: `${theme.palette.colors.orange}26`,
    },
    iconWarranty: {
        width: "1.6rem",
        height: "1.6rem",
        cursor: "pointer",
    },
    amountIcon: {
        width: '45px',
        height: 'auto',
        paddingRight: '5px',
    },
}))

export const useCreateMobileStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px"
    },
    rootIndex: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr'
    },
    containerData: {
        maxHeight: `calc(100vh - ${150}px)`,
        overflowY: "auto",
    },
    titleTicket: {
        color: theme.palette.colors.purple,
        padding: "10px"
    },
    flexGrow: {
        flexGrow: 1
    },
    pagination: {
        gridColumn: 1,
        gridRow: 3,
        display: 'flex',
        alignItems: "flex-end"
    },
    containerIndex: {
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${190}px)`,
        minHeight: `calc(100vh - ${190}px)`,
    },
    container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridColumn: 1,
        gridRow: 2,
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${300}px)`,
        minHeight: `calc(100vh - ${300}px)`,
    },
    cardList: {
        gridRow: '1 / 2',
        margin: '10px 10px 0px 0px',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        columnGap: '5px'
    },
    buttonsGroupContainer: {
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroupEquipmentContainer: {
        marginRight: "40px",
        marginTop: "5px",
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroupReport: {
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.gray,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
    },
    buttonsGroup: {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        "&:hover": {
            backgroundColor: `${theme.palette.colors.primarySelected} !important`,
            color: `${theme.palette.common.white} !important`,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
        "&.MuiButton-root": {
            minWidth: "14px !important",
            height: "2rem !important",
        },
    },
    textExpanded: {
        color: theme.palette.colors.purple,
    },
    textExpandedBold: {
        marginLeft: theme.spacing(2),
    },
    containerSpareParts: {
        display: "grid",
        gridTemplateRows: 'auto auto ',
        width: "100%"
    },
    buttonCleanContainer: {
        display: "flex",
        gridRow: 1,
        marginTop: "20px",
        height: "47px !important"
    },
    headerSapMovil: {
        display: "flex",
        gridRow: 1,
        justifyContent: "flex-end",
        height: 45,
        marginBottom: 10
    },
    tableSpareParts: {
        gridRow: 2,
    },
    notchedOutlineSuccess: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8
    },
    notchedOutlineSuccessMovile: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8,
        height: 38,
        marginTop: 2
    },
    qualityMovilSap: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonsGroupMovil: {
        paddingLeft: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonCleamMovil: {
        marginRight: 10,
        height: "42px",
        width: "42px",
        marginLeft: 10,
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.red,
            opacity: .75,
            color: theme.palette.common.white,
        },
    },
    spartPartsExpanders: {
        "&.MuiCardContent-root": {
            padding: "3px !important"

        }
    }
}))

export const useSerialHistoryDesktopStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px"
    },
    stepperContainer: {
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${470}px)`,
        marginTop: "-20px"
    },
    paperSearch: {
        padding: "20px",
        overflowY: "auto",
    },
    titleHistory: {
        padding: "15px"
    },
    rootTimeLine: {
        display: "flex",
        justifyContent: "flex-start",
        flex: 0
    },
    contentServiecHistory: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    timelineItem: {
        marginLeft: "-20px"
    },
    serviceHeader: {
        padding: "10px",
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '100px 150px 150px 150px 150px 150px 150px',
    },
    serviceHeaderTicket: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    serviceHeaderDate: {
        gridColumnStart: 3,
        gridColumnEnd: 3,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    serviceHeaderStatus: {
        gridColumnStart: 4,
        gridColumnEnd: 4,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    serviceHeaderDescription: {
        gridColumnStart: 5,
        gridColumnEnd: 5,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    serviceHeaderUltim: {
        gridColumnStart: 6,
        gridColumnEnd: 6,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    serviceHeaderItem7: {
        gridColumnStart: 7,
        gridColumnEnd: 7,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    serviceContent: {
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '50px 150px 150px 150px 150px 150px 150px',
    },
    partHeader: {
        padding: "10px",
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '100px 150px 350px 150px 150px 150px',
    },
    partContent: {
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '50px 150px 350px 150px 150px 150px',
    },
}))

export const panelEditUseStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    titleContainer: {
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr',
    },
    titleLeftContent: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRow: 1,
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingTop: "10px"
    },
    titleRightContent: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        paddingRight: "30px",
        paddingTop: "10px"
    },
    editTab: {
        padding: "20px",
    },
    drawerContent: {
        width: "620px",
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    carrierHeader: {
        padding: "5px",
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '50px 150px 150px 1fr',
    },
    carrierContent: {
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '150px 150px 1fr',
    },
    cell1: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRow: 1,
        color: theme.palette.text.primary,
        paddingRight: "5px"
    },
    cell2: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    cell3: {
        gridColumnStart: 3,
        gridColumnEnd: 3,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    cell4: {
        gridColumnStart: 4,
        gridColumnEnd: 4,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    cell5: {
        gridColumnStart: 5,
        gridColumnEnd: 5,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    carrierHeaderGrid: {
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '350px 1fr',
    },
    headerLeft: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRow: 1,
        padding: "5px"
    },
    headerRight: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        padding: "5px"
    },
    titleBold: {
        fontWeight: 'bold'
    },
    titleRight: {
        marginLeft: "3px",
    },
    flex: {
        display: 'flex',
    },
    stepper: {
        alignSelf: 'stretch',
        marginTop: "-8px"
    },
    stepLabel: {
        fontSize: '10px'
    },
    containerLineTime: {
        maxHeight: `calc(100vh - ${520}px)`,
        overflowY: "auto",
    },
    fileImport: {
        marginRight: '5px',
        marginBottom: '-5px',
        display: "flex",
        justifyContent: "flex-end"
    }
}))

export const useSerialSapDesktopStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${85}px)`,
    },
    paperSearch: {
        padding: "20px",
        overflowY: "auto",
        "& .MuiFormLabel-root": {
            color: theme.palette.text.primary,
        },
    },
}))

export const useSerialSapMobileStyle = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        overflowY: 'auto',
        maxHeight: `calc(100vh - ${85}px)`,
    },
    paperSearch: {
        padding: "20px",
        overflowY: "auto",
        "& .MuiFormLabel-root": {
            color: theme.palette.text.primary,
        },
    },
}))