/**Import react section */
import React, { useState } from 'react'
import moment from 'moment'

/**Import common componets */
import ButtonConfirm from './../../../common/Buttons/ButtonConfirm'
/**Import MaterialUi section */
import { Grid } from '@material-ui/core'
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import DateSelector from '../../../common/DateSelector'


const ChangeInitialDate = (props) => {
    const { onSetPasswordDialogConfiguration } = props;
    const classes = createServicesStyle();
    const [changeModdel, setCompletedModel] = useState({status: props.status, intiatedDate: moment()});

    const openModalForChangeInitialDateService = () => {
        if (onSetPasswordDialogConfiguration)
            onSetPasswordDialogConfiguration(changeModdel)
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setCompletedModel({
            ...changeModdel,
            [name]: value
        });
    }

    /**Verify if can be saved */
    const canBeSaved = () => {
        if ((changeModdel && changeModdel.intiatedDate) ? (changeModdel.intiatedDate.length <= 1) : true) return false;
        return true;
    }

    return (
        <div>
            <Grid item xs={12}>
                <DateSelector
                    dataCy='data-picker-intiated-date'
                    label="Intiated Date"
                    name='intiatedDate'
                    showIncon={false}
                    active={true}
                    value={changeModdel ? changeModdel.intiatedDate : null}
                    disabled={(false)}
                    onChange={onPropertyChange}
                />
            </Grid>
            <Grid item xs={12} className={classes.updateButton}>
                <ButtonConfirm
                    title="CONFIRM"
                    isSaving={!canBeSaved()}
                    onSavedClick={openModalForChangeInitialDateService}
                />
            </Grid>
        </div>
    )
}

export default ChangeInitialDate
