/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/**Import component sections */
import DesktopView from './components/DesktopView'
import Create from './Create'
import Toaster from '../common/Toaster'
import Edit from './Edit'

/**Import ctions sections */
import {
	loadAllSalesRepresentatives,
	updateSalesRepresentative,
	lockedUser,
	REMOVE_USER_FROM_ACCOUNT,
	downloadSalesRepresentativesList
} from '../../store/salesrepresentatives/salesRepresentativesActions'

/**
 * This is the index of the sales representative */
const Index = () => {
	/**Hooks sections */
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()

	const isLoading = useSelector((state) =>
		state.salesRepresentatives.get('isLoadingRepresentatives')
	)
	const isSaving = useSelector((state) => state.salesRepresentatives.get('isSaving'))
	const allSalesRepresentatives = useSelector((state) =>
		state.salesRepresentatives.get('salesRepresentatives')
	)
	const pagination = useSelector((state) => state.salesRepresentatives.get('pagination'))
	const loggedUserProfile = useSelector((state) => state.session.get('profile'))
	const userProfile = useSelector((state) => state.session.get('profile'))

	/**Local store this component section */
	const [query, setQuery] = useState('')
	const [salesEdit, setSalesEdit] = useState({ opened: false, customer: null })
	const [addPanelConfiguration, setAddPanelConfiguration] = useState({ opened: false })
	const [gridMode, setGridMode] = useState(false)
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false)
	const [identifierCardSeleted, setIdentifierCardSelected] = useState()
	const [identifierLockSeleted, setIdentifierLockSelected] = useState()

	/**
	 * Here we save and switch view mode grid|list and persist on local storage.
	 */
	useEffect(() => {
		if (gridMode === false) {
			setGridMode(false)
		} else {
			setGridMode(true)
		}
	}, [gridMode])

	/**
	 * Load internal user
	 */
	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query) setQuery(queryParameters.query)
		dispatch(loadAllSalesRepresentatives(queryParameters))
	}, [dispatch, location.search])

	const updateSalesRepresentativeData = (representativeData) => {
		dispatch(updateSalesRepresentative(representativeData))
			.then((representative) => {
				setSalesEdit({ opened: false, customer: representative })
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to update sales representative',
					variant: 'warning'
				})
			})
	}

	/**
	 * Update sort criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onEditSalesRepresentativeClick = (customerData) => {
		setIdentifierCardSelected(customerData.Token ? customerData.Token : '')
		setSalesEdit({ opened: true, customer: customerData })
	}

	const onClosedEditLateralPanel = () => {
		setIdentifierCardSelected('')
		setSalesEdit({ opened: false, customer: null })
	}

	/**On add lateral panel configuration */
	const onAddLateralPanelConfig = () => {
		setAddPanelConfiguration({ opened: !addPanelConfiguration.opened })
	}

	/**Change mode view in sales representative */
	const switchViewMode = () => {
		if (gridMode === false) {
			setGridMode(true)
		} else {
			setGridMode(false)
		}
	}

	/**Open/close dialog modal for upload logo user */
	const onSetHandleOpenCloseModalUploadImg = (representative) => {
		setHandleOpenModalUploadImg(!handleOpenModalUploadImg)
		if (representative) {
			setSalesEdit({ opened: true, customer: representative })
		}
	}

	const onViewUserClick = (representative) => {
		history.push(
			`/salesRepresentatives/${representative.Code}/${representative.Name}/${representative.Token}`
		)
	}

	const onRemoveUserFromAccount = (token) => {
		dispatch({
			type: REMOVE_USER_FROM_ACCOUNT,
			token
		})
	}

	const onDownloadListOfSalesRepresentatives = () => {
		dispatch(downloadSalesRepresentativesList())
	}

	const onLocked = (token, isLocked, event) => {
		if (event) event.stopPropagation()
		setIdentifierLockSelected(token)
		dispatch(lockedUser(token, isLocked))
			.then((_) => {
				setIdentifierLockSelected('')
				onRemoveUserFromAccount(token)
			})
			.catch((error) => {
				setIdentifierLockSelected('')
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to update service provider',
					variant: 'warning'
				})
			})
	}

	return (
		<>
			<DesktopView
				isLoading={isLoading}
				allSalesRepresentatives={allSalesRepresentatives}
				searchValue={query}
				onChangeSortingCriteria={updateSortCriteria}
				onChangeSearchValue={(query) => updateSearchQuery(query)}
				onChangePage={onChangePage}
				onEditSalesRepresentativeClick={onEditSalesRepresentativeClick}
				pagination={pagination}
				switchViewMode={switchViewMode}
				gridMode={gridMode}
				onAddLateralPanelConfig={onAddLateralPanelConfig}
				identifierCardSeleted={identifierCardSeleted}
				onViewUserClick={onViewUserClick}
				onLocked={onLocked}
				identifierLockSeleted={identifierLockSeleted}
				loggedUserProfile={loggedUserProfile}
				onDownloadReport={onDownloadListOfSalesRepresentatives}
				userProfile={userProfile}
			/>
			<Create
				onAddLateralPanelConfig={onAddLateralPanelConfig}
				addPanelConfiguration={addPanelConfiguration}
				isSaving={isSaving}
			/>
			<Edit
				updateSalesRepresentativeData={updateSalesRepresentativeData}
				salesEdit={salesEdit}
				isSaving={isSaving}
				onClosedEditLateralPanel={onClosedEditLateralPanel}
				handleOpenModalUploadImg={handleOpenModalUploadImg}
				onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
			/>
			{/** Toaster */}
			{toasterOptions.open && (
				<Toaster
					toasterOptions={toasterOptions}
					onClose={() => setToasterOptions({ open: false })}
				/>
			)}
		</>
	)
}
export default Index
