import { makeStyles } from '@material-ui/styles'

/**
 * avatar styles
 */
export const useCardStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateRows: '40px auto',
        maxWidth: "100%",
        maxHeight: "auto",
        minHeight: "150px",
    },
    header: {
        gridRow: 1,
        backgroundColor: theme.palette.colors.purpleDark,
        color: theme.palette.common.white,
    },
    headerNoDate: {
        gridRow: 1,
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
    },
    content: {
        gridRow: 2,
        padding: "10px",
    },
    headerSkeleton: {
        gridRow: 1,
    },
    titleHeader:{
        marginTop:"8px",
        marginLeft:"3px",
        fontSize:"15px",
        display:"flex"

    },
    flexGrow:{
        flexGrow:1
    },
    icon:{
        marginRight:"7px"
    }
}))
