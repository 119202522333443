/**Import react section */
import React, { Fragment } from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'

import { Typography, ButtonGroup } from '@material-ui/core'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import FilterSelect from '../../common/Filters/FilterSelect'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import SearchBox from '../../common/SearchToolbox'
import Datagrid from '../../common/apsys.datagrid.materialui/DataGrid'
import Pagination from '../../common/Pagination/Pagination'
import ImagesNoInformation from '../../common/ImagesNoInformation'
import DashboardCard from '../../dashboard/components/DashboardCard'
import ReportDateRange from '../../common/ReportDateRange/ReportDateRange'

import defaultTheme from '../../../resources/themes/default'
import { getDatagridConfiguration } from '../resources/requestIndexConfig'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import { useCreateDesktopStyle } from '../resources/useStyles'
import {
	IMBERA_TECHNICIAN,
	PORTAL_ADMINISTRATOR,
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	CUSTOMER_MANAGER,
	VISUALIZER
} from '../../../resources/RolesEnum'
import { GetStatusSparePartsClosed, GetStatusTrackingNumber, GetWarehouseType } from '../../../store/helpers/FilterHelper'
import { getUsersOptionsByRole } from '../../../store/helpers/UserHelper'

/**import helpers section */
import { getOptions } from '../../../store/helpers/FilterHelper'

const IndexClosedDesktopView = (props) => {
	const classes = useCreateDesktopStyle()
	const {
		userProfile,
		sparePartsIndex,
		onOpenRequest,
		pagination,
		onChangePage,
		onChangeSortingCriteria,
		internalUsers,
		allCustomers,
		serviceProviders,
		isDownloadReport,
		onDownloadReportGral
	} = props
	let configuration = getDatagridConfiguration()
	let technicianUserOptions = getUsersOptionsByRole(IMBERA_TECHNICIAN, internalUsers, true)
	let serviceProvider = getOptions(serviceProviders)
	let customers = getOptions(allCustomers)

	const onRenderContent = () => {
		if (sparePartsIndex.requests.length >= 1 || sparePartsIndex.isLoading) {
			return (
				<Datagrid
					headers={configuration}
					isLoading={sparePartsIndex.isLoading}
					data={sparePartsIndex.requests}
					onChangeSortingCriteria={onChangeSortingCriteria}
					sortBy={pagination.orderBy}
					sortDirection={pagination.orderType}
					onItemClick={onRowClick}
				/>
			)
		} else {
			return (
				<ImagesNoInformation
					query={props.searchValue}
					data={sparePartsIndex.requests}
					isLoading={sparePartsIndex.isLoading}
					titleData={'No exist spart part request closed'}
				/>
			)
		}
	}

	const onRowClick = (sender, item) => {
		if (onOpenRequest) {
			onOpenRequest(item)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Spare Part Request closed</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={4} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<div className={classes.cardList}>
						<DashboardCardLists isLoadingDashboard={props.isLoadingDashboard} dashboard={props.dashboard} />
					</div>
					<div className={classes.container}>
						<DataGridToolbar
							userProfile={userProfile}
							allowedRoles={IMBERA_TECHNICIAN}
							allowedRolesManual={IMBERA_TECHNICIAN}
							onChange={props.onChangeSearchValue}
							searchValue={props.searchValue}
							technicianUserOptions={technicianUserOptions}
							serviceProviders={serviceProvider}
							customers={customers}
							isDownloadReport={isDownloadReport}
							onDownloadReportGral={onDownloadReportGral}
						/>
						{onRenderContent()}
					</div>
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination
							count={pagination.total ? pagination.total : 0}
							page={pagination.pageNumber ? pagination.pageNumber : 0}
							onChangePage={onChangePage}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}

export default withImmutablePropsToJS(IndexClosedDesktopView)

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()

	return (
		<div className={classes.searchIndex}>
			<SearchBox placeholder='Search request' onChange={props.onChange} value={props.searchValue} />
			<ButtonGroup component='span' variant='text' size='small' className={classes.buttonsGroupContainer}>
				<ReportDateRange onDownloadReport={props.onDownloadReportGral} isDownloadReport={props.isDownloadReport} />
			</ButtonGroup>
			<div className={classes.flexGrow} />
			<FilterSelect
				nameFilter={'Tracking Status'}
				data={GetStatusTrackingNumber()}
				nameFilterUrl={'trackingFilter'}
				isVisibleSearch={false}
				isActiveCleanButton={true}
				userProfile={props.userProfile}
				roles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SPARE_PARTS_IMBERA_USA,
					SPARE_PARTS_IMBERA_MX,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					VISUALIZER
				]}
			/>
			<FilterSelect
				nameFilter={'Warehouses'}
				data={GetWarehouseType()}
				nameFilterUrl={'warehouseFilter'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SPARE_PARTS_IMBERA_USA,
					SPARE_PARTS_IMBERA_MX,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					VISUALIZER
				]}
			/>
			<FilterSelect
				nameFilter={'Technicians'}
				data={props.technicianUserOptions}
				nameFilterUrl={'technicianFilter'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[IMBERA_TECHNICIAN, PORTAL_ADMINISTRATOR, SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, VISUALIZER]}
			/>
			<FilterSelect
				nameFilter={'PROVIDERS'}
				data={props.serviceProviders}
				nameFilterUrl={'providers'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[IMBERA_TECHNICIAN, PORTAL_ADMINISTRATOR, SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, VISUALIZER]}
			/>
			<FilterSelect
				nameFilter={'CUSTOMERS'}
				data={props.customers}
				nameFilterUrl={'customers'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[IMBERA_TECHNICIAN, PORTAL_ADMINISTRATOR, SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, VISUALIZER]}
			/>
			<FilterSelect
				nameFilter={'STATUS'}
				data={GetStatusSparePartsClosed()}
				nameFilterUrl={'statusFilter'}
				isVisibleSearch={false}
				userProfile={props.userProfile}
				roles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SPARE_PARTS_IMBERA_USA,
					SPARE_PARTS_IMBERA_MX,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					VISUALIZER
				]}
			/>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool
}

DataGridToolbar.defaultProps = {
	gridMode: false,
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined'),
	onDownloadReportGral: () => console.warn('Callback [onDownloadReportGral] no defined')
}

/**
 * DashboardCardLists component
 */
const DashboardCardLists = (props) => {
	const { dashboard } = props

	return (
		<Fragment>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.completedServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.completedServiceDark}
				totalServices={dashboard ? dashboard.totalClosed : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalClosed : 0}
				label={'Closed  Parts'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.cancelServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.cancelServiceDark}
				totalServices={dashboard ? dashboard.totalCancelled : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalCancelled : 0}
				label={'Cancelled'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.assignedMain}
				secondaryColor={defaultTheme.palette.serviceStatus.assignedDark}
				totalServices={dashboard ? dashboard.totalServices : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalServices : 0}
				label={'Total with services'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.newServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.newServiceDark}
				totalServices={dashboard ? dashboard.totalManual : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalManual : 0}
				label={'Total manual'}
			/>
		</Fragment>
	)
}
