
/** Import react section **/
import React from 'react';
import moment from 'moment'
import propTypes from 'prop-types'
import defaultTheme from '../../../resources/themes/default'
/** Import Material UI section **/
import {
    Paper,
    Popper,
} from '@material-ui/core';

/**Import componets section */
import { DateRangeSelectStyles } from '../../../resources/styles/ServicesListStyles'
import DateRangePicker from '../daterangepicker/DateRangePicker'
import ButtonCancel from '../Buttons/ButtonCancel'
import ButtonConfirm from '../Buttons/ButtonConfirm'


export const PopperComponent = (props) => {
    const classes = DateRangeSelectStyles();
    const { settingsEl, endDate, startDate, onDateRangeChange, onDownloadReport, isDownloadReport, OnlySave } = props;

    let open = !!settingsEl;

    const onButtonConfirmClick=()=>{
        if(onDownloadReport){
            return onDownloadReport(startDate, endDate)
                .then(_=>{
                    /**Closed popper download report */
                    props.setSettingsEl()
                })
        }
    }

    return (
        <Popper
            anchorEl={settingsEl}
            open={open}
            placement={'bottom'}
        >
            <Paper
                elevation={2}
                className={classes.papperPopper}
            >
                <div className={classes.containerData}>
                    <NetworkDayDateRangePicker
                        endDate={endDate}
                        startDate={startDate}
                        onDateRangeChange={onDateRangeChange}
                    />
                    <div className={classes.actionButtons}>
                        <ButtonCancel
                            title='CANCEL'
                            onCancelClick={props.setSettingsEl}
                            isSaving={isDownloadReport}
                        />
                        {!OnlySave ? (
                            <ButtonConfirm
                                title='CONFIRM'
                                onSavedClick={onButtonConfirmClick}
                                isSaving={isDownloadReport}
                            />

                        ) : (
                            <ButtonConfirm
                                title='CONFIRM'
                                onSavedClick={props.setSettingsEl}
                                isSaving={isDownloadReport}
                            />

                        )}
                    </div>

                </div>
            </Paper>
        </Popper>
    )
}

const NetworkDayDateRangePicker = (props) => {
    const predefinedRanges = [
        {
            label: 'This Year',
            startDate: moment().startOf('year'),
            endDate: moment()
        },
        {
            label: 'Last Year',
            startDate: moment().add(-1, 'year'),
            endDate: moment()
        },
        {
            label: 'Last Semester',
            startDate: moment().add(-6, 'month'),
            endDate: moment()
        },
        {
            label: 'Last Trimester',
            startDate: moment().add(-3, 'month'),
            endDate: moment()
        }
    ]

    return (
        <DateRangePicker
            textColor={defaultTheme.palette.primary.light}
            variant='outlined'
            startDate={props.startDate}
            endDate={props.endDate}
            predefinedRanges={predefinedRanges}
            onChangeRange={props.onDateRangeChange}
        />
    )
}

PopperComponent.propTypes = {
	/**
	 * Callback execute for fownload report by range date 
	 */
    onDownloadReport: propTypes.func.isRequired,
    /**
     * Determinate if is download report
     */
    isDownloadReport: propTypes.bool.isRequired,
    /**
     * OnlySave
     */
    OnlySave: propTypes.bool,
}

PopperComponent.defaultProps = {
    OnlySave: false,
    isDownloadReport: false,
    onDownloadReport: () =>
        console.warn('Callback [onDownloadReport] no defined'),
}

export default PopperComponent
