/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';

/**Import Material Ui section */
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { toasterStyles } from '../../resources/styles/ToasterStyles'

/**
 * Toast component
 *
 * @class Toaster
 * @extends {Component}
 */
export const Toaster = props => {
    const classes = toasterStyles();
    const { toasterOptions, onClose } = props;

    return (
        <Snackbar
            open={toasterOptions.open}
            autoHideDuration={6000}
            onClose={onClose}
            className={classes.root}
        >
            <SnackbarContent className={classes[toasterOptions.variant]}
                message={
                    <span className={classes.message}>
                        {toasterOptions.message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}

Toaster.propTypes = {
    /** bool to active toaster */
    open: PropTypes.bool.isRequired,
    /**Function invoke callback for closer toaster */
    onClose: PropTypes.func.isRequired,
    /**object to show */
    toasterOptions: PropTypes.object.isRequired,
};

Toaster.defaultProps = {
    open: false,
    toasterOptions: {
        open: false,
        variant: null,
         message: null
    },
    onClose: () =>
        console.warn('Callback [onClose] no defined'),
}


export default Toaster;