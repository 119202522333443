/**Import react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper';

/**Import components section */
import ShowDesktopView from './components/ShowDesktopView'
import UploadImgCropDialog from '../../common/cropDialog/UploadImgCropDialog'
import Toaster from '../../common/Toaster'

/**Import actions sections */
import { loadUserProfile, saveUser, uploadLogoUser } from '../../../store/users/UsersActions'
const Show = (props) => {
    const dispatch = useDispatch();
    const [isActiveModeEdit, setActiveModeEdit] = useState(false);
    const userProfile = useSelector((state) => state.users.get('user'))
    const isSaving = useSelector((state) => state.users.get('isSaving'))
    const isLoading = useSelector((state) => state.users.get('isLoading'))
    const loggedUser = useSelector((state) => state.session.get('profile'))
    const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false);
    const [logoFileBase64, setLogoFileBase64] = useState("");
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    const token = props.match.params.token;
    /**
	 * Load profile user
	 */
    useEffect(() => {
        dispatch(loadUserProfile(token))
    }, [dispatch, token])

    const onEditButtonClick = () => {
        setActiveModeEdit(!isActiveModeEdit)
    }

    /**
     * Handler to edit an user.
     * Execute async action to edit an user.
     * @param {object} user
     */
    const editUserHandler = user => {
        dispatch(saveUser(user))
            .then(_ => {
                setActiveModeEdit(!isActiveModeEdit)
                setToasterOptions({ open: true, message: 'Information saved success', variant: 'success' })
            })
            .catch(error => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to update information', variant: 'error' })
            })
    };

    /**Open/close dialog modal for upload logo user */
    const onSetHandleOpenCloseModalUploadImg = () => {
        setHandleOpenModalUploadImg(!handleOpenModalUploadImg);
    };

    /**Upload image crop */
    const onUploadLogoUser = (imageBase64) => {
        let userId = userProfile.get("Id")
        dispatch(uploadLogoUser(userId, imageBase64))
            .then(() => {
                onSetHandleOpenCloseModalUploadImg();
            })
            .catch(() => {
                console.error("Error to upload image crop");
            });
    };

    /**Upload img base 64 */
    const onSetLogoFileBase64 = (fileBase64) => {
        setLogoFileBase64(fileBase64);
    };

    return (
        <>
            <ShowDesktopView
                onEditButtonClick={onEditButtonClick}
                isModeSaved={isActiveModeEdit}
                userProfile={userProfile}
                onSaveButtonClick={editUserHandler}
                isSaving={isSaving}
                onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                onSetLogoFileBase64={onSetLogoFileBase64}
                loggedUser={loggedUser}
                isLoading={isLoading}
            />

            {
                <UploadImgCropDialog
                    open={handleOpenModalUploadImg}
                    onClose={onSetHandleOpenCloseModalUploadImg}
                    fileBase64={logoFileBase64}
                    isSaving={isSaving}
                    onUploadImagen={onUploadLogoUser}

                />
            }
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

export default Show
