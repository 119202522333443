/** Redux section import **/
import { combineReducers } from 'redux'
import { reducer as oidcReducer } from 'redux-oidc'
import sessionReducer from './session/sessionReducer'
import usersReducer from './users/UsersReducer'
import servicesReducer from './services/serviceReducer'
import salesRepresentativesReducer from './salesrepresentatives/salesRepresentativesReducer'
import serviceProvidersReducer from './serviceproviders/serviceProvidersReducer'
import networkDaysReducer from './networkdays/networkdaysReducer'
import retailsReducer from './retails/retailsReducer'
import sparePartsReducer from './spareParts/sparePartsReducer'
import regionsReducer from './regions/regionsReducer'
import customersReducer from './customers/customersReducer'
import paymentRequestsReduder from './paymentrequests/paymentRequestReducer'
import sparePartSalesReducer from './sparePartSales/sparePartSalesReducer'
import warrantySeriesSapReducer from './parserSeriesSap/ParserSeriesSapReducer'
import statisticsReducer from './statistics/statisticsReducer'

export default combineReducers({
	oidc: oidcReducer,
	session: sessionReducer,
	users: usersReducer,
	services: servicesReducer,
	salesRepresentatives: salesRepresentativesReducer,
	serviceProviders: serviceProvidersReducer,
	retails: retailsReducer,
	networkDays: networkDaysReducer,
	spareParts: sparePartsReducer,
	regions: regionsReducer,
	customers:customersReducer,
	paymentRequests: paymentRequestsReduder,
	sparePartSales: sparePartSalesReducer,
	warrantySeriesSap: warrantySeriesSapReducer,
	statistics: statisticsReducer,

})
