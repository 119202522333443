/**Import react section */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/**Import actions section */
import { searchHistoryBySerie, searchHistoryByAddress, getAllServiceAddresses } from '../../store/spareParts/sparePartsActions'
/**Import components section */
import SerialHistoryDesktopView from './components/SerialHistoryDesktopView'
const SerialHistoryIndex = (props) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const onChangeLocation = (location) => history.push(location)

	const isLoaddingSerialHistory = useSelector((state) => state.spareParts.get('isLoaddingSerialHistory'))
	const serieHistory = useSelector((state) => state.spareParts.get('serialHistory'))
	const userProfile = useSelector((state) => state.session.get('profile'))
	const isLoaddingAddresses = useSelector((state) => state.spareParts.get('isLoaddingAddresses'))
	const serviceAddresses = useSelector((state) => state.spareParts.get('serviceAddresses'))

	/**
	 * Load all service addresses for address selector
	 */
	useEffect(() => {
		dispatch(getAllServiceAddresses())
	}, [dispatch])

	const onSearchHistoryBySerie = (serie) => {
		return dispatch(searchHistoryBySerie(serie))
	}

	const onSearchHistoryByAddress = (address) => {
		return dispatch(searchHistoryByAddress(address))
	}

	return (
		<SerialHistoryDesktopView
			isLoaddingSerialHistory={isLoaddingSerialHistory}
			onSearchHistoryBySerie={onSearchHistoryBySerie}
			isLoaddingAddresses={isLoaddingAddresses}
			onSearchHistoryByAddress={onSearchHistoryByAddress}
			serieHistory={serieHistory}
			serviceAddresses={serviceAddresses}
			onChangeLocation={onChangeLocation}
			userProfile={userProfile}
		/>
	)
}

export default SerialHistoryIndex
