/**Import react section */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

/**Import components section */
import Toaster from '../common/Toaster'
import ChangeProviderPanel from './components/ChangeProviderPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel';

/**Import helper section */
import { SpareParRequestStatus } from '../../store/helpers/AppConstants'
import { deleteSparepartRequests, sendRequestToValidationInplant, cancelRequest } from '../../store/spareParts/sparePartsActions'
import { getEmailUsersByRole } from '../../store/helpers/UserHelper'
import { IMBERA_TECHNICIAN } from '../../resources/RolesEnum'
import { toSafeObject } from '../../store/helpers/StateHelper'


const EditSpareProvider = (props) => {
    /**Use hooks section */
    const dispatch = useDispatch()
    const history = useHistory()

    /**Props section */
    const { lateralPanelProviderConfig, openLateralPanelSendProvider } = props

    /**Use selector section */
    const isSaving = useSelector((state) => state.spareParts.getIn(['show', 'isSaving']))
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    let emailsRolesTechsUsa = getEmailUsersByRole(IMBERA_TECHNICIAN, internalUsers)

    /**State local for this component*/
    const [toaster, setToaster] = useState({
        open: false,
        variant: null,
        message: null
    })

    /**
     * Action to delete spare part in draft
     */
    const onDeleteSparePart = () => {
        return dispatch(deleteSparepartRequests(lateralPanelProviderConfig ? lateralPanelProviderConfig.spartPart.Token : ''))
            .then((_) => {
                history.push('/spareparts')
                openLateralPanelSendProvider();
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to deleted spart part requests', variant: 'warning' })
                console.error("Failed to removed spare part requests", error);
            });
    };

    /**
	 * Callback executed when the user submits the request
	 */
    const onSentToValidateRequest = () => {
        dispatch(sendRequestToValidationInplant(lateralPanelProviderConfig ? lateralPanelProviderConfig.spartPart : null, emailsRolesTechsUsa))
            .then(() => {
                openLateralPanelSendProvider();
                history.push('/spareparts')
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to submit spare part a tech', variant: 'error' })
            })
    }

        /**
     * On clancel spare part request
     */
    const onCancelRequest = () => {
        dispatch(cancelRequest(lateralPanelProviderConfig ? lateralPanelProviderConfig.spartPart.Token : ''))
            .then(() => {
                openLateralPanelSendProvider();
                history.push('/spareparts')
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to canelled spare part request', variant: 'warning' })
            })
    }

    /**
     * Function to invoke action to change status
     * @param {*} status 
     */
    const GetFunctionToInvoke = (status) => {
        switch (Number(status)) {
            case SpareParRequestStatus.SEND_TECH_USA:
                return onSentToValidateRequest
            case SpareParRequestStatus.DELETED:
                return onDeleteSparePart
            case SpareParRequestStatus.CANCELLED:
                return onCancelRequest
            default:
                return null
        }
    }

    return (
        <>
            {
                (lateralPanelProviderConfig && lateralPanelProviderConfig.opened) &&
                <LateralPanel
                    onClose={openLateralPanelSendProvider}
                >
                    <ChangeProviderPanel
                        onCloseButtonClick={openLateralPanelSendProvider}
                        sparePart={(lateralPanelProviderConfig && lateralPanelProviderConfig.spartPart)}
                        onSavedClick={GetFunctionToInvoke}
                        isSaving={isSaving}
                    />

                </LateralPanel>
            }

            <Toaster
                toasterOptions={toaster}
                onClose={() => setToaster({ open: false })}
            />
        </>
    )
}

EditSpareProvider.propTypes = {
    /**Object have configuration for change status panel lateral */
    lateralPanelProviderConfig: propTypes.shape({
        opened: propTypes.bool,
        sparePart: propTypes.object
    }),
    /**Callback executed when user required change status the spare part requests and open lateral panel */
    openLateralPanelSendProvider: propTypes.func.isRequired,
}
EditSpareProvider.defaultProps = {
    lateralPanelProviderConfig:{
        opened:false,
        sparePart:{}
    },
    openLateralPanelSendProvider: () =>
        console.warn('Callback [openLateralPanelSendProvider] no defined'),
}

export default withImmutablePropsToJS(EditSpareProvider)
