
import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'

export const IS_LOADING_RETAILS = 'IS_LOADING_RETAILS'
export const SET_SALES_RETAILS = 'SET_SALES_RETAILS'
export const SET_ALL_RETAILS = 'SET_ALL_RETAILS'
export const UPDATE_RETAIL = 'UPDATE_RETAIL'

/**
 * Is loading action constructor
 */
export const isLoadingRetails = (status) => {
    return {
        type: IS_LOADING_RETAILS,
        status
    }
}

/**
 * Set Retails action creator
 */
export const setRetails = (retails) => {
    return {
        type: SET_SALES_RETAILS,
        retails
    }
}

/**
 * Set all retails
 */
export const setAllRetails = (retails) => {
    return {
        type: SET_ALL_RETAILS,
        retails
    }
}

export const setUpdateRetails = (retail) => {
    return {
        type: UPDATE_RETAIL,
        retail
    }
}

export const loadAllRetailss = () => {
    return function (dispatch, getState) {
        dispatch(isLoadingRetails(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/retails`
        return axios.get(url, config)
            .then(serverResponse => {
                dispatch(setRetails(serverResponse.data));
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isLoadingRetails(false));
                console.error("Error loading all reatials", error)
                return Promise.reject();
            });
    }
}

export const loadAllRetailsWithFilters = (queryParameters) => {
    return function (dispatch, getState) {
        dispatch(isLoadingRetails(true))
        // Get the authorized configuration
        let user = getState().oidc.user
        let config = getAuthorizedConfiguration(user)
        let url = `${window.config.serviceManagementBackendUrl}/retails/filters`
        let command = {
            PageNumber: queryParameters ? queryParameters.pageNumber : 1,
            PageSize: 50,
            OrderBy: queryParameters ? queryParameters.sortBy : null,
            OrderType: queryParameters ? queryParameters.sortDirection : 'desc',
            Query: !!queryParameters.query ? queryParameters.query : "",
        }
        return axios.post(url, command, config)
            .then(serverResponse => {
                dispatch(setAllRetails(serverResponse.data));
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch(isLoadingRetails(false));
                console.error("Error loading all reatails", error)
                return Promise.reject();
            });
    }
}