/**Import react section  */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

/**Import components section */
import IndexDesktopView from './components/IndexDesktopView'
import AttachmentDialog from './components/AttachmentDialog'
import Toaster from '../common/Toaster'

/**Import actions section */
import { addTerms, getTermsAndConditions, updateTerms } from '../../store/serviceproviders/serviceProvidersActions'
import { toSafeObject } from '../../store/helpers/StateHelper'

const Index = () => {
    /**Hooks sections */
    const dispatch = useDispatch()

    const [attanchmentDialog, setAttachmentDialog] = useState({ opened: false, Comments: "", Token: ""})
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const termsAndConditions = useSelector((state) => state.serviceProviders.get('termsAndConditions'))
    const isSaving = useSelector((state) => state.serviceProviders.get('isSaving'))
    const isLoading = useSelector((state) => state.serviceProviders.get('isLoadingProviders'))
    const userProfile = useSelector((state) => toSafeObject(state.session.get('profile')))

    /**
     * Load terms
     */
    useEffect(() => {
        dispatch(getTermsAndConditions())
    }, [dispatch])


    const onOpenAttanchemtDialog = (comments, token) => {
        setAttachmentDialog({ opened: !attanchmentDialog.opened, Comments: comments ? comments : "", Token: token ? token : "" })
    }

    const onAddTermsAndConditions = (pdfFile, evidenceModel) => {
        dispatch(addTerms(pdfFile, evidenceModel))
            .then(_ => {
                onOpenAttanchemtDialog()
                setToasterOptions({ open: true, message: 'File upload success', variant: 'success' })

            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to add termns and conditions', variant: 'warning' })
            });
    }

    const onUpdateTermsAndConditions = (pdfFile, evidenceModel) => {
        dispatch(updateTerms(pdfFile, evidenceModel, attanchmentDialog ? attanchmentDialog.Token : "", termsAndConditions.get("Review")))
            .then(_ => {
                onOpenAttanchemtDialog()
                setToasterOptions({ open: true, message: 'File upload success', variant: 'success' })

            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to update terms and conditions', variant: 'warning' })
            });
    }

    return (
        <>

            <IndexDesktopView
                onOpenAttanchemtDialog={onOpenAttanchemtDialog}
                termsAndConditions={termsAndConditions}
                isLoading={isLoading}
                isSaving={isSaving}
                userProfile={userProfile}
            />

            {
                attanchmentDialog && attanchmentDialog.opened &&< AttachmentDialog
                existTerms={termsAndConditions}
                title={termsAndConditions ? "UPDATE TERMS AND CONDITIONS" : "ADD TERMS AND CONDITIONS"}
                open={attanchmentDialog.opened}
                onSaveEvidence={termsAndConditions ? onUpdateTermsAndConditions : onAddTermsAndConditions}
                onClose={onOpenAttanchemtDialog}
                accept={".pdf"}
                isMultiple={false}
                Comments={attanchmentDialog.Comments ? attanchmentDialog.Comments : ""}
                isSaving={isSaving}

            />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }

        </>
    )
}

export default Index
