import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

/** Compont and material ui */
import {
	ButtonGroup,
	Button,
	Tooltip,
	TextField,
	Typography,
	IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import SendEmailIcon from '@material-ui/icons/Email'
import Datagrid from '../../common/apsys.datagrid.materialui/DataGrid'
import Select from '../../common/ReactSelectAvatar'
import ExistencesTable from './manual/ExistencesTable'
import existencesTableConfig from '../resources/ExistencesTableConfig'

import { GetWarehouse, GetCarriers } from '../../../store/helpers/SelectOptions'
import { hasAuthorization } from '../../../store/session/sessionActions'
/**Resources */
import {
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	SERVICE_PROVIDER_TECHNICIAN,
	SERVICE_PROVIDER_MANAGER,
	PORTAL_ADMINISTRATOR,
	IMBERA_TECHNICIAN,
	PAYMENT_MANAGER
} from '../../../resources/RolesEnum'
import { SpareParRequestStatus } from '../../../store/helpers/AppConstants'
import DateSelector from '../../common/DateSelector'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
/**
 * Component containing the list of spare parts requested for a equipment
 */
const EquipmentSparePartList = (props) => {
	const classes = useDataGridToolbarStyle()
	const {
		isAuthorizadoForEditedData,
		onUpdateSpartPart,
		userProfile,
		status,
		onEditLateralPanelConfig,
		onSendEmailToTechnical,
		isSparePartManual,
		IsPayment,
		identifierExpanded,
		setIdentifierExpanded
	} = props
	const { errors, control } = useForm()
	const warehouseOptions = GetWarehouse()
	const carriersOptions = GetCarriers()
	const isDownloadReport = useSelector((state) =>
		state.spareParts.getIn(['index', 'isDownloadReport'])
	)
	let isNotShowStatus = hasAuthorization(userProfile, [
		SERVICE_PROVIDER_TECHNICIAN,
		SERVICE_PROVIDER_MANAGER
	])
	let isAuthorizerCanEditRoleImberaMx = hasAuthorization(userProfile, [
		SPARE_PARTS_IMBERA_MX
	])

	let isAuthorizerCanEditRoleImberaUsa =
		hasAuthorization(userProfile, [SPARE_PARTS_IMBERA_USA]) &&
		(status === SpareParRequestStatus.IN_PROCESS ||
			status === SpareParRequestStatus.SEND_TO_MX)

	const updateSpartPart = (item, name, value, propertyToUpdate = '') => {
		item[name] = value
		if (onUpdateSpartPart) {
			onUpdateSpartPart(item, propertyToUpdate)
		}
	}

	let isAuthorizerCanBeGetExistencesFromSap = hasAuthorization(userProfile, [
		PORTAL_ADMINISTRATOR,
		IMBERA_TECHNICIAN,
		SPARE_PARTS_IMBERA_MX,
		PAYMENT_MANAGER
	])

	const onRenderButtonCollapse = (item) => {
		let index = identifierExpanded.includes(item.Code)
		return index
	}

	const onExpandedPanelClick = (item) => {
		let index = identifierExpanded.includes(item.Code)
		if (index) {
			let newList = identifierExpanded.filter((identifier) => {
				return identifier !== item.Code
			})
			setIdentifierExpanded(newList)
		} else {
			let identifier = item.Code
			setIdentifierExpanded(identifierExpanded.concat(identifier))
		}
	}

	/**
	 * Get the configuration table
	 */
	const getTableConfiguration = () => {
		let enhacedTableConfig = [...tableConfig]
		if (isAuthorizadoForEditedData) {
			enhacedTableConfig.push({
				title: 'Options',
				dataSource: 'options',
				onRenderProperty: (item) => {
					return (
						<ButtonGroup variant='text' size='small'>
							{isAuthorizadoForEditedData && (
								<Tooltip
									component='span'
									title='Remove Spare Part'
								>
									<Button
										onClick={() =>
											props.onRemoveSparePart(item)
										}
										disabled={
											props.isSaving &&
											item.Token ===
											props.identifierSparePartDelete
										}
									>
										<DeleteIcon color='action' />
									</Button>
								</Tooltip>
							)}
						</ButtonGroup>
					)
				}
			})
		}
		let descriptionProperty = enhacedTableConfig.find(
			(x) => x.dataSource === 'Description'
		)
		descriptionProperty.onRenderProperty = (item) => {
			return (
				<Typography style={{ width: '140px' }} variant='caption'>
					{`${item.Code} | ${item.Description}`} |{' '}
					{
						<strong style={{ color: 'red' }}>
							Quantity {item.Quantity}
						</strong>
					}
				</Typography>
			)
		}
		let warehouseProperty = enhacedTableConfig.find(
			(x) => x.dataSource === 'Warehouse'
		)
		warehouseProperty.onRenderProperty = (item) => {
			return (
				<div style={{ width: '90px' }}>
					<Select
						key={'Warehouse'}
						fullWidth
						defaultValue={item.Warehouse}
						options={warehouseOptions}
						name='Warehouse'
						isDisabled={!isAuthorizerCanEditRoleImberaUsa}
						value={
							item.Warehouse
								? warehouseOptions.find(
									(x) => x.value === item.Warehouse
								)
								: null
						}
						onChange={(name, option) =>
							updateSpartPart(
								item,
								'Warehouse',
								option.value,
								'WAREHOUSE'
							)
						}
						margin='dense'
						register={null}
						errors={errors}
						control={control}
					/>
				</div>
			)
		}
		if (
			status !== SpareParRequestStatus.DRAFT &&
			status !== SpareParRequestStatus.REJECTED_RESEND
		) {
			enhacedTableConfig.push({
				title: 'Status',
				dataSource: 'Status',
				onRenderProperty: (item) => {
					return (
						<Typography style={{ width: '50px' }} variant='caption'>
							{item.Status}
						</Typography>
					)
				}
			})
		}

		if (
			status !== SpareParRequestStatus.DRAFT &&
			status !== SpareParRequestStatus.REJECTED_RESEND &&
			!isNotShowStatus &&
			!IsPayment
		) {
			enhacedTableConfig.push({
				title: '',
				dataSource: 'Options',
				onRenderProperty: (item) => {
					return (
						<ButtonGroup variant='text' size='small'>
							<Tooltip component='span' title='Options'>
								<Button
									onClick={() =>
										onEditLateralPanelConfig(item)
									}
								>
									<MoreVertIcon color='primary' />
								</Button>
							</Tooltip>
							{item.Carrier &&
								item.NumberTracking &&
								item.ShipDate &&
								!IsPayment && (
									<Tooltip
										component='span'
										title='Send email'
									>
										<Button
											onClick={() =>
												onSendEmailToTechnical(
													item.Token
												)
											}
											disabled={
												props.isSaving ||
												item.Token ===
												props.identifierSparePartDelete
											}
										>
											<SendEmailIcon color='action' />
										</Button>
									</Tooltip>
								)}
						</ButtonGroup>
					)
				}
			})
		}

		let referenceSAPProperty = enhacedTableConfig.find(
			(x) => x.dataSource === 'ReferenceSAP'
		)
		referenceSAPProperty.onRenderProperty = (item) => {
			let editCanbe =
				(isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) ||
				(isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<TextField
					style={{ width: '100px' }}
					key={'referenceSAP'}
					fullWidth
					disabled={!editCanbe}
					defaultValue={item.ReferenceSAP}
					onBlur={(event) =>
						updateSpartPart(
							item,
							'ReferenceSAP',
							event.target.value
						)
					}
					margin='dense'
					variant='outlined'
					inputProps={{
						style: { fontSize: 12 }
					}}
				/>
			)
		}

		let numberTrakingProperty = enhacedTableConfig.find(
			(x) => x.dataSource === 'NumberTracking'
		)
		numberTrakingProperty.onRenderProperty = (item) => {
			let editCanbe =
				(isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) ||
				(isAuthorizerCanEditRoleImberaUsa)//&& item.Warehouse === 2)
			return (
				<TextField
					style={{ width: '160px' }}
					key={'numberTraking'}
					fullWidth
					disabled={!editCanbe}
					defaultValue={item.NumberTracking}
					onBlur={(event) =>
						updateSpartPart(
							item,
							'NumberTracking',
							event.target.value
						)
					}
					margin='dense'
					variant='outlined'
					inputProps={{
						style: { fontSize: 12 }
					}}
				/>
			)
		}

		let shipDateProperty = enhacedTableConfig.find(
			(x) => x.dataSource === 'ShipDate'
		)
		shipDateProperty.onRenderProperty = (item) => {
			let editCanbe =
				(isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) ||
				(isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<div style={{ width: '100px' }}>
					<DateSelector
						showIncon={true}
						dataCy='date'
						name='shipDate'
						disabled={editCanbe}
						value={item.ShipDate}
						onChange={(event) =>
							updateSpartPart(
								item,
								'ShipDate',
								event.target.value
							)
						}
					/>
				</div>
			)
		}

		let carrierProperty = enhacedTableConfig.find(
			(x) => x.dataSource === 'Carrier'
		)
		carrierProperty.onRenderProperty = (item) => {
			let editCanbe =
				(isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) ||
				(isAuthorizerCanEditRoleImberaUsa)//&& item.Warehouse === 2)
			return (
				<div style={{ width: '120px' }}>
					<Select
						key={'Carrier'}
						fullWidth
						defaultValue={item.Carrier}
						options={carriersOptions}
						name='Carrier'
						isDisabled={!editCanbe}
						value={
							item.Carrier
								? carriersOptions.find(
									(x) => x.label === item.Carrier
								)
								: null
						}
						onChange={(name, option) =>
							updateSpartPart(item, 'Carrier', option.label)
						}
						margin='dense'
						register={null}
						errors={errors}
						control={control}
					/>
					{item.IsOtherCarrier && (
						<TextField
							key={'otherCarrier'}
							fullWidth
							disabled={!editCanbe}
							defaultValue={item.OtherCarrier}
							onBlur={(event) =>
								updateSpartPart(
									item,
									'OtherCarrier',
									event.target.value
								)
							}
							margin='dense'
							variant='outlined'
							inputProps={{
								style: { fontSize: 12 }
							}}
						/>
					)}
				</div>
			)
		}
		if (isAuthorizerCanBeGetExistencesFromSap) {
			enhacedTableConfig.push({
				title: '',
				dataSource: 'Expanded',
				onRenderProperty: (item) => {
					let changeButton = onRenderButtonCollapse(item)
					return (
						<IconButton
							size='small'
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => onExpandedPanelClick(item)}
						>
							{changeButton ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</IconButton>
					)
				}
			})
		}

		return enhacedTableConfig
	}

	const expandedPanelComponent = (item) => {
		return (
			<ExistencesTable
				columnSpam={existencesTableConfig.length + 3}
				item={item}
				identifierPanel={identifierExpanded}
				isSaving={isDownloadReport}
				updateType={'opened'}
			/>
		)
	}

	return (
		<Fragment>
			<ButtonGroup size='small' className={classes.buttonsGroupContainer}>
				{isAuthorizadoForEditedData && !isSparePartManual && (
					<Button
						onClick={() =>
							props.onAddSparePartRequest(props.equipment)
						}
						variant='outlined'
						className={classes.buttonsGroup}
						color='primary'
						disabled={props.isLoadingFromSAP}
					>
						Add spare parts sap
					</Button>
				)}
			</ButtonGroup>
			<Datagrid
				headers={getTableConfiguration()}
				data={props.equipment.spareParts}
				isExpandedPanel={true}
				expandedPanelComponent={expandedPanelComponent}
			/>
		</Fragment>
	)
}
EquipmentSparePartList.propTypes = {
	equipment: propTypes.object,
	/**
	 * Callback executed when the user request to add a new spare part
	 */
	onAddSparePartRequest: propTypes.func,
	/**
	 * Callback executed when removing a spare part
	 */
	onRemoveSparePart: propTypes.func,
	/**
	 * Callback executed when click en edit comments
	 */
	onEditLateralPanelConfig: propTypes.func.isRequired
}

EquipmentSparePartList.propTypes = {}
export default EquipmentSparePartList

const tableConfig = [
	{
		title: 'Part number',
		dataSource: 'Description'
	},
	{
		title: 'Warehouse',
		dataSource: 'Warehouse'
	},
	{
		title: 'Reference',
		dataSource: 'ReferenceSAP'
	},
	{
		title: 'Tracking number',
		dataSource: 'NumberTracking'
	},
	{
		title: 'Carrier',
		dataSource: 'Carrier'
	},
	{
		title: 'ETA',
		dataSource: 'ShipDate'
	}
]
