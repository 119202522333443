export const getDatagridConfiguration = () => {
	let config = [...dataGridConfiguration]
	return config
}
export const dataGridConfiguration = [
	{
		title: 'Folio',
		dataSource: 'Folio',
		sortable: true
	},
	{
		title: 'Creation Date',
		dataSource: 'CreationDate',
		sortable: true,
		onRenderProperty: (item) => {
			return item.CreationDate.format('MM/DD/YYYY')
		}
	},
	{
		title: 'Assigned Date MX',
		dataSource: 'AssignedDate',
		sortable: true,
		onRenderProperty: (item) => {
			return item.AssignedDate !== null ? item.AssignedDate : ''
		}
	},
	{
		title: 'Status',
		onRenderProperty: (item) => {
			return getStatusDescription(item.Status)
		}
	},
	{
		title: 'Created By',
		onRenderProperty: (item) => {
			return item.CreatorUser ? item.CreatorUser.Name : ''
		}
	},
	{
		title: 'Service Order',
		onRenderProperty: (item) => {
			return item.IsManual ? 'Manual' : item.Ticket
		}
	},
	{
		title: 'Service Provider',
		dataSource: 'ServiceProviderName',
		onRenderProperty: (item) => {
			return item.ServiceProviderName === '' ? 'Unassigned' : item.ServiceProviderName
		}
	},
	{
		title: 'Serial Number',
		onRenderProperty: (item) => {
			return item.Serie
		}
	},
	{
		title: 'Part Number',
		onRenderProperty: (item) => {
			return `${item.Code}`
		}
	},
	{
		title: 'Warehouse',
		onRenderProperty: (item) => {
			return getWarehouseDescription(item.Warehouse)
		}
	},
	{
		title: 'Tracking Number',
		onRenderProperty: (item) => {
			return item.TrackingNumber
		}
	},
	{
		title: 'Tracking Status',
		onRenderProperty: (item) => {
			return item.StatusTracking
		}
	},
	{
		title: 'Carrier',
		onRenderProperty: (item) => {
			return item.Carrier
		}
	},
	{
		title: 'Network days',
		dataSource: 'DayDifference',
		sortable: true,
		onRenderProperty: (item) => {
			return item.DayDifference
		}
	}
]

export const getStatusDescription = (statusId) => {
	switch (statusId) {
		case 1:
			return 'Draft'
		case 2:
			return 'Submitted'
		case 3:
			return 'Send Imbera MX'
		case 4:
			return 'Closed'
		case 6:
			return 'Cancelled'
		case 7:
			return 'Send to Validate'
		case 8:
			return 'Rejected in plant'
		default:
			return 'Undefined'
	}
}

export const getWarehouseDescription = (warehouseId) => {
	switch (warehouseId) {
		case 1:
			return 'Mexico'
		case 2:
			return 'USA'
		default:
			return ''
	}
}
