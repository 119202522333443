import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { regionsStyles } from '../../../resources/styles/RegionsStyles'
import ToolbarSkeleton from '../services/componets/ToolbarSkeleton'
const DesktopViewSkeleton =(props) => {
    const classes = regionsStyles();
    return (
        <div className={classes.root}>
             <ToolbarSkeleton />
             <Skeleton variant="rect" animation='wave' width={"100%"} height={`calc(100vh - ${125}px)`} />
        </div>
    )
}

export default DesktopViewSkeleton