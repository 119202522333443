/** Import ReactJS */
import React from 'react';
import PropTypes from 'prop-types';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles'
import {
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { PositionPlacement } from '../../store/helpers/AppConstants';
const useDefaultStyles = makeStyles((theme) => ({
    cssLabel: {
    },
    cssFocused: {
        color: 'black !important'
    },
    cssOutlinedInput: {
        color: 'black !important',
    },
    notchedOutline: {
        borderColor: 'red !important',
        borderRadius: 8
    },
    notchedOutlineSuccess: {
        borderWidth: '1px',
        borderColor: `${theme.palette.colors.purple}`,
        color: '#545CD8 !important',
        borderRadius: 8
    },
    input: {
        "&.Mui-disabled": {
            color: "black",
            opacity: 1,
        },
    },

}));

const TextBox = props => {
    const {
        dataCy,
        active,
        inactiveTitlePosition,
        autoFocus,
        autoComplete,
        label,
        value,
        name,
        fullWidth,
        margin,
        disabled,
        type,
        multiline,
        rowsMax,
        rows,
        required,
        register,
        errors,
        defaultValue,
    } = props;
    const classes = useDefaultStyles();

    const onChangeHandler = event => {
        if (props.onChange) {
            props.onChange(event);
        }
        else {
            console.warn("onChange callback not found.")
        }
    }

    const onBlurHandler = (value) => {
        if (props.onBlur) {
            props.onBlur(value)
        }
    }

    const onKeyDownHandler = event => {
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
        else {
            console.warn("onKeyDownHandler callback not found.")
        }
    }


    return (
        {
            true: <><TextField
                data-cy={dataCy}
                autoFocus={autoFocus || false}
                autoComplete={autoComplete}
                label={label}
                value={value || ""}
                name={name}
                required={required}
                className={classes.textField}
                inputRef={register ? register : null}
                fullWidth={fullWidth}
                margin={margin}
                variant="outlined"
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                onKeyDown={onKeyDownHandler}
                type={type}
                multiline={multiline}
                disabled={disabled}
                maxRows={rowsMax}
                minRows={rows}
                defaultValue={defaultValue}
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: (!errors?.[name]) ? classes.notchedOutlineSuccess : classes.notchedOutline,

                    },
                    inputMode: "text",
                    className: classes.input,
                }}
            />
                {errors?.[name] && <Typography style={{ fontSize: '0.75rem' }} noWrap color='error'>{`${errors[name]?.message}`}</Typography>}
            </>,
            false: <div>
                {inactiveTitlePosition === PositionPlacement.TOP && <Typography variant="subtitle1">{label}</Typography>}
                <Typography variant="h6" >{value}</Typography>
                {inactiveTitlePosition === PositionPlacement.BOTTOM && <Typography variant="subtitle1">{label}</Typography>}
            </div>
        }[active]
    );
}

TextBox.propTypes = {
    active: PropTypes.bool,
    inactiveTitlePosition: PropTypes.oneOf([
        PositionPlacement.TOP,
        PositionPlacement.BOTTOM,
    ]),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    // required: PropTypes.bool,
    label: PropTypes.node,
    name: PropTypes.string,
    margin: PropTypes.string,
    rowsMax: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    helperText: PropTypes.node,
    type: PropTypes.string,
}

TextBox.defaultProps = {
    active: false,
    inactiveTitlePosition: PositionPlacement.BOTTOM,
    disabled: false,
    multiline: false,
    required: false,
    isSuccess: false,
    margin: 'normal',
}


const useWhiteStyles = makeStyles({
    cssLabel: {
        color: 'white !important'
    },
    cssOutlinedInput: {
        color: 'white !important'
    },
    cssFocused: {
        color: 'white !important'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'white !important',
        color: 'white !important',
        borderRadius: 8
    }
});

export const WhiteTextBox = props => {
    const {
        active,
        autoFocus,
        label,
        value,
        name,
        helperText,
        fullWidth,
        margin,
        disabled,
        type,
        multiline,
        autoComplete,
        defaultValue
    } = props;
    const classes = useWhiteStyles();

    const onChangeHandler = event => {
        if (props.onChange) {
            props.onChange(event);
        }
    }

    const onBlurHandler = (value) => {
        if (props.onBlur) {
            props.onBlur(value)
        }
    }

    return ({
        true: <TextField
            id={name}
            autoFocus={autoFocus || false}
            label={label}
            value={value}
            defaultValue={defaultValue}
            name={name}
            helperText={helperText || ""}
            fullWidth={fullWidth}
            margin={margin}
            variant="outlined"
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            type={type}
            multiline={multiline}
            disabled={disabled}
            autoComplete={autoComplete}
            InputLabelProps={{
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                },
            }}
            InputProps={{
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                },
                inputMode: "text",
                endAdornment: name === "CodeSap" ? (<InputAdornment position="start">$</InputAdornment>) : ("")
            }}
        />,
        false: <div>
            <Typography style={{ color: "white" }} variant="subtitle1" >{value}</Typography>
            <Typography style={{ color: "white" }} variant="subtitle2">{label}</Typography>
        </div>
    }[active]
    );
}

export default (TextBox);