/**Import react section */
import React, { useEffect, useState, cloneElement } from 'react'
import propTypes from 'prop-types'
/**Import Material UI section */
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { Typography, TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
const ExpandedPanelComponent = (props) => {
	const {
		columnSpam,
		isSaving,
		onPropertyChange,
		item,
		identifierPanel,
		isAuthorizadoForEditedData,
		modeView
	} = props
	const [comments, setComments] = useState(item ? item.Comments : '')

	useEffect(() => {
		if (item) {
			setComments(item.Comments)
		}
	}, [item])

	const includesData = () => {
		let index = identifierPanel.includes(modeView ? item.Id : item.Token)
		return index
	}

	const onUpdateData = (event) => {
		if (
			onPropertyChange &&
			(event.key === 'Enter' || event.key === 'Tab')
		) {
			setComments(event.target.value)
			onPropertyChange(
				item.Token,
				item.RefrigerationNotCoolFailure,
				item.IluminationFailure,
				item.DoorFailure,
				event.target.value,
				item.FaultWarranty,
				item.CompletionDate
			)
		}
	}

	const updateData = (event) => {
		onPropertyChange(
			item.Token,
			item.RefrigerationNotCoolFailure,
			item.IluminationFailure,
			item.DoorFailure,
			comments,
			item.FaultWarranty,
			item.CompletionDate
		)
	}

	return (
		<>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={columnSpam}
				>
					<Collapse
						in={includesData(item)}
						timeout='auto'
						unmountOnExit
					>
						<Box margin={1}>
							{item.TicketServicesIncludes && (
								<Chip
									className={props.ticketServicesChipStyle}
									label={
										'Included in these service tickets: ' +
										item.TicketServicesIncludes
									}
								/>
							)}
							<Box>
								{item.DestinatarionSerie && (
									<Typography variant='subtitle1'>
										<strong>{`Serie: `}</strong>
										{` ${item.Serie} `} |{' '}
										<strong>{`Model: `}</strong>
										{` ${item.Model} `}
										<br />
										<strong>{`Description: `}</strong>
										{` ${item.DescriptionSerie}`}
										<br />
										<strong>{`Customer: `}</strong>{' '}
										{` ${item.CustomerSerie} `}
										<br />
										<strong>{`Destination: `}</strong>
										{` ${item.DestinatarionSerie}`}
									</Typography>
								)}
							</Box>
						</Box>
						<Box margin={1}>
							{props.content ? (
								cloneElement(props.content, { equipment: item })
							) : (
								<>
									<TextBox
										active={true}
										disabled={
											isSaving ||
											isAuthorizadoForEditedData
										}
										fullWidth
										autoFocus
										multiline
										rows={4}
										name='Comments'
										variant='outlined'
										margin='dense'
										label='Comments'
										onKeyDown={onUpdateData}
										onChange={(event) =>
											setComments(event.target.value)
										}
										value={comments}
									/>
									<ButtonSaved
										onSavedClick={updateData}
										title='Update'
										isSaving={
											isSaving ||
											isAuthorizadoForEditedData
										}
									/>
								</>
							)}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

ExpandedPanelComponent.propTypes = {
	/**Determine the number of columns to fit the content */
	columnSpam: propTypes.number,
	/**Determinate if expanded panel is open */
	/**Determinate if is saving information in the database */
	isSaving: propTypes.bool.isRequired,
	/**It is the element that contains the row */
	item: propTypes.object.isRequired,
	/**It is an element that identifies the row to determine which expanded panel is open */
	identifierPanel: propTypes.array.isRequired,
	/**Saved data in the database */
	onPropertyChange: propTypes.func.isRequired,
	/**Determinate if user canbe edited data */
	isAuthorizadoForEditedData: propTypes.bool.isRequired,
	/**
	 * If this property is defined, then overrides the default panel content for this node
	 */
	content: propTypes.node
}
ExpandedPanelComponent.defaultProps = {
	columnSpam: 0,
	isSaving: false,
	isAuthorizadoForEditedData: false,
	item: null,
	identifierPanel: '',
	content: null,
	onPropertyChange: () =>
		console.warn('Callback [onPropertyChange] no defined')
}

export default ExpandedPanelComponent
