import { UserTypes, RoleType } from './AppConstants'
import {
	PORTAL_ADMINISTRATOR,
	IMBERA_TECHNICIAN,
	DISPATCHER,
	PAYMENT_MANAGER,
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	SALES_MANAGER,
	SALES_REPRESENTATIVE_MANAGER,
	PROVIDER_REGISTRATION_VALIDATOR,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	VISUALIZER
} from '../../resources/RolesEnum'

/**
 * Method to get all available roles by user type
 */
export const getAvailableRolesByUserType = (type, roles) => {
	let allRolesFiltered = []
	if (type === undefined && roles) {
		let rolesNames = roles.map((r) => r.ClaimValue)
		allRoles.forEach((rolesDefined) => {
			if (rolesNames.includes(rolesDefined.key)) allRolesFiltered.push(rolesDefined)
		})
	} else {
		allRolesFiltered = allRoles.filter((role) => role.type === type)
	}
	return allRolesFiltered
}

export const allRoles = [
	{
		key: `${PORTAL_ADMINISTRATOR}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'ADMINISTRATOR PORTAL',
		description: 'portalAdministratorDescription'
	},
	{
		key: `${IMBERA_TECHNICIAN}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'IMBERA TECHNICIAN',
		description: 'Imbera technician'
	},
	{
		key: `${DISPATCHER}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'DISPATCHER',
		description: 'Imbera dispatcher'
	},
	{
		key: `${SPARE_PARTS_IMBERA_USA}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'IMBERA SPARE PART USA',
		description: 'Imbera spare part USA'
	},
	{
		key: `${SPARE_PARTS_IMBERA_MX}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'IMBERA SPARE PART MX',
		description: 'Imbera spare part MX'
	},
	{
		key: `${PROVIDER_REGISTRATION_VALIDATOR}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'DOCUMENT AND PAYMENT VALIDATOR',
		description: 'Valid supplier documents and payment requests'
	},
	{
		key: `${PAYMENT_MANAGER}`,
		userType: UserTypes.ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'CHECK PAYMENT MANAGER',
		description: 'Check payment manager'
	},
	{
		key: `${SERVICE_PROVIDER_MANAGER}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.SERVICE_PROVIDER,
		label: 'SERVICE PROVIDER MANAGER',
		description: 'Service provider manager'
	},
	{
		key: `${SERVICE_PROVIDER_TECHNICIAN}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.SERVICE_PROVIDER,
		label: 'SERVICE PROVIDER TECHNICIAN',
		description: 'Servoce provider technician'
	},
	{
		key: `${SALES_REPRESENTATIVE_MANAGER}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.SALES_REPRESENTATIVE,
		label: 'SALES REPRESENTATIVE MANAGER',
		description: 'Sales representative manager'
	},
	{
		key: `${SALES_MANAGER}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.SALES_REPRESENTATIVE,
		label: 'SALES REPRESENTATIVE',
		description: 'Sales representative'
	},
	{
		key: `${CUSTOMER_MANAGER}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.CUSTOMER,
		label: 'CUSTOMER MANAGER',
		description: 'Customer manager'
	},
	{
		key: `${CUSTOMER_TECHNICIAN}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.CUSTOMER,
		label: 'CUSTOMER TECHNICIAN',
		description: 'Customer technician'
	},
	{
		key: `${VISUALIZER}`,
		userType: UserTypes.NON_ACTIVE_DIRECTORY,
		type: RoleType.USER_IMBERA,
		label: 'VISUALIZER',
		description: 'Platform viewer user'
	}
]
