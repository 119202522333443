import { createSelector } from 'reselect'
import { toSafeObject, toSafeArray } from '../../store/helpers/StateHelper'
import { sortBy } from '../../store/helpers/SortingHelper'

/**
 * Create a selector for user in sales representative sort criteria
 */
export const queryParametersSelector = createSelector(
	(state) => state.salesRepresentatives.get('internalQueryParameters'),
	(internalQueryParameters) => toSafeObject(internalQueryParameters)
)

/**
 * Create a selector for the filtered and sorted users in providers
 */
export const representativesUsersSelector = createSelector(
	(state) => state.salesRepresentatives.get('representativeUsers'),
	(state) => state.salesRepresentatives.get('internalQueryParameters'),
	(internalUsers, queryParameters) => {

		let queryObject = toSafeObject(queryParameters)
		const getFilteredUsers = (users) => {
			if (queryObject && queryObject.query) { 
				let fixedQuery = queryObject.query.trim().toLowerCase()
				return users.filter((x) => {
					return x.UserName.trim().toLowerCase().includes(fixedQuery) ||
					x.Name.trim().toLowerCase().includes(fixedQuery)
				})	
			} else 
				return users
		}

		const getSortedUsers = (users) => {
			if (queryObject && queryObject.sortBy) {
				return sortBy(users, queryObject.sortBy, queryObject.sortDirection)
			} else return users
		}

		let result = toSafeArray(internalUsers)
		result = getFilteredUsers(result)
		result = getSortedUsers(result)		
		return result
	}
)

