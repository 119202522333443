/**Import react section */
import React, { useState } from 'react'
/**Import common componets */
import TextBox from './../../../common/TextBox'
import ButtonConfirm from './../../../common/Buttons/ButtonConfirm'
/**Import MaterialUi section */
import { Grid } from '@material-ui/core'
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'

const CancelledService = (props) => {
    const { onSetPasswordDialogConfiguration, IsClosed, status } = props;
    const classes = createServicesStyle();
    const [cancelledModel, setCancelledModel] = useState({ comments: '', status: props.status });

    const openModalForCancelledSeervice = () => {
        if (onSetPasswordDialogConfiguration)
            onSetPasswordDialogConfiguration(cancelledModel, status)
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setCancelledModel({
            ...cancelledModel,
            [name]: value
        });

    }

    /**Verify if can be sabed */
    const canBeSaved = () => {
        if (cancelledModel ? (cancelledModel.comments.length <= 0) : true) return false;
        return true;
    }
    return (
        <div>
            <Grid item xs={12}>
                <TextBox
                    active={true}
                    fullWidth
                    autoFocus
                    disabled={IsClosed}
                    name="comments"
                    variant="outlined"
                    margin="dense"
                    label="REASON FOR CANCELLATION"
                    multiline
                    rows={4}
                    onChange={onPropertyChange}
                    value={cancelledModel ? cancelledModel.comments : ""}
                />
            </Grid>
            {
                !IsClosed && <Grid item xs={12} className={classes.updateButton}>
                    <ButtonConfirm
                        title="CONFIRM"
                        isSaving={!canBeSaved()}
                        onSavedClick={openModalForCancelledSeervice}
                    />
                </Grid>
            }
        </div>
    )
}

export default CancelledService
