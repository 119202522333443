/**Import react section */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import AvatarComponent from '../../common/AvatarComponent';
import { WhiteTextBox } from '../../common/TextBox'

/**Import resources section */
import { salesRepresentativeStyles } from '../../../resources/styles/SalesRepresentativeStyles'
const EditRetailer = (props) => {
    const classes = salesRepresentativeStyles();

    const { onSaveButtonClick, onCloseButtonClick, isSaving, onSetLogoFileBase64, onSetHandleOpenCloseModalUploadImg } = props;
    const [representativeModel, setRepresentativeModel] = useState(props.customer || defaultEditRetailer);

    useEffect(() => {
        setRepresentativeModel(props.customer)
    }, [props.customer])


    const onPropertyChange = event => {
        let { name, value } = event.target;
        setRepresentativeModel({
            ...representativeModel,
            [name]: value
        })
    }

    const canBeSaved = () => {
        if (representativeModel.Name.length <= 0) return false;
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(representativeModel)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Edit sales representative"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={canBeSaved()}
                onSaveButtonClick={onSave}
                isSaving={isSaving}
            />
            <div>
                <div className={classes.avatar}>
                    <AvatarComponent
                        name={representativeModel.Name ? representativeModel.Name : ""}
                        onSetLogoFileBase64={onSetLogoFileBase64}
                        onSetHandleOpenModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                        avatarUrl={representativeModel.AvatarUrl ? representativeModel.AvatarUrl : ""}
                        isAvatarFromIdentity={false}
                    />
                </div>
                <div className={classes.texboxContainer}>
                    <WhiteTextBox
                        active={true}
                        fullWidth
                        autoFocus
                        name="Name"
                        variant="outlined"
                        margin="dense"
                        label="Name"
                        onChange={onPropertyChange}
                        value={representativeModel ? representativeModel.Name : ""}
                    />
                </div>
                <div className={classes.texboxContainer}>
                    <WhiteTextBox
                        active={true}
                        fullWidth
                        autoFocus
                        name="CodeSap"
                        variant="outlined"
                        margin="dense"
                        label="SAP code to get price list"
                        onChange={onPropertyChange}
                        value={representativeModel ? representativeModel.CodeSap : ""}
                    />
                </div>
            </div>
        </LateralPanel>
    )
}

const defaultEditRetailer = {
    Code: '',
    Name: '',
}

EditRetailer.propTypes = {
    customer: propTypes.shape({
        Name: propTypes.string.isRequired,
        Code: propTypes.string.isRequired,
    })
}

export default EditRetailer
