/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

/**Import components section */
import LateralPanel from '../../common/lateralPanel/LateralPanel'
import PanelHeader from '../../common/lateralPanel/PanelHeader'
import AvatarComponent from '../../common/AvatarComponent'
import { WhiteTextBox } from '../../common/TextBox'
import { Typography } from '@material-ui/core'
import { TaxClassCheckbox } from '../TaxClassCheckbox'
import { ServiceTypeSelector } from './ServiceTypeSelector'
import AddressForm from '../../common/AddressForm'

/**Import resources section */
import { salesRepresentativeStyles } from '../../../resources/styles/SalesRepresentativeStyles'
import { isNullOrEmpty } from '../../../store/helpers/StringHelper'

const EditLateralPanel = (props) => {
	const { isSaving } = props
	const classes = salesRepresentativeStyles()

	const { onSaveButtonClick, onCloseButtonClick, onSetLogoFileBase64, onSetHandleOpenCloseModalUploadImg } = props
	const [serviceProviderModel, setServiceProviderModel] = useState(props.serviceProvider || defaultEditLateralPanel)

	useEffect(() => {
		setServiceProviderModel(props.serviceProvider)
	}, [props.serviceProvider])

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setServiceProviderModel({
			...serviceProviderModel,
			[name]: value
		})
	}

	/*On change service type property */
	const onSelectOption = (event) => {
		let { label, value } = event
		setServiceProviderModel({
			...serviceProviderModel,
			ServiceType: { value, label }
		})
	}

	/**Determines if changes can be saved */
	const canBeSaved = () => {
		if (serviceProviderModel.Name.length <= 0) return false
		if (isNullOrEmpty(serviceProviderModel.BussinessName)) return false
		if (isNullOrEmpty(serviceProviderModel.Tin)) return false
		if (isNullOrEmpty(serviceProviderModel.TaxClass)) return false
		if (isNullOrEmpty(serviceProviderModel.CodeSap)) return false
		if (serviceProviderModel.ServiceType && serviceProviderModel.ServiceType.value === 'Warranty' && isNullOrEmpty(serviceProviderModel.ServiceEmail)) return false
		return true
	}

	const onSave = () => {
		if (props.onSaveButtonClick) {
			onSaveButtonClick(serviceProviderModel)
		}
	}

	console.log('Service Provider Model', serviceProviderModel)

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				title={'Edit service provider'}
				onCloseButtonClick={onCloseButtonClick}
				isModeSaved={true}
				canBeSaved={canBeSaved()}
				onSaveButtonClick={onSave}
				isSaving={isSaving}
			/>
			<div className={classes.lateralPanelForm}>
				<div className={classes.avatar}>
					<AvatarComponent
						name={serviceProviderModel.name ? serviceProviderModel.name : ''}
						isAvatarFromIdentity={false}
						avatarUrl={serviceProviderModel.AvatarUrl ? serviceProviderModel.AvatarUrl : ''}
						onSetLogoFileBase64={onSetLogoFileBase64}
						onSetHandleOpenModalUploadImg={onSetHandleOpenCloseModalUploadImg}
					/>
				</div>
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						autoFocus
						name='Name'
						variant='outlined'
						margin='dense'
						label='Name (as shown on your income tax return)'
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.Name : ''}
					/>
				</div>
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						name='BussinessName'
						variant='outlined'
						margin='dense'
						label='Business name.'
						helperText={
							<Typography variant='caption text' style={{ color: 'white' }}>
								Disregarded entity name, if different from above
							</Typography>
						}
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.BussinessName : ''}
					/>
				</div>
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						name='ISPName'
						variant='outlined'
						margin='dense'
						label='ISP Name'
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.ISPName : ''}
					/>
				</div>
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						name='Tin'
						variant='outlined'
						margin='dense'
						label='EIN (Employer Identification Number)'
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.Tin : ''}
					/>
				</div>
				<TaxClassCheckbox taxClassValue={serviceProviderModel ? serviceProviderModel.TaxClass : ''} onPropertyChange={onPropertyChange} />
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						name='CodeSap'
						variant='outlined'
						margin='dense'
						label='SAP code to get price list'
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.CodeSap : ''}
					/>
				</div>
				<ServiceTypeSelector
					onPropertyChange={onSelectOption}
					serviceType={serviceProviderModel ? serviceProviderModel.ServiceType : ''}
					placeHolder={'Service Type'}
				/>
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						name='ServiceEmail'
						variant='outlined'
						margin='dense'
						label='Service email'
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.ServiceEmail : ''}
					/>
				</div>
				<div className={classes.texboxContainer}>
					<WhiteTextBox
						active={true}
						fullWidth
						name='ClosingPhone'
						variant='outlined'
						margin='dense'
						label='Phone for closing'
						onChange={onPropertyChange}
						value={serviceProviderModel ? serviceProviderModel.PhoneForClosing : ''}
					/>
				</div>
				<div className={classes.AddressContainer}>
					<Typography style={{ color: 'white' }} variant='subtitle1'>
						Mailing Address
					</Typography>
				</div>
				<AddressForm onPropertyChange={onPropertyChange} addressInfo={serviceProviderModel.MailAddress} prefix={'Mail'} />
				<div className={classes.AddressContainer}>
					<Typography style={{ color: 'white' }} variant='subtitle1'>
						Secondary Address / Remit to Address
					</Typography>
				</div>
				<AddressForm onPropertyChange={onPropertyChange} addressInfo={serviceProviderModel.SecondaryAddress} prefix={'Second'} />
			</div>
		</LateralPanel>
	)
}

const defaultEditLateralPanel = {
	Code: '',
	Name: ''
}

EditLateralPanel.propTypes = {
	/**Service provider to edit */
	serviceProvider: propTypes.shape({
		Name: propTypes.string.isRequired,
		Code: propTypes.string.isRequired
	}),
	/**
	 * Determine if is saving
	 */
	isSaving: propTypes.bool,
	/**
	 * Function saved data
	 */
	onSaveButtonClick: propTypes.func.isRequired
}

export default EditLateralPanel
