import React from 'react'
import propTypes from 'prop-types'

import AttachIcon from '@material-ui/icons/AttachFile'
import CloseIcon from '@material-ui/icons/Cancel'

/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useStyles'
import DropDownZone from '../../common/DropDownZone'

/**Import styles section */
import { Alert } from '@material-ui/lab'
/**Import Material Ui section */
import { Typography, Chip, Divider, Grid } from '@material-ui/core'

const EvidenceComponent = (props) => {
	const classes = useCreateDesktopStyle()
	const { onAddFiles, onRemoveFile, canBeAddFiles, files } = props

	return (
		<Grid>
			<Grid item xs={12}>
				<Typography className={classes.title}>
					<strong>{'FILES'}</strong>
				</Typography>
				<Divider />
				{canBeAddFiles && (
					<DropDownZone
						classes={classes}
						multiple={false}
						message={'Click here to select files'}
						onFilesReceived={(files) => {
							onAddFiles(files)
						}}
					/>
				)}

				{files.length !== 0 ? (
					files.map((file) => {
						return (
							<UploadFileChip
								key={file.name}
								file={file}
								canBeEdit={canBeAddFiles}
								onRemoveFile={onRemoveFile}
							/>
						)
					})
				) : (
					<Alert severity='info'>
						{'There are no files added in the spare part request'}
					</Alert>
				)}
			</Grid>
		</Grid>
	)
}
EvidenceComponent.propTypes = {
	onAddFiles: propTypes.func,
	onRemoveFile: propTypes.func
}
EvidenceComponent.defaultProps = {
	onAddFiles: () => console.warn('Callback [onAddFiles] no defined'),
	onRemoveFile: () => console.warn('Callback [onRemoveFile] no defined'),
	canBeAddFiles: false,
	Files: [],
	canBeEditInPlant: false
}

/**
 * Component to upload file
 * @param {*} props
 */
const UploadFileChip = (props) => {
	const { canBeEdit, file, onRemoveFile } = props

	return (
		<>
			{canBeEdit ? (
				<Chip
					color='primary'
					icon={<AttachIcon />}
					label={
						<div
							style={{
								maxWidth: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}
						>
							<Typography
								variant='caption'
								noWrap
								title={file.name}
							>
								{file.name}
							</Typography>
						</div>
					}
					id={file.name}
					key={file.name}
					onDelete={() => {
						onRemoveFile(file.name)
					}}
					deleteIcon={<CloseIcon id={file.name} />}
					clickable
					target='_blank'
					onClick={() =>
						window.open(
							`${window.config.serviceManagementBackendUrl}/${file.relativePath}`
						)
					}
					style={{
						margin: '1px',
						maxWidth: '450px',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				/>
			) : (
				<Chip
					color='primary'
					icon={<AttachIcon />}
					label={
						<div
							style={{
								maxWidth: '200px',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}
						>
							<Typography
								variant='caption'
								noWrap
								title={file.name}
							>
								{file.name}
							</Typography>
						</div>
					}
					clickable
					component='a'
					target='_blank'
					onClick={() =>
						window.open(
							`${window.config.serviceManagementBackendUrl}/${file.relativePath}`
						)
					}
					id={file.name}
					key={file.name}
					style={{
						margin: '1px',
						maxWidth: '450px',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}
				/>
			)}
		</>
	)
}

export default EvidenceComponent
