/**Import react section */
import React, { useState } from 'react'
import moment from 'moment'
import Select from 'react-select'

/**Import common componets */
import ButtonConfirm from './../../../common/Buttons/ButtonConfirm'
/**Import MaterialUi section */
import { Grid, Typography } from '@material-ui/core'
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import DateSelector from '../../../common/DateSelector'
import AlertComponent from '../../../common/AlertComponent'
import { GetFaultWarranty } from '../../../../store/helpers/SelectOptions'

const CompletedService = (props) => {
	const subCategoriesOptions = GetFaultWarranty()
	const { isSavingService, onSetPasswordDialogConfiguration, isProvider } = props
	const classes = createServicesStyle()
	const [completedModdel, setCompletedModel] = useState({ status: props.status, completionDate: moment(), subcategory: '' })

	const openModalForCompletedService = () => {
		if (onSetPasswordDialogConfiguration) onSetPasswordDialogConfiguration(completedModdel)
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setCompletedModel({
			...completedModdel,
			[name]: value
		})
	}

	/**
	 * On select failure category
	 * @param {*} failure
	 */
	const onSelectChange = (failure) => {
		setCompletedModel({
			...completedModdel,
			subcategory: failure.label
		})
	}

	/**Verify if can be saved */
	const canBeSaved = () => {
		if (completedModdel ? completedModdel.subcategory.length <= 0 : true) return false
		if (completedModdel && completedModdel.completionDate ? completedModdel.completionDate.length <= 1 : true) return false
		return true
	}

	return (
		<div>
			<Grid item xs={12}>
				{isProvider && (
					<>
						<AlertComponent
							title={'Info'}
							message={'Sure you want to close the service, once closed you will not be able to modify the information.'}
							severityType={'info'}
						/>
						<br />
					</>
				)}
				<DateSelector
					dataCy='data-picker-completed-date'
					label='Completion Date'
					name='completionDate'
					showIncon={false}
					active={true}
					value={completedModdel ? completedModdel.completionDate : null}
					disabled={false}
					onChange={onPropertyChange}
				/>
				<br /> <br />
				<Typography style={{ fontSize: '0.75rem' }} noWrap>
					{'Select the subcategory of the failure'}{' '}
				</Typography>
				<Select
					fullWidth
					options={subCategoriesOptions}
					name='subcategory'
					value={completedModdel.subcategory ? subCategoriesOptions.find((x) => x.label === completedModdel.subcategory) : null}
					onChange={onSelectChange}
					styles={{
						menu: (base) => ({ ...base, zIndex: 31 })
					}}
				/>
			</Grid>
			<br />
			<Grid item xs={12} className={classes.updateButton}>
				<ButtonConfirm title='CONFIRM' isSaving={isSavingService || !canBeSaved()} onSavedClick={openModalForCompletedService} />
			</Grid>
		</div>
	)
}

export default CompletedService
