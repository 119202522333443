/**Import react section */
import React, { useState } from 'react'
/**Import common componets */
import TextBox from '../../common/TextBox'
import ButtonConfirm from '../../common/Buttons/ButtonConfirm'
/**Import MaterialUi section */
import { Grid } from '@material-ui/core'
import {createServicesStyle} from '../../../resources/styles/CreateServiceStyles'
import AlertComponent from '../../common/AlertComponent'

const ChangeStatusMessage = (props) => {
    const { onSetPasswordDialogConfiguration, status, message, canBeDelete } = props;
    const classes = createServicesStyle();
    const [requestModel, setRequestModel] = useState({comments:'', status: props.status});

    const openModalForChangeRequest = () => {
        if (onSetPasswordDialogConfiguration)
            onSetPasswordDialogConfiguration(requestModel, status)
    }

    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setRequestModel({
            ...requestModel,
            [name]: value
        });

    }

    /**Verify if can be sabed */
    const canBeSaved = () => {
        if(canBeDelete) return true;
        if (requestModel ? (requestModel.comments.length <= 0) : true) return false;
        return true;
    }

    return (
        <div>
            {canBeDelete && <Grid item xs={12}>
                {
                    <AlertComponent
                        title={"Delete payment request"}
                        message={"Sure you want to delete the payment request, once deleted the changes cannot be reversed"}
                        severityType={"warning"}
                    />
                }<br/>
            </Grid>}
            {!canBeDelete && <Grid item xs={12}>
                <TextBox
                    active={true}
                    fullWidth
                    autoFocus
                    name="comments"
                    variant="outlined"
                    margin="dense"
                    label={message}
                    multiline
                    rows={4}
                    onChange={onPropertyChange}
                    value={requestModel ? requestModel.comments : ""}
                />
            </Grid>}
            <Grid item xs={12} className={classes.updateButton}>
                <ButtonConfirm
                    title="CONFIRM"
                    isSaving={!canBeSaved()}
                    onSavedClick={openModalForChangeRequest}
                />
            </Grid>
        </div>
    )
}

export default ChangeStatusMessage
