import { makeStyles } from '@material-ui/styles'

/**
 * The main container styles
 */
export const useDashboardContainer = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateRows: 'auto auto 1fr'
	},
	cardList: {
		gridRow: '1 / 2',
		margin: '10px 10px 0px 0px',
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		columnGap: '5px'
	},
	container: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		gridColumn: 1,
		gridRow: 2,
		overflowY: 'auto',
		maxHeight: `calc(100vh - ${300}px)`,
		minHeight: `calc(100vh - ${300}px)`,
	},
	pagination: {
		gridColumn: 1,
		gridRow: 3,
		display: 'flex',
		alignItems:"flex-end"
	},
    grow: {
        flexGrow: 1,
    }
}))

/**
 * The card styles
 */
export const useDashboardCardStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: ({ mainColor }) => `${mainColor}`,
		minWidth: '220px',
		height: ({ height }) => `${height}`,
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridTemplateRows: '1fr 10px'
	},
	leftContainer: {
		gridColumn: '1 / 2',
		gridRow: '1 / 2',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		color: 'white',
		alignItems: 'center'
	},
	rightContainer: {
		gridColumn: '2 / 3',
		gridRow: '1 / 2',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	bottomBorder: {
		gridColumn: '1 / 3',
		gridRow: '2 / 3',
		backgroundColor: ({ secondaryColor }) => `${secondaryColor}`
	},
	primaryText: {
		color: theme.palette.common.black,
		fontWeight: 'bold'
	},
	secondaryText: {
		color: theme.palette.common.white
	}
}))
