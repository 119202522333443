import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMobile } from '../../hooks/useIsMobile'

import DesktopView from './components/DesktopView'
import MobileView from './components/MobileView'
import Toaster from '../common/Toaster'

import {
	loadServices,
	loadDashboardServices,
	downloadReportOpenServices,
	addDateAttention,
	importCommentsFromBackend
} from '../../store/services/serviceActions'
import { loadAllServiceProviderWithFilters } from '../../store/serviceproviders/serviceProvidersActions'
import { loadAllSalesRepresentatives } from '../../store/salesrepresentatives/salesRepresentativesActions'
import { loadInternalUsers } from '../../store/users/UsersActions'
import { loadAllRetailss } from '../../store/retails/retailsActions'
import { toSafeObject } from '../../store/helpers/StateHelper'
import ImportCommentHistoryPanel from '../services/components/ImportCommentHistoryPanel'


/***
 * Dashboard view container
/**
 *
 *
 * @param {*} props
 * @returns
/**
 *
 *
 * @param {*} props
 * @returns
 */
const View = (props) => {
	const isMobile = useIsMobile(props.isMobile)

	const servicesList = useSelector((state) =>
		toSafeObject(state.services.get('servicesList'))
	)
	const isLoading = useSelector((state) => state.services.get('isLoading'))
	const isDownloadReport = useSelector((state) => state.services.get('isDownloadReport'))
	const isImportCoomments = useSelector((state) => state.services.get('isSavingService'))
	const isLoadingDashboard = useSelector((state) =>
		state.services.get('isLoadingDashboard')
	)
	const dashboard = useSelector((state) => state.services.get('dashboard'))

	const serviceProviders = useSelector((state) =>
		state.serviceProviders.get('serviceProviders')
	)
	const salesRepresentatives = useSelector((state) =>
		state.salesRepresentatives.get('salesRepresentatives')
	)
	const retails = useSelector((state) => state.retails.get('retails'))
	const internalUsers = useSelector((state) =>
		state.users.get('internalUsers')
	)
	const userProfile = useSelector((state) => state.session.get('profile'))
	const accountCode = useSelector((state) =>
		toSafeObject(state.session.get('profile')).Account.Code
	)

	const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

	/**Local store this component section */
	const [query, setQuery] = useState('')
	const [lateralPanelImportComments, setLateralPanelImportComments] = useState(false);
	const dispatch = useDispatch()

	let location = useLocation()
	let history = useHistory()

	/**
	 * Load services list
	 */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadServices("open", queryParams, 10, isMobile)).then((result) => { })
	}, [dispatch, location.search, isMobile, history])

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		if (servicesList.services && servicesList?.services.length === 0 && queryParams.pageNumber > 1) {
			queryParams.pageNumber = 1
			history.push({ search: queryString.stringify(queryParams) })
		}
	}, [servicesList, location.search, history])

	/**
	 * Load dasborad list
	 */
	useEffect(() => {
		dispatch(loadDashboardServices("open")).then(() => { })
	}, [dispatch])

	useEffect(() => {
		dispatch(loadAllServiceProviderWithFilters(null, 1000))
		dispatch(loadAllSalesRepresentatives(null, 1000))
		dispatch(loadAllRetailss())
		dispatch(loadInternalUsers())
	}, [dispatch])

	/**
	 * On change page number
	 */
	const onChangeServicePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change sortin criteria
	 * @param {} sender
	 * @param {*} sortingCriteria
	 */
	const onchangeServiceSortingCriteria = (sortingCriteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortingCriteria.by
		queryParams.sortDirection = sortingCriteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**Search value */
	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeLocation = (location) => history.push(location)

	const redirectToCreateService = () => {
		history.push("create")
	}

	const redirectToServiceDetail = (token, serie) => {
		history.push(`/services/${token}/${serie}`)
	}
	const onDownloadReportOpenServices = (startDate, endDate) => {
		const queryParams = queryString.parse(location.search)
		return dispatch(downloadReportOpenServices(toSafeObject(internalUsers), "open", startDate, endDate, queryParams))
			.then(() => {
				return Promise.resolve();
			})
			.catch((error) => {
				console.error("Failed to download report services open", error);
			});
	};

	/**
 * Function to add date attention by tech provider
 * @param {*} service 
 * @param {*} dateAttentionModel 
 * @param {*} dispatcherService 
 */
	const onAddDateAttention = (service, dateAttentionModel, dispatcherService) => {
		dispatch(addDateAttention(service, dateAttentionModel, dispatcherService, true))
			.then(() => {
			})
			.catch((error) => {
			});
	}

	/**Open lateral panel for import file reference SAP */
	const openImportCommentsPanel = () => {
		setLateralPanelImportComments(!lateralPanelImportComments)
	}

	/**
	 * Function to add date attention by tech provider
	 * @param {*} atttachmentsToImport 
	 */
	const onImportCommentsHistory = (atttachmentsToImport) => {
		const queryParams = queryString.parse(location.search)
		dispatch(importCommentsFromBackend(atttachmentsToImport))
			.then(() => {
				setLateralPanelImportComments(!lateralPanelImportComments)
				dispatch(loadServices("open", queryParams, 10, isMobile)).then(() => { })
				return Promise.resolve();
			})
			.catch((error) => {
				setToasterOptions({ open: true, message: "An error to import comments in services", variant: 'error' })
			});
	}

	return (
		<>
			{isMobile ?
				<MobileView
					services={servicesList.services}
					totalServicesPages={servicesList.total}
					servicePageNumber={servicesList.pageNumber}
					onChangeServicePage={onChangeServicePage}
					onchangeServiceSortingCriteria={onchangeServiceSortingCriteria}
					sortServiceBy={servicesList.orderBy}
					sortServiceDirection={servicesList.orderType}
					isLoading={isLoading}
					isLoadingDashboard={isLoadingDashboard}
					dashboard={dashboard}
					serviceProviders={serviceProviders}
					salesRepresentatives={salesRepresentatives}
					retails={retails}
					searchValue={query}
					onChangeSearchValue={(query) => updateSearchQuery(query)}
					internalUsers={internalUsers}
					onChangeLocation={onChangeLocation}
					redirectToCreateService={redirectToCreateService}
					redirectToServiceDetail={redirectToServiceDetail}
					userProfile={userProfile}
					onDownloadReportOpenServices={onDownloadReportOpenServices}
					isDownloadReport={isDownloadReport}
					onAddDateAttention={onAddDateAttention}
				/> :

				<DesktopView
					services={servicesList.services}
					totalServicesPages={servicesList.total}
					servicePageNumber={servicesList.pageNumber}
					onChangeServicePage={onChangeServicePage}
					onchangeServiceSortingCriteria={onchangeServiceSortingCriteria}
					sortServiceBy={servicesList.orderBy}
					sortServiceDirection={servicesList.orderType}
					isLoading={isLoading}
					isLoadingDashboard={isLoadingDashboard}
					dashboard={dashboard}
					serviceProviders={serviceProviders}
					salesRepresentatives={salesRepresentatives}
					retails={retails}
					searchValue={query}
					onChangeSearchValue={(query) => updateSearchQuery(query)}
					internalUsers={internalUsers}
					onChangeLocation={onChangeLocation}
					redirectToCreateService={redirectToCreateService}
					redirectToServiceDetail={redirectToServiceDetail}
					userProfile={userProfile}
					onDownloadReportOpenServices={onDownloadReportOpenServices}
					isDownloadReport={isDownloadReport}
					openImportCommentsPanel={openImportCommentsPanel}
				/>
			}

			{lateralPanelImportComments &&
				<ImportCommentHistoryPanel
					onCloseButtonClick={openImportCommentsPanel}
					onImportCommentsHistory={onImportCommentsHistory}
					isSaving={isImportCoomments}
					accountCode={accountCode}
				/>
			}

			{/** Toaster */}
			{
				toasterOptions.open &&
				<Toaster
					toasterOptions={toasterOptions}
					onClose={() => setToasterOptions({ open: false })}
				/>
			}
		</>
	)
}
export default View
