/**Import react section */
import React from 'react'
import moment from 'moment'

/**Import Material UI section */
import { Stepper, Step, StepLabel, Typography, StepIcon, StepConnector } from '@material-ui/core'
import { panelEditUseStyles } from '../resources/useStyles'
/** Material-UI  section */
import { withStyles } from '@material-ui/styles';

const PackageTracking = (props) => {
    const { carrierData } = props;
    const classes = panelEditUseStyles();
    return (
        <Stepper
            className={classes.stepper}
            activeStep={1}
            alternativeLabel
            connector={<ColorLibConnector />}
        >
            <Step
                key={0}
            >
                <StepLabel
                    StepIconComponent={IconComponent}
                >
                    <div>
                        <Typography align='left' variant='subtitle2'>{carrierData ? (carrierData.packageStatus ? carrierData.packageStatus : '') : ''}</Typography>
                        <Typography align='left' variant='subtitle2'>{carrierData ? carrierData.lastEvent : ''}</Typography>
                        <Typography align='left' variant='subtitle1' className={classes.titleBold}>SHIPMENT DETAILS</Typography>
                        <div className={classes.flex}>
                            <Typography variant='subtitle2' className={classes.titleBold}>{`Service: `}</Typography>
                            <Typography variant='subtitle2' className={classes.titleRight}>{`${carrierData ? carrierData.service_code : ''}`}</Typography>
                        </div>
                        <div className={classes.flex}>
                            <Typography variant='subtitle2' className={classes.titleBold}>{`Destination country: `}</Typography>
                            <Typography variant='subtitle2' className={classes.titleRight}>{`${carrierData ? carrierData.destination_country : ''}`}</Typography>
                        </div>
                    </div>
                </StepLabel>

            </Step>
            <Step key={1}>
                <StepLabel
                    StepIconComponent={IconComponent}
                >
                    <div>
                        <Typography align='right' variant='subtitle2'>Create at</Typography>
                        <Typography align='right' variant='subtitle2' className={classes.titleBold}>
                            {`${carrierData ? moment(carrierData.created_at).format('llll') : ''}`}</Typography>
                        <Typography align='right' variant='subtitle2' className={classes.titleBold}>{`Updated at`}</Typography>
                        <Typography align='right' variant='subtitle2' className={classes.titleBold}>
                            {`${carrierData ? moment(carrierData.updated_at).format('llll') : ''}`}</Typography>
                    </div>
                </StepLabel>
            </Step>
        </Stepper>
    )
}

const IconComponent = withStyles((theme) => ({
    root: {
        color: theme.palette.colors.purple,
        '&$active': {
            color: theme.palette.colors.purple,
            marginLeft: "200px !important",
            width: 40,
            height: 40,

        },
        '&$completed': {
            color: theme.palette.colors.purple,
            marginLeft: "-127px",
            marginTop: "5px",
            width: 30,
            height: 30,
        }
    },
    active: {},
    completed: {}
}))(StepIcon);

const ColorLibConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundColor: theme.palette.colors.purple,
            marginLeft: "-120px"
        }
    },
    completed: {
        '& $line': {
            backgroundColor: theme.palette.colors.purple,
            marginLeft: "200px !important"
        }
    },
    line: {
        height: 10,
        width: "470px",
        border: 1,
        backgroundColor: theme.palette.colors.purple,
        borderRadius: 0
    }
}))(StepConnector);

export default PackageTracking