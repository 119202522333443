

import { createSelector } from 'reselect'
import { toSafeArray } from '../../store/helpers/StateHelper'
let allServicesOpened = (state) => state.regions.get('services')

export const getServicesSelector = createSelector(
    allServicesOpened,
    (allServicesOpened) => {
        let allServices = toSafeArray(allServicesOpened)
        return allServices
    }
)

export const getServicesByRegions = createSelector(
    getServicesSelector,
    (allServicesOpened) => {
        return allServicesOpened
    }
)