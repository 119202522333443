/**Import react section */
import React from 'react'
/**Import react section */
import ZingChart from 'zingchart-react'
import GraphServicesConfig from './GraphServicesConfig'

const GraphServices =(props)=> {
	const charValues = GraphServicesConfig(props.chartData, props.chartDescription)
    return <ZingChart data={charValues}  />
}

export default GraphServices