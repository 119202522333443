import { createUserManager } from 'redux-oidc'
import { getAbsoluteUrlAddress } from './UrlHelper'

export const buildUserManager = (configuration) =>
	createUserManager({
		client_id: 'apsys.imberausa.servicemanagement.webapp',
		redirect_uri: getAbsoluteUrlAddress('callback'),
		post_logout_redirect_uri: getAbsoluteUrlAddress(''),
		response_type: 'id_token token',
		scope: 'openid profile userprofile',
		authority: `${configuration.identityServerUrl}/identity`,
		filterProtocolClaims: true,
		loadUserInfo: true,
		monitorSession: false
	})

export const getUserManager = () => window.userManager
export const setUserMananger = (userMananger) =>
	(window.userManager = userMananger)
