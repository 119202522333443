import React from 'react';

import { salesRepresentativeStyles } from '../../resources/styles/SalesRepresentativeStyles';
import { WhiteTextBox } from './TextBox';

export const AddressForm = (props) => {
    const { onPropertyChange, addressInfo, prefix } = props
    const classes = salesRepresentativeStyles();

    return (
        <div>
            <div className={classes.texboxContainer}>
                <WhiteTextBox
                    active={true}
                    fullWidth
                    name={prefix + "Street"}
                    variant="outlined"
                    margin="dense"
                    label="Street(mailing address)"
                    onChange={onPropertyChange}
                    defaultValue={addressInfo ? addressInfo.street : ""}
                />
            </div>
            <div className={classes.texboxContainer}>
                <WhiteTextBox
                    active={true}
                    fullWidth
                    name={prefix + "City"}
                    variant="outlined"
                    margin="dense"
                    label="City"
                    onChange={onPropertyChange}
                    defaultValue={addressInfo ? addressInfo.city : ""}
                />
            </div>
            <div className={classes.texboxContainer}>
                <WhiteTextBox
                    active={true}
                    fullWidth
                    name={prefix + "ZipCode"}
                    variant="outlined"
                    margin="dense"
                    label="Zip Code"
                    onChange={onPropertyChange}
                    defaultValue={addressInfo ? addressInfo.postal_code : ""}
                />
            </div>
            <div className={classes.texboxContainer}>
                <WhiteTextBox
                    active={true}
                    fullWidth
                    name={prefix + "State"}
                    variant="outlined"
                    margin="dense"
                    label="State"
                    onChange={onPropertyChange}
                    defaultValue={addressInfo ? addressInfo.state : ""}
                />
            </div>
            <div className={classes.texboxContainer}>
                <WhiteTextBox
                    active={true}
                    fullWidth
                    name={prefix + "Country"}
                    variant="outlined"
                    margin="dense"
                    label="Country"
                    onChange={onPropertyChange}
                    defaultValue={addressInfo ? addressInfo.country : ""}
                />
            </div>
        </div>
    )
}

export default (AddressForm);