import { makeStyles } from '@material-ui/styles'

/**
 * custom lateral panel
 */
export const panelLateralServicesStyles = makeStyles((theme) => ({
    drawerContent: {
        width: "420px",
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    panelHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        backgroundColor: "#444054",
    },
    title: {
        color: theme.palette.common.white
    },
    grow: {
        flexGrow: 1,
    },
    buttonClose: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.red,
        "&:hover": {
            backgroundColor: theme.palette.colors.red
        },
        width: "37px",
        height: "36px",
        margin: 0,
        marginLeft: theme.spacing(1),

    },
    buttonEdit: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.blue,
        "&:hover": {
            backgroundColor: theme.palette.colors.blue
        },
        width: "37px",
        height: "36px",
        margin: 0,
    },
    buttonSaved: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.green,
        "&:hover": {
            backgroundColor: theme.palette.colors.green
        },
        width: "37px",
        height: "36px",
        margin: 0,
        "&.Mui-disabled": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colors.green,
            opacity: .5,
        },
    },
    iconPanel: {
        width: "4rem",
        height: "4rem",
        margin: '0 auto',
        padding: "5px"
    },
    iconColorGreen: {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.colors.green} !important`,
    },
    iconColorRed: {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.colors.red} !important`,
    },
    contentPanelLsit: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "80vh"
    },
    fabStyle: {
        marginRight: theme.spacing(0.5),
        boxShadow: "0px 0px 0px 0px",
        width: "30px",
        height: 'auto'
    },
    faultWarrantyIcon: {
        width: '25px',
        height: 'auto',
    },
    faultWarrantyIconSelected: {
        marginRight: theme.spacing(0.5),
        boxShadow: "0px 0px 0px 0px",
        backgroundColor: theme.palette.serviceStatus.newServiceDark,
        "&:hover": {
            backgroundColor: theme.palette.serviceStatus.newServiceDark,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.serviceStatus.newServiceDark,
        },
    },
    containerIcon: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        gridColumn: '1 / 1',
    },
    containerHeader: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
    },
    containerText: {
        gridColumn: '2 / 2',
        padding: "5px"
    },
    subTitle: {
        color: theme.palette.common.white,
        marginTop: '-8px'

    },
    panelContent: {
        padding: '0px 20px 0px 20px'
    },
    active: {
        backgroundColor: theme.palette.colors.purple,
        color: theme.palette.common.white,
    },
    assigned: {
        backgroundColor: theme.palette.colors.blue,
        color: theme.palette.common.white,
    },
    suspended: {
        backgroundColor: theme.palette.colors.orange,
        color: theme.palette.common.white,
    },
    waiting: {
        backgroundColor: theme.palette.colors.waiting,
        color: theme.palette.common.white,
    },
    completed: {
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
    },
    replaced: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    cancel: {
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
    },
}))

/**
 * custom lateral panel
 */
export const panelLateralIconStyles = makeStyles((theme) => ({
    panelHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        backgroundColor: ({ backgroundColorHeader }) => `${backgroundColorHeader ? backgroundColorHeader : theme.palette.colors.purple}`,
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 'bold'
    },
    grow: {
        flexGrow: 1,
    },
    buttonClose: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.red,
        "&:hover": {
            backgroundColor: theme.palette.colors.red
        },
        width: "37px",
        height: "36px",
        margin: 0,
        marginLeft: theme.spacing(1),

    },
    buttonEdit: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.blue,
        "&:hover": {
            backgroundColor: theme.palette.colors.blue
        },
        width: "37px",
        height: "36px",
        margin: 0,
    },
    buttonSaved: {
        alignSelf: 'flex-start',
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.green,
        "&:hover": {
            backgroundColor: theme.palette.colors.green
        },
        width: "37px",
        height: "36px",
        margin: 0,
        "&.Mui-disabled": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colors.green,
            opacity: .5,
        },
    },
    iconPanel: {
        width: "4rem",
        height: "4rem",
        margin: '0 auto',
        padding: "5px"
    },
    faultWarrantyIcon: {
        width: '25px',
        height: 'auto',
    },
    faultWarrantyIconSelected: {
        marginRight: theme.spacing(0.5),
        boxShadow: "0px 0px 0px 0px",
        backgroundColor: ({ backgroundIcon }) => `${backgroundIcon ? backgroundIcon : theme.palette.colors.purple}`,
        "&:hover": {
            backgroundColor: ({ backgroundIcon }) => `${backgroundIcon ? backgroundIcon : theme.palette.colors.purple}`,
        },
        "&.Mui-disabled": {
            backgroundColor: ({ backgroundIcon }) => `${backgroundIcon ? backgroundIcon : theme.palette.colors.purple}`,
        },
    },
    containerIcon: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        gridColumn: '1 / 1',
    },
    containerHeader: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
    },
    containerText: {
        gridColumn: '2 / 2',
        padding: "5px"
    },
    subTitle: {
        color: theme.palette.common.white,
        marginTop: '-8px',
        fontWeight: 'bold'
    },
}))