/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import Toolbar from '../../common/Toolbar'
import {
	Layout,
	Content,
	Header,
	HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'
import DateRangePicker from '../../common/daterangepicker/DateRangePicker'
import CreateSummaryForm from './CreateSummaryForm'
import DesktopSummaryView from './DesktopSummaryView'

/** Import material UI */
import { Paper, Typography } from '@material-ui/core'

/**Import resources section */
import { useCreateDesktopStyle } from '../resources/useStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'
import { getOptionsReactSelect } from '../../../store/helpers/FilterHelper'

import { hasAuthorization } from '../../../store/session/sessionActions'
import { PAYMENT_MANAGER } from '../../../resources/RolesEnum'

const SummaryDesktopView = (props) => {
	/**onjects props sections */
	const {
		userProfile,
		serviceProviders,
		paymentRequests,
		paymentSummary
	} = props
	const {
		isLoadingSummary,
		isSavingSummary,
		onDonwloadReportSummary,
		onDownloadPaymentRequestsReport
	} = props
	const {
		onSearchPayments,
		onSetPasswordDialogConfiguration,
		onGetPaymentRequest
	} = props

	let isAuthorizerCanGenerated = hasAuthorization(userProfile, [
		PAYMENT_MANAGER
	])

	const classes = useCreateDesktopStyle()
	let seviceProviderOptions = getOptionsReactSelect(serviceProviders)
	const [summaryModel, setSummaryModel] = useState({
		serviceProvider: null,
		startDate: moment().add(-1, 'month'),
		endDate: moment().add(1, 'day'),
		checkFolio: '',
		checkFolios: '',
		totaltoPay: 0,
		note: '',
		email: ''
	})
	const [optionView, setOptionView] = useState(true)

	/** Invoke when select property change*/
	const onSelectChange = (name, option) => {
		setSummaryModel({
			...summaryModel,
			[name]: option
		})
	}

	const onSelectChangeS = (name, option, nameS, optionS) => {
		setSummaryModel({
			...summaryModel,
			[name]: option,
			[nameS]: optionS
		})
	}

	/**
	 * On select range date
	 * @param {*} range
	 */
	const onDateRangeChange = (range) => {
		setSummaryModel({
			...summaryModel,
			startDate: range.startDate,
			endDate: range.endDate
		})
	}

	/**
	 * function to get information
	 */
	const searchPayments = () => {
		if (onSearchPayments) {
			onSearchPayments(summaryModel)
			setSummaryModel({
				...summaryModel,
				checkFolio: '',
				note: '',
				email: ''
			})
		}
	}

	/**
	 * Function on change property
	 * @param {*} event
	 */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setSummaryModel({
			...summaryModel,
			[name]: value
		})
	}

	/**
	 * Function to save summary payment
	 */
	const onSaveGenerateSummary = () => {
		if (onSetPasswordDialogConfiguration) {
			onSetPasswordDialogConfiguration(summaryModel)
		}
	}

	const changeView = (option) => {
		setOptionView(!option)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<>
						{isAuthorizerCanGenerated && (
							<SummaryDateRangePicker
								onDateRangeChange={onDateRangeChange}
								startDate={summaryModel.startDate}
								endDate={summaryModel.endDate}
							/>
						)}
						{!isAuthorizerCanGenerated && (
							<HeaderBreadCrum>
								<Typography color='secondary'>
									Payments / View Payment Summary
								</Typography>
							</HeaderBreadCrum>
						)}
					</>
				}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={7}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar title='PAYMENT SUMMARY' icon={iconSparePart} />
					<Paper className={classes.paperSummary}>
						{isAuthorizerCanGenerated && (
							<div>
								{optionView && (
									<CreateSummaryForm
										onSelectChange={onSelectChange}
										onSelectChangeS={onSelectChangeS}
										summaryModel={summaryModel}
										onSearchPayments={searchPayments}
										paymentRequests={paymentRequests}
										isLoadingSummary={isLoadingSummary}
										onPropertyChange={onPropertyChange}
										onSaveGenerateSummary={
											onSaveGenerateSummary
										}
										seviceProviderOptions={
											seviceProviderOptions
										}
										caBeEdit={paymentSummary}
										isSavingSummary={isSavingSummary}
										onDonwloadReportSummary={
											onDownloadPaymentRequestsReport
										}
										optionView={optionView}
										changeView={changeView}
									/>
								)}

								{!optionView && (
									<DesktopSummaryView
										paymentSummary={paymentSummary}
										onGetPaymentRequest={
											onGetPaymentRequest
										}
										isLoadingSummary={isLoadingSummary}
										isSavingSummary={isSavingSummary}
										onDonwloadReportSummary={
											onDonwloadReportSummary
										}
										optionView={optionView}
										changeView={changeView}
									/>
								)}
							</div>
						)}

						{!isAuthorizerCanGenerated && (
							<DesktopSummaryView
								paymentSummary={paymentSummary}
								onGetPaymentRequest={onGetPaymentRequest}
								isLoadingSummary={isLoadingSummary}
								isSavingSummary={isSavingSummary}
								onDonwloadReportSummary={
									onDonwloadReportSummary
								}
								isAuthorizerCanGenerated={
									isAuthorizerCanGenerated
								}
								optionView={optionView}
								changeView={changeView}
							/>
						)}
					</Paper>
				</div>
			</Content>
		</Layout>
	)
}

SummaryDesktopView.propTypes = {
	/**
	 * The initial date to shown in the x-axis chart
	 */
	startDate: propTypes.instanceOf(moment),
	/**
	 * The final date to shown in the x-axis chart
	 */
	endDate: propTypes.instanceOf(moment),
	/**
	 * Callback executed when the change interval time
	 */
	onDateRangeChange: propTypes.func
}
SummaryDesktopView.defaultProps = {
	userProfile: {},
	startDate: moment(),
	endDate: moment(),
	onDateRangeChange: () =>
		console.warn('No [onDateRangeChange] callback defined')
}

/**
 * Paymente summary DateRangePicker configured
 */
const SummaryDateRangePicker = (props) => {
	const predefinedRanges = [
		{
			label: 'This Year',
			startDate: moment().startOf('year'),
			endDate: moment()
		},
		{
			label: 'Last Year',
			startDate: moment().add(-1, 'year'),
			endDate: moment()
		},
		{
			label: 'Last Semester',
			startDate: moment().add(-6, 'month'),
			endDate: moment()
		},
		{
			label: 'Last Trimester',
			startDate: moment().add(-3, 'month'),
			endDate: moment()
		}
	]

	return (
		<DateRangePicker
			textColor='white'
			variant='outlined'
			startDate={props.startDate}
			endDate={props.endDate}
			predefinedRanges={predefinedRanges}
			onChangeRange={props.onDateRangeChange}
		/>
	)
}

export default withImmutablePropsToJS(SummaryDesktopView)
