import React from 'react'
import propTypes from 'prop-types'
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import { Paper, Typography } from '@material-ui/core'
import SearchBox from '../../common/SearchToolbox'
import { useDesktopViewStyles } from '../resources/NetworkDayStyles'

/**
 * SalesRepresentativeNetworkDays
 */
const SalesRepresentativeNetworkDays = (props) => {
	const classes = useDesktopViewStyles()

	const getHeaderTableConfig = () => {
        let nameProperty = headersTableConfig.find((x) => x.dataSource === 'Name')
        nameProperty.onRenderProperty = (item) => {
            return <Typography className={classes.textTable}>{item.Name}</Typography>
        }

		let averageProperty = headersTableConfig.find((x) => x.dataSource === 'Average')
        averageProperty.onRenderProperty = (item) => {
            return <Typography className={classes.textTable}>{item.Average}</Typography>
        }
		let maximumProperty = headersTableConfig.find((x) => x.dataSource === 'Maximum')
        maximumProperty.onRenderProperty = (item) => {
            return <Typography className={classes.textTable}>{item.Maximum}</Typography>
        }
		let countProperty = headersTableConfig.find((x) => x.dataSource === 'Count')
        countProperty.onRenderProperty = (item) => {
            return <Typography className={classes.textTable}>{item.Count}</Typography>
        }
        return headersTableConfig
    }

	return (
		<Paper className={classes.contentProviderTablePaper}>
			<div className={classes.search}>
				<Typography  variant='subtitle1' className={classes.titleColor}>Representatives</Typography>
				<div className={classes.grow} />
				<SearchBox
					placeholder='Search'
					onChange={props.onQueryRepresentativeChange}
					value={props.searchValue}
				/>
			</div>
			<div className={classes.containerTable}>
				<DataGrid
					data={props.salesRepresentative}
					headers={getHeaderTableConfig()}
					sortBy={props.sortingCriteria.by}
					sortDirection={props.sortingCriteria.direction}
					onChangeSortingCriteria={props.onChangeSortingCriteria}
					onItemClick={(sender, salesRepresentative) => props.onSalesRepresentativeClick(salesRepresentative)}
				/>

			</div>
		</Paper>
	)
}
SalesRepresentativeNetworkDays.propTypes = {
	/**
	 * Sales representative list
	 */
	salesRepresentative: propTypes.arrayOf(
		propTypes.shape({
			Name: propTypes.string.isRequired,
			Minimum: propTypes.number.isRequired,
			Average: propTypes.number.isRequired,
			Maximum: propTypes.number.isRequired
		})
	),
	/**
	 * The sorting criteria 
	 */
	sortingCriteria: propTypes.shape({
		by: propTypes.string.isRequired,
		direction: propTypes.string.isRequired
	}).isRequired,
	/**
	 * Callback executed when the user change the sorting criteria
	 */
	onChangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * Callback executed when the user click on a sales representative
	 */
	onSalesRepresentativeClick: propTypes.func,
	/**
	 * Callback executed when the search sales representative change
	 */
	onQueryRepresentativeChange: propTypes.func,

}
SalesRepresentativeNetworkDays.defaultProps = {
	salesRepresentative: [],
	onChangeSortingCriteria: () => console.warn("No [onChangeSortingCriteria] callback defined"),
	onQueryRepresentativeChange: () => console.warn("No [onQueryRepresentativeChange] callback defined")
}
export default SalesRepresentativeNetworkDays

const headersTableConfig = [
	{
		title: 'Name',
		dataSource: 'Name',
		sortable: true
	},
	{
		title: 'Avg',
		dataSource: 'Average',
		sortable: true
	},
	{
		title: 'Max',
		dataSource: 'Maximum',
		sortable: true
	},
	{
		title: 'Count',
		dataSource: 'Count',
		sortable: true
	}
]
