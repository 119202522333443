/**Import react section */
import React from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import Iframe from 'react-iframe'

/**Import componnets section */
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import Toolbar from '../../common/Toolbar'
import Authorization from '../../common/AuthorizationEvaluation'
import DesktopViewSkeleton from '../../skeletons/regions/DesktopViewSkeleton'
/**Import material UI Section */
import { Typography, ButtonGroup, Tooltip, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import { termsStyle } from '../resources/TermsStyles'
import termsIcon from '../../../resources/images/terms.svg'
import AlertComponent from '../../common/AlertComponent'
import { PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR } from '../../../resources/RolesEnum'

/**
 * Componete terms
 *  */

const IndexDesktopView = (props) => {
	const { userProfile } = props
	const { termsAndConditions, isSaving, isLoading } = props
	const classes = termsStyle()

	const renderContentRight = () => {
		return (
			<div className={classes.headerbotton}>
				{termsAndConditions !== null && (
					<Typography variant='subtitle2' className={classes.titleHeader}>
						{'Updated: '}
						{termsAndConditions.CreationDate}
					</Typography>
				)}
				<Authorization profile={userProfile} allowedRoles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR]}>
					<ButtonGroup component='span' size='small' variant='outlined' color='primary' className={classes.buttonsGroupContainer}>
						{termsAndConditions === null && (
							<Tooltip component='span' title='Add Terms and conditions'>
								<Button className={classes.buttonsGroup} onClick={() => props.onOpenAttanchemtDialog('', '')}>
									<AddIcon />
								</Button>
							</Tooltip>
						)}
						{termsAndConditions && (
							<Tooltip component='span' title='Edit terms and conditions'>
								<Button
									className={classes.buttonsGroup}
									onClick={() =>
										props.onOpenAttanchemtDialog(
											termsAndConditions ? termsAndConditions.Attachment.Comments : '',
											termsAndConditions ? termsAndConditions.Token : ''
										)
									}
								>
									<EditIcon />
								</Button>
							</Tooltip>
						)}
					</ButtonGroup>
				</Authorization>
			</div>
		)
	}

	const onRenderContent = () => {
		if (isLoading) {
			return <DesktopViewSkeleton />
		} else {
			return (
				<div className={classes.rootIndex}>
					<Toolbar
						title={
							termsAndConditions === null
								? 'Terms and conditions'
								: `Terms and conditions | Revision: ${termsAndConditions.Review} | Validator: ${termsAndConditions.CreatorName}`
						}
						renderContentRight={renderContentRight()}
						icon={termsIcon}
					/>
					<div className={classes.container}>
						{termsAndConditions && termsAndConditions.UrlDoument && !isSaving ? (
							<Iframe
								url={`${window.config.serviceManagementBackendUrl}/${termsAndConditions.UrlDoument}/${termsAndConditions.Attachment.FileName}`}
								className={classes.container}
								width='100%'
								id='myId'
							/>
						) : (
							<AlertComponent
								title='Information'
								severityType='info'
								message={isSaving ? 'LOADING FILE OF TERMS AND CONDITIONS' : 'THERE IS NO FILE OF TERMS AND CONDITIONS'}
							/>
						)}
					</div>
				</div>
			)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Terms and Conditions</Typography>
					</HeaderBreadCrum>
				}
			/>
			<Content key={'AuthenticatedContent'}>{onRenderContent()}</Content>
		</Layout>
	)
}

export default withImmutablePropsToJS(IndexDesktopView)
