import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'

/**Import styles section */

/**Import Material Ui section */
import {
    Grid, CardContent, CardHeader, Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab'
import PersonIcon from '@material-ui/icons/Person';
import HystoryIcon from '@material-ui/icons/History';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import NotesIcon from '@material-ui/icons/SpeakerNotes';

const HistorialComponent = (props) => {
	const {Workflows} = props

	return (
		<Grid>
            <Grid item xs={12} >
                {Workflows.map((workflow) => {
                    return(
                        <HistoryCard 
                            key={workflow.Token}
                            Workflow={workflow}
                        />
                )})}
            </Grid>
		</Grid>
	)
}
HistorialComponent.propTypes = {
}
HistorialComponent.defaultProps = {
    Workflows:[],
}

const getDescription = (actionType) => {
    switch (actionType) {
        case "WORKFLOW_SEND_VALIDATE":
            return "SEND TO VALIDATE"
        case "WORKFLOW_VALIDATE":
            return "VALIDATE IN PLANT"
        case "WORKFLOW_REJECTED":
            return "REJECTED IN PLANT"
        case "WORKFLOW_REMOVED":
            return "SERVICE REMOVED"
        default:
            return null
    }
}

const getColorIcon = (actionType) => {
    switch (actionType) {
        case "WORKFLOW_SEND_VALIDATE":
            return "#FDDD0E"
        case "WORKFLOW_VALIDATE":
            return "#3AC47D"
        case "WORKFLOW_REJECTED":
        case "WORKFLOW_REMOVED":
            return "#D92550"
        default:
            return null
    }
};


const HistoryCard = (props) => {
	const { Workflow} = props
	return (
        <CardContent>
            <CardHeader
                style={{ paddingBottom: '0px', paddingTop: '5px', backgroundColor: '#e8eff2f6' }}
                title={
                    <Typography gutterBottom variant="subtitle1"
                        component="span" color="textPrimary">
                        <HystoryIcon color='primary'  style={{ marginBottom: '-5px', paddingRight: "5px", color: getColorIcon(Workflow.ActionType) }}/>
                        {getDescription(Workflow.ActionType)}
                    </Typography>
                }
            />
            <CardHeader
                title={
                    <Typography gutterBottom variant="body2" component="p">
                        <CalendarIcon color='secondary' style={{ marginBottom: '-5px', paddingRight: "5px" }}/>
                        {` Date: ${moment(Workflow.CreationDate).format("MMMM Do YYYY, h:mm:ss a")}`}
                    </Typography>
                }
                subheader={
                    <>
                        <Typography gutterBottom variant="body2" component="p">
                            <PersonIcon color='secondary' style={{ marginBottom: '-5px', paddingRight: "5px"}} /> {Workflow.CreatorName}
                        </Typography>
                        <Alert
                            variant='outlined'
                            severity='info'
                            icon={<NotesIcon fontSize='inherit' />}
                        >
                            <AlertTitle>Comments</AlertTitle>
                            {`${Workflow.Comments}`}
                        </Alert>
                    </>
                }
            />
        </CardContent>
	)
}

HistoryCard.propTypes = {
	Workflow: propTypes.shape({
	})
	
}
HistoryCard.defaultProps = {
	Workflow: {
	},
}


export default HistorialComponent
