/**
 * Define Register style
 * 
 */
import { makeStyles } from '@material-ui/styles'


export const desktopViewRegisterStyles = makeStyles((theme) => ({
    root: {
        maxWidth: theme.views.maxWidth,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        maxHeight: `calc(100vh - ${85}px)`,
        minHeight: `calc(100vh - ${85}px)`,
        overflowY: 'auto',
    },
    containerTerm:{
        maxHeight: `calc(100vh - ${50}px)`,
        minHeight: `calc(100vh - ${310}px)`,
    },
    searchIndex: {
        height:"13px",
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    buttonsGroupContainer: {
        marginRight: theme.spacing(1),
        boxShadow: "0px 0px 0px 0px",
    },
    buttonsGroup: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        "&:hover": {
            backgroundColor: theme.palette.colors.primarySelected,
            color: theme.palette.common.white,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.greenDisabled,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.MuiButton-root": {
            minWidth:"14px !important",
            height:"2rem !important",
        },
    },
    buttonsSend: {
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        "&:hover": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout}`,
        },
        "&.MuiButton-root": {
            minWidth:"14px !important",
            height:"2rem !important",
        },
    },
    /**-----Card content stepper styles */
    cardContainer:{
        display: 'grid',
		gridTemplateRows: 'auto 50px',
    },
    cardContent:{
        gridColumn: 1,
        gridRow: 1,
        minHeight: `calc(100vh - ${260}px)`,
        maxHeight: `calc(100vh - ${260}px)`,
        overflowY:"auto"
    },
    actionButton: {
		display: 'flex',
        justifyContent: 'flex-end',
        gridColumn: 1,
		gridRow: 2,
    },
    acceptTermsContainer:{
        marginLeft: "auto",
        marginRight: "auto",
        width:"400px"
    },
    button:{
        backgroundColor:  `${theme.palette.colors.purple}!important`,
    },
    actionButtonGrandAccess: {
		display: 'flex',
        justifyContent: 'flex-end',
    }
    /**----------------------------------------------*/
}))