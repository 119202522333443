import { makeStyles } from '@material-ui/styles'

/**
 * avatar styles
 */
export const useCardAvatarStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateRows: 'auto auto auto ',
        maxWidth: "162px",
        maxHeight: "210px",
        minHeight: "210px",
        padding: "5px",
        cursor: "pointer"
    },
    rootSelected: {
        display: "grid",
        gridTemplateRows: 'auto auto auto ',
        maxWidth: "162px",
        maxHeight: "210px",
        minHeight: "210px",
        padding: "5px",
        cursor: "pointer",
        backgroundColor:theme.palette.secondary.main
    },
    avatarContainer: {
        gridColumn: 1,
        gridRow: 2,
        display: "flex",
        alignSelf: "center",
        justifyContent: "center"
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        backgroundColor: theme.palette.primary.main,
        border: "5px solid transparent",
        borderColor: theme.palette.secondary.main
    },
    textContainer: {
        gridColumn: 1,
        gridRow: 3,
        maxWidth: "145px",
    },
    actionsButton: {
        gridColumn: 1,
        gridRow: 1,
        maxWidth: "130px",
    },
    popperRoot: {
        padding: "10px",
        width: "13rem",
        marginTop: "0.9rem",
        zIndex: '999',
    },
    papperContainer: {
        width: "15rem",
        maxHeight: "300px",
        overflowY: 'auto'
    }

}))
