import React from 'react'
import { Typography } from '@material-ui/core'
import { mobileUseStyle as useStyles } from '../../../resources/styles/AnonymousStyles'

/**
 * Anonymous mobile view
 */
const AnonymousMobileView = (props) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography variant='h3' gutterBottom color='secondary'>
					Welcome to Imbera's portal.
				</Typography>
				<Typography variant='subtitle1' gutterBottom>
					Here you can access all the tools you need for your
					Imbera products, from opening a warranty service
					ticket to researching parts.
				</Typography>
			</div>
		</div>
	)
}

export default AnonymousMobileView
