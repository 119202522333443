/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/**Import components section */
import UserInProviderDesktopView from './components/UserInProviderDesktopView'
import Create from './Create'
import Edit from './Edit'
import Toaster from '../common/Toaster'
import { generatedNewPassword } from '../../store/helpers/PasswordHelper'

/**Import actions sections */
import {
    loadUserInServiceProvider,
    setInternalQueryParameters,
    updateLastDateUser,
    editActiveNotifications,
} from '../../store/serviceproviders/serviceProvidersActions'
import {
    providersUsersSelector,
    queryParametersSelector
} from '../../store/serviceproviders/ServiceProviderSelector'

/**Import actions sections */
import {
    removeUserFromAccount,
    activeUserExpiredToSingPortal,
    resendPasswordInvitation,
} from '../../store/users/UsersActions'

const UsersInProviderIndex = (props) => {
    /**Hooks sections */
    const dispatch = useDispatch()
    let location = useLocation()
    let history = useHistory()
    const code = props.match.params.code;
    const name = props.match.params.name;

    /**
     * Selectors sections use selector section
     */
    const providersUsers = useSelector(providersUsersSelector)
    const queryParameters = useSelector(queryParametersSelector)

    /**Get data from redux store use selector */
    const isLoading = useSelector((state) => state.serviceProviders.get('isLoadingProviders'))
    const isSaving = useSelector((state) => state.serviceProviders.get('isSaving'))
    const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
    const loggedUserProfile = useSelector((state) => state.session.get('profile'))

    const [pagination, setPagination] = useState({ pageSize: 5, total: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [allUserPaginated, setAllUsersPaginated] = useState([]);
    const [isOpenLateralPanelAdd, setIsOpenLateralPanelAdd] = useState({ openend: false });
    const [userInServiceProviderEdit, setUserInServiceProviderEdit] = useState({ opened: false, user: null });

    /**Defined mode view */
    const [gridMode, setGridMode] = useState(false);
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, user: null });
    const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false);
    const [userActiveExpiredConfing, setUserActiveExpiredConfing] = useState({ opened: false, user: null });
    const [resendPasswordDialog, setResendPasswordDialog] = useState({ opened: false, user: null });

    /**
     * Load internal user
     */
    useEffect(() => {
        const queryParameters = queryString.parse(location.search)
        if (!!queryParameters.pageNumber) {
            setCurrentPage(queryParameters.pageNumber)
        }
        dispatch(loadUserInServiceProvider(queryParameters, code))
    }, [dispatch, location.search, code])

    /**
     * Here we save and switch view mode grid|list and persist on local storage.
     */
    useEffect(() => {
        if (gridMode === false) {
            setGridMode(false);
        }
        else {
            setGridMode(true);
        }
    }, [gridMode]);

    useEffect(() => {
        let usersPerPage = 10;
        let total = providersUsers.length / usersPerPage;
        let allTotal = Math.round(Math.ceil(total))
        const indexOfLastUser = currentPage * usersPerPage;
        let indexOfFirtsUser = indexOfLastUser - usersPerPage;
        let currentUsers = providersUsers.slice(indexOfFirtsUser, indexOfLastUser)
        setAllUsersPaginated(currentUsers)
        setPagination({ pageSize: usersPerPage, total: allTotal });
    }, [providersUsers, pagination.total, currentPage])

    const updateSearchQuery = (searchQuery) => {
        const queryParams = queryString.parse(location.search)
        if (searchQuery) queryParams.query = searchQuery
        else delete queryParams.query
        history.push({ search: queryString.stringify(queryParams) })
        dispatch(setInternalQueryParameters(queryParams))
    }

    /**
     * Update sort criteria
     */
    const updateSortCriteria = (criteria) => {
        const queryParams = queryString.parse(location.search)
        queryParams.sortBy = criteria.by
        queryParams.sortDirection = criteria.direction
        history.push({ search: queryString.stringify(queryParams) })
        dispatch(setInternalQueryParameters(queryParams))
    }

    /**Change mode view in users */
    const switchViewMode = () => {
        if (gridMode === false) {
            setGridMode(true);
        } else {
            setGridMode(false);
        }
    }

    /**
    * On change page number
    */
    const onChangePage = (event, page) => {
        const queryParams = queryString.parse(location.search)
        queryParams.pageNumber = page
        setCurrentPage(page)
        history.push({ search: queryString.stringify(queryParams) })
    }

    /**Open lateral menu for add an internal user */
    const onAddUserInProviderPanelConfig = () => {
        setIsOpenLateralPanelAdd({ opened: !isOpenLateralPanelAdd.opened })
    }

    const onEditPanelUserConfig = (user) => {
        setUserInServiceProviderEdit({ opened: !userInServiceProviderEdit.opened, user: user })
    }

    /**
     * Remove an user fron this list user for company IMBERA
     */
    const onRemoveUserFromAccount = () => {
        dispatch(removeUserFromAccount(passwordDialogConfiguration.user.Token, code))
            .then(() => {
                onSetPasswordDialogConfiguration()
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error removing user from account", variant: 'error' })
                console.error('Error removing user from account', error);
            });
    };

    /**Open/close dialog confirm dialog user*/
    const onSetPasswordDialogConfiguration = (user) => {
        setPasswordDialogConfiguration({ opened: !passwordDialogConfiguration.opened, user: user });
    };

    /* Open/close dialog confirm resend password */
    const onSetResendPasswordDialog = (user) => {
        setResendPasswordDialog({ opened: !resendPasswordDialog.opened, user: user });
    }

    /**Open/close dialog modal for upload logo user */
    const onSetHandleOpenCloseModalUploadImg = (user) => {
        setHandleOpenModalUploadImg(!handleOpenModalUploadImg);
        if (user) {
            setUserInServiceProviderEdit({ opened: true, user: user })
        }
    };

    /**Open/close dialog confirm dialog user to active expired user */
    const onSetUserActiveExpiredDialog = (user) => {
        setUserActiveExpiredConfing({ opened: !userActiveExpiredConfing.opened, user: user });
    };

    /** Function to active/deactive notifications for selected user */
    const onActiveNotifications = (token, value) => {
        dispatch(editActiveNotifications(token, value))
    }


    /**
     * Function to actuve user expired in portal 
     */
    const onActiveUserExpired = () => {
        dispatch(activeUserExpiredToSingPortal(userActiveExpiredConfing.user.Id))
            .then((response) => {
                onSetUserActiveExpiredDialog()
                dispatch(updateLastDateUser(response))
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error active user expired in portal", variant: 'error' })
                console.error('Error active user expired', error);
            });
    };

    /**
     * Function dialog to resend user password 
     */
    const onResendPasswordUser = () => {
        let newPassword = generatedNewPassword();
        let resendPasswordUserUpdate = resendPasswordDialog.user;
        dispatch(resendPasswordInvitation(resendPasswordUserUpdate, newPassword))
            .then((response) => {
                onSetResendPasswordDialog()
                setToasterOptions({ open: true, message: "Password was sent successfully", variant: 'success' })
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "An error occurred when sending the password, please try again", variant: 'error' })
                console.error('Error active user expired', error);
            });
    }



    return (
        <>
            <UserInProviderDesktopView
                users={allUserPaginated}
                isLoading={isLoading}
                pagination={pagination}
                searchValue={queryParameters ? queryParameters.query : ''}
                sortBy={queryParameters ? queryParameters.sortBy : null}
                sortDirection={queryParameters ? queryParameters.sortDirection : null}
                onChangeSearchValue={(query) => updateSearchQuery(query)}
                onChangeSortingCriteria={(sortCriteria) =>
                    updateSortCriteria(sortCriteria)
                }
                switchViewMode={switchViewMode}
                gridMode={gridMode}
                onChangePage={onChangePage}
                openLateralPanelOnClick={onAddUserInProviderPanelConfig}
                onEditPanelUserConfig={onEditPanelUserConfig}
                loggedUserProfile={loggedUserProfile}
                name={`Service providers / ${name}`}
                isSaving={isSaving}
                onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                onSetResendPasswordDialog={onSetResendPasswordDialog}
                onSetUserActiveExpiredDialog={onSetUserActiveExpiredDialog}

            />
            <Create
                addUserInProviderPanelConfig={isOpenLateralPanelAdd}
                onAddUsersProvideOpenClosePanel={onAddUserInProviderPanelConfig}
                isSaving={isSaving}
            />
            <Edit
                passwordDialogConfiguration={passwordDialogConfiguration}
                resendPasswordDialog={resendPasswordDialog}
                isValidatingPassword={isValidatingPassword}
                onEditPanelUserConfig={onEditPanelUserConfig}
                userInServiceProviderEdit={userInServiceProviderEdit}
                isSaving={isSaving}
                onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                onSetResendPasswordDialog={onSetResendPasswordDialog}
                onRemoveUserFromAccount={onRemoveUserFromAccount}
                isModeServiceProvider={true}
                handleOpenModalUploadImg={handleOpenModalUploadImg}
                onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                onSetUserActiveExpiredDialog={onSetUserActiveExpiredDialog}
                userActiveExpiredConfing={userActiveExpiredConfing}
                onActiveUserExpired={onActiveUserExpired}
                onResendPasswordUser={onResendPasswordUser}
                onActiveNotifications={onActiveNotifications}
            />
            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

export default UsersInProviderIndex
