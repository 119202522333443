import { currencyFormat } from '../../../store/helpers/AmountHelper'
import moment from 'moment'

export const getDatagridSummaryConfiguration = () => {
	let config = [...dataGridConfiguration]
	return config
}

export const dataGridConfiguration = [
	{
		title: ''
	},
	{
		title: 'Folio',
		dataSource: 'Folio'
	},
	{
		title: 'Creation date',
		dataSource: 'CreationDate',
		onRenderProperty: (item) =>
			item.CreationDate
				? moment(item.CreationDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
				: ''
	},
	{
		title: 'Invoices',
		dataSource: 'InvoiceFolio'
	},
	{
		title: 'Status',
		dataSource: 'Status',
		onRenderProperty: (item) => {
			return getStatusDescription(item.Status)
		}
	},
	{
		title: 'Service Provider',
		dataSource: 'ServiceProviderName',
		onRenderProperty: (item) => {
			return item.ServiceProvider
				? item.ServiceProvider.Name
				: 'Unassigned'
		}
	},
	{
		title: 'Total',
		dataSource: 'TotalAmount',
		onRenderProperty: (item) => {
			return currencyFormat(item.TotalAmount)
		}
	}
]

export const getStatusDescription = (statusId) => {
	switch (statusId) {
		case 1:
			return 'Draft'
		case 2:
			return 'Submitted'
		case 4:
			return 'Rejected'
		case 5:
			return 'Accepted'
		case 6:
			return 'Paid'
		default:
			return 'Undefined'
	}
}
