import React from 'react'
import classnames from 'classnames'

/** Import components */
import { Button, Typography } from '@material-ui/core'
import { unauthorizedUseStyles as useStyles } from '../../../resources/styles/ErrorViewStyles'

/**
 * Unauthorized view
 */
const DesktopView = (props) => {
	const unAuthClasses = useStyles()

	return (
        <div className={classnames(unAuthClasses.content, unAuthClasses.root)}>
            <div className={unAuthClasses.leftPanel}>
                <Typography color='secondary' variant='h2' gutterBottom>
                    Access denied
                </Typography>
                <Typography color='inherit' variant='body2' gutterBottom>
                    You don't have permission to access this resource.
                </Typography>
                <Typography color='inherit' variant='body2' gutterBottom>
                    Please feel free to give us a call if you have any
                    questions.
                </Typography>
                <Button color='secondary' onClick={props.onLogout}>
                    Change user's credentials
                </Button>
            </div>
        </div>
	)
}
export default DesktopView
