/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

/** MapWrapper */
import {  PositionMapDefault } from '../../../store/helpers/AppConstants';
import MapWrapper from './MapWrapper';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyB-XC24Vm9GJKhQ9ZFqVUvyIPj2HVCumSQ");
Geocode.enableDebug();

/**
 * Conponent to show Map gelocalitaion
 * @param {*} props 
 */
const MapComponent = (props) => {
    const { onChangeAddress, onChangePosition, position, fullAddress, directions, modeCreate} = props;
    const [address, setAddress] = useState(fullAddress)
    const[markerPosition, setMarkerPosition] = useState({lat:0, lng: 0})

    const [defaultZoom, setDefaultZoom] = useState(12);
    useEffect(() => {
        if(directions.length >6){
            setDefaultZoom(3)
        }
        else if(directions.length >4 && directions.length <=6){
            setDefaultZoom(8)
        }
        else if(directions.length >2 && directions.length <=4){
            setDefaultZoom(10)
        }
        else if(directions.length >=0 && directions.length <=2){
            setDefaultZoom(10)
        }
    }, [directions])


    useEffect(() => {
        if(markerPosition){
            if (position.latitude !== markerPosition.lat) {
                setMarkerPosition({lat : position.latitude, lng : position.longitude })
                setAddress(fullAddress)
           }
        }
    }, [markerPosition, position, fullAddress])

    /**
	 * When the user types an address in the search text box
	 * @param place
	 */
	const onSearchPlaceSelected = (place) => {
        if(place.address_components && place.geometry){
            updateAddress(place);
            let latValue = place.geometry.location.lat();
            let lngValue = place.geometry.location.lng();
            setMarkerPosition({lat: latValue, lng: lngValue})
            if(onChangePosition)
                onChangePosition({lat: latValue, lng: lngValue });
        };
    }

    /**
     * Function on update address selected
     * @param {*} place 
     */
    const updateAddress = (place) =>{
        let addressComponents =  place.address_components;
        let fullAddress = place.formatted_address;
        let addAddress = {
            street_number: '',
            route:'', //address
            locality: '',
            sublocality: '',
            country: '',
            administrative_area_level_1: '', // state
            administrative_area_level_3: '', // location aditional
            postal_code:'',
        }
        addressComponents.forEach(address => {
            addAddress = getAddress(addAddress, address, "street_number");
            addAddress = getAddress(addAddress, address, "route");
            addAddress = getAddress(addAddress, address, "locality");
            addAddress = getAddress(addAddress, address, "sublocality");
            addAddress = getAddress(addAddress, address, "country");
            addAddress = getAddress(addAddress, address, "administrative_area_level_1");
            addAddress = getAddress(addAddress, address, "postal_code");
            addAddress = getAddress(addAddress, address, "administrative_area_level_3");
        });
        setAddress(fullAddress) 
        if(onChangeAddress){
            if(!addAddress.locality && addAddress.sublocality){
                addAddress["locality"] = addAddress.sublocality;
            }
            if(!addAddress.locality && addAddress.administrative_area_level_3){
                addAddress["locality"] = addAddress.administrative_area_level_3;
            }
            if(!addAddress.locality && addAddress.administrative_area_level_1){
                addAddress["locality"] = addAddress.administrative_area_level_1;
            }
            onChangeAddress(addAddress);
        }
    }
    
    /**
     * get address from update place selected
     * @param {*} addAddress 
     * @param {*} address 
     * @param {*} type 
     */
    const getAddress = (addAddress, address, type) =>{
        let verifyAddress = address.types.find(u => u === type);
        if(verifyAddress){
            if(type==='country' && ( address.long_name && address.long_name ==="Estados Unidos") )
                addAddress[type] = "EE. UU.";
            else
                addAddress[type] = address.long_name;
        }
        return addAddress;
    }

    /**
     * On selected place with marker drag
     * @param {*} event 
     */
    const onMarkerDragEnd = ( event ) => {
		let newLat = event.latLng.lat();
		let newLng = event.latLng.lng();
		Geocode.fromLatLng( newLat , newLng ).then(
			response => {
                if(response.results){
                    let getFirtAddress =  response.results[0];
                    if(getFirtAddress.address_components && getFirtAddress.geometry){
                        updateAddress(getFirtAddress);
                        let latValue = getFirtAddress.geometry.location.lat;
                        let lngValue = getFirtAddress.geometry.location.lng;
                        setMarkerPosition({lat: latValue, lng: lngValue})
                        if(onChangePosition)
                            onChangePosition({lat: latValue, lng: lngValue });
                    }
                }
			 },
			error => {
				console.error(error);
			}
		);
    };

    /**
     * Action on set direction for list direccions reteail
     * @param {*} address 
     */
    const setDirectionAddress = (address) =>{
        if(onChangeAddress)
            onChangeAddress(address);
    }

    /**
     * Render component
     */
    return (
        <MapWrapper 
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-XC24Vm9GJKhQ9ZFqVUvyIPj2HVCumSQ&v=3.exp&libraries=geometry,drawing,places&sensor=false"
            isMarkerShown
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height:`300px`, marginTop: modeCreate ? "50px" : "" }} />}
            mapElement={<div style={{ height: `100%` }} />}
            directions={directions}
            onChangeAddress={setDirectionAddress}
            markerPosition={markerPosition }
            onPlaceSelected={ onSearchPlaceSelected }
            fullAddress={address}
            onMarkerDragEnd={onMarkerDragEnd}
            modeCreate={modeCreate}
            defaultZoom={defaultZoom}
        />
    )
}

MapComponent.propTypes = {
    /**Function invoke callback for set properties in the state local from address*/
    onChangeAddress: propTypes.func,
    onChangePosition:propTypes.func,
    /** object to get position defautl from map */
    position: propTypes.object.isRequired,
    fullAddress: propTypes.string,
}
MapComponent.defaultProps = {
    fullAddress: '',
    position: {latitude: PositionMapDefault.latitude, longitude: PositionMapDefault.longitude},
    directions:[],
    onChangeAddress: () =>
        console.warn('Callback [onChangeAddress] no defined'),
    onChangePosition: () =>
        console.warn('Callback [onChangePosition] no defined'),
        
}
export default MapComponent
