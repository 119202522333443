/**Import react section */
import React from 'react'
import { useForm } from 'react-hook-form'
import propTypes from 'prop-types'

/**Import Material UI Section */
import { Grid, Typography, Card, Chip } from '@material-ui/core'

/**Import componets section */
import ReactSelectAvatar from '../../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'
import ReactSelectCommon from '../../../common/ReactSelectCommon'
import MapComponet from '../../../services/components/MapComponent'
import Address from './Address'
import ButtonSaved from '../../../common/Buttons/ButtonSaved'
import ButtonCancel from '../../../common/Buttons/ButtonCancel'

/**import resources section */
import { useCreateDesktopStyle } from '../../resources/sparePartManualStyles'
import { GetServiceType, GetWarrantyType } from '../../../../store/helpers/SelectOptions'
import { SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN } from '../../../../resources/RolesEnum'
import { hasAuthorization } from '../../../../store/session/sessionActions'

const HeaderFormCreate = (props) => {
    const classes = useCreateDesktopStyle();

    const { register, handleSubmit, errors, control } = useForm()

    /**Functions section */
    const { onSelectChange, onChangePosition, onChangeAddress,
        redirectToSpartPart, addSparePartOnClick, onPropertyChange, } = props

    /**objects section */
    const { mapPostionModel, serviceModel } = props

    /**Array sections props */
    const { allDirections, serviceProvidersOptions } = props
    /** */
    const { isSaving } = props;
    const serviceType = GetServiceType()

    return (
        <Grid container spacing={1} >
            <HeaderComponent
                register={register}
                errors={errors}
                control={control}
                onSelectChange={onSelectChange}
                serviceProvidersOptions={serviceProvidersOptions}
                sparePartModel={serviceModel}
                serviceType={serviceType}
                userProfile={props.userProfile}
            />
            <br />
            <br />
            <Card style={{ padding: "20px", }}>
                <Typography variant='overline'> <strong>{'Address'}</strong> </Typography>
                <Grid item xs={12} md={12}>
                    <Typography variant='overline'> {'Find your address'} </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <MapComponet
                                position={mapPostionModel}
                                onChangePosition={onChangePosition}
                                onChangeAddress={onChangeAddress}
                                directions={allDirections}
                                modeCreate={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ marginTop: "-8px" }}>
                            <Address
                                addressModel={
                                    serviceModel
                                        ? serviceModel.Address
                                        : {}
                                }
                                onPropertyChange={onPropertyChange}
                                serviceModel={serviceModel}
                                mode={'MODE_CREATE'}
                                register={register}
                                errors={errors}
                            />
                        </Grid>

                    </Grid>

                    <div className={classes.buttonActions}>
                        <ButtonCancel
                            disabled={isSaving}
                            onCancelClick={
                                redirectToSpartPart
                            }
                            title='Cancel'
                        />
                        <ButtonSaved
                            isSaving={isSaving}
                            onSavedClick={handleSubmit(
                                addSparePartOnClick
                            )}
                            title='CREATE'
                        />
                    </div>

                </Grid>
            </Card>
        </Grid>
    )
}

const HeaderComponent = (props) => {
    /**Functions props*/
    const { onSelectChange } = props
    /**Objects section */
    const { sparePartModel, serviceType } = props;
    /**Array options */
    const { serviceProvidersOptions } = props;

    /**Register properties for validate information react hook form */
    const { register, errors, control } = props;

    let isUserProvider = hasAuthorization(props.userProfile, [SERVICE_PROVIDER_MANAGER, SERVICE_PROVIDER_TECHNICIAN])

    return (
        <Grid item xs={12}>
            <Card style={{ padding: "20px", marginLeft: "-3px", marginRight: "-3px" }}>
                <Grid container spacing={1}>
                    {
                        !isUserProvider &&
                        <Grid item xs={12} sm={4}>
                            <ReactSelectAvatar
                                id='Provider'
                                name='Provider'
                                placeholder={'Service provider'}
                                options={serviceProvidersOptions}
                                formatOptionLabel={formatOptionLabel}
                                onChange={onSelectChange}
                                value={
                                    sparePartModel
                                        ? sparePartModel.Provider
                                        : null
                                }
                                errors={errors}
                                control={control}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={isUserProvider ? 6 : 4}>
                        <ReactSelectCommon
                            id='customerType'
                            name='CustomerType'
                            placeholder={'Customer type'}
                            options={serviceType}
                            value={
                                sparePartModel ? sparePartModel.CustomerType : null
                            }
                            onChange={onSelectChange}
                            control={control}
                            errors={errors}
                            register={
                                sparePartModel && !!sparePartModel.CustomerType
                                    ? register(
                                        { name: 'CustomerType' },
                                        { required: { value: false } }
                                    )
                                    : register(
                                        { name: 'CustomerType' },
                                        {
                                            required: {
                                                value: true,
                                                message:
                                                    'The customer type is required'
                                            }
                                        }
                                    )
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={isUserProvider ? 6 : 4}>
                        <ReactSelectCommon
                            id='warranryType'
                            name='WarrantyType'
                            placeholder={'Warranty type'}
                            options={GetWarrantyType()}
                            value={
                                sparePartModel ? sparePartModel.WarrantyType : null
                            }
                            onChange={onSelectChange}
                            control={control}
                            errors={errors}
                            register={
                                sparePartModel && !!sparePartModel.WarrantyType
                                    ? register(
                                        { name: 'WarrantyType' },
                                        { required: { value: false } }
                                    )
                                    : register(
                                        { name: 'WarrantyType' },
                                        {
                                            required: {
                                                value: true,
                                                message:
                                                    'The warranty type is required'
                                            }
                                        }
                                    )
                            }
                        />
                    </Grid>

                </Grid>
            </Card>
        </Grid>
    )
}

HeaderFormCreate.propTypes = {
    /**List of service Providers */
    serviceProviders: propTypes.array.isRequired,
    /**List of customers */
    customers: propTypes.array.isRequired,
    isSaving: propTypes.bool.isRequired,
    /**Function invoke callback for create a spart part manual */
    onCreateSpartPartRequestsManual: propTypes.func.isRequired,
    /**Redirects to the spare part view when you don't want to create a new spare part*/
    onRedirectToHome: propTypes.func.isRequired
}

HeaderFormCreate.defaultProps = {
    isSaving: false,
    serviceProviders: [],
    customers: [],
    onCreateSpartPartRequestsManual: () => console.warn('Callback [createService] no defined'),
    onRedirectToHome: () =>
        console.warn('Callback [onRedirectToHome] no defined')
}

const formatOptionLabel = ({ value, label, avatarUrl, isAvatarFromIdentity }) => (
    <div>
        <Chip
            id={value}
            size="small"
            avatar={
                <AvatarCustomSize
                    avatarUrl={avatarUrl}
                    name={label}
                    isAvatarFromIdentity={isAvatarFromIdentity}
                />}
            label={label}
            clickable
            style={{ color: "white", background: "#5357DC" }}
        />
    </div>
);

export default HeaderFormCreate