/**Import react section */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'

/**Import components */
import { isNullOrEmpty } from '../../store/helpers/StringHelper'
import DesktopViewRegister from './components/DesktopViewRegister'
import AddRegister from './components/AddRegister'
import Toaster from '../common/Toaster'
/**import actions section */
import {
	loadUserByUserName,
	loadInternalUsers
} from '../../store/users/UsersActions'
import {
	loadServiceProvider,
	sendToAuthorizer,
	getTermsAndConditions,
	moveBackwardStep,
	moveForwardStep,
	acceptTermsGuarantee
} from '../../store/serviceproviders/serviceProvidersActions'
import { PROVIDER_REGISTRATION_VALIDATOR } from '../../resources/RolesEnum'
import { getEmailUsersByRole } from '../../store/helpers/UserHelper'
import { toSafeObject } from '../../store/helpers/StateHelper'

const IndexRegister = () => {
	/**Hooks sections */
	const dispatch = useDispatch()
	let history = useHistory()
	let match = useRouteMatch()
	const token = match.params.token

	const [openEvidenceDialog, setOpenEvidenceDialog] = useState({
		opened: false
	})
	/**Service Provider Selector */
	const isLoading = useSelector((state) =>
		state.serviceProviders.get('isLoadingProviders')
	)
	const isSaving = useSelector((state) =>
		state.serviceProviders.get('isSaving')
	)
	const serviceProvider = useSelector((state) =>
		state.serviceProviders.get('serviceProvider')
	)
	console.log(
		'🚀 ~ file: IndexRegister.js:34 ~ IndexRegister ~ serviceProvider:',
		serviceProvider
	)
	const activeStep = useSelector((state) =>
		state.serviceProviders.get('activeStep')
	)
	const isFowardEnabled = useSelector((state) =>
		state.serviceProviders.get('isFowardEnabled')
	)
	const isBackwardEnabled = useSelector((state) =>
		state.serviceProviders.get('isBackwardEnabled')
	)
	const userProfile = useSelector((state) => state.session.get('profile'))
	const [toasterOptions, setToasterOptions] = useState({
		open: false,
		variant: null,
		message: null
	})

	const [
		passwordDialogConfiguration,
		setPasswordDialogConfiguration
	] = useState({ opened: false, attachment: null })
	const [passwordDialogAccess, setPasswordDialogAccess] = useState({
		opened: false,
		isGrantedAccess: false
	})
	const [administrator, setAdministrator] = useState(null)

	const termsAndConditions = useSelector((state) =>
		toSafeObject(state.serviceProviders.get('termsAndConditions'))
	)
	const internalUsers = useSelector((state) =>
		toSafeObject(state.users.get('internalUsers'))
	)
	let emailsRoleValidator = getEmailUsersByRole(
		PROVIDER_REGISTRATION_VALIDATOR,
		internalUsers
	)

	/**
	 * Load provider
	 */
	useEffect(() => {
		dispatch(loadServiceProvider(token))
		dispatch(getTermsAndConditions())
		dispatch(loadInternalUsers())
	}, [dispatch, token])

	/**
	 * Load administrator from provider
	 */
	useEffect(() => {
		if (serviceProvider && serviceProvider.get('UserName')) {
			dispatch(loadUserByUserName(serviceProvider.get('UserName'))).then(
				(user) => {
					setAdministrator(user)
				}
			)
		}
	}, [dispatch, serviceProvider])

	const onOpenEvidenceDialog = () => {
		setOpenEvidenceDialog({ opened: !openEvidenceDialog.opened })
	}

	const onOpenDialogPassword = (attachment) => {
		setPasswordDialogConfiguration({
			opened: !passwordDialogConfiguration.opened,
			attachment: attachment
		})
	}

	const onOpenDialogForGrantedAccess = (isGrantedAccess) => {
		setPasswordDialogAccess({
			opened: !passwordDialogAccess.opened,
			isGrantedAccess: isGrantedAccess
		})
	}

	/**
	 * Here we dispatch the action for go to next step.
	 */
	const onMoveBackWardHandler = () => {
		dispatch(moveBackwardStep())
	}

	const onMoveForwardHandler = () => {
		dispatch(moveForwardStep())
	}

	const onRedirectToServiceProvider = () => {
		history.push(`/serviceProviders`)
	}

	/**
	 * Function to send document to authrozer
	 * @param {*} token
	 */
	const onSenToAutorizer = (token) => {
		return dispatch(sendToAuthorizer(token, emailsRoleValidator))
			.then((_) => {
				onMoveForwardHandler()
				setToasterOptions({
					open: true,
					message: 'Invitation re-send to provider correctly',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToasterOptions({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to update service provider',
					variant: 'warning'
				})
			})
	}

	const onAcceptTermsGuarantee = (token, acceptedTerms) => {
		return dispatch(acceptTermsGuarantee(token, acceptedTerms))
			.then((_) => {})
			.catch((error) => {})
	}

	return (
		<>
			<DesktopViewRegister
				onOpenEvidenceDialog={onOpenEvidenceDialog}
				isLoading={isLoading}
				isSaving={isSaving}
				serviceProvider={serviceProvider}
				onOpenDialogPassword={onOpenDialogPassword}
				onSenToAutorizer={onSenToAutorizer}
				onMoveBackWardHandler={onMoveBackWardHandler}
				onMoveForwardHandler={onMoveForwardHandler}
				activeStep={activeStep}
				isFowardEnabled={isFowardEnabled}
				isBackwardEnabled={isBackwardEnabled}
				onAcceptTermsGuarantee={onAcceptTermsGuarantee}
				onOpenDialogForGrantedAccess={onOpenDialogForGrantedAccess}
				userProfile={userProfile}
				onRedirectToServiceProvider={onRedirectToServiceProvider}
				termsAndConditions={termsAndConditions}
			/>
			<AddRegister
				openEvidenceDialog={openEvidenceDialog}
				onOpenEvidenceDialog={onOpenEvidenceDialog}
				serviceProvider={serviceProvider}
				onOpenDialogPassword={onOpenDialogPassword}
				passwordDialogConfiguration={passwordDialogConfiguration}
				onOpenDialogForGrantedAccess={onOpenDialogForGrantedAccess}
				passwordDialogAccess={passwordDialogAccess}
				administrator={administrator}
			/>

			{toasterOptions.open && (
				<Toaster
					toasterOptions={toasterOptions}
					onClose={() => setToasterOptions({ open: false })}
				/>
			)}
		</>
	)
}

export default IndexRegister
