
/** Import react section **/
import React from 'react';
import List from '@material-ui/core/List';

/** Import Material UI section **/
import {
    ClickAwayListener,
    Paper,
    Popper
} from '@material-ui/core';
/**Import style sections */
import { useCardAvatarStyles } from '../../resources/styles/CardAvatarStyle'
import AlertComponent from '../common/AlertComponent'

const MenuListPopper = (props) => {
    const { renderOptionMenu } = props;

    let open = !!props.settingsEl;
    let classes = useCardAvatarStyles();
    return (
        <Popper open={open}
            className={classes.popperRoot}
            anchorEl={props.settingsEl}
            placement={'bottom'}
            transition>
            {
                <ClickAwayListener onClickAway={props.setSettingsEl}>
                    <Paper className={classes.papperContainer}>
                        {renderOptionMenu && renderOptionMenu.props.children ?
                            <List dense >
                                {renderOptionMenu}
                            </List> : <AlertComponent
                                title={"info"}
                                message={"Here are no options"}
                                severityType={"info"}
                            />}
                    </Paper>
                </ClickAwayListener>
            }
        </Popper>
    );
};
export default MenuListPopper;