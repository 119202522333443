/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

/**Import actions sectiuon */
import {
	loadSparePartSalesRequests,
	loadDashboardSparePartSales
} from '../../store/sparePartSales/sparePartSalesAction'
import { sparePartSaleIndexSelector } from '../../store/sparePartSales/sparePartSalesSelectors'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { loadAllCustomers } from '../../store/customers/customersActions'
/**Import components section */
import Toaster from '../common/Toaster'
import DesktopView from './components/IndexDesktopView'
import PanelImportReference from './components/PanelImportReferenceSales'
import { importSalesReferencesFromBackend } from '../../store/spareParts/sparePartsActions'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'

/**
 * Create sparte parts view
/**
 *
 *
 * @returns
 */
const Index = (props) => {
	const dispatch = useDispatch()
	const history = useHistory()
	let location = useLocation()

	const sparePartSalesIndex = useSelector(sparePartSaleIndexSelector)
	const userProfile = useSelector((state) =>
		toSafeObject(state.session.get('profile'))
	)
	const pagination = useSelector((state) =>
		state.sparePartSales.getIn(['index', 'pagination'])
	)
	const isLoadingDashboard = useSelector((state) =>
		state.sparePartSales.get('isLoadingDashboard')
	)
	const dashboard = useSelector((state) =>
		state.sparePartSales.get('dashboard')
	)
	const isDownloadingReport = useSelector((state) =>
		state.sparePartSales.getIn(['index', 'isDownloadReport'])
	)

	/**Load all customers from redux store for filters */
	const allCustomers = useSelector((state) =>
		state.customers.get('customers')
	)

	const isDownloadReport = useSelector((state) => state.spareParts.getIn(["index", "isDownloadReport"]))

	/**Local store this component section */
	const [query, setQuery] = useState('')
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})

	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query) setQuery(queryParameters.query)
		dispatch(
			loadSparePartSalesRequests('open', queryParameters)
		).then(() => { })
	}, [dispatch, location.search])

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		if (
			sparePartSalesIndex?.requests.length === 0 &&
			queryParams.pageNumber > 1
		) {
			queryParams.pageNumber = 1
			history.push({ search: queryString.stringify(queryParams) })
		}
	}, [sparePartSalesIndex, location.search, history])
	const [lateralPanelImportFile, setLateralPanelImportFile] = useState({ opened: false });

	/**
	 * Load dasborad list spare parts
	 */
	useEffect(() => {
		dispatch(loadDashboardSparePartSales('open')).then(() => { })
		dispatch(loadAllCustomers(null, 1000))
	}, [dispatch])

	const onChangeLocation = (location) => history.push(location)

	const onCreateRequest = () => history.push('/sparepartsales/create')

	/**Open lateral panel for import file reference SAP */
	const openImportReferenceSAP = () => {
		setLateralPanelImportFile({ opened: !lateralPanelImportFile.opened })
	}

	/**
	 * OpenRequest
	 */
	const onOpenRequest = (sparePartSale) => {
		history.push(`/sparepartsales/open/${sparePartSale.Token}`)
	}

	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Update sort criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onImportSalesReferenseSAP = (files, getType) => {
		const queryParameters = queryString.parse(location.search)
		dispatch(importSalesReferencesFromBackend(files, getType))
			.then(() => {
				openImportReferenceSAP();
				dispatch(loadSparePartSalesRequests("open", queryParameters, false, [])).then(() => {})
				return Promise.resolve();
			})
			.catch((error) => {
				setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to import references files', variant: 'error' })
			})
	}

	return (
		<>
			<DesktopView
				onChangeLocation={onChangeLocation}
				onCreateRequest={onCreateRequest}
				onOpenRequest={onOpenRequest}
				sparePartSalesIndex={sparePartSalesIndex}
				userProfile={userProfile}
				onChangeSearchValue={(query) => updateSearchQuery(query)}
				searchValue={query}
				pagination={pagination}
				onChangePage={onChangePage}
				onChangeSortingCriteria={updateSortCriteria}
				isLoadingDashboard={isLoadingDashboard}
				dashboard={dashboard}
				allCustomers={allCustomers}
				isDownloadingReport={isDownloadingReport}
				openImportReferenceSAP = {openImportReferenceSAP}
			/>
			{lateralPanelImportFile.opened &&
				<PanelImportReference
					onCloseButtonClick={openImportReferenceSAP}
					onImportSalesReferenseSAP={onImportSalesReferenseSAP}

					isSaving={isDownloadReport}
				/>
			}

			<Toaster
				toasterOptions={toaster}
				onClose={() => setToaster({ open: false })}
			/>
		</>
	)
}
export default Index
