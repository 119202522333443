/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import Iframe from 'react-iframe'

/**Material ui section */
import { Checkbox, FormControlLabel } from '@material-ui/core'

/**Import components section */
import AlertComponent from '../../../common/AlertComponent'
import { desktopViewRegisterStyles } from '../../resources/DesktopViewRegisterStyles'

const AcceptTermsAndConditions = (props) => {
    const classes = desktopViewRegisterStyles();
    const { isSaving, onAccepTerms, acceptTermsGuarantee, termsAndConditions } = props;

    return (
        <>
             {!termsAndConditions && <AlertComponent
                title={"Terms and Conditions"}
                message={"In order to complete your registration you must accept the terms of use and the processing, treatment and transfer of your personal data in accordance with the privacy policies."}
                severityType={!acceptTermsGuarantee ? "warning" : "success"} 

            /> } 
             {!termsAndConditions && <FormControlLabel
                control={
                    <Checkbox
                        checked={acceptTermsGuarantee}
                        onChange={onAccepTerms}
                        name="checkedB"
                        color="primary"
                        disabled={isSaving}
                    />
                }
                label="Accept terms and conditions"
            />}

            {termsAndConditions && <Iframe
                url={`${window.config.serviceManagementBackendUrl}/${termsAndConditions.UrlDoument}/${termsAndConditions.Attachment.FileName}`}
                className={classes.containerTerm}
                width="100%"
                id="myId"
            />}
        </>
    )
}

/**Proptypes this component */
AcceptTermsAndConditions.propTypes = {
    /**
	 * Invoke callback for accepted termns and conditions
	 */
    onAccepTerms: propTypes.func.isRequired,
    /**
    * Determinate is saving when accepted terms
    */
    isSaving: propTypes.bool.isRequired,
    /**
    * Determinate is accepted termns and determinate Checkbox is checked
    */
    acceptTermsGuarantee: propTypes.bool.isRequired,
}


AcceptTermsAndConditions.defaultProps = {
    onAccepTerms: () =>
        console.warn('Callback [onAccepTerms] no defined'),
    isSaving: false,
    acceptTermsGuarantee: false
}

export default AcceptTermsAndConditions
