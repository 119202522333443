/**Import react section */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

/**Import components section */
import Toaster from '../common/Toaster'
import ChangeStatusCustomerPanel from './components/ChangeStatusCustomerPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel';

/**Import helper section */
import { SpareParRequestStatus } from '../../store/helpers/AppConstants'
import { sendRequestToValidationInplant, cancelRequest } from '../../store/spareParts/sparePartsActions'
import {deleteSparepartSales} from '../../store/sparePartSales/sparePartSalesAction'

import { getEmailUsersByRole } from '../../store/helpers/UserHelper'
import { IMBERA_TECHNICIAN } from '../../resources/RolesEnum'
import { toSafeObject } from '../../store/helpers/StateHelper'


const EditSpareCustomer = (props) => {
    /**Use hooks section */
    const dispatch = useDispatch()
    const history = useHistory()

    /**Props section */
    const { lateralPanelCustomerConfig, openLateralPanelSendCustomer } = props

    /**Use selector section */
    const isSaving = useSelector((state) => state.sparePartSales.getIn(['show', 'isSaving']))
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    let emailsRolesTechsUsa = getEmailUsersByRole(IMBERA_TECHNICIAN, internalUsers)

    /**State local for this component*/
    const [toaster, setToaster] = useState({
        open: false,
        variant: null,
        message: null
    })

    /**
     * Action to delete spare part in draft
     */
    const onDeleteSparePart = () => {
        return dispatch(deleteSparepartSales(lateralPanelCustomerConfig ? lateralPanelCustomerConfig.sparePartSale.Token : ''))
            .then((_) => {
                history.push('/sparepartsales')
                openLateralPanelSendCustomer();
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to deleted spart part sales', variant: 'warning' })
                console.error("Failed to removed spare part sales", error);
            });
    };

    /**
	 * Callback executed when the user submits the request
	 */
    const onSentToValidateRequest = () => {
        dispatch(sendRequestToValidationInplant(lateralPanelCustomerConfig ? lateralPanelCustomerConfig.sparePartSale : null, emailsRolesTechsUsa))
            .then(() => {
                openLateralPanelSendCustomer();
                history.push('/sparepartsales')
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to submit spare part a tech', variant: 'error' })
            })
    }

        /**
     * On clancel spare part request
     */
    const onCancelRequest = () => {
        dispatch(cancelRequest(lateralPanelCustomerConfig ? lateralPanelCustomerConfig.sparePartSale.Token : ''))
            .then(() => {
                openLateralPanelSendCustomer();
                history.push('/sparepartsales')
                return Promise.resolve();
            })
            .catch((error) => {
                setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to canelled spare part request', variant: 'warning' })
            })
    }

    /**
     * Function to invoke action to change status
     * @param {*} status 
     */
    const GetFunctionToInvoke = (status) => {
        switch (Number(status)) {
            case SpareParRequestStatus.SEND_TECH_USA:
                return onSentToValidateRequest
            case SpareParRequestStatus.DELETED:
                return onDeleteSparePart
            case SpareParRequestStatus.CANCELLED:
                return onCancelRequest
            default:
                return null
        }
    }

    return (
        <>
            {
                (lateralPanelCustomerConfig && lateralPanelCustomerConfig.opened) &&
                <LateralPanel
                    onClose={openLateralPanelSendCustomer}
                >
                    <ChangeStatusCustomerPanel
                        onCloseButtonClick={openLateralPanelSendCustomer}
                        sparePartSale={(lateralPanelCustomerConfig && lateralPanelCustomerConfig.sparePartSale)}
                        onSavedClick={GetFunctionToInvoke}
                        isSaving={isSaving}
                    />

                </LateralPanel>
            }

            <Toaster
                toasterOptions={toaster}
                onClose={() => setToaster({ open: false })}
            />
        </>
    )
}

EditSpareCustomer.propTypes = {
    /**Object have configuration for change status panel lateral */
    lateralPanelCustomerConfig: propTypes.shape({
        opened: propTypes.bool,
        sparePartSale: propTypes.object
    }),
    /**Callback executed when user required change status the spare part requests and open lateral panel */
    openLateralPanelSendCustomer: propTypes.func.isRequired,
}
EditSpareCustomer.defaultProps = {
    lateralPanelCustomerConfig:{
        opened:false,
        sparePartSale:{}
    },
    openLateralPanelSendCustomer: () =>
        console.warn('Callback [openLateralPanelSendCustomer] no defined'),
}

export default withImmutablePropsToJS(EditSpareCustomer)
