/**Import react section */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
/**Import Material UI section */
import { Stepper, Step, StepLabel, Typography, Card } from '@material-ui/core'

/**Import resources section */
import { useSerialHistoryDesktopStyle } from '../resources/useStyles'
import { GetWarehouseDescription } from '../../../store/helpers/SparePartHelper'
const SparePartTimeLine = (props) => {
	const { spareParts } = props
	let history = useHistory()
	const classes = useSerialHistoryDesktopStyle()

	const [activeStep] = useState(0)

	const onRedirectToSparePartRequests = (token) => {
		history.push(`/spareparts/open/${token}`)
	}

	return (
		<div>
			<Card className={classes.partHeader}>
				<Typography className={classes.serviceHeaderTicket}>{`Creation date`}</Typography>
				<Typography className={classes.serviceHeaderDate}>{`#Part | Description`}</Typography>
				<Typography className={classes.serviceHeaderStatus}>{`Warehouse`}</Typography>
				<Typography className={classes.serviceHeaderDescription}>{`Tracking number`}</Typography>
				<Typography align='center' className={classes.serviceHeaderUltim}>{`Quantity`}</Typography>
				<Typography className={classes.serviceHeaderFolio}>{`Folio`}</Typography>
			</Card>
			<br />

			<Stepper activeStep={activeStep} orientation='vertical' className={classes.stepperContainer}>
				{spareParts &&
					spareParts.map((part, index) => {
						return (
							<Step key={index} onClick={() => onRedirectToSparePartRequests(part.TokenRequest)}>
								<StepLabel>
									<div className={classes.partContent}>
										<Typography className={classes.serviceHeaderTicket}>{part.CreationDate}</Typography>
										<Typography noWrap className={classes.serviceHeaderDate}>{`${part.Code} | ${part.Description}`}</Typography>
										<Typography className={classes.serviceHeaderStatus}>{GetWarehouseDescription(part.Warehouse)}</Typography>
										<Typography className={classes.serviceHeaderDescription}>{part.NumberTracking}</Typography>
										<Typography align='center' className={classes.serviceHeaderUltim}>
											{part.Quantity}
										</Typography>
										<Typography className={classes.serviceHeaderFolio}>{part.FolioRequest}</Typography>
									</div>
								</StepLabel>
							</Step>
						)
					})}
			</Stepper>
		</div>
	)
}

export default SparePartTimeLine
