import { makeStyles } from '@material-ui/styles'

/**
 * avatar styles
 */
export const ButtonCreateStyles = makeStyles((theme) => ({
    btnSaved: {
        backgroundColor: theme.palette.colors.purple,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.purple,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.purple,
            color: theme.palette.common.white,
            opacity: .5,
        },
    },
    btnCancel: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.red,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.red,
            color: theme.palette.common.white,
            opacity: .5,
        },
    },
    btnConfirm: {
        backgroundColor: theme.palette.colors.purpleRose,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.purpleRose,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.purpleRose,
            color: theme.palette.common.white,
            opacity: .5,
        },
    },
    btnSync: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            opacity: .5,
        },
    }
}))
