/**Import react section */
import React, { useState } from 'react';
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux';

/**Import MaterialUi section */
import { Grid } from '@material-ui/core'

/**Import components section */
import LateralPanel from '../../../common/lateralPanel/LateralPanel';
import PanelHeader from '../../../common/lateralPanel/PanelHeader'
import TextBox from '../../../common/TextBox'
import AlertComponent from '../../../common/AlertComponent'
import ButtonSaved from '../../../common/Buttons/ButtonSaved'

/**Import store section */
import { lookForUser, findUserByUserName } from '../../../../store/users/UsersActions'
import { AccountCode } from '../../../../store/helpers/AppConstants'
/**Import resources section */
const AddUserPanel = (props) => {
    const dispatch = useDispatch();

    /**Local store this component */
    const { onSaveButtonClick, onCloseButtonClick, isSaving } = props;

    const [userModel, setUserModel] = useState(defaultEditRetailer);
    const [userExist, setUserExist] = useState(true);
    const [userAlreadyAdded, setUserAlreadyAdded] = useState(false)
    const [isFindingUser, setIsFinding] = useState(false)

    const onSearchUserInTheDirectoryActive = () => {
        setIsFinding(true);
        lookForUser(userModel ? userModel.userName : "")
            .then(foundUser => {
                setIsFinding(false);
                if (foundUser && foundUser.userName != null) {
                    setUserExist(true)
                    setUserModel({
                        ...userModel,
                        "id": foundUser.id,
                        "name": foundUser.name,
                        "type": foundUser.type,
                        "email": foundUser.email,
                    });
                }
                else {
                    setUserExist(false)
                    setUserAlreadyAdded(false)
                    setUserModel({
                        ...userModel,
                        account: account,
                        "name": "",
                    });
                }
            })
            .catch(() => {
                setUserExist(false)
                setUserAlreadyAdded(false)
            })

        /**Finding user exists in redux store */
        dispatch(findUserByUserName(userModel ? userModel.userName : ""))
            .then((result) => {
                setUserAlreadyAdded(result)
            })
            .catch(() => {
                setUserExist(false)
                setUserAlreadyAdded(false)
            })
    }


    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        setUserExist(true)
        setUserAlreadyAdded(false)
        setUserModel({
            ...userModel,
            [name]: value
        });

    }

    /**Verify if cam be sabed */
    const canBeSaved = () => {
        if(!userModel) return false;
        if (userModel.name ? (userModel.name.length <= 0) : true) return false;
        if (userModel.userName ? (userModel.userName.length <= 0) : true) return false;
        if (!userExist) return false;
        if (userAlreadyAdded) return false;
        return true;
    }

    const onSave = () => {
        if (props.onSaveButtonClick) {
            onSaveButtonClick(userModel)
        }
    }

    return (
        <LateralPanel
            onClose={onCloseButtonClick}
        >
            <PanelHeader
                title={"Add user"}
                onCloseButtonClick={onCloseButtonClick}
                isModeSaved={true}
                canBeSaved={canBeSaved()}
                onSaveButtonClick={onSave}
                isSaving={isSaving}
            />
            <div style={{ padding: "20px" }}>
                <Grid container >
                    <Grid item xs={12}>
                        <Grid container spacing={1} style={{ display: "flex", alignSelf: 'center', alignItems: "center" }}>
                            <Grid item xs={9}>
                                <TextBox
                                    active={true}
                                    disabled={isSaving}
                                    fullWidth
                                    autoFocus
                                    name="userName"
                                    variant="outlined"
                                    margin="dense"
                                    label="User name"
                                    onChange={onPropertyChange}
                                    value={userModel ? userModel.userName : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <ButtonSaved
                                    isSaving = {isSaving || isFindingUser || (userModel ? userModel.userName.length <2:"")}
                                    title={'Search'}
                                    onSavedClick={onSearchUserInTheDirectoryActive}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            active={true}
                            disabled={true}
                            fullWidth
                            autoFocus
                            name="name"
                            variant="outlined"
                            margin="dense"
                            label="Name"
                            onChange={onPropertyChange}
                            value={userModel ? userModel.name : ""}
                        />
                    </Grid>
                </Grid>
                {
                    !userExist &&
                    <AlertComponent
                        title={"Error"}
                        message={"The user was not found in the Active Directory. Check the user name and try it again"}
                        severityType={"error"}
                    />
                }
                {
                    userAlreadyAdded &&
                    <AlertComponent
                        title={"Warning"}
                        message={"This user has already been added. Try a different user"}
                        severityType={"warning"}
                    />
                }
            </div>

        </LateralPanel>
    )
}

const account = {
    code: AccountCode.IMBERA,
    name: "IMBERA"
}

const defaultEditRetailer = {
    name: '',
    userName: '',
    account: account,
}

/**Proptypes this component */
AddUserPanel.propTypes = {
    /**
	 * On close lateral menu
	 */
    onCloseButtonClick: propTypes.func.isRequired,
    /**
    * On save new user
    */
    onSaveButtonClick: propTypes.func.isRequired,
}


AddUserPanel.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined')
}

export default AddUserPanel
