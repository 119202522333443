/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'

/**Import Material UI Section */
import { Grid, Typography, Card, Chip, Divider } from '@material-ui/core'

/**Import componets section */
import ReactSelectAvatar from '../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import ButtonCancel from '../../common/Buttons/ButtonCancel'
import TextBox from '../../common/TextBox'

/**Import styles section */
import { createPaymentStyle } from '../resources/CreatePaymentStyles'

/**
 * Component for show form payment
 * @param {*} props
 */
const PaymentHeaderForm = (props) => {
	const classes = createPaymentStyle()
	/**Functions section */
	const { onPropertyChange, onSelectChange, redirectToHomeCancelPayment, addPaymentRequestOnClick } = props
	/**Const arrays props sections */
	const { userProfile, paymentModel, closedServices, isSavingPaymentRequest } = props
	/**Register properties for validate information react hook form */
    const { register, handleSubmit, errors, control } = useForm()
    
	return (
        <Card style={{ padding: "20px", marginLeft: "-3px", marginRight: "-3px" }}>
            <PaymentHeaderComponent
                userProfile={userProfile}
                paymentModel={paymentModel}
                onSelectChange={onSelectChange}
                onPropertyChange={onPropertyChange}
                closedServices={closedServices}
                register={register}
                errors={errors}
                control={control}
            />
            <Grid item xs={12} md={12}>
                <div className={classes.buttonAdd}>
                    <ButtonCancel
                        disabled={isSavingPaymentRequest}
                        onCancelClick={
                            redirectToHomeCancelPayment
                        }
                        title='Cancel'
                    />
                    <ButtonSaved
                        isSaving={isSavingPaymentRequest}
                        onSavedClick={handleSubmit(
                            addPaymentRequestOnClick
                        )}
                        title='CREATE'
                    />
                </div>
            </Grid>
        </Card>
	)
}

PaymentHeaderForm.propTypes = {
	/**Function invoke callback for set properties in the state local*/
	onPropertyChange: propTypes.func,
	/** Contains the information and properties of the payment*/
	paymentModel: propTypes.object,
	/**Function invoke callback for sets options */
	onSelectChange: propTypes.func,
}
PaymentHeaderForm.defaultProps = {
	onPropertyChange: () =>
		console.warn('Callback [onPropertyChange] no defined'),
        paymentModel: {},
    onSelectChange: () =>
		console.warn('Callback [onSelectChange] no defined')
}

export default PaymentHeaderForm


/**
 * Component to add header form payment
 * @param {*} props 
 */
const PaymentHeaderComponent = (props) => {
    const classes = createPaymentStyle()
	const { paymentModel, closedServices, userProfile} = props
	const { onSelectChange, onPropertyChange } = props
	/**Register properties for validate information react hook form */
	const { register, errors, control } = props

	return (
		<Grid item xs={12}>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Typography className={classes.title}>{"APPLICANT"}</Typography>
					<Chip
						id={"value"}
						size="small"
						avatar={
							<AvatarCustomSize
								avatarUrl={null}
								name={"APPLICANT"}
								isAvatarFromIdentity={false}
							/>}
						label={userProfile && (`${userProfile.name}`)}
						style={{ color: "white", background: "#5357DC" }}
					/>
					<Divider style={{margin: "9px"}} />
				</Grid>     
				<Grid item xs={12} sm={6}>
					<Typography className={classes.title}>{"SERVICE SELECTED"}</Typography>
					<ReactSelectAvatar
						id='Service'
						name='Service'
						placeholder={'Selected Service'}
						notShowTitle={true}
						options={closedServices}
						autoFocus
						formatOptionLabel={null}
						onChange={onSelectChange}
						value={
							paymentModel
								? paymentModel.Service
								: null
						}
						register={
							paymentModel &&
								!!paymentModel.Service
								? register(
									{ name: 'Service' },
									{ required: { value: false } }
								)
								: register(
									{ name: 'Service' },
									{
										required: {
											value: true,
											message:
												'The service is required'
										}
									}
								)
						}
						errors={errors}
						control={control}
					/>
				</Grid>
				<Grid item xs={12}>
				<Typography className={classes.title}>{"INVOICE"}</Typography>
					<TextBox
						key={"InvoiceFolio"}
						active={true}
						disabled={false}
						fullWidth
						name={"InvoiceFolio"}
						variant="outlined"
						margin="dense"
						onChange={onPropertyChange}
						value={paymentModel ? paymentModel.InvoiceFolio ? paymentModel.InvoiceFolio : '' : ''}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextBox
						key={"Comments"}
						active={true}
						disabled={false}
						fullWidth
						multiline={true}
						name={"Comments"}
						rows={4}
						variant="outlined"
						margin="dense"
						label={"COMMENTS"}
						onChange={onPropertyChange}
						value={paymentModel ? paymentModel.Comments ? paymentModel.Comments : '' : ''}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

PaymentHeaderComponent.propTypes = {
	paymentModel: propTypes.object,
	closedServices: propTypes.arrayOf(
		propTypes.shape({
			label: propTypes.string.isRequired,
			value: propTypes.string.isRequired
		})
	),
	onSelectChange: propTypes.func.isRequired
}
PaymentHeaderComponent.defaultProps = {
	closedServices: [],
}

