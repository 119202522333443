import { makeStyles } from '@material-ui/styles'
import imberaLogo from '../../resources/images/imberalogo.png'


/**
 * Sidebar styles
 */
export const useSideBarStyles = makeStyles((theme) => ({
    root: {
        marginRight: '5px',
        width: '45px',
        height:"100%"
    },
    header: {
        height: theme.mixins.toolbar.minHeight,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${imberaLogo})`
    },
    content: {
        backgroundColor: theme.palette.common.gray,
        padding: '10px'
    },
    iconButton: {
        marginLeft: '2px',
        width: theme.spacing(5),
        height: theme.spacing(5)
    },
    activeIcon: {
        backgroundColor: theme.palette.primary.main
    }
}))
 