/**Import react section */
import React from 'react'
import Select from 'react-select';
import { Controller } from 'react-hook-form'
import propTypes from 'prop-types'
/**Import Material UI Section */
import { Typography } from '@material-ui/core'

/**Import components section */
const ReactSelectAvatar = (props) => {
    const {
        id,
        notShowTitle,
        placeholder,
        options,
        margin,
        onChange,
        value,
        name,
        errors,
        control,
        autoFocus,
        isDisabled,
        formatOptionLabel,
        rules,
        isMulti
    } = props

    return (
        <>
            {!notShowTitle && <Typography style={{ fontSize: '0.75rem' }} noWrap>{placeholder} </Typography>}
            <Controller
                as={<Select
                    id={id}
                    placeholder={placeholder}
                    margin={margin}
                    autoFocus={autoFocus}
                    value={value}
                    isDisabled={isDisabled}
                    options={options}
                    isMulti={isMulti}
                    formatOptionLabel={formatOptionLabel ? formatOptionLabel : null}
                    styles={{
                        menu: (base) => ({
                            ...base,
                            zIndex: 62,
                            maxHeight: 150
                        }),
                        menuList: (base) => ({
                            ...base,
                            maxHeight: 150,
                            paddingTop: 0
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        clearIndicator: (base) => ({
                            ...base,
                            display: 'none'
                        }),
                        control: (base, state) => ({
                            ...base,
                            borderColor:
                                !errors[name] || state.isFocused
                                    ? '#ddd'
                                    : 'red', // overwrittes hover style
                            '&:hover': {
                                borderColor:
                                    !errors[name] || state.isFocused
                                        ? '#ddd'
                                        : 'red'
                            }
                        })
                    }}
                    menuPortalTarget={document.parentNode}
                    menuPosition='fixed'
                    menuPlacement={'bottom'}
                />
                }
                options={options}
                name={name}
                control={control}
                onChange={([selected]) => {
                    onChange(name, selected)
                }}
                valueName={value}
                rules={rules}
            />
            {errors[name] && (
                <Typography
                    style={{ fontSize: '0.75rem' }}
                    noWrap
                    color='error'
                >{`${errors[name].message}`}</Typography>
            )}
        </>
    )
}

ReactSelectAvatar.propTypes = {
    // is multi
    isMulti: propTypes.bool,
    // ...
    /**Unique property for selector */
    id: propTypes.string.isRequired,
    notShowTitle: propTypes.bool,
    /**selector placeholder*/
    placeholder: propTypes.string.isRequired,
    /**Determines the shape of the selector*/
    margin: propTypes.string.isRequired,
    /**Determines the identifier for react select*/
    name: propTypes.string.isRequired,
    /**Messge of error */
    helperText: propTypes.string,
    /**Function invoke callback for set properties in the state local*/
    onChange: propTypes.func,
    /**
     * The option for render in the react select component
     */
    options: propTypes.arrayOf(
        propTypes.shape({
            value: propTypes.oneOfType([
                propTypes.string.isRequired,
                propTypes.number.isRequired
            ]),
            label: propTypes.oneOfType([
                propTypes.string.isRequired,
                propTypes.number.isRequired
            ]),
            isAvatarFromIdentity: propTypes.oneOfType([
                propTypes.bool
            ])
        })
    ),
    errors: propTypes.object.isRequired,
    control: propTypes.object.isRequired,
}
ReactSelectAvatar.defaultProps = {
    id: '',
    name: '',
    notShowTitle: false,
    placeholder: '',
    margin: 'dense',
    helperText: '',
    onChange: () => console.warn('Callback [onChange] no defined'),
    options: [],
    isMulti: false,
}

export default ReactSelectAvatar