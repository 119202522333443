/**Import react section */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper';

import { addSalesRepresentative, addUserInSalesRepresentative } from '../../store/salesrepresentatives/salesRepresentativesActions'


/**Import components section  */
import AddLateralPanel from './components/AddLateralPanel'
import Toaster from '../common/Toaster'

import AddUserInRepresentativePanel from './components/AddUserInRepresentativePanel'

const Create = (props) => {
    const { onAddLateralPanelConfig, addPanelConfiguration, isSaving, addUserInRepresentativePanelConfig, onAddUsersRepresentativeOpenClosePanel } = props;

    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });

    /**Hooks sections */
    const dispatch = useDispatch()

    const onAddSalesRepresentative = (representativeModel) => {
        dispatch(addSalesRepresentative(representativeModel))
            .then(_ => {
                onAddLateralPanelConfig()
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to create sales representative', variant: 'warning' })
            });
    }

    const onAddUserInSalesRepresentative = (representativeModel, code) => {
        dispatch(addUserInSalesRepresentative(representativeModel, code))
            .then(_ => {
                onAddUsersRepresentativeOpenClosePanel()
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: !isNullOrEmpty(error) ? error : 'Error to create sales representative', variant: 'warning' })
            });
    }


    return (
        <>
            {
                (addPanelConfiguration && addPanelConfiguration.opened) &&
                <AddLateralPanel
                    onSaveButtonClick={onAddSalesRepresentative}
                    onCloseButtonClick={onAddLateralPanelConfig}
                    isSaving={isSaving}
                />
            }

            {
                (addUserInRepresentativePanelConfig && addUserInRepresentativePanelConfig.opened) &&
                <AddUserInRepresentativePanel
                    onSaveButtonClick={onAddUserInSalesRepresentative}
                    onCloseButtonClick={onAddUsersRepresentativeOpenClosePanel}
                    isSaving={isSaving}
                />
            }

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
    )
}

Create.propTypes = {
	/**
	 * open lateral panel for add an new sales representative
	 */
    onAddLateralPanelConfig: propTypes.func.isRequired,
    /**
	 * Determinate if is saving data
	 */
    isSaving: propTypes.bool.isRequired,
}


Create.defaultProps = {
    onAddLateralPanelConfig: () =>
        console.warn('Callback [onAddLateralPanelConfig] no defined'),
    isSaving: false
}

export default Create