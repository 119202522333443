/**Import react section */
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
/**Import react section */
import DataGrid from '../../common/apsys.datagrid.materialui/DataGrid'
import Typography from "@material-ui/core/Typography";
import {
    ButtonGroup, Tooltip, Button, TextField, Card, CardHeader,
    Avatar, CardContent, Divider, IconButton
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined';

/**Import components section */
import SearchBox from '../../common/SearchToolbox'
/**Import resources section */
import dataGridMovilConfig from '../resources/materialSapMovilConfig'
import { useCreateDesktopStyle } from '../resources/useStyles'
import Pagination from '../../common/Pagination/Pagination'

const EquipmentsSapMovil = (props) => {
    const classes = useCreateDesktopStyle()
    /**hooks section */
    /**Props functions sections */
    const { onAddSparePart, onUpdateQuantityEquipment, onChangeQuerySap } = props
    /**string props section */
    const { querySparePart, } = props
    /**Bools props section */
    const { isModeCreate } = props
    /**Store local this component */
    const [equipment, setEquipment] = useState(null)
    const [allSpareParts, setAllSpareParts] = useState([])
    const [pagination, setPagination] = useState({ pageSize: 5, total: 0 });
    const [allSparePartPaginated, setAllSparePartPaginated] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setEquipment(props.equipmentsSap)
    }, [props])

    //filter all spare parts
    useEffect(() => {
        setAllSpareParts(props.sparePartsSap)
    }, [props.sparePartsSap])

    useEffect(() => {
        let sparePartsPerPage = 10;
        let total = allSpareParts.length / sparePartsPerPage;
        let allTotal = Math.round(Math.ceil(total))
        const indexOfLastSparePart = currentPage * sparePartsPerPage;
        let indexOfFirtsSparePart = indexOfLastSparePart - sparePartsPerPage;
        let currentSparePart = allSpareParts.slice(indexOfFirtsSparePart, indexOfLastSparePart)
        setAllSparePartPaginated(currentSparePart)
        setPagination({ pageSize: sparePartsPerPage, total: allTotal });

    }, [allSpareParts, pagination.total, currentPage])

    const onButtonAddClick = (equipment, sparePart) => {
        if (onAddSparePart) {
            onAddSparePart(equipment, sparePart)
        }
    }

    /**On change properties */
    const onPropertyChange = (serie, sparePart, event) => {
        let { name, value } = event.target;
        if (name === "Quantity" && value.length > 0) {
            let regexQuantity = /^([0-9]+$)/;
            if (!regexQuantity.test(value)) return;
        }

        let existSparePart = equipment.SpareParts.find(x => x.Code === sparePart.Code);
        if (existSparePart !== undefined) {
            existSparePart[name] = value
        }
        onUpdateQuantityEquipment(equipment)
    }

    /**
     * Configuration data grid
     * @param {*} equipment 
     */
    const getDataGridConfiguration = (equipment) => {
        let enhacedTableConfig = [...dataGridMovilConfig];
        if (isModeCreate) {
            enhacedTableConfig.push({
                title: "Quantity",
                dataSource: 'IconAdd',
                onRenderProperty: (item) => {
                    return (
                        <div className={classes.qualityMovilSap}>
                            <TextField
                                key={"Quantity"}
                                name="Quantity"
                                defaultValue={item.Quantity}
                                onBlur={(event) => onPropertyChange(equipment.Serie, item, event)}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                autoComplete='off'
                                style={{height: "35px !important"}}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutlineSuccessMovile,
                                    },
                                }}
                            />
                            <ButtonGroup component="span" size='small' variant="outlined" color="primary"
                                className={classes.buttonsGroupMovil}
                            >
                                <Tooltip component="span" title='Add part'>
                                    <Button
                                        className={classes.buttonsGroup}
                                        disabled={item.Quantity ? false : true}
                                        onClick={() => onButtonAddClick(equipment, item)}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </div>
                    )
                }
            })
        }
        return enhacedTableConfig
    }

    const onChangePage = (event, page) => {
        setCurrentPage(page)
    }

    const onChangeQuery = (query) => {
        setCurrentPage(1)
        if (onChangeQuerySap) {
            onChangeQuerySap(query)
        }
    }

    return (
        <div>
            {equipment && <Card>
                <Divider />
                <CardHeader
                    avatar={
                        <Avatar>
                            <GuaranteeReportIcon />
                        </Avatar>
                    }
                    title={<Typography className={classes.textExpanded}> {` SPARE PARTS`} </Typography>}
                    subheader={
                        <div>
                            <Typography variant="caption"><strong >{`Serie: `}</strong>{` ${equipment.Serie} `} | <strong >{`Model: `}</strong> {` ${equipment.Model} `}</Typography>
                            <Typography variant="caption"><strong>{`Description: `}</strong> {` ${equipment.DescriptionMaterial} `} </Typography>
                        </div>
                    }
                />

                <CardContent>
                    <div className={classes.containerSpareParts}>
                        <div className={classes.headerSapMovil}>
                            <SearchBox
                                placeholder='Search spare part'
                                onChange={onChangeQuery}
                                value={querySparePart}
                            />
                            
                            <IconButton
                                type='submit'
                                className={classes.buttonCleamMovil}
                                onClick={() => props.onUpdateQuantityEquipment(null)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div className={classes.tableSpareParts}>
                            <DataGrid
                                headers={getDataGridConfiguration(equipment)}
                                data={allSparePartPaginated}
                            />
                            <div className={classes.pagination}>
                                <div className={classes.flexGrow} />
                                <Pagination count={pagination.total ? pagination.total : 0} page={currentPage ? currentPage : 0} onChangePage={onChangePage} />
                            </div>

                        </div>
                    </div>
                </CardContent>
            </Card>
            }
        </div>
    )
}

EquipmentsSapMovil.propTypes = {

    /**Equipments fount with serie in SAP */
    equipmentsSap: propTypes.object,

	/**
	 * Determine if the isSaving data in database
	 */
    isSaving: propTypes.bool.isRequired,

	/**
	 * Create spare part in the back end
	 */
    onAddSparePart: propTypes.func.isRequired,
    /**
	 * Callback update information in the store redux equipment sap
	 */
    onUpdateQuantityEquipment: propTypes.func.isRequired,
}
EquipmentsSapMovil.defaultProps = {
    equipmentsSap: null,
    isSaving: false,
    onAddSparePart: () =>
        console.warn('Callback [onAddSparePart] no defined'),
    onUpdateQuantityEquipment: () =>
        console.warn('Callback [onUpdateQuantityEquipment] no defined')
}

export default EquipmentsSapMovil