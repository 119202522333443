/**Import actions section */
import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMobile } from '../../hooks/useIsMobile'

/**Import components section */
import DesktopView from './components/CreateDesktopView'
import CreateMovilView from './componentsMobile/CreateMovilView'
import Toaster from '../common/Toaster'
import Edit from './Edit'
import EditSpareProvider from './EditSpareProvider'
import { useHistory, useParams } from 'react-router-dom'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'

/**Import actions section */
import { loadServices } from '../../store/services/serviceActions'
import {
	setSelectedService,
	addSparePartToRequest,
	removeSparePart,
	updateSparePartRequest,
	consultBySerie,
	SET_SPARE_PARTS_FROM_SAP,
	SET_QUERY_SAP,
	SET_SELECTED_SERVICE,
	downloadReportByserie
} from '../../store/spareParts/sparePartsActions'
import { sparePartsSAPSelector } from '../../store/spareParts/sparePartsSelectors'
import { servicesSelector } from '../../store/services/servicesSelectors'
import { sparePartCreateSelector } from '../../store/spareParts/sparePartsSelectors'
import { toSafeObject } from '../../store/helpers/StateHelper'
/**
 * Create sparte parts view
 */
const Create = (props) => {
	const isMobile = useIsMobile(props.isMobile)
	const dispatch = useDispatch()
	const history = useHistory()
	const params = useParams()
	const services = useSelector((state) => servicesSelector(state))
	const createSpareRequest = useSelector(sparePartCreateSelector)
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})
	const [identifierSparePartDelete, setIdentifierSparePartDelete] = useState(
		''
	)
	const [lateralPanelStatusConfig, setLateralPanelStatusConfig] = useState({
		opened: false,
		service: null
	})
	const [
		lateralPanelProviderConfig,
		setLateralPanelProvicerConfig
	] = useState({ opened: false, service: null })
	const userProfile = useSelector((state) =>
		toSafeObject(state.session.get('profile'))
	)
	const isLoadingFromSAP = useSelector((state) =>
		state.spareParts.getIn(['show', 'isLoadingFromSAP'])
	)
	const equipmentsSap = useSelector((state) =>
		state.spareParts.getIn(['show', 'equipmentsSap'])
	)
	const querySparePart = useSelector((state) =>
		state.spareParts.get(['show', 'querySparePart'])
	)
	const isDownloadReport = useSelector((state) =>
		state.spareParts.getIn(['index', 'isDownloadReport'])
	)
	const isGettingDraft = useSelector((state) =>
		state.spareParts.getIn(['create', 'isGettingDraft'])
	)

	/**Store local this component */
	const sparePartsSap = useSelector(sparePartsSAPSelector)

	useEffect(() => {
		dispatch(loadServices('open', null, 1000)).then(() => {
			// Nothing to code
		})
		dispatch({
			type: SET_SELECTED_SERVICE,
			draft: null,
			service: null
		})
	}, [dispatch])

	/**
	 * Change the ticket selected
	 */
	useEffect(() => {
		if (services.length > 0 && params.ticket) {
			let selectedServices = services.find(
				(srv) => srv.Ticket === params.ticket
			)
			dispatch(setSelectedService(selectedServices))
		}
	}, [dispatch, services, params])

	/**Clean state redux equipments sap */
	useEffect(() => {
		dispatch({
			type: SET_SPARE_PARTS_FROM_SAP,
			Equipment: null
		})
		dispatch({
			type: SET_QUERY_SAP,
			query: ''
		})
	}, [dispatch])

	/**
	 * on change search material from list sap
	 * @param {*} query
	 */
	const onChangeQuerySap = (query) => {
		dispatch({
			type: SET_QUERY_SAP,
			query
		})
	}

	/**
	 * onServiceSelected
	 * @param {*} service
	 */
	const onServiceSelected = (service) => {
		history.push(`/spareparts/create/${service.Ticket}`)
	}

	/**
	 * onAddSparePart
	 */
	const onAddSparePart = (equipment, sparePart) => {
		dispatch(addSparePartToRequest(sparePart, equipment))
			.then(() => {
				// Nothing to code
			})
			.catch((err) => {
				console.error('Error saving the spare part', err)
				setToaster({
					open: true,
					variant: 'warning',
					message: err.Message
				})
			})
	}

	const onUpdateQuantityEquipment = (Equipment) => {
		/**If equipment is null clean query in redux store empty */
		if (Equipment === null) {
			onChangeQuerySap('')
		}
		return dispatch({
			type: SET_SPARE_PARTS_FROM_SAP,
			Equipment
		})
	}

	const onRemoveSparePart = (sparePart) => {
		setIdentifierSparePartDelete(sparePart.Token)
		return dispatch(
			removeSparePart(
				sparePart,
				createSpareRequest ? createSpareRequest.draft.Token : '',
				false
			)
		)
			.then(() => {
				setIdentifierSparePartDelete('')
			})
			.catch((error) => {
				setIdentifierSparePartDelete('')
				setToaster({
					open: true,
					message: !isNullOrEmpty(error)
						? error
						: 'An error to delete spare part',
					variant: 'error'
				})
			})
	}

	const onSaveSparePartRequest = (
		mapPositionModel,
		sparePartRequestsForm
	) => {
		return dispatch(
			updateSparePartRequest(
				createSpareRequest ? createSpareRequest.draft.Token : '',
				mapPositionModel,
				sparePartRequestsForm
			)
		)
			.then(() => {
				// Nothing to code
				setToaster({
					open: true,
					message: 'Spare part request update successfully',
					variant: 'success'
				})
			})
			.catch((error) => { })
	}

	/**
	 * Action to search list material by serie from SAP
	 * @param {*} serie
	 */
	const onSearchMaterialsBySerie = (serie) => {
		onUpdateQuantityEquipment(null)
		return dispatch(consultBySerie(serie)).catch((error) => {
			setToaster({
				open: true,
				message: !isNullOrEmpty(error)
					? error
					: 'An error to consult materials by serie from SAP',
				variant: 'warning'
			})
		})
	}

	/**
	 * Function to donwload report by serie
	 */
	const onDonwloadReportBySerie = () => {
		return dispatch(downloadReportByserie()).catch(() => {
			setToaster({
				open: true,
				message: 'An error to download report by serie',
				variant: 'error'
			})
		})
	}

	const onChangeLocation = (location) => history.push(location)

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelChangeStatus = (spartPart) => {
		setLateralPanelStatusConfig({
			opened: !lateralPanelStatusConfig.opened,
			spartPart: spartPart
		})
	}

	/**Open lateral panel for show send by provider */
	const openLateralPanelSendProvider = (spartPart) => {
		setLateralPanelProvicerConfig({
			opened: !lateralPanelProviderConfig.opened,
			spartPart: spartPart
		})
	}

	return (
		<Fragment>
			{isMobile ? (
				<CreateMovilView
					isMobile={isMobile}
					onChangeLocation={onChangeLocation}
					activeServices={services}
					onServiceSelected={onServiceSelected}
					onAddSparePart={onAddSparePart}
					onRemoveSparePart={onRemoveSparePart}
					request={createSpareRequest}
					identifierSparePartDelete={identifierSparePartDelete}
					onSaveSparePartRequest={onSaveSparePartRequest}
					userProfile={userProfile}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					openLateralPanelSendProvider={openLateralPanelSendProvider}
					isGettingDraft={isGettingDraft}
					isLoadingFromSAP={isLoadingFromSAP}
					onSearchSerieInSap={onSearchMaterialsBySerie}
					equipmentsSap={equipmentsSap}
					onChangeQuerySap={onChangeQuerySap}
					sparePartsSap={sparePartsSap}
					querySparePart={querySparePart}
					onUpdateQuantityEquipment={onUpdateQuantityEquipment}
				/>
			) : (
				<DesktopView
					onChangeLocation={onChangeLocation}
					activeServices={services}
					onServiceSelected={onServiceSelected}
					onAddSparePart={onAddSparePart}
					onRemoveSparePart={onRemoveSparePart}
					request={createSpareRequest}
					identifierSparePartDelete={identifierSparePartDelete}
					onSaveSparePartRequest={onSaveSparePartRequest}
					userProfile={userProfile}
					openLateralPanelChangeStatus={openLateralPanelChangeStatus}
					openLateralPanelSendProvider={openLateralPanelSendProvider}
					isLoadingFromSAP={isLoadingFromSAP}
					onSearchSerieInSap={onSearchMaterialsBySerie}
					equipmentsSap={equipmentsSap}
					onChangeQuerySap={onChangeQuerySap}
					sparePartsSap={sparePartsSap}
					querySparePart={querySparePart}
					onUpdateQuantityEquipment={onUpdateQuantityEquipment}
					isDownloadReport={isDownloadReport}
					onDonwloadReportBySerie={onDonwloadReportBySerie}
				/>
			)}
			<Edit
				lateralPanelStatusConfig={lateralPanelStatusConfig}
				openLateralPanelChangeStatus={openLateralPanelChangeStatus}
				userProfile={userProfile}
			/>

			<EditSpareProvider
				lateralPanelProviderConfig={lateralPanelProviderConfig}
				openLateralPanelSendProvider={openLateralPanelSendProvider}
			/>

			<Toaster
				toasterOptions={toaster}
				onClose={() => setToaster({ open: false })}
			/>
		</Fragment>
	)
}
export default Create
