const ProvidersTableConfig = [
	{
		title: '',
		dataSource: 'avatar',
		width: 300
	},
	{
		title: 'Code',
		dataSource: 'Code',
		sortable: true,
		width: 600
	},
	{
		title: 'Name',
		dataSource: 'Name',
		sortable: true,
		width: 600
	},
	{
		title: 'Business Name',
		dataSource: 'BussinessName',
		sortable: true,
		width: 300
	},
	{
		title: 'EIN',
		dataSource: 'Tin',
		width: 500
	},
	{
		title: 'Federal Tax Classification',
		dataSource: 'TaxClass',
		width: 300
	},
	{
		title: 'Details',
		dataSource: 'Details',
		width: 300
	},
	{
		title: '',
		dataSource: 'SendInvitation',
		width: 300
	},
	{
		title: 'Status',
		dataSource: 'Status',
		width: 200
	},
	{
		title: '',
		dataSource: 'LockedService',
		width: 200
	}
]
export default ProvidersTableConfig
