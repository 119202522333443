/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

/**Import actions sectiuon */
import {
	loadPaymentRequests,
	loadDashboardPaymentRequests,
	downloadReportPaymentRequest
} from '../../store/paymentrequests/paymentRequestActions'
import { loadAllServiceProviderWithFilters } from '../../store/serviceproviders/serviceProvidersActions'
import { loadAllCustomers } from '../../store/customers/customersActions'
import { toSafeObject } from '../../store/helpers/StateHelper'
import { loadInternalUsers } from '../../store/users/UsersActions'
/**Import components section */
import Toaster from '../common/Toaster'
import DesktopView from './components/IndexDesktopView'

/**
 * Component list payment requests view
 * @param {*} props
 */
const Index = (props) => {
	const dispatch = useDispatch()
	const history = useHistory()
	let location = useLocation()

	const paymentPartsIndex = useSelector((state) =>
		toSafeObject(state.paymentRequests.get('index'))
	)
	const isLoadingPayments = useSelector((state) =>
		state.paymentRequests.get('isLoadingPayments')
	)
	const userProfile = useSelector((state) =>
		toSafeObject(state.session.get('profile'))
	)
	const pagination = useSelector((state) =>
		state.paymentRequests.getIn(['index', 'pagination'])
	)
	const isLoadingDashboard = useSelector((state) =>
		state.paymentRequests.get('isLoadingDashboard')
	)
	const dashboard = useSelector((state) =>
		state.paymentRequests.get('dashboard')
	)
	const isDownloadReport = useSelector((state) =>
		state.paymentRequests.get('isDownloadReport')
	)

	/**Load all serviceProviders from redux store for filters */
	const serviceProviders = useSelector((state) =>
		state.serviceProviders.get('serviceProviders')
	)

	/**Load all customers from redux store for filters */
	const customers = useSelector((state) => state.customers.get('customers'))

	/**Local store this component section */
	const [query, setQuery] = useState('')
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})

	useEffect(() => {
		const queryParameters = queryString.parse(location.search)
		if (!!queryParameters.query) setQuery(queryParameters.query)
		dispatch(loadPaymentRequests(queryParameters)).catch(() => {})
	}, [dispatch, location.search])

	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		if (
			paymentPartsIndex?.paymentRequests.length === 0 &&
			queryParams.pageNumber > 1
		) {
			queryParams.pageNumber = 1
			history.push({ search: queryString.stringify(queryParams) })
		}
	}, [paymentPartsIndex, location.search, history])

	/**
	 * Load dasborad list spare parts
	 */
	useEffect(() => {
		dispatch(loadDashboardPaymentRequests()).then(() => {})
		dispatch(loadAllServiceProviderWithFilters(null, 1000))
		dispatch(loadAllCustomers(null, 1000))
	}, [dispatch])

	useEffect(() => {
		dispatch(loadInternalUsers())
	}, [dispatch])

	const onChangeLocation = (location) => history.push(location)

	const onCreateRequest = () => history.push('/paymentrequests/create')

	/**
	 * OpenRequest
	 */
	const onOpenRequest = (paymentRequest) => {
		history.push(`/paymentrequests/${paymentRequest.Token}`)
	}

	/**
	 * Action to update query
	 * @param {*} searchQuery
	 */
	const updateSearchQuery = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			setQuery(searchQuery)
		} else {
			delete queryParams.query
			setQuery('')
		}
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * On change page number
	 */
	const onChangePage = (event, page) => {
		const queryParams = queryString.parse(location.search)
		queryParams.pageNumber = page
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Update sort criteria
	 */
	const updateSortCriteria = (criteria) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = criteria.by
		queryParams.sortDirection = criteria.direction
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * Function download report payment
	 * @param {*} startDate
	 * @param {*} endDate
	 */
	const onDownloadReportPaymentRequest = (startDate, endDate) => {
		const queryParams = queryString.parse(location.search)
		return dispatch(
			downloadReportPaymentRequest(startDate, endDate, queryParams)
		)
			.then(() => {
				return Promise.resolve()
			})
			.catch((error) => {
				console.error(
					'Failed to download report payment request',
					error
				)
			})
	}

	return (
		<>
			<DesktopView
				isLoadingPayments={isLoadingPayments}
				paymentPartsIndex={paymentPartsIndex}
				onChangeLocation={onChangeLocation}
				onCreateRequest={onCreateRequest}
				onOpenRequest={onOpenRequest}
				userProfile={userProfile}
				onChangeSearchValue={(query) => updateSearchQuery(query)}
				searchValue={query}
				pagination={pagination}
				onChangePage={onChangePage}
				onChangeSortingCriteria={updateSortCriteria}
				isLoadingDashboard={isLoadingDashboard}
				dashboard={dashboard}
				serviceProviders={serviceProviders}
				onDownloadReportPaymentRequest={onDownloadReportPaymentRequest}
				isDownloadReport={isDownloadReport}
				customers={customers}
			/>

			<Toaster
				toasterOptions={toaster}
				onClose={() => setToaster({ open: false })}
			/>
		</>
	)
}
export default Index
