/**Import react section */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import propTypes from 'prop-types'
import { useForm } from "react-hook-form";
/**Import Material Ui section */
import { Grid, Typography, Chip } from '@material-ui/core'
/**Import Material UI Section */
import DialogActions from '@material-ui/core/DialogActions';

/**Import styles section */
import { createServicesStyle } from '../../../../resources/styles/CreateServiceStyles'
import { getOptionsReactSelect } from '../../../../store/helpers/FilterHelper'
import { toSafeObject } from '../../../../store/helpers/StateHelper'
/**Import components section */
import ButtonConfirm from '../../../common/Buttons/ButtonConfirm'
import ReactSelectAvatar from '../../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'

const ChangeRepSales = (props) => {
    /**Props section */
    const { isSavingService, isAuthorizadoForEditedData } = props;
    const { onChangeRepresentanteSales } = props;

    /**Custom hooks an styles */
    const classes = createServicesStyle();
    const { register, errors, control, handleSubmit } = useForm();
    const [assignementModel, setAssignementModel] = useState({salesRepresentative: props.salesRepresentative});

    /**Get option from store redux */
    const allRepSales = useSelector((state) => toSafeObject(state.salesRepresentatives.get('salesRepresentatives')))
    /**Map the options to show in the selector */
    let repSalesOptions = getOptionsReactSelect(allRepSales)

    /**Verify if can be sabed */
    const canBeSaved = () => {
        if (assignementModel ? (assignementModel.salesRepresentative === null) : true) return false;
        return true;
    }

    /** Invoke when select property change*/
    const onSelectChange = (name, option) => {
        setAssignementModel({
            ...assignementModel,
            [name]: option
        })
    }

    /**
     * function to assigned service
     */
    const changeRepresentate = () => {
        if (onChangeRepresentanteSales) {
            onChangeRepresentanteSales(assignementModel);
        }
    }

    return (
        <Grid item xs={12}>
            <Typography className={classes.title}>{"CHANGE SALES REPRESENTATIVE"}</Typography>
            <ReactSelectAvatar
                id='salesRepresentative'
                name="salesRepresentative"
                notShowTitle={true}
                placeholder={"SALES REPRESENTATIVE"}
                options={repSalesOptions}
                onChange={onSelectChange}
                formatOptionLabel={formatOptionLabel}
                value={(assignementModel.salesRepresentative && !!assignementModel.salesRepresentative.Code) ? repSalesOptions.find(x => x.value === assignementModel.salesRepresentative.Code) : null}
                register={(assignementModel && !!assignementModel.salesRepresentative) ? register({ name: 'salesRepresentative' }, { required: { value: false } }) : register({ name: 'salesRepresentative' }, { required: { value: true, message: "The rep is required" } })}
                errors={errors}
                control={control}
                isDisabled={!isAuthorizadoForEditedData}
            /><br />

            {isAuthorizadoForEditedData && <DialogActions>
                <ButtonConfirm
                    isSaving={isSavingService || !canBeSaved()}
                    onSavedClick={handleSubmit(changeRepresentate)} title='CONFIRM' />
            </DialogActions>}
        </Grid>
    )
}

const formatOptionLabel = ({ value, label, avatarUrl, isAvatarFromIdentity }) => (
    <div>
        <Chip
            id={value}
            size="small"
            avatar={
                <AvatarCustomSize
                    avatarUrl={avatarUrl}
                    name={label}
                    isAvatarFromIdentity={isAvatarFromIdentity}
                />}
            label={label}
            clickable
            style={{ color: "white", background: "#5357DC" }}
        />
    </div>
);


ChangeRepSales.propTypes = {
    isSavingService: propTypes.bool.isRequired,
    /**Function invoke callback for assigned service */
    onChangeRepresentanteSales: propTypes.func.isRequired,
    isAuthorizadoForEditedData: propTypes.bool.isRequired,
}
ChangeRepSales.defaultProps = {
    isSavingService: false,
    isAuthorizadoForEditedData: false,
    onChangeRepresentanteSales: () =>
        console.warn('Callback [onChangeRepresentanteSales] no defined'),
}


export default ChangeRepSales

