/**Import react section */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'
import { isNullOrEmpty } from '../../store/helpers/StringHelper'

/**Import components section */
import EditPanel from './components/EditPanel'
import Toaster from '../common/Toaster'
import ChangeStatusPanel from './components/ChangeStatusPanel'
import LateralPanel from '../common/lateralPanel/LateralPanel'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'

/**Import helper section */
import { SpareParRequestStatus } from '../../store/helpers/AppConstants'
import {
	deleteSparepartSales,
	confirmSendToPlant,
	sendRequestSalesImberaMx,
	closeRequestSales,
	cancelRequestSales
} from '../../store/sparePartSales/sparePartSalesAction'

import { getEmailUsersByRole, getEmailInternalUsersByRole } from '../../store/helpers/UserHelper'
import { SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN } from '../../resources/RolesEnum'
import { toSafeObject } from '../../store/helpers/StateHelper'

const Edit = (props) => {
	/**Use hooks section */
	const dispatch = useDispatch()
	const history = useHistory()

	/**Props section */
	const {
		editLateralPanelConfig,
		onCloseButtonClick,
		onUpdateSpartPart,
		userProfile,
		status,
		onCheckShipping,
		carrierData,
		isGettingTrackingData
	} = props
	const { lateralPanelStatusConfig, openLateralPanelChangeStatus } = props

	/**Use selector section */
	const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))
	const isSaving = useSelector((state) => state.sparePartSales.getIn(['show', 'isSaving']))
	const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
	let emailsroleImberaUsa = getEmailUsersByRole(SPARE_PARTS_IMBERA_USA, internalUsers)
	let emailsroleImberaMx = getEmailUsersByRole(SPARE_PARTS_IMBERA_MX, internalUsers)
	let emailsInternalUsers = getEmailInternalUsersByRole(
		[SPARE_PARTS_IMBERA_MX, SPARE_PARTS_IMBERA_USA, PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN],
		internalUsers
	)

	/**State local for this component*/
	const [partModel, setPartModel] = useState(null)
	const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, statusModel: null })
	const [toaster, setToaster] = useState({
		open: false,
		variant: null,
		message: null
	})

	useEffect(() => {
		setPartModel(props.part)
	}, [props.part])

	/**Open/close dialog confirm dialog user*/
	const onSetPasswordDialogConfiguration = (statusModel) => {
		setPasswordDialogConfiguration({
			opened: !passwordDialogConfiguration.opened,
			statusModel: statusModel
		})
	}

	const onDeleteSparePart = () => {
		return dispatch(deleteSparepartSales(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart.Token : ''))
			.then((_) => {
				onSetPasswordDialogConfiguration()
				setToaster({
					open: true,
					message: 'Spare part sales deleted success',
					variant: 'success'
				})
				history.push('/sparepartsales')
				openLateralPanelChangeStatus()
				return Promise.resolve()
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to deleted spart part requests',
					variant: 'warning'
				})
				console.error('Failed to removed spare part sales', error)
			})
	}

	/**
	 * Callback executed when the user submits the request
	 */
	const onConfirmSendToPlant = () => {
		dispatch(confirmSendToPlant(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart : null, emailsroleImberaUsa))
			.then(() => {
				onSetPasswordDialogConfiguration()
				history.push('/sparepartsales')
				return Promise.resolve()
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to submit spare part',
					variant: 'warning'
				})
			})
	}

	const onSendRequestImberaMx = () => {
		dispatch(sendRequestSalesImberaMx(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart.Token : null, emailsroleImberaMx))
			.then(() => {
				onSetPasswordDialogConfiguration()
				openLateralPanelChangeStatus()
				return Promise.resolve()
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to send mx spare part',
					variant: 'warning'
				})
			})
	}

	/**
	 * On close spare part requests
	 */
	const onCloseRequest = () => {
		dispatch(closeRequestSales(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart.Token : ''))
			.then(() => {
				onSetPasswordDialogConfiguration()
				openLateralPanelChangeStatus()
				setToaster({
					open: true,
					message: 'Request closed correctly',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to close spare part request',
					variant: 'warning'
				})
			})
	}

	/**
	 * On clancel spare part request
	 */
	const onCancelRequest = () => {
		dispatch(cancelRequestSales(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart.Token : '', emailsInternalUsers))
			.then(() => {
				onSetPasswordDialogConfiguration()
				openLateralPanelChangeStatus()
				setToaster({
					open: true,
					message: 'Request cancelled correctly',
					variant: 'success'
				})
			})
			.catch((error) => {
				setToaster({
					open: true,
					message: !isNullOrEmpty(error) ? error : 'An error to canelled spare part request',
					variant: 'warning'
				})
			})
	}

	/**
	 * Callback executed when the user rejected request
	 */
	const onRejectedRequestInPlant = () => {
		// dispatch(rejectedRequestInPlant(lateralPanelStatusConfig ? lateralPanelStatusConfig.spartPart : null, passwordDialogConfiguration.statusModel))
		//     .then(() => {
		//         onSetPasswordDialogConfiguration()
		//         history.push('/spareparts')
		//         return Promise.resolve();
		//     })
		//     .catch((error) => {
		//         setToaster({ open: true, message: !isNullOrEmpty(error) ? error : 'An error to submit spare part', variant: 'error' })
		//     })
	}

	/**
	 * Function to invoke action to change status
	 * @param {*} status
	 */
	const GetFunctionToInvoke = (status) => {
		switch (Number(status)) {
			case SpareParRequestStatus.IN_PROCESS:
			case SpareParRequestStatus.SEND_TECH_USA:
				return onConfirmSendToPlant
			case SpareParRequestStatus.DELETED:
				return onDeleteSparePart
			case SpareParRequestStatus.SEND_TO_MX:
				return onSendRequestImberaMx
			case SpareParRequestStatus.CLOSED:
				return onCloseRequest
			case SpareParRequestStatus.CANCELLED:
				return onCancelRequest
			case SpareParRequestStatus.REJECTED_RESEND:
				return onRejectedRequestInPlant
			default:
				return null
		}
	}

	const GetStatusDescription = (status) => {
		switch (Number(status)) {
			case SpareParRequestStatus.DRAFT:
				return 'This action will change the status of the spare part to Draft to Submitted'
			case SpareParRequestStatus.IN_PROCESS:
				return 'This operation will send the request for tracking and processing the tracking numbers'
			case SpareParRequestStatus.SEND_TO_MX:
				return 'This operation will send the request for the follow-up and the process of the tracking numbers for Imbera MX'
			case SpareParRequestStatus.CLOSED:
				return 'This operation ends the request for spare parts. To close the request, all tracking numbers must be added.'
			case SpareParRequestStatus.DELETED:
				return 'This action will delete the spare request, the information cannot be restored.'
			case SpareParRequestStatus.CANCELLED:
				return 'This operation cancels the request for spare parts. Cancel all parts of the request'
			case SpareParRequestStatus.SEND_TECH_USA:
				return 'This operation validate request from technical provider and Submitted for follow-up'
			case SpareParRequestStatus.REJECTED_RESEND:
				return "This operation rejects the request from the supplier's technician and is sent to the supplier for coding"
			default:
				return null
		}
	}

	return (
		<>
			{editLateralPanelConfig && editLateralPanelConfig.opened && (
				<EditPanel
					onCloseButtonClick={onCloseButtonClick}
					part={partModel}
					onUpdateSpartPart={onUpdateSpartPart}
					userProfile={userProfile}
					status={status}
					onCheckShipping={onCheckShipping}
					carrierData={carrierData}
					isGettingTrackingData={isGettingTrackingData}
				/>
			)}

			{lateralPanelStatusConfig && lateralPanelStatusConfig.opened && (
				<LateralPanel onClose={openLateralPanelChangeStatus}>
					<ChangeStatusPanel
						onCloseButtonClick={openLateralPanelChangeStatus}
						sparePartSales={lateralPanelStatusConfig && lateralPanelStatusConfig.spartPart}
						onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
					/>
				</LateralPanel>
			)}

			{passwordDialogConfiguration.opened && (
				<ConfirmPasswordDialog
					title={'Confirm operation'}
					open={passwordDialogConfiguration.opened}
					onClickButtonConfirm={GetFunctionToInvoke(
						passwordDialogConfiguration ? passwordDialogConfiguration.statusModel && passwordDialogConfiguration.statusModel.status : null
					)}
					isSaving={isValidatingPassword || isSaving}
					onClose={onSetPasswordDialogConfiguration}
					message1={`${GetStatusDescription(
						passwordDialogConfiguration.statusModel && passwordDialogConfiguration.statusModel.status
					)} . Are you sure to proceed  ${userProfile ? userProfile.name : ''}?`}
					message2='Enter the password to confirm the operation'
				/>
			)}

			<Toaster toasterOptions={toaster} onClose={() => setToaster({ open: false })} />
		</>
	)
}

Edit.propTypes = {
	/**Object have configuration for change status panel lateral */
	lateralPanelStatusConfig: propTypes.shape({
		opened: propTypes.bool,
		sparePart: propTypes.object
	}),
	/**Callback executed when user required change status the spare part requests and open lateral panel */
	openLateralPanelChangeStatus: propTypes.func.isRequired
}
Edit.defaultProps = {
	lateralPanelStatusConfig: {
		opened: false,
		sparePart: {}
	},
	openLateralPanelChangeStatus: () => console.warn('Callback [openLateralPanelChangeStatus] no defined')
}

export default withImmutablePropsToJS(Edit)
