/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

/**Import Material Ui section */
import {
    Grid,
} from '@material-ui/core'

/**Import components section */
import DateSelector from '../../common/DateSelector'
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'

import { getUserByUserName } from '../../../store/helpers/UserHelper'
import { toSafeObject } from '../../../store/helpers/StateHelper'
import TimeLineAttentionDate from '../componentsMobile/TimeLineAttentionDate'
const DateAttentionComponent = (props) => {
    /**objects section*/
    const { service } = props
    /**Functions sections*/
    const { onAddDateAttention } = props

    /**bool props section */
    const { isSavingService, isAuthorizadoForEditedData } = props
    const [dateAtentionModel, setDateAtentionModel] = useState({ comments: '', dateAttention: null });
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))
    let dispatcherService = getUserByUserName(internalUsers, service ? service.Dispatcher:null)

    useEffect(() => {
        if(props.service){
            setDateAtentionModel({
                "comments": props.service.TechnicianComments,
                "dateAttention": props.service.TechnicianServiceDate
            })
        }

    }, [props.service])

    const onDateAttentionAndCommnets = (event) => {
        event.stopPropagation()
        if (onAddDateAttention) {
            onAddDateAttention(service, dateAtentionModel, dispatcherService)
        }
    }

    const canBeSaved = () => {
        if (dateAtentionModel.dateAttention === null) return false;
        if ((dateAtentionModel.comments === null || dateAtentionModel.comments.length <= 0)) return false;
        return true;
    }


    /**On change properties */
    const onPropertyChange = event => {
        let { name, value } = event.target;
        if(name==="comments"){
            event.stopPropagation()
        }
        setDateAtentionModel({
            ...dateAtentionModel,
            [name]: value
        });
    }

    return (
        <Grid container>
            {isAuthorizadoForEditedData && <Grid item xs={12}>
                <DateSelector
                    dataCy='data-picker-date-attention'
                    label="ATTENTION DATE"
                    name='dateAttention'
                    showIncon={false}
                    active={true}
                    value={dateAtentionModel ? dateAtentionModel.dateAttention : null}
                    onChange={onPropertyChange}
                    disabled={!isAuthorizadoForEditedData}
                />
            </Grid>}
            {isAuthorizadoForEditedData && <Grid item xs={12}>
                <br/>
                <TextBox
                    active={true}
                    fullWidth
                    name="comments"
                    variant="outlined"
                    margin="dense"
                    label="TECHNICIAN COMMENTS"
                    multiline
                    rows={3}
                    autoFocus
                    onChange={onPropertyChange}
                    value={dateAtentionModel ? dateAtentionModel.comments : ""}
                    disabled={!isAuthorizadoForEditedData}
                />
            </Grid>}
            <Grid item xs={12} style={{ display:"flex", justifyContent: 'flex-end' }}>
                {
                    isAuthorizadoForEditedData && !service.IsClosed &&
                    <ButtonSaved
                        title="Save"
                        onSavedClick={onDateAttentionAndCommnets}
                        isSaving={isSavingService || !canBeSaved()}

                    />
                }

            </Grid>

            <Grid item xs={12}>
                <TimeLineAttentionDate
                    dateAttentions = {props.service? props.service.DateAttentions:[]}
                
                />

            </Grid>
        </Grid>
    )
}

export default DateAttentionComponent
