/**Import react section */
import React from 'react'
/**Import material ui section */
import { Typography, Grid, Divider, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

/**Import components section */
import CardComponent from '../../common/mobile/CardComponent'
import CardMobileSkeleton from '../../skeletons/services/componets/CardMobile'
/**Import resources styles */
import { ServiceCardMobileStyle } from '../../../resources/styles/mobile/ServiceCardMobileStyle'
import CallIcon from '@material-ui/icons/Call'
import iconSparePart from '../../../resources/images/sparePart.svg';
import { GetColorStatusService } from '../../../store/helpers/StatusHelper'

const ServicesCardsClosed = (props) => {
    const classes = ServiceCardMobileStyle();
    /**Array props section */
    const { services } = props

    /**Constant functions */
    const { redirectToServiceDetail, isLoading } = props

    const onCardClick = (item) => {
        if (redirectToServiceDetail) {
            redirectToServiceDetail(item.Token, item.Serie)
        }
    }

    /**Render text with name color bold */
    const onRenderText = (titleBold, title, phoneNumber) => {
        return (
            <>
                <div className={classes.flex}>
                    {
                        titleBold && <><Typography variant="subtitle2" className={classes.titleValue}>
                            <strong className={classes.titleBold}>{titleBold}</strong>
                            {title}
                        </Typography>
                            <br /><div className={classes.grow} />
                        </>
                    }

                    {
                        phoneNumber &&
                        <Typography noWrap variant="subtitle2" className={classes.titlePhone}>
                            <CallIcon className={classes.iconButtonPhone} size="small" /> {phoneNumber}
                        </Typography>
                    }
                </div>
                <Divider />
            </>
        )
    }

    const onRenderIcon = (ticket, status) => {
        return (
            <div className={classes.flex}>
                {ticket && <Typography variant="subtitle2" align="right"> {`Ticket: ${ticket}`}</Typography>}
                <IconButton
                    edge="end"
                    aria-label="toggle visibility"
                    className={GetColorStatusService(status, classes)}
                    size="small"
                >
                    <img src={iconSparePart}
                        alt='warranty-icon'
                        className={classes.faultWarrantyIcon}
                    />
                </IconButton>
            </div>
        )
    }

    /**Render content card text */
    const onRenderContenCard = (service) => {
        return (
            <>
                {service.SiteContactName && onRenderText("Site contact:", service.SiteContactName, service.SiteContactPhone, "", "")}
                {service.ContactName && onRenderText("Contact name:", service.ContactName, service.ContactPhone)}
                {service.full_address && onRenderText("Address:", service.full_address)}
            </>
        )
    }

    const onRenderCardContent = () => {
        if (isLoading) {
            return <CardMobileSkeleton />
        }
        else {
            return (
                <Grid
                    container
                    spacing={1}
                >
                    {
                        services.length !== 0 ? services.map((service) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={service.Id}
                                >
                                    <CardComponent
                                        title={`Serie : ${service.Serie}`}
                                        onRenderContent={() => onRenderContenCard(service)}
                                        item={service}
                                        onCardClick={onCardClick}
                                        onRenderContentRight={() => onRenderIcon(service.Ticket, service.Status)}
                                    /><br />
                                </Grid>
                            )
                        }) : <Alert severity="info" icon={false}>{" It does not have closed services."}</Alert>
                    }

                </Grid>
            )
        }
    }

    return <>
        {onRenderCardContent()}
    </>
}

export default ServicesCardsClosed
