/**Import react section */
import React from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import DesktopViewSkeleton  from '../../skeletons/regions/DesktopViewSkeleton'
import {
    Layout,
    Content,
    Header,
    HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'
import SideBar from '../../common/DashboadSideBar'
import Toolbar from '../../common/Toolbar'
import MapComponent from './MapComponent'
/**Import material ui section */
import { Typography } from '@material-ui/core'
/** Import styles */
import { regionsStyles } from '../../../resources/styles/RegionsStyles'
import iconSparePart from '../../../resources/images/sparePart.svg';

const DesktopView = (props) => {
    const { isLoading, allServicesByRegions } = props;
    const classes = regionsStyles();
    const onRenderContent = () => {
        if(isLoading){
            return <DesktopViewSkeleton/>
        }
        return (
            <div className={classes.root}>
                <Toolbar
                    title='REGIONS WITH SERVICES'
                    icon={iconSparePart}
                />
                <MapComponent
                    directions={allServicesByRegions}
                />
            </div>
        )

    }

    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                user={props.user}
                breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>
                            Services by regions
						</Typography>
                    </HeaderBreadCrum>
                }
            />
            <SideBar
                key={'AuthenticatedSideBar'}
                onChangeLocation={props.onChangeLocation}
                selectedButtonIndex={10}
                userProfile={props.userProfile}
            />
            <Content key={'AuthenticatedContent'}>
                {onRenderContent()}
            </Content>
        </Layout>
    )
}

export default withImmutablePropsToJS(DesktopView)