export default (mainColor, secondaryColor, percentage) => {
	var percentageFormat = `${percentage}%`
	if (percentage > 100) percentage = 100

	return {
		type: 'pie',
		backgroundColor: 'none',
		plot: {
			tooltip: {
				visible: false
			},
			valueBox: {
				type: 'first',
				text: percentageFormat,
				connected: false,
				fontColor: 'black',
				fontSize: '20px',
				fontWeight: 'normal',
				fontFamily: 'Roboto',
				placement: 'center',
				visible: true
			},
			animation: {
				delay: 0,
				effect: 'ANIMATION_EXPAND_VERTICAL',
				method: 'ANIMATION_LINEAR',
				sequence: 'ANIMATION_BY_PLOT',
				speed: '600'
			},
			detach: false,
			hoverState: {
				visible: false
			},
			refAngle: 50,
			slice: 50
		},
		plotarea: {
			backgroundColor: 'transparent',
			margin: '5px'
		},
		series: [
			{
				values: [percentage],
				backgroundColor: secondaryColor,
				shadow: true
			},
			{
				values: [100 - percentage],
				backgroundColor: mainColor,
				shadow: false
			}
		]
	}
}
