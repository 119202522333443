import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'
export const IS_LOADING_SERVICES = 'IS_LOADING_SERVICES'
export const SET_SERVICES = 'SET_SERVICES'

/**
 * Is loading action constructor
 */
export const isLoadingServices = (status) => {
	return {
		type: IS_LOADING_SERVICES,
		status
	}
}

/**
 * Set services in action list
 */
export const setServicesList = (services) => {
	return {
		type: SET_SERVICES,
		services
	}
}

export const loadServices = (status) => {
	return async (dispatch, getState) => {

		try {
			dispatch(isLoadingServices(true))
			const loadServicesFromBackend = async () => {
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Get the users
				let url = `${window.config.serviceManagementBackendUrl}/services/regions/${status}`
				let serverResponse = await axios.post(url, null, config)
				let serviceList = serverResponse.data;
				dispatch(setServicesList(serviceList))
			}
			return loadServicesFromBackend()
		}
		catch (ex) {
			console.error('Errog getting the service list', ex)
		}

	}
}

export const getGroupedServicesByRegionAndStatus = (services, criteria) => {
	if (criteria === 'NewServices') {
        return services.reduce((property, service) => {
            let location = service.Address && service.Address.locality;
            if(!services.Address[location].hasOwnProperty(location)){
                services.Address[location] = 0
            }
            property.Address[location]++;
            return property
		}, {})
	}
}