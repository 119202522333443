import React from 'react'
import propTypes from 'prop-types'
import numeral from 'numeral'
import { useDashboardCardStyles } from '../../../resources/styles/DashboardStyles'

// Import components
import { Typography } from '@material-ui/core'
import ZingChart from 'zingchart-react'

import chartConfig from './ChartConfiguration'

/**
 * Dashboard card component
 * @param {*} props
 */
const DashboardCard = (props) => {
	const classes = useDashboardCardStyles({
		mainColor: props.mainColor,
		secondaryColor: props.secondaryColor,
		height: props.height
	})
	const charData = chartConfig(props.mainColor, props.secondaryColor, props.percentajeValue)

	var valueFormated = ''
	if (props.totalServices < 1000) valueFormated = numeral(props.totalServices).format('0,0')
	else if (props.totalServices >= 1000 && props.totalServices < 10000)
		valueFormated = numeral(props.totalServices).format('0.00a')
	else valueFormated = numeral(props.totalServices).format('0.0a')

	return (
		<div className={classes.root}>
			<div className={classes.leftContainer}>
				<Typography variant='h3' className={classes.primaryText}>
					{valueFormated}
				</Typography>
				<Typography
					className={classes.secondaryText}
					variant='overline'
				>
					{props.label}
				</Typography>
			</div>
			<div className={classes.rightContainer}>
				<ZingChart data={charData} height={props.height} />
			</div>
			<div className={classes.bottomBorder}></div>
		</div>
	)
}
DashboardCard.propTypes = {
	mainColor: propTypes.string.isRequired,
	secondaryColor: propTypes.string.isRequired,
	totalServices: propTypes.number.isRequired,
	percentajeValue: propTypes.number.isRequired,
	label: propTypes.string.isRequired,
	height: propTypes.number.isRequired
}
DashboardCard.defaultProps = {
	mainColor: '#D3D3D3',
	secondaryColor: '#ADADAD',
	totalServices: 0,
	percentajeValue: 0,
	label: 'label',
	height: 180
}
export default DashboardCard
