/**Import react section */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4'

/** Material UI Section*/
import {
    makeStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Grid,
    LinearProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

/** Custom component import section */
import DropDownZone from '../../common/DropDownZone';
import FileContent from '../../common/FileContent';
import AlertComponent from '../../common/AlertComponent'

const DialogEvidence = (props) => {
    const classes = useStyles();
    const [xlsx, setxlsx] = useState([]);

    

    const canBeSaved = () => {
        if (xlsx.length > 0) return false;
        return true;
    }

    /**
     * Remove file added of state 
     * @param {*} fileId 
     */
    const onRemoveFile = (fileId) => {
        let index = xlsx.findIndex(attachment => attachment.id === fileId);
        if (index !== -1) {
            let newListFiles = xlsx.filter(file => {
                return file.id !== fileId
            })
            setxlsx(newListFiles)
        }
    }

    /**
     * Save information in back end
     */
    const onSaveButtonClick = () => {
        if (props.onSaveEvidence) {
            props.onSaveEvidence(xlsx);
        }
    }

    /**Exist document add with name */
    const onExisteDocumentWithSameName = (fileName) => {
        let existDocument = xlsx.find(u => u.fileName === fileName);
        return existDocument ? true : false;
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="button" className={classes.title}><strong>{props.title}</strong></Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {!props.existTerms && <Grid item xs={12}>
                        <AlertComponent
                            title="Add warranty series"
                            severityType="info"
                            message={"Add SAP warranty series, by adding them all the warranty series will be updated, the existing ones will be eliminated and replaced by the ones you upload in this section."}
                        />
                    </Grid>}
                   
                    <Grid item xs={12} >
                        <DropDownZone
                            datacy='drop-zone-documents-operating'
                            classes={classes}
                            accept= {props.accept}
                            multiple={false}
                            message={"Click here to select files"}
                            onFilesReceived={(files) => {
                                if (files.length > 0) {
                                    const filesAdded = files.map((file) => {
                                        let existFile = onExisteDocumentWithSameName(file.name);
                                        return ({
                                            id: uuidv4(),
                                            attachment: file,
                                            existFile: existFile
                                        })
                                    })
                                    setxlsx(xlsx.concat(filesAdded))
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        {
                            xlsx.length > 0 &&
                            <FileContent
                                files={xlsx}
                                onRemoveFile={onRemoveFile}
                            />
                        }
                    </Grid>

                </Grid>
            </DialogContent>
            {props.isSaving && <LinearProgress color="secondary" />}
            <DialogActions>
                <div className={classes.wrapper}>
                    <Button
                        data-cy="btn-accept"
                        className={classes.buttonAccept}
                        variant='contained'
                        onClick={onSaveButtonClick}
                        disabled={canBeSaved() || props.isSaving}
                    >
                        {!props.existTerms && "Confirm add warranty series"}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

DialogEvidence.propTypes = {
    onSaveEvidence: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,

}
DialogEvidence.defaultProps = {
    open: false,
    isSaving: false
}

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.colors.purple
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonAccept: {
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        right: "10px",
        "&:hover": {
            backgroundColor: theme.palette.colors.green
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.green,
            color: theme.palette.common.white,
            opacity: .75,
        }
    },
    buttonProgress: {
        color: theme.palette.prim,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    closeButton: {
        position: 'absolute',
        right: "22px",
        top: theme.spacing(1),
        backgroundColor: theme.palette.colors.red,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.colors.red
        },
        width: "37px",
        height: "36px",
        paddingTop: 8
    },
}));


export default DialogEvidence
