/**Import react section */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'

/** Material UI import section */
import { Button, DialogContent, Typography } from '@material-ui/core'
import { ButtonCreateStyles } from '../../../resources/styles/ButtonCreateStyles'

/** Custom components import section */
import Dialog from '../../common/Dialog'
import AlertComponent from '../../common/AlertComponent'

/** Resources import section */
/**
 * @param {*} props
 * @returns
 */
const ConfirmCommentHistoryUpdateDialog = (props) => {
	const classes = ButtonCreateStyles()
	const { onClose, onSaveCommentFromModal } = props

	/**
	 * Hanlde click event from accept button
	 */
	const onHandleClick = () => {
		onSaveCommentFromModal()
		onClose()
	}

	return (
		<>
			<Dialog
				open={props.open}
				onClose={onClose}
				header={<strong>CONFIRM FIRST COMMENT EDITION</strong>}
				actions={
					<Fragment>
						<Button
							variant='contained'
							className={classes.btnSaved}
							onClick={onHandleClick}
						>
							CONFIRM
						</Button>
					</Fragment>
				}
			>
				<DialogContent>
					{
						<AlertComponent
							title={'Warning'}
							message={
								'You are attempting to edit the first comment'
							}
							severityType={'warning'}
						/>
					}
					<br />

					<Typography variant='subtitle2'>
						Are you sure you want to edit the first comment?
					</Typography>
					<br />
				</DialogContent>
			</Dialog>
		</>
	)
}

ConfirmCommentHistoryUpdateDialog.propTypes = {
	/**Triggers the save of the first comment*/
	onSaveCommentFromModal: propTypes.func,
	/**Determines if the dialog is open */
	open: propTypes.bool,
	/**Handles closing the dialog  */
	onClose: propTypes.func.isRequired
}

ConfirmCommentHistoryUpdateDialog.defaultProps = {
	open: false,
	onSaveCommentFromModal: () =>
		console.warn('Callback [onSaveCommentFromModal] no defined'),
	onClose: () => console.warn('Callback [onClose] no defined')
}

export default ConfirmCommentHistoryUpdateDialog
