import { makeStyles } from '@material-ui/styles'

export const panelEditUseStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    titleContainer: {
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr',
    },
    titleLeftContent: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRow: 1,
        paddingRight:"20px",
        paddingLeft:"20px",
        paddingTop:"10px"
    },
    titleRightContent: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        paddingRight:"30px",
        paddingTop:"10px"
    },
    editTab: {
        padding: "20px",
    },
    drawerContent: {
        width: "620px",
        height: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    carrierHeader: {
        padding: "5px",
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '50px 150px 150px 1fr',
    },
    carrierContent: {
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '150px 150px 1fr',
    },
    cell1: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRow: 1,
        color: theme.palette.text.primary,
        paddingRight: "5px"
    },
    cell2: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    cell3: {
        gridColumnStart: 3,
        gridColumnEnd: 3,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    cell4: {
        gridColumnStart: 4,
        gridColumnEnd: 4,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    cell5: {
        gridColumnStart: 5,
        gridColumnEnd: 5,
        gridRow: 1,
        color: theme.palette.text.primary,
    },
    carrierHeaderGrid: {
        display: "grid",
        gridTemplateRows: 'auto',
        gridTemplateColumns: '350px 1fr',
    },
    headerLeft: {
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridRow: 1,
        padding: "5px"
    },
    headerRight: {
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridRow: 1,
        padding: "5px"
    },
    titleBold: {
        fontWeight: 'bold'
    },
    titleRight: {
        marginLeft: "3px",
    },
    flex: {
        display: 'flex',
    },
    stepper: {
        alignSelf: 'stretch',
        marginTop:"-8px"
    },
    stepLabel: {
        fontSize: '10px'
    },
    containerLineTime:{
        maxHeight: `calc(100vh - ${520}px)`,
        overflowY: "auto",
    },
    fileImport:{
       marginRight: '5px',
       marginBottom:'-5px', 
       display: "flex",
       justifyContent: "flex-end"
    }
}))