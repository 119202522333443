/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js';

/**Import component sectcion */
import { headersTableConfig } from '../resources/internalUsersConfig'
import SearchBox from '../../../common/SearchToolbox'
import {
	Layout,
	Content,
	Header,
	HeaderBreadCrum
} from '../../../layouts/AuthenticatedLayout'
import { useDataGridToolbarStyle } from '../../../../resources/styles/CommonIndexStyles'
import AvatarCustomSize from '../../../common/AvatarCustomSizes'
import RenderContentIndex from '../../../common/renderContentIndex/RenderContentIndex'
import Pagination from '../../../common/Pagination/Pagination'

/**Import Material UI Section */
import { Typography, ButtonGroup, Tooltip, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core'
import UserProfileIcon from '@material-ui/icons/AccountBoxOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import GridIcon from '@material-ui/icons/GridOn';

/**
 * Desktop view for the list on imbera users account
 */
const DesktopView = (props) => {
	const classes = useDataGridToolbarStyle();

	/**Functions props section */
	const {
		openLateralPanelEditOnClick,
		openLateralPanelOnClick,
		switchViewMode,
		onSetPasswordDialogConfiguration,
		identifierCardSeleted,
		onChangePage,
		pagination,
		onDownloadReport
	} = props;
	/**bool props section */
	const { isSaving } = props;
	/**Objects props sections */
	const { gridMode, loggedUserProfile } = props;


	/**Opened lateral panel for edit information */
	const onButtonProfileClick = (item) => {
		if (openLateralPanelEditOnClick) {
			openLateralPanelEditOnClick(item)
		}
	}

	/**Opened lateral panel for edit information */
	const onCardClick = (item) => {
		if (props.openLateralPanelEditOnClick) {
			props.openLateralPanelEditOnClick(item)
		}
	}

	/** Render menu options for list in mode cards*/
	const renderOptionMenu = (user) => {
		let isDisabledOption = (loggedUserProfile.userName !== user.UserName) ? false : true
		return (
			<>
				{
					<ListItem
						button
						data-cy='item-remove-access'
						disabled={isSaving || isDisabledOption}
						onClick={() => onSetPasswordDialogConfiguration(user)}
					>
						<ListItemIcon>
							<DeleteIcon color='action' />
						</ListItemIcon>
						<ListItemText primary={"Remove Access"} />
					</ListItem>}
			</>

		)
	}


	/**
	 * Get the header configuration
	 */
	const getHeaderConfiguration = () => {
		let activeProperty = headersTableConfig.find(
			(x) => x.dataSource === 'Active'
		)
		activeProperty.onRenderProperty = (user) => {
			let isDisabledOption = (loggedUserProfile.userName !== user.UserName) ? false : true

			return (
				<ButtonGroup variant='text' size='small'>
					<Tooltip component="span" title='View Profile'>
						<Button
							onClick={() => onButtonProfileClick(user)}
						>
							<UserProfileIcon color='primary' />
						</Button>
					</Tooltip>
					<Tooltip component="span" title='Remove Access'>
						<Button
							disabled={isDisabledOption}
							onClick={() => onSetPasswordDialogConfiguration(user)}
						>
							<DeleteIcon color='action' />
						</Button>
					</Tooltip>
				</ButtonGroup>
			)
		}
		let avatarProperty = headersTableConfig.find(
			(x) => x.dataSource === 'avatar'
		)
		avatarProperty.onRenderProperty = (item) => {
			return (
				<AvatarCustomSize
					name={item.Name}
					avatarUrl={item.AvatarUrl}
					avatarStyle={classes.avatar}
					isAvatarFromIdentity={true}
				/>
			)
		}
		return headersTableConfig
	}

	/**Render content in cads o en mode list */
	const renderContent = () => {
		return (
			<RenderContentIndex
				headerConfig={getHeaderConfiguration()}
				isLoading={props.isLoading}
				data={props.users}
				gridMode={gridMode}
				onChangeSortingCriteria={props.onChangeSortingCriteria}
				searchValue={props.searchValue}
				titleData={"There is no added data, please add a user."}
				sortBy={props.sortBy}
				sortDirection={props.sortDirection}
				onEditClick={onCardClick}
				renderOptionMenu={renderOptionMenu}
				isActiveButtonOptions={true}
				isAvatarFromIdentity={true}
				identifierCardSeleted={identifierCardSeleted}
			/>
		)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>
							Administration / Imbera users
						</Typography>
					</HeaderBreadCrum>
				}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<DataGridToolbar
						onChange={props.onChangeSearchValue}
						searchValue={props.searchValue}
						openLateralPanelOnClick={openLateralPanelOnClick}
						switchViewMode={switchViewMode}
						gridMode={gridMode}
						onDownloadReport={onDownloadReport}
					/>
					{renderContent()}
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination count={pagination.total ? pagination.total : 0} page={pagination.pageNumber ? pagination.pageNumber : 0} onChangePage={onChangePage} />
					</div>
				</div>
			</Content>


		</Layout>
	)
}
DesktopView.propTypes = {
	/**
	 * The imbera user's list
	 */
	users: propTypes.arrayOf(
		propTypes.shape({
			UserName: propTypes.string.isRequired,
			Email: propTypes.string.isRequired,
			Active: propTypes.bool.isRequired
		})
	),

	/**
	 * Determine if the user list's is loading
	 */
	isLoading: propTypes.bool.isRequired,
	/**
	 * Determine if the isSaving data in database
	 */
	isSaving: propTypes.bool.isRequired,

	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,

	/**
	 * On change sort criteria callback
	 */
	onChangeSortingCriteria: propTypes.func.isRequired,
	/**
	 * On change search value
	 */
	onChangeSearchValue: propTypes.func.isRequired,
	/**
	 * Determine the initial sort by column
	 */
	sortBy: propTypes.string,
	/** 
	* Defines the initial sort direction
	*/
	sortDirection: propTypes.oneOf(['asc', 'desc']),
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool,
	/**
	 * Opens side panel to add new  user's
	 */
	openLateralPanelOnClick: propTypes.func.isRequired,
	/**
	 * Open modal for emove an user fron this list user for company IMBERA
	 */
	onSetPasswordDialogConfiguration: propTypes.func.isRequired,
}
DesktopView.defaultProps = {
	users: [],
	isLoading: false,
	isSaving: false,
	gridMode: false,
	sortBy: "",
	sortDirection: "asc",
	searchValue: "",
	identifierCardSeleted: "",
	onChangeSortingCriteria: () =>
		console.warn('Callback [onChangeSortingCriteria] no defined'),
	onChangeSearchValue: () =>
		console.warn('Callback [onChangeSearchValue] no defined'),
	openLateralPanelOnClick: () =>
		console.warn('Callback [openLateralPanelOnClick] no defined'),
	switchViewMode: () =>
		console.warn('Callback [switchViewMode] no defined'),
	onSetPasswordDialogConfiguration: () =>
		console.warn('Callback [onSetPasswordDialogConfiguration] no defined'),
}
export default withImmutablePropsToJS(DesktopView)

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()

	return (
		<div className={classes.searchIndex}>
			<SearchBox
				placeholder='Search user'
				onChange={props.onChange}
				value={props.searchValue}
			/>
			<ButtonGroup component="span" size='small' variant="outlined" color="primary" className={classes.buttonsGroupContainer}>
				<Tooltip component="span" title='Download list of internal users'>
					<Button
						className={classes.buttonsGroup}
						onClick={props.onDownloadReport}
					>
						<CloudDownloadIcon />
					</Button>
				</Tooltip>
				<Tooltip component="span" title='Mode list'  >
					<Button
						className={classes.buttonsGroup}
						disabled={!props.gridMode}
						onClick={props.switchViewMode}
					>
						<ListIcon />
					</Button>
				</Tooltip>
				<Tooltip component="span" title='Mode card' >
					<Button
						className={classes.buttonsGroup}
						disabled={props.gridMode}
						onClick={props.switchViewMode}
					>
						<GridIcon />
					</Button>
				</Tooltip>
				<Tooltip component="span" title='Add new user'>
					<Button
						className={classes.buttonsGroup}
						onClick={props.openLateralPanelOnClick}
					>
						<AddIcon />
					</Button>
				</Tooltip>
			</ButtonGroup>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool,
	/**
	 * Opens side panel to add new  user's
	 */
	openLateralPanelOnClick: propTypes.func.isRequired,

}

DataGridToolbar.defaultProps = {
	gridMode: false,
	searchValue: "",
	onChange: () =>
		console.warn('Callback [onChange] no defined'),
	switchViewMode: () =>
		console.warn('Callback [switchViewMode] no defined'),
	openLateralPanelOnClick: () =>
		console.warn('Callback [openLateralPanelOnClick] no defined'),
}
