export const existencesTableConfig = [
	{
		title: 'Center',
		dataSource: 'Center',
    },
    {
		title: 'Material',
		dataSource: 'Code',
    },
    {
		title: 'Description material',
		dataSource: 'Description',
    },
    {
		title: 'Warehouse',
		dataSource: 'WarehouseCode',
    },
    {
		title: 'Description Warehouse',
		dataSource: 'DescriptionWarehouse',
    },
    {
		title: 'Stock',
		dataSource: 'Stock',
    },
    {
		title: 'UM',
		dataSource: 'UM',
    },
]
export default existencesTableConfig
