/**Import react section */
import React from 'react'
import { useForm } from 'react-hook-form'
import propTypes from 'prop-types'

/**Import Material UI Section */
import { Grid, Typography, Card, Chip } from '@material-ui/core'

/**Import componets section */
import ReactSelectAvatar from '../../common/ReactSelectAvatar'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import MapComponet from '../../services/components/MapComponent'
import CreateAddress from './CreateAddress'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import ButtonCancel from '../../common/Buttons/ButtonCancel'

/**import resources section */
import { useCreateDesktopStyle } from '../resources/useCreateDesktopStyle'
import { CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN } from '../../../resources/RolesEnum'
import { hasAuthorization } from '../../../store/session/sessionActions'

const HeaderFormCreate = (props) => {
    const classes = useCreateDesktopStyle();
    let isUserCustomer = hasAuthorization(props.userProfile, [CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN])

    const { register, handleSubmit, errors, control } = useForm()

    /**Functions section */
    const { onSelectChange, onChangePosition, onChangeAddress,
        redirectToSpartPart, addSparePartOnClick, onPropertyChange, } = props

    /**objects section */
    const { mapPostionModel, spartPartModel } = props

    /**Array sections props */
    const { allDirections, customersOptions, customersAvatarOptions } = props
    /** */
    const { isSaving } = props;
    return (
        <Grid container spacing={1} >
            {!isUserCustomer && <HeaderComponent
                register={register}
                errors={errors}
                control={control}
                onSelectChange={onSelectChange}
                customersOptions={customersOptions}
                customersAvatarOptions={customersAvatarOptions}
                sparePartModel={spartPartModel}
                userProfile={props.userProfile}
            />}
            <Card style={{ padding: "20px", }}>
                <Typography variant='overline'> <strong>{'Shipping address'}</strong> </Typography>
                <Grid item xs={12} md={12}>
                    <Typography variant='overline'> {'Find your address'} </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <MapComponet
                                position={mapPostionModel}
                                onChangePosition={onChangePosition}
                                onChangeAddress={onChangeAddress}
                                directions={allDirections}
                                modeCreate={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ marginTop: "-8px" }}>
                            <CreateAddress
                                addressModel={
                                    spartPartModel
                                        ? spartPartModel.Address
                                        : {}
                                }
                                onPropertyChange={onPropertyChange}
                                spartPartModel={spartPartModel}
                                mode={'MODE_CREATE'}
                                register={register}
                                errors={errors}
                            />
                        </Grid>

                    </Grid>

                    <div className={classes.buttonActions}>
                        <ButtonCancel
                            disabled={isSaving}
                            onCancelClick={
                                redirectToSpartPart
                            }
                            title='Cancel'
                        />
                        <ButtonSaved
                            isSaving={isSaving}
                            onSavedClick={handleSubmit(
                                addSparePartOnClick
                            )}
                            title='CREATE'
                        />
                    </div>
                </Grid>
            </Card>
        </Grid>
    )
}

const HeaderComponent = (props) => {
    /**Functions props*/
    const { onSelectChange } = props
    /**Objects section */
    const { sparePartModel } = props;
    /**Array options */
    const { customersOptions, customersAvatarOptions } = props;

    /**Register properties for validate information react hook form */
    const { errors, control } = props;
    return (
        <Grid item xs={12}>
            <Card style={{ padding: "20px", marginLeft: "-3px", marginRight: "-3px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <ReactSelectAvatar
                            id='Customer'
                            name='Customer'
                            placeholder={'Customer'}
                            avatarOptions={customersAvatarOptions}
                            options={customersOptions}
                            formatOptionLabel={formatOptionLabel}
                            onChange={onSelectChange}
                            value=
                            {sparePartModel
                                ? sparePartModel.Customer
                                : null}
                            errors={errors}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

HeaderFormCreate.propTypes = {
    /**List of customers */
    customers: propTypes.array.isRequired,
    isSaving: propTypes.bool.isRequired,
    /**Function invoke callback for create a spart part manual */
    addSparePartOnClick: propTypes.func.isRequired,
    /**Redirects to the spare part view when you don't want to create a new spare part*/
    onRedirectToHome: propTypes.func.isRequired
}

HeaderFormCreate.defaultProps = {
    isSaving: false,
    customers: [],
    addSparePartOnClick: () => console.warn('Callback [addSparePartOnClick] no defined'),
    onRedirectToHome: () =>
        console.warn('Callback [onRedirectToHome] no defined')
}

const formatOptionLabel = ({ value, label, avatarName, avatarUrl, isAvatarFromIdentity }) => (
    <div>
        <Chip
            id={value}
            size="small"
            avatar={
                <AvatarCustomSize
                    avatarUrl={avatarUrl}
                    name={avatarName}
                    isAvatarFromIdentity={isAvatarFromIdentity}
                />}
            label={label}
            clickable
            style={{ color: "white", background: "#5357DC" }}
        />
    </div>
);

export default HeaderFormCreate