
/**Import react section */
import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
/**Import material ui section */
import { useCardStyles } from '../../../../resources/styles/mobile/CardStyle'

import { Card, Grid } from '@material-ui/core'
const CardMobile = () => {
    const classes = useCardStyles();
    const cards = ["1", "2", "3", "4"];

    return (
        <Grid container spacing={1}>
            {cards.map(s => {
                return (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        key={s}
                    >
                        <Card className={classes.root} key={s}>
                            <div className={classes.headerSkeleton}>
                                <Skeleton variant="rect" width={"100%"} height={30} />
                            </div>
                            <div className={classes.content}>
                                <Skeleton variant="rect" width={"100%"} height={50} />
                                <Skeleton variant="rect" width={"100%"} height={50} />
                                <Skeleton variant="rect" width={"100%"} height={50} />
                            </div>

                        </Card>

                    </Grid>

                )
            })}


        </Grid>
    )
}

export default CardMobile