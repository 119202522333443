/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
/**Import components sections */
import {
    Layout,
    Content,
    Header,
    HeaderBreadCrum
} from '../../layouts/AuthenticatedLayout'
import RegisterTable from './Stepper/RegisterTable'
import AssistanceStepper from './Stepper/AssistanceStepper'
import AcceptTermsAndConditions from './Stepper/AcceptTermsAndConditions'
/**Import material UI Section */
import { Typography, Button, Card, CardContent, CardActions,
    Checkbox,  FormControlLabel} from '@material-ui/core'
/**Import resources section */
import { desktopViewRegisterStyles } from '../resources/DesktopViewRegisterStyles'
import { hasAuthorization } from '../../../store/session/sessionActions'
import { SERVICE_PROVIDER_MANAGER } from '../../../resources/RolesEnum'

const DesktopViewRegister = (props) => {
    /**Functions */
    const { onOpenEvidenceDialog, onOpenDialogPassword,
        onSenToAutorizer, onMoveBackWardHandler,
        onMoveForwardHandler, onAcceptTermsGuarantee,
        onOpenDialogForGrantedAccess, onRedirectToServiceProvider,
        termsAndConditions
    } = props;
    /**Bool */
    const { isLoading, isFowardEnabled, isBackwardEnabled, isSaving } = props;
    /**objects */
    const { serviceProvider, userProfile } = props;
    /**number props*/
    const { activeStep } = props;
    let isValidToAddOtherFiles = hasAuthorization(userProfile, [SERVICE_PROVIDER_MANAGER])

    const classes = desktopViewRegisterStyles();

    const onSendToAuthorizerDocuments = () => {
        if (onSenToAutorizer) {
            onSenToAutorizer(serviceProvider ? serviceProvider.Token : '')
        }

    }

    const onAccepTerms = (event) => {
        if (onAcceptTermsGuarantee) {
            onAcceptTermsGuarantee(serviceProvider ? serviceProvider.Token : '', event.target.checked)
        }
    }

    const getStepComponent = (activeStep) => {
        switch (activeStep) {
            case 0:
                return (
                    <div className={termsAndConditions ? "" : classes.acceptTermsContainer}>
                        <AcceptTermsAndConditions
                            onAccepTerms={onAccepTerms}
                            acceptTermsGuarantee={serviceProvider ? serviceProvider.AcceptTermsGuarantee : false}
                            isSaving={isSaving || !(serviceProvider ? serviceProvider.CanBeEditedDataInRegister : false)}
                            termsAndConditions={termsAndConditions}
                        />
                    </div>
                )

            case 1:
                return <RegisterTable
                    isSaving={isSaving}
                    serviceProvider={serviceProvider}
                    isLoading={isLoading}
                    urlDoument={serviceProvider ? serviceProvider.UrlDoument : ''}
                    onOpenDialogPassword={onOpenDialogPassword}
                    onSendToAuthorizerDocuments={onSendToAuthorizerDocuments}
                    onOpenEvidenceDialog={onOpenEvidenceDialog}
                    userProfile={userProfile}
                    onOpenDialogForGrantedAccess={onOpenDialogForGrantedAccess}
                    onRedirectToServiceProvider={onRedirectToServiceProvider}
                />
            case 2:
                return (
                    <RegisterTable
                        isSaving={isSaving}
                        serviceProvider={serviceProvider}
                        isLoading={isLoading}
                        urlDoument={serviceProvider ? serviceProvider.UrlDoument : ''}
                        onOpenDialogPassword={onOpenDialogPassword}
                        onSendToAuthorizerDocuments={onSendToAuthorizerDocuments}
                        onOpenEvidenceDialog={onOpenEvidenceDialog}
                        userProfile={userProfile}
                        onOpenDialogForGrantedAccess={onOpenDialogForGrantedAccess}
                        onRedirectToServiceProvider={onRedirectToServiceProvider}
                        activeStep={activeStep}
                        isValidToAddOtherFiles={isValidToAddOtherFiles}
                    />
                )
            default: {
                return null;
            }
        }
    }

    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                user={props.user}
                breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>
                            Service provider / Register
						</Typography>
                    </HeaderBreadCrum>
                }
            />
            <Content key={'AuthenticatedContent'}>
                <div className={classes.root}>

                    <AssistanceStepper
                        activeStep={activeStep}

                    />
                    <Card className={classes.cardContainer}>
                        <CardContent className={classes.cardContent}>
                            {getStepComponent(activeStep)}
                        </CardContent>
                        <CardActions className={classes.actionButton}>
                            {(termsAndConditions && activeStep === 0) && <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={serviceProvider ? serviceProvider.AcceptTermsGuarantee : false}
                                        onChange={onAccepTerms}
                                        name="checkedB"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label="Accept terms and conditions"
                            />}
                            
                            {
                                activeStep !== 2 && <Button
                                variant='contained'
                                color={"primary"}
                                disabled={(!isBackwardEnabled || activeStep === 0 )}
                                onClick={onMoveBackWardHandler}
                            >
                                BACK
                            </Button>
                            }
                            {
                                activeStep !== 2 &&
                                <Button
                                    variant='contained'
                                    color={"primary"}
                                    disabled={!isFowardEnabled
                                        || (serviceProvider ? !serviceProvider.AcceptTermsGuarantee : false)
                                        || (activeStep === 1 && (serviceProvider ? serviceProvider.CanBeEditedDataInRegister : false)
                                        )
                                    }
                                    onClick={onMoveForwardHandler}
                                >
                                    NEXT
                            </Button>
                            }

                        </CardActions>

                    </Card>
                </div>

            </Content>
        </Layout >
    )
}

/**Proptypes this component */
DesktopViewRegister.propTypes = {
    /**
	 * On open modal for add attachment required for register
	 */
    onOpenEvidenceDialog: propTypes.func.isRequired,
    /**
    * Determinate is saving when add attachments or accepted terms
    */
    isSaving: propTypes.bool.isRequired,
}


DesktopViewRegister.defaultProps = {
    onOpenEvidenceDialog: () =>
        console.warn('Callback [onOpenEvidenceDialog] no defined'),
    isSaving: false
}

export default withImmutablePropsToJS(DesktopViewRegister)
