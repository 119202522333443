/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

/**Import Material UI section */
import { Grid, Typography } from '@material-ui/core'

/**Import component section */
import SparePartRequestForm from './SparePartRequestForm'
import MapComponet from '../../services/components/MapComponent'
import { PositionMapDefault } from '../../../store/helpers/AppConstants'

const Address = (props) => {
    const { onSaveSparePartRequest, requestDraft, isSaving,isAuthorizadoForEditedData, mode, 
        isVisibleChipsAssignement, isMobile} = props;
    const [mapPositionModel, setPositionModel] = useState({
        latitude: PositionMapDefault.latitude,
        longitude: PositionMapDefault.longitude
    })
	let isActiveButtonInModeCreate = mode !=="MODE_SHOW" ? true : false;
    const [addressModel, setAddressModel] = useState({ Address: {} })
    const [directionsProvider, setDirectionsProvider] = useState([])

    useEffect(() => {
        setAddressModel(requestDraft)
        if(requestDraft && requestDraft.ServiceProvider  && isActiveButtonInModeCreate && !requestDraft.ServiceProvider.Address){
            setDirectionsProvider(requestDraft.ServiceProvider.Address)
        }
    }, [requestDraft, isActiveButtonInModeCreate])

    useEffect(() => {
        if (requestDraft && requestDraft.Address && !!requestDraft.Address.longitude) {
            setPositionModel({
                latitude: requestDraft.Address.latitude,
                longitude: requestDraft.Address.longitude
            })
        }
    }, [requestDraft])

    /**
 * Function add map position
 * @param {*} position
 */
    const onChangePosition = (position) => {
        setPositionModel({ latitude: position.lat, longitude: position.lng })
    }

    /**
 * Action ok Change address
 * @param {*} address
 */
    const onChangeAddress = (address) => {
        setAddressModel({
            ...addressModel,
            Address: address
        })
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='subtitle1'><strong>ADDRESS</strong></Typography>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
                <MapComponet
                    position={mapPositionModel}
                    onChangePosition={onChangePosition}
                    onChangeAddress={onChangeAddress}
                    directions={directionsProvider}
                    modeCreate={isActiveButtonInModeCreate}
                    fullAddress={addressModel && addressModel.Address && addressModel.Address.full_address}
                    isMobile={isMobile}
                />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6} style={{marginTop:"-8px"}}>
                <SparePartRequestForm
                    sparePartRequest={addressModel}
                    mapPosotionModel={mapPositionModel}
                    onSaveSparePartRequest={onSaveSparePartRequest}
                    isSaving={isSaving}
                    isAuthorizadoForEditedData={isAuthorizadoForEditedData}
                    isModeCreate={(isAuthorizadoForEditedData && requestDraft && !requestDraft.IsClosed)}
                    isVisibleChipsAssignement={isVisibleChipsAssignement}
                    isMobile={isMobile}
                />

            </Grid>
        </Grid>
    )
}

Address.propTypes = {
	/**
	 * The list of all the active services, shown in the service selector
	 */
	isAuthorizadoForEditedData: propTypes.bool.isRequired,
	/**
	 * Callback executed when an active service is selected
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Callback executed when the user save or add a spare part
	 */
	onSaveSparePartRequest: propTypes.func.isRequired,
	/**
	 * Callback executed when removing a spare part
	 */
	requestDraft: propTypes.object,
	/**
	 * Callback executed when the user submits the request
	 */
	onSubmitRequest: propTypes.func
}
Address.defaultProps = {
    requestDraft: {
        Address: null,
        Service: null,
		SpareParts: []
    },
    isSaving: false,
    isAuthorizadoForEditedData: false,
	onSaveSparePartRequest: () => console.warn('Callback [onSaveSparePartRequest] no defined')
}


export default Address
