/**Import react section */
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/**Import components section */
import Toaster from '../common/Toaster'
import UserInCustomerDesktopView from '../serviceProviders/components/UserInProviderDesktopView'
import Create from './Create'
import Edit from './Edit'
import { generatedNewPassword } from '../../store/helpers/PasswordHelper'

/**Import actions sections */
import {
    loadUserInCustomer,
    setInternalQueryParameters,
    SET_CUSTOMERS_USERS,
    updateLastDateUser,
    editActiveNotifications
} from '../../store/customers/customersActions'
/**Import selectors sections */
import {
    customerUsersSelector,
    queryParametersSelector
} from '../../store/customers/customerSelector'
import {
    removeUserFromAccount,
    activeUserExpiredToSingPortal,
    resendPasswordInvitation,
} from '../../store/users/UsersActions'

const UsersInCustomersIndex = (props) => {
    /**Hooks sections */
    const dispatch = useDispatch();
    const location = useLocation();
    let history = useHistory()
    const code = props.match.params.code;
    const name = props.match.params.name;
    /**
     * Selectors use selector section
     */
    const queryParameters = useSelector(queryParametersSelector)
    const customers = useSelector(customerUsersSelector)

    /**Get data from redux store use selector */
    const loggedUserProfile = useSelector((state) => state.session.get('profile'))
    const isLoading = useSelector((state) => state.customers.get('isLoadingCustomers'))
    const isSaving = useSelector((state) => state.customers.get('isSaving'))
    const isValidatingPassword = useSelector((state) => state.users.get('isValidatingPassword'))

    /**Paginated sales representative */
    const [currentPage, setCurrentPage] = useState(1);
    const [allUserPaginated, setAllUsersPaginated] = useState([]);
    const [pagination, setPagination] = useState({ pageSize: 10, total: 0 });

    /** Local state of the component*/
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const [isOpenLateralPanelAdd, setIsOpenLateralPanelAdd] = useState({ openend: false });
    const [gridMode, setGridMode] = useState(false);
    const [passwordDialogConfiguration, setPasswordDialogConfiguration] = useState({ opened: false, user: null });
    const [userInCustomerEdit, setUserInCustomerEdit] = useState({ opened: false, user: null });
    const [handleOpenModalUploadImg, setHandleOpenModalUploadImg] = useState(false);
    const [userActiveExpiredConfing, setUserActiveExpiredConfing] = useState({ opened: false, user: null });
    const [resendPasswordDialog, setResendPasswordDialog] = useState({ opened: false, user: null });

    /**
    * Here we save and switch view mode grid|list and persist on local storage.
    */
    useEffect(() => {
        if (gridMode === false) {
            setGridMode(false);
        }
        else {
            setGridMode(true);
        }
    }, [gridMode]);

    /**
     * Load internal user
     */
    useEffect(() => {
        const queryParameters = queryString.parse(location.search)
        if (!!queryParameters.pageNumber) {
            setCurrentPage(queryParameters.pageNumber)
        }
        dispatch(loadUserInCustomer(queryParameters, code))
    }, [dispatch, location.search, code])

    /**Paginated users in customer*/
    useEffect(() => {
        let usersPerPage = 10;
        let total = customers.length / usersPerPage;
        let allTotal = Math.round(Math.ceil(total))
        const indexOfLastUser = currentPage * usersPerPage;
        let indexOfFirtsUser = indexOfLastUser - usersPerPage;
        let currentUsers = customers.slice(indexOfFirtsUser, indexOfLastUser)
        setAllUsersPaginated(currentUsers)
        setPagination({ pageSize: usersPerPage, total: allTotal });
    }, [customers, pagination.total, currentPage])


    /**
    * On change page number
    */
    const onChangePage = (event, page) => {
        const queryParams = queryString.parse(location.search)
        queryParams.pageNumber = page
        setCurrentPage(page)
        history.push({ search: queryString.stringify(queryParams) })
    }

    /**
     * Update sort criteria
     */
    const updateSortCriteria = (criteria) => {
        const queryParams = queryString.parse(location.search)
        queryParams.sortBy = criteria.by
        queryParams.sortDirection = criteria.direction
        history.push({ search: queryString.stringify(queryParams) })
        dispatch(setInternalQueryParameters(queryParams))
    }

    /**Update search value */
    const updateSearchQuery = (searchQuery) => {
        const queryParams = queryString.parse(location.search)
        if (searchQuery) queryParams.query = searchQuery
        else delete queryParams.query
        history.push({ search: queryString.stringify(queryParams) })
        dispatch(setInternalQueryParameters(queryParams))
    }

    /**Open/close dialog confirm dialog user*/
    const onSetPasswordDialogConfiguration = (user) => {
        setPasswordDialogConfiguration({ opened: !passwordDialogConfiguration.opened, user: user });
    };

    /**Open/close dialog confirm dialog user*/
    const onSetResendPasswordDialog = (user) => {
        setResendPasswordDialog({ opened: !resendPasswordDialog.opened, user: user });
    };

    /**Change mode view in users */
    const switchViewMode = () => {
        if (gridMode === false) {
            setGridMode(true);
        } else {
            setGridMode(false);
        }
    }

    const onEditPanelUserConfig = (user) => {
        setUserInCustomerEdit({ opened: !userInCustomerEdit.opened, user: user })
    }

    /**Open lateral menu for add an internal user */
    const onAddUserInCustomerPanelConfig = () => {
        setIsOpenLateralPanelAdd({ opened: !isOpenLateralPanelAdd.opened })
    }

    /**Open/close dialog modal for upload logo user */
    const onSetHandleOpenCloseModalUploadImg = (user) => {
        setHandleOpenModalUploadImg(!handleOpenModalUploadImg);
        if (user) {
            setUserInCustomerEdit({ opened: true, user: user })
        }
    };

    const onSetAllUsers = (users) => {
        dispatch({
            type: SET_CUSTOMERS_USERS,
            users: users
        })
    }

    /**
     * Remove an user from this list user for company IMBERA
     */
    const onRemoveUserFromAccount = () => {
        dispatch(removeUserFromAccount(passwordDialogConfiguration.user.Token, code))
            .then(() => {
                let allUser = allUserPaginated.filter(x => x.Token !== passwordDialogConfiguration.user.Token)
                onSetAllUsers(allUser)
                onSetPasswordDialogConfiguration()
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error removing user from account", variant: 'error' })
                console.error('Error removing user from account', error);
            });
    };

    /**Open/close dialog confirm dialog user to active expired user */
    const onSetUserActiveExpiredDialog = (user) => {
        setUserActiveExpiredConfing({ opened: !userActiveExpiredConfing.opened, user: user });
    };

    /**
     * Function to actuve user expired in portal 
     */
    const onActiveUserExpired = () => {
        dispatch(activeUserExpiredToSingPortal(userActiveExpiredConfing.user.Id))
            .then((response) => {
                onSetUserActiveExpiredDialog()
                dispatch(updateLastDateUser(response))
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "Error active user expired in portal", variant: 'error' })
                console.error('Error active user expired', error);
            });
    };

    /* Function dialog to resend user password   */
    const onResendPasswordUser = () => {
        let newPassword = generatedNewPassword();
        let resendPasswordUserUpdate = resendPasswordDialog.user;
        dispatch(resendPasswordInvitation(resendPasswordUserUpdate, newPassword))
            .then((response) => {
                onSetResendPasswordDialog()
                setToasterOptions({ open: true, message: "Password was sent successfully", variant: 'success' })
                return Promise.resolve();
            })
            .catch((error) => {
                setToasterOptions({ open: true, message: "An error occurred when sending the password, please try again", variant: 'error' })
                console.error('Error active user expired', error);
            });
    }

    /** Function to active/deactive notifications for selected user */
    const onActiveNotifications = (token, value) => {
        dispatch(editActiveNotifications(token, value))
    }

    return (
        <>
            <UserInCustomerDesktopView
                users={allUserPaginated}
                isLoading={isLoading}
                loggedUserProfile={loggedUserProfile}
                name={`Customers / ${name}`}
                onChangeSortingCriteria={(sortCriteria) =>
                    updateSortCriteria(sortCriteria)
                }
                sortBy={queryParameters ? queryParameters.sortBy : null}
                sortDirection={queryParameters ? queryParameters.sortDirection : null}
                onChangeSearchValue={(query) => updateSearchQuery(query)}
                switchViewMode={switchViewMode}
                gridMode={gridMode}
                onChangePage={onChangePage}
                pagination={pagination}
                openLateralPanelOnClick={onAddUserInCustomerPanelConfig}
                onEditPanelUserConfig={onEditPanelUserConfig}
                onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                onSetResendPasswordDialog={onSetResendPasswordDialog}
                onSetUserActiveExpiredDialog={onSetUserActiveExpiredDialog}
            />
            <Create
                addUserInCustomerPanelConfig={isOpenLateralPanelAdd}
                onAddUserInCustomerPanelConfig={onAddUserInCustomerPanelConfig}
                isSaving={isSaving}
            />

            <Edit
                passwordDialogConfiguration={passwordDialogConfiguration}
                resendPasswordDialog={resendPasswordDialog}
                isValidatingPassword={isValidatingPassword}
                onEditPanelUserConfig={onEditPanelUserConfig}
                userInCustomerEditConfig={userInCustomerEdit}
                isModeCustomerUser={true}
                handleOpenModalUploadImg={handleOpenModalUploadImg}
                onSetHandleOpenCloseModalUploadImg={onSetHandleOpenCloseModalUploadImg}
                onSetPasswordDialogConfiguration={onSetPasswordDialogConfiguration}
                onSetResendPasswordDialog={onSetResendPasswordDialog}
                onRemoveUserFromAccount={onRemoveUserFromAccount}
                isSaving={isSaving}
                onSetUserActiveExpiredDialog={onSetUserActiveExpiredDialog}
                userActiveExpiredConfing={userActiveExpiredConfing}
                onActiveUserExpired={onActiveUserExpired}
                onResendPasswordUser={onResendPasswordUser}
                onActiveNotifications={onActiveNotifications}
            />

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }

        </>
    )
}

export default UsersInCustomersIndex
