/**Import react section */
import React, { Fragment, useState } from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'

import { Typography, ButtonGroup, Tooltip, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import queryString from 'query-string'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import FilterSelect from '../../common/Filters/FilterSelect'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import SearchBox from '../../common/SearchToolbox'
import Authorization from '../../common/AuthorizationEvaluation'
import Datagrid from '../../common/apsys.datagrid.materialui/DataGrid'
import Pagination from '../../common/Pagination/Pagination'
import ImagesNoInformation from '../../common/ImagesNoInformation'
import DashboardCard from '../../dashboard/components/DashboardCard'

import defaultTheme from '../../../resources/themes/default'
import PanelDownloadPrices from './PanelDownloadPrices'
import { getDatagridConfiguration } from '../resources/requestIndexConfig'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import { useCreateDesktopStyle } from '../resources/useStyles'
import {
	PORTAL_ADMINISTRATOR,
	IMBERA_TECHNICIAN,
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	SERVICE_PROVIDER_MANAGER,
	SERVICE_PROVIDER_TECHNICIAN,
	CUSTOMER_MANAGER,
	CUSTOMER_TECHNICIAN,
	SALES_REPRESENTATIVE_MANAGER,
	SALES_MANAGER,
	VISUALIZER
} from '../../../resources/RolesEnum'
import { GetStatusSpareParts, GetStatusTrackingNumber, GetWarehouseType } from '../../../store/helpers/FilterHelper'

import { getOptionsCustomer, getOptions } from '../../../store/helpers/FilterHelper'
import ReportDateRange from '../../common/ReportDateRange/ReportDateRange'
import { useDispatch, useSelector } from 'react-redux'
import { downloadReportSparePartSalesRequestGral } from '../../../store/sparePartSales/sparePartSalesAction'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

import ImportFile from '@material-ui/icons/CloudUpload'

/**
 * View create desktop
 */
const IndexDesktopView = (props) => {
	const classes = useCreateDesktopStyle()
	const {
		userProfile,
		sparePartSalesIndex,
		onOpenRequest,
		pagination,
		onChangePage,
		onChangeSortingCriteria,
		allCustomers,
		isDownloadingReport,
		openImportReferenceSAP

	} = props
	const dispatch = useDispatch()

	let location = useLocation()
	let configuration = getDatagridConfiguration()
	let customers = getOptions(allCustomers)
	let customersOptions = getOptionsCustomer(allCustomers)
	const isDownloadReport = useSelector((state) => state.spareParts.getIn(['index', 'isDownloadReport']))

	const [openPricesPanel, setOpenPricesPanel] = useState(false)

	const onClickDownloadPrices = () => {
		setOpenPricesPanel(!openPricesPanel)
	}

	const onDownloadReportGral = (startDate, endDate) => {
		const queryParameters = queryString.parse(location.search)
		return dispatch(downloadReportSparePartSalesRequestGral('closed', queryParameters, startDate, endDate)).then(() => {})
	}

	const onRenderContent = () => {
		if (sparePartSalesIndex.requests.length >= 1 || sparePartSalesIndex.isLoading) {
			return (
				<Datagrid
					headers={configuration}
					isLoading={sparePartSalesIndex.isLoading}
					data={sparePartSalesIndex.requests}
					onChangeSortingCriteria={onChangeSortingCriteria}
					sortBy={pagination.orderBy}
					sortDirection={pagination.orderType}
					onItemClick={onRowClick}
					customers={customers}
				/>
			)
		} else {
			return (
				<ImagesNoInformation
					query={props.searchValue}
					data={sparePartSalesIndex.requests}
					isLoading={sparePartSalesIndex.isLoading}
					titleData={'No exist spart part request'}
				/>
			)
		}
	}

	const onRowClick = (sender, item) => {
		if (onOpenRequest) {
			onOpenRequest(item)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Spare Part Sales</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={5} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<div className={classes.cardList}>
						<DashboardCardLists isLoadingDashboard={props.isLoadingDashboard} dashboard={props.dashboard} />
					</div>
					<div className={classes.container}>
						<DataGridToolbar
							onClickDownloadPrices={onClickDownloadPrices}
							onCreateRequest={props.onCreateRequest}
							userProfile={userProfile}
							allowedRoles={[
								PORTAL_ADMINISTRATOR,
								IMBERA_TECHNICIAN,
								CUSTOMER_MANAGER,
								CUSTOMER_TECHNICIAN,
								SERVICE_PROVIDER_MANAGER,
								SERVICE_PROVIDER_TECHNICIAN,
								SALES_REPRESENTATIVE_MANAGER,
								SALES_MANAGER
							]}
							onChange={props.onChangeSearchValue}
							searchValue={props.searchValue}
							customers={customers}
							isDownloadingReport={isDownloadingReport}
							onDownloadReportGral={onDownloadReportGral}
							isDownloadReport={isDownloadReport}
							openImportReferenceSAP = {openImportReferenceSAP}

						/>
						{onRenderContent()}
					</div>
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination
							count={pagination.total ? pagination.total : 0}
							page={pagination.pageNumber ? pagination.pageNumber : 0}
							onChangePage={onChangePage}
						/>
					</div>
					<PanelDownloadPrices
						open={openPricesPanel}
						onClose={onClickDownloadPrices}
						customers={customersOptions}
						isDownloadingReport={isDownloadingReport}
						allowedRoles={[PORTAL_ADMINISTRATOR, IMBERA_TECHNICIAN, SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX]}
						userProfile={userProfile}
					/>
				</div>
			</Content>
		</Layout>
	)
}
IndexDesktopView.propTypes = {
	onCreateRequest: propTypes.func.isRequired,
	sparePartSalesIndex: propTypes.shape({
		isLoading: propTypes.bool.isRequired
	}),
	/**
	 * Open a spare part request
	 */
	onOpenRequest: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * On change search value
	 */
	onChangeSearchValue: propTypes.func.isRequired,
	/**
	 * The object contains the paginated
	 */
	pagination: propTypes.shape({
		/**
		 * Determine the initial sort by column
		 */
		orderBy: propTypes.string,
		/**
		 * Defines the initial sort direction
		 */
		orderType: propTypes.oneOf(['asc', 'desc']),
		/**
		 * Defines the total de retails pagination
		 */
		total: propTypes.number,
		/**
		 * Defines the page number pagination
		 */
		pageNumber: propTypes.number
	}),
	/**
	 * On change sort criteria callback
	 */
	onChangeSortingCriteria: propTypes.func.isRequired
}
IndexDesktopView.defaultProps = {
	sparePartSalesIndex: {
		isLoading: false
	},
	onOpenRequest: () => console.warn('Callback [onOpenRequest] no defined'),
	onChangeSearchValue: () => console.warn('Callback [onChangeSearchValue] no defined')
}
export default withImmutablePropsToJS(IndexDesktopView)

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()
	return (
		<div className={classes.searchIndex}>
			<SearchBox placeholder='Search request' onChange={props.onChange} value={props.searchValue} />
			<ButtonGroup component='span' variant='text' size='small' className={classes.buttonsGroupContainer}>
				<ReportDateRange onDownloadReport={props.onDownloadReportGral} isDownloadReport={props.isDownloadReport} />
				<div>
					<Tooltip component='span' title='Download prices'>
						<Button onClick={props.onClickDownloadPrices} className={classes.buttonsDownload}>
							<CloudDownloadIcon />
						</Button>
					</Tooltip>
					<Authorization
						profile={props.userProfile}
						allowedRoles={[IMBERA_TECHNICIAN, PORTAL_ADMINISTRATOR, SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, CUSTOMER_MANAGER]}
					>
						<Tooltip component='span' title='Create spare parts sales'>
							<Button onClick={props.onCreateRequest} className={classes.buttonsGroup}>
								<AddIcon />
							</Button>
						</Tooltip>
					</Authorization>
					<Authorization profile={props.userProfile} allowedRoles={[PORTAL_ADMINISTRATOR]}>
					<Tooltip component='span' title='Import References'>
						<Button onClick={props.openImportReferenceSAP} className={classes.buttonsGroupBlue}>
							<ImportFile />
						</Button>
					</Tooltip>
				</Authorization>
				</div>
			</ButtonGroup>
			<div className={classes.flexGrow} />
			<FilterSelect
				nameFilter={'Tracking Status'}
				data={GetStatusTrackingNumber()}
				nameFilterUrl={'trackingFilter'}
				isVisibleSearch={false}
				isActiveCleanButton={true}
				userProfile={props.userProfile}
				roles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SPARE_PARTS_IMBERA_USA,
					SPARE_PARTS_IMBERA_MX,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					SALES_REPRESENTATIVE_MANAGER,
					SALES_MANAGER,
					VISUALIZER
				]}
			/>
			<FilterSelect
				nameFilter={'Warehouses'}
				data={GetWarehouseType()}
				nameFilterUrl={'warehouseFilter'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SPARE_PARTS_IMBERA_USA,
					SPARE_PARTS_IMBERA_MX,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					SALES_REPRESENTATIVE_MANAGER,
					SALES_MANAGER,
					VISUALIZER
				]}
			/>
			<FilterSelect
				nameFilter={'CUSTOMERS'}
				data={props.customers}
				nameFilterUrl={'customers'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[IMBERA_TECHNICIAN, PORTAL_ADMINISTRATOR, SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, VISUALIZER]}
			/>
			<FilterSelect
				nameFilter={'STATUS'}
				data={GetStatusSpareParts()}
				nameFilterUrl={'statusFilter'}
				isVisibleSearch={false}
				userProfile={props.userProfile}
				roles={[
					IMBERA_TECHNICIAN,
					PORTAL_ADMINISTRATOR,
					SPARE_PARTS_IMBERA_USA,
					SPARE_PARTS_IMBERA_MX,
					SERVICE_PROVIDER_MANAGER,
					SERVICE_PROVIDER_TECHNICIAN,
					CUSTOMER_MANAGER,
					SALES_REPRESENTATIVE_MANAGER,
					SALES_MANAGER,
					VISUALIZER
				]}
			/>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Open side panel to add new  user's
	 */
	onCreateRequest: propTypes.func.isRequired
}

DataGridToolbar.defaultProps = {
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	onCreateRequest: () => console.warn('Callback [onCreateRequest] no defined')
}

/**
 * DashboardCardLists component
 */
const DashboardCardLists = (props) => {
	const { dashboard } = props

	return (
		<Fragment>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.newServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.newServiceDark}
				totalServices={dashboard ? dashboard.totalDraft : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalDraft : 0}
				label={'Draft Sales'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.waitingSparePartsMain}
				secondaryColor={defaultTheme.palette.serviceStatus.waitingSparePartsDark}
				totalServices={dashboard ? dashboard.totalInProcess : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalInProcess : 0}
				label={'Submitted Parts'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.assignedMain}
				secondaryColor={defaultTheme.palette.serviceStatus.assignedDark}
				totalServices={dashboard ? dashboard.totalClosed : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalClosed : 0}
				label={'Send Imbera MX Parts'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.completedServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.completedServiceDark}
				totalServices={dashboard ? dashboard.totalCancelled : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalCancelled : 0}
				label={'Total completed'}
			/>
		</Fragment>
	)
}
