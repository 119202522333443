import { makeStyles } from '@material-ui/styles'

/**
 * Custom avatar select styles 
 */
export const avatarStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        backgroundColor: theme.palette.colors.purpleDark,
        marginLeft:"3px"
    },
    titleLetterDefault:{
        fontSize: 25
    },
    titleLetterCustom:{
        fontSize: 13
    }
}))