
import { fromJS } from 'immutable';
import * as retailsActions from './retailsActions';

let initialState = fromJS({
	isLoadingRetails: false,
    retails: [],
    allRetails: [],
})

/**
 * Sales Representative's reducer
 */
const usersReducer = (state = initialState, action) => {
    switch(action.type) {
        case retailsActions.IS_LOADING_RETAILS: 
            return state.merge({
                isLoadingRetails: action.status
            })
        case retailsActions.SET_SALES_RETAILS: {
            return state.merge({
                retails: fromJS(action.retails),
                isLoadingRetails: false
            })
        }
        case retailsActions.SET_ALL_RETAILS: {
            return state.merge({
                allRetails: fromJS(action.retails).set("isLoadingRetails", false),
            })
        }
        default: 
            return state
    }
}

export default usersReducer

