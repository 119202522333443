/**Import react section */
import React, { useState } from 'react'

/**Import Material Ui section */
import { Grid } from '@material-ui/core'

/**Import components section */
import TextBox from '../../common/TextBox'
import ButtonSaved from '../../common/Buttons/ButtonSaved'
import TimeLineCommentHistory from './TimeLineCommentHistory'

const CommentHistoryComponent = (props) => {
	/**objects section*/
	const { service } = props
	/**Functions sections*/
	const {
		onAddCommentHistory,
		onUpdateCommentHistory,
		onUpdateComment
	} = props

	/**bool props section */
	const {
		isSavingService,
		isAuthorizedForCreatingComment,
		isAuthorizedForChangingStatus
	} = props
	const [comments, setComments] = useState('')

	const onCommentHistoryAdd = (event) => {
		event.stopPropagation()
		if (onAddCommentHistory) {
			onAddCommentHistory(service, comments)
		}
	}

	const canBeSaved = () => {
		if (comments === null && comments.length <= 0) return false
		return true
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { value } = event.target
		setComments(value)
	}

	return (
		<Grid container>
			{isAuthorizedForCreatingComment && (
				<Grid item xs={12}>
					<br />
					<TextBox
						active={true}
						fullWidth
						name='comments'
						variant='outlined'
						margin='dense'
						label='COMMENTS'
						multiline
						rows={3}
						autoFocus
						onChange={onPropertyChange}
						value={comments ? comments : ''}
						disabled={!isAuthorizedForCreatingComment}
					/>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				style={{ display: 'flex', justifyContent: 'flex-end' }}
			>
				{isAuthorizedForCreatingComment && (
					<ButtonSaved
						title='Add'
						onSavedClick={onCommentHistoryAdd}
						isSaving={isSavingService || !canBeSaved()}
					/>
				)}
			</Grid>

			<Grid item xs={12}>
				<TimeLineCommentHistory
					commentsHistory={
						props.service ? props.service.CommentsHistories : []
					}
					serviceToken={props.service.Token}
					onUpdateCommentHistory={onUpdateCommentHistory}
					onUpdateComment={onUpdateComment}
					isAuthorizedForChangingStatus={
						isAuthorizedForChangingStatus
					}
					isAuthorizedForCreatingComment={
						isAuthorizedForCreatingComment
					}
				/>
			</Grid>
		</Grid>
	)
}

export default CommentHistoryComponent
