/**Import react section */
import React from 'react'
/**Import material UI section */
import { Tooltip, Avatar } from '@material-ui/core'

const FaultWarrantyIcon = (props) => {
    const { title, icon, classImg, onChange, fabStyle, isSaving, disabled } = props;

    const onButtonClick = () =>{
        if(onChange && !disabled){
            onChange()
        }
    }
    return (
        <Tooltip component="span" title={title}>
               <Avatar className={fabStyle} disabled={isSaving || disabled}>
                <img src={icon}
                    alt='warranty-icon'
                    className={classImg}
                    onClick={onButtonClick}
                />

               </Avatar>
        </Tooltip>
    )
}

export default FaultWarrantyIcon
