const TERMS_AND_CONDITIONS_STEP = 0;
const ATTACHED_FILES_STEP = 1;
const WAITING_VALIDATE_STEP = 2;

export const moveForwardStepIsActive = (serviceProvider, nextStep) => {
    let moveForwardStepIsActive = false;
    if (nextStep === TERMS_AND_CONDITIONS_STEP && serviceProvider.get('AcceptTermsGuarantee') === true)
        moveForwardStepIsActive = true;
    if (nextStep === ATTACHED_FILES_STEP && serviceProvider.get('Status') === 2)
        moveForwardStepIsActive = true;
    if (nextStep === WAITING_VALIDATE_STEP && serviceProvider.get('Status') === 2)
        moveForwardStepIsActive = true;
    if (nextStep === ATTACHED_FILES_STEP && serviceProvider.get('Status') === 3)
        moveForwardStepIsActive = true;
    if (nextStep === ATTACHED_FILES_STEP && serviceProvider.get('Status') === 4)
        moveForwardStepIsActive = true;
    return moveForwardStepIsActive;
};
