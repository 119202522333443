/**Import react section */
import React from 'react'
import { v4 as uuid4 } from 'uuid'

import propTypes from 'prop-types'
import { isNullOrEmptyAvatar } from '../../store/helpers/StringHelper';

/**Import material UI section */
import { Avatar } from '@material-ui/core';

import {avatarStyles} from '../../resources/styles/SelectAvatarStyles'


/**Import components section */
const AvatarCustomSize = (props) => {
    const classes = avatarStyles();
    const {name, avatarUrl, avatarStyle, isAvatarFromIdentity} = props;
    const firstLetterName = name ? name.charAt(0).toUpperCase() : "A";


    /**Get avatar url  */
    const getAvatarUrl = () => {
        if (!isNullOrEmptyAvatar(avatarUrl ? avatarUrl : null) && isAvatarFromIdentity) {
            const { identityServerUrl } = window.config
            let urlImage = `${identityServerUrl}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuid4();
            return logoUrl;
        }
        else if (!isNullOrEmptyAvatar(avatarUrl ? avatarUrl : null)) {
            const { serviceManagementBackendUrl } = window.config
            let urlImage = `${serviceManagementBackendUrl}/${avatarUrl}`;
            let logoUrl = urlImage + "?token=" + uuid4();
            return logoUrl;
        }
        else
            return null
    }

    return (
        <Avatar
            color="primary"
            size='small'
            className={avatarStyle ? avatarStyle: classes.avatar}
            alt="Avatar"
            src={getAvatarUrl()}
        >
            <span className={avatarStyle ? classes.titleLetterDefault: classes.titleLetterCustom}>{isNullOrEmptyAvatar(avatarUrl) ? firstLetterName.toUpperCase() : null}</span>

        </Avatar>
    )
}

AvatarCustomSize.propTypes = {
    name: propTypes.string,
    avatarUrl: propTypes.string,
}

AvatarCustomSize.defaultProps = {
    name: "",
    avatarUrl: "",
}

export default AvatarCustomSize
