import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
/** Import components */
import { Layout, Content, Header,HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import { Typography } from '@material-ui/core'
import headerConfig from '../../services/resources/ServiceTableConfig'
import ToolbarSearchBox from '../../common/mobile/ToolbarSearchBox'
import SideBar from '../../common/DashboadSideBar'
import ServicesCardsClosed from '../../services/componentsMobile/ServicesCardsClosed'
import Pagination from '../../common/Pagination/Pagination'
import { ServiceCardMobileStyle } from '../../../resources/styles/mobile/ServiceCardMobileStyle'

import { useConfigurator } from '../../common/apsys.datagrid.materialui/UseConfigurator'
import Configurator from '../../common/apsys.datagrid.materialui/PopperConfigurator'

import { DISPATCHER } from '../../../resources/RolesEnum'

/**
 * Authenticated desktop view
 */
const MobileViewClosed = (props) => {
	/**Functions */
	const {
		onChangeSearchValue,
		redirectToCreateService,
		redirectToServiceDetail,
		onDownloadReportOpenServices,
		onAddDateAttention
	} = props
	/**String values */
	const { searchValue } = props
	/**Objects */
	const { userProfile } = props
	/**bools */
	const { isDownloadReport } = props
	const classes = ServiceCardMobileStyle();

	/** Table configuration */
	const [serviceTableConfiguration, onChangeConfiguration] = useConfigurator(
		'dashboard-services-datagrid-config',
		headerConfig
	)
	const [configuratorAnchorEl, setConfigurationAnchorEl] = useState(null)

	/**
	 * The user change the service table configuration
	 */
	const onConfigurationChange = (configuration) => {
		onChangeConfiguration(configuration)
		setConfigurationAnchorEl(null)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
                    <HeaderBreadCrum>
                        <Typography color='secondary'>Services</Typography>
                    </HeaderBreadCrum>
                }
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={2}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<div className={classes.searchBox}>
						<ToolbarSearchBox
							placeholder="Search service"
							searchValue={searchValue}
							onChange={onChangeSearchValue}
							userProfile={userProfile}
							roles={[DISPATCHER]}
							isActiveBtnAdd={false}
							isLoading={props.isLoading}
							onButtonAddClick={redirectToCreateService}
						/>
					</div>
					<div className={classes.container}>
						<ServicesCardsClosed
							services={props.services}
							isLoading={props.isLoading}
							onchangeSortingCriteria={
								props.onchangeServiceSortingCriteria
							}
							onChangeSearchValue={onChangeSearchValue}
							isServicesOpen={true}
							redirectToCreateService={redirectToCreateService}
							redirectToServiceDetail={redirectToServiceDetail}
							userProfile={userProfile}
							configuration={serviceTableConfiguration}
							onDownloadReportOpenServices={onDownloadReportOpenServices}
							isDownloadReport={isDownloadReport}
							onAddDateAttention={onAddDateAttention}
						/>
					</div>
					<div className={classes.pagination}>
						<div className={classes.grow} />
						<Pagination
							count={props.totalServicesPages}
							page={props.servicePageNumber}
							onChangePage={props.onChangeServicePage}
						/>
					</div>
					<Configurator
						open={configuratorAnchorEl ? true : false}
						anchorEl={configuratorAnchorEl}
						configuration={serviceTableConfiguration}
						onConfigurationChange={onConfigurationChange}
						onClickAway={() => setConfigurationAnchorEl(null)}
					/>
				</div>
			</Content>
		</Layout>
	)
}
MobileViewClosed.propTypes = {
	/**
	 * The list of services
	 */
	services: propTypes.arrayOf(
		propTypes.shape({
			Ticket: propTypes.any.isRequired,
			Serie: propTypes.any.isRequired,
			Model: propTypes.any.isRequired
		})
	).isRequired,
	/**
	 * The total service's pages
	 */
	totalServicesPages: propTypes.number.isRequired,
	/**
	 * The actual page number
	 */
	servicePageNumber: propTypes.number.isRequired,
	/**
	 * Callback executed when the user change the service's page
	 */
	onChangeServicePage: propTypes.func,
	/**
	 * On change service sorting criteria
	 */
	onchangeServiceSortingCriteria: propTypes.func,
	/**
	 * Determine the sorting column for the service table
	 */
	sortServiceBy: propTypes.string,
	/**
	 * The sort direction for the service table
	 */
	sortServiceDirection: propTypes.oneOf(['asc', 'desc']),
	/**
	 * Determine if is loading the services list
	 */
	isLoading: propTypes.bool.isRequired,
	/**
	 * Callback executed when the user request a change location
	 */
	onChangeLocation: propTypes.func.isRequired,
	/**
	 * This function executes the download of the open services
	 */
	onDownloadReportOpenServices: propTypes.func,
}
MobileViewClosed.defaultProps = {
	services: [],
	totalServicesPages: 0,
	servicePageNumber: 0,
	isLoading: false
}
export default withImmutablePropsToJS(MobileViewClosed)