import { makeStyles } from '@material-ui/styles'

/**
 * Custom crop dialog Styles
 */
export const regionsStyles = makeStyles((theme) => ({
    root: {
        maxWidth:theme.views.maxWidth,
        marginLeft:"auto",
        marginRight:"auto",
        marginTop:"10px"
    },
    contentTotal:{
        display: "flex",
        marginTop:"2px" 
    },
    boxCircle:{
        backgroundColor: ({ color }) => `${color}`,
        boxShadow: "0px 0px 0px 0px",
        width: '1rem',
        height: '1rem'
    },
    description:{
        padding: 0, 
        margin: 0, 
        marginLeft: "5px"
    }
}))