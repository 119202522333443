import axios from 'axios'
import { round } from 'mathjs'
import moment from 'moment'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'
import { loadAllSalesRepresentatives } from '../salesrepresentatives/salesRepresentativesActions'

export const SET_NETWORKDAYS_CHART = 'SET_NETWORKDAYS_CHART'
export const SET_SERVICE_SORTING_CRITERIA = 'SET_SERVICE_SORTING_CRITERIA'
export const SET_SALESREPRENTATIVES_SORTING_CRITERIA = 'SET_SALESREPRENTATIVES_SORTING_CRITERIA'
export const SET_CUSTOMERS_SORTING_CRITERIA = 'SET_CUSTOMERS_SORTING_CRITERIA'
export const SET_SERVICE_PROVIDER_FILTER = 'SET_SERVICE_PROVIDER_FILTER'
export const SET_SALES_REPRESENTATIVE_FILTER = 'SET_SALES_REPRESENTATIVE_FILTER'
export const SET_START_AND_END_DATE = 'SET_START_AND_END_DATE'
export const SET_SERVICE_PROVIDER_QUERY = 'SET_SERVICE_PROVIDER_QUERY'
export const SET_SALES_REPRESENTATIVE_QUERY = 'SET_SALES_REPRESENTATIVE_QUERY'
export const SET_CUSTOMER_FILTER = 'SET_CUSTOMER_FILTER'
export const SET_CUSTOMER_QUERY = 'SET_CUSTOMER_QUERY'

export const setCustomerQuery = (queryCustomer) => {
	return {
		type: SET_CUSTOMER_QUERY,
		queryCustomer: queryCustomer
	}
}

/**
 * Set customer filter action creator
 * @param {*} customer 
 */
 export const setCustomerFilter = (customer) => {
	return {
		type: SET_CUSTOMER_FILTER,
		customer: customer
	}
}

/**
 * Action creator to set the information of the networkdays chart
 */
export const setNetworkDaysChart = (
	services,
	serviceProvidersNetworkDays,
	salesRepresentativeNetworkDays
) => {
	return {
		type: SET_NETWORKDAYS_CHART,
		services: services,
		serviceProvidersNetworkDays: serviceProvidersNetworkDays,
		salesRepresentativeNetworkDays: salesRepresentativeNetworkDays
	}
}

/**
 * Action creator to set the services sorting criteria
 */
export const setServiceSortingCriteria = (criteria) => {
	return {
		type: SET_SERVICE_SORTING_CRITERIA,
		criteria: criteria
	}
}

/**
 * Action creator to set the services sorting criteria
 */
export const setSalesRepresentativesSortingCriteria = (criteria) => {
	return {
		type: SET_SALESREPRENTATIVES_SORTING_CRITERIA,
		criteria: criteria
	}
}

/**
 * Action creator to set the customers sorting criteria
 */
 export const setCustomersSortingCriteria = (criteria) => {
	return {
		type: SET_CUSTOMERS_SORTING_CRITERIA,
		criteria: criteria
	}
}

/**
 * Set service provider filter action creator
 * @param {*} serviceProvider 
 */
export const setServiceProviderFilter = (serviceProvider) => {
	return {
		type: SET_SERVICE_PROVIDER_FILTER,
		serviceProvider: serviceProvider
	}
}

/**
 * Set sales representative filter action creator
 * @param {*} salesRepresentative 
 */
export const setSalesRepresentativeFilter = (salesRepresentative) => {
	return {
		type: SET_SALES_REPRESENTATIVE_FILTER,
		salesRepresentative: salesRepresentative
	}
}

export const setStartDateAndEndDate = (startDate, endDate) => {
	return {
		type: SET_START_AND_END_DATE,
		startDate,
		endDate
	}
}

export const setServiceProviderQuery = (queryProvider) => {
	return {
		type: SET_SERVICE_PROVIDER_QUERY,
		queryProvider: queryProvider
	}
}

export const setSalesRepresentativeQuery = (queryRepresentative) => {
	return {
		type: SET_SALES_REPRESENTATIVE_QUERY,
		queryRepresentative: queryRepresentative
	}
}


/**
 * Load the network days report
 */
export const loadNetworkDaysChart = (startDate, endDate) => {
	return async (dispatch, getState) => {
		await dispatch(loadAllSalesRepresentatives(null, 1000))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Get the users
		let command = {
			StartDate: startDate,
			EndDate: endDate,
			Status: 'closed'
		}
		let url = `${window.config.serviceManagementBackendUrl}/services/status`
		let serverResponse = await axios.post(url, command, config)

		let networkDaysInformation = serverResponse.data

		let closedServices = serverResponse.data.services.map((closed) => {
			closed.IntiatedDate = moment(closed.IntiatedDate, 'MM/DD/YYYY')
			closed.CompletionDate = moment(closed.CompletionDate, 'MM/DD/YYYY')
			return closed
		})

		dispatch(
			setNetworkDaysChart(
				closedServices,
				networkDaysInformation.ServiceProviders,
				networkDaysInformation.Representatives
			)
		)
	}
}

/**
 * Get a grouped network day values of a list of services
 */
export const getGroupedNetworkDay = (services, criteria) => {
	if (criteria === 'maximum') {
		return services.reduce((maximum, service) => {
			return service.NetworkDays > maximum ? service.NetworkDays : maximum
		}, 0)
	} else if (criteria === 'average') {
		if (services.length === 0) return 0
		let networkDaysSum = services.reduce((sum, service) => {
			return sum + service.NetworkDays
		}, 0)
		return round(networkDaysSum / services.length, 2)
	} else if (criteria === 'minimum') {
		return services.reduce((minumum, service) => {
			return service.NetworkDays < minumum ? service.NetworkDays : minumum
		}, 0)
	}
}
