

// Function that generates a random number between the upper and lower limits passed by parameter
export function generateRandom(lowerNumber, topNumber) {
    var numAleatorio = Math.floor((Math.random() * (topNumber - lowerNumber + 1)) + lowerNumber);
    return numAleatorio;
}

// función que genera un tipo de caracter en base al tipo que se le pasa por parámetro (mayúscula, minúscula, número, símbolo o aleatorio)
export function generateCharacter(characterType) {
    // We have created a specific character list, which also does not have some characters such as uppercase "i" or lowercase "l" to avoid transcription errors
    var characterList = '$+=?@_23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz';
    var characterGenerated = '';
    var lowerValue = 0;
    var higherValue = 0;

    switch (characterType) {
        case 'lowerCase':
            lowerValue = 38;
            higherValue = 61;
            break;
        case 'capitalLetter':
            lowerValue = 14;
            higherValue = 37;
            break;
        case 'number':
            lowerValue = 6;
            higherValue = 13;
            break;
        case 'symbol':
            lowerValue = 0;
            higherValue = 5;
            break;
        case 'random':
            lowerValue = 0;
            higherValue = 61;
            break
        default: break

    }

    characterGenerated = characterList.charAt(generateRandom(lowerValue, higherValue));
    return characterGenerated;
}

export function saveCharacterInRandomPosition(characterPassedByParameter, tamanyoPassword, arrayCaracteres) {
    var storedInEmptyPosition = false;
    var arrayPosition = 0;

    while (storedInEmptyPosition !== true) {
        arrayPosition = generateRandom(0, tamanyoPassword - 1);	// We generate a random in the password size range
        // The array has been initialized with null in its positions. If it is an empty position, we save the character
        if (arrayCaracteres[arrayPosition] == null) {
            arrayCaracteres[arrayPosition] = characterPassedByParameter;
            storedInEmptyPosition = true;
        }
    }
}


// function that starts once the page is loaded
export const generatePassword = (lowercaseLettersAchieved, minimumNumberLowercaseLetters, 
    temporaryCharacter, tamanyoPassword, arrayCaracteres, consecutiveCharacters,
    uppercaseLettersAchieved, minimumNumberCapitalLetters, numbersObtained, minimumNumberNumbers,
    symbolsObtained, symbolNumber, finalPassword ) => {
    // we generate the different types of characters and put them in a temporary_password
    while (lowercaseLettersAchieved < minimumNumberLowercaseLetters) {
        temporaryCharacter = generateCharacter('lowerCase');
        saveCharacterInRandomPosition(temporaryCharacter, tamanyoPassword, arrayCaracteres);
        lowercaseLettersAchieved++;
        consecutiveCharacters++;
    }

    while (uppercaseLettersAchieved < minimumNumberCapitalLetters) {
        temporaryCharacter = generateCharacter('capitalLetter');
        saveCharacterInRandomPosition(temporaryCharacter, tamanyoPassword, arrayCaracteres);
        uppercaseLettersAchieved++;
        consecutiveCharacters++;
    }

    while (numbersObtained < minimumNumberNumbers) {
        temporaryCharacter = generateCharacter('number');
        saveCharacterInRandomPosition(temporaryCharacter, tamanyoPassword, arrayCaracteres);
        numbersObtained++;
        consecutiveCharacters++;
    }

    while (symbolsObtained < symbolNumber) {
        temporaryCharacter = generateCharacter('symbol');
        saveCharacterInRandomPosition(temporaryCharacter, tamanyoPassword, arrayCaracteres);
        symbolsObtained++;
        consecutiveCharacters++;
    }


    // if we have not generated all the characters we need, we will randomly add the missing ones
    // until we reach the password size that interests us
    while (consecutiveCharacters < tamanyoPassword) {
        temporaryCharacter = generateCharacter('random');
        saveCharacterInRandomPosition(temporaryCharacter, tamanyoPassword, arrayCaracteres);
        consecutiveCharacters++;
    }
    // Now we pass the contents of the array to the finalPassword variable
    for (var i = 0; i < arrayCaracteres.length; i++) {
        finalPassword = finalPassword + arrayCaracteres[i];
    }
    return finalPassword
}

export const generatedNewPassword = () =>{
    var finalPassword = '';

    var consecutiveCharacters = 0;			// Counter of the characters we've got
    var temporaryCharacter = '';

    var minimumNumberLowercaseLetters = 1;			// In this and the following variables we define how many 
    var minimumNumberCapitalLetters = 1;			// Characters of each type we want in each
    var minimumNumberNumbers = 1;
    var symbolNumber = 1;

    var lowercaseLettersAchieved = 0;
    var uppercaseLettersAchieved = 0;
    var numbersObtained = 0;
    var symbolsObtained = 0;
    var tamanyoPassword = 9;			// We define the size of our password
    var arrayCaracteres = [];             // Array to store characters temporarily
    for (var i = 0; i < tamanyoPassword; i++) {		// We initialize the array with the null value
        arrayCaracteres[i] = null;
    }
    let generatePaswordString = generatePassword(lowercaseLettersAchieved, minimumNumberLowercaseLetters,
        temporaryCharacter, tamanyoPassword, arrayCaracteres, consecutiveCharacters,
        uppercaseLettersAchieved, minimumNumberCapitalLetters, numbersObtained, minimumNumberNumbers,
        symbolsObtained, symbolNumber, finalPassword)
    
    return generatePaswordString;
}
