import { makeStyles } from '@material-ui/core/styles'

/**
 * Desktop view styles
 */
export const useDesktopViewStyles = makeStyles((theme) => ({
	content: {
		padding: '10px',
		maxWidth: theme.views.maxWidth,
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: 'auto auto 1fr'
	},
	cardScoreTotal: {
		gridRow: '1 / 2',
		gridColumn: '1 / 2',
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		columnGap: '5px'
	},
	chart: {
		gridColumn: '1 / 2',
		gridRow: '2 / 3'
	},
	rootContainerTable:{
		display:"grid",
		gridTemplateColumns: '1fr 1fr 1fr',
	},
	rootContainerTableExternal:{
		display:"grid",
		gridTemplateColumns: '1fr 1fr',
	},
	serviceProviderTable: {
		gridColumn: '1 / 2',
		gridRow: '3 / 3',
		padding: '2px',
	},
	serviceProviderTableExternal:{
		gridColumn: '1 / 2',
		gridRow: '3 / 3',
		padding: '2px',
	},
	salesRepresentivesProviderTable: {
		gridColumn: '2 / 3',
		gridRow: '3 / 3',
		padding: '2px',
	},
	salesRepresentivesProviderTableExtenal: {
		gridColumn: '2 / 3',
		gridRow: '3 / 3',
		padding: '2px',
	},
	customerTable: {
		gridColumn: '3 / 4',
		gridRow: '3 / 3',
		padding: '2px',
	},
	customerTableExternal: {
		gridColumn: '2 / 3',
		gridRow: '3 / 3',
		padding: '2px',
	},
	customerInServiceProviderTableExternal:{
		gridColumn: '1 / 2',
		gridRow: '3 / 3',
		padding: '2px',
	},
	contentProviderTablePaper: {
		display: 'grid',
		gridTemplateRows: '1fr auto'
	},
	search:{
		gridRow: '1 / 1',
		display:'flex'
	},
	containerTable:{
		gridRow: '2 / 2',
		overflowY: 'auto',
		height: '400px'
	},
	grow: {
        flexGrow: 1,
	},
	titleColor: {
		color: theme.palette.colors.purple,
		alignSelf:"center",
		marginLeft:"15px"
    },
	buttonReport:{
		marginLeft:"10px",
		"&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.primaryDisabled} !important`,
            color: `${theme.palette.common.white} !important`,
            opacity: .75,
            border: `0.5px solid ${theme.palette.colors.grayLayout} !important`,
        },
	},
	containerDateRange:{
		display:"flex"
	},
	textTable:{
		fontSize:"11px"
	}

}))


/**
 * The card styles
 */
export const useDashboardCardStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: ({ mainColor }) => `${mainColor}`,
		minWidth: '220px',
		height: ({ height }) => `${height}`,
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr 10px'
	},
	rightContainer: {
		gridColumn: '1 / 3',
		gridRow: '1 / 2',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	bottomBorder: {
		gridColumn: '1 / 3',
		gridRow: '2 / 3',
		backgroundColor: ({ secondaryColor }) => `${secondaryColor}`
	},
	primaryText: {
		color: theme.palette.common.black,
		fontWeight: 'bold'
	},
	secondaryText: {
		color: theme.palette.common.white
	}
}))
