/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import defaultTheme from '../../../resources/themes/default'
/**Import react geogle maps */
import { withScriptjs, withGoogleMap, GoogleMap, InfoWindow, Marker } from "react-google-maps"
import { compose } from "recompose"
/** Import icon map */
import inconMap1 from '../../../resources/images/maps/circleRed1.png';
import inconMap2 from '../../../resources/images/maps/circleRed2.png';
import inconMap3 from '../../../resources/images/maps/circleRed3.png';
import inconMap4 from '../../../resources/images/maps/circleRed4.png';
import TotalServicesByRegion from './TotalServicesByRegion'
import { Typography } from '@material-ui/core'


/**
 * Conponent to show Map gelocalitaion
 * @param {*} props 
 */
const MapWrapper = compose(withScriptjs, withGoogleMap)(props => {
    const { directions, markerPosition } = props;
    const [openOInfoId, setOpenInfoId] = useState('');

    const handleToggleMarker = (markerId) => {
        setOpenInfoId(markerId)
    }

    const getIconMap = () =>{
        let newArrayDirections = [];
        directions.forEach(direction => {
            if(direction.TotalCount<=10)
                direction.icon = inconMap4
            if(direction.TotalCount > 10 && direction.TotalCount<=20)
                direction.icon = inconMap3
            if(direction.TotalCount > 20 && direction.TotalCount<=30)
                direction.icon = inconMap2
            if(direction.TotalCount > 30)
                direction.icon = inconMap1
            newArrayDirections.push(direction)
            
        });
        return newArrayDirections
    }

    let allDirectionWithIcon = getIconMap();
    /**
     * Render component
     */
    return (
        <GoogleMap
            defaultZoom={4}
            defaultCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        >
            {allDirectionWithIcon.map((direction) => {
                return (<Marker
                    key={direction.Token}
                    position={{ lat: direction.Latitude, lng: direction.Longitude }}
                    icon={direction.icon}
                    onMouseOver={() => handleToggleMarker(direction.Token)}
                    onMouseOut={() => handleToggleMarker('')}
                >
                    {openOInfoId === direction.Token  && <InfoWindow
                        position={{ lat: direction.Latitude, lng: direction.Longitude }}
                    >
                        <div >
                            <Typography align='center' variant='subtitle2' noWrap>{direction.Address}</Typography>
                            <TotalServicesByRegion
                                total={direction.TotalActive}
                                color={defaultTheme.palette.colors.purple}
                                description={"New services"}
                            />
                             <TotalServicesByRegion
                                total={direction.TotalAssigned}
                                color={defaultTheme.palette.serviceStatus.assignedDark}
                                description={"Assigned services"}
                            />
                             <TotalServicesByRegion
                                total={direction.TotalSuspended}
                                color={defaultTheme.palette.serviceStatus.suspendedMain}
                                description={"Suspended services"}
                            />
                             <TotalServicesByRegion
                                total={direction.TotalWaiting}
                                color={defaultTheme.palette.colors.orange}
                                description={"Awaiting spare parts"}
                            />
                            <TotalServicesByRegion
                                total={direction.TotalCount}
                                color={defaultTheme.palette.colors.green}
                                description={"Total"}
                            />
                        </div>
                    </InfoWindow>}
                </Marker>
                )
            })}

        </GoogleMap>
    )
})

MapWrapper.propTypes = {
    /** object to get position defautl from map */
    markerPosition: propTypes.object.isRequired,
    directions: propTypes.array,
}
MapWrapper.defaultProps = {
    directions: [],
}

export default MapWrapper;

