
import { fromJS } from 'immutable';
import * as salesRepresentativesActions from './salesRepresentativesActions';

let initialState = fromJS({
    isLoadingRepresentatives: false,
    isSaving: false,
    salesRepresentatives: [],
    representativeUsers: [],
    internalQueryParameters: null,
    pagination: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        orderBy: "code",
        orderType: "asc",
    },
})

/**
 * Sales Representative's reducer
 */
const salesRepresentativeReducer = (state = initialState, action) => {
    switch (action.type) {
        case salesRepresentativesActions.IS_LOADING_SALES_REPRESENTATIVES:
            return state.merge({
                isLoadingRepresentatives: action.status
            })
        case salesRepresentativesActions.SET_SALES_REPRESENTATIVES: {
            return state.merge({
                salesRepresentatives: fromJS(action.data.salesRepresentatives),
                isLoadingRepresentatives: false,
                pagination: fromJS({
                    pageNumber: action.data.pageNumber,
                    pageSize: action.data.pageSize,
                    total: action.data.total,
                    orderBy: action.data.orderBy,
                    orderType: action.data.orderType,
                })
            })
        }
        case salesRepresentativesActions.UPDATE_SALES_REPRESENTATIVE: {
            let representativeUpdate = fromJS(action.representative);
            return state.update('salesRepresentatives', allSalesRepresentatives => updateSalesRepresentative(allSalesRepresentatives, representativeUpdate));
        }
        case salesRepresentativesActions.IS_SAVING: {
            return state.merge({
                isSaving: action.status
            })
        }
        case salesRepresentativesActions.ADD_SALES_REPRESENTATIVE: {
            let newRepresentative = fromJS(action.representative);
            return state.update('salesRepresentatives', allSalesRepresentative => pushSalesRepresentative(allSalesRepresentative, newRepresentative));
        }
        case salesRepresentativesActions.SET_REPRESENTATIVE_USERS: {
            return state.merge({
                representativeUsers: fromJS(action.users),
                isLoadingRepresentatives: false
            })
        }
        case salesRepresentativesActions.SET_REPRESENTATIVES_QUERYPARAMETERS: {
            return state.merge({
                internalQueryParameters: fromJS(action.queryParametes)
            })
        }
        case salesRepresentativesActions.REMOVE_USER_FROM_ACCOUNT: {
            return state.updateIn(['representativeUsers'], allInternalUsers => allInternalUsers.filter(user => user.get('Token') !== action.token))
                .set('isSaving', false);
        }
        case salesRepresentativesActions.ADD_USER_IN_SALES_REPRESENTATIVE: {
            let newUser = fromJS(action.user);
            return state.update('representativeUsers', allUsers => pushUser(allUsers, newUser));
        }
        case salesRepresentativesActions.UPDATE_SALES_REPRESENTATIVE_USERS: {
            let userUpdate = fromJS(action.user);
            return state.update('representativeUsers', allIntenalUsers => updateSalesRepresentative(allIntenalUsers, userUpdate));
        }
        case salesRepresentativesActions.UPDATE_LAST_ACTIVE_NOTIFICATIONS_SALES_RERESENTATIVE_USER: {
            let lastActivation = action.user.ActiveNotifications
            return state.update('representativeUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.UserName, 'ActiveNotifications', lastActivation));
        }
        case salesRepresentativesActions.SET_ROLES_IN_PROFILE: {
            let roles = fromJS(action.roles);
            return state.update('representativeUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.userName, 'Roles', fromJS(roles)));
        }
        case salesRepresentativesActions.UPDATE_LAST_DATE_USER_REP: {
            let lastDate = action.user.last_authentication_date;
            return state.update('representativeUsers', allIntenalUsers => updateUserProperty(allIntenalUsers, action.user.user_name, 'LastAuthenticationDate', lastDate));
        }
        default:
            return state
    }
}

const updateSalesRepresentative = (allRepresentative, updateRepresentative) => {
    let indexOfRepresentative = allRepresentative.findIndex(representative => representative.get('Token') === updateRepresentative.get('Token'));
    if (indexOfRepresentative >= 0) {
        return allRepresentative.setIn([indexOfRepresentative], updateRepresentative);
    }
    return allRepresentative;
}

const pushSalesRepresentative = (allSalesRepresentative, newSalesRepresentative) => {
    if (allSalesRepresentative.some(user => user.get('Code') === newSalesRepresentative.get('Code'))) {
        return allSalesRepresentative;
    }
    return allSalesRepresentative.push(newSalesRepresentative);
}

const pushUser = (allIntenalUsers, newUser) => {
    if (allIntenalUsers.some(user => user.get('UserName') === newUser.get('UserName'))) {
        return allIntenalUsers;
    }
    return allIntenalUsers.push(newUser);
}

const updateUserProperty = (allIntenalUsers, userName, propertyName, propertyValue) => {
    let indexOfUser = allIntenalUsers.findIndex(user => user.get('UserName') === userName);
    if (indexOfUser >= 0) {
        return allIntenalUsers.setIn([indexOfUser, propertyName], propertyValue);
    }
    return allIntenalUsers;
}

export default salesRepresentativeReducer

