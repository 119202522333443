/**Import react section */
import React from 'react'

/**Import material ui section */
import { Typography, Grid, Divider, IconButton} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

/**Import components section */
import CardComponent from '../../common/mobile/CardComponent'
import CardMobileSkeleton from '../../skeletons/services/componets/CardMobile'
/**Import resources styles */
import { ServiceCardMobileStyle } from '../../../resources/styles/mobile/ServiceCardMobileStyle'
import { GetColorStatusRequest } from '../../../store/helpers/StatusHelper'
import iconSparePart from '../../../resources/images/sparePart.svg';

const SparePartsCards = (props) => {
    const classes = ServiceCardMobileStyle();

    /**Array props section */
    const { spareParts } = props
    /**Constant functions */
    const { redirectToDetail, isLoading } = props

    /**Render text with name color bold */
    const onRenderText = (titleBold, title) => {
        return (
            <>
                <div className={classes.flex}>
                    <Typography variant="subtitle2" className={classes.titleValue}>
                        <strong className={classes.titleBold}>{titleBold}</strong>
                        {title}
                    </Typography>
                </div>
                <Divider/>
            </>
        )

    }

    /**Render content card text */
    const onRenderContenCard = (partRequest) => {
        return (
            <>
                {partRequest.Serie && onRenderText("Serial", partRequest.Serie)}
                {partRequest.Code && onRenderText("Part:", `${partRequest.Code ? partRequest.Code : ""} | ${partRequest.DescriptionCode ? partRequest.DescriptionCode : ""}`)}
                {partRequest.TrackingNumber && onRenderText("Number tracking", partRequest.TrackingNumber)}
                {partRequest.Comments && onRenderText("Comments: ", partRequest.Comments)}
            </>
        )
    }

    
    const onRenderIcon = (ticket, status) => {
        return (
            <div className={classes.flex}>
                {ticket &&<Typography variant="subtitle2" align="right"> {`Ticket: ${ticket}`}</Typography>}
                <IconButton
                    edge="end"
                    aria-label="toggle visibility"
                    className={GetColorStatusRequest(status, classes)}
                  size="small"
                >
                    <img src={iconSparePart}
                        alt='warranty-icon'
                        className={classes.faultWarrantyIcon}
                    />
                </IconButton>
            </div>
        )
    }

    const onCardClick = (item) => {
        if (redirectToDetail) {
            redirectToDetail(item)
        }
    }

    const onRenderContent = () => {
        if (isLoading) {
            return <CardMobileSkeleton />
        }
        else {
            return (
                <Grid
                    container
                    spacing={1}
                >
                    {
                        spareParts.length !== 0 ? spareParts.map((part) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={part.Id}
                                >
                                    <CardComponent
                                        title={`Folio: ${part.Folio} `}
                                        onRenderContent={() => onRenderContenCard(part)}
                                        item={part}
                                        onCardClick={onCardClick}
                                        onRenderContentRight={() => onRenderIcon(part.Ticket, part.Status)}
                                    /><br />
                                </Grid>
                            )


                        }) : <Alert severity="info" icon={false}>{"There are no requests for spare parts generated."}</Alert>
                    }
                </Grid>
            )
        }

    }
    return onRenderContent()
}

export default SparePartsCards