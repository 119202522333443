/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import Pagination from '../../common/Pagination/Pagination'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import RenderContentIndex from '../../common/renderContentIndex/RenderContentIndex'
import SearchBox from '../../common/SearchToolbox'
import headerConfig from '../resources/CustomerTableConfig'
import AvatarCustomSize from '../../common/AvatarCustomSizes'
import LockButton from '../../serviceProviders/components/LockButton'
import LockOpenMeteriaIcon from '@material-ui/icons/LockOpen'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

/**Import material UI Section */
import {
	Typography,
	ButtonGroup,
	Tooltip,
	Button,
	Chip,
	ListItem,
	ListItemText,
	ListItemIcon
} from '@material-ui/core'
import GridIcon from '@material-ui/icons/GridOn'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
/**Import resources section */
import LockIcon from '../../../resources/images/lock/Lock.svg'
import LockOpenIcon from '../../../resources/images/lock/LockOpen.svg'
import Authorization from '../../common/AuthorizationEvaluation'

//roles
import { PORTAL_ADMINISTRATOR } from '../../../resources/RolesEnum'

const DesktopView = (props) => {
	const classes = useDataGridToolbarStyle()
	/**objects props */
	const { pagination, loggedUserProfile } = props
	/**Function props */
	const {
		onChangePage,
		onViewUserClick,
		onLocked,
		onEditCustomerClick,
		switchViewMode,
		onAddLateralPanelConfig,
		onDownloadReport
	} = props
	/**String props section */
	const { identifierCardSeleted, identifierLockSeleted } = props
	/**Array props section*/
	const { allCustomers } = props
	/**Bool sections props */
	const { gridMode, isSaving, userProfile } = props

	/**
	 * Get the header configuration
	 */
	const getHeaderConfiguration = () => {
		let avatarProperty = headerConfig.find((x) => x.dataSource === 'avatar')
		avatarProperty.onRenderProperty = (item) => {
			return (
				<AvatarCustomSize
					name={item.Name}
					avatarUrl={item.AvatarUrl}
					avatarStyle={classes.avatar}
					isAvatarFromIdentity={false}
				/>
			)
		}
		let detailsProperty = headerConfig.find((x) => x.dataSource === 'Details')

		detailsProperty.onRenderProperty = (item) => {
			return (
				<Chip
					label='View users'
					className={item.Locked ? classes.chipLocked : classes.chipView}
					onClick={() => onViewUserClick(item)}
					disabled={item.Locked}
				/>
			)
		}
		let statusProperty = headerConfig.find((x) => x.dataSource === 'Status')

		statusProperty.onRenderProperty = (item) => {
			return (
				<LockButton
					icon={item.Locked ? LockOpenIcon : LockIcon}
					onLocked={(event) => onLocked(item.Token, !item.Locked, event)}
					isLocked={item.Locked}
					title={item.Locked ? 'Unlock customer' : 'Block customer'}
					disabled={identifierLockSeleted === item.Token}
				/>
			)
		}

		return headerConfig
	}

	/** Render menu options for list in mode cards*/
	const renderOptionMenu = (user) => {
		let isDisabledOption = loggedUserProfile.userName !== user.UserName ? false : true
		return (
			<>
				{
					<ListItem
						button
						data-cy='item-remove-access'
						disabled={isSaving || isDisabledOption}
						onClick={(event) => onLocked(user.Token, !user.Locked, event)}
					>
						<ListItemIcon>
							{user.Locked ? (
								<img src={LockOpenIcon} alt='icon' />
							) : (
								<LockOpenMeteriaIcon />
							)}
						</ListItemIcon>
						<ListItemText
							primary={user.Locked ? 'Unlock customer' : 'Block customer'}
						/>
					</ListItem>
				}
			</>
		)
	}

	const onRowClick = (sender, item) => {
		if (onEditCustomerClick) {
			onEditCustomerClick(item)
		}
	}

	/**Render content in cads o en mode list */
	const renderContent = () => {
		return (
			<RenderContentIndex
				headerConfig={getHeaderConfiguration()}
				isLoading={props.isLoading}
				pagination={pagination}
				onRowClick={onRowClick}
				data={allCustomers}
				gridMode={gridMode}
				onChangeSortingCriteria={props.onChangeSortingCriteria}
				searchValue={props.searchValue}
				titleData={'There is no added data, please add a customer.'}
				onEditClick={onEditCustomerClick}
				isAvatarFromIdentity={false}
				identifierCardSeleted={identifierCardSeleted}
				isActiveButtonOptions={true}
				renderOptionMenu={renderOptionMenu}
			/>
		)
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				user={props.user}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Administration / Imbera customers</Typography>
					</HeaderBreadCrum>
				}
			/>
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<DataGridToolbar
						onChange={props.onChangeSearchValue}
						searchValue={props.searchValue}
						switchViewMode={switchViewMode}
						gridMode={gridMode}
						onAddLateralPanelConfig={onAddLateralPanelConfig}
						onDownloadReport={onDownloadReport}
						userProfile={userProfile}
					/>
					{renderContent()}
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination
							count={pagination ? pagination.total : 0}
							page={pagination ? pagination.pageNumber : 0}
							onChangePage={onChangePage}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()
	return (
		<div className={classes.searchIndex}>
			<SearchBox
				placeholder='Search customer'
				onChange={props.onChange}
				value={props.searchValue}
			/>
			<ButtonGroup
				component='span'
				size='small'
				variant='outlined'
				color='primary'
				className={classes.buttonsGroupContainer}
			>
				<Tooltip component='span' title='Download list of customers users'>
					<Button className={classes.buttonsGroup} onClick={props.onDownloadReport}>
						<CloudDownloadIcon />
					</Button>
				</Tooltip>
				<Tooltip component='span' title='Mode list'>
					<Button
						className={classes.buttonsGroup}
						disabled={!props.gridMode}
						onClick={props.switchViewMode}
					>
						<ListIcon />
					</Button>
				</Tooltip>
				<Tooltip component='span' title='Mode card'>
					<Button
						className={classes.buttonsGroup}
						disabled={props.gridMode}
						onClick={props.switchViewMode}
					>
						<GridIcon />
					</Button>
				</Tooltip>
				<Authorization profile={props.userProfile} allowedRoles={[PORTAL_ADMINISTRATOR]}>
					<Tooltip component='span' title='Add customer'>
						<Button
							className={classes.buttonsGroup}
							onClick={props.onAddLateralPanelConfig}
						>
							<AddIcon />
						</Button>
					</Tooltip>
				</Authorization>
			</ButtonGroup>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Change the list mode to card and vice versa
	 * */
	switchViewMode: propTypes.func.isRequired,
	/**
	 * Determines whether card mode is stored in local storage
	 */
	gridMode: propTypes.bool
}

DataGridToolbar.defaultProps = {
	gridMode: false,
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	switchViewMode: () => console.warn('Callback [switchViewMode] no defined')
}

export default withImmutablePropsToJS(DesktopView)
