const SEARCH_SKU = 0;
const SEARCH_MATERIAL = 1;
const RESULTS = 2;

export const moveForwardStepIsActive = (nextStep) => {
    let moveForwardStepIsActive = false;
    if (nextStep === SEARCH_SKU)
        moveForwardStepIsActive = true;
    if (nextStep === SEARCH_MATERIAL)
        moveForwardStepIsActive = true;
    if (nextStep === RESULTS)
        moveForwardStepIsActive = true;
    if (nextStep === SEARCH_MATERIAL)
        moveForwardStepIsActive = true;
    if (nextStep === SEARCH_MATERIAL)
        moveForwardStepIsActive = true;
    return moveForwardStepIsActive;
};
