import { makeStyles } from '@material-ui/styles'

/**
 * avatar styles
 */
export const toolbarSearchBoxStyle = makeStyles((theme) => ({
    container: {
        display: "flex",
    },
    searchMovil:{
        width: "300px"
    },
    fab:{
        backgroundColor: theme.palette.colors.purpleDark,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.colors.purpleDark,
            opacity:.75
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.purpleDark} !important`,
            color: `${theme.palette.common.white} !important`,
        },
    },
    fabSap:{
        backgroundColor: theme.palette.colors.green,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.colors.green,
            opacity:.75
        },
        "&.Mui-disabled": {
            backgroundColor: `${theme.palette.colors.green} !important`,
            color: `${theme.palette.common.white} !important`,
        },
    }
}))
