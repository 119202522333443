/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js';
/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import {
	Layout,
	Content,
    Header,
} from '../../layouts/AuthenticatedLayout'
import Toolbar from '../../common/Toolbar'
import AddressComponent from '../components/Address'
import ExpansionComponent from './SparePartsExpansion'

/** Import material UI */
import {
	Typography,
	Card,
	CardContent,
	Paper,
} from '@material-ui/core'
/** Import styles */
import { showMobileStyles } from '../resources/ShowMobileStyles'
import { toolbarStyles } from '../../../resources/styles/ToolbarStyles'
import iconSparePart from '../../../resources/images/sparePart.svg';
import { GetBackgroundColorSparePartRequestByStatus } from '../../../store/helpers/StatusHelper'
import ShowMobileSkeleton from '../../skeletons/services/componets/ShowMobileSkeleton'

/**
 * View create desktop
 */
const OpenViewMovil = (props) => {
	const {
		userProfile,
		isLoading,
		sparePartRequest,
	} = props

    const [status, setStatus] = useState(null)
    const [mode] = useState("MODE_SHOW");
	const [expanded, setExpanded] = React.useState("");
	const classes = showMobileStyles()
    const toolbarStylesClasses = toolbarStyles()


    useEffect(() => {
		if (!!props.sparePartRequest.open && props.sparePartRequest.open.Status) {
			setStatus(props.sparePartRequest.open.Status)
		}
	}, [props.sparePartRequest.open])

    const colorByStatus = status && GetBackgroundColorSparePartRequestByStatus(status, toolbarStylesClasses)
	const onRenderViewOptions = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
			</div>
		)
    }

    const onRenderContent = () => {
        if (isLoading) {
            return <ShowMobileSkeleton />
        } else {
            return (
                <div className={classes.root}>
                    <Toolbar
                        title={(sparePartRequest && sparePartRequest.open) ? `REQUEST | FOLIO: ${sparePartRequest.open.Folio}` : ""}
                        renderContentRight={onRenderViewOptions()}
                        icon={iconSparePart}
                        renderBackgroundColor={colorByStatus}
					/>
                    <Card>
						<CardContent>
                            {(sparePartRequest && sparePartRequest.open) && 
                                <span>
                                    <Typography className={classes.textHeader} color="textSecondary">{(sparePartRequest && sparePartRequest.open && sparePartRequest.open.Service)
                                        ? `TICKET: ${sparePartRequest.open.Service.Ticket}` : ""}</Typography>

                                    <Typography className={classes.textHeader} color="textSecondary">{(sparePartRequest && sparePartRequest.open && sparePartRequest.open.Service)
                                        ? `RETAILER: ${sparePartRequest.open.Service.Retailer.Name}` : ""}</Typography>

                                    <Typography className={classes.textHeader} color="textSecondary">{(sparePartRequest && sparePartRequest.open && sparePartRequest.open.Customer)
                                        ? `CUSTOMER: ${sparePartRequest.open.Customer.Code} | ${sparePartRequest.open.Customer.Name}` : ""}</Typography>
                                </span>
                            }
						</CardContent>
					</Card>

                    <Paper>
                        <div className={classes.root}>
                            <Typography className={classes.titleSeries}>{"SERIES"}</Typography>
                            {sparePartRequest && sparePartRequest.open && sparePartRequest.open.Equipments.map((item) => {
                                return(
                                <ExpansionComponent
                                    key={item.Serie} 
                                    expanded={expanded}
                                    item={item}
                                    handleChange={handleChange}
                            />)})}
                        </div>
                    </Paper>
                    
                    <Card>
                        <CardContent>
                            <AddressComponent
                                requestDraft={sparePartRequest.open}
                                mode={mode}
                                isVisibleChipsAssignement={true}
                                isMobile={true}
                            />
                        </CardContent>
                    </Card>

                </div>
            )
        }

    }

	/**
	 * On select expander serial
	 * @param {*} panel 
	 */
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
	  
	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
			/>
			<SideBar
				key={'AuthenticatedSideBar'}
				onChangeLocation={props.onChangeLocation}
				selectedButtonIndex={3}
				userProfile={userProfile}
			/>
			<Content key={'AuthenticatedContent'}>
				{onRenderContent()}
			</Content>
		</Layout>
	)
}

OpenViewMovil.propTypes = {
    onChangeLocation: propTypes.func.isRequired,
	/**Callback executed when user reuired change status the spare part requests and open lateral panel */
    openLateralPanelChangeStatus: propTypes.func.isRequired,
    sparePartRequest: propTypes.object.isRequired,
    isLoading: propTypes.bool.isRequired,
}
OpenViewMovil.defaultProps = {
    onChangeLocation: () =>
        console.warn('Callback [onChangeLocation] no defined'),
	openLateralPanelChangeStatus: () =>
		console.warn('Callback [openLateralPanelChangeStatus] no defined'),
}

export default withImmutablePropsToJS(OpenViewMovil)


