/**Import react section */
import React, { Fragment } from 'react'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import propTypes from 'prop-types'

import { Typography, ButtonGroup, Tooltip, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import FilterSelect from '../../common/Filters/FilterSelect'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import SearchBox from '../../common/SearchToolbox'
import Authorization from '../../common/AuthorizationEvaluation'
import Datagrid from '../../common/apsys.datagrid.materialui/DataGrid'
import Pagination from '../../common/Pagination/Pagination'
import ImagesNoInformation from '../../common/ImagesNoInformation'
import DashboardCard from '../../dashboard/components/DashboardCard'
import ReportDateRange from '../../common/ReportDateRange/ReportDateRange'

import defaultTheme from '../../../resources/themes/default'
import { getDatagridConfiguration } from '../resources/requestIndexConfig'
import { useDataGridToolbarStyle } from '../../../resources/styles/CommonIndexStyles'
import { useCreateDesktopStyle } from '../resources/useStyles'
import {
	PORTAL_ADMINISTRATOR,
	SERVICE_PROVIDER_MANAGER,
	PROVIDER_REGISTRATION_VALIDATOR,
	VISUALIZER,
	
} from '../../../resources/RolesEnum'
import { GetStatusPaymentRequest } from '../../../store/helpers/FilterHelper'

import { getOptions } from '../../../store/helpers/FilterHelper'

/**
 * View create desktop
 */
const IndexDesktopView = (props) => {
	const classes = useCreateDesktopStyle()
	const { userProfile, paymentPartsIndex, pagination, serviceProviders, dashboard, onCreateRequest, customers } = props
	const { isDownloadReport, isLoadingPayments, isLoadingDashboard } = props
	const { onOpenRequest, onChangePage, onChangeSortingCriteria, onDownloadReportPaymentRequest } = props
	let configuration = getDatagridConfiguration()
	let serviceProvider = getOptions(serviceProviders)
	let customerOptions = getOptions(customers)

	const onRenderContent = () => {
		if (paymentPartsIndex.paymentRequests.length >= 1 || isLoadingPayments) {
			return (
				<Datagrid
					headers={configuration}
					isLoading={isLoadingPayments}
					data={paymentPartsIndex.paymentRequests}
					onChangeSortingCriteria={onChangeSortingCriteria}
					sortBy={pagination.orderBy}
					sortDirection={pagination.orderType}
					onItemClick={onRowClick}
				/>
			)
		} else {
			return (
				<ImagesNoInformation
					query={props.searchValue}
					data={paymentPartsIndex.paymentRequests}
					isLoading={paymentPartsIndex.isLoading}
					titleData={'No exist payment request'}
				/>
			)
		}
	}

	const onRowClick = (sender, item) => {
		if (onOpenRequest) {
			onOpenRequest(item)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Payment Requests</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={6} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootIndex}>
					<div className={classes.cardList}>
						<DashboardCardLists isLoadingDashboard={isLoadingDashboard} dashboard={dashboard} />
					</div>
					<div className={classes.containerIndex}>
						<DataGridToolbar
							onCreateRequest={onCreateRequest}
							userProfile={userProfile}
							allowedRoles={[SERVICE_PROVIDER_MANAGER]}
							onChange={props.onChangeSearchValue}
							searchValue={props.searchValue}
							serviceProviders={serviceProvider}
							customers={customerOptions}
							invoice={[]}
							validationDate={[]}
							onDownloadReportPaymentRequest={onDownloadReportPaymentRequest}
							isDownloadReport={isDownloadReport}
						/>
						{onRenderContent()}
					</div>
					<div className={classes.pagination}>
						<div className={classes.flexGrow} />
						<Pagination
							count={pagination.total ? pagination.total : 0}
							page={pagination.pageNumber ? pagination.pageNumber : 0}
							onChangePage={onChangePage}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}
IndexDesktopView.propTypes = {
	isLoadingPayments: propTypes.bool.isRequired,
	onCreateRequest: propTypes.func.isRequired,
	/**
	 * Open a spare part request
	 */
	onOpenRequest: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * On change search value
	 */
	onChangeSearchValue: propTypes.func.isRequired,
	/**
	 * The object contains the paginated
	 */
	paymentPartsIndex: propTypes.shape({
		/**
		 * Determine the initial sort by column
		 */
		orderBy: propTypes.string,
		/**
		 * Defines the initial sort direction
		 */
		orderType: propTypes.oneOf(['asc', 'desc']),
		/**
		 * Defines the total de retails pagination
		 */
		total: propTypes.number,
		/**
		 * Defines the page number pagination
		 */
		pageNumber: propTypes.number,
		/**
		 * Lsit payment requests
		 */
		paymentRequests: propTypes.arrayOf(
			propTypes.shape({
				Folio: propTypes.any.isRequired
			})
		).isRequired
	}),
	/**
	 * On change sort criteria callback
	 */
	onChangeSortingCriteria: propTypes.func.isRequired
}
IndexDesktopView.defaultProps = {
	isLoadingPayments: false,
	paymentPartsIndex: {
		paymentRequests: []
	},
	onOpenRequest: () => console.warn('Callback [onOpenRequest] no defined'),
	onChangeSearchValue: () => console.warn('Callback [onChangeSearchValue] no defined')
}
export default withImmutablePropsToJS(IndexDesktopView)

/**
 * Datagrid column toolbar
 */
const DataGridToolbar = (props) => {
	const classes = useDataGridToolbarStyle()

	return (
		<div className={classes.searchIndex}>
			<SearchBox placeholder='Search request' onChange={props.onChange} value={props.searchValue} />
			<ButtonGroup component='span' variant='text' size='small' className={classes.buttonsGroupContainer}>
				<Authorization profile={props.userProfile} allowedRoles={props.allowedRoles}>
					<Tooltip component='span' title='Create Payment Request'>
						<Button onClick={props.onCreateRequest} className={classes.buttonsGroup}>
							<AddIcon />
						</Button>
					</Tooltip>
				</Authorization>
			</ButtonGroup>

			<ReportDateRange onDownloadReport={props.onDownloadReportPaymentRequest} isDownloadReport={props.isDownloadReport} />

			<div className={classes.flexGrow} />
			{/* <FilterSelect
				nameFilter={'INVOICE'}
				data={props.invoice}
				nameFilterUrl={'invoice'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR]}
			/>
			<FilterSelect
				nameFilter={'VALIDATION DATE'}
				data={props.validationDate}
				nameFilterUrl={'validationDate'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR]}
			/> */}
			<FilterSelect
				nameFilter={'CUSTOMERS'}
				data={props.customers}
				nameFilterUrl={'customers'}
				isActiveCleanButton={true}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR, VISUALIZER]}
			/>
			<FilterSelect
				nameFilter={'PROVIDERS'}
				data={props.serviceProviders}
				nameFilterUrl={'providers'}
				isVisibleSearch={true}
				userProfile={props.userProfile}
				roles={[PORTAL_ADMINISTRATOR, PROVIDER_REGISTRATION_VALIDATOR, VISUALIZER]}
			/>
			<FilterSelect
				nameFilter={'STATUS'}
				data={GetStatusPaymentRequest()}
				nameFilterUrl={'statusFilter'}
				isVisibleSearch={false}
				userProfile={props.userProfile}
				roles={[PORTAL_ADMINISTRATOR, SERVICE_PROVIDER_MANAGER, VISUALIZER]}
			/>
		</div>
	)
}

DataGridToolbar.propTypes = {
	/**
	 * On change search value
	 */
	onChange: propTypes.func.isRequired,
	/**
	 * Initial search value
	 */
	searchValue: propTypes.string,
	/**
	 * Open side panel to add new  user's
	 */
	onCreateRequest: propTypes.func.isRequired
}

DataGridToolbar.defaultProps = {
	searchValue: '',
	onChange: () => console.warn('Callback [onChange] no defined'),
	onCreateRequest: () => console.warn('Callback [onCreateRequest] no defined')
}

/**
 * DashboardCardLists component
 */
const DashboardCardLists = (props) => {
	const { dashboard } = props

	return (
		<Fragment>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.newServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.newServiceDark}
				totalServices={dashboard ? dashboard.totalDraft : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalDraft : 0}
				label={'Draft Requests'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.waitingSparePartsMain}
				secondaryColor={defaultTheme.palette.serviceStatus.waitingSparePartsDark}
				totalServices={dashboard ? dashboard.totalInProcess : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalInProcess : 0}
				label={'In process requests'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.cancelServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.cancelServiceDark}
				totalServices={dashboard ? dashboard.totalRejected : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalRejected : 0}
				label={'Rejected requests'}
			/>
			<DashboardCard
				mainColor={defaultTheme.palette.serviceStatus.completedServiceMain}
				secondaryColor={defaultTheme.palette.serviceStatus.completedServiceDark}
				totalServices={dashboard ? dashboard.totalAccepted : 0}
				percentajeValue={dashboard ? dashboard.percentageTotalAccepted : 0}
				label={'Accepted requests'}
			/>
		</Fragment>
	)
}
