/**Import react section */
import React from 'react';
import PropTypes from 'prop-types';
/**Import material UI Section */
import { Dialog, Fab } from '@material-ui/core';
import { DialogTitle, DialogActions, DialogContent, LinearProgress, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import { cropDialogStyles } from '../../resources/styles/CropDialogStyles'

/**
 * Dialog component
 *
 * @export
 * @class Dialog
 * @extends {Component}
 */
export const DialogView = props => {
    const classes = cropDialogStyles();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={props.maxWidth}
        >
            <DialogTitle>
                <Typography className={classes.title}>{props.header}</Typography>
                <Fab
                    size="small"
                    disabled={props.disabled}
                    className={classes.buttonClose}
                    onClick={props.onClose}>
                    <DeleteIcon className={classes.colorIconClose} />
                </Fab>

            </DialogTitle>
            <DialogContent
                style={props.style}
            >
                {props.children}
            </DialogContent>
            {props.isSaving && <LinearProgress color="secondary" />}
            <DialogActions className={classes.actionsButton}>
                {props.actions}
            </DialogActions>
        </Dialog>
    );
}

DialogView.propTypes = {
    open: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.string,
    style: PropTypes.shape({
        maxWidth: PropTypes.string,
    }),
    header: PropTypes.element.isRequired,
    children: PropTypes.element,
    actions: PropTypes.element,
};

export default (DialogView);