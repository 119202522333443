import React from 'react'
import propTypes from 'prop-types'
import {
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	ListItemSecondaryAction,
	Typography,
	Fab,
	Divider,
	Box
} from '@material-ui/core'
import { GetDistinctEntityFromServiceList } from '../../../store/networkdays/networkdaysSelector'
import { panelLateralNetworDaysStyles } from '../../../resources/styles/PanelLateralStyles';
import NetworkDaysIcon from '@material-ui/icons/NetworkCheckOutlined'

/**
 * Services side bar
 */
const ServicesSideBar = (props) => {
	const classes = panelLateralNetworDaysStyles();
	/**
	 * Get the service providers list included in the services
	 * @param {*} services
	 */
	const getServiceProviders = (services) => {
		let serviceProviders = GetDistinctEntityFromServiceList(
			services,
			'ServiceProviderCode',
			'ServiceProviderName'
		)
		return serviceProviders
	}

	return (
		<Drawer {...props} anchor={'right'}>
			<div className={classes.drawerContent}>
				<div className={classes.panelHeader}>
					<div className={classes.containerHeader}>
						<div className={classes.containerIcon}>
							<Fab size="small" className={classes.faultWarrantyIconSelected} disabled={true}>
								<NetworkDaysIcon />
							</Fab>
						</div>
						<div className={classes.containerText}>
							<Typography gutterBottom variant="subtitle1" className={classes.title}>{"Network days"}</Typography>
						</div>

					</div>
				</div>
				<div className={classes.contentList}>

					{getServiceProviders(props.services).map((serviceProvider) => {
						return (
							<List
								dense
								subheader={
									<ListSubheader className={classes.listSubheaderStyle}>
										{serviceProvider.Name}
									</ListSubheader>
								}
								key={serviceProvider.Code}
							>
								{props.services.map((service) => {
									return (
										<Box {...defaultProps} borderTop={0} borderRight={0} borderBottom={0} className={classes.box}>
											<ListItem key={service.Token}>
												<ListItemText
													primary={
														<Typography variant='caption' noWrap={true}>
															{service.Ticket} | {service.RetailerName}
														</Typography>
													}
													secondary={`${service.RepresentativeName}`}
												/>
												<ListItemSecondaryAction>
													<Typography>
														{`${service.NetworkDays} Days`}
													</Typography>
												</ListItemSecondaryAction>
											</ListItem>
											<Divider />
										</Box>
									)
								})}
							</List>
						)
					})}
				</div>

			</div>

		</Drawer >
	)
}

const defaultProps = {
	bgcolor: 'background.paper',
	border: 3,
	m: 1,
	borderColor: 'text.primary',
};

ServicesSideBar.propTypes = {
	open: propTypes.bool.isRequired,
	services: propTypes.array.isRequired
}
ServicesSideBar.defaultProps = {
	open: false,
	services: []
}
export default ServicesSideBar
