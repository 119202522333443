/**Import react section */
import React from 'react'
import propTypes from 'prop-types'

/** Import material UI */
import {
    Typography,
    Grid,
    Chip,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
/** Components */
import ExpandedSummary from './ExpandedSummary'

/**Import resources section */
import { serieComponentStyles } from '../resources/ShowMobileViewStyle'

const SerieComponent = (props) => {
    const classes = serieComponentStyles()
    /**Functions props */
    const { onAddSpareRequestInDraft } = props
    /**bools props section */
    const { isSavingRequestDraft, isAuthorizadoForEditedData } = props
    /**Array props section */
    const { equipments } = props

    const onRenderContentExpansionSummary = (equipment) => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {isAuthorizadoForEditedData && <div className={classes.expanderHeader}>
                        <div className={classes.flexGrow}></div>
                        <Chip
                            label="Add spare part requests"
                            onClick={() => onAddSpareRequestInDraft()}
                            avatar={<AddIcon />}
                            color='primary'
                            disabled={isSavingRequestDraft}
                        />
                    </div>}
                </Grid>
                <List >
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" variant="body2" className={classes.inline}  color="textPrimary" >
                                        <strong>Model: </strong> 
                                    </Typography>
                                    {` ${equipment.Model ? equipment.Model : ""} | ${equipment.Gas ? equipment.Gas : ""}`} <br/>
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary" >
                                        <strong>Fault issue: </strong>
                                    </Typography>
                                    {equipment.FaultIssue ? equipment.FaultIssue : ""} <br />
                                    <Typography component="span" variant="body2" className={classes.inline} color="textPrimary" >
                                        <strong>Comments: </strong>
                                    </Typography>
                                    {equipment.Comments ? equipment.Comments : ""} <br />
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </Grid>
        )
    }

    return (
        <>
           { equipments && equipments.map(equipment => {
                return (
                    <ExpandedSummary
                        key={equipment.Token} 
                        id={equipment.Token}
                        isPaddingContent={false}
                        title= {`Serial ${equipment.Serie} | ${equipment.Model}`}
                        onRenderContent={() => onRenderContentExpansionSummary(equipment)}
                        isVisibleColor={true}
                    />
                )
            })}
        </>
    )
}


SerieComponent.propTypes = {
	/**
	 * The list of all equipments in service
	 */
    equipments: propTypes.array.isRequired,
    /**
     * Determinate if is saving service
     */
    isSavingRequestDraft: propTypes.bool,
    /**
     * Callback redirect to spare part request
     */
    onAddSpareRequestInDraft: propTypes.func.isRequired
}
SerieComponent.defaultProps = {
    equipments: [],
    isSavingRequestDraft: false,
    onAddSpareRequestInDraft: () =>
        console.warn('Callback [onAddSpareRequestInDraft] no defined'),
}

export default SerieComponent