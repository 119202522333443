import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { hasAuthorization } from '../../store/session/sessionActions'
import * as Sentry from '@sentry/react';

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * A protected route for authenticated user and authorization roles validation
 */
export const ProtecteRoute = ({
	component: Component,
	allowedRoles,
	...other
}) => {
	let loggedUserProfile = useSelector((state) => state.session.get('profile'))
	loggedUserProfile = loggedUserProfile ? loggedUserProfile.toJS() : null

	const isAuthorized = () => hasAuthorization(loggedUserProfile, allowedRoles)

	return (
		<SentryRoute
			{...other}
			render={(props) => {
				if (isAuthorized()) return <Component {...props} />
                
				return (
					<Redirect
						to={{
							pathname: '/unauthorized',
							state: {
								from: props.location
							}
						}}
					/>
				)
			}}
		/>
	)
}
