/**Import react sectcion */
import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/** Import components */
import { Layout, Content, Header } from '../../layouts/AuthenticatedLayout'
import SideBar from '../../common/DashboadSideBar'
import DateRangePicker from '../../common/daterangepicker/DateRangePicker'
import GraphServices from './GraphServices'
/**Import material ui section */
import { Paper } from '@material-ui/core'

/**Import styles section */
import { graphIndexStyles } from '../resources/GraphStyles'
const GraphIndexDesktopView = (props) => {
    const {chartData, chartDescription, userProfile} = props;
    const classes = graphIndexStyles();

    return (
        <Layout>
            <Header
                key={'AuthenticatedHeader'}
                user={props.user}
                breadcrumbs={
                    <NetworkDayDateRangePicker
                        onDateRangeChange={props.onDateRangeChange}
                        startDate={props.startDate}
                        endDate={props.endDate}

                    />
                }
            />
            <SideBar
                key={'AuthenticatedSideBar'}
                onChangeLocation={props.onChangeLocation}
                selectedButtonIndex={8}
                userProfile={userProfile}
            />
            <Content key={'AuthenticatedContent'}>
                <Paper className={classes.root}>
                    <div >
                        <GraphServices
                            chartData={chartData}
                            chartDescription={chartDescription}
                        />
                    </div>
                </Paper>
            </Content>
        </Layout >

    )
}

GraphIndexDesktopView.propTypes = {
	/**
	 * The initial date to shown in the x-axis chart
	 */
	startDate: propTypes.instanceOf(moment),
	/**
	 * The final date to shown in the x-axis chart
	 */
    endDate: propTypes.instanceOf(moment),
    /**
	 * Callback executed when the change interval time
	 */
    onDateRangeChange: propTypes.func,
}
GraphIndexDesktopView.defaultProps = {
    startDate: moment(),
	endDate: moment(),
    onDateRangeChange: () =>
        console.warn('No [onDateRangeChange] callback defined')
}


/**
 * NetworkDay DateRangePicker configured
 */
const NetworkDayDateRangePicker = (props) => {
    const predefinedRanges = [
        {
            label: 'This Year',
            startDate: moment().startOf('year'),
            endDate: moment()
        },
        {
            label: 'Last Year',
            startDate: moment().add(-1, 'year'),
            endDate: moment()
        },
        {
            label: 'Last Semester',
            startDate: moment().add(-6, 'month'),
            endDate: moment()
        },
        {
            label: 'Last Trimester',
            startDate: moment().add(-3, 'month'),
            endDate: moment()
        }
    ]

    return (
        <DateRangePicker
            textColor='white'
            variant='outlined'
            startDate={props.startDate}
            endDate={props.endDate}
            predefinedRanges={predefinedRanges}
            onChangeRange={props.onDateRangeChange}
        />
    )
}

export default  withImmutablePropsToJS(GraphIndexDesktopView)