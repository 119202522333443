import { fromJS } from 'immutable';
import * as statistics from './statisticsActions';

let initialState = fromJS({
    activeStep: 0,
    isFowardEnabled: true,
    isBackwardEnabled: false,
    isLoadingFirstData: false,
    isLoadingSecondData: false,
    isSaving: false,
    serviceProviders: [],
    preferentServiceProviders: [],
    serviceProvider: null,
    internalQueryParameters: null,
    providerUsers: [],
    materialsByGae: [],
    customersInvoice: [],
    statisticsReport: [],
    pagination: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        orderBy: "code",
        orderType: "asc",
    },
})


const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case statistics.MOVE_FORWARD_STEP: {
            return state.merge({
                activeStep: action.activeStep,
                isFowardEnabled: action.isFowardEnabled,
                isBackwardEnabled: action.isBackwardEnabled
            });
        }
        case statistics.MOVE_BACKWARD_STEP: {
            return state.merge({
                activeStep: action.activeStep,
                isFowardEnabled: action.isFowardEnabled,
                isBackwardEnabled: action.isBackwardEnabled,
            });
        }
        case statistics.IS_LOADING_FIRST_DATA: {
            return state.set('isLoadingFirstData', action.status);
        }
        case statistics.SET_FIRST_DATA: {
            return state.set('materialsByGae', fromJS(action.data));
        }
        case statistics.IS_LOADING_SECOND_DATA: {
            return state.set('isLoadingSecondData', action.status);
        }
        case statistics.SET_SECOND_DATA: {
            return state.set('customersInvoice', fromJS(action.data));
        }
        case statistics.IS_DOWNLOAD_REPORT: {
            return state.set('isDownloadStatisticsReport', action.status);
        }
        case statistics.SET_ACTIVE_STEP: {
            return state.set('activeStep', action.activeStep);
        }
        default:
            return state
}}

export default usersReducer