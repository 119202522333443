export default (values, trend) => {
	return {
		type: 'mixed',
		backgroundColor: 'none',
		title: {
			text: 'Network Days',
			'adjust-layout': true
		},
		plot: {
			animation: {
				effect: 'ANIMATION_UNFOLD_VERTICAL',
				speed: 'ANIMATION_FAST'
			}
		},
		plotarea: {
			margin: 'dynamic 45 60 dynamic'
		},
		scaleX: {
			transform: {
				type: 'date'
			},
			zooming: true
			//zoomTo: [0, 800],
		},
		scrollX: {},
		preview: {
			adjustLayout: true,
			borderWidth: '1px',
			handle: {
				height: '20px',
				lineWidth: '0px'
			}
		},
		series: [
			{
				type: 'area',
				values: values
			},
			{
				type: 'line',
				lineColor: '#808080',
				lineStyle: "dotted",
				marker: {
					visible: false
				},
				values: trend
			}
		]
	}
}
