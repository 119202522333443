import React from 'react'
import moment from 'moment'
import { GetServiceStatus, GetColorNetWorkDays } from '../../../store/helpers/StatusHelper'
import { Typography } from '@material-ui/core'

const ServiceTableConfig = [
	{
		title: 'Ticket',
		dataSource: 'Ticket',
		sortable: true,
		width: 20
	},
	{
		title: 'Rep',
		dataSource: 'RepresentativeName',
		sortable: true
	},
	{
		title: 'Serial Number',
		dataSource: 'Serie',
		sortable: true
	},
	{
		title: 'Model',
		dataSource: 'Model',
		sortable: true
	},
	{
		title: 'Gas',
		dataSource: 'Gas',
		sortable: true
	},
	{
		title: 'Dispatcher',
		dataSource: 'Dispatcher',
		onRenderProperty: (item) =>
			item.Dispatcher ? (
				<Typography noWrap={true} variant='subtitle2'>
					{' '}
					{item.Dispatcher.Name}
				</Typography>
			) : (
				''
			)
	},
	{
		title: 'Tech Assigned',
		dataSource: 'TechAssigned',
		width: 20,
		onRenderProperty: (item) =>
			item.TechAssigned ? (
				<Typography noWrap={true} variant='subtitle2'>
					{' '}
					{item.TechAssigned.Name}
				</Typography>
			) : (
				''
			)
	},
	{
		title: 'Customer',
		dataSource: 'RetailerName',
		sortable: true
	},
	{
		title: 'Status',
		onRenderProperty: (item) => (item.Status ? GetServiceStatus(item.Status) : ''),

		sortable: true
	},
	{
		title: 'Service Provider',
		dataSource: 'ServiceProviderName',
		sortable: true
	},
	{
		title: 'Initiated Date',
		dataSource: 'IntiatedDate',
		sortable: true,
		onRenderProperty: (item) => (item.IntiatedDate ? moment(item.IntiatedDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : '')
	},
	{
		title: 'Completion Date',
		dataSource: 'CompletionDate',
		sortable: true,
		onRenderProperty: (item) => (item.CompletionDate ? moment(item.CompletionDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : '')
	},
	{
		title: 'Dispatch Status',
		dataSource: 'DispatchStatus',
		sortable: true
	},
	{
		title: 'Network Days',
		dataSource: 'NetworkDays',
		sortable: true,
		onRenderProperty: (item) => (item.NetworkDays !== null && item.NetworkDays !== undefined ? GetColorNetWorkDays(item.NetworkDays) : '')
	}
]
export default ServiceTableConfig
