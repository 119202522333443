/**Import react section */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
/**Import Material Ui section */

/**Import actions section */
import { loadAllSalesRepresentatives } from '../../store/salesrepresentatives/salesRepresentativesActions'
import { loadAllRetailss } from '../../store/retails/retailsActions'
import { addService } from '../../store/services/serviceActions'
import { loadInternalUsers } from '../../store/users/UsersActions'
import { toSafeObject } from '../../store/helpers/StateHelper'

/**Import component section */
import CreateDesktopView from './components/CreateDesktopView'
import Toaster from '../common/Toaster'

/**impoer helpers section */
import { getUsersByRole } from '../../store/helpers/UserHelper'
import { DISPATCHER } from '../../resources/RolesEnum'
const Create = () => {
    /**Hooks sections */
    const dispatch = useDispatch();
	let history = useHistory();

    const salesRepresentatives = useSelector((state) =>
        state.salesRepresentatives.get('salesRepresentatives')
    )
    const userProfile = useSelector((state) => toSafeObject(state.session.get('profile')))
    const retails = useSelector((state) => state.retails.get('retails'))
    const isSavingService = useSelector((state) => state.services.get('isSavingService'))
    const [toasterOptions, setToasterOptions] = useState({ open: false, variant: null, message: null });
    const internalUsers = useSelector((state) => toSafeObject(state.users.get('internalUsers')))

	const redirectToService = (token) =>{
		history.push(`/services/${token}/${'-'}`)
    }

    const redirectToHomeCancelService = () =>{
		history.push(`/`)
    }

    let emailsRolesDispatcher = getUsersByRole(DISPATCHER, internalUsers)
    
    /**
     * Function to create service
     * @param {*} service 
     * @param {*} position 
     */
    const createService = (service, position, accountCode) =>{
        return dispatch(addService(service, position, accountCode, emailsRolesDispatcher)).then((token) => {
            redirectToService(token)
        })
        .catch(() => {
            setToasterOptions({ open: true, message: "An error was generated while generating the request", variant: 'error' })
        });
    }

    const onChangeLocation = (location) => history.push(location)


    useEffect(() => {
        dispatch(loadInternalUsers())
        dispatch(loadAllSalesRepresentatives(null, 1000))
        dispatch(loadAllRetailss())
    }, [dispatch])

    return (
        <>
            <CreateDesktopView
                salesRepresentatives={salesRepresentatives}
                retails={retails}
                createService={createService}
                isSavingService={isSavingService}
                redirectToHomeCancelService={redirectToHomeCancelService}
                onChangeLocation={onChangeLocation}
                userProfile={userProfile}
            />

            {/** Toaster */}
            {
                toasterOptions.open &&
                <Toaster
                    toasterOptions={toasterOptions}
                    onClose={() => setToasterOptions({ open: false })}
                />
            }
        </>
        
    )
}

export default Create
