import axios from 'axios'
import { getAuthorizedConfiguration } from '../helpers/RequestHelper'

import { moveForwardStepIsActive } from '../helpers/StepperHelperStatistics'
export const MOVE_FORWARD_STEP = 'MOVE_FORWARD_STEP'
export const MOVE_BACKWARD_STEP = 'MOVE_BACKWARD_STEP'
export const SET_FIRST_DATA = 'SET_FIRST_DATA'
export const SET_SECOND_DATA = 'SET_SECOND_DATA'
export const IS_LOADING_SECOND_DATA = 'IS_LOADING_SECOND_DATA'
export const IS_LOADING_FIRST_DATA = 'IS_LOADING_FIRST_DATA'
export const IS_DOWNLOAD_REPORT = 'IS_DOWNLOAD_REPORT'
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'

export const moveBackwardStep = () => {
	return function(dispatch, getState) {
		let actualStep = getState().statistics.get('activeStep')
		let prevStep = --actualStep
		dispatch({
			type: MOVE_BACKWARD_STEP,
			activeStep: prevStep,
			isFowardEnabled: true,
			isBackwardEnabled: prevStep !== 0 ? true : false
		})
	}
}

export const moveForwardStep = () => {
	return function(dispatch, getState) {
		let actualStep = getState().statistics.get('activeStep')
		let nextStep = ++actualStep
		let isFowardEnabled = moveForwardStepIsActive(nextStep)
		dispatch({
			type: MOVE_FORWARD_STEP,
			activeStep: nextStep,
			isFowardEnabled: isFowardEnabled,
			isBackwardEnabled: true
		})
	}
}

export const isLoadingFirstData = (status) => {
	return {
		type: IS_LOADING_FIRST_DATA,
		status
	}
}
export const isLoadingSecondData = (status) => {
	return {
		type: IS_LOADING_SECOND_DATA,
		status
	}
}

export const setFirstData = (data) => {
	return {
		type: SET_FIRST_DATA,
		data
	}
}

export const setSecondData = (data) => {
	return {
		type: SET_SECOND_DATA,
		data
	}
}

export const clearFirstData = () => {
	return {
		type: SET_FIRST_DATA,
		data: []
	}
}
export const clearSecondData = () => {
	return {
		type: SET_SECOND_DATA,
		data: []
	}
}

const setIsDownloadReport = (status) => {
	return {
		type: IS_DOWNLOAD_REPORT,
		status
	}
}

export const setActiveStep = (step) => {
	return function(dispatch) {
		dispatch({
			type: SET_ACTIVE_STEP,
			activeStep: step
		})
	}
}

export const getMaterialsByGae = (gae) => {
	return function(dispatch, getState) {
		let loadedFirstData = getState().statistics.get('statistics')
		if (loadedFirstData && loadedFirstData.size > 0) {
			return Promise.resolve()
		}

		dispatch(isLoadingFirstData(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// {gae} es un valor con el  que se va a hacer la peticion
		let url = `${window.config.serviceManagementBackendUrl}/statistics/sap/gae/${gae}`
		return axios
			.get(url, config)
			.then((serverResponse) => {
				dispatch(isLoadingFirstData(false))
				if (serverResponse.status === 200) {
					dispatch(setFirstData(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isLoadingFirstData(false))
				console.error('Error loading first data', error)
				dispatch(clearFirstData())
				return Promise.reject(error.response.data.Message)
			})
	}
}

//obtener facturacion de clientes
export const getCustomerInvoice = (matNumbers, startDate, endDate, customers) => {
	return function(dispatch, getState) {
		dispatch(isLoadingSecondData(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		let url = `${window.config.serviceManagementBackendUrl}/statistics/sap/gae/customers`
		let command = {
			Matnr: matNumbers.map((x) => ({ LOW: x.toString().padStart(18, '0'), HIGH: x.toString().padStart(18, '0') })),
			StartDate: startDate,
			EndDate: endDate,
			Customers: customers.Customer.map((x) => ({
				LOW: x.value.toString().padStart(10, '0'),
				HIGH: x.value.toString().padStart(10, '0')
			}))
		}
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				dispatch(isLoadingSecondData(false))
				if (serverResponse.status === 200) {
					dispatch(setSecondData(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isLoadingSecondData(false))
				console.error('Error loading second data', error)
				dispatch(clearSecondData())
				return Promise.reject(error.response.data.Message)
			})
	}
}

//method to download the excel file
export const downloadStatisticsReport = (result) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		config.responseType = 'arraybuffer'
		let url = `${window.config.serviceManagementBackendUrl}/statistics/download`
		let command = {
			InvoiceCustomers: result
		}
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				dispatch(setIsDownloadReport(false))
				if (serverResponse.status === 200) {
					let blob = new Blob([serverResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Statistics_Report.xlsx'
					a.click()
				}
				return Promise.resolve('Ok')
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error downloading report file', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}
