import { makeStyles } from '@material-ui/styles'

/**
 * custom lateral panel edit commonstyle
 */
export const editLateralPanelStyles = makeStyles((theme) => ({
    root:{
        padding:"20px"
    },
    avatar: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.colors.purpleDark
    },
    texboxContainer:{
        padding:"20px",
        backgroundColor: theme.palette.colors.purpleDark
    },
    contentRolesForm:{
        maxHeight: `calc(100vh - ${400}px)`, 
        overflowY: "auto", 
        paddingRight:"20px",
        paddingLeft:"20px",
    }
}))