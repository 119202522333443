import axios from 'axios'
import { getAuthorizedConfiguration, getAuthorizedConfigurationParameters } from '../helpers/RequestHelper'
import { loadInternalUsers } from '../users/UsersActions'
import { toSafeArray } from '../../store/helpers/StateHelper'
import { sortBy } from '../../store/helpers/SortingHelper'
import moment from 'moment'

export const IS_LOADING_SERVICES = 'IS_LOADING_SERVICES'
export const SET_SERVICES = 'SET_SERVICES'
export const IS_LOADING_DASHBOAR = 'IS_LOADING_DASHBOAR'
export const SET_DASHBOAR = 'SET_DASHBOAR'
export const IS_SAVING_SERVICE = 'IS_SAVING_SERVICE'
export const IS_LOADING_SERVICE = 'IS_LOADING_SERVICE'
export const SET_SERVICE = 'SET_SERVICE'
export const IS_ADDING_EQUIPMENT = 'IS_ADDING_EQUIPMENT'
export const IS_SAVING_EQUIPMENT = 'IS_SAVING_EQUIPMENT'
export const ADD_EQUIMPENT_IN_LIST = 'ADD_EQUIMPENT_IN_LIST'
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT'
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT'
export const IS_DONWLOAD_REPORT = 'IS_DONWLOAD_REPORT'
export const SET_START_AND_END_DATE = 'SET_START_AND_END_DATE'
export const SET_SERVICE_METRICS = 'SET_SERVICE_METRICS'
export const UPDATE_ATTENTION_DATE = 'UPDATE_ATTENTION_DATE'
export const SET_SERVICES_CLOSED = 'SET_SERVICES_CLOSED'
export const ADD_FILES_SERVICE = 'ADD_FILES_SERVICE'
export const REMOVE_FILES_SERVICE = 'REMOVE_FILES_SERVICE'
export const ADD_COMMENTS_HISTORY = 'ADD_COMMENTS_HISTORY'
export const UPDATE_COMMENTS_HISTORY = 'UPDATE_COMMENTS_HISTORY'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'

/**
 * Is loading action constructor
 */
export const isLoadingServices = (status) => {
	return {
		type: IS_LOADING_SERVICES,
		status
	}
}

/**
 * Set services in action list
 */
export const setServicesList = (services) => {
	return {
		type: SET_SERVICES,
		services
	}
}

/**
 * Is loading action constructor
 */
export const isLoadingDashboar = (status) => {
	return {
		type: IS_LOADING_DASHBOAR,
		status
	}
}

/**
 * Set dashboard action dashboard
 */
export const setDashboardList = (dashboard) => {
	return {
		type: SET_DASHBOAR,
		dashboard
	}
}

/**
 * Is saving action constructor
 */
export const isSavingService = (status) => {
	return {
		type: IS_SAVING_SERVICE,
		status
	}
}

export const isLoadingService = (status) => {
	return {
		type: IS_LOADING_SERVICE,
		status
	}
}

/**
 * Set service in action selected
 */
export const setService = (service) => {
	return {
		type: SET_SERVICE,
		service
	}
}

/**
 * On saving equipment
 * @param {*} status
 */
export const isSavingEquipment = (status) => {
	return {
		type: IS_SAVING_EQUIPMENT,
		status
	}
}

/** On adding equipment */
export const isAddingEquipment = (status) => {
	return {
		type: IS_ADDING_EQUIPMENT,
		status
	}
}

/**
 * Add equipment in list from service
 * @param {} equipment
 */
export const addEquimpentListService = (equipment) => {
	return {
		type: ADD_EQUIMPENT_IN_LIST,
		equipment
	}
}

/**
 * function update equipment
 * @param {*} equipment
 */
export const updateEquipment = (equipment) => {
	return {
		type: UPDATE_EQUIPMENT,
		equipment
	}
}

export const deleteEquipment = (equipmentToken) => {
	return {
		type: DELETE_EQUIPMENT,
		equipmentToken
	}
}

/**
 * Flag status (true or false)
 */
const setIsDownloadReport = (status) => {
	return {
		type: IS_DONWLOAD_REPORT,
		status
	}
}

export const setStartDateAndEndDate = (startDate, endDate) => {
	return {
		type: SET_START_AND_END_DATE,
		startDate,
		endDate
	}
}

const setServiceMetrics = (data) => {
	return {
		type: SET_SERVICE_METRICS,
		data
	}
}

const updateAttentionDate = (data) => {
	return {
		type: UPDATE_ATTENTION_DATE,
		data
	}
}

const setServiceClosed = (services) => {
	return {
		type: SET_SERVICES_CLOSED,
		services
	}
}

const addCommentsHistory = (data) => {
	return {
		type: ADD_COMMENTS_HISTORY,
		data
	}
}

const updateCommentsHistory = (data) => {
	return {
		type: UPDATE_COMMENTS_HISTORY,
		data
	}
}

// const updateCommentInService = (data) => {
// 	return {
// 		type: UPDATE_COMMENT,
// 		data,
// 	}
// }

/**
 * Load the dashboard page
 */
export const loadServices = (status, queryParameters, pageSize = 10, isMobile) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isLoadingServices(true))

			const loadServicesFromBackend = async (queryParameters) => {
				// Load the internal user in order to complete dispatcher and tech assigned
				await dispatch(loadInternalUsers())
				let internalUsers = toSafeArray(getState().users.get('internalUsers'))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Define the request
				const command = {
					PageNumber: queryParameters ? queryParameters.pageNumber : 1,
					PageSize: pageSize,
					OrderBy: isMobile ? 'TechnicianServiceDate' : queryParameters ? queryParameters.sortBy : null,
					OrderType: isMobile ? 'asc' : queryParameters ? queryParameters.sortDirection : 'desc',
					Query: !!queryParameters && queryParameters.query ? queryParameters.query : '',
					ServiceProviderFilter: [],
					Status: status,
					IsMobile: isMobile
				}
				if (!!queryParameters) {
					if (!!queryParameters.statusFilter) {
						if (typeof queryParameters.statusFilter !== 'string')
							command.StatusFilter = queryParameters ? queryParameters.statusFilter.map((d) => parseInt(d, 10)) : []
						else command.StatusFilter = queryParameters ? [queryParameters.statusFilter] : []
					}
					if (!!queryParameters.representativesFilter) {
						if (typeof queryParameters.representativesFilter !== 'string')
							command.RepresentativeFilter = queryParameters ? queryParameters.representativesFilter.map((d) => d) : []
						else command.RepresentativeFilter = queryParameters ? [queryParameters.representativesFilter] : []
					}
					if (!!queryParameters.serviceProviderFilter) {
						if (typeof queryParameters.serviceProviderFilter !== 'string')
							command.ServiceProviderFilter = queryParameters ? queryParameters.serviceProviderFilter.map((d) => d) : []
						else command.ServiceProviderFilter = queryParameters ? [queryParameters.serviceProviderFilter] : []
					}
					if (!!queryParameters.retailersFilter) {
						if (typeof queryParameters.retailersFilter !== 'string')
							command.RetailFilter = queryParameters ? queryParameters.retailersFilter.map((d) => d) : []
						else command.RetailFilter = queryParameters ? [queryParameters.retailersFilter] : []
					}
					if (!!queryParameters.dispatcherFilter) {
						if (typeof queryParameters.dispatcherFilter !== 'string')
							command.DispatcherFilter = queryParameters ? queryParameters.dispatcherFilter.map((d) => d) : []
						else command.DispatcherFilter = queryParameters ? [queryParameters.dispatcherFilter] : []
					}
					if (!!queryParameters.teachAssignedFilter) {
						if (typeof queryParameters.teachAssignedFilter !== 'string')
							command.TeachAssignedFilter = queryParameters ? queryParameters.teachAssignedFilter.map((d) => d) : []
						else command.TeachAssignedFilter = queryParameters ? [queryParameters.teachAssignedFilter] : []
					}
				}

				// Get the users
				let url = `${window.config.serviceManagementBackendUrl}/services/status`
				let serverResponse = await axios.post(url, command, config)
				let serviceList = serverResponse.data
				// Sets the full information of the dispatcher and the tech assigned
				serviceList.services = serviceList.services.map((service) => {
					const dispatcher = internalUsers.find((x) => x.UserName === service.DispatcherUserName)
					const tech = internalUsers.find((x) => x.UserName === service.TechAssignedUserName)
					service.Dispatcher = dispatcher
					service.TechAssigned = tech
					return service
				})

				dispatch(setServicesList(serviceList))
			}
			return loadServicesFromBackend(queryParameters)
		} catch (ex) {
			console.error('Errog getting the service list', ex)
		}
	}
}

/**
 * Load the services closed by service provider
 */
export const loadServicesClosed = () => {
	return async (dispatch, getState) => {
		try {
			const loadServicesClosedFromBackend = async () => {
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Get the services closed by provider
				let url = `${window.config.serviceManagementBackendUrl}/services/closed`
				let serverResponse = await axios.get(url, config)
				dispatch(setServiceClosed(serverResponse.data))
			}
			return loadServicesClosedFromBackend()
		} catch (ex) {
			console.error('Errog getting the service list', ex)
		}
	}
}

/**
 * Load services to load dashboard
 */
export const loadDashboardServices = (status) => {
	return async (dispatch, getState) => {
		try {
			const loadServicesFromBackend = async () => {
				dispatch(isLoadingDashboar(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				// Create command
				let command = {
					Status: status
				}
				let config = getAuthorizedConfiguration(user)
				// Get the services from load dashboard
				let url = `${window.config.serviceManagementBackendUrl}/services/dashboard/status`
				let serverResponse = await axios.post(url, command, config)
				dispatch(setDashboardList(serverResponse.data))
			}
			await loadServicesFromBackend()
		} catch (ex) {
			dispatch(isLoadingDashboar(false))
			console.error('Error getting dashboard services', ex)
		}
	}
}

/**
 * Action to create new service
 * @param {*} service
 */
export function addService(service, position, accountCode, emailsRolesDispatcher) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Add service
		let command = {
			NameRetailer: service.Retailer ? service.Retailer.label : '',
			CodeRepresentative: service.SalesRepresentative ? service.SalesRepresentative.value : '',
			ServiceDescription: service ? service.ServiceDescription : '',
			ServiceType: service.ServiceType ? service.ServiceType.value : '',
			ServiceProviderCode: service.ServiceProvider ? service.ServiceProvider.value : '',
			AccountCode: accountCode,
			Address: {
				Country: service.Address ? service.Address.country : '',
				State: service.Address ? service.Address.administrative_area_level_1 : '',
				ZipCode: service.Address ? service.Address.postal_code : '',
				Location: service.Address ? service.Address.locality : '',
				Street: service.Address ? service.Address.route : '',
				StreetNumber: service.Address ? service.Address.street_number : '',
				Latitude: position ? position.latitude : null,
				Longitude: position ? position.longitude : null
			},
			Users: emailsRolesDispatcher
		}
		let url = `${window.config.serviceManagementBackendUrl}/service`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					return Promise.resolve(serverResponse.data.Token)
				}
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error in create service', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to get service
 * @param {*} serviceToken
 */
export const getService = (serviceToken) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isLoadingServices(true))
			const getServiceFromBackend = async () => {
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Get service
				let url = `${window.config.serviceManagementBackendUrl}/service/${serviceToken}`
				let serverResponse = await axios.get(url, config)
				let service = serverResponse.data
				let sparePartLists = []

				// Sets the full information of the spareParts
				service.SpareParts.map((service) => {
					service.SpareParts = service.SpareParts.map((sparePart) => {
						sparePartLists.push(sparePart)
						return sparePart
					})
					return service
				})
				service.SpareParts = sparePartLists

				dispatch(setService(service))
			}
			await getServiceFromBackend()
			dispatch(isLoadingServices(false))
		} catch (ex) {
			dispatch(isLoadingServices(false))
			console.error('Error in load service', ex)
		}
	}
}

/**
 * Action to create new service
 * @param {*} service
 */
export function addSeriesInService(serviceToken, series) {
	return function(dispatch, getState) {
		dispatch(isAddingEquipment(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		// Add service
		let url = `${window.config.serviceManagementBackendUrl}/sparepartrequest/sap/${series}`
		return axios
			.get(url, config)
			.then((serialResponse) => {
				let serailSAP = serialResponse.data
				let command = {
					Token: serviceToken,
					Series: [series],
					CustomerCode: serailSAP.CustomerCode,
					CustomerName: serailSAP.CustomerName,
					WarrantyDate: serailSAP.WarrantyDate,
					Model: serailSAP.Model,
					DescriptionSerie: serailSAP.DescriptionMaterial,
					MaterialCode: serailSAP.MaterialCode,
					CustomerSerie: serailSAP.CustomerCode,
					CustomerNameSerie: serailSAP.CustomerName,
					DestinatarionCode: serailSAP.DestinatarionCode,
					DestinatarionName: serailSAP.DestinatarionName
				}
				let url = `${window.config.serviceManagementBackendUrl}/service/equipments`
				return axios.post(url, command, config)
			})
			.then((serverResponse) => {
				serverResponse.data.map((equipment) => {
					return dispatch(addEquimpentListService(equipment))
				})
				dispatch(isAddingEquipment(false))
			})
			.catch((error) => {
				dispatch(isAddingEquipment(false))
				console.error('Error in add series in service service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update equipment in service
 * @param {*} serviceToken
 * @param {*} equipmentToken
 * @param {*} property
 * @param {*} propertyData
 */
export function updateSerieInSerivce(
	serviceToken,
	equipmentToken,
	refrigerationNotCoolFailure,
	iluminationFailure,
	doorFailure,
	comments,
	faultWarranty,
	completionDate
) {
	return function(dispatch, getState) {
		dispatch(isSavingEquipment(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			EquipmentToken: equipmentToken,
			RefrigerationNotCoolFailure: refrigerationNotCoolFailure,
			IluminationFailure: iluminationFailure,
			DoorFailure: doorFailure,
			Comments: comments,
			FaultWarranty: faultWarranty,
			CompletionDate: completionDate
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/equipment`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingEquipment(false))
					dispatch(updateEquipment(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingEquipment(false))
				console.error('Error to update property in equipment', error.response)
				return Promise.reject()
			})
	}
}

export function deleteSerieInSerivce(serviceToken, equipmentToken) {
	return function(dispatch, getState) {
		dispatch(isSavingEquipment(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)

		let endPoint = `${window.config.serviceManagementBackendUrl}/service/equipment/delete/${serviceToken}/${equipmentToken}`
		return axios
			.delete(endPoint, config)
			.then((serverResponse) => {
				console.log('🚀 ~ file: serviceActions.js:614 ~ .then ~ serverResponse:', serverResponse)
				if (serverResponse.status === 200) {
					dispatch(isSavingEquipment(false))
					dispatch(deleteEquipment(equipmentToken))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingEquipment(false))
				console.error('Error to delete equipment', error.response)
				return Promise.reject()
			})
	}
}

export function updateSubCategoryInSerivce(
	serviceToken,
	equipmentToken,
	refrigerationNotCoolFailure,
	iluminationFailure,
	doorFailure,
	comments,
	faultWarranty
) {
	return function(dispatch, getState) {
		dispatch(isSavingEquipment(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			EquipmentToken: equipmentToken,
			RefrigerationNotCoolFailure: refrigerationNotCoolFailure,
			IluminationFailure: iluminationFailure,
			DoorFailure: doorFailure,
			Comments: comments,
			FaultWarranty: faultWarranty
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/equipment`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingEquipment(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingEquipment(false))
				console.error('Error to update property in equipment', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action update service data
 * @param {*} service
 */
export const saveService = (service) => {
	return async (dispatch, getState) => {
		try {
			const updateServiceFromBackend = async () => {
				dispatch(isSavingService(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Add service
				let command = {
					Token: service ? service.Token : '',
					ContactPhone: service ? service.ContactPhone : '',
					ContactName: service ? service.ContactName : '',
					SiteContactName: service ? service.SiteContactName : '',
					SiteContactPhone: service ? service.SiteContactPhone : '',
					ContactEmail: service ? service.ContactEmail : '',
					ServiceDescription: service ? service.ServiceDescription : '',
					DispatcherUserName: service.Dispatcher ? service.Dispatcher.value : null,
					ServiceProviderCode: service.ServiceProvider ? service.ServiceProvider.value : '',
					TeachAssignedUserName: service.TechAssigned ? service.TechAssigned.value : '',
					StatusComments: service ? service.StatusComments : '',
					DispatchStatus: service ? service.DispatchStatus : ''
				}
				let url = `${window.config.serviceManagementBackendUrl}/service`
				let serverResponse = await axios.put(url, command, config)
				dispatch(setService(serverResponse.data))
				dispatch(isSavingService(false))
			}
			return await updateServiceFromBackend()
		} catch (ex) {
			dispatch(isSavingService(false))
			console.error('Error in update service', ex)
		}
	}
}

/**
 * Action update service data
 * @param {*} service
 */
export const updateCommentsServiceChange = (service, comments) => {
	return async (dispatch, getState) => {
		try {
			const updateServiceFromBackend = async () => {
				dispatch(isSavingService(true))
				// Get the authorized configuration
				let user = getState().oidc.user
				let config = getAuthorizedConfiguration(user)
				// Add service
				let command = {
					Token: service ? service.Token : '',
					ContactPhone: service ? service.ContactPhone : '',
					ContactName: service ? service.ContactName : '',
					SiteContactName: service ? service.SiteContactName : '',
					SiteContactPhone: service ? service.SiteContactPhone : '',
					ServiceDescription: service ? service.ServiceDescription : '',
					DispatcherUserName: service.Dispatcher ? service.Dispatcher.value : null,
					ServiceProviderCode: service.ServiceProvider ? service.ServiceProvider.value : '',
					TeachAssignedUserName: service.TechAssigned ? service.TechAssigned.value : '',
					CommentProvider: service ? comments : ''
				}
				let url = `${window.config.serviceManagementBackendUrl}/service`
				let serverResponse = await axios.put(url, command, config)
				dispatch(setService(serverResponse.data))
				dispatch(isSavingService(false))
			}
			return await updateServiceFromBackend()
		} catch (ex) {
			dispatch(isSavingService(false))
			console.error('Error in update service', ex)
		}
	}
}

/**
 * Action to assigmenent Model
 * @param {*} serviceToken
 * @param {*} assignementModel
 */
export function assignementService(serviceToken, assignementModel) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			DispatcherCode: assignementModel.dispatcher ? assignementModel.dispatcher.value || assignementModel.dispatcher.UserName : '',
			TechAssignedCode: assignementModel.techAssigned ? assignementModel.techAssigned.value || assignementModel.techAssigned.UserName : '',
			ServiceProviderCode: assignementModel.serviceProvider
				? assignementModel.serviceProvider.value || assignementModel.serviceProvider.Code
				: ''
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/assigned`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to assigned service from Tech Assigned', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to change representative sales Model
 * @param {*} serviceToken
 * @param {*} assignementModel
 */
export function reasisngedServiceProvider(serviceToken, assignementModel) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			ServiceProviderCode: assignementModel.serviceProvider
				? assignementModel.serviceProvider.value || assignementModel.serviceProvider.Code
				: ''
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/reasigned`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to reasigned service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to reasing service provider
 * @param {*} serviceToken
 * @param {*} assignementModel
 */
export function changeRepresentativeSales(serviceToken, model) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			RepresentativeCode: model.salesRepresentative ? model.salesRepresentative.value || model.salesRepresentative.Code : ''
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/changerepresentative`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to change rep sales', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to assignerd Service Provider Tech
 * @param {*} serviceToken
 * @param {*} assignementModel
 */
export function assignerdServiceProviderTech(serviceToken, assignementModel) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			ServiceProviderTechAssignedUserName: assignementModel.serviceProviderTechAssigned
				? assignementModel.serviceProviderTechAssigned.value || assignementModel.serviceProviderTechAssigned.UserName
				: '',
			EmailTechnical: assignementModel.serviceProviderTechAssigned
				? assignementModel.serviceProviderTechAssigned.value || assignementModel.serviceProviderTechAssigned.UserName
				: ''
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/assigned/techprovider`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to assigned service from Tech Assigned service provider', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * This method get a unique list of services included in a list of equipments
 * @param {*} equipmentList
 */
export const getServiceFromEquipmentList = (equipmentList) => {
	let result = equipmentList.reduce((result, equip) => {
		let added = result.find((srv) => srv.Ticket === equip.Ticket)
		if (!added) {
			result.push({
				Dispatcher: equip.Dispatcher,
				RepresentativeCode: equip.RepresentativeCode,
				RepresentativeName: equip.RepresentativeName,
				RetailerCode: equip.RetailerCode,
				RetailerName: equip.RetailerName,
				ServiceProviderCode: equip.ServiceProviderCode,
				ServiceProviderName: equip.ServiceProviderName,
				TechAssigned: equip.TechAssigned,
				Ticket: equip.Ticket
			})
		}
		return result
	}, [])
	return sortBy(result, 'Ticket')
}

export function cancelService(serviceToken, statusModel, ticket, emailsToCancel) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			Comments: statusModel ? statusModel.comments : null,
			Ticket: ticket,
			Emails: emailsToCancel
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/cancel`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to cancel service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function suspendedService(serviceToken, suspendedModel, ticket) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			Comments: suspendedModel ? suspendedModel.comments : '',
			FutureCareDate: suspendedModel.futureCareDate ? suspendedModel.futureCareDate.format('DD/MM/YYYY') : null,
			Ticket: ticket
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/suspended`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to suspended service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function completedService(serviceToken, ticket, completedModdel, serie) {
	console.log('🚀 ~ file: serviceActions.js:1023 ~ completedService ~ serie:', serie)
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			Ticket: ticket,
			CompletionDate: completedModdel.completionDate ? completedModdel.completionDate.format('DD/MM/YYYY') : null,
			Subcategory: completedModdel ? completedModdel.subcategory : '',
			Serie: serie
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/completed`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to completed service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function replacedService(serviceToken, replacedModel, ticket) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			Ticket: ticket,
			Serie: replacedModel ? replacedModel.serie : '',
			Comments: replacedModel ? replacedModel.comments : ''
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/equipment/replaced`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to replaced service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to delete service in status active
 * @param {*} serviceToken
 * @param {*} ticket
 */
export function deleteService(serviceToken, ticket) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/delete/${serviceToken}/${ticket}`
		return axios
			.delete(endPoint, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to delete service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to change initial date in equipment from service
 * @param {*} serviceToken
 * @param {*} ticket
 * @param {*} initialDateModdel
 */
export function changeInitialDateService(serviceToken, ticket, initialDateModdel) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))

		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			Ticket: ticket,
			IntiatedDate: initialDateModdel.intiatedDate ? initialDateModdel.intiatedDate.format('DD/MM/YYYY') : null
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/service/changeInitialDate`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(setService(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to change initial date in equipment from service', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export const downloadReportOpenServices = (internalUsers, status, startDate, endDate, queryParameters) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user

		let endPoint = `${window.config.serviceManagementBackendUrl}/services/downloadReport/${status}`
		let command = {
			ParserApplicationUsers: internalUsers,
			StartDate: startDate,
			endDate: endDate,
			UserName: identityUser.profile.preferred_username
		}
		if (!!queryParameters) {
			if (!!queryParameters.statusFilter) {
				if (typeof queryParameters.statusFilter !== 'string')
					command.StatusFilter = queryParameters ? queryParameters.statusFilter.map((d) => parseInt(d, 10)) : []
				else command.StatusFilter = queryParameters ? [queryParameters.statusFilter] : []
			}
			if (!!queryParameters.representativesFilter) {
				if (typeof queryParameters.representativesFilter !== 'string')
					command.RepresentativeFilter = queryParameters ? queryParameters.representativesFilter.map((d) => d) : []
				else command.RepresentativeFilter = queryParameters ? [queryParameters.representativesFilter] : []
			}
			if (!!queryParameters.serviceProviderFilter) {
				if (typeof queryParameters.serviceProviderFilter !== 'string')
					command.ServiceProviderFilter = queryParameters ? queryParameters.serviceProviderFilter.map((d) => d) : []
				else command.ServiceProviderFilter = queryParameters ? [queryParameters.serviceProviderFilter] : []
			}
			if (!!queryParameters.retailersFilter) {
				if (typeof queryParameters.retailersFilter !== 'string')
					command.RetailFilter = queryParameters ? queryParameters.retailersFilter.map((d) => d) : []
				else command.RetailFilter = queryParameters ? [queryParameters.retailersFilter] : []
			}
			if (!!queryParameters.dispatcherFilter) {
				if (typeof queryParameters.dispatcherFilter !== 'string')
					command.DispatcherFilter = queryParameters ? queryParameters.dispatcherFilter.map((d) => d) : []
				else command.DispatcherFilter = queryParameters ? [queryParameters.dispatcherFilter] : []
			}
			if (!!queryParameters.teachAssignedFilter) {
				if (typeof queryParameters.teachAssignedFilter !== 'string')
					command.TeachAssignedFilter = queryParameters ? queryParameters.teachAssignedFilter.map((d) => d) : []
				else command.TeachAssignedFilter = queryParameters ? [queryParameters.teachAssignedFilter] : []
			}
		}

		return axios
			.post(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `ReportServices.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report for services', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to get all service
 * @param {*} serviceToken
 * @param {*} ticket
 */
export function loadServicesMetrics(startDate, endDate) {
	return function(dispatch, getState) {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		dispatch(isSavingService(true))
		let command = {
			StartDate: startDate,
			EndDate: endDate
		}
		let url = `${window.config.serviceManagementBackendUrl}/services/metrics`
		return axios
			.post(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let servicesMetrics = serverResponse.data
					dispatch(setServiceMetrics(servicesMetrics))
					dispatch(isSavingService(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to get service open closed', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add date attention by tech provider
 * @param {*} service
 * @param {*} attentionDateModel
 * @param {*} dispatcherService
 */
export function addDateAttention(service, attentionDateModel, dispatcherService, isListService) {
	return function(dispatch, getState) {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		dispatch(isSavingService(true))
		var savedDateAttention = attentionDateModel.dateAttention ? moment(attentionDateModel.dateAttention).format('DD/MM/YYYY') : null
		let command = {
			ServiceToken: service ? service.Token : '',
			TechnicianServiceDate: attentionDateModel
				? service.TechnicianServiceDate === attentionDateModel.dateAttention
					? savedDateAttention
					: attentionDateModel.dateAttention.format('DD/MM/YYYY')
				: '',
			TechnicianComments: attentionDateModel ? attentionDateModel.comments : '',
			EmailDispatcher: dispatcherService ? dispatcherService.Email : ''
		}
		let url = `${window.config.serviceManagementBackendUrl}/services/dateAttentionDate`
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setService(serverResponse.data))
					dispatch(updateAttentionDate(serverResponse.data))
					dispatch(isSavingService(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to add date attention', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to add file or imagen in service
 * @param {*} serviceToken
 * @param {*} files
 */
export const addAttachments = (serviceToken, files) => {
	return function(dispatch, getState) {
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let command = {
			ServiceToken: serviceToken
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/services/attachments`
		let data = new FormData()
		for (var index = 0; index <= files.length; index++) {
			data.append('file', files[index])
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then((response) => {
				dispatch({
					type: ADD_FILES_SERVICE,
					evidences: response.data
				})
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error uploading evidence in service', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to remove file or image in service
 * @param {*} serviceToken
 * @param {*} fileName
 */
export function removeAttachments(serviceToken, fileName) {
	return function(dispatch, getState) {
		let params = {
			Name: fileName
		}
		let configuration = getAuthorizedConfigurationParameters(getState().oidc.user, params)
		let endPoint = `${window.config.serviceManagementBackendUrl}/services/${serviceToken}/attachments`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_FILES_SERVICE,
						fileName: fileName
					})
				}
				return Promise.resolve()
			})
			.catch((error) => {
				console.error('Error to remove evidence file(s) in service', error.response)
				return Promise.reject()
			})
	}
}

export const downloadReportNetworkDateServices = (internalUsers, status, startDate, endDate, queryParameters) => {
	return function(dispatch, getState) {
		dispatch(setIsDownloadReport(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		var identityUser = getState().oidc.user

		let endPoint = `${window.config.serviceManagementBackendUrl}/services/downloadReport/${status}`
		let command = {
			ParserApplicationUsers: internalUsers,
			StartDate: startDate,
			endDate: endDate,
			UserName: identityUser.profile.preferred_username
		}
		if (!!queryParameters) {
			if (!!queryParameters.salesRep) {
				if (typeof queryParameters.salesRep !== 'string')
					command.RepresentativeFilter = queryParameters ? queryParameters.salesRep.map((d) => d) : []
				else command.RepresentativeFilter = queryParameters ? [queryParameters.salesRep] : []
			}
			if (!!queryParameters.serviceProvider) {
				if (typeof queryParameters.serviceProvider !== 'string')
					command.ServiceProviderFilter = queryParameters ? queryParameters.serviceProvider.map((d) => d) : []
				else command.ServiceProviderFilter = queryParameters ? [queryParameters.serviceProvider] : []
			}
			if (!!queryParameters.customerCode) {
				if (typeof queryParameters.customerCode !== 'string')
					command.CustomerFilter = queryParameters ? queryParameters.customerCode.map((d) => d) : []
				else command.CustomerFilter = queryParameters ? [queryParameters.customerCode] : []
			}
		}

		return axios
			.post(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config
			})
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIsDownloadReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `ReportServices.xlsx`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(setIsDownloadReport(false))
				console.error('Error donwload report for services', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to add comment history
 * @param {*} service
 * @param {*} comments
 */
export function addCommentHistory(service, comments) {
	return function(dispatch, getState) {
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		dispatch(isSavingService(true))
		let command = {
			ServiceToken: service ? service.Token : '',
			Comments: comments ? comments : '',
			UserName: user.profile.username
		}
		let url = `${window.config.serviceManagementBackendUrl}/services/commentHistory/add`
		return axios
			.put(url, command, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(addCommentsHistory(serverResponse.data))
					dispatch(isSavingService(false))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to add date attention', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Action to update comment history in service
 * @param {*} serviceToken
 * @param {*} commentToken
 */
export function updateCommentHistoryStatus(serviceToken, commentToken) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			CommentToken: commentToken
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/services/commentHistory/update`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(updateCommentsHistory(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to update property in equipment', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update specific comment only available for internal user
 * @param {*} serviceToken
 * @param {*} commentToken
 * @returns
 */
export function updateComment(serviceToken, commentToken, newCommentValue) {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))
		let config = getAuthorizedConfiguration(getState().oidc.user)
		let data = {
			ServiceToken: serviceToken,
			CommentToken: commentToken,
			Comment: newCommentValue
		}
		let endPoint = `${window.config.serviceManagementBackendUrl}/services/commentHistory/update`
		return axios
			.put(endPoint, data, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingService(false))
					dispatch(updateCommentsHistory(serverResponse.data))
				}
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error to update property in equipment', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to import comments
 */
export const importCommentsFromBackend = (files) => {
	return function(dispatch, getState) {
		dispatch(isSavingService(true))
		// Get the authorized configuration
		let user = getState().oidc.user
		let config = getAuthorizedConfiguration(user)
		const { serviceManagementBackendUrl } = window.config
		let endPoint = `${serviceManagementBackendUrl}/services/import/commentsbyprovider`
		let command = {}
		let data = new FormData()
		data.append('files', files)
		for (var index = 0; index < files.length; index++) {
			data.append('FileNames', files[index].attachment.name)
			data.append('file', files[index].attachment)
		}
		data.append('command', JSON.stringify(command))
		return axios
			.post(endPoint, data, config)
			.then(() => {
				dispatch(isSavingService(false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isSavingService(false))
				console.error('Error importing comments', error)
				return Promise.reject(error.response.data.Message)
			})
	}
}
