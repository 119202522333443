/**Import react section */
import React from 'react';
import propTypes from 'prop-types'

/** Material UI import section */
import {
    Fab,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

/** Resources import section */
import { panelLateralStyles } from '../../../resources/styles/PanelLateralStyles';

const PanelHeader = props => {
    const { title } = props;
    const { onCloseButtonClick, onSaveButtonClick, onEditButtonClick, isModeSaved, canBeSaved, isSaving } = props;
    const classes = panelLateralStyles();

    return (
        <div className={classes.panelHeader}>
            <Typography gutterBottom variant="subtitle1" className={classes.title}>{title || ''}</Typography>

            {
                (!!onEditButtonClick || !!onSaveButtonClick) &&
                <Fab
                    disabled={(isModeSaved ? !canBeSaved : false) || isSaving}
                    className={isModeSaved ? classes.buttonSaved : classes.buttonEdit}
                    onClick={isModeSaved ? onSaveButtonClick : onEditButtonClick}
                >
                    {isModeSaved ? <SaveIcon /> : <EditIcon />}
                </Fab>
            }
            {
                !!onCloseButtonClick &&
                <Fab
                    className={classes.buttonClose}
                    onClick={onCloseButtonClick}
                >
                    <DeleteIcon />
                </Fab>
            }
        </div>
    )
}
PanelHeader.propTypes = {
	/**
	 * Determine if is saving
	 */
    isSaving: propTypes.bool,
    /**
	 * Determine if is mode saved
	 */
    isModeSaved: propTypes.bool,
    /**
	 * On change button saved
	 */
    onSaveButtonClick: propTypes.func,
    /**
 * On change button edit
 */
    onEditButtonClick: propTypes.func,
}


PanelHeader.defaultProps = {
    isLoading: false,
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    onSaveButtonClick: () =>
        console.warn('Callback [onSaveButtonClick] no defined'),
    onEditButtonClick: () =>
        console.warn('Callback [onEditButtonClick] no defined')
}

export default PanelHeader;